<template>
  <div id="divider" :style="{ height: height ,'margin-left':marginpage,
          'margin-right':marginpage,
					'margin-top':margintop,
          'margin-bottom':marginbottom,
          'padding-top':paddingtop,
          'padding-bottom':paddingbottom,}">
    <p
      v-show="list.options.type == 2"
      :class="[classname, bjclassname]"
      :style="{ borderBottomColor: list.options.color }"
    ></p>
  </div>
</template>
<script>
export default {
  name: "line_place",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: "",
    };
  },

  created() {
    this.list = this.data;
  },

  methods: {},
  computed: {
    // 辅助空白的高度
    height() {
      if (this.list.options.type == 1) {
        return this.list.options.height + "px";
      } else {
        return "20px";
      }
    },
    // 辅助线的类名
    classname() {
      if (this.list.options.type == 2 && this.list.options.line == 1) {
        return "shi";
      } else if (this.list.options.type == 2 && this.list.options.line == 2) {
        return "xu";
      } else {
        return "dian";
      }
    },
     bjclassname() {
    if (this.list.options.type == 2 && this.list.options.about == 1) {
      return "";
    } else {
      return "about";
    }
  },
  margintop() {
  	console.log(this.list.options,'this.list.options');
  	if (this.list.options) {
  		if (this.list.options.margintop && this.list.options.margintop > 0)
  			return this.list.options.margintop + 'px';
  	}
  },
  marginbottom() {
  	if (this.list.options) {
  		if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
  			return this.list.options.marginbottom + 'px';
  	}
  },
  paddingtop() {
  	if (this.list.options) {
  		if (this.list.options.paddingtop && this.list.options.paddingtop > 0)
  			return this.list.options.paddingtop + 'px';
  	}
  },
  paddingbottom() {
  	if (this.list.options) {
  		if (this.list.options.paddingbottom && this.list.options.paddingbottom > 0)
  			return this.list.options.paddingbottom + 'px';
  	}
  },
  marginpage() {
  	if (this.list.options) {
  		if (this.list.options.marginpage && this.list.options.marginpage > 0)
  			return this.list.options.marginpage + 'px';
  	}
  },
  },
  //   有无边距
 
};
</script>

<style lang="less" scoped>
#divider {
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  p {
    width: 100%;
  }
}
// 实线
.shi {
  border-bottom: 1px solid black;
}
// 虚线
.xu {
  border-bottom: 1px dashed black;
}
// 点线
.dian {
  border-bottom: 1px dotted black;
}
// 边距
.about {
  margin: 0 20px;
}
.full{
    flex-grow: 1;
  }
</style>