import httpapi from '../../utils/httpapi';
const personApi = {
	//用户风格列表
	list(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/man/page?mpid='+param.mpid +'&pageno='+param.pageno+'&pagesize='+param.pagesize, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//用户风格编辑
	edit(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/man/add', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	
	//A1100.编辑/添加员工
	manadd(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/man/add', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	mandel(param,funsucc){
		httpapi.post('/customer/qiyeweixin/man/del', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	}
}
export default personApi
