import httpapi from '../../utils/httpapi';
const resourceApi = {
	//查询二维码配置信息
	list(param, funsucc) {
		httpapi.get('/customer/resource/qywx/list?'+`clsid=${param.clsid}&mc=${param.mc}&pageno=${param.pageno}&pagesize=${param.pagesize}`).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	del(param,funsucc){
		httpapi.post('/customer/resource/res/delete',param).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	}
}
export default resourceApi