<template>
	<div class="info-content">
		<div class="item">
			<div class="mc">标题</div>
			<div class="text"><el-input v-model="list.title" size="small"></el-input></div>
		</div>
		<div class="item">
			<div class="mc">备注</div>
			<div class="text"><el-input type="textarea" :rows="4" v-model="list.memo" size="small"></el-input></div>
		</div>
		<div class="item">
			<div class="mc">背景</div>
			<div class="text">
				<el-radio-group v-model="list.stylestate" size="small">
					<el-tooltip class="item" effect="dark" content="风格" placement="bottom">
						<el-radio-button :label="'T'">风格</el-radio-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="页面" placement="bottom">
						<el-radio-button :label="'F'">页面</el-radio-button>
					</el-tooltip>

				</el-radio-group>
			</div>
		</div>
		<div class="item disp" v-if="(list.stylestate &&  list.stylestate == 'F') ">
			<div class="mc">背景色</div>
			<div class="text">
				<div class="list">
					<div class="title">{{ list.backgroundColor||'默认' }}<span></span></div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('backgroundColor')">重置</el-button><el-color-picker
							v-model="list.backgroundColor" size="medium"></el-color-picker>
					</div>
				</div>
			</div>
		</div>
		<div class="item" v-if="(list.stylestate &&  list.stylestate == 'F') ">
			<div class="mc">标题</div>
			<div class="text">
				<el-radio-group v-model="list.headcolor" size="small">
					<el-tooltip class="item" effect="dark" content="黑色" placement="bottom">
						<el-radio-button :label="0">黑色</el-radio-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="白色" placement="bottom">
						<el-radio-button :label="1">白色</el-radio-button>
					</el-tooltip>

				</el-radio-group>
			</div>
		</div>
		<div class="item">
			<div class="mc">顶部</div>
			<div class="text">
				<el-radio-group v-model="list.showhead" size="small">
					<el-tooltip class="item" effect="dark" content="有" placement="bottom">
						<el-radio-button :label="1">显示</el-radio-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="无" placement="bottom">
						<el-radio-button :label="0">隐藏</el-radio-button>
					</el-tooltip>

				</el-radio-group>
			</div>
		</div>
		<div class="item">
			<div class="mc">底部导航栏</div>
			<div class="text">
				<el-radio-group v-model="list.showfoot" size="small">
					<el-tooltip class="item" effect="dark" content="有" placement="bottom">
						<el-radio-button :label="1">显示</el-radio-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="无" placement="bottom">
						<el-radio-button :label="0">隐藏</el-radio-button>
					</el-tooltip>

				</el-radio-group>
			</div>
		</div>

		<!-- <el-form-item  label="页边距">
            <el-slider v-model="list.paddingpage" show-input :max="200" > 
          </el-slider>
          </el-form-item> -->
	</div>
</template>

<script>
	import designOptionVal from '../../../../api/cus/designOptionVal'
	export default {
		name: 'Info',
		props: ['data', 'className'],
		data() {
			return {
				list: {}
			}
		},
		mounted() {
			this.list = this.data
			console.log(this.list, 'pagehead')
		},
		methods: {
			reset(key) {
				this.$delete(this.list, key)
			},
		}
	}
</script>

<style lang="less" scoped>
	.info-content {
		padding:0 20px ;
		.item {
			margin: 5px 0;
			.mc{
				margin-bottom: 10px;
				font-size: 10px;
				color: #404450;
			}
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #969799;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		.reset {
			margin-right: 10px;
		}
	}
	.disp{
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
</style>