<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="14"></leftnav>
			<div class="rightbox"  style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>消息管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>消息管理</span>
						<div class="" style="float:right">
							<el-button type="primary" size="small" @click="funAddMsg">新增消息</el-button>
						</div>
					</div>
					<div class="main">
						<!--列表-->
						<el-table size="small" :data="msgData" highlight-current-row v-loading="loading" :border="false"
							element-loading-text="拼命加载中" style="width: 100%;">
							<el-table-column align="center" sortable prop="appType" label="类型" width="180">
								<template slot-scope="scope">
									<span v-if="scope.row.appType == 'text'">文本</span>
									<span v-if="scope.row.appType == 'news'">新闻</span>
									<span v-if="scope.row.appType == 'app'">小程序</span>
								</template>
							</el-table-column>
							<el-table-column align="center" sortable prop="title" label="标题" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="appid" label="小程序appid" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="apppath" label="小程序路径" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="content" label="内容" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="createDate" label="日期" width="180" >
							</el-table-column>
							<el-table-column label="操作" min-width="200" fixed="right">
								<template slot-scope="scope">
									<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
									<el-button size="mini" type="danger"
										@click="deletemsg(scope.$index, scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
				<el-dialog :title="title" :visible.sync="editFormVisible" width="50%" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="消息类型" prop="appType" >
								<el-select v-model="editForm.apptype" placeholder="请选择" size="small" v-if="title == '新增'">
									<el-option v-for="item in apptypelist" :key="item.id" :label="item.mc" :value="item.id">
									</el-option>
								</el-select>
								<el-select v-model="editForm.apptype" placeholder="请选择" size="small" v-else>
									<el-option v-for="item in apptypelist" :key="item.id" :label="item.mc" :value="item.id" :disabled="true">
									</el-option>
								</el-select>
						</el-form-item>
						<el-form-item label="小程序分类" prop="appcls" v-if="editForm.apptype == 'app'">
							<el-input size="small" v-model="editForm.appcls" auto-complete="off"
								placeholder="请输入小程序分类"></el-input>
						</el-form-item>
						<el-form-item label="小程序appid" prop="appid" v-if="editForm.apptype == 'app'">
							<el-input size="small" v-model="editForm.appid" auto-complete="off"
								placeholder="请输入小程序appid"></el-input>
						</el-form-item>
						<el-form-item label="小程序路径" prop="apppath" v-if="editForm.apptype == 'app'">
							<el-input size="small" v-model="editForm.apppath" auto-complete="off"
								placeholder="请输入小程序路径"></el-input>
						</el-form-item>
						<el-form-item label="文本内容" prop="content" v-if=" editForm.apptype == 'text'">
							<el-input size="small" type="textarea" rows="5" v-model="editForm.content" auto-complete="off"
								placeholder="请输入文本内容"></el-input>
						</el-form-item>
						<el-form-item label="图文" prop="title" v-if="editForm.apptype == 'news'">
							<selNews ref="selNews" @selNewsResult="selNewsResult"></selNews>
							<el-button size="small" type="primary" class="title" @click="funSelNews()">选择图文
							</el-button>
							<div class="main">
								<div class="main-left">
									<div class="main-box" >
										<div class="cont" v-for="(citem,cindex) in sendhtml" :key="cindex">
											<div class="head" v-if="cindex == 0">
												<div class="logo">
													<img :src="citem.logo">
													<div class="title">
														{{citem.title}}
													</div>
												</div>
											</div>
											<div class="main-news" v-else>
												<div class="main-title">
													{{citem.title}}
												</div>
												<div class="main-img">
													<img :src="citem.logo" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="submitForm()">确定
						</el-button>
					</div>
				</el-dialog>
			</div>
		</div>
		
	</div>
</template>

<script>
	import menuApi from '../../../../../../api/cus/menu.js'
	import msgApi from '../../../../../../api/cus/msg.js'
	import license from '../../../../../../api/cus/license.js'
	import selNews from '@/components/customer/selNews.vue'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			selNews,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title:"",
				loading: false,
				editForm:{
					apptype:"",
					appcls:"",
					appid:"",
					apppath:"",
					content:"",
					id:"",
					mpid:"",
					newsid:"",
					orderno:"",
					title:"",
				},
				apptypelist: [{
						id: 'text',
						mc: "文本"
					},
					{
						id: 'news',
						mc: "图文"
					},
					{
						id: 'app',
						mc: "小程序"
					},
				],
				licenseData:[],
				editFormVisible:false,
				msgData: [],
				sendhtml:[]
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist()
			},
			getlist(){
				let param = {
					mpid: this.mpid,
					pageno: 1,
					pagesize: 100,
				}
				this.loading = true
				msgApi.msglist(param, res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.loading = false
						this.msgData = res.data
					}
				})
			},
			getlicense(){
				license.getall({mpid:this.mpid}, res => {
					console.log(res, 'res.getlicense');
					if (res.code == 200) {
						this.licenseData = res.data
					}
				})
			},
			handleEdit(index,row) {
				let param = {
					id: row.id,
				}
				console.log(param,'param');
				msgApi.msgdetail(param, res => {
					console.log(res, 'res.msgdetail');
					this.editFormVisible = true
					if (res.code == 200) {
						this.title = "编辑"
						this.editForm.apptype = res.data.appType;
						this.editForm.appcls =res.data.appcls;
						this.editForm.appid =res.data.appid;
						this.editForm.apppath =res.data.apppath;
						this.editForm.content =res.data.content;
						this.editForm.contentxml =res.data.contentxml;
						this.editForm.createDate =res.data.createDate;
						this.editForm.id =res.data.id;
						this.editForm.mpid =res.data.mpid;
						this.editForm.newsid =res.data.newsid;
						this.editForm.orderNo =res.data.orderNo;
						this.editForm.title =res.data.title;
						this.editForm.updateDate =res.data.updateDate;
					}else{
						this.$message({
							type: 'error',
							message: '失败!'
						})
					}
				})
				
			},
			funSelNews(){
				this.$refs['selNews'].funShow(this.mpid)
			},
			selNewsResult(param){
				console.log(param,'param');
				this.editForm.newsid = param.newsid;
				this.editForm.title = param.title;
				this.sendhtml = param.sendhtml
			},
			funAddMsg() {
				this.title = "新增"
				this.editForm.apptype = "text";
				this.editForm.appcls ="";
				this.editForm.appid ="";
				this.editForm.apppath ="";
				this.editForm.content ="";
				this.editForm.contentxml ="";
				this.editForm.createDate ="";
				this.editForm.id ="";
				this.editForm.mpid ="";
				this.editForm.newsid ="";
				this.editForm.orderNo ="";
				this.editForm.title ="";
				this.editForm.updateDate ="";
				this.editFormVisible = true
			},
			submitForm(){
				if(this.title == "编辑"){
					let param = {
						mpid: this.mpid,
						appType:this.editForm.apptype,
						appcls:this.editForm.appcls,
						appid:this.editForm.appid,
						apppath:this.editForm.apppath,
						content:this.editForm.content,
						id:this.editForm.id,
						orderno:0,
						title:this.editForm.title,
					}
					
					if(this.editForm.apptype == 'news'){
						param.newsid = this.editForm.newsid
					}
					console.log(param,'param');
					msgApi.msgedit(param, res => {
						console.log(res, 'res。edit');
						this.editFormVisible = false
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '编辑成功!'
							})
							this.getlist()
						}else{
							this.$message({
								type: 'error',
								message: '编辑失败!'
							})
						}
					})
				}else{
					let param = {
						mpid: this.mpid,
						appType:this.editForm.apptype,
						appcls:this.editForm.appcls,
						appid:this.editForm.appid,
						apppath:this.editForm.apppath,
						content:this.editForm.content,
						orderno:0,
						title:this.editForm.title,
					}
					
					if(this.editForm.apptype == 'news'){
						param.newsid = this.editForm.newsid
					}
					console.log(param,'param');
					msgApi.msgedit(param, res => {
						console.log(res, 'res');
						this.editFormVisible = false
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '添加成功!'
							})
							this.getlist()
						}else{
							this.$message({
								type: 'error',
								message: '添加失败!'
							})
						}
					})
				}
				
			},
			deletemsg(index,row) {
				let param = {
					id: row.id,
				}
				console.log(param,'param');
				msgApi.msgedel(param, res => {
					console.log(res, 'res.del');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '删除成功!'
						})
						this.getlist()
					}else{
						this.$message({
							type: 'error',
							message: '删除失败!'
						})
					}
				})
			},
		}
	}
</script>

<style scoped>
	.page{
		background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
	}
	.pagecontent{
		display: flex;
		
		height: calc(100vh - 81px);
		overflow: auto;
	}
	.box-card {
		width: 1320px;
		margin: 20px 0 0 0;
		
	}
	.main {
		display: flex;
		flex-wrap: wrap;
		height: 600px;
		overflow-y: auto;
	}
	
	.main .main-left {
		height: 100%;
		display: flex;
		flex-direction: row;
		overflow-y: auto;
		flex-wrap: wrap;
		position: relative;
	}
	
	.main .main-left .main-box {
		margin: 10px;
		width: 375px;
		height: 380px;
		overflow: auto;
		border-radius: 5px;
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 8px 0 #e4e7ed;
	}
	
	.main .main-left .main-box .head {
		display: flex;
		align-items: center;
	}
	
	.main .main-left .main-box .head .logo {
		width: 100%;
		height: 100px;
		position: relative;
	}
	
	.main .main-left .main-box .head .logo img {
		width: 100%;
		height: 100%;
	}
	
	.main .main-left .main-box .head .title {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 50px;
		line-height: 50px;
		color: #fff;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
	}
	
	.main .main-left .main-news {
		padding: 0 20px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.main .main-left .main-news .main-img {
		width: 30px;
		height: 30px;
		background-color: #E1E1E1;
	}
	
	.main .main-left .main-news .main-img img {
		width: 100%;
		height: 100%;
	}
	.main .active{
		border: 1px solid #55aaff ;
	}
	.main .normal{
		border:none;
	}
</style>