<template>
  <div id="shop">
    <div class="list">
      <div class="logo"></div>
      <div class="details">
        <div>智能优选</div>
        <div class="one">
          <span>在线支付满150减20</span>
          <i class="el-icon-arrow-right two"></i>
        </div>
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Shop",

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
#shop {
  min-height: 200px;
  background-position: center center;
  background-repeat: no-repeat;

  display: flex;

  align-items: flex-end;
  .jieshao {
    width: 100%;
    margin-bottom: 20px;
  }
}
.logo {
  width: 50px;
  height: 50px;
  background: red;
}
.list {
  display: flex;
  align-items: flex-end;
  margin: 20px 20px;
}
.details {
  margin-left: 20px;
  .one {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
.two {
  text-align: right;
}
</style>