/**
* 服务商应用管理
*/
<template>
	<div>
		<navcon></navcon>
		
		<div class="page">
			
			<el-card class="box-card">
				<div class="unitno">编号：{{userInfo.unitno}}</div>
				<div slot="header" class="clearfix">
					<div class="btnbox" style="flex-grow: 1;" v-if="ispermissions" :class="showbottom == false ? 'activebtn' : ''">
						<el-button type="primary" class="bitem" size="small" @click="createApp(1)">创建小程序</el-button>
						<!-- <el-button type="primary" size="small" @click="createApp(2)">注册小程序</el-button> -->
						<el-button type="primary"  class="bitem" size="s                                                                                                                                                                                                                               mall" @click="createApp(3)">创建公众号</el-button>
						<el-button type="primary"  class="bitem" size="small" @click="createApp(4)">创建企业微信</el-button>
						<el-button type="primary"  class="bitem" size="small" @click="createApp(5)">创建支付宝小程序</el-button>
						<el-button type="primary"  class="bitem" size="small" @click="createApp(6)">创建支付宝生活号</el-button>
					</div>

					<el-form :inline="true" :model="formInline" class="user-search">
						<el-form-item label="">
							
							 <el-input placeholder="输入应用名称" v-model="formInline.appname" size="medium" class="input-with-select">
							    <el-button slot="append" icon="el-icon-search"  @click="search"></el-button>
							  </el-input>
						</el-form-item>
						<!-- <el-form-item>
							<el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
						</el-form-item> -->
					</el-form>
				</div>
				<!--列表-->
				<div class="gzhlist">
					<div class="gitem" @click="handleDetail(index,item)" style="margin-bottom: 20px;" v-for="(item,index) in appData" :key="index">
						<div class="row">
							<div class="mc">{{item.mpname}}</div>
							<div class="tip">{{item.mptypename}}</div>
						</div>
						<div class="row" style="margin:12px 0 ;">
							<div class="id">{{item.appid}}</div>
							<div :class="'zt' + item.isuse"></div>
							<div>{{item.isuse == 0 ? "未启用" : "启用"}}</div>
						</div>
						<div>{{item.createdate}}</div>
					</div>
				</div>
				<!-- <el-table size="small" :data="appData" highlight-current-row v-loading="loading" :border="false"
					element-loading-text="拼命加载中" style="width: 100%;" @row-dblclick="handleDetails">
					<el-table-column align="center" prop="appid" label="应用名称/ID" width="180">
					</el-table-column>
					<el-table-column align="center" prop="mpname" label="公众号名称" width="180">
					</el-table-column>
					<el-table-column align="center" prop="logo" label="头像" width="180">
						<template slot-scope="scope">
							<img :src="scope.row.logo" />
						</template>
					</el-table-column>
					<el-table-column align="center" prop="mptypename" label="账户类型" width="180">
						
					</el-table-column>
					<el-table-column align="center" prop="isuse" label="启用状态" width="120">
						<template slot-scope="scope">
							{{scope.row.isuse == 0 ? "未启用" : "启用"}}
						</template>
					</el-table-column>
					<el-table-column align="center" prop="createdate" label="创建日期" width="180">
					</el-table-column>
					<el-table-column label="操作" min-width="200">
						<template slot-scope="scope">
							<el-button size="mini" @click="handleDetail(scope.$index, scope.row)">详情</el-button>
						</template>
					</el-table-column>
				</el-table> -->
				<!-- 分页组件 -->
				
			</el-card>
			<el-card class="box-card"  v-if="this.showbottom">
				<div slot="header" class="clearfix">
					<span>开发工具推荐</span>
				</div>
				<div class="box">
					<div class="box-warp">
						<div class="box-item img1">
							<!-- <div class="title">
								沙箱
							</div>
							<div class="memo">
								使用沙箱环境，真实调试/验证接口能力
							</div> -->
						</div>
						<div class="box-item img2">
							<!-- <div class="title">
								云监控
							</div>
							<div class="memo">
								实时监控业务稳定性,支持自定义监控和告警
							</div> -->
						</div>
						<div class="box-item img3">
							<!-- <div class="title">
								小程序云
							</div>
							<div class="memo">
								集成云托管、云营销、云开发等产品，降本增效高稳定，业务增长新引擎
							</div> -->
						</div>
						<div class="box-item img4">
							<!-- <div class="title">
								质量监控中心
							</div>
							<div class="memo">
								实时监控小程序运行质量问题，提供故障告警、数据分析和优化方案
							</div> -->
						</div>
					</div>
				</div>
			</el-card>
			<!--微信:添加小程序-->
			<wxxcx ref="wxxcx"></wxxcx>
			<!--微信:注册小程序-->
			<wxxcxreg ref="wxxcxreg"></wxxcxreg>
			<!--微信:添加公众号-->
			<wxgzh ref="wxgzh"></wxgzh>
			<!--微信:添加企业微信-->
			<wxqywx ref="wxqywx"></wxqywx>
			<!--支付宝:添加支付宝小程序-->
			<zfbxcx ref="zfbxcx"></zfbxcx>
			<!--支付宝:添加支付宝生活号-->
			<zfblife ref="zfblife"></zfblife>

		</div>
		<pagebottom v-if="this.showbottom"></pagebottom>
	</div>

</template>

<script>
	import wxxcx from "../../../components/customer/home/wxxcx.vue"
	import wxxcxreg from "../../../components/customer/home/wxxcxreg.vue"
	import wxgzh from "../../../components/customer/home/wxgzh.vue"
	import wxqywx from "../../../components/customer/home/wxqywx.vue"
	import zfbxcx from "../../../components/customer/home/zfbxcx.vue"
	import zfblife from "../../../components/customer/home/zfblife.vue"
	// 导入请求方法
	import accountApi from '../../../api/cus/account.js'
	import Pagination from '../../../components/Pagination'
	import pagebottom from '../../../components/pagebottom.vue'
	import navcon from '../../../components/customer/navcon.vue'
	import {
		getloginuser,
		delloginall
	} from '../../../utils/util.js'
	export default {
		data() {
			return {
				loading: false, //是显示加载
				title: '添加用户',
				editFormVisible: false, //控制编辑页面显示与隐藏
				userInfo: {},
				appData: [],
				// 编辑与添加
				editForm: {
					appid: "",
					appkey: "",
					appname: "",
					isvid: "",
					mashopnum: 1,
					maxmchnum: 1,
					usemchnum: 1,
					useshopnum: 1,
				},
				// rules表单验证
				rules: {
					appname: [{
						required: true,
						message: '请输入app名称',
						trigger: 'blur'
					}],
				},
				// 请求数据参数
				formInline: {
					page: 1,
					limit: 10,
					appname: '',
				},
				// 分页参数
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 10
				},
				ispermissions: true,
				screenWidth:0,
				showbottom:false
			}
		},
		// 注册组件
		components: {
			wxxcx,
			wxxcxreg,
			wxgzh,
			wxqywx,
			zfbxcx,
			zfblife,
			Pagination,
			pagebottom,
			navcon
		},

		/**
		 * 数据发生改变
		 */
		watch: {},

		mounted() {
			document.title = '应用管理-公众号管理平台-大新软件'
			let screenWidth = window.innerWidth
			
			console.log(this.screenWidth,'this.screenWidth');
			if(this.screenWidth <= 750){
				this.showbottom = false
			}else{
				this.showbottom = true
			}
			let u = getloginuser()
			
			if (u != undefined) {
				this.userInfo = u
				console.log(this.userInfo, 'this.userInfo');
				if (this.userInfo.permissions != undefined && this.userInfo.permissions[0] == "admin") {
					this.ispermissions = true
				} else {
					this.ispermissions = false
				}
				this.getdata(this.formInline)
			} else {
				this.$router.push("/customer/login?gourl=" + encodeURIComponent("/customer/manage/app"));
			}
		},
		/**
		 * 里面的方法只有被调用才会执行
		 */
		methods: {
			// 获取数据方法
			getdata(page) {
				this.loading = true
				// 请求数据开始
				accountApi.list(res => {
					console.log(res, 'accountApi.res');
					if (res.code == 200) {
						this.loading = false
						this.appData = res.data
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					}else if(res.code == 401){
						delloginall()
						this.$router.push({
							path:'/customer/login'
						})
					}
					 else {
						
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			// 分页插件事件
			callFather(parm) {
				this.formInline.page = parm.currentPage
				this.formInline.limit = parm.pageSize
				this.getdata(this.formInline)
			},
			//搜索事件
			search() {
				this.getdata(this.formInline)
			},
			//编辑，新增
			handleEdit(index, row) {
				if (row != undefined && row != 'undefined') {
					this.title = '修改应用'
					accountApi.info({
						mpid: row.mpid
					}, res => {
						console.log(res, 'handleEdit.res');
						if (res.code == 200) {
							this.loading = false
							this.editForm.appname = res.data.mpName
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				} else {
					this.title = '添加应用'
					this.editForm.appname = ''
				}
			},
			createApp(zt) {
				switch (zt) {
					case 1:
						this.$router.push({
							path: '/customer/skip',
							query: {
								zt: zt
							}
						})
						break;
					case 2:
						this.$router.push({
							path: '/customer/skip',
							query: {
								zt: zt
							}
						})
						break;
					case 3:
						this.$router.push({
							path: '/customer/skip',
							query: {
								zt: zt
							}
						})
						// this.$refs["wxgzh"].funShow("创建公众号")
						break;
					case 4:
						// this.$refs["wxqywx"].funShow("创建企业微信")
						// this.$router.push({
						// 	path: '/customer/skip',
						// 	query: {
						// 		zt: zt
						// 	}
						// })
						this.$router.push({
							path:"/customer/callback/autowxwork?totype=handreg" 
						})
						break;
					case 5:
						this.$refs["zfbxcx"].funShow("创建支付宝小程序")
						break;
					case 6:
						this.$refs["zfblife"].funShow("创建支付宝生活号")
						break;
					default:
						break;
				}
			},
			handleDetails(row, col) {
				this.handleDetail(0, row)
			},
			handleDetail(index, row) {
				console.log(row, 'row');
				let path = ''
				switch (row.mptype) {
					case 10:
						//微信公众号
						path = '/customer/home/mp/weixingzh/alls/index'
						break;
					case 11:
						//注册小程序
						path = '/customer/home/mp/weixinapp/alls/index'
						break;
					case 12:
						//企业微信
						path = '/customer/home/mp/weixinqiye/alls/index'
						break;
					case 21:
						//支付宝小程序
						path = '/customer/home/mp/zhifubaoapp/home'
						break;
					default:
						break;
				}
				this.$router.push({
					path: path,
					query: {
						mpid: row.mpid
					}
				})
			}
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.user-search {
		margin-top: 20px;
	}

	.userRole {
		width: 100%;
	}

	.box-card {
		width: 1320px;
		margin: 20px auto 0 auto;
		border-radius: 10px;
		position: relative;
	}


	.box-warp {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;
	}

	.box .box-item {
		cursor: pointer;
		margin: 0 15px;
		width: 310px;
		height: 120px;
		border: 1px solid #ffffff;
		
		border-radius: 4px;
	}

	.box .box-item:hover {
		border: 1px solid #1677ff;
		border-radius: 4px;
		box-shadow: 0 1px 20px #e6ebf0;
		transition: all .5s;
	}

	.box .box-item .title {
		margin-bottom: 10px;
		color: #333;
		font-size: 16px;
	}

	.page {
		padding: 20px 0 0 0;
		margin: 0 auto;
		
	}

	.gzhlist {
		display: flex;
		flex-wrap: wrap;

	}

	.gzhlist .gitem {
		padding: 14px 13px;
		background-color: #F8F8F8 ;
		width:210px;
		height:82px;
		margin-right: 20px;
		margin-bottom: 20px;
		border-radius: 5px;
	}
	.gzhlist .gitem .mc{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 14px;
		color: #202020;
		flex-shrink: 0;
		white-space: nowrap;
		max-width: 120px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.gzhlist .gitem .tip{
		background-color: #FAEDE1;
		border:1px solid #FF9533;
		color:#FF9533;
		font-size: 8px;
		scale: 0.8;
		text-align: center;
		padding: 2px 10px;
	}
	.row{
		display: flex;
		align-items: center;
	}
	.btnbox {
		display: flex;
		justify-content: left;
	}
	
	.zt0{
		width: 4px;
		height: 4px;
		border-radius: 4px;
		background-color: #ff0000;
		margin-right: 5px;
	}
	.zt1{
		width: 10px;
		height: 10px;
		border-radius: 10px;
		background-color: #00aa00;
		margin-right: 5px;
	}
	.id{
		font-family: Source Han Sans SC, Source Han Sans SC;
		font-weight: 400;
		font-size: 12px;
		margin-right: 10px;
		max-width: 130px;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.img1{
		background: url("../../../assets/image/market/icon_managebg1.png");
	}
	.img2{
		background: url("../../../assets/image/market/icon_managebg2.png");
	}
	.img3{
		background: url("../../../assets/image/market/icon_managebg3.png");
	}
	.img4{
		background: url("../../../assets/image/market/icon_managebg4.png");
	}
	.unitno{
		position: absolute;
		top: 10px;
		right: 10px;
		
	}
	@media screen and (max-width: 414px) {
		.page {
			padding: 20px 0;
		}

		.gzhlist {
			display: flex;
			flex-wrap: wrap;
			width: 300px
		}
		.btnbox {
			display: flex;
			justify-content: left;
			flex-wrap: wrap;
			width:340px;
		}
		.bitem{
			margin-bottom:20px;
		}
		.box-item {
			
			margin: 0 15px;
			height: 75px;
			width: 100px;
			color: #666;
			border: 1px solid #e3e8f0;
			border-radius: 4px;
		}
		
		.box-warp {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
		}

	}
</style>