import httpapi from '../../utils/httpapi';
const designTemplataApi = {
	// 指定长度和基数
	createToolBoxId() {
		let len = 16;
		let radix = 16;
		var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
		var uuid = [],
			i;
		radix = radix || chars.length;

		if (len) {
			// Compact form
			for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
		} else {
			// rfc4122, version 4 form
			var r;

			// rfc4122 requires these characters
			uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
			uuid[14] = '4';

			// Fill in random data.  At i==19 set the high bits of clock sequence as
			// per rfc4122, sec. 4.1.5
			for (i = 0; i < 36; i++) {
				if (!uuid[i]) {
					r = 0 | Math.random() * 16;
					uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
				}
			}
		}

		return uuid.join('');
	},
	getToolBox: function(params, funsucc) {
		httpapi.post('/isv/design/toolbox', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},

	getSysTemplate: function name(params, funsucc) {
		httpapi.post('/design/template/user/market', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	getUserTemplate: function name(params, funsucc) {
		httpapi.post('/isv/design/temp/list', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	getstyle: function(params, funsucc) {
		httpapi.post('/isv/design/main/style/tmpid', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},

	/**
	 * 获取用户模版内页面
	 * @param {*} params 
	 * @param {*} funsucc 
	 */
	getUserPageList: function(params, funsucc) {
		httpapi.post('/isv/design/temp/detail', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	getUserTemplateDetail: function name(params, funsucc) {
		httpapi.post('/design/template/user/page/detail', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	/**
	 * 注册模版
	 * @param {} params 
	 * @param {*} funsucc 
	 */
	regUserTemplate: function(params, funsucc) {
		httpapi.post('/design/template/user/reg', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	getDesign: function(tmpid, pageno, funsucc) {
		httpapi.post('/isv/design/page/getdesign', {
			tmpid: tmpid,
			pagetype: pageno
		}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	getDetail: function(param, funsucc) {
		httpapi.post('/isv/design/temp/detail',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	saveDesign: function(id, json, funsucc) {
		httpapi.post('/isv/design/page/setdesign', {
			pageid: id,
			json: encodeURIComponent(JSON.stringify(json))
		}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	editname: function(param, funsucc) {
		httpapi.post('/isv/design/temp/editname', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	edittabbar: function(param, funsucc) {
		httpapi.post('/isv/design/temp/editfooter', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},

	editlogo: function(param, funsucc) {
		httpapi.post('/isv/design/temp/editlogo', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	iconlist: function(funsucc) {
		httpapi.post('/system/design/config/component/list').then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	icondel: function(funsucc) {
		httpapi.post('/design/template/base/component/del').then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	iconadd: function(param, funsucc) {
		httpapi.post('/design/template/base/component/add', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	iconedit: function(param, funsucc) {
		httpapi.post('/design/template/base/component/edit', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	iconsave: function(param, funsucc) {
		httpapi.post('/system/design/config/component/editicon', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	mchsave: function(param, funsucc) {
		httpapi.post('/design/template/base/component/editshop', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//创建模板
	tempcreate(params, funsucc) {
		httpapi.post('/isv/design/temp/create', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//拷贝模板
	tempcopy(params, funsucc) {
		httpapi.post('/isv/design/temp/copy', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//上架模板
	tempup(params, funsucc) {
		httpapi.post('/isv/design/temp/online/up', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//下架模板
	tempdown(params, funsucc) {
		httpapi.post('/isv/design/temp/online/down', params).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default designTemplataApi