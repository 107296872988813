<template>
	<div class="content" :style="{background:bgcolor,'height':height}">

		<!-- <div class="shopbg" v-if="list.data && list.data.headimg" :style="{
			'height':list.data.headimg.height+ 'px',
		}">
			<img :src="list.data.headimg.url">
		</div> -->
		<div class="shopbg" v-if="list.data && list.data.headimg" :style="{
			'height':list.data.headimg.height+ 'px',
		}">
			<img :src="list.data.headimg.url">
		</div>
		<dragBox v-if="list.data && list.data.logo " :allowdel="0" :data="list.data.logo" :dragkey="'logo'"  @selectHandler="selectHandler">
			<div :style="{
			'overflow': 'hidden',
			'width':list.data.logo.width+ 'px',
			'height':list.data.logo.height+ 'px',
			'border-radius':list.data.logo.borderradius + 'px'
		}">
				<img style="width: 100%;height: 100%;" :src="logourl" />
			</div>
		</dragBox>

		<dragBox v-if="list.data && list.data.shopname" :allowdel="0" :data="list.data.shopname" :dragkey="'shopname'"  @selectHandler="selectHandler">
			<div class="shopname" :style="{
			'width':list.data.shopname.width+ 'px',
			'height':list.data.shopname.height+ 'px',
			'line-height':list.data.shopname.height+ 'px',
			'color':list.data.shopname.titlecolor,
			'font-size':getsize(list.data.shopname.size),
			'font-weight':list.data.shopname.bold == 1 ? 700 : 300,
			'text-align':list.data.shopname.center == 1 ? 'center' : '' 
		}">
				{{list.data.shopname.title}}
			</div>

		</dragBox>
		<dragBox v-if="list.data && list.data.deskname && list.data.deskname.isflag" :allowdel="0" :data="list.data.deskname" :dragkey="'deskname'"  @selectHandler="selectHandler">
			<div class="shopname" :style="{
			'width':list.data.deskname.width+ 'px',
			'height':list.data.deskname.height+ 'px',
			'line-height':list.data.deskname.height+ 'px',
			'color':list.data.deskname.titlecolor,
			'font-size':list.data.deskname.size > 10 ? list.data.deskname.size +'px': (list.data.deskname.size == 3 ? '12px' :(list.data.deskname.size == 2 ? '14px':'16px')),
			'font-weight':list.data.deskname.bold == 1 ? 700 : 300,
			'text-align':list.data.deskname.center == 1 ? 'center' : '' 
		}">
				{{list.data.deskname.title}}
			</div>

		</dragBox>

		<dragBox v-if="list.data && list.data.notice" :allowdel="0" :data="list.data.notice" :dragkey="'notice'"  @selectHandler="selectHandler">
			<div class=" notice" v-if="list.data && list.data.notice" :style="{
			'width':list.data.notice.width+ 'px',
			'height':list.data.notice.height+ 'px',
			'color':list.data.notice.titlecolor,
			'font-size':list.data.notice.size > 10 ? list.data.notice.size +'px': (list.data.notice.size == 1 ? '12px' :(list.data.notice.size == 2 ? '14px':'16px')),
			'font-weight':list.data.notice.bold == 1 ? 700 : 300,
			'text-align':list.data.notice.center == 1 ? 'center' : ''
		}">
				{{list.data.notice.title}}
			</div>
		</dragBox>
		<dragBox v-if="list.data && list.data.phone && list.data.phone.isflag" :allowdel="0" :data="list.data.phone" :dragkey="'phone'"  @selectHandler="selectHandler">
			<div :style="{
				'overflow': 'hidden',
				'width':list.data.phone.width+ 'px',
				'height':list.data.phone.height+ 'px',
				'border-radius':list.data.phone.borderradius + 'px'
			}">
				<img style="width: 100%;height: 100%;" :src="phoneurl" />
			</div>
		</dragBox>
		<dragBox v-if="list.data && list.data.map && list.data.map.isflag" :allowdel="0" :data="list.data.map" :dragkey="'map'"  @selectHandler="selectHandler">
			<div :style="{
			'overflow': 'hidden',
			'width':list.data.map.width+ 'px',
			'height':list.data.map.height+ 'px',
			'border-radius':list.data.map.borderradius + 'px'
		}">
				<img style="width: 100%;height: 100%;" :src="mapurl" />
			</div>
		</dragBox>
		<dragBox v-if="list.data && list.data.more && list.data.more.isflag" :allowdel="0" :data="list.data.more" :dragkey="'more'"  @selectHandler="selectHandler">
			<div class=" notice" v-if="list.data && list.data.more " :style="{
			'width':list.data.more.width+ 'px',
			'height':list.data.more.height+ 'px',
			'color':list.data.more.titlecolor,
			'font-size':list.data.more.size > 10 ? list.data.more.size +'px': (list.data.more.size == 1 ? '12px' :(list.data.more.size == 2 ? '14px':'16px')),
			'font-weight':list.data.more.bold == 1 ? 700 : 300,
			'text-align':list.data.more.center == 1 ? 'center' : ''
		}">
				{{list.data.more.title}}
			</div>
		</dragBox>
		<dragBox v-if="list.data && list.data.linename && list.data.linename.isflag" :allowdel="0" :data="list.data.linename" :dragkey="'linename'"  @selectHandler="selectHandler">
			<div class=" notice" v-if="list.data && list.data.linename " :style="{
			'width':list.data.linename.width+ 'px',
			'height':list.data.linename.height+ 'px',
			'color':list.data.linename.titlecolor,
			'font-size':list.data.linename.size > 10 ? list.data.linename.size +'px': (list.data.linename.size == 1 ? '12px' :(list.data.linename.size == 2 ? '14px':'16px')),
			'font-weight':list.data.linename.bold == 1 ? 700 : 300,
			'text-align':list.data.linename.center == 1 ? 'center' : ''
		}">
				{{list.data.linename.title}}
			</div>
		</dragBox>
	</div>
</template>

<script>
	import dragBox from "@/components/applet/toolbox/dragbox.vue";
	export default {
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		components: {
			dragBox
		},


		data() {
			return {
				list: "",
				styleShow: -1,
			}
		},
		computed: {
			height() {
				if (this.list.options) {
					if (this.list.options.height && this.list.options.height > 0)
						return this.list.options.height + 'px';
				}
			},
			bgcolor() {
				if (this.list.options) {
					if (this.list.options.bgcolor && this.list.options.bgcolor.length > 0)
						return this.list.options.bgcolor;
				}
			},
			logourl() {
				if (this.list.data.logo) {
					if (this.list.data.logo.url && this.list.data.logo.url.length > 0)
						return this.list.data.logo.url;
				}
			},
			mapurl() {
				if (this.list.data.map) {
					if (this.list.data.map.url && this.list.data.map.url.length > 0)
						return this.list.data.map.url;
				}
			},
			phoneurl() {
				if (this.list.data.phone) {
					if (this.list.data.phone.url && this.list.data.phone.url.length > 0)
						return this.list.data.phone.url;
				}
			},
			bgimg() {
				if (this.list.options) {
					if (this.list.options.bgimg && this.list.options.bgimg.length > 0)
						return this.list.options.bgimg;
				}
			}
		},
		watch: {
			list(o, v) {
				
			}
		},
		mounted() {
			this.list = this.data;
			
		},
		methods: {
			getsize(size) {
				if (size == 1) {
					return '16px'
				} else if (size == 2) {
					return '14px'
				} else if (size == 3) {
					return '12px'
				} else {
					return size + 'px'
				}
			},
 selectHandler(param){
      this.$emit('selectItemHandler',{
        type:'shop_info_nav',
        data:param
      })
    },
		}
	}
</script>

<style scoped>
	.content {
		position: relative;
		overflow: hidden;
	}

	.absolute {
		position: absolute;
	}

	/*样式一开始*/
	.shopmsg {
		position: absolute;
		left: 10px;
		bottom: 15px;
		display: flex;
		z-index: 999;
	}

	.shopmsg .shopleft {
		width: 60px;
	}

	.shopmsg .shopleft .logo {
		width: 60px;
		height: 60px;
		background-color: #fff;
	}

	.shopmsg .shopleft .logo img {
		width: 100%;
		height: 100%;
	}

	.shopmsg .shopright {
		color: #fff;
		padding-left: 10px;
		flex-grow: 1;
		display: flex;
		flex-direction: column;
	}

	.shopmsg .shopright .title {
		font-size: 16px;
		font-weight: 800;
	}

	.shopmsg .shopright .memo {
		display: flex;
		justify-content: space-between;
	}

	.shopmsg .shopright .m1 {
		margin-top: 10px;
		background-color: #ffaaff;
	}

	.shopmsg .shopright .m2 {
		margin: 0 10px;
		margin-top: 10px;
	}

	.shopmsg .shopright .m3 {
		margin-top: 10px;
	}

	.shopbg {
		/* position: absolute;
		left: 0;
		top: 0; */
		width: 100%;
		overflow: hidden;
	}

	.shopbg img {
		width: 100%;
	}

	/*样式二开始*/
	.shopmsg2 {
		position: absolute;
		left: 10px;
		bottom: 15px;
		display: flex;
		z-index: 999;
	}

	.shopmsg2 .shopleft {}

	.shopmsg2 .shopleft .logo {
		width: 60px;
		height: 60px;
	}

	.shopmsg2 .shopleft .logo img {
		width: 100%;
		height: 100%;
	}

	.shopname {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

	}

	.notice {
		line-height: 1.5;
		overflow: hidden;
		text-overflow: ellipsis;
		/* white-space: nowrap; */
	}
</style>