var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img"},[_c('div',{staticClass:"imgcont",style:({
        'margin-left': _vm.marginpage,
        'margin-right': _vm.marginpage,
		'height' : _vm.height,
    'background':_vm.bgcolor
		
      })},[(
          _vm.list.options && _vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
        )?_c('div',[_c('img',{staticClass:"bgimg",attrs:{"src":_vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
              ? _vm.list.options.bgimg
              : ''}})]):_vm._e(),(_vm.list.data != undefined && _vm.list.data.length > 0)?_c('div',{staticClass:"list"},_vm._l((_vm.list.data),function(item,index){return _c('dragBox',{key:item.cid,attrs:{"data":item,"index":index},on:{"deleteHandle":_vm.deleteHandle,"selectHandler":_vm.selectHandler}},[(item.type === 'button')?_c('div',{staticClass:"item item-button",style:({
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              background: item.bgcolor,
              'line-height':(item.height-2) + 'px',
              'border-radius':item.borderradius + 'px'
            })},[(item.title && item.title.length > 0)?_c('span',{style:({
               color: item.titlecolor,
               'text-align':item.position == 1 ? 'left':'center',
               'font-weight':item.bold == 2 ? 700:300,
               'font-size':_vm.getsize(item.size)
            })},[_vm._v(_vm._s(item.title))]):_vm._e()]):(item.type === 'text')?_c('div',{staticClass:"item item-text",style:({
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              color: item.titlecolor,
              'text-align':item.position == 1 ? 'left':'center',
              'font-weight':item.bold == 2 ? 700:300,
              'line-height':(item.height-2) + 'px',
			  'font-size':_vm.getsize(item.size)
            })},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.type == 'image')?_c('img',{staticClass:"item",style:({
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              'border-radius':item.borderradius + 'px'
            }),attrs:{"src":item.url}}):_vm._e()])}),1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }