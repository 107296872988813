import httpapi from '../../utils/httpapi';
const wxappApi = {
	//微信小程序名称设置
	wxmcset(param, funsucc) {
		httpapi.post('/customer/wxapp/open/weixin/app/nickname/set', param).then(res => {
			console.log(res, 'send.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//微信小程序基本信息（微信端）
	baseinfofromweixin(param, funsucc) {
		httpapi.get('/customer/wxapp/open/weixin/app/baseinfofromweixin?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//按企业信息查询小程序申请状态
	compquerywxapp(param, funsucc) {
		httpapi.get('/customer/wxapp/open/weixin/app/comp/querywxapp?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//按企业信息申请小程序
	compregwxapp(param, funsucc) {
		httpapi.post('/customer/wxapp/open/weixin/app/comp/regwxapp', param).then(res => {
			console.log(res, 'compregwxapp.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//微信小程序访问域名查询
	wxappget(param, funsucc) {
		httpapi.get('/customer/wxapp/open/weixin/app/domain/get?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//微信小程序访问域名修改
	wxappset(param, funsucc) {
		httpapi.post('/customer/wxapp/open/weixin/app/domain/set', param).then(res => {
			console.log(res, 'wxappset.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//复用微信公众号查询小程序申请状态
	gzhquerywxapp(param, funsucc) {
		httpapi.get('/customer/wxapp/open/weixin/app/gzh/querywxapp?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//复用微信公众号创建小程序
	gzhregwxapp(param, funsucc) {
		httpapi.post('/customer/wxapp/open/weixin/app/gzh/regwxapp', param).then(res => {
			console.log(res, 'gzhregwxapp.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//微信小程序名称检查
	wxmccheck(param, funsucc) {
		httpapi.get('/customer/wxapp/open/weixin/app/nickname/check?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//微信小程序业务域名查询
	webviewget(param, funsucc) {
		httpapi.get('/customer/wxapp/open/weixin/app/webview/get?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//微信小程序业务域名修改
	webviewset(param, funsucc) {
		httpapi.post('/customer/wxapp/open/weixin/app/webview/set', param).then(res => {
			console.log(res, 'webviewset.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//微信小程序logo修改
	webwxlogoset(param, funsucc) {
		httpapi.post('/customer/wxapp/open/weixin/app/logo/set', param).then(res => {
			console.log(res, 'webwxlogoset.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	//微信小程序功能介绍设置
	webwxmemoset(param, funsucc) {
		httpapi.post('/customer/wxapp/open/weixin/app/memo/set', param).then(res => {
			console.log(res, 'webwxmemoset.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	wxclslist(param,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/class/find?mpid=' + param.mpid).then(res => {
			console.log(res, 'webwxmemoset.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	creatshortlink(param,funsucc){
		httpapi.post('/customer/wxapp/open/weixin/wxa/shortlink/create',param).then(res => {
			
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	}
}
export default wxappApi