<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		
		<el-tabs v-model="activeName" type="card">

			<el-tab-pane label="数据" name="first">
				<div style="display: flex;">
					<div style="display: flex;flex-direction: column;">
						<div class="zujian-item">
							<el-dropdown @command="addItem" size="medium" placement="bottom-end"
								trigger="click">
								<span class="el-dropdown-link">
									+
								</span>
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item command="image">图片</el-dropdown-item>
									<el-dropdown-item command="text">文字</el-dropdown-item>
									<el-dropdown-item command="button">按钮</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
						<vuedraggable v-model="list.data" tag="div" draggable=".item"
							v-if="list.data && list.data.length > 0" class="image-list"
							:class="{ disable: data.tabType == 2 }">
							<div class="item" :class="index === selindex? 'sel':'normal'" v-for="(item, index) in list.data"
								:key="index" @click="selitem(item,index)">
						
								<span class="label"
									:class="'label'+item.type">{{item.type == 'image' ? '图片' : (item.type == 'button'? '按钮':'文字')}}</span>
								<span @click="removeImage(index)" class="el-icon-close"></span>
								<span @click="funcopy(item)" class="el-icon-plus"></span>
								<img :src="item.url" v-if="item.type == 'image'" />
								<div style="margin-top: 20px;" v-else>
									{{item.title}}
								</div>
							</div>
						</vuedraggable>
					</div>
					<ul v-if="selindex > -1">
						<li>
							<!-- 图片开始 -->
							<div class="l-info" v-if="item.type === 'image'">
								<p>
									<span>图片：</span>
									<span class="text"><el-input type="text" size="small" v-model="item.url"
											style="width: 200px;margin: 10px 0;"></el-input> <el-button type="primary"
											size="mini" @click="addImage(selindex)">上传</el-button></span>

								</p>
								<p style="margin: 20px ;">
									<span>链接：</span>
									<span class="text">{{ item && item.link && item.link.name }}</span>
									<el-button size="mini" @click="urlPopup(selindex, item.link)">选择链接</el-button>
								</p>
								<!-- 圆角 -->
								<div class="list">
									<div class="title">
										<span>圆角</span>
									</div>
									<div class="btn2 full">
										<el-slider v-model="item.borderradius"  show-input :max="800">
										</el-slider>
									</div>
								</div>


								<div class="more" @click="funMore">
									{{moreShow == true ? '隐藏' : '更多'}}
								</div>
								<div v-if="moreShow">
									<div class="list">
										<div class="title">
											<span>左</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.left"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>上</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.top"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>宽</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.width"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>高</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.height"></el-input>
										</div>
									</div>
								</div>

							</div>
							<!-- 图片结束 -->

							<!-- 文字开始 -->
							<div class="l-info" v-if="item.type === 'text'">
								<div>标题:</div>
								<p>
									<span class="text"><el-input type="text" v-model="item.title" size="small"
											style="width: 200px;"></el-input></span>
								</p>
								<div class="row">
									<el-select v-model="item.size" filterable allow-create placeholder="请选择标题大小"
										size="small">
										<el-option v-for="item in fontlist" :key="item" :label="item" :value="item">
										</el-option>
									</el-select>
									<el-tooltip effect="dark" content="加粗" placement="top">
										<div class="bbox" style="margin-left: 10px;">
											<img class="blodimg" src="../../../../../assets/image/icon_b1.png" v-if="item.bold && item.bold == 2" @click="funchu(item,1)"></img>
											<img class="blodimg" src="../../../../../assets/image/icon_b0.png" v-else @click="funchu(item,2)" /></img>
										</div>
									</el-tooltip>
									<el-tooltip effect="dark" content="居中" placement="top">
										<div class="bbox">
											<img class="blodimg" src="../../../../../assets/c1.png" v-if="item.position && item.position == 2" @click="funcenter(item,1)"></img>
											<img class="blodimg" src="../../../../../assets/c0.png" v-else @click="funcenter(item,2)" /></img>
										</div>
									</el-tooltip>
									<el-tooltip effect="dark" content="标题颜色" placement="top">
										<el-color-picker v-model="item.titlecolor" size="medium"></el-color-picker>
									</el-tooltip>
								</div>


								<p style="margin: 20px ;">
									<span>链接：</span>
									<span class="text">{{ item && item.link && item.link.name }}</span>
									<el-button size="mini" @click="urlPopup(selindex, item.link)">选择链接</el-button>
								</p>
								

								<div class="more" @click="funMore">
									{{moreShow == true ? '隐藏' : '更多'}}
								</div>
								<div v-if="moreShow">
									<div class="list">
										<div class="title">
											<span>左</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.left"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>上</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.top"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>宽</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.width"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>高</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.height"></el-input>
										</div>
									</div>
								</div>

							</div>
							<!-- 文字结束 -->

							<!-- 按钮开始 -->
							<div class="l-info" v-if="item.type === 'button'">
								<div>按钮:</div>
								<p>
									
									<span class="text"><el-input type="text" v-model="item.title" size="small"
											style="width: 200px;"></el-input></span>
								</p>
								
								<div class="row">
									<el-select v-model="item.size" filterable allow-create placeholder="请选择标题大小"
										size="small">
										<el-option v-for="item in fontlist" :key="item" :label="item" :value="item">
										</el-option>
									</el-select>
									<el-tooltip effect="dark" content="加粗" placement="top">
										<div class="bbox" style="margin-left: 10px;">
											<img class="blodimg" src="../../../../../assets/image/icon_b1.png" v-if="item.bold && item.bold == 2" @click="funchu(item,1)"></img>
											<img class="blodimg" src="../../../../../assets/image/icon_b0.png" v-else @click="funchu(item,2)" /></img>
										</div>
									</el-tooltip>
									
									<el-tooltip effect="dark" content="字体色" placement="top">
										<el-color-picker v-model="item.titlecolor" size="medium"></el-color-picker>
									</el-tooltip>
									<el-tooltip effect="dark" content="背景色" placement="top">
										<el-color-picker v-model="item.bgcolor" size="medium"></el-color-picker>
									</el-tooltip>
									
								</div>
								<p style="margin: 20px ;">
									<span>链接：</span>
									<span class="text">{{ item && item.link && item.link.name }}</span>
									<el-button size="mini" @click="urlPopup(selindex, item.link)">选择链接</el-button>
								</p>
								<!-- 圆角 -->
								<div class="list">
									<div class="title">
										<span>圆角</span>
									</div>
									<div class="btn2 full">
										<el-slider v-model="item.borderradius" show-input :max="800">
										</el-slider>
									</div>
								</div>
								<div class="more" @click="funMore">
									{{moreShow == true ? '隐藏' : '更多'}}
								</div>
								<div v-if="moreShow">
									<div class="list">
										<div class="title">
											<span>左</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.left"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>上</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.top"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>宽</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.width"></el-input>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>高</span>
										</div>
										<div class="btn2 full">
											<el-input type="number" size="small" v-model="item.height"></el-input>
										</div>
									</div>
								</div>

							</div>
							<!-- 按钮结束 -->
						</li>
					</ul>
				</div>




			</el-tab-pane>
			
			<el-tab-pane label="样式" name="style">
				<!-- 背景颜色 -->
				<div class="list">
					<div class="title">
						<span>背景颜色</span>
					</div>
					<div class="center">{{ list.options.bgcolor||'默认' }}</div>
					<div class="btn" style="display: flex;align-items: center;">
						<el-button type="text" class="reset" @click="reset('bgcolor')">重置</el-button><el-color-picker
							v-model="list.options.bgcolor" size="medium"></el-color-picker>
					</div>
				</div>
				<!-- 背景图 -->
				<div class="titles">
					<span>背景图 </span>
					<div style="display: flex;">
						<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button>
						<!-- <el-button type="primary" size="mini" >上传</el-button> -->
						<div class="upbtn" @click="funaddbgimg">上传</div>
					</div>
				</div>
				<div class="list">
					<div class="btn2 full">
						<img :src="list.options.bgimg" />
					</div>
				</div>
				<!--属性设置-->
				<div class="details">
					<div class="list">
						<div class="title">
							<span>高</span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.height" show-input :max="5000">
							</el-slider>
						</div>
					</div>
					<!-- 圆角 -->
					<div class="list">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="800">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>上边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list">
						<div class="title">
							<span>下边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="200">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginpage" show-input :max="200">
							</el-slider>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import sellink from "@/components/designer/sellink"
	import vuedraggable from "vuedraggable";
	import selImgs from "@/components/designer/selImgs";
	export default {
		name: "image_docker_edit",
		components: {
			sellink,
			vuedraggable,
			selImgs,
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				fontlist: [
					10, 11, 12, 14, 16, 18, 20, 24, 32, 36, 40, 48, 64, 96, 128
				],
				list: {},
				item: {},
				url: "",
				index: null,
				show: false,
				imageIndex: null,
				activeName: "first",
				selindex: -1,
				moreShow: false
			};
		},
		computed: {
			
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;
			
		},
		methods: {
			selectItemHandler(param) {
				if (this.list.data && this.list.data.length > param.index) {
					this.item = this.list.data[param.index];
					this.selindex = param.index
				}

			},
			funchu(item, bold) {
				item.bold = bold
				
			},
			funcenter(item, center) {
				item.position = center
				
			},
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				this.item = item;
				this.selindex = index
			},
			funMore() {
				this.moreShow = !this.moreShow
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index, link) {
				this.$refs['sellink'].funShow()
			},
			removeImage(index) {
				this.$confirm('确认删除吗, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					this.list.data.splice(index, 1);
				}).catch(() => {});

			},

			funaddbgimg() {
				this.imageIndex = "bgimg";
				this.$refs["selImgs"].funShow();
			},
			addImage(index) {
				this.imageIndex = index;
				this.$refs["selImgs"].funShow();
				//this.$refs["upload"].$children[0].$refs.input.click();
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			// 指定长度和基数
			uuid2(len, radix) {
				var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
				var uuid = [],
					i;
				radix = radix || chars.length;

				if (len) {
					// Compact form
					for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
				} else {
					// rfc4122, version 4 form
					var r;

					// rfc4122 requires these characters
					uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
					uuid[14] = '4';

					// Fill in random data.  At i==19 set the high bits of clock sequence as
					// per rfc4122, sec. 4.1.5
					for (i = 0; i < 36; i++) {
						if (!uuid[i]) {
							r = 0 | Math.random() * 16;
							uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
						}
					}
				}

				return uuid.join('');
			},

			funcopy(item) {
				
				if (item != undefined && item.cid != undefined) {
					const data = {
						cid: this.uuid2(16, 16),
						type: item.type,
						left: item.left,
						top: item.top,
						width: item.width,
						height: item.height,
						title: item.title, //文字
						url: item.url, //图片地址
						bgcolor: item.bgcolor, //背景色
						titlecolor: item.titlecolor, //标题色
						position: item.position,
						bold: item.bold,
						size: item.size,
						borderradius: item.borderradius
					};
					this.list.data.push(data);
				} else {
					this.$message.error("未选中")
				}

			},

			addItem(type) {
				const len = this.list.data.length;
				const col = 3;
				const row = parseInt(len / 5);
				const row_num = len % 3;

				let left = row_num * 75;
				let top = 100 * row;
				if (left < 0) {
					left = 0;
				}
				if (top < 0) {
					top = 0;
				}
				let width = 100;
				let height = 100;
				let url = ''
				let title = '';
				let borderradius = 0;
				let bgcolor = '#F7420B'
				let color = '#FFFFFF'
				switch (type) {
					case 'image':
						url =
							'http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/169241699017262694620230911073baca94a48ff8387f775ae0cfdc3.png?key=123456'
						break;
					case 'button':
						width = 100;
						height = 40;
						title = '按钮'
						borderradius = 10;
						bgcolor = '#F7420B'
						color = '#FFFFFF'
						break;
					case 'text':
						width = 100;
						height = 30;
						title = '文字'
						bgcolor = '#FFFFFF'
						color = '#000000'
						borderradius = 0;
						break;
					default:
						return;
				}
				
				const data = {
					cid: this.uuid2(16, 16),
					type: type,
					left: left,
					top: top,
					width: width,
					height: height,
					title: title, //文字
					url: url, //图片地址
					bgcolor: bgcolor, //背景色
					titlecolor: color, //标题色
					position: 2,
					bold: 1,
					size: 1,
					borderradius: borderradius,
				};
				this.list.data.push(data);

				
			},
			sellinkresult(param) {
				
				const index = this.selindex;
				if (index !== null) {
					let data = this.list.data[index];
					data.link = param;
					
					this.$set(this.list.data, index, data);
				}
			},
			selImgsresult(imgitem) {
				
				if (this.imageIndex === "bgimg") {
					//this.list.options.bgimg = imgitem.url;
					this.$set(this.list.options, 'bgimg', imgitem.url);
				} else {
					if (this.imageIndex !== null) {
						let myitem = this.list.data[this.imageIndex]
						myitem.url = imgitem.url;
						this.$set(this.list.data, this.imageIndex, myitem);
					}
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: calc(100% - 20px);
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin-left: 10px;
			margin-top: 10px;
		}

		.l-info {
			float: left;
			font-size: 12px;
			padding-top: 8px;
			flex-grow: 1;
			

			p {
				margin: 12px 12px 0;
				white-space: nowrap;
				overflow: hidden;
				display: flex;
				align-items: center;

				.link {
					color: #1b8bff;
					cursor: pointer;
				}

				.text {
					white-space: nowrap;
					text-align: -webkit-auto;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}

		.image-list {
			margin: 0;
			float: left;
			width: 100px;
			flex-shrink: 0;
			height: calc(100vh - 300px);
			overflow-y: auto;

			.item {
				width: 80px;
				height: 50px;
				overflow: hidden;
				margin: 2px;
				position: relative;

				.label {
					color: #fff;
					font-size: 10px;
					border-radius: 5px;
					background-color: #1b8bff;
					position: absolute;
					left: 0;
					padding: 2px;
				}

				.labelimage {
					background-color: #00aaff;
				}

				.labelbutton {
					background-color: #55aa7f;
				}

				.labeltext {
					background-color: #ffaaff;
				}

				.el-icon-close {
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;
				}

				.el-icon-plus {
					position: absolute;
					right: 0px;
					bottom: 0;
					cursor: pointer;
				}

				img {
					width: 100%;
				}
			}

			.normal {
				border: 1px solid #ccc;
			}

			.sel {
				border: 1px solid #1b8bff;
			}

			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			li {
				list-style: none;
				border-radius: 4px;
				padding: 6px 14px 10px;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;

				&.disable div {
					pointer-events: none;
					user-select: none;
				}



				.r-image {
					text-align: right;

					.el-icon-close {
						color: #999;
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 6px;
						cursor: pointer;

						&:hover {
							color: red;
						}
					}

					.image-box {
						width: 70px;
						height: 70px;
						border-radius: 5px;
						overflow: hidden;
						position: relative;
						background: #fff;
						display: flex;
						justify-content: center;
						align-items: center;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.5);
							opacity: 0;
							transition: all 0.3s;
						}

						span {
							position: absolute;
							top: 50%;
							left: 50%;
							color: #fff;
							transform: translate(-50%, -50%);
							font-size: 20px;
							cursor: pointer;
							opacity: 0;
							transition: all 0.3s;
						}

						img {
							max-width: 100%;
						}

						&:hover {

							&::before,
							span {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.list {
			display: flex;
			align-items: center;
			margin: 20px;
			justify-content: space-between;

			.title {
				margin-right: 10px;
				flex-shrink: 0;
				width: 60px;
			}

			.full {
				flex-grow: 1;

				img {
					width: 100%;
				}
			}

			.btn {
				display: flex;
				align-items: center;
			}
		}
	}

	.more {
		margin-left: 20px;
		padding-bottom: 20px;
		cursor: pointer;
		color: #00aaff;
	}

	.titles {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 0 20px;
	}

	p {
		margin: 0 10px;
	}

	.el-slider {
		width: 300px !important;
	}

	.center {
		color: #969799;
	}

	.reset {
		color: #FF6060;
	}

	.upbtn {
		color: #00aaff;
		line-height: 30px;
		margin-left: 5px;
	}
	.bbox {
		cursor: pointer;
		margin-right: 5px;
		width: 32px;
		height: 30px;
		border-radius: 4px;
		background-color: #DEDFE1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	
	.blodimg {
		width: 20px;
		height: 20px;
	}
	.row{
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 12px 12px 0 12px;
	}
	.zujian-item {
		cursor: pointer;
		margin: 2px;
		width: 80px;
		    height: 30px;
		line-height: 30px;
		text-align: center;
		background-color: #409EFF;
	
		.el-dropdown-link {
			color: #fff;
			font-size: 20px;
			width: 60px;
			height: 30px;
			display: inline-block;
		}
	}
</style>