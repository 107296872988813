<template>
  <div>
    <div class="img">
      <el-image
        style="width: 100%"
        :src="item.url"
        v-for="(item, index) in list.data"
        :key="index"
        v-show="data.data &&data.data.length > 0"
      ></el-image>
      <div class="empty" v-show="data.data.length == 0">
        <el-empty description="此区域放置图片"></el-empty>
      </div>
      <!-- <el-image style="width: 100%" :src="url"></el-image> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      list: "",
    };
  },

  mounted() {
    this.list = this.data;
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.img {
  width: 400px;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}
.empty {
  width: 400px;
  // text-align: center;
  // display: flex;
  // flex-wrap: wrap;
}
</style>