<template>
	<div class="">
		<!-- 编辑界面 -->
		<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
			<el-form label-width="150px" ref="editForm" :model="editForm">
				<el-form-item label="小程序名称" prop="mpname">
					<el-input size="small" v-model="editForm.mpname" auto-complete="off"
						placeholder="请输入小程序名称"></el-input>
				</el-form-item>
				<el-form-item label="AES内容加密密钥" prop="aeskey">
					<el-input size="small" type="textarea" v-model="editForm.aeskey" auto-complete="off"
						placeholder="请输入AES内容加密密钥"></el-input>
				</el-form-item>
				<el-form-item label="应用id" prop="appID">
					<el-input size="small" v-model="editForm.appID" auto-complete="off"
						placeholder="请输入应用id"></el-input>
				</el-form-item>
				<el-form-item label="AppRsa私钥" prop="appRsaPriKey">
					<el-input size="small" type="textarea" v-model="editForm.appRsaPriKey" auto-complete="off"
						placeholder="请输入AppRsa私钥"></el-input>
				</el-form-item>
				
				<el-form-item label="AppRsa公钥" prop="appRsaPubKey">
					<el-input size="small" type="textarea" v-model="editForm.appRsaPubKey" auto-complete="off"
						placeholder="请输入AppRsa公钥"></el-input>
				</el-form-item>
				<el-form-item label="平台Rsa公钥" prop="platRsaPubKey">
					<el-input size="small" type="textarea" v-model="editForm.platRsaPubKey" auto-complete="off"
						placeholder="请输入平台Rsa公钥"></el-input>
				</el-form-item>
				<el-form-item label="签名方式" prop="signtype">
					<el-input size="small" v-model="editForm.signtype" auto-complete="off"
						placeholder="请输入签名方式"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='editFormVisible=false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title"
					@click="submitForm('editForm')">保存
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import accountApi from '../../../api/cus/account.js'
	export default {
		data() {
			return {
				title: '',
				loading: false,
				editForm: {
					"mpid": 0,
					"unitno": 0,
					"aeskey": "",
					"appID": "",
					"appRsaPriKey": "",
					"appRsaPubKey": "",
					"mpname": "",
					"platRsaPubKey": "",
					"signtype": ""
				},
				editFormVisible: false
			}
		},
		methods: {
			funShow(title) {
				this.title = title
				this.editFormVisible = true
			},
			submitForm() {
				let param = {
					"mpid": this.editForm.mpid,
					"unitno": this.editForm.unitno,
					"aeskey": this.editForm.aeskey,
					"appID": this.editForm.appID,
					"appRsaPriKey": this.editForm.appRsaPriKey,
					"appRsaPubKey": this.editForm.appRsaPubKey,
					"mpname": this.editForm.mpname,
					"platRsaPubKey": this.editForm.platRsaPubKey,
					"signtype": this.editForm.signtype
				}
				console.log(param, 'addzfbapp.param');
				accountApi.addzfbapp(param, res => {
					console.log(res, 'addzfbapp.res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
</style>