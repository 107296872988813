<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="数据" name="first">
				
				<vuedraggable v-model="list.data" tag="div" draggable=".item" v-if="list.data && list.data.length > 0"
					class="image-list" :class="{ disable: data.tabType == 2 }">
					<div v-for="(item, index) in list.data" :key="index" @click="selitem(item,index)" class="item"
						:class="index === imageIndex? 'sel':'normal'">
						<img :src="item.url" style="width: 100%;height: 100%;"/>
						<div>{{ item && item.name }}</div>
						<div class="r-image">
							<span @click="removeImage(imageIndex)" class="el-icon-close"></span>
							<div class="image-box">
								<span @click="addImage(imageIndex)" class="el-icon-edit-outline"></span>
							</div>
						</div>
					</div>
				</vuedraggable>
				<template v-if="list.data && list.data.length < len">
					<el-button type="primary" icon="el-icon-plus" @click="addImage(null)" class="add-image">
						添加图片({{ data.data.length }}/10)
					</el-button>
					<p class="size">（建议尺寸：{{ size }}）添加图片 (最多{{ len }}张，可拖动排序）</p>
				</template>
				<div class="l-info" v-if="imageIndex > -1">
					<p>
						<span>名称：</span>
						<span class="text">{{ item && item.name }}</span>
					</p>
					<p>
						<span>图片链接：</span>
						<el-input v-model="item.url" size="small"></el-input>
						<el-button type="primary" size="small" @click="addImage(imageIndex)">上传</el-button>
					</p>
					<p style="margin: 20px ;">
						<span>链接：</span>
						<span class="text">{{ item && item.link && item.link.name }}</span>
						<el-button size="small" @click="urlPopup(imageIndex, item.link)">选择链接</el-button>
					</p>
					
				</div>

			</el-tab-pane>
			<!-- <el-tab-pane label="设置" name="set">
				<div class="list">
					<div class="title"><span>数据来源</span> </div>
					<div>
						<el-radio-group v-model=" list.options.source.type">
							<el-tooltip class="item" effect="dark" content="常规" placement="top-start">
								<el-radio-button label="static">常规</el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="动态" placement="top-start">
								<el-radio-button label="auto">动态</el-radio-button>
							</el-tooltip>
						</el-radio-group>
					</div>
				</div>
				<div class="list">
					<div class="title"><span>数据编号</span> </div>
					<div>
						<el-input type="text" size="small" v-model="list.options.source.id"></el-input>
					</div>
				</div>
			</el-tab-pane> -->
			<el-tab-pane label="风格" name="style">
				<!-- <div class="list">
					<div class="title"><span>图片样式</span> <span>投影 </span></div>
					<div>
						<el-radio-group v-model="list.options.type" size="small">
							<el-tooltip class="item" effect="dark" content="常规" placement="top-start">
								<el-radio-button :label="1">常规</el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="投影" placement="top-start">
								<el-radio-button :label="2">投影</el-radio-button>
							</el-tooltip>
						</el-radio-group>
					</div>
				</div> -->
				<div class="list1">
					<div class="title"><span>高度</span></div>
					<div>
						<el-slider v-model="list.options.height" show-input :max="2000">
						</el-slider>
					</div>
				</div>
				<div class="list1">
					<div class="title"><span>页面边距</span></div>
					<div>
						<el-slider v-model="list.options.marginpage" show-input :max="30">
						</el-slider>
					</div>
				</div>
				<div class="list1">
					<div class="title"><span>上边距</span></div>
					<div>
						<el-slider v-model="list.options.margintop" show-input :max="30">
						</el-slider>
					</div>
				</div>
				<div class="list1">
					<div class="title"><span>下边距</span></div>
					<div>
						<el-slider v-model="list.options.marginbottom" show-input :max="30">
						</el-slider>
					</div>
				</div>
				<div class="list1">
					<div class="title"><span>圆角</span></div>
					<div>
						<el-slider v-model="list.options.borderradius" show-input :max="30">
						</el-slider>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import sellink from "@/components/designer/sellink"
	import selImgs from "@/components/designer/selImgs"
	import vuedraggable from "vuedraggable";
	export default {
		components: {
			sellink,
			selImgs,
			vuedraggable,
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				list: {},
				item: {},
				url: "",
				index: null,
				show: false,
				imageIndex: -1,
				radio1: "",
				activeName: 'first'
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;
			if(this.list.options.source == undefined){
				this.$set(this.list.options,'source', {
					id:'',
					type:'auto'
				})
				
			}
		},
		methods: {
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				this.item = item;
				this.imageIndex = index;
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index) {
				this.$refs['sellink'].funShow()
			},
			removeImage(index) {
				this.list.data.splice(index, 1);
			},
			addImage(index) {
				this.imageIndex = index;

				if (this.data.data) {
					if (this.data.data.length < 10) {
						this.$refs['selImgs'].funShow()
					}
				} else {
					this.$refs["upload"].$children[0].$refs.input.click();
				}

			},
			sellinkresult(param) {
				
				const index = this.imageIndex;
				if (index !== null) {
					let data = this.list.data[index];
					data.link = param;
					
					this.$set(this.list.data, index, data);
				}
			},
			selImgsresult(imgitem) {
				const index = this.imageIndex;
				const data = {
					name: imgitem.name,
					url: imgitem.url
				};

				if (index !== null) {
					this.$set(this.list.data, index, data);
				} else {
					this.list.data.push(data);
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 10px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 20px;
		}

		.add-image {
			width: calc(100% - 20px);
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin-left: 10px;
			margin-top: 10px;
		}

		.image-list {
			margin: 20px 0;
			padding: 0 10px;
			overflow: auto;
			display: flex;
			flex-wrap: wrap;

			.item {
				width: 80px;
				height: 80px;
				overflow: hidden;
				margin: 2px;
				position: relative;
				&:hover .r-image{
					background: rgba(0, 0, 0, 0.5);
				}
			}

			.normal {
				border: 1px solid #ccc;
			}

			.sel {
				border: 1px solid #1b8bff;
			}

			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}
		}
	}

	.l-info {
		border-top:1px solid #f0f0f0 ;
		margin: 10px;
		font-size: 12px;
		padding-top: 8px;
		width: 90%;

		p {
			margin: 12px 0 0;
			white-space: nowrap;
			overflow: hidden;
			display: flex;

			.link {
				color: #1b8bff;
				cursor: pointer;
			}

			.text {
				white-space: nowrap;
				text-align: -webkit-auto;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}
	}

	.list {
		margin: 20px 20px;
		margin-top: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.title {
			:first-child {
				color: #ccc;
				margin-right: 20px;
			}
		}
	}

	.list1 {
		margin: 20px 20px;
		margin-top: 0;
	}

	.r-image {
		text-align: right;
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 999;
		

		.el-icon-close {
			color: #999;
			font-size: 12px;
			font-weight: 600;
			margin-bottom: 6px;
			cursor: pointer;

			&:hover {
				color: red;
			}
		}

		.image-box {
			width: 100%;
			height: 100%;
			border-radius: 5px;
			overflow: hidden;
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: all 0.3s;
			}

			span {
				position: absolute;
				top: 50%;
				left: 50%;
				color: #fff;
				transform: translate(-50%, -50%);
				font-size: 20px;
				cursor: pointer;
				opacity: 0;
				transition: all 0.3s;
			}

			img {
				max-width: 100%;
			}

			&:hover {

				&::before,
				span {
					opacity: 1;
				}
			}
		}
	}
	p{
		display: flex;
		align-items: center;
	}
</style>