<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>企微查询&nbsp;<a href="https://open.work.weixin.qq.com/wwopen/developer#/sass/license/service/order/list" target="_blank">企微订单管理</a></span>
				<div class="" style="display: flex;justify-content: left;">
					<el-input type="text" placeholder="请输入配置编号" v-model="formInline.mpid" size="small"
						class="mr"></el-input>
					<el-input type="text" placeholder="请输入应用名称" v-model="formInline.mpname" size="small"
						class="mr"></el-input>
					<el-button size="small" type="primary" @click.stop="funSearch">搜索</el-button>
				</div>
			</div>

			<!--列表-->
			<el-table size="small" :data="list" highlight-current-row v-loading="loading" :border="false"
				element-loading-text="拼命加载中" style="width: 100%;">
				<el-table-column label="操作" width="150px">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="handEdit(scope.row)">查看</el-button>
						<el-button size="mini" type="danger" @click="handDel(scope.row)">删除</el-button>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="orderid" label="企微订单" width="200"></el-table-column>
				<el-table-column align="center" prop="billno" label="订单号" width="180"></el-table-column>
				<el-table-column align="center" prop="external_count" label="用户数" width="80"></el-table-column>
				<el-table-column align="center" prop="mpgid" label="企业ID" width="180"></el-table-column>
				<el-table-column align="center" prop="order_type" label="类型" width="60"></el-table-column>
				<el-table-column align="center" prop="pricesale" label="价格" width="80"></el-table-column>
				<el-table-column align="center" prop="buy_months" label="购买月数" width="80"></el-table-column>
				<el-table-column align="center" prop="kf_orderstate" label="状态" width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.kf_orderstate == 0">停用</span>
						<span v-else>启用</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="kf_createdate" label="创建时间" width="180"></el-table-column>

			</el-table>
			<el-dialog style="padding-bottom:20px;" :title="title" top="1%" :visible.sync="InfoVisible" width="50%"
				@click='InfoVisible=false'>
				<el-form label-width="150px" ref="InfoeditForm" :model="InfoeditForm">
					<el-form-item label="基础账号数" prop="base_count">
						{{account.base_count}}

					</el-form-item>
					
					<el-form-item label="订单状态" prop="base_count">
						{{InfoeditForm.kf_orderstate == 0 ? '待收款' : ''}}{{InfoeditForm.kf_orderstate == 2 ? '已收款' : ''}}
					</el-form-item>
					<el-form-item label="采购状态" prop="base_count">
						{{InfoeditForm.cg_orderstate == 0 ? '待确认' : ''}}{{InfoeditForm.cg_orderstate == 1? '待采购' : ''}}{{InfoeditForm.cg_orderstate == 2 ? '采购下单成功' : ''}}{{InfoeditForm.cg_orderstate == 3? '采购支付中' : ''}}{{InfoeditForm.cg_orderstate == 4? '采购支付成功' : ''}}
						{{InfoeditForm.cg_orderstate == 9? '采购支付失败' : ''}}{{InfoeditForm.cg_orderstate == 10? '取消采购' : ''}}{{InfoeditForm.cg_orderstate == 20? '申请退款中' : ''}}
						{{InfoeditForm.cg_orderstate == 21? '退款成功' : ''}}
						<el-button style="margin-left: 20px;" type="primary" v-if="InfoeditForm.cg_orderstate == 1"
							@click="funadd">录入采购单</el-button>
					</el-form-item>
					<el-form-item label="购买的月数" prop="buy_months">
						{{account.buy_months}}
					</el-form-item>
					<el-form-item label="互通账号数" prop="external_count">
						{{account.external_count}}
					</el-form-item>
					<el-form-item label="账号购买时长" prop="account_duration">
						{{account.account_duration}}
					</el-form-item>
					<el-form-item label="金额" prop="pricesale">
						{{InfoeditForm.pricesale}}
					</el-form-item>
					<el-form-item label="企微订单号" prop="billno">
						{{InfoeditForm.orderid}}
					</el-form-item>
					<el-form-item label="企业ID" prop="base_count">
						{{InfoeditForm.mpgid }}
					</el-form-item>
					<el-form-item label="单号" prop="billno">
						{{InfoeditForm.billno}}
					</el-form-item>
					<el-form-item label="下单时间" prop="kf_createdate">
						{{InfoeditForm.kf_createdate}}
					</el-form-item>
					<el-form-item label="备注" prop="memo">
						{{InfoeditForm.memo}}
					</el-form-item>
				</el-form>
				<div style="color: #ff0000;">{{errmsg}}</div>
				<el-steps :active="stepactive" align-center finish-status="success">
					<el-step title="商家支付"></el-step>
					<el-step title="向企微申请采购"></el-step>
					<el-step title="采购付款"></el-step>
					<el-step title="付款成功"></el-step>
					<el-step title="获取授权码"></el-step>
				</el-steps>
				
				<el-table size="small" :data="zhlist" highlight-current-row v-loading="loading" :border="false"
					element-loading-text="拼命加载中" height="300px" style="width: 100%;">
					<el-table-column align="center" prop="licenseNo" label="授权码" width="240">
					</el-table-column>
					<el-table-column align="center" prop="licenseType" label="授权类型" width="120">
						<template v-slot="scope">
							{{scope.row.licenseType == 1 ? '基础账号' : '微信互通'}}
						</template>
					</el-table-column>
					<el-table-column align="center" prop="licensestate" label="授权状态" width="120">
						<template v-slot="scope">
							{{scope.row.licensestate == 1 ? '已授权' : '未授权'}}
						</template>
					</el-table-column>
					<el-table-column align="center" prop="manMc" label="授权人" width="180">
					</el-table-column>
					<el-table-column align="center" prop="updateDate" label="更新时间" width="180">
					</el-table-column>
				</el-table>
				<div class="btnbox" style="margin-top: 20px;">
					<el-button size="medium" style="margin-right: 20px;" @click='InfoVisible=false'>关闭</el-button>
					<el-button size="medium" style="margin-left: 20px;" type="primary"
						v-if="InfoeditForm.kf_orderstate == 0" @click="funorderpayconfim">确认收款</el-button>
					<el-button size="medium" style="margin-left: 20px;" type="primary"
						v-else-if="InfoeditForm.cg_orderstate == 1" @click="funorder_buy_apply">向企微采购下单</el-button>
					<el-button size="medium" style="margin-left: 20px;" type="primary"
						v-else-if="InfoeditForm.cg_orderstate == 2 || InfoeditForm.cg_orderstate == 9" @click="funorder_buy_payshow">采购付款</el-button>
						
					<el-button size="medium" style="margin-left: 20px;" type="primary"
						v-else-if="InfoeditForm.cg_orderstate == 3 " @click="funorder_buy_payquery">查询服务商支付结果</el-button>
					<el-button size="medium" style="margin-left: 20px;" type="primary"
						v-if="InfoeditForm.cg_orderstate == 2 ||InfoeditForm.cg_orderstate == 9" @click="funcancelcgorder">取消采购订单</el-button>
						<el-button size="medium" style="margin-left: 20px;" type="primary"
							@click="funfluseorderdetail">刷新采购状态</el-button>
							<el-button size="medium" style="margin-left: 20px;" type="primary"
								v-if="InfoeditForm.cg_orderstate == 4" @click="fungetwxorderactive">获取企微授权码</el-button>
				</div>
			</el-dialog>
			<el-dialog style="padding-bottom:20px;height: 300px;" title="选择采购付款方式" top="5%" :visible.sync="showcgpayway"
				width="30%" @click='showcgpayway=false'>
				<div class="btnbox">
					<el-button type="primary" size="medium" style="margin-right: 20px;"
						@click="funorder_buy_payreq">使用服务商余额支付</el-button>
					<a :href="'https://open.work.weixin.qq.com/wwopen/developer#/sass/license/service/order/detail?orderid=' + InfoeditForm.orderid" target="_blank">
						<el-button type="primary" size="medium" style="margin-right: 20px;"
							>使用微信支付</el-button>
					</a>
				</div>
			</el-dialog>
			<el-dialog style="padding-bottom:20px;height: 300px;" title="录入采购单" top="5%" :visible.sync="showorderadd"
				width="30%" @click='showorderadd=false'>
				<el-input v-model="wxorderno" style="width: 400px;margin-bottom: 40px;" size="medium"
					placeholder="请输入企微订单号"></el-input>
				<div class="btnbox">
					<el-button type="primary" size="medium" style="margin-right: 20px;"
						@click="addcgorder">确认</el-button>
					<el-button style="margin-left: 20px;" size="medium" @click="showorderadd=false"></el-button>
				</div>
			</el-dialog>
			<!-- 分页组件 -->
			<!-- <Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather" ></Pagination> -->
		</el-card>
		<!-- 编辑界面 -->

		<Pagination v-bind:child-msg="pageparm" @callFather="callFather" style="float: right;"></Pagination>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue'
	import wxworkApi from '../../../../api/sys/wxwork.js'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "",
				loading: false,
				formInline: {
					mpid: '',
					billno: '',
					pageno: 1,
					pagesize: 10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},


				InfoVisible: false,
				list: [],
				InfoeditForm: {},
				zhlist: [],
				account: {},
				wxorderno: '',
				showorderadd: false,
				stepactive: 0,
				showcgpayway: false,
				errmsg:''
			}
		},
		mounted() {
			this.showcgpayway = false
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.formInline.mpid = mpid
			}
			let orderid = this.$route.query.orderid
			if (orderid != undefined) {
				this.formInline.billno = orderid
			}
			this.getlist(this.formInline)
		},
		methods: {
			getlist(page) {
				let param = {
					mpid: page.mpid,
					billno: page.billno,
					pageno: page.pageno,
					pagesize: page.pagesize
				}
				console.log(param, 'param.getlist');
				wxworkApi.orderlist(param, res => {
					console.log(res, 'res.getorderlist');
					if (res.code == 200) {
						this.list = res.data.list
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			handEdit(row) {
				this.__getdetail(row.billno, row.mpid)
			},
			__getdetail(billno, mpid) {
				this.title = "详情"
				this.InfoVisible = true
				let param = {
					mpid: mpid,
					billno: billno,
				}
				wxworkApi.orderdetail(param, res => {
					console.log(res, 'res.handleEdit');
					if (res.code == 200) {
						this.InfoeditForm = res.data
						this.zhlist = res.data.list
						this.account = res.data.account
						if (res.data.cg_orderstate == 1) {
							this.stepactive = 1
						} else if (res.data.cg_orderstate == 2) {
							this.stepactive = 2
						} else if (res.data.cg_orderstate == 3) {
							this.stepactive = 3
						} else if (res.data.cg_orderstate == 4) {
							this.stepactive = 4
						}else if (res.data.cg_orderstate == 5) {
							this.stepactive = 5
						}
					} else {
						this.errmsg = res.msg
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
				this.InfoVisible = true
			},
			handDel(row) {
				this.$confirm('是否删除该订单?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						billno: row.billno
					}
					wxworkApi.orderdel(param, res => {
						console.log(res, 'res.handDel');
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getlist(this.formInline)
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}).catch(() => {});
			},
			funSearch() {
				this.getlist(this.formInline)
			},
			clsEdit(item) {
				this.$router.push({
					path: "orderlist?mpid=" + item.mpid
				})
			},
			funadd() {
				this.wxorderno = ''
				this.showorderadd = true

			},
			addcgorder() {
				let param = {
					billcode: this.InfoeditForm.billno,
					wxorderno: this.wxorderno,
				}
				wxworkApi.cgorderadd(param, res => {
					console.log(res, 'res.handleEdit');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.showorderadd = false
						this.InfoVisible = false
						this.getlist(this.formInline)
					} else {
						this.errmsg = res.msg
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funcancelcgorder() {
				let param = {
					billcode: this.InfoeditForm.billno,
				}
				wxworkApi.cancelcgorder(param, res => {
					console.log(res, 'res.handleEdit');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})

						this.InfoVisible = false
						this.getlist(this.formInline)
					} else {
						this.errmsg = res.msg
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})

			},
			funorderpayconfim() {
				let param = {
					billcode: this.InfoeditForm.billno,
				}
				wxworkApi.orderpayconfim(param, res => {
					console.log(res, 'res.handleEdit');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})

						this.__getdetail(this.InfoeditForm.billno, this.InfoeditForm.mpid)
					} else {
						this.errmsg = res.msg
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funorder_buy_apply() {
				let param = {
					billcode: this.InfoeditForm.billno,
				}
				wxworkApi.orderbuyapply(param, res => {
					console.log(res, 'res.handleEdit');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})

						this.__getdetail(this.InfoeditForm.billno, this.InfoeditForm.mpid)
					} else {
						this.errmsg = res.msg
						this.$message({
							type: 'error',
							message:res.msg
						})
					}
				})

			},
			fungetwxorderactive(){
					let param = {
						billcode: this.InfoeditForm.billno,
					}
					wxworkApi.wxorderactive(param, res => {
						console.log(res, 'res.handleEdit');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg
							})
							this.__getdetail(this.InfoeditForm.billno, this.InfoeditForm.mpid)
						} else {
							this.errmsg = res.msg
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				
				
				
			},
			funorder_buy_payshow() {
				this.showcgpayway = true
			},
			funorder_buy_payreq() {
				let param = {
					billcode: this.InfoeditForm.billno,
				}
				wxworkApi.orderpayreq(param, res => {
					console.log(res, 'res.handleEdit');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.showcgpayway = false
						this.__getdetail(this.InfoeditForm.billno, this.InfoeditForm.mpid)
					} else {
						this.errmsg = res.msg
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})


			},
			funorder_buy_payreq2() {
				this.$confirm('请进入服务商企业微信->企业微信服务商助手查看', '提示', {
					confirmButtonText: '确定',
					type: 'warning',
					showCancelButton:false
				}).then(() => {
					window.location.href = 'https://open.work.weixin.qq.com/wwopen/developer#/sass/license/service/order/detail?orderid=' + this.InfoeditForm.orderid
				}).catch(() => {});

			},
			funfluseorderdetail(){
					let param = {
						billcode: this.InfoeditForm.billno,
					}
					wxworkApi.wxorderdetail(param, res => {
						console.log(res, 'res.handleEdit');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg
							})
							this.__getdetail(this.InfoeditForm.billno, this.InfoeditForm.mpid)
						} else {
							this.errmsg = res.msg
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				
				
				
			},
			funorder_buy_payquery() {
				let param = {
					billcode: this.InfoeditForm.billno,
				}
				wxworkApi.orderpayquery(param, res => {
					console.log(res, 'res.funorder_buy_payquery');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})

						this.__getdetail(this.InfoeditForm.billno, this.InfoeditForm.mpid)
					} else {
						this.errmsg = res.msg
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})


			},
			// 分页插件事件
			callFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.getlist(this.formInline)
			},
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main {
		height: 500px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		overflow-y: auto;
	}

	.main .box {
		border: 2px solid #000;
		border-radius: 10px;
		margin: 10px;
		padding: 10px;
		height: 375px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}

	.main .box .bg {
		display: none;
	}

	.main .box:hover .bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.6);
	}

	.main .box .img {
		width: 150px;
		height: auto;
	}

	.main .box .img img {
		width: 100%;
		height: 100%;
	}

	.search {
		padding-bottom: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 10px 20px !important;
	}

	.mr {
		margin: 0 10px;
	}

	.box-card {
		width: 1320px;

	}

	.btnbox {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 20px;
	}
</style>