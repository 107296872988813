
<template>
  <div class="img" >
    <div
      class="imgcont"
      :style="{
        'margin-left': marginpage,
        'margin-right': marginpage,
		'height' : height,
    'background':bgcolor
		
      }"
    >
      <div
        v-if="
          list.options && list.options.bgimg && list.options.bgimg.length > 0
        "
      >
        <img
          :src="
            list.options.bgimg && list.options.bgimg.length > 0
              ? list.options.bgimg
              : ''
          "
          class="bgimg"
        />
      </div>
      <div class="list" v-if="list.data != undefined && list.data.length > 0">
        <dragBox
          v-for="(item, index) in list.data"
          :key="item.cid"
          :data="item"
          :index="index"
          @deleteHandle="deleteHandle"
          @selectHandler="selectHandler"
        >
          <div class="item item-button"
            v-if="item.type === 'button'"
            :style="{
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              background: item.bgcolor,
              'line-height':(item.height-2) + 'px',
              'border-radius':item.borderradius + 'px'
            }" 
          >
		  <!--'font-size':item.size == 1 ? '16px':(item.size == 2? '14px':'12px')-->
            <span v-if="item.title && item.title.length > 0" :style="{
               color: item.titlecolor,
               'text-align':item.position == 1 ? 'left':'center',
               'font-weight':item.bold == 2 ? 700:300,
               'font-size':getsize(item.size)
            }">{{ item.title }}</span> 
			
        </div>
          <div  class="item item-text"
            v-else-if="item.type === 'text'"
            :style="{
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              color: item.titlecolor,
              'text-align':item.position == 1 ? 'left':'center',
              'font-weight':item.bold == 2 ? 700:300,
              'line-height':(item.height-2) + 'px',
			  'font-size':getsize(item.size)
            }"
          >
            {{ item.title }} 
          </div>
          <img  class="item"
            v-if="item.type == 'image'"
            :style="{
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              'border-radius':item.borderradius + 'px'
            }"
            :src="item.url"
          />
        </dragBox>
      </div>
    </div>
  </div>
</template>

<script>
import dragBox from "@/components/applet/toolbox/dragbox.vue";

export default {
  name: "image_docker",
  components: {
    dragBox
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },

   
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      list: "",
    };
  },

  mounted() {
    this.list = this.data;
  },
  computed: {
    bgcolor(){
      if (this.list.options) {
	  		if (this.list.options.bgcolor && this.list.options.bgcolor.length > 0)
	  			return this.list.options.bgcolor;
	  	}
    },
	  height(){
	  	if (this.list.options) {
	  		if (this.list.options.height && this.list.options.height > 0)
	  			return (this.list.options.height / 2) + 'px';
	  	}
	  },
    margintop() {
      if (this.list.options) {
        if (this.list.options.margintop && this.list.options.margintop > 0)
          return this.list.options.margintop + "px";
      }
    },
    marginbottom() {
      if (this.list.options) {
        if (
          this.list.options.marginbottom &&
          this.list.options.marginbottom > 0
        )
          return this.list.options.marginbottom + "px";
      }
    },
    marginpage() {
      if (this.list.options) {
        if (this.list.options.marginpage && this.list.options.marginpage > 0)
          return this.list.options.marginpage + "px";
      }
    },
    borderradius() {
      if (this.list.options) {
        if (
          this.list.options.borderradius &&
          this.list.options.borderradius > 0
        )
          return this.list.options.borderradius + "px";
      }
    },
	size(){
		
		if (this.list.data) {
		  if (
		    this.list.data.size &&
		    this.list.data.size > 0
		  ){
			 
			   return this.list.options.size + "px";
		  }
		}
	}
  },

  methods: {
	  getsize(size){
		  if(size == 1){
			   return '16px'
		  }
		  else  if(size == 2)
		  {
			   return '14px'
		  }
		  else if(size == 3){
			   return '12px'
		  }
		  else{
			  return size + 'px'
		  }
	  },
    selectHandler(param){
      this.$emit('selectItemHandler',{
        type:'image_docker',
        data:param
      })
    },
    deleteHandle(param) {
		let index = param.index
      this.$delete(this.list.data,index);
      //this.list.data.splice(index,1);
      //this.$forceUpdate(this.list.data)
      //this.$set(this.list,'data',this.list.data)
     
    },
  },
};
</script>

<style lang="less" scoped>
.img {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  min-height: 20px;

  .imgcont {
    width: 100%;
    position: relative;
    overflow: hidden;
  }

  .bgimg {
    width: 100%;
  }
  .list {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;

    .item{
      border: 1px dashed #ccc;
    }
    .item-button,.item-text{
      line-height: 2;
    }
  }
}
.empty {
  width: 400px;
  // text-align: center;
  // display: flex;
  // flex-wrap: wrap;
}
</style>