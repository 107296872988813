<template>
	<div>
		<!--选择图片-->
		<el-dialog title="礼券列表"  :visible.sync="ImgVisible" top="3%" width="1200px" :before-close="handleClose"
			:append-to-body="true">
			<div class="list">
				<div class="item" v-for="(item,index) in ticketlist" :key="index" @click="funselticket(item)">
					<img :src="item.logo" class="timg" alt=""/>
					<div class="right">
						<div class="title">{{item.title}}</div>
						<div style="display: flex;align-items: center;margin: 5px 0;">
							<div style="flex-grow: 1;">{{item.title2}}</div>
							<div class="money">￥{{item.pricesale}}</div>
						</div>
						
						<div class="memo">{{item.memo}}</div>
						<div class="memo" style="margin-top: 5px;">有效期：{{item.datestart}} ~ {{item.dateend}}</div>
					</div>
				</div>
			</div>
		</el-dialog>
		
	</div>
</template>

<script>
	
	import uploadImg from '@/components/customer/uploadImg.vue'
	import Pagination from '@/components/Pagination.vue'
	import deskqrcodeApi from '../../api/cus/deskqrcode.js'
	export default {
		components: {
			uploadImg,
			Pagination
		},
		data() {
			return {
				ImgVisible: false,
				ticketlist:[]
			}
		},
		methods: {
			funshow(options){
				if(options != undefined){
					this.ImgVisible = true
					this.getticketlist(options)
				}
				
			},
			getticketlist(options){
				let param = {
					mpid: options.mpid,
					shopid:options.shopid
				}
				console.log(param,'param');
				deskqrcodeApi.ticketlist(param, res => {
					console.log(res, 'ticket');
					if (res.code == 200) {
						this.ticketlist = res.data.rows
					}
				})
			},
			funselticket(item){
				this.$emit('ticketresult', {
					item:item
				})
				this.handleClose()
			},
			handleClose(){
				this.ImgVisible = false
			}
		}
	}
</script>

<style  scoped>
	.list{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 700px;
		overflow: auto;
		padding: 20px 0;
		
	}
	.list .item{
		border: 1px solid #e0e0e0;
		border-radius: 20px;
		width: 80%;
		margin: 10px auto;
		overflow: hidden;
		flex-shrink: 0;
		display: flex;
		align-items: center;
	}
	.list .item .timg{
		width:100px;
		height: 100px;
		margin-right: 20px;
	}
	.list .item .right{
		flex-grow: 2;
		padding: 10px 0;
	}
	.list .item .title{
		font-size: 15px;
		font-weight: 600;
		
	}
	.list .item .memo{
		font-size: 9px;
	}
	.list .item  .money{
		margin-right: 20px;
		font-weight: 600;
	}
</style>