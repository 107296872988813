<template>
	<div class="">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>群活码</span>
			</div>
			<p class="title-tips">
				1、通过企业微信二维码群创建拉人任务，客户扫码不用添加企业成员即可入群，简化客户入群流程，增加客户入群率，方便企业管理。
			</p>
			<p class="title-tips">
				2、入群人数统计规则：扫描系统活码即认为客户入群，扫码人数会与实际扫企业微信活码入群人数有偏差
			</p>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="二维码名称" prop="xcxewm">
					<el-input v-model="ruleForm.xcxewm" placeholder="请输入二维码名称" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="对外二维码" prop="xcxewm">
					<el-image :src="ruleForm.picurl" style="width: 100px;height: auto;"></el-image>
					<div><el-button size="small" @click.stop="funUpload()">上传图片</el-button></div>
				</el-form-item>
				<el-form-item label="门店" prop="mdmc">
					<el-select v-model="ruleForm.mdmc" placeholder="请选择门店">
						<el-option v-for="item in mdlist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="群名称设置" prop="xcxurl">
					<el-input v-model="ruleForm.xcxurl" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="入群引导语" prop="xcxurl">
					<el-input v-model="ruleForm.xcxurl" placeholder="请输入入群引导语" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="选择群聊" prop="xcxurl">
					<el-button type="primary" @click="funaddewm('ruleForm')">添加活码</el-button>
					<el-table :data="tableData" border style="width: 100%">
						<el-table-column prop="pic" label="群活码图片" width="180">
							<template slot-scope="scope">
								<img :src="scope.row.pic" style="width: 80px;height: 80px;"/>
							</template>
						</el-table-column>
						<el-table-column prop="number" label="人数" width="180">
						</el-table-column>
						<el-table-column prop="rqzt" label="入群状态">
						</el-table-column>
						<el-table-column prop="zt" label="状态">
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="100">
							<template slot-scope="scope">
								<el-button @click="handleClick(scope.row)" type="text" size="small">修改</el-button>
								<el-button type="text" size="small">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">保存活码</el-button>
				</el-form-item>
			</el-form>
		</el-card>
		<el-dialog  :visible.sync="ewmVisible" width="30%" @click='ewmVisible=false'>
			<el-form label-width="150px" ref="ewmForm" :model="ewmForm">
				<el-form-item label="人数" prop="number">
					<el-input size="small" v-model="ewmForm.number" auto-complete="off"
						placeholder="请输入人数"></el-input>
				</el-form-item>
				<el-form-item label="二维码图片" prop="picurl">
					<el-image :src="ewmForm.picurl" style="width: 100px;height: auto;"></el-image>
					<el-button size="small" @click.stop="funUpload()">上传图片</el-button>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" class="title" @click="submitForm()">确定
				</el-button>
			</div>
		</el-dialog>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import selImgs from "@/components/system/selImgs";
	export default {
		components: {
			selImgs,
		},
		data() {
			return {
				mdlist: [

				],
				ewmForm:{},
				ruleForm: {
					xcxurl: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				ewmVisible:false,
				tableData: [{
					pic: 'https://mp.bjchaoke.com/dist/static/img/qrcode_wx.884b6411.png',
					zt: 1,
					rqzt: 0,
					number:1000
				}, {
					pic: 'https://mp.bjchaoke.com/dist/static/img/qrcode_wx.884b6411.png',
					zt: 1,
					rqzt: 1,
					number:1000
				},],
				rules: {
					xcxurl: [{
							required: true,
							message: '请输入活动名称',
							trigger: 'blur'
						},
						{
							min: 3,
							max: 5,
							message: '长度在 3 到 5 个字符',
							trigger: 'blur'
						}
					],
					region: [{
						required: true,
						message: '请选择活动区域',
						trigger: 'change'
					}],
					date1: [{
						type: 'date',
						required: true,
						message: '请选择日期',
						trigger: 'change'
					}],
					date2: [{
						type: 'date',
						required: true,
						message: '请选择时间',
						trigger: 'change'
					}],
					type: [{
						type: 'array',
						required: true,
						message: '请至少选择一个活动性质',
						trigger: 'change'
					}],
					resource: [{
						required: true,
						message: '请选择活动资源',
						trigger: 'change'
					}],
					desc: [{
						required: true,
						message: '请填写活动形式',
						trigger: 'blur'
					}]
				}
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			funUpload() {
				this.$refs["selImgs"].funShow()
			},
			funaddewm(){
				this.ewmVisible = true
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.ruleForm.picurl = imgitem.url
			},
		}
	}
</script>

<style scoped>
	.title-tips {
		padding-left: 28px;
		font-weight: 650;
		font-style: normal;
		color: #f59a23;
		font-size: 14px;
	}
</style>