<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="15"></leftnav>
			<div class="rightbox"  style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>图文模板</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card" style="max-width:1080px">
					<div slot="header" class="clearfix">
						<span style="line-height: 40px;">图文模板</span>
						<span><el-button type="primary"style="float: right;margin-top: 7px;" @click="funAddMenu">新增</el-button></span>
					</div>
					<div class="main">
						<div class="main-left">
							<div class="main-box" v-for="(item,index) in menulist" :key="index" @click="fundetail(item)">
								<div class="cont" v-for="(citem,cindex) in item.sendhtml" :key="cindex">
									<div class="head" v-if="cindex == 0">
										<div class="logo">
											<img :src="citem.logo" v-if="citem.logo  && citem.logo.length >0">
											<div class="title">
												{{citem.title}}
											</div>
										</div>
									</div>
									<div class="main-news" v-else>
										<div class="main-title">
											{{citem.title}}
										</div>
										<div class="main-img">
											<img :src="citem.logo" />
										</div>
									</div>
								</div>
								<div class="footer2">
									<div class="edit">
										<el-button type="primary" size="mini" @click.stop="fundetail(item)">编辑</el-button>
									</div>
									<div class="del" @click.stop="funDel(item)">
										<el-button type="danger" size="mini">删除</el-button>
									</div>
								</div>
								
							</div>
						</div>
					</div>
				</el-card>
			</div>
			</div>
		
	</div>
</template>

<script>
	import menuApi from '../../../../../../api/cus/menu.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				title: "",
				mpid: "",
				menulist: [],
				editForm: {},
				editFormVisible: false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getnewslist()

			},
			getnewslist() {
				let param = {
					mpid: this.mpid,
					pageno: 1,
					pagesize: 50
				}
				menuApi.newslist(param, res => {
					if (res.code == 200) {
						let arr = []
						res.data.list.forEach(f => {
							arr.push({
								createdate: f.createdate,
								ispublish: f.ispublish,
								issucc: f.issucc,
								newsid: f.newsid,
								sendhtml: JSON.parse(f.sendhtml),
								title: f.title
							})
						})
						this.menulist = arr
						console.log(this.menulist, 'this.menulist');
					}
				})
			},
			fundetail(item) {
				this.$router.push({
					path: "/customer/home/mp/weixinqiye/imgmenu/imgmenu",
					query: {
						newsid: item.newsid,
						mpid: this.mpid
					}
				})
			},
			funAddMenu() {
				let param = {
					mpid: this.mpid
				}
				menuApi.newsadd(param, res => {
					console.log(res, 'funAddMenu.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: "新增成功"
						})
						this.getnewslist()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funDel(item){
				let param = {
					mpid: this.mpid,
					newsid:item.newsid
				}
				menuApi.newsedel(param, res => {
					console.log(res, 'funDel.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: "删除成功"
						})
						this.getnewslist()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.page{
	
	background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
	}
	.pagecontent{
		display: flex;
		
		height: calc(100vh - 81px);
		overflow: auto;
	}
	.main {
		display: flex;
		height: 600px;
		overflow-y: auto;
	}
	/deep/.el-card__header{
		padding: 0 20px !important;
	}
	.main .main-left {
		width: 1180px;
		overflow-y: auto;
		position: relative;
		align-items: stretch;
	}

	.main .main-left .main-box {
		float: left;
		margin: 10px;
		overflow: auto;
		border-radius: 5px;
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: column;
		border: 1px solid #e4e7ed;
	}
	.main .main-left .main-box .cont{
		box-shadow: 0 0 8px 0 #e4e7ed;
		width: 320px;
		max-height: 500px;
		overflow: hidden;
	}
	.main .main-left .main-box .footer2{
		display: flex;
		align-items: center;
		justify-content: space-around;
		background-color: aliceblue;
		padding: 10px 0;
	}
	.main .main-left .main-box .head {
		display: flex;
	}

	.main .main-left .main-box .head .logo {
		width: 100%;
		height: 200px;
		position: relative;
	}

	.main .main-left .main-box .head .logo img {
		width: 100%;
		height: 100%;
	}

	.main .main-left .main-box .head .title {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 50px;
		line-height: 50px;
		color: #fff;
		width: 100%;
		padding-left: 10px;
		background: rgba(0, 0, 0, 0.5);
	}

	.main .main-left .main-news {
		padding: 0 20px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main .main-left .main-news .main-img {
		width: 48px;
		height: 48px;
		background-color: #E1E1E1;
	}

	.main .main-left .main-news .main-img img {
		width: 100%;
		height: 100%;
	}
</style>