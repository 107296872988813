<template>
	<div>
		<navcon></navcon>
		<div class="content">
			
			<!-- <el-card class="box-card"> -->
				<div slot="header" class="clearfix">
					<el-form :inline="true" :model="formInline" class="user-search">
						
						<el-input placeholder="输入应用名称" v-model="formInline.billno" size="medium" class="input-with-select">
						   <el-button slot="append" icon="el-icon-search"  @click="search"></el-button>
						 </el-input>
					</el-form>
				</div>
				<!--列表-->
				<el-table size="small" :data="orderlist" highlight-current-row v-loading="loading" :border="false"
					element-loading-text="拼命加载中" style="width: 100%;background-color: #F6F7F9;border-radius: 10px;" >
					<el-table-column align="center" prop="billno" label="订单号" width="180">
					</el-table-column>
					<el-table-column align="center" prop="tmpname" label="模版名称" width="180">
					</el-table-column>
					<el-table-column align="center" prop="billstate" label="状态" width="180">
						<template slot-scope="scope">
							<span v-if="scope.row.billstate == 0">失败</span>
							<span v-else-if="scope.row.billstate == 9">取消</span>
							<span v-else>成功</span>
						</template>
					</el-table-column>
					<el-table-column align="center" prop="buytime" label="购买时间" width="180">
					</el-table-column>
					<el-table-column align="center" prop="memo" label="说明" width="180">
					</el-table-column>
					<el-table-column align="center" prop="pricebuy" label="金额" width="120">
					</el-table-column>
					<el-table-column label="操作" min-width="200" align="center">
						<template slot-scope="scope">
							<el-button size="mini" type="primary" @click="handleDetail(scope.$index, scope.row)">详情</el-button>
						</template>
					</el-table-column>
				</el-table>
				<!-- 分页组件 -->
				<div class="fy">
					<Pagination v-bind:child-msg="pageparm" @callFather="callFather" style="float: right;"></Pagination>
				</div>
			<!-- </el-card> -->
		</div>
		<pagebottom></pagebottom>
	</div>
</template>

<script>
	import order from '@/api/cus/ordertemp'
	import Pagination from '../../../components/Pagination'
	import pagebottom from '../../../components/pagebottom.vue'
	import navcon from '../../../components/customer/navcon.vue'
	
	export default{
		// 注册组件
		components: {
			Pagination,
			pagebottom,
			navcon
		},
		
		data(){
			return{
				loading:false,
				orderlist:[],
				// 请求数据参数
				formInline: {
					page: 1,
					limit: 10,
					billno: '',
				},
				// 分页参数
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 10
				}
			}
		},
		mounted(){
			document.title = '我的订单-公众号管理平台-大新软件'
			this.getlist(this.formInline)
		},
		methods:{
			getlist(page){
				let param = {
					pageno:page.page,
					pagesize:page.limit
				}
				console.log(param,'getlist.param');
				this.loading = true
				order.list(param,res=>{
					console.log(res,'getlist');
					if(res.code == 200){
						this.loading = false
						this.orderlist = res.data.list
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					}else if(res.code == 401){
						delloginall()
						this.$router.push({
							path:'/customer/login'
						})
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			},
			search(){
				this.getlist(this.formInline)
			},
			// 分页插件事件
			callFather(parm) {
				this.formInline.page = parm.currentPage
				this.formInline.limit = parm.pageSize
				this.getlist(this.formInline)
			},
			handleDetail(index,row){
				this.$router.push({
				  path: "/customer/order/temporderdetail?billno="+row.billno,
				});
			}
		}
	}
</script>

<style scoped>
	.content{
		width: 1320px;		margin: 20px auto 0 auto;		border-radius: 10px;
	}
	.content>>>.el-table, .el-table__expanded-cell {
	    background-color: #F6F7F9;
	}
	
	.content>>>.el-table th, .content>>>.el-table tr {
	    background-color: #F6F7F9;
	}
	.content .fy{
		display: flex;
		align-items: center;
	}
	.input-with-select{
		width: 300px;
		margin-bottom: 20px;
	}
	.user-search{
		display: flex;
		justify-content: flex-end;
	}
</style>