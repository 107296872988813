import httpapi from '../../utils/httpapi';
const deskqrcodeApi = {
	//关联门店查询
	bindshoplist(funsucc) {
		httpapi.get('/customer/unit/bindshop/list').then(res => {
			
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	detailbyshopid(shopid,funsucc){
		httpapi.post('/customer/qiyeweixin/qrdesk/listbyshopid?shopid=' + shopid).then(res => {
			
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	add(param,funsucc){
		httpapi.postjson('/customer/qiyeweixin/qrdesk/add',param).then(res => {
			
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	desklist(param,funsucc){
		httpapi.post('/customer/qiyeweixin/qrdesk/qrdesk/desklist',param).then(res => {
			
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	flushdesk(param,funsucc){
		httpapi.post('/customer/qiyeweixin/qrdesk/flushdeskbymch',param).then(res => {
			
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	flushshop(funsucc){
		httpapi.post('/customer/unit/bindshop/flsushbysoft').then(res => {
			
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	daobao(param,funsucc){
		httpapi.post('/customer/qiyeweixin/qrdesk/zip',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	getqwewm(param,funsucc){
		httpapi.post('/customer/qiyeweixin/qrdesk/qywx/deskqrcode',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	ticketlist(param,funsucc){
		httpapi.get('/customer/th3app/food/ticket/qiyeweixin/activelist?mpid=' + param.mpid + '&shopid=' + param.shopid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	getlist_contact_way(param,funsucc){
		httpapi.post('/customer/qiyeweixin/qrcode/config/list_contact_way',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	del_contact_way(param,funsucc){
		httpapi.post('/customer/qiyeweixin/qrcode/config/del_contact_way',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	}
}
export default deskqrcodeApi