<template>
	<div class="" v-if="editFormVisible">
		<!-- 编辑界面 -->
		<p class="title">{{title}}</p>
			<el-form label-width="180px" ref="editForm" :model="editForm">
				<!-- <el-form-item label="open_appid" prop="open_appid">
					<el-input size="small" v-model="editForm.open_appid" auto-complete="off"
						placeholder="请输入open_appid"></el-input>
				</el-form-item> -->
				<el-form-item label="公众号名称" prop="mpname">
					<el-input size="small" v-model="editForm.mpname" auto-complete="off"
						placeholder="请输入公众号名称"></el-input>
				</el-form-item>
				<el-form-item label="是否绑定开放平台" prop="isopen">
					<el-select v-model="editForm.isopen" placeholder="是否绑定开放平台" size="small">
						<el-option v-for="item in openList" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="选择开放平台" prop="open_appid" v-if="editForm.isopen == 1">
					<el-select v-model="editForm.open_appid" placeholder="请选择开放平台" size="small">
						<el-option v-for="item in opentypeList" :key="item.appId" :label="item.appName" :value="item.appId">
						</el-option>
					</el-select>
				</el-form-item>
				<div v-if="editForm.isopen == 0">
					<el-form-item label="token" prop="token">
						<el-input size="small" v-model="editForm.token" auto-complete="off"
							placeholder="请输入token"></el-input>
					</el-form-item>
					<el-form-item label="微信原始id" prop="mpgid">
						<el-input size="small" v-model="editForm.mpgid" auto-complete="off"
							placeholder="请输入原始id"></el-input>
					</el-form-item>
					<el-form-item label="开发者ID(AppID)" prop="appID">
						<el-input size="small" v-model="editForm.appID" auto-complete="off"
							placeholder="请输入开发者ID(AppID)"></el-input>
					</el-form-item>
					<el-form-item label="开发者密码(AppSecret)" prop="appSecret">
						<el-input size="small" v-model="editForm.appSecret" auto-complete="off"
							placeholder="请输入开发者密码(AppSecret)"></el-input>
					</el-form-item>
				</div>
				
				<!-- <el-form-item label="微信认证情况" prop="isver">
					<el-input size="small" v-model="editForm.isver" auto-complete="off"
						placeholder="请输入微信认证情况"></el-input>
				</el-form-item> -->
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='funClose'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title"
					@click="submitForm('editForm')">保存
				</el-button>
			</div>
	</div>
</template>

<script>
	import accountApi from '../../../api/cus/account.js'
	export default {
		data() {
			return {
				title: '',
				loading: false,
				editForm: {
					"open_appid": "",
					"mpid": 0,
					"unitno": 0,
					"appID": "",
					"appSecret": "",
					"mpgid": "",
					"mpname": "",
					"token": "",
					"isopen": 1,
					"isver": 0
				},
				openList: [{
						id: 0,
						mc: '开发者模式'
					},
					{
						id: 1,
						mc: '开放平台'
					},
				],
				opentypeList:[],
				editFormVisible: false
			}
		},
		methods: {
			funShow(title) {
				this.title = title
				this.editFormVisible = true
				this.getauth()
			},
			funClose() {
				this.editFormVisible = false
				this.$emit('funCloseReslut',{zt:'wxgzh'})
			},
			getauth(){
				accountApi.allauth(10,res=>{
					console.log(res,'allauth');
					if(res.code == 200){
						this.opentypeList = res.data
					}
				})
			},
			submitForm() {
				let param = {
					"open_appid": this.editForm.open_appid,
					"token": this.editForm.token,
					"mpid": this.editForm.mpid,
					"mpgid": this.editForm.mpgid,
					"unitno": this.editForm.unitno,
					"appID": this.editForm.appID,
					"appSecret": this.editForm.appSecret,
					"mpname": this.editForm.mpname,
					"isopen": this.editForm.isopen,
					"isver": this.editForm.isver
				}
				console.log(param, 'addwxapp.param');
				accountApi.addgzh(param, res => {
					console.log(res, 'addwxapp.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.editFormVisible = false
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.title {
		margin: 10px 0;
		text-align: center;
	}
	
	.dialog-footer {
		text-align: center;
	}
</style>