<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="数据" name="first">
				

				<div class="rowbox">
					<vuedraggable v-model="list.data" tag="div" draggable=".item"
						v-if="list.data && list.data.length > 0" class="image-list"
						:class="{ disable: data.tabType == 2 }">
						<template v-if="list.data && list.data.length < len">
							<el-button type="primary" @click="addImage(null)" class="add-image">
								添加图片
							</el-button>

						</template>
						<div v-for="(item, index) in list.data" :key="index" @click="selitem(item,index)" class="item"
							:class="index === imageIndex? 'sel':'normal'">
							<img :src="item.url" />
							
							<div>{{ item && item.name }}</div>
							<div class="r-image">
								<span @click="removeImage(imageIndex)" class="el-icon-close"></span>
								<div class="image-box">
									<!-- <img :src="item && item.url" /> -->
									<span @click="addImage(imageIndex)" class="el-icon-edit-outline"></span>
								</div>
							</div>
						</div>
						
					</vuedraggable>
					<div class="l-info" v-if="imageIndex > -1">
						<div class="l-item">
							<div style="margin-bottom: 15px;">标题：</div>
							<el-input type="text" size="small" v-model="item.title" style="width: 310px;"></el-input>
						</div>
						<div class="rowbox" style="align-items: center;margin: 15px 0 10px 10px;">
							<span>图片链接：</span>
							<el-input v-model="item.url" size="small" style="width: 200px;"></el-input>
							<!-- <el-button type="primary" @click="addImage(imageIndex)" size="small">上传</el-button> -->
							<div @click="addImage(imageIndex)" class="upimgbtn">上传</div>
						</div>
						<p style="margin: 15px 0 10px 10px ;">
							<span>链接：</span>
							<span class="text" style="margin: 0 40px;">{{ item && item.link && item.link.name }}</span>
							<el-button size="small" @click="urlPopup(imageIndex, item.link)">选择链接</el-button>
						</p>
						<p class="size">（建议尺寸：{{ size }}）</p>
						<p class="desc">添加图片 (最多{{ len }}张，可拖动排序）</p>
					</div>
					
				</div>
				
				<!-- 项目属性开始 -->

				<!-- <div class="r-image">
										<span @click="removeImage(imageIndex)" class="el-icon-close"></span>
										<div class="image-box">
											<img :src="item && item.url" />
											<span @click="addImage(imageIndex)" class="el-icon-edit-outline"></span>
										</div>
									</div> -->

				<!-- 项目属性结束 -->
				<el-dialog title="请填写图片跳转链接" :visible.sync="show" @close="close">
					<el-form label-width="100px">
						<el-form-item label="跳转链接">
							<el-input v-model="url"></el-input>
						</el-form-item>
						<el-form-item label="">
							<el-button type="primary" @click="confirm">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>

			</el-tab-pane>
			<!-- <el-tab-pane label="背景" name="background">
				
			</el-tab-pane> -->
			<el-tab-pane label="样式" name="style">
				<!--属性设置-->
				<div class="lis">
					<div class="title">
						<span>高度</span>
					</div>
					<el-slider v-model="list.options.height" show-input :max="4000">
					</el-slider>
				</div>
				<div class="list">
					<div class="title">
						<span>上边距 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.margintop" show-input :max="200">
						</el-slider>
					</div>
				</div>
				
				<div class="list">
					<div class="title">
						<span>页边距 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.marginpage" show-input :max="200">
						</el-slider>
					</div>
				</div>
				
				<div class="details">
					<div class="list">
						<div class="title">
							<span>背景图片 </span>
						</div>
						<div class="btn2 full" style="display: flex;align-items: center;">
							<el-input v-model="list.options.bgimg" style="width: 220px;" size="small"></el-input>
							<!-- <input type="text" v-model="list.options.bgimg" /> -->
							<!-- <el-button type="primary" @click="addImage('bg')" size="small">
								选择图片
							</el-button> -->
							
						</div>
						<div @click="addImage('bg')" class="upimgbtn">上传</div>
					</div>
				
					<!-- 背景颜色 -->
					<div class="list">
						<div class="title"><span>背景颜色</span></div>
						<div class="center">{{list.options.bgcolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('bgcolor')">重置</el-button><el-color-picker v-model="list.options.bgcolor"
								size="medium"></el-color-picker>
						</div>
					</div>
				
					<div class="list">
						<div class="title"><span>内部背景色</span></div>
						<div class="center">{{list.options.contcolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('contcolor')">重置</el-button><el-color-picker
								v-model="list.options.contcolor" size="medium"></el-color-picker>
						</div>
					</div>
				
				
				
				</div>
				
				<div class="details">
					<div class="list">
						<div class="title">
							<span>方向 </span>
							<span> {{ list.options.direction == 1 ? "横向" : "竖向" }} </span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.direction" @change="changedirection">
								<el-tooltip class="item" effect="dark" content="横向" placement="bottom">
									<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="竖向" placement="bottom">
									<el-radio-button :label="2">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>
					<!-- 标题文字大小 -->
					<div class="list">
						<div class="title">
							<span>标题大小 </span>
							<span>{{
				      list.options.size == 1
				        ? "大(16号)"
				        : list.options.size == 2
				        ? "中(14号)"
				        : "小(12号)"
				    }}
							</span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.size">
								<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
									<el-radio-button :label="1">
										<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
									<el-radio-button :label="2">
										<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
									<el-radio-button :label="3">
										<div style="font-size: 12px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>

					<!-- 是否文字加粗 -->
					<div class="list">
						<div class="title">
							<span>标题粗细 </span>
							<span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.bold">
								<el-tooltip class="item" effect="dark" content="常规体" placement="bottom">
									<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="加粗体" placement="bottom">
									<el-radio-button :label="2">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>

					<!-- 标题文字颜色 -->
					<div class="list">
						<div class="title"><span>标题颜色</span></div>
						<div class="center">{{list.options.titlecolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('titlecolor')">重置</el-button><el-color-picker
								v-model="list.options.titlecolor" size="medium"></el-color-picker>
						</div>
					</div>

					<!-- 分割线颜色 -->
					<div class="list">
						<div class="title"><span>分割线颜色</span></div>
						<div class="center">{{list.options.linecolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('linecolor')">重置</el-button><el-color-picker
								v-model="list.options.linecolor" size="medium"></el-color-picker>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>图片大小 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.imgsize" show-input :max="800">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>项目边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.itempaddingtop" show-input :max="800">
							</el-slider>
						</div>
					</div>
					<div class="list">
						<div class="title">
							<span>每行数量 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.itemnum" show-input :max="5">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>文字行高 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.lineheight" show-input :max="800">
							</el-slider>
						</div>
					</div>

					<!-- 圆角 -->
					<div class="list">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="800">
							</el-slider>
						</div>
					</div>


				</div>
			</el-tab-pane>
		</el-tabs>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
	</div>
</template>

<script>
	import selImgs from "@/components/designer/selImgs"
	import sellink from "@/components/designer/sellink"
	import vuedraggable from "vuedraggable";
	export default {
		name: "image_row_edit",
		components: {
			sellink,
			selImgs,
			vuedraggable,
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				list: {},
				url: "",
				index: null,
				show: false,
				imageIndex: -1,
				item: {

				},
				stylecol: {
					"data": [{
						"form": {},
						"top": 0,
						"left": 0,
						"name": "",
						"width": 200,
						"title": "礼品卡",
						"margintop": 0,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309653bc1d030ab448ca13a436ad624c2f4.png?key=123456",
						"height": 200
					}, {
						"form": {},
						"top": 0,
						"left": 275,
						"name": "",
						"width": 200,
						"title": "优惠券",
						"margintop": 0,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309595b78fcce5e4768bb875fd6fb8184df.png?key=123456",
						"height": 200
					}, {
						"form": {},
						"top": 0,
						"left": 550,
						"name": "",
						"width": 200,
						"title": "领券中心",
						"margintop": 0,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099f2a93d3967d40e2bc57da74ab9e941d.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "积分商城",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/169241699017262694620230955ed009279ce45ceaf74c8219f54275d.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "我的预约",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023094a41b120f800499dbd466f05770d2b75.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "排位",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309b6b8088b8ec44dc09a2cb4085891e9bd.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "退出登录",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309e5023251ab96451a9147b06dc8af7676.png?key=123456",
						"height": 200
					}],
					"options": {
						"marginbottom": 0,
						"lineheight": 58,
						"bgimg": "",
						"imgsize": 21,
						"itemnum": 0,
						"contcolor": "#20232B",
						"bgcolor": "",
						"marginpage": 16,
						"bgimgform": {},
						"borderradius": 10,
						"width": 750,
						"itempaddingtop": 0,
						"margintop": 0,
						"height": 870,
						"direction": 2,
						"titlecolor": "#EEE5E5",
						"linecolor": "#2C2828"
					},
					"name": "7.图文导航",
					"type": "menu_img_title",
					"boxid": "62C628B8D92A3EA3"
				},
				stylerow: {
					"data": [{
						"form": {},
						"top": 0,
						"left": 0,
						"name": "",
						"width": 200,
						"title": "我的预约",
						"margintop": 0,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309a4d133b4b68b4405ae2ddeb59e6317c5.png?key=123456",
						"height": 200
					}, {
						"form": {},
						"top": 0,
						"left": 275,
						"name": "",
						"width": 200,
						"title": "我的排位",
						"margintop": 0,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023095f301d083dcd4376b61445b3e143a5f5.png?key=123456",
						"height": 200
					}, {
						"form": {},
						"top": 0,
						"left": 550,
						"name": "",
						"width": 200,
						"title": "我的存酒",
						"margintop": 0,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023093b6c492491204c778af73437a36db711.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "我的订单",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/169241699017262694620230932a814d24ca44ac89ec09aecef2cfc57.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "充值中心",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/169241699017262694620230935c0a6c56c5941a29112100b9a57ff5f.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "预售套餐",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023092f7e865250b44a43badd8185d7f2dfa6.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "我的足迹",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309d280cce3a44f431eaab4856311035291.png?key=123456",
						"height": 200
					}, {
						"top": 0,
						"left": 0,
						"link": {
							"name": "无链接",
							"type": "none"
						},
						"width": 200,
						"title": "退出登录",
						"margintop": 20,
						"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309748019944b6a4b8e81b08ed21cc860ce.png?key=123456",
						"height": 200
					}],
					"options": {
						"marginbottom": 0,
						"lineheight": 26,
						"titlecolor": "#E5E5E5",
						"bgimg": "",
						"linecolor": "#2C2828",
						"imgsize": 0,
						"bold": 2,
						"itemnum": 4,
						"contcolor": "#20232B",
						"bgcolor": "",
						"size": 3,
						"marginpage": 16,
						"bgimgform": {},
						"borderradius": 10,
						"width": 750,
						"itempaddingtop": 12,
						"margintop": 1,
						"height": 355,
						"direction": 1
					},
					"name": "8.图文导航",
					"type": "menu_img_title",
					"boxid": "107E3C07A697FB16"
				},
				activeName: "first"
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;
		},
		methods: {
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				this.item = item;
				this.imageIndex = index;
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index, link) {
				
				this.$refs['sellink'].funShow()
			},
			sellinkresult(param) {
				
				const index = this.imageIndex;
				if (index !== null) {
					let data = this.list.data[index];
					data.link = param;
					
					this.$set(this.list.data, index, data);
				}
			},
			removeImage(index) {
				this.list.data.splice(index, 1);
			},
			addImage(index) {
				this.imageIndex = index;
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				if (this.imageIndex == 'bg') {
					this.uploadbg(imgitem);
				} else {
					this.uploadItem(imgitem);
				}
			},
			changedirection(e) {
			
				if (e == 2) {
					//heng
					this.list.data = this.stylecol.data;
					this.list.options.height = this.stylecol.options.height;
				} else {
					this.list.data = this.stylerow.data;
					this.list.options.height = this.stylerow.options.height;
					this.list.options.linecolor = this.stylerow.options.linecolor;
				}
			},
			uploadbg(imgitem) {
				this.list.options.bgimg = imgitem.url;
			},

			uploadItem(imgitem) {
				const index = this.imageIndex;
				if (index !== null) {
					let data = this.list.data[index];
					data.url = imgitem.url;
					
					this.$set(this.list.data, index, data);
				} else {
					const data = {
						title: '菜单',
						url: imgitem.url,
						link: {
							type: "none",
							name: "无链接"
						},
						width: 200,
						height: 200,
						left: 0,
						top: 0,
						margintop: 20
					};

					this.list.data.push(data);
				}
			},

		},
	};
</script>

<style lang="less" scoped>
	.rowbox {
		display: flex;
		flex-direction: row;
	}

	.upimgbtn {
		color: #1b8bff;
		margin: 0 10px;
	}

	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;
		position: relative;

		.el-icon-star-on {
			cursor: pointer;
			position: absolute;
			top: 10px;
			right: 0;
			font-size: 20px;
			z-index: 9999999;
		}

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: 65px;
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin: 2px
		}

		.image-list {
			margin: 0;

			overflow: auto;
			display: flex;

			flex-direction: column;


			.item {
				width: 60px;
				height: 60px;
				overflow: hidden;
				margin: 2px;
				position: relative;

				&:hover .r-image {
					background: rgba(0, 0, 0, 0.5);
				}

				img {
					width: 100%;
				}
			}

			.normal {
				border: 1px solid #ccc;
				background-color: #F5F5F5;
			}

			.sel {
				border: 1px solid #1b8bff;
				background-color: #F5F5F5;
			}

			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			li {
				list-style: none;
				background: #f7f8f9;
				border-radius: 4px;
				padding: 6px 14px 10px;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;

				&.disable div {
					pointer-events: none;
					user-select: none;
				}

				.l-info {
					font-size: 12px;
					padding-top: 8px;
					width: calc(100% - 70px);

					p {
						margin: 12px 0 0;
						white-space: nowrap;
						overflow: hidden;
						display: flex;

						.link {
							color: #1b8bff;
							cursor: pointer;
						}

						.text {
							white-space: nowrap;
							text-align: -webkit-auto;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}


			}
		}

		.l-item {
			margin: 10px 0 10px 10px !important;
		}

		.list {
			display: flex;
			align-items: center;
			// justify-content: space-between;
			margin: 20px;
			justify-content: space-between;

			.title {
				margin-right: 10px;
				flex-shrink: 0;
				width: 80px;
			}
			.center{
				color: #969799;
				text-align: center;
			}
			.full {
				flex-grow: 1;
			}

			.btn {
				display: flex;
				align-items: center;

				.reset {
					margin-right: 10px;
					color:#FF6060 ;
				}
			}

			.flex-row {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}

		.r-image {
			text-align: right;
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			z-index: 999;


			.el-icon-close {
				color: #999;
				font-size: 12px;
				font-weight: 600;
				margin-bottom: 6px;
				cursor: pointer;

				&:hover {
					color: red;
				}
			}

			.image-box {
				width: 100%;
				height: 100%;
				border-radius: 5px;
				overflow: hidden;
				position: relative;
				// background: #fff;
				display: flex;
				justify-content: center;
				align-items: center;

				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					// background: rgba(0, 0, 0, 0.5);
					opacity: 0;
					transition: all 0.3s;
				}

				span {
					position: absolute;
					top: 50%;
					left: 50%;
					color: #fff;
					transform: translate(-50%, -50%);
					font-size: 20px;
					cursor: pointer;
					opacity: 0;
					transition: all 0.3s;
				}

				img {
					max-width: 100%;
				}

				&:hover {

					&::before,
					span {
						opacity: 1;
					}
				}
			}
		}
	}

	p {
		margin: 0 10px;
	}

	.lis {
		margin: 0 20px;
	}
</style>