<template>
	<div>
		<div class="header">
			<div class="logo">
				<img src="../../../assets/image/logo.png" alt="logo" />
			</div>
			<div class="memo">

			</div>
			<div class="btns">
				<el-button @click="submit" type="primary" size="small">保存模版</el-button>
				<el-button @click="funback" size="small">返回</el-button>
			</div>
		</div>
		<div class="desgin_con">
			<section class="menulist">
				<div class="item" :class="currId == item.id ? 'sel' : 'nom'" v-for="(item,index) in menulist"
					:key="index" @click="funmenuclick(item,index)">
					<div class="img">
						<img :src="currId != item.id ? item.logo : item.logoactive" />
					</div>
					<div class="txt">
						{{item.txt}}
					</div>
				</div>
			</section>
			<toolboxlist ref="toolboxlist" :typeList="typeList" :pagelist="pagelist" :currId="currId"
				:templateid="templateid" @funaddbox="funaddbox" @fungotopage="fungotopage" @dragStart="dragStart"
				@dragEnd="dragEnd" @funlikeadd="funlikeadd"></toolboxlist>
				<div class="pagelist" v-show="showdesign.pagemenu">
					<div class="pitem" v-for="(item,index) in pagelist" :class="item.pageno == selpagetype ? 'selstyle': ''"
						@click="funselpage(item)">
						{{item.pagename}}
					</div>
				</div>
				<div class="desgin_con_main" v-show="showdesign.diandan">
					<diandan ref="diandan" @diandanresult='diandanresult'></diandan>
				</div>
			<div class="desgin_con_main" v-show="showdesign.dragpage">

				<div id="darag">
					<div style="height: 10px;width:100%"></div>

					<!-- 展示区域 -->
					<template>
						<section class="main" ref="main">
							<div class="phone-main">
								<div class="top-nav" :style="{'background':  view.options.stylestate && view.options.stylestate == 'F' ?  view.options.backgroundColor : viewstyle.subject1_bg_color,'color':view.options.stylestate && view.options.stylestate == 'F' ?
											
											
											(view.options.headcolor == 1 ? '#ffffff':'#000000') :  viewstyle.page_title_txt_color}"
									:class="selboxindex == -10? 'selected':'normal'" @click="selectPage"
									v-if="view.options.showhead == 1">
									<img src="../../../assets/design/topNavBlack.png" />
									<span class="tit">{{ view.options.title }}</span>
								</div>
								<div class="top-nav"
									:style="{'color':  view.options.stylestate && view.options.stylestate == 'F' ?  view.options.backgroundColor : viewstyle.page_title_txt_color,'color':view.options.headcolor == 1 ? '#ffffff':'#000000' }"
									:class="selboxindex == -10? 'selected':'normal'" @click="selectPage" v-else>
									<img src="../../../assets/design/topNavBlack.png" />

								</div>

								<div class="view-content-top" v-if="view.options.showhead == 1"></div>

								<div class="view-content" ref="content"
									:style="{ backgroundColor: view.options.stylestate && view.options.stylestate == 'F' ?  view.options.backgroundColor : viewstyle.subject2_bg_color }">


									<template v-for="(item, index) in view.list">
										<div v-if="index >= 0" :data-index="index" :key="item.boxid" class="item"
											:class="index == selboxindex ? 'selected':'normal'"
											@click="selectType(index)">
											<!-- waiting -->
											<template v-if="item.status && item.status == 2">
												<div class="wait">组件放置区域</div>
											</template>
											<template v-else>
												<component :is="typeList[item.type]['com']" :data="item"
													:className="className[item.tabType]"
													@selectItemHandler="selectItemHandler">
												</component>
											</template>
											<i @click="deleteItem($event, index)" class="el-icon-error"></i>
										</div>
									</template>


								</div>

								<div class="view-content-bottom" v-if="view.options.showfoot == 1"></div>

								<div class="foot-nav" :class="selboxindex == -20? 'selected':'normal'"
									v-if="view.options.showfoot == 1">
									<h_foot_nav_cube :data="footer" :styleview="viewstyle"></h_foot_nav_cube>
								</div>
							</div>
						</section>
						<section class="dragcont">

							<section class="dragmenu">
								<div class="item" :class="selboxindex == -10? 'selected':'normal'"
									@click="selectPage()">
									{{ pagename }}
								</div>

								<Draggable v-model="view.list" draggable=".item" chosenClass="chosen">
									<div v-for="(item, index) in view.list" :data-index="index" :key="item.boxid"
										class="item" @click="selectType(index)"
										:class="index == selboxindex ? 'selected':'normal'">
										{{item.name }}
										<i class="el-icon-sort"></i>
										<i @click="deleteItem($event, index)" class="el-icon-error"></i>
									</div>
								</Draggable>
								<div class="item menu" @click="selectMenu()">
									底部菜单
								</div>
							</section>
							<!-- 描述信息 -->
							<section class="right">


								<el-button class="button-dalod" size="mini" title="JSON" @click="getjson()"
									icon="el-icon-download" style="
						  position: absolute;
						  right: 0px;
						  bottom: 0px;
						"></el-button>

								<!-- <el-button @click="submit" style="
						  float: right;
						  margin: 10px;
						  position: absolute;
						  right: 10px;
						  top: 5px;
						">保存模版</el-button> -->
								<div class="property">
									<!-- 动态修改内容区域的值 -->
									<Index :data="props" ref="property" v-if="isRight"></Index>
								</div>
							</section>
						</section>
					</template>

				</div>
			</div>
			<!-- <div style="width:1200px;height: 200px;">{{ view }}</div> -->
			<div class="preview">
				<canvas id="QRCode_header"
					style="width: 100px; height: 100px;margin-left: 23px;margin-top: 10px;"></canvas>
				<div class="sjx"></div>
				<div class="tip">微信扫码预览</div>
				<div class="tip">D{{this.templateid}}</div>
			</div>
			<showload :showload="showload"></showload>
			<tabbar ref="tabbar"></tabbar>
		</div>
	</div>
</template>

<script>
	import Draggable from "vuedraggable";
	// const QRCode = require('qrcode');
	import showload from "@/components/loading";
	import QRCode from "qrcode";
	// 文本
	import h_text_title from "@/components/applet/toolbox/base/text/text_title";
	import h_text_notice from "@/components/applet/toolbox/base/text/text_notice";
	//img
	import h_image_row from "@/components/applet/toolbox/base/img/image_row";

	import h_image_col from "@/components/applet/toolbox/base/img/image_col";
	import h_menu_img_title from "@/components/applet/toolbox/base/img/menu_img_title";
	import h_image_swiper from "@/components/applet/toolbox/base/img/image_swiper";
	import h_image_magic from "@/components/applet/toolbox/base/img/image_magic";
	import h_image_spot from "@/components/applet/toolbox/base/img/image_spot";
	import h_image_desk from "@/components/applet/toolbox/base/img/image_desk";
	import h_image_docker from "@/components/applet/toolbox/base/img/image_docker";

	import h_foot_nav_cube from "@/components/applet/toolbox/nav/foot_nav_cube";
	import h_foot_nav_circle from "@/components/applet/toolbox/nav/foot_nav_circle";


	import h_goods_list_sp from "@/components/applet/toolbox/goods/goods_list_sp";
	//
	import h_line_place from "@/components/applet/toolbox/base/line/line_place";

	import h_account_login from "@/components/applet/toolbox/club/login/account_login";
	//店铺信息
	import h_shop_info_nav from "@/components/applet/toolbox/shop/shop_info_nav";
	//办理会员
	import h_club_newadd from "@/components/applet/toolbox/club/account/club_newadd";
	//会员资产
	import h_club_asset from "@/components/applet/toolbox/club/account/club_asset";

	import h_coupon_list from "@/components/applet/toolbox/coupon/coupon_list";

	import h_news_list from "@/components/applet/toolbox/shop/news_list";
	import h_image_share from "@/components/applet/toolbox/base/img/image_share";
	import h_image_join from "@/components/applet/toolbox/base/img/image_join";
	import h_image_qunqr from "@/components/applet/toolbox/base/img/image_qunqr";
	import Index from "../../../components/applet/toolbox/property.vue";

	import designTemplataApi from "../../../api/isv/designtmplate";
	import designOptionVal from "../../../api/isv/designOptionVal";

	import toolboxlist from "../../../components/applet/toolbox/toolboxlist.vue"
	import tabbar from "../../../components/applet/toolbox/tabbar.vue"
	import util from "../../../utils/util.js"
	import diandan from '../../../components/diypage/diandan/index.vue'
	export default {
		components: {
			Draggable,
			Index,
			h_text_title,
			h_text_notice,
			h_image_row,
			h_image_col,
			h_menu_img_title,
			h_image_swiper,
			h_image_magic,
			h_image_spot,
			h_image_desk,
			h_image_docker,

			h_goods_list_sp,

			h_foot_nav_cube,
			h_foot_nav_circle,

			h_line_place,
			h_account_login,

			h_shop_info_nav,
			h_club_newadd,
			h_club_asset,
			h_coupon_list,
			h_news_list,
			h_image_share,
			h_image_join,
			h_image_qunqr,
			showload,
			toolboxlist,
			tabbar,
			diandan
		},
		data() {
			return {
				pageid: "", //页面编号
				templateid: '', //模版编号
				pagetype: "", //页面类型
				pagename: "", //页面名称
				pagelist: [],
				mainShow: true,
				allowsort: false,
				showload: false,
				label: "测试组件",
				// 左侧组件库数据
				typeList: {
					// 存放每个组件的数据结构
					text_title: {
						name: "标题",
						icon: "el-icon-picture",
						com: h_text_title,
					},
				},
				// view: [
				//   {
				//     type: "pageinfo",
				//     title: "页面标题",
				//     options:{

				//     }
				//   },
				// ],
				selpagetype:"index",
				view: {
					options: {
						type: "pageinfo",
						title: "页面标题",
						headcolor: 0,
						showhead: 1,
						showfoot: 1
					},
					list: [],
				},
				footer: {},
				viewstyle: {},

				title: "页面标题",
				type: "",
				index: null, // 当前组件的索引
				selboxindex: null,
				selboxid: null,
				isPush: false, // 是否已添加组件

				props: {}, // 传值
				isRight: false,

				className: {
					1: "one",
					2: "two",
					3: "three",
				},
				computdata: "",
				qrUrl: "",
				userInfo: {},
				currId: 0,
				showdesign: {
					diandan: false,
					dragpage: true,
					pagemenu: true
				},
				menulist: [{
						id: 0,
						logo: require('../../../assets/design/icon_1.png'),
						logoactive: require('../../../assets/design/icon_1_active.png'),
						txt: '组件'
					},
					{
						id: 1,
						logo: require('../../../assets/design/icon_7.png'),
						logoactive: require('../../../assets/design/icon_7_active.png'),
						txt: '收藏'
					},
					// {
					// 	id: 4,
					// 	logo: require('../../../assets/design/icon_3.png'),
					// 	logoactive: require('../../../assets/design/icon_3_active.png'),
					// 	txt: '页面'
					// },
					{
						id: 5,
						logo: require('../../../assets/design/icon_4.png'),
						logoactive: require('../../../assets/design/icon_4_active.png'),
						txt: '风格'
					},
					{
						id: 6,
						logo: require('../../../assets/design/icon_5.png'),
						logoactive: require('../../../assets/design/icon_5_active.png'),
						txt: '上传'
					},
					{
						id: 2,
						logo: require('../../../assets/design/icon_6.png'),
						logoactive: require('../../../assets/design/icon_6_active.png'),
						txt: '设置'
					},
				]
			};
		},

		mounted() {
			console.log("moutent");
			// let pageid = this.$route.query.id;
			let pagetype = this.$route.query.t;
			let templateid = this.$route.query.tid;
			if (pagetype == undefined || pagetype.length <= 0) {
				pagetype = 'index'
			}
			let u = util.getloginuser()
			// let u = sessionStorage.getItem('designeruser')
			if (u != null) {
				this.userInfo = u;
			} else {
				this.$router.push("/");
			}
			let pagename = decodeURIComponent(this.$route.query.mc);
			// this.pageid = pageid;
			this.pageid = '';
			this.templateid = templateid;
			this.pagetype = pagetype;

			this.initdata();
			if (pagename == 'undefined') {
				// this.pagename = pagename;
				this.pagename = '首页'
			} else {
				this.pagename = pagename;
			}
			this.getQrcode()
		},
		computed: {
			// info() {
			//   return this.view[0];
			// },
		},
		methods: {
			getQrcode() {
				let opts = {
					errorCorrectionLevel: "H", //容错级别
					type: "image/png", //生成的二维码类型
					quality: 0.3, //二维码质量
					margin: 0, //二维码留白边距
					width: 100, //宽
					height: 100, //高
					text: "", //二维码内容
					color: {
						dark: "#333333", //前景色
						light: "#fff", //背景色
					},
				};
				let mchno = this.userInfo.mchno
				let shopno = this.userInfo.shopno
				if (mchno == null || mchno == undefined || mchno.length <= 0) {
					mchno = '10001'
				}
				if (shopno == null || shopno == undefined || shopno.length <= 0) {
					shopno = '1000101'
				}
				this.qrUrl =
					`http://wxapp.91erp.net/wxty1/?fun=ty&vp=viewtemplatepage&mch=${mchno}&fdid=${shopno}&tcls=design&tid=${this.templateid}&t=${this.pagetype}`
				console.log(this.qrUrl, 'this.qrUrl');
				// this.qrUrl = "http://wxapp.91erp.net/wxty1/?fun=ty&vp=viewtemplatepage&mch=10099&fdid=1009901&tcls=design&tid=" + this
				// 	.templateid + "&t=" + this.pagetype
				let msg = document.getElementById("QRCode_header");
				// 将获取到的数据(val)画到msg(canvas)上
				QRCode.toCanvas(msg, this.qrUrl, opts, function(error) {
					if (error) {
						console.log("二维码加载失败", error);
						this.$message.error("二维码加载失败");
					}
				});
			},
			diandanresult(res) {
				if (res != undefined) {
					this.$confirm('确认保存点单模板吗, 是否保存?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.computdata = JSON.stringify(res.data);
						const form = JSON.parse(JSON.stringify(res.data));
						this.__savediandanstyle(res.data, res => {
							if (res.code == 200) {
								this.$message.success("数据提交成功！");
							} else {
								this.$message.error("数据提交失败，" + res.msg);
							}
						});
					}).catch(() => {});
				}
			},
			funselpage(item) {
				console.log(item, 'funselpage.item')
				this.selpagetype = item.pageno;
				if (item.pageno == 'diandan') {
					this.showdesign.diandan = true
					this.showdesign.dragpage = false
					this.showdesign.pagemenu = true
					this.pageid = item.id;
					this.templateid = item.tmpid;
					this.pagetype = item.pageno;
					this.pagename = item.pagename;
			
			
					this.$refs["diandan"].fundiandandata({
						templateid: this.templateid,
						pageid: item.id
					})
				} else {
					this.showdesign.diandan = false
					this.showdesign.dragpage = true
					this.showdesign.pagemenu = true
					this.pageid = "";
					this.templateid = item.tmpid;
					this.pagetype = item.pageno;
					this.pagename = item.pagename;
					// this.__getdesignfromtemplateid();
					this.initdata()
					this.getQrcode()
				}
			
			},
			funmenuclick(item, index) {
				console.log(item,'item')
				this.currId = item.id
				let u = util.getloginuser()
				let usertype = ''
				if (u != null) {
					usertype = u.usertype
				}

				// this.mainShow = true
				if (this.currId == 1) {
				this.showdesign.diandan = false
				this.showdesign.dragpage = true
				this.showdesign.pagemenu = true
					this.$refs['toolboxlist'].funcollect(1)
				}
				if (this.currId == 2) {
					this.showdesign.diandan = false
					this.showdesign.dragpage = true
					this.showdesign.pagemenu = true
					this.$refs['toolboxlist'].funshow(this.templateid, usertype)
				}
				if (this.currId == 5) {
					// this.mainShow = false
					this.showdesign.diandan = false
					this.showdesign.dragpage = false
					this.showdesign.pagemenu = false
					this.$refs['toolboxlist'].funshow(this.templateid, usertype)
				}
			},
			initdata() {
				let _this = this;
				_this.pageid = ''
				_this.isRight = false;
				_this.showload = true;
				designTemplataApi.getUserPageList({
					id: this.templateid
				}, res => {
					console.log(res, 'getUserPageList');
					_this.showload = false;
					if (res.code == 200) {
						// this.item = {
						//   tmpname:res.data.tmpname
						// }
						this.pagelist = res.data.pagelist
					}
				})

				designTemplataApi.getToolBox({
					t: this.pagetype
				}, (res) => {
					if (res.code == 200) {
						console.log(res, 'getToolBox')
						let toolbox = res.data;

						let keyarry = Object.keys(toolbox);
						for (let i in keyarry) {
							let k = keyarry[i];

							switch (toolbox[k].no) {
								case "text_title":
									toolbox[k]["com"] = h_text_title;
									break;
								case "text_notice":
									toolbox[k]["com"] = h_text_notice;
									break;
								case "image_row":
									toolbox[k]["com"] = h_image_row;
									break;
								case "image_col":
									toolbox[k]["com"] = h_image_col;
									break;
								case "menu_img_title":
									toolbox[k]["com"] = h_menu_img_title;
									break;
								case "image_swiper":
									toolbox[k]["com"] = h_image_swiper;
									break;
								case "image_magic":
									toolbox[k]["com"] = h_image_magic;
									break;
								case "image_spot":
									toolbox[k]["com"] = h_image_spot;
									break;
								case "image_desk":
									toolbox[k]["com"] = h_image_desk;
									break;
								case "image_docker":
									toolbox[k]["com"] = h_image_docker;
									break;

								case "account_login":
									toolbox[k]["com"] = h_account_login;
									break;
								case "shop_info_nav":
									toolbox[k]["com"] = h_shop_info_nav;
									break;
								case "goods_list_sp":
									toolbox[k]["com"] = h_goods_list_sp;
									break;
								case "foot_nav_cube":
									toolbox[k]["com"] = h_foot_nav_cube;
									break;
								case "foot_nav_circle":
									toolbox[k]["com"] = h_foot_nav_circle;
									break;
								case "line_place":
									toolbox[k]["com"] = h_line_place;
									break;
								case "club_newadd":
									toolbox[k]["com"] = h_club_newadd;
									break;
								case "club_asset":
									toolbox[k]["com"] = h_club_asset;
									break;
								case "coupon_list":
									toolbox[k]["com"] = h_coupon_list;
									break;
								case "news_list":
									toolbox[k]["com"] = h_news_list;
									break;
								case "image_share":
									toolbox[k]["com"] = h_image_share;
									break;
								case "image_join":
									toolbox[k]["com"] = h_image_join;
									break;
								case "image_qunqr":
									toolbox[k]["com"] = h_image_qunqr;
									break;
								default:
									console.log(toolbox[k].com, "key");
									toolbox[k]["com"] = h_text_title;
									break;
							}
						}
						console.log("toolbox :>> ", toolbox);
						_this.typeList = toolbox;

						let defaultView = {
							options: {
								type: "pageinfo",
								title: "页面标题",
								headcolor: 0,
								showhead: 1,
								showfoot: 0,
								stylestate: 'F'
							},
							list: [],
						};

						switch (this.pagetype) {
							case 'index':
								defaultView.title = '首页'
								break;
							case 'my':
								defaultView.title = '我的'
								break;
						}

						console.log(defaultView, 'defaultView==>');

						console.log(this.pageid, 'this.pageid');
						if (this.templateid != undefined && this.templateid.length > 0) {
							designTemplataApi.getDesign(this.templateid, this.pagetype, (res) => {
								console.log(res, "getDesign");
								if (res.code == 200) {
									_this.pageid = res.data.pageid;
								}
								if (res.code == 200 && res.data.design != null) {
									_this.view = res.data.design;
									if (_this.view.options.stylestate == undefined) {
										//_this.view.options.stylestate = 'F'
										_this.$set(_this.view.options, 'stylestate', 'F')

									}
									if (_this.view.options.foot != undefined) {
										_this.$delete(_this.view.options, 'foot')
									}
									_this.footer = res.data.footer;
									_this.viewstyle = res.data.style;
								} else {
									_this.view = defaultView
								}
								console.log(_this.view, 'view1==>');
							});
						} else {
							_this.view = defaultView
							console.log(_this.view, 'view2==>');
						}


					}
				});
			},
			funedit() {
				this.$router.push({
					path: "/system/design/component/seticon",
				})
			},
			funstylesys() {
				// console.log(222, '22==>');
				this.$router.push({
					path: "/system/design/style/style",
				})
			},
			genID() {
				//return Number(Math.random().toString().substr(3,20) + Date.now()).toString(36);
				return (Math.random() * 10000000).toString(16).substr(0, 4) + (new Date()).getTime() + Math.random()
					.toString()
					.substr(2, 5);

			},
			funlikeadd(item) {

				let myitem = JSON.parse(JSON.stringify(item));
				let len = this.view.list.length;
				//item.name = item.name

				myitem.boxid = designTemplataApi.createToolBoxId() + this.genID()
				console.log(myitem.boxid, 'boxid');
				this.view.list.push(myitem)
				console.log(this.view.list, 'view.list');
			},
			funaddbox(item) {
				console.log('funaddbox :item>> ', item);
				this.type = item.no;

				let defaultData = {
					type: this.type, // 组件类型
					//name:item.name,
					//status: 2, // 默认状态
					data: [], // 数据
					options: {}, // 选项操作
				};

				let myoptions = designOptionVal[this.type];
				if (myoptions != undefined) {
					defaultData = JSON.parse(JSON.stringify(myoptions));
					//defaultData.status = 2;
				}
				let len = this.view.list.length;
				defaultData.name = (len + 1) + '.' + item.name
				defaultData.boxid = designTemplataApi.createToolBoxId(),
					this.view.list.push(defaultData)
			},
			getjson() {
				this.computdata = JSON.stringify(this.view);
			},
			toImage() {
				// 手动创建一个 canvas 标签
				const canvas = document.createElement("canvas")
				// 获取父标签，意思是这个标签内的 DOM 元素生成图片
				// imageTofile是给截图范围内的父级元素自定义的ref名称
				let canvasBox = this.$refs.content
				// 获取父级的宽高
				const width = parseInt(window.getComputedStyle(canvasBox).width)
				const height = parseInt(window.getComputedStyle(canvasBox).height)
				// 宽高 * 2 并放大 2 倍 是为了防止图片模糊
				canvas.width = width * 2
				canvas.height = height * 2
				canvas.style.width = width + 'px'
				canvas.style.height = height + 'px'
				const context = canvas.getContext("2d");
				context.scale(2, 2);
				const options = {
					backgroundColor: "white",
					canvas: canvas,
					useCORS: true,
					scale: 1, //设置放大的倍数

				}

				setTimeout(() => {
					html2canvas(canvasBox, options).then((canvas) => {
						// toDataURL 图片格式转成 base64
						let dataURL = canvas.toDataURL("image/png")
						console.log(dataURL)
						this.downloadImage(dataURL)
					})
				}, 200);


			},
			fungotopage(item) {
				this.pageid = "";
				this.templateid = item.tmpid;
				this.pagetype = item.pageno;
				this.pagename = item.pagename;
				this.initdata();
				this.getQrcode()

			},
			funset() {
				this.$router.push({
					path: "/designer/xcxtemplate/setting",
					query: {
						tid: this.templateid
					}
				})
			},
			funstyle() {
				this.$router.push({
					path: "/designer/xcxtemplate/style",
					query: {
						tid: this.templateid
					}
				})
			},
			funtabbar() {
				this.$router.push({
					path: "/designer/xcxtemplate/tabbar",
					query: {
						tid: this.templateid
					}
				})
			},
			//下载图片
			downloadImage(url) {
				// 如果是在网页中可以直接创建一个 a 标签直接下载 
				let a = document.createElement('a')
				a.href = url
				a.download = '首页截图'
				a.click()
			},
			// 提交
			submit() {
				this.$confirm('确认保存此模板吗, 是否保存?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.computdata = JSON.stringify(this.view);
					console.log(JSON.stringify(this.view));
					// JSON 转换会丢失 formData
					const form = JSON.parse(JSON.stringify(this.view));
					console.log(form, "ro");
					// if (form.length == 1) {
					//   this.$message.error("请添加模块！");
					//   return;
					// }

					// for (let i of form) {
					//   if (i.type === "text") {
					//     if (i.title && i.title == 0) {
					//     }
					//   } else if (i.data && i.data.length == 0) {
					//     this.$message.error(i.type + "请填写完整信息！");
					//     return;
					//   }
					//   if (i.type === "product") {
					//     i.data = i.data.map((val) => val.productId).join(",");
					//   }
					// }
					
					
					// designTemplataApi.saveDesign(this.pageid, this.view, (res) => {
					// 	if (res.code == 200) {
					// 		this.$message.success("数据提交成功！");
					// 	} else {
					// 		this.$message.error("数据提交失败，" + res.msg);
					// 	}
					// });
					this.__savetemplate(res => {
						if (res.code == 200) {
							this.$message.success("数据提交成功！");
						} else {
							this.$message.error("数据提交失败，" + res.msg);
						}
					});
				}).catch(() => {});

			},
			__savediandanstyle(data, funsucc) {
				console.log(data, '__savediandanstyle.data');
				console.log(this.pageid, 'this.pageid');
				designTemplataApi.saveDesign(this.pageid, data, (res) => {
					funsucc(res);
				});
			},
			__savetemplate(funsucc) {
				designTemplataApi.saveDesign(this.pageid, this.view, (res) => {
			
					funsucc(res);
			
				});
			},
			funback() {
				this.$router.push("/designer/xcxtemplate/index")
			},
			selectItemHandler(param) {

				let _this = this;
				this.$nextTick(res => {
					if (_this.$refs.property) {
						_this.$refs.property.selectItemHandler(param);
					}

				})
				//
			},
			// 切换视图组件
			selectType(index) {
				let props = this.view.list[index];
				if (props.boxid != undefined) {
					if (props.boxid == this.selboxid) {
						return;
					}
				} else {
					props.boxid = designTemplataApi.createToolBoxId();
				}
				this.selboxid = props.boxid;
				this.selboxindex = index;
				this.isRight = false;
				this.props = props;
				console.log(this.props, "selectType.props");
				this.$nextTick(() => (this.isRight = true));
			},
			// selectfoot() {
			// 	this.selboxindex = -20;
			// 	this.isRight = false;
			// 	this.props = this.view.options.foot;
			// 	console.log(this.props, "selectType.props");
			// 	this.$nextTick(() => (this.isRight = true));
			// },
			selectPage() {
				this.selboxindex = -10;
				this.isRight = false;
				this.props = this.view.options;
				console.log(this.props, "selectType.props");
				this.$nextTick(() => (this.isRight = true));
			},
			selectMenu() {
				this.$refs['tabbar'].funShow(this.templateid)
			},
			deleteItem(e, index) {
				this.$confirm('确认要删除此模块, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '删除成功!'
					});
					e.preventDefault();
					e.stopPropagation();
					this.view.list.splice(index, 1);
					this.isRight = false;
					this.props = {};
				}).catch(() => {});

			},
			// 拖拽类型
			dragStart(e) {
				this.type = e.target.dataset.type;
				console.log(this.type, "dragStart");
			},
			// 结束拖拽
			dragEnd(e) {

				this.$delete(this.view.list[this.index], "status");
				this.isPush = false;
				this.type = null;
			},
			// 已放置到指定位置
			drog(e) {
				if (!this.type) {
					// 内容拖拽
					return;
				}
				e.preventDefault();
				e.stopPropagation();
				this.dragEnd();
			},
			// 移动中
			dragOver(e) {
				console.log(e);
				console.log(this.type, "drag type");
				if (!this.type) {
					// 内容拖拽
					return;
				}
				e.preventDefault();
				e.stopPropagation();

				let className = e.target.className;
				let name = className !== "view-content" ? "item" : "view-content";

				let defaultData = {
					type: this.type, // 组件类型
					status: 2, // 默认状态
					data: [], // 数据
					options: {}, // 选项操作
				};

				let myoptions = designOptionVal[this.type];
				if (myoptions != undefined) {
					defaultData = JSON.parse(JSON.stringify(myoptions));
					defaultData.status = 2;
				}

				console.log("dragStart==>", name, defaultData);
				if (name == "view-content") {
					if (!this.isPush) {
						this.index = this.view.list.length;
						this.isPush = true;

						let myoptions = designOptionVal[this.type];
						console.log(this.type + " :>> ", myoptions);
						this.view.list.push(defaultData);
					}
				} else if (name == "item") {
					let target = e.target;
					let [y, h, curIndex] = [
						e.offsetY,
						target.offsetHeight,
						target.dataset.index,
					];
					let direction = y < h / 2;

					if (!this.isPush) {
						// Push to Top or Bottom
						if (direction) {
							if (curIndex == 0) {
								this.view.list.unshift(defaultData);
							} else {
								this.view.list.splice(curIndex, 0, defaultData);
							}
						} else {
							curIndex = +curIndex + 1;
							this.view.list.splice(curIndex, 0, defaultData);
						}
					} else {
						// Moving
						if (direction) {
							var i = curIndex == 0 ? 0 : curIndex - 1;
							var result = this.view.list[i]["status"] == 2;

							this.selboxindex = i;

						} else {
							var i = +curIndex + 1;
							var result =
								this.view.list.length > i && this.view.list[i]["status"] == 2;
						}
						console.log(`temp->`, result);
						if (result) return;

						const temp = this.view.list.splice(this.index, 1);

						this.view.list.splice(curIndex, 0, temp[0]);
					}

					this.index = curIndex;
					this.isPush = true;
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.desgin_con {
		min-width: 1360px;
		display: flex;
		flex-wrap: wrap;


	}

	.desgin_con .desgin_con_main {
		float: left;
	}

	.desgin_con .desgin_con_top {
		padding-right: 20px;
		text-align: right;
		height: 40px;
		line-height: 40px;
		background: #fff;
		border-bottom: 1px solid #F5F5F5;
		padding-bottom: 1px;
	}

	#darag {
		margin: 0 auto;
		overflow: hidden;
	}

	.menulist {
		float: left;
		width: 80px;
		height: calc(100vh - 51px);
		position: relative;
		background: #fff;
		border-right: 1px solid #f0f0f0;

		// background: #f5f5f5;
		overflow-y: auto;
		overflow-x: hidden;

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #dbdbdb;
		}

		&::-webkit-scrollbar-track {
			background: #f6f6f6;
		}

		.selected {
			background: #3089dc;
			color: #fff;
		}

		.item {
			text-align: center;
			font-weight: 300;
			margin: 15px 0;
			position: relative;
			display: flex;
			align-items: center;
			flex-direction: column;

			.img {
				width: 20px;
				height: 20px;

				img {
					width: 100%;
					height: 100%;
				}
			}

			.txt {
				margin-top: 10px;
				font-size: 12px;
			}
		}

		.sel {
			color: #3388FF;
		}

		.nom {
			cursor: pointer;
			color: #222;
		}
	}

	.dragcont {
		float: right;
		min-width: 550px;
	}

	.dragmenu {
		float: left;
		width: 120px;
		height: calc(100vh - 61px);
		margin-right: 20px;
		position: relative;
		background: #fff;

		// background: #f5f5f5;
		overflow-y: auto;
		overflow-x: hidden;
		box-shadow: 0 2px 6px #ccc;

		.menu {
			position: absolute;
			left: 0;
			bottom: 0;
		}

		&::-webkit-scrollbar {
			width: 6px;
		}

		&::-webkit-scrollbar-thumb {
			background: #dbdbdb;
		}

		&::-webkit-scrollbar-track {
			background: #f6f6f6;
		}

		.selected {
			background: #3089dc;
			color: #fff;
		}

		.item {
			cursor: pointer;
			box-shadow: 0 1px 1px #f0f0f0;
			//border-bottom: 1px solid #ccc;
			height: 50px;
			line-height: 50px;
			text-align: center;
			font-weight: 300;
			font-size: 13px;
			position: relative;

			.el-icon-error {
				position: absolute;
				right: 5px;
				top: 2px;
				color: #ccc;
				font-size: 10px;
				cursor: pointer;
				z-index: 2;
			}

			.el-icon-sort {
				position: absolute;
				right: 5px;
				bottom: 2px;
				color: #ccc;
				font-size: 10px;
				cursor: pointer;
				z-index: 2;
			}
		}
	}

	.left {
		float: left;
		width: 310px;
	}

	.right {
		float: left;
		width: 530px;

		overflow: hidden;
	}

	.main {
		float: left;
		margin: 0 10px;
		padding: 0 10px;
		background: #fff;

		.phone-main {
			width: 375px;
			margin-top: 10px;
			position: relative;
			border: 4px solid #1f1c1c;
			border-radius: 20px;
			overflow: hidden;
		}

		.foot-nav {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			transition: all 0.3s;
			// border-top: 1px solid #f9f9f9;
			// box-shadow: 0px 2px 6px #ccc;
		}

		.selected {
			border: 2px solid #3089dc;
		}

		.top-nav {
			position: absolute;
			top: 0;
			z-index: 999;
			transition: all 0.3s;

			& * {
				pointer-events: none;
			}

			&:hover {
				transform: scale(0.95);
				border-radius: 10px;
				overflow: hidden;
				box-shadow: 0 0 10px #afafaf;
			}

			.tit {
				position: absolute;
				left: 50%;
				bottom: 10px;
				transform: translateX(-50%);
			}

			img {
				width: 375px;
				image-rendering: -moz-crisp-edges;
				image-rendering: -o-crisp-edges;
				image-rendering: -webkit-optimize-contrast;
				image-rendering: crisp-edges;
				-ms-interpolation-mode: nearest-neighbor;
			}
		}

		.view-content-top {
			height: 69px;
		}

		.view-content-bottom {
			height: 65px;
		}

		.view-content {
			position: relative;
			height: calc(100vh - 291px);
			// background: #f5f5f5;
			overflow-y: auto;
			overflow-x: hidden;

			&::-webkit-scrollbar {
				width: 6px;
			}

			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
			}


			.item {
				position: relative;
				width: 375px;
				overflow: hidden;

				//transition: all 0.3s;
				//border-bottom: 1px dashed #ccc;
				&:hover {
					//transform: scale(0.95);

					box-shadow: 0 0 10px #afafaf;

					.el-icon-error {
						display: block;
					}
				}

				// div {
				// 	pointer-events: none;
				// }

				.wait {
					background: #deedff;
					height: 35px;
					text-align: center;
					line-height: 35px;
					font-size: 12px;
					color: #666;
				}

				.el-icon-error {
					position: absolute;
					right: -10px;
					top: -6px;
					color: red;
					font-size: 25px;
					cursor: pointer;
					display: none;
					z-index: 9999;
				}
			}
		}
	}


	.chosen {
		border: solid 2px #3089dc !important;
	}

	.preview {
		position: fixed;
		right: 20px;
		top: 20%;
		width: 150px;
		/* 预览框的宽度 */
		height: 150px;
		// overflow-y: auto;
		/* 垂直滚动 */
		background-color: #fff;

		.tip {
			text-align: center;
			font-size: 10px;
		}
	}

	.preview .sjx {
		position: absolute;
		right: -13px;
		z-index: 999;
		top: 50px;
		border-width: 6px;
		border-color: transparent transparent transparent #fff;
		border-style: dotted dotted dotted solid;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		border: none;
	}
	.pagelist {
		margin-left: 10px;
		margin-right: 10px;
		box-shadow: 0 2px 6px #ccc;
	}
	
	.pagelist .pitem {
		cursor: pointer;
		box-shadow: 0 1px 1px #f0f0f0;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-weight: 300;
		font-size: 13px;
		position: relative;
		width: 90px;
		overflow: hidden;
		white-space: nowrap;
	
	}
	
	.selstyle {
		color: #ffffff;
		background: #3089dc;
	}
	.header {
		height: 50px;
		line-height: 50px;
		// display: flex;
		// align-items: center;
		// justify-content: space-between;
		border-bottom: 1px solid #f0f0f0;

		.logo {
			float: left;
			margin-left: 30px;
			width: 178px;
			height: 40px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.memo {
			margin-left: 20px;
			float: left;
			line-height: 50px;
		}

		.btns {
			float: right;
			margin-right: 50px;
		}
	}
</style>