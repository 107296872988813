<template>
	<div class="pages">
		<div class="alltemp">
			<span @click="funback">模板详情</span>
			<span>></span>
			<span>订单详情</span>
		</div>
		<div class="title">
			订单详情
		</div>
		<div class="order">
			<span>订单编号:{{tempInfo.billno}}</span><span>下单时间: {{tempInfo.createDate}}</span>
			<div class="orderpay">
				<span class="payok"  v-if="tempInfo.paystate == 2">已付款</span><span class="payno"  v-else>未付款</span>
				<!-- <div>
					状态：
					<span v-if="tempInfo.billstate == 0">失败</span>
					<span v-else-if="tempInfo.billstate == 9">取消</span>
					<span v-else>成功</span>
				</div> -->
				<div class="" v-if="tempInfo.paystate == 0">
					<span @click="wxsubmit">立即支付</span>
					<!-- <span @click="wxsubmit">微信支付</span> -->
					<!-- <span @click="zfbsubmit">支付宝支付</span> -->
					<!-- <el-button size="small" type="primary" @click="wxsubmit">微信支付</el-button>
					<el-button size="small" type="primary" @click="zfbsubmit">支付宝支付</el-button> -->
				</div>
			</div>
		</div>
		
		<div class="box" >
			<div class="item">
				<!-- <div>
					订单号：{{tempInfo.billno}}<span  @click="ordercancel" v-if="tempInfo.paystate == 0">取消订单</span>
				</div> -->
				<div class="left">
					<div class="titles">
						付款信息
					</div>
					<div>微信支付</div>
					<div>
						支付金额：{{tempInfo.pricebuy}}
					</div>
					<div>
						开始日期：{{tempInfo.datestart}}
					</div>
					<div>
						到期日期：{{tempInfo.dateend}}
					</div>
				</div>
				<div class="right">
					<div class="titles">
						备注
					</div>
					<div class="memo">
						{{tempInfo.memo}}
					</div>
				</div>
				
				
				
			</div>
			<div class="item" v-if="false">
				<div class="left">
					<img src="../../../assets/image/market/bgt.png"/>
				</div>
				<div class="right">
					<div class="titles">
						{{tempInfo.tmpname}}
					</div>
					<div>
						高端时尚简约设计
					</div>
					<div>
						价格：{{tempInfo.buytime}}
					</div>
					<div>
						购买时长：{{tempInfo.buytime}}
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import orderApi from "../../../api/cus/ordertemp.js";
	export default {
		data() {
			return {
				billno:'',
				tempInfo: {}
			}
		},
		mounted() {
			let billno = this.$route.query.billno
			if (billno != undefined) {
				this.billno = billno
				this.init()
			}
		},
		methods: {
			init() {
				orderApi.orderDetail(this.billno, res => {
					console.log(res, 'res.getDetail');
					if (res.code == 200) {
						this.tempInfo = res.data
						if(this.tempInfo.paystate == 0 && this.tempInfo.moneyfact == 0){
							this.wxsubmit()
						}
					}
				})
			},
			funback(){
				this.$router.go(-1)
			},
			wxsubmit() {
				let _this = this;
				orderApi.requestpay(
				  {
					billno:this.billno,
				    payWay: 10,
				  },
				  (res) => {
				    console.log(res);
				    if (res.code == 200) {
						if(res.data.state == 201){
							this.init()
						}else{
							this.payquery()
						}
				    }
				  }
				);
			},
			payquery(){
				let _this = this;
				orderApi.payquery(
				  {
					billno:this.billno,
				  },
				  (res) => {
				    console.log(res,'payquery.res');
				    if (res.code == 200) {
				      this.$router.push({
				        path: "/customer/xcxtemplate/index",
				      });
				    }
				  }
				);
			},
			zfbsubmit() {
				let _this = this;
				orderApi.requestpay(
				  {
					billno:this.billno,
				    payWay: 11,
				  },
				  (res) => {
				    console.log(res);
				    if (res.code == 200) {
				      this.$router.push({
				        path: "/customer/xcxtemplate/index",
				      });
				    }
				  }
				);
			},
			ordercancel(){
				let _this = this;
				this.$confirm('是否取消订单, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					orderApi.paycancel(
					  {
						billno:this.billno,
					  },
					  (res) => {
					    console.log(res);
					    if (res.code == 200) {
							this.init()
							this.$message({type:"success",message:'订单取消成功'})
					    }
					  }
					);
				}).catch(() => {
				
				});
				
			}
		}
	}
</script>

<style scoped>
	.pages {
		padding: 10px;
		width: 1200px;
		margin: 0 auto;
	}
	.pages .alltemp{
		display: flex;
		align-items: center;
		margin-bottom: 30px;
	}
	.pages .alltemp span:nth-child(1){
		color: #9D9A9A;
		cursor: pointer;
	}
	.pages .alltemp span:nth-child(2){
		color: #9D9A9A;
		padding: 0 10px;
	}
	.pages .alltemp span:nth-child(3){
		font-size: 14px;
		font-weight: 800;
	}
	.pages .title{
		font-size: 24px;
		color: #000000;
		line-height: 24px;
	}
	.pages .order{
		display: flex;
		color: #91929E;
		position: relative;
		padding: 10px 0;
		border-bottom: 1px solid #D3D3D3;
	}
	.pages .order .orderpay{
		position: absolute;
		right: 0;
		top: -35px;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.pages .order .orderpay .payok{
		color: #000;
		font-size: 22px;
	}
	.pages .order .orderpay .payno{
		color: #F65160;
		font-size: 24px;
	}
	.pages .order span{
		padding: 10px;
	}
	.pages .order span:nth-child(1){
		padding-left: 0;
	}
	.pages  .box .item{
		margin: 16px 0;
		padding: 10px 20px;
		border-radius: 10px;
		background-color: #F6F7F9;
		display: flex;
	}
	.pages  .box .item:nth-child(1){
		height: 170px;
	}
	.pages  .box .item:nth-child(2){
		height: 358px;
	}
	.pages  .box .item:nth-child(1) .left{
		color: #91929E;
		width: 50%;
	}
	.pages  .box .item:nth-child(1)  .titles{
		font-size: 16px;
		font-weight: 800;
	}
	.pages  .box .item:nth-child(1)  .memo{
		margin-top: 5px;
	}
	.pages  .box .item:nth-child(1) .left div{
		margin: 10px 0;
	}
	.pages  .box .item:nth-child(1) .right{
		color: #91929E;
		padding-top: 10px;
	}
	.pages  .box .item:nth-child(2) .left{
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.pages  .box .item:nth-child(2) .left img{
		width: 150px;
		height: 305px;
	}
	.pages  .box .item:nth-child(2) .right{
		padding: 30px 0 0 50px;
	}
	.pages  .box .item:nth-child(2) .right .titles{
		color: #3388FF;
		font-size: 18px;
		font-weight: 800;
	}
	.pages  .box .item:nth-child(2) .right div{
		margin: 10px 0;
		color: #383838;
	}
</style>