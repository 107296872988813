import httpapi from '../../utils/httpapi';
const groupApi = {
	//微信群分页查询
	list(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/groupchat/page?mpid='+param.mpid+'&name='+param.name+'&pageno='+param.pageno+'&pagesize='+param.pagesize, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//查询二维码配置明细
	detail(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/qrcode/detail?mxid='+param.mxid, {}).then(res => {
			console.log(res, 'detail.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//向企业微信同步群
	synclist(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/groupchat/sync/list?mpid='+param.mpid, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default groupApi