import httpapi from '../../utils/httpapi';
const keywordApi = {
	//删除关键字
	del(param, funsucc) {
		httpapi.post('/customer/account/cmdtxt/delete', param).then(res => {
			console.log(res, 'del.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//关键字详情
	detail(param, funsucc) {
		httpapi.get('/customer/account/cmdtxt/detail?' + `cmdid=${param.cmdid}&mpid=${param.mpid}`, ).then(res => {
			console.log(res, 'detail.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//首次关注消息设置-启用图文消息
	edit(param, funsucc) {
		httpapi.postjson('/customer/account/cmdtxt/edit', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//查询关键字回复-分页
	page(param, funsucc) {
		httpapi.get('/customer/account/cmdtxt/page?' +
			`mpid=${param.mpid}&pageno=${param.pageno}&pagesize=${param.pagesize}`, {}).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},

}
export default keywordApi