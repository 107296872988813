/**
* 左边菜单
*/
<template>
	<el-menu default-active="2"  router 
		unique-opened class="el-menu-vertical-demo" background-color="#FFFFFF" text-color="#222"
		active-text-color="#00aaff">
		<div class="appinfo">
			<div class="">公司名称:{{appinfo.companyName}}</div>
			<div class="">应用名称:{{appinfo.mpName}}<i class="el-icon-edit" @click="updatemc"></i></div>
			<div class="">应用ID:{{appinfo.appID}}</div>
			<div class="">应用key:{{appinfo.appkey}}</div>
			<div class="">授权状态:{{appinfo.isOpen == 1 ? "已授权" : "未授权"}}</div>
		</div>
		<el-menu-item v-for="(menu,cindex) in allmenu" :index="'/'+menu.url+'?mpid='+mpid" :key="cindex">
			<i class="iconfont" :class="menu.icon"></i>
			<span>{{menu.menuname}}</span>
		</el-menu-item>
		<!-- 服务商界面 -->
		<el-dialog :title="title" :visible.sync="mcShow" width="50%" @click='mcShow = false'>
			<el-form label-width="150px" ref="mcForm" :model="mcForm">
				<el-form-item label="公众号名称" prop="mpname">
					<el-input size="small" v-model="mcForm.mpname" auto-complete="off"
						placeholder="请输入公众号名称"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='mcShow = false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title" @click="funmcAdd()">保存
				</el-button>
			</div>
		</el-dialog>
	</el-menu>
</template>
<script>
	import accountApi from '../../../../../api/cus/account'
	// import svrappApi from '../../../api/cus/svrapp.js'
	export default {
		name: 'leftnav',
		data() {
			return {
				title: "修改应用名称",
				loading: false,
				mcShow: false,
				allmenu: [],
				appinfo: {},
				mpid: '',
				mcForm: {}
			}
		},
		created() {
			let res = {
				success: true,
				data: [
					{
						menuid: 10,
						icon: 'icon-news-manage',
						menuname: '总览',
						hasThird: 'N',
						url: 'customer/home/mp/zhifubaoapp/alls/index',
						menus: null
					},{
						menuid: 11,
						icon: 'icon-news-manage',
						menuname: '小程序配置',
						hasThird: 'N',
						url: 'customer/home/mp/zhifubaoapp/appconfig/index',
						menus: null
					},{
						menuid: 12,
						icon: 'icon-news-manage',
						menuname: '开发配置',
						hasThird: 'N',
						url: 'customer/home/mp/zhifubaoapp/depconfig/index',
						menus: null
					}
				],
				msg: 'success'
			}
			this.allmenu = res.data

		},
		mounted() {


			let mpid = this.$route.query.mpid
			console.log(mpid, 'letnavmpid');
			this.mpid = mpid
			if (mpid != undefined) {} else {
				this.$router.push("/");
			}
			accountApi.info({
				mpid: this.mpid
			}, res => {
				this.appinfo = res.data
			})
		},
		methods: {
			updatemc() {
				this.mcShow = true
			},
			funmcAdd() {
				accountApi.updategzh({
					mpid: this.mpid,
					mpname: this.mcForm.mpname
				}, res => {
					this.mcShow = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '修改成功!'
						})
						this.appinfo.mpName = this.mcForm.mpname
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>
<style>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 240px;
		min-height: 400px;
	}

	.el-menu-vertical-demo:not(.el-menu--collapse) {
		border: none;
		text-align: left;
	}

	.el-menu-item-group__title {
		padding: 0px;
	}

	.el-menu-bg {
		background-color: #F5F5F5 !important;
	}

	.el-menu {
		border: none;
	}

	.logobox {
		height: 40px;
		line-height: 40px;
		color: #9d9d9d;
		font-size: 20px;
		text-align: center;
		padding: 20px 0px;
	}

	.logoimg {
		height: 40px;
	}

	.appinfo {
		padding: 10px;
		line-height: 1.5;
		border-bottom: 1px solid #eee;
		color: #333;
		font-size: 12px;
	}

	.el-icon-edit {
		cursor: pointer;
	}
</style>