<template>
  <div id="divider" :style="{ height: height }">
    <p
      v-show="list.options.type == 2"
      :class="[classname, bjclassname]"
      :style="{ borderBottomColor: list.options.color }"
    ></p>
  </div>
</template>
<script>
export default {
  name: "Divider",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: "",
    };
  },

  created() {
    this.list = this.data;
  },

  methods: {},
  computed: {
    // 辅助空白的高度
    height() {
      if (this.list.options.type == 1) {
        return this.list.options.height + "px";
      } else {
        return "20px";
      }
    },
    // 辅助线的类名
    classname() {
      if (this.list.options.type == 2 && this.list.options.line == 1) {
        return "shi";
      } else if (this.list.options.type == 2 && this.list.options.line == 2) {
        return "xu";
      } else {
        return "dian";
      }
    },
     bjclassname() {
    if (this.list.options.type == 2 && this.list.options.about == 1) {
      return "";
    } else {
      return "about";
    }
  },
  },
  //   有无边距
 
};
</script>

<style lang="less" scoped>
#divider {
  width: 100%;
  height: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  p {
    width: 100%;
  }
}
// 实线
.shi {
  border-bottom: 1px solid black;
}
// 虚线
.xu {
  border-bottom: 1px dashed black;
}
// 点线
.dian {
  border-bottom: 1px dotted black;
}
// 边距
.about {
  margin: 0 20px;
}
</style>