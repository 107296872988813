<template>
	<div class="content">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="16"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<div class="mtitle">版本管理</div>
				<div class="modebox">
					<div class="title">线上版本</div>
					<div class="row">
						<div class="lev" style="align-items: center;">
							<div>版本号</div>
							<div class="m5">{{onlineinfo.release_version}}</div>
						</div>
						<div class="lev w50">
							<div class="row">
								<div>{{onlineinfo.release_desc}}</div>
							</div>
							<div class="row m10">
								<div style="margin-right: 20px;color: #9A9A9A;">发布时间</div>
								<div>{{totime(onlineinfo.release_time)}}</div>
							</div>
							<div class="row">
								<div style="margin-right: 20px;color: #9A9A9A;">项目备注</div>
								<div>增加预览按钮关闭功能</div>
							</div>
						</div>
						<div class="lev m50">
							<el-button type="primary" @click="funshowver">版本回退</el-button>
						</div>
					</div>
				</div>
				
				<div class="modebox" v-if="lastedform.auditid  > 0">
					<div class="title">审核版本</div>
					<div class="row">
						<div class="lev" style="align-items: center;">
							<div>版本号</div>
							<div class="m5"> {{lastedform.user_version}}</div>
							<div class="ztbox" :class="'zt' + lastedform.status">
								<div>
									{{lastedform.status == 0 ? '审核成功' : ''}}{{lastedform.status == 1 ? '审核被拒绝' : ''}}{{lastedform.status == 2 ? '审核中' : ''}}{{lastedform.status == 3 ? '已撤回' : ''}}{{lastedform.status == 4 ? '审核延后' : ''}}
								</div>
							</div>
						</div>
						<div class="lev w50">
							<div class="row m10">
								<div style="margin-right: 20px;color: #9A9A9A;">发布时间</div>
								<div>{{totime(lastedform.submit_audit_time)}}</div>
							</div>
							<div class="row">
								<div style="margin-right: 20px;color: #9A9A9A;">项目备注</div>
								<div>{{lastedform.user_desc}}</div>
							</div>
						</div>
						<div class="lev m50">
							<el-button type="primary" @click="funcancelcheck">
								取消审核
							</el-button>
						</div>
					</div>
					
					<div class="errmsg" v-html="lastedform.reason" v-if="lastedform.status == 1 && lastedform.reason.length > 0">
					</div>
				</div>
				<div class="modebox" v-else>
					<div class="title">审核版本</div>
					<div class="msg">暂无审核版本</div>
				</div>
				<div class="modebox">
					<div class="title">体验版</div>
					<div class="row">
						<div class="lev" style="align-items: center;">
							<div>版本号</div>
							<div class="m5"> {{exp_info.exp_version}}</div>
							<div class="ewmbox" @click="getewm">
								<div>体验版</div>
								<img class="ewmimg" src="../../../../../../assets/image/icon_ewmimg.png" alt="">
							</div>
						</div>
						<div class="lev w50">
							<div class="row m10">
								<div style="margin-right: 20px;color: #9A9A9A;">发布时间</div>
								<div>{{totime(exp_info.exp_time)}}</div>
							</div>
							<div class="row">
								<div style="margin-right: 20px;color: #9A9A9A;">项目备注</div>
								<div>{{exp_info.exp_desc}}</div>
							</div>
						</div>
				
						<div class="lev m50">
				
							<el-dropdown split-button type="primary" @click="handleClick">
								提交审核
								<el-dropdown-menu slot="dropdown">
									<el-dropdown-item>黄金糕</el-dropdown-item>
									<el-dropdown-item>狮子头</el-dropdown-item>
									<el-dropdown-item>螺蛳粉</el-dropdown-item>
									<el-dropdown-item>双皮奶</el-dropdown-item>
									<el-dropdown-item>蚵仔煎</el-dropdown-item>
								</el-dropdown-menu>
							</el-dropdown>
						</div>
					</div>
				</div>
				<el-dialog title="小程序版本回退" :visible.sync="vershow" width="40%" @click='vershow = false'>
					<div class="" style="margin-bottom: 20px;">你可选择回退到以下版本，最多保存最近发布或回退的5个版本。选择版本时请注意是否包含插件版本更新，可以在详情中查看插件版本。
					</div>
					<div class="vtitle">
						<div class="v20">版本</div>
						<div class="v50">更新原因</div>
						<div class="v30">更新时间</div>
					</div>
					<div class="vcont">
						<div class="vitem" v-for="(item,index) in historylist" :key="index">
							<div class="v20">
								<el-radio v-model="radio" :label="index" @change="funselver">{{item.user_version}}</el-radio>
							</div>
							<div class="v50">{{item.user_desc}}</div>
							<div class="v30">{{totime(item.user_version)}}</div>
						</div>
					</div>
				
					<div class="footer">
						<el-button size="medium" class="savebtn" type="primary" @click='funback'>确定</el-button>
					</div>
				</el-dialog>
				<el-dialog title="扫码访问体验版" :visible.sync="ewmshow" width="40%" @click='ewmshow = false'>
					<div class="ewmtitle">管理员体验者可扫描下方二维码即可体验体验版</div>
					<div class="center">
						<div class="imgbox">
							<img class="ewmimg2" :src="ewmimg" alt="">
						</div>
						<div>体验版</div>
					</div>
					<div class="footer">
						<el-button size="medium" class="savebtn" type="primary" @click='ewmshow = false'>确定</el-button>
					</div>
				</el-dialog>
				</div>
				</div>
	
	</div>
</template>

<script>
	import accountApi from '../../../../../../api/cus/account'
	import utils from '../../../../../../utils/util.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				list: [],
				historylist: [],
				lastedform: {

				},
				onlineinfo: {
					release_desc: '',
					release_time: 0,
					release_version: ''
				},
				exp_info: {
					exp_desc: "",
					exp_time: 0,
					exp_version: ""
				},
				ewmimg: '',
				ewmshow: false,
				radio: '',
				vershow: false
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			if (mpid != undefined) {} else {
				this.$router.push("/");
			}

			this.getonline()
			this.getlasted()
			
		},
		methods: {
			gethistory() {
				accountApi.coderhistory(this.mpid, res => {
					console.log(res, 'res.gethistory');
					if (res.code == 200) {
						if (res.data.errcode == 0) {
							this.historylist = res.data.version_list
						} else {
							this.$message({
								message: res.data.errmsg,
								type: 'error'
							})
						}

					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})

			},
			getonline() {
				accountApi.coderonline(this.mpid, res => {
					console.log(res, 'res.getonline');
					if (res.code == 200) {
						if (res.data.errcode == 0) {
							this.onlineinfo = res.data.release_info
							this.exp_info = res.data.exp_info
						} else {
							this.$message({
								message: res.data.errmsg,
								type: 'error'
							})
						}

					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})

			},
			getlasted() {
				accountApi.coderauditlasted(this.mpid, res => {
					console.log(res, 'res.getlasted');
					if (res.code == 200) {
						if (res.data.errcode == 0) {
							this.lastedform = res.data

							console.log(this.lastedform, 'lastedform');
						} else if (res.data.errcode == 85058) {
							this.$message({
								message: '暂无最近提审信息',
								type: 'error'
							})
						} else {
							this.$message({
								message: res.data.errmsg,
								type: 'error'
							})
						}

					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			getewm() {
				accountApi.getappewm(this.mpid, res => {
					console.log(res, 'res.getewm');
					if (res.code == 200) {
						this.ewmimg = `data:image/png;base64,${res.data.img}`
						console.log(this.ewmimg, 'ewmimg');
						this.ewmshow = true
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			handleClick() {
				this.$router.push({
					path: './submitapp?mpid=' + this.mpid
				})
			},
			funcancelcheck() {
				accountApi.audit_cancel(this.mpid, res => {
					console.log(res, 'res.funcancelcheck');
					if (res.data.errcode == 0) {
						this.$message({
							message: res.msg,
							type: 'success'
						})
					} else {
						this.$message({
							message: res.data.errmsg,
							type: 'error'
						})
					}
				})
			},
			funback(){
				if(this.radio = ''){
					this.$message({
						message:'请选择需要回退的版本',
						type:'error'
					})
					return
				}
				console.log(this.historylist,'historylist');
				console.log(this.radio,'radio');
				let selindex = parseInt(this.radio)
				let param ={
					version:this.historylist[selindex].user_version,
					mpid:this.mpid
				}
				console.log(param,'param');
				accountApi.verback(param, res => {
					console.log(res, 'res.funback');
					if (res.data.errcode == 0) {
						this.$message({
							message: res.msg,
							type: 'success'
						})
					} else {
						this.$message({
							message: res.data.errmsg,
							type: 'error'
						})
					}
				})
			},
			funshowver() {
				this.vershow = true
				this.gethistory()
				this.radio = ''
			},
			funselver(e) {
				console.log(e, 'e');
				this.radio = e
			},
			totime(time) {

				return utils.timestampToDate(time)
			}
		}
	}
</script>

<style scoped>
	.content {
		padding: 20px;
		height: 100vh;
		background:#F5F7FA;
		overflow: auto;
	}

	.content .mtitle {
		font-size: 16px;
		font-weight: 700;
		padding: 20px;
	}

	.content .title {
		font-size: 16px;
		margin: 0 0 20px 0;
	}

	.content .modebox {
		width: 90%;
		margin-top: 10px;
		background-color: #Ffffff;
		border-radius: 4px;
		box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
		padding: 30px 30px;
		margin: 20px auto 0 auto;
	}

	.content .modebox .lev {
		display: flex;
		flex-direction: column;

	}

	.ewmbox {
		width: 60px;
		height: 20px;
		border-radius: 3px;
		border: 1px solid #00aaff;
		color: #00aaff;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.ewmbox .ewmimg {
		width: 13px;
		height: 13px;
	}

	.ewmtitle {
		width: 100%;
		text-align: center;
	}

	.center {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin: 20px 0;
	}

	.center .imgbox {
		width: 200px;
		height: 200px;


	}

	.center .imgbox .ewmimg2 {
		width: 100%;
		height: 100%;
	}

	.footer .savebtn {
		margin: 0 auto;
		width: 150px;
	}

	.footer {
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}

	.msg {
		width: 100%;
		line-height: 58px;
		text-align: center;
		color: #9A9A9A;
	}

	.w50 {
		width: 60%;
		padding-left: 30px;
		margin: 0 150px 0 50px;

	}

	.m50 {
		margin-left: 50px;
	}

	.row {
		display: flex;
		align-items: center;
	}

	.m10 {
		margin: 10px 0;
	}

	.m5 {
		margin: 5px 0;
	}

	.ztbox {
		width: 80px;
		height: 20px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 9px;
	}

	.zt2 {
		border: 1px solid #cfcf00;
		color: #cfcf00;
	}

	.zt0 {
		border: 1px solid #00aa00;
		color: #00aa00;
	}

	.zt1 {
		border: 1px solid #ff0000;
		color: #ff0000;
	}

	.zt3 {
		border: 1px solid #8c8c8c;
		color: #8c8c8c;
	}

	.zt4 {
		border: 1px solid #00aaff;
		color: #00aaff;
	}

	.errmsg {
		margin-top: 10px;
		color: #ff0000;
	}

	.vtitle {
		display: flex;
		width: 100%;
		background: #F6F8F9;
		line-height: 40px;
	}

	.vcont {
		min-height: 400px;
	}

	.vcont .vitem {
		display: flex;
		width: 100%;
		height: 60px;
		align-items: center;

	}
	.page{
		
	}
	.rightbox{
		padding-top:20px;
		padding-bottom:20px;
		width: 1320px;
		overflow:auto;
	}
	.pagecontent{
		
		
		height: calc(100vh - 81px);
	}
	.v20 {
		width: 20%;
		text-align: center;

	}

	.v50 {
		width: 50%;
	}

	.v30 {
		width: 30%;
	}
</style>