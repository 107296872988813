<template>
	<div class="qycontent" v-if="editFormVisible">
		<!-- 编辑界面 -->
		<p class="title">{{title}}</p>
		<!-- <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'> -->
		<el-form label-width="150px" ref="editForm" :model="editForm">
			<!-- <el-form-item label="open_appid" prop="open_appid">
					<el-input size="small" v-model="editForm.open_appid" auto-complete="off"
						placeholder="请输入open_appid"></el-input>
				</el-form-item> -->

			<el-form-item label="开放平台" >
				第三方平台
			</el-form-item>

			<el-form-item label="企业微信名称" prop="mpname">
				<el-input size="small" v-model="editForm.mpname" auto-complete="off" placeholder="请输入企业微信名称" style="width: 250px;"></el-input>
			</el-form-item>
			
			<div v-if="editForm.qiyetype == 0">
				<el-form-item label="企业号" prop="corpid">
					<el-input size="small" v-model="editForm.corpid" auto-complete="off" placeholder="请输入企业号"></el-input>
				</el-form-item>
				<el-form-item label="应用id" prop="appID">
					<el-input size="small" v-model="editForm.appID" auto-complete="off"
						placeholder="请输入应用id"></el-input>
				</el-form-item>
				<el-form-item label="AES应用密钥" prop="aeskey">
					<el-input size="small" v-model="editForm.aeskey" auto-complete="off"
						placeholder="请输入应用密钥"></el-input>
				</el-form-item>
				<el-form-item label="应用秘钥" prop="appSecret">
					<el-input size="small" v-model="editForm.appSecret" auto-complete="off" placeholder="请输入应用秘钥"></el-input>
				</el-form-item>
				<el-form-item label="通讯录密钥" prop="bookSecret">
					<el-input size="small" v-model="editForm.bookSecret" auto-complete="off" placeholder="请输入通讯录密钥"></el-input>
				</el-form-item>
				<el-form-item label="客户联系密钥" prop="contactSecret">
					<el-input size="small" v-model="editForm.contactSecret" auto-complete="off" placeholder="请输入客户联系密钥"></el-input>
				</el-form-item>
			</div>




		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button style="width: 150px;margin-right: 20px;"  size="medium" @click='funClose'>取消</el-button>
			<el-button style="width: 150px;margin-left: 20px;" size="medium" type="primary" :loading="loading" class="title" @click="submitForm('editForm')">保存
			</el-button>
		</div>
		<!-- </el-dialog> -->
	</div>
</template>

<script>
	import accountApi from '../../../api/cus/account.js'
	export default {
		data() {
			return {
				title: '',
				loading: false,
				editForm: {
					"mpid": "",
					"unitno": 0,
					"aeskey": "",
					"appID": "",
					"appSecret": "",
					"corpid": "",
					"mpname": "",
					"bookSecret": "",
					"contactSecret": ""
				},
				openList: [{
						id: 0,
						mc: '开发者模式'
					},
					{
						id: 1,
						mc: '开放平台'
					},
				],
				qiyelist: [{
						id: 0,
						mc: '自建应用'
					},
					{
						id: 1,
						mc: '第三方应用'
					},
					{
						id: 2,
						mc: '代开发应用'
					},
				],
				editFormVisible: false
			}
		},
		methods: {
			funShow(title) {
				this.title = title
				this.editFormVisible = true
			},
			funClose() {
				this.editFormVisible = false
				this.$emit('funCloseReslut', {
					zt: 'wxqywx'
				})
			},
			submitForm() {
				if (this.editForm.qiyetype == 0) {
					this.adddevp();
				} else if (this.editForm.qiyetype == 1) {
					this.addisv();
				} else if (this.editForm.qiyetype == 2) {
					this.addproxy();
				} else {
					this.$message({
						type: 'error',
						message: '请选择应用类型'
					})
				}
			},
			addproxy() {
				let param = {
					"mpname": this.editForm.mpname
				}
				console.log(param, 'addqywx.param');
				accountApi.addqyproxy(param, res => {
					console.log(res, 'addqywx.res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			addisv() {
				let param = {
					"mpname": this.editForm.mpname
				}
				console.log(param, 'addqywx.param');
				accountApi.addqywxisv(param, res => {
					console.log(res, 'addqywx.res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			adddevp() {
				let param = {
					"mpid": this.editForm.mpid,
					"unitno": this.editForm.unitno,
					"appID": this.editForm.appID,
					"aeskey": this.editForm.aeskey,
					"mpname": this.editForm.mpname,
					"corpid": this.editForm.corpid,
					"appSecret": this.editForm.appSecret,
					"bookSecret": this.editForm.bookSecret,
					"contactSecret": this.editForm.contactSecret
				}
				console.log(param, 'addqywx.param');
				accountApi.addqywxdevp(param, res => {
					console.log(res, 'addqywx.res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped lang="less">

	
	.title {
		margin: 10px 0;
		text-align: center;
	}

	.dialog-footer {
		text-align: center;
	}
</style>