<template>
  <div id="search">
    <div class="details">
      <!-- 显示位置  -->
      <div class="list">
        <div class="title">
          <span>显示位置 </span>
          <span
            >{{ list.options.display == 1 ? "滚动至顶部固定" : "正常模式" }}
          </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.display">
            <el-tooltip
              class="item"
              effect="dark"
              content="滚动至顶部固定"
              placement="bottom"
            >
              <el-radio-button :label="1">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="正常模式"
              placement="bottom"
            >
              <el-radio-button :label="2">A</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 展示模式 -->
      <div class="list">
        <div class="title">
          <span>显示位置 </span>
          <span
            >{{
              list.options.displaymode == 1 ? "常驻模式" : "上滑消失下滑出现"
            }}
          </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.displaymode">
            <el-tooltip
              class="item"
              effect="dark"
              content="常驻模式"
              placement="bottom"
            >
              <el-radio-button :label="1">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="上滑消失下滑出现"
              placement="bottom"
            >
              <el-radio-button :label="2">A</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 框体形式 -->
      <div class="list">
        <div class="title">
          <span>框体样式 </span>
          <span>{{ list.options.frame == 1 ? "方形" : "圆形" }} </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.frame">
            <el-tooltip
              class="item"
              effect="dark"
              content="方形"
              placement="bottom"
            >
              <el-radio-button :label="1">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="圆形"
              placement="bottom"
            >
              <el-radio-button :label="2">A</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 文本位置 -->
      <div class="list">
        <div class="title">
          <span>框体样式 </span>
          <span>{{ list.options.framealign == 1 ? "居左" : "居中" }} </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.framealign">
            <el-tooltip
              class="item"
              effect="dark"
              content="居左"
              placement="bottom"
            >
              <el-radio-button :label="1">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="居中"
              placement="bottom"
            >
              <el-radio-button :label="2">A</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 框体高度 frameheight -->
      <div class="list1">
        <div class="title">
          <span>框体高度 </span>
        </div>
        <div class="btn2">
          <el-slider v-model="list.options.frameheight" show-input :max="60"> </el-slider>
        </div>
      </div>
      <!-- 扫一扫 -->
      <div class="list">
        <div class="title">
          <span>扫一扫 </span>
          <span>{{ list.options.show ? "显示" : "不显示" }} </span>
        </div>
        <div class="btn">
          <el-switch
            v-model="list.options.show"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
      <!-- 背景颜色 -->
      <div class="list">
        <div class="title">
          <span>背景颜色</span>{{ list.options.background || "默认"
          }}<span></span>
        </div>
        <div class="btn">
          <el-button type="text" class="reset">重置</el-button
          ><el-color-picker
            v-model="list.options.background"
            size="medium"
          ></el-color-picker>
        </div>
      </div>
      <!-- 框体颜色 -->
      <div class="list">
        <div class="title">
          <span>框体颜色</span>{{ list.options.framecolor || "默认"
          }}<span></span>
        </div>
        <div class="btn">
          <el-button type="text" class="reset">重置</el-button
          ><el-color-picker
            v-model="list.options.framecolor"
            size="medium"
          ></el-color-picker>
        </div>
      </div>
      <!-- 文本颜色 -->
      <div class="list">
        <div class="title">
          <span>文本颜色</span>{{ list.options.textcolor || "默认"
          }}<span></span>
        </div>
        <div class="btn">
          <el-button type="text" class="reset">重置</el-button
          ><el-color-picker
            v-model="list.options.textcolor"
            size="medium"
          ></el-color-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: {},
    };
  },

  created() {
    this.list = this.data;
  },
  methods: {},
};
</script>

<style lang="less" scoped>
#search {
  margin: 20px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.list1 {
  margin-bottom: 20px;
}
.title {
  font-size: 14px;

  :first-child {
    padding-right: 10px;

    color: #969799;
  }
}
.btn {
  display: flex;
  align-items: center;
  :first-child {
  }
  .reset {
    margin-right: 10px;
  }
}
</style>