import httpapi from '../../utils/httpapi';
const styleApi = {
	//系统风格列表
	list(param, funsucc) {
		httpapi.post('/system/design/style/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//系统风格编辑
	edit(param, funsucc) {
		httpapi.postjson('/system/design/style/edit', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	clslist(funsucc){
		httpapi.post('/system/design/style/cls').then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	}
}
export default styleApi
