<template>
	<div class="">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>欢迎语</span>
				<el-alert
				    title="因企业微信限制，若使用成员已在「企业微信后台」配置了欢迎语，这里的欢迎语将不会生效"
				    type="warning"
				    show-icon :closable="false" style="width: 60%;">
				  </el-alert>
			</div>
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="门店" prop="mdmc">
					<el-select v-model="ruleForm.mdmc" placeholder="请选择门店">
						<el-option v-for="item in mdlist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="欢迎语" prop="xcxurl">
					<el-input v-model="ruleForm.xcxurl" placeholder="请输入入群欢迎语" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="小程序原始ID" prop="xcxurl">
					<el-input v-model="ruleForm.xcxurl" placeholder="请输入入群小程序原始ID" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="小程序路径" prop="xcxurl">
					<el-input v-model="ruleForm.xcxurl" placeholder="请输入小程序路径" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="封面" prop="xcxewm">
					<el-image :src="ruleForm.picurl" style="width: 100px;height: auto;"></el-image>
					<div><el-button size="small" @click.stop="funUpload()">上传图片</el-button></div>
				</el-form-item>
				
				<el-form-item label="赠券" prop="xcxurl">
					<el-button type="primary" @click="funaddcoupon('ruleForm')">添加券</el-button>
					<el-table :data="tableData" border style="width: 100%">
						<el-table-column prop="couponmc" label="券名称" width="180">
							<template slot-scope="scope">
								<el-select v-model="scope.row.couponmc" placeholder="请选择门店" filterable>
									<el-option v-for="item in couponlist" :key="item.id" :label="item.mc" :value="item.id">
									</el-option>
								</el-select>
							</template>
						</el-table-column>
						<el-table-column prop="id" label="券ID">
						</el-table-column>
						<el-table-column prop="number" label="数量">
							<template slot-scope="scope">
								<el-input-number v-model="scope.row.number"  :min="1" :max="10" label="数量"></el-input-number>
							</template>
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="100">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="fundelcoupon(scope.$index)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
				</el-form-item>
			</el-form>
		</el-card>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import selImgs from "@/components/system/selImgs";
	export default {
		components: {
			selImgs,
		},
		data() {
			return {
				mdlist: [

				],
				couponlist:[],
				ewmForm:{},
				ruleForm: {
					xcxurl: '',
					region: '',
					date1: '',
					date2: '',
					delivery: false,
					type: [],
					resource: '',
					desc: ''
				},
				ewmVisible:false,
				tableData: [{
					id:100001,
					couponmc:'30元代金券',
					number:2
				}, {
					id:100001,
					couponmc:'100元代金券',
					number:1
				},],
				rules: {
					
				}
			};
		},
		methods: {
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						alert('submit!');
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			funUpload() {
				this.$refs["selImgs"].funShow()
			},
			funaddcoupon(){
				this.tableData.push( {
					id:'',
					couponmc:'',
					number:1
				})
			},
			fundelcoupon(index){
				console.log(index);
				this.tableData.splice(1,index)
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.ruleForm.picurl = imgitem.url
			},
		}
	}
</script>

<style scoped>
	.clearfix{
		display: flex;
		align-items: center;
	}
	.title-tips {
		padding-left: 28px;
		font-weight: 650;
		font-style: normal;
		color: #f59a23;
		font-size: 14px;
	}
</style>