var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content",style:({background:_vm.bgcolor,'height':_vm.height})},[(_vm.list.data && _vm.list.data.headimg)?_c('div',{staticClass:"shopbg",style:({
		'height':_vm.list.data.headimg.height+ 'px',
	})},[_c('img',{attrs:{"src":_vm.list.data.headimg.url}})]):_vm._e(),(_vm.list.data && _vm.list.data.logo )?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.logo,"dragkey":'logo'},on:{"selectHandler":_vm.selectHandler}},[_c('div',{style:({
		'overflow': 'hidden',
		'width':_vm.list.data.logo.width+ 'px',
		'height':_vm.list.data.logo.height+ 'px',
		'border-radius':_vm.list.data.logo.borderradius + 'px'
	})},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.logourl}})])]):_vm._e(),(_vm.list.data && _vm.list.data.shopname)?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.shopname,"dragkey":'shopname'},on:{"selectHandler":_vm.selectHandler}},[_c('div',{staticClass:"shopname",style:({
		'width':_vm.list.data.shopname.width+ 'px',
		'height':_vm.list.data.shopname.height+ 'px',
		'line-height':_vm.list.data.shopname.height+ 'px',
		'color':_vm.list.data.shopname.titlecolor,
		'font-size':_vm.getsize(_vm.list.data.shopname.size),
		'font-weight':_vm.list.data.shopname.bold == 1 ? 700 : 300,
		'text-align':_vm.list.data.shopname.center == 1 ? 'center' : '' 
	})},[_vm._v(" "+_vm._s(_vm.list.data.shopname.title)+" ")])]):_vm._e(),(_vm.list.data && _vm.list.data.deskname && _vm.list.data.deskname.isflag)?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.deskname,"dragkey":'deskname'},on:{"selectHandler":_vm.selectHandler}},[_c('div',{staticClass:"shopname",style:({
		'width':_vm.list.data.deskname.width+ 'px',
		'height':_vm.list.data.deskname.height+ 'px',
		'line-height':_vm.list.data.deskname.height+ 'px',
		'color':_vm.list.data.deskname.titlecolor,
		'font-size':_vm.list.data.deskname.size > 10 ? _vm.list.data.deskname.size +'px': (_vm.list.data.deskname.size == 3 ? '12px' :(_vm.list.data.deskname.size == 2 ? '14px':'16px')),
		'font-weight':_vm.list.data.deskname.bold == 1 ? 700 : 300,
		'text-align':_vm.list.data.deskname.center == 1 ? 'center' : '' 
	})},[_vm._v(" "+_vm._s(_vm.list.data.deskname.title)+" ")])]):_vm._e(),(_vm.list.data && _vm.list.data.notice)?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.notice,"dragkey":'notice'},on:{"selectHandler":_vm.selectHandler}},[(_vm.list.data && _vm.list.data.notice)?_c('div',{staticClass:" notice",style:({
		'width':_vm.list.data.notice.width+ 'px',
		'height':_vm.list.data.notice.height+ 'px',
		'color':_vm.list.data.notice.titlecolor,
		'font-size':_vm.list.data.notice.size > 10 ? _vm.list.data.notice.size +'px': (_vm.list.data.notice.size == 1 ? '12px' :(_vm.list.data.notice.size == 2 ? '14px':'16px')),
		'font-weight':_vm.list.data.notice.bold == 1 ? 700 : 300,
		'text-align':_vm.list.data.notice.center == 1 ? 'center' : ''
	})},[_vm._v(" "+_vm._s(_vm.list.data.notice.title)+" ")]):_vm._e()]):_vm._e(),(_vm.list.data && _vm.list.data.phone && _vm.list.data.phone.isflag)?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.phone,"dragkey":'phone'},on:{"selectHandler":_vm.selectHandler}},[_c('div',{style:({
			'overflow': 'hidden',
			'width':_vm.list.data.phone.width+ 'px',
			'height':_vm.list.data.phone.height+ 'px',
			'border-radius':_vm.list.data.phone.borderradius + 'px'
		})},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.phoneurl}})])]):_vm._e(),(_vm.list.data && _vm.list.data.map && _vm.list.data.map.isflag)?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.map,"dragkey":'map'},on:{"selectHandler":_vm.selectHandler}},[_c('div',{style:({
		'overflow': 'hidden',
		'width':_vm.list.data.map.width+ 'px',
		'height':_vm.list.data.map.height+ 'px',
		'border-radius':_vm.list.data.map.borderradius + 'px'
	})},[_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.mapurl}})])]):_vm._e(),(_vm.list.data && _vm.list.data.more && _vm.list.data.more.isflag)?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.more,"dragkey":'more'},on:{"selectHandler":_vm.selectHandler}},[(_vm.list.data && _vm.list.data.more )?_c('div',{staticClass:" notice",style:({
		'width':_vm.list.data.more.width+ 'px',
		'height':_vm.list.data.more.height+ 'px',
		'color':_vm.list.data.more.titlecolor,
		'font-size':_vm.list.data.more.size > 10 ? _vm.list.data.more.size +'px': (_vm.list.data.more.size == 1 ? '12px' :(_vm.list.data.more.size == 2 ? '14px':'16px')),
		'font-weight':_vm.list.data.more.bold == 1 ? 700 : 300,
		'text-align':_vm.list.data.more.center == 1 ? 'center' : ''
	})},[_vm._v(" "+_vm._s(_vm.list.data.more.title)+" ")]):_vm._e()]):_vm._e(),(_vm.list.data && _vm.list.data.linename && _vm.list.data.linename.isflag)?_c('dragBox',{attrs:{"allowdel":0,"data":_vm.list.data.linename,"dragkey":'linename'},on:{"selectHandler":_vm.selectHandler}},[(_vm.list.data && _vm.list.data.linename )?_c('div',{staticClass:" notice",style:({
		'width':_vm.list.data.linename.width+ 'px',
		'height':_vm.list.data.linename.height+ 'px',
		'color':_vm.list.data.linename.titlecolor,
		'font-size':_vm.list.data.linename.size > 10 ? _vm.list.data.linename.size +'px': (_vm.list.data.linename.size == 1 ? '12px' :(_vm.list.data.linename.size == 2 ? '14px':'16px')),
		'font-weight':_vm.list.data.linename.bold == 1 ? 700 : 300,
		'text-align':_vm.list.data.linename.center == 1 ? 'center' : ''
	})},[_vm._v(" "+_vm._s(_vm.list.data.linename.title)+" ")]):_vm._e()]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }