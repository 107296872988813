<template>
	<div class="">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="16"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>自定义菜单</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div class="box-cont">
						<div class="box-left">
							<img src="../../../../../../assets/img/nav.png" />
							<div class="bottom">
								<div class="item1">
									<img src="../../../../../../assets/img/hf.svg" />
								</div>
								<div class="itemcon">
									<div class="item3 w33" v-for="(item,index) in  menulist" :key="index">
										<div class="name" :class="currIndex == index ? 'active' : ''"
											@click="funmenuClick(index)">
											<div>{{item.menuName}}</div>
				
											<div class="clist">
												<div class="adds" :class="currcIndex == cindex ? 'active' : ''" @click.stop="funAddchildmenu(citem,cindex)" v-if="currIndex== index"
													v-for="(citem,cindex) in item.sub_button" :key="cindex">
													{{citem.menuName}}
												</div>
				
												<div class="adds" @click.stop="funAddcmenu(item)"
													v-if="!(item.sub_button && item.sub_button.length > 5) && currIndex== index">
													<span>添加子菜单</span>
													<!-- <div class="del" @click.stop="fundelcmenu(citem)"
														v-if="!(citem.sub_button && citem.sub_button.length > 5)">
														<i class="el-icon-delete"></i>
													</div> -->
												</div>
				
											</div>
											<!-- <div class="dels" @click.stop="fundelcmenu(item)"
												v-if="!(item.sub_button && item.sub_button.length > 5) && currIndex== index">
												<i class="el-icon-delete"></i>
											</div> -->
										</div>
									</div>
				
									<div class="add w33" @click="funAddmenu" v-if="menulist.length < 3">
										<i class="el-icon-plus" v-if="menulist.length > 0"></i>
										<span v-else>添加菜单</span>
									</div>
								</div>
				
							</div>
						</div>
						<div class="box-right">
							<div class="box" v-if="menulist.length == 0">
								你未添加自定义菜单，点击左侧添加菜单为公众号创建菜单栏。
							</div>
							<div class="box" v-else>
								<div class="title">菜单信息</div>
								<div style="float: right;">
									<el-button type="primary" size="small" @click="funFbSave">发布</el-button>
								</div>
								<div class="item">
									<span class="label">菜单名称:</span><el-input type="text" size="small" v-model="menufrom.menuName"></el-input>
								</div>
								
								<div class="item">
									<span class="label">类型：</span>
									<el-radio-group v-model="menufrom.menuType" >
										<el-radio label="click">点击</el-radio>
										<el-radio label="view">链接</el-radio>
										<el-radio label="miniprogram">小程序</el-radio>
									</el-radio-group>
								</div>
								<div class="item" v-if="menufrom.menuType == 'click'">
									<span class="label">推送类型：</span>
									<el-radio-group v-model="menufrom.appType">
										<el-radio label="txt">文本</el-radio>
										<el-radio label="news">图文</el-radio>
										<el-radio label="app">应用</el-radio>
										<el-radio label="miniprogram">小程序</el-radio>
										<el-radio label="kf">个性化</el-radio>
									</el-radio-group>
								</div>
								
								<div class="item" v-if="menufrom.menuType == 'click'">
									<span class="label">菜单关键字:</span><el-input type="text" size="small" v-model="menufrom.menuKey"></el-input>
								</div>
								<div class="item">
									<span class="label">内容:</span><el-input type="textarea" size="small" style="width: 300px;"
										v-model="menufrom.textContent"></el-input>
								</div>
								
								<!-- <div class="item">
									查询方式：
									<el-radio-group v-model="menufrom.cxtype">
										<el-radio :label="3">固定</el-radio>
										<el-radio :label="6">动态</el-radio>
									</el-radio-group>
								</div> -->
								<div class="item" v-if="menufrom.menuType == 'view' || menufrom.menuType == 'miniprogram'">
									<span class="label">网页链接:</span><el-input type="text" size="small" v-model="menufrom.menuurl"></el-input>
								</div>
								<div class="item">
									<span class="label">小程序APPID:</span><el-input type="text" size="small" v-model="menufrom.miniappid"></el-input>
								</div>
								<div class="item">
									<span class="label">小程序页面路径：</span><el-input type="text" size="small" v-model="menufrom.miniapppath"></el-input>
								</div>
								<div class="item">
									<span class="label">序号：</span><el-input type="text" size="small" v-model="menufrom.orderNo"></el-input>
								</div>
								<el-button type="primary" size="small" @click="funAddSave">保存</el-button>
								<el-button  size="small" @click="fundelcmenu" v-if="menufrom.sub_button != undefined && menufrom.sub_button.length > 0">删除</el-button>
							</div>
						</div>
					</div>
				
				</el-card>
				</div>
				</div>
		
	</div>
</template>

<script>
	import menuApi from "../../../../../../api/cus/menu.js"
	import leftnav from '../../../../../../components/customer/home/mp/weixingzh/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				currIndex: 0,
				currcIndex:0,
				menufrom: {
					menuType:'click',
					appType:'txt'
				},
				menulist: [],
				mpid: ""
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.mpid = mpid
			} else {
				this.$router.push("/");
			}
			this.getmenu()
		},
		methods: {
			getmenu() {
				let param = {
					mpid: this.mpid
				}
				menuApi.list(param, res => {
					console.log(res, 'menures');
					if (res.code == 200) {
						// let list = []
						// res.data.forEach(item => {
						// 	let clist = []

						// 	if (item.sub_button && item.sub_button.length > 0) {

						// 		item.sub_button.forEach(citem => {
						// 			clist.push({
						// 				id: citem.menuId,
						// 				pid:item.menuId,
						// 				mc: citem.menuName,
						// 				orderno: citem.orderNo,

						// 			})
						// 		})
						// 	}
						// 	list.push({
						// 		id: item.menuId,
						// 		mc: item.menuName,
						// 		orderno: item.orderNo,
						// 		list: clist

						// 	})
						// })
						this.menulist = res.data
					}

				})
			},
			funAddmenu() {

				let mychkitem = this.menulist.find(i => {
					return i.id == '0'
				})
				if (mychkitem != undefined) {
					alert('unSAVE')
					return;
				}
				this.menulist.push({
					// menuId: '0',
					// menuName: '菜单名称',
					// orderno: this.menulist.length + 1,
					// sub_button: [],
					"menuId": "",
					"pmenuId": '0',
					"menuName": "新菜单",
					"menuKey": "",
					"menuType": "click",
					"menuURL": "",
					"appType": "txt",
					"miniappid": "",
					"miniapppath": "",
					"miniappparam": "",
					"textContent": "",
					"contentXML": "",
					"kfAccount": "",
					"groupId": "",
					"queryType": "",
					"queryCount": 0,
					"orderNo": this.menulist.length + 1,
					"sub_button": []
				})
				this.menufrom = {
					"menuId": "",
					"pmenuId": '0',
					"menuName": "新菜单",
					"menuKey": "",
					"menuType": "click",
					"menuURL": "",
					"appType": "txt",
					"miniappid": "",
					"miniapppath": "",
					"miniappparam": "",
					"textContent": "",
					"contentXML": "",
					"kfAccount": "",
					"groupId": "",
					"queryType": "",
					"queryCount": 0,
					"orderNo": 0,
					"sub_button": []
				}
				this.currIndex = this.menulist.length - 1
			},
			funAddcmenu(item) {
				console.log(item, 'item');
				if (item.sub_button && item.sub_button.length >= 5) {
					return
				}
				if (!(item.sub_button)) {
					item.sub_button = []
				}

				let mychkitem = item.sub_button.find(i => {
					return i.menuId == '0'
				})
				console.log(mychkitem, 'mychkitem');
				if (mychkitem != undefined) {
					alert('unSAVE')
					return;
				}

				item.sub_button.push({
					"menuId": "",
					"pmenuId": item.menuId,
					"menuName": "子菜单",
					"menuKey": "",
					"menuType": "click",
					"menuURL": "",
					"appType": "txt",
					"miniappid": "",
					"miniapppath": "",
					"miniappparam": "",
					"textContent": "",
					"contentXML": "",
					"kfAccount": "",
					"groupId": "",
					"queryType": "",
					"queryCount": 0,
					"orderNo": 0,
					"sub_button": []
				})
				console.log(item, 'item');
			},
			funAddchildmenu(item,index) {
				this.menufrom = item
				this.currcIndex = index
				console.log(item, 'funAddchildmenu');
			},
			funmenuClick(index) {
				this.currIndex = index
				this.menufrom = this.menulist[index]
				console.log(this.menufrom);
			},
			funAddSave() {
				console.log(this.menufrom, 'funAddSave');

				let param = {
					"menuid": this.menufrom.menuId,
					"mpid": this.mpid,
					"menuname": this.menufrom.menuName,
					"menukey": this.menufrom.menuKey,
					"menutype": this.menufrom.menuType,
					"menuurl": this.menufrom.menuURL,
					"apptype": this.menufrom.appType,
					"textcontent": this.menufrom.textContent,
					"pmenuid": this.menufrom.pmenuId,
					"orderno": this.menufrom.orderNo,
					"kfaccount": this.menufrom.kfAccount,
					"groupid": this.menufrom.groupId,
					"querytype": this.menufrom.queryType,
					"querycount": this.menufrom.queryCount,
					"miniappid": this.menufrom.miniappid,
					"miniapppath": this.menufrom.miniapppath,
					"miniappparam": this.menufrom.miniappparam
				}
				console.log(param,'funAddSave.param');
				menuApi.edit(param, res => {
					console.log(res, 'funAddSave.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getmenu()
					}else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funFbSave(){
				let param = {
					mpid:this.mpid
				}
				console.log(param,'funFbSave.param');
				menuApi.publish(param, res => {
					console.log(res, 'funFbSave.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getmenu()
					}else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			fundelcmenu() {
				let param = {
					menuid: this.menufrom.menuId,
					mpid: this.mpid
				}
				console.log(param, 'param.del');
				menuApi.del(param, res => {
					console.log(res, 'del.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getmenu()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.box-cont {
		width: 100%;
		display: flex;
	}

	.box-cont .box-left {
		position: relative;
		width: 300px;
		height: 550px;
		background-color: #f5f5f5;
	}

	.box-cont .box-left img {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 80px;
		background-color: #fff;
	}

	.box-cont .box-left .bottom {
		display: flex;
		align-items: center;
		position: absolute;
		width: 100%;
		left: 0;
		bottom: 0px;
		height: 68px;
		line-height: 68px;
	}

	.box-cont .box-left .bottom .item1 {
		position: relative;
		flex-shrink: 0;
		width: 50px;
		height: 100%;
	}

	.box-cont .box-left .bottom .item1 img {
		width: 100%;
		height: 100%;
	}

	.box-cont .box-left .bottom .item2 {
		cursor: pointer;
		color: #07c160;
		border: 2px dashed #07c160;
		flex: 1;
		height: 100%;
		text-align: center;
	}

	.box-cont .box-left .bottom .itemcon {
		flex-grow: 1;
		display: flex;
		flex-direction: row;
	}

	.box-cont .box-left .bottom .item3 {
		display: flex;
		align-items: center;
		text-align: center;
	}

	.box-cont .box-left .bottom .w33 {
		flex-grow: 1;
		text-align: center;
	}

	/* .box-cont .box-left .bottom .item3 .name:nth-child(1) :before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		height: 50px;
		width: 1px;
		background-color: #ccc;
	} */

	.box-cont .box-left .bottom .item3 .name {
		cursor: pointer;
		background-color: #fff;
		border: 1px solid #e3e4e5;
		flex: 1;
		position: relative;
	}

	.box-cont .box-left .bottom .item3 .name .dels {
		position: absolute;
		cursor: pointer;
		left: 30px;
		bottom: -48px;
	}

	.box-cont .box-left .bottom .item3 .name .clist {
		position: absolute;

		left: 0;
		bottom: 68px;
		text-align: center;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.box-cont .box-left .bottom .item3 .adds {
		cursor: pointer;
		border: 1px dashed #ccc;
		background-color: #fff;
		padding: 0 5px;
		position: relative;
		margin-bottom: 5px;
		white-space: nowrap;
	}

	.box-cont .box-left .bottom .item3 .adds .del {
		position: absolute;
		left: -50px;
		top: 0;
		color: #222;

		width: 50px;
	}

	.box-cont .box-left .bottom  .add {
		cursor: pointer;
		border-top: 1px solid #f0f0f0;
		background-color: #fff;
		flex: 1;
		border: 1px dashed #07c160;
	}

	.active {
		color: #07c160;
		border: 1px solid #07c160 !important;
	}

	.box-cont .box-right {
		margin-left: 10px;
		flex: 1;
		height: 550px;
		background: #fff;
	}

	.box-cont .box-right .box {
		padding: 20px;
	}

	.box-cont .box-right .box .item {
		margin: 20px 0;
		display: flex;
		align-items: center;
	}

	.box-cont .box-right .box .item .el-input {
		margin-left: 10px;
		width: 300px;
	}
	.box-cont .box-right .title{
		font-size: 15px;
		font-weight: 800;
	}
	.box-cont .box-right .label{
		width: 130px;
	}
	.pagecontent {
		display: flex;
	
		height: calc(100vh - 81px);
	}
	
	.w200 {
		width: 200px;
	}
	
	.value {
		color: #777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow: 1;
	}
	.btnbox{
		width:100%;
		display:flex;
		justify-content: center;
	}
	.btnbox .btn{
	width: 200px;
	margin: 20px auto;
	}
	.page {
		background: #F5F7FA;
	}
	
	.border {
		border-bottom: 1px solid #E2E2E2;
	}
	
	.rightbox {
		padding-top: 20px;
		width: 1320px;
	}
</style>