<template>
	<div id="notice">

		<!-- <el-tabs v-model="activeName" type="card">
	    <el-tab-pane label="数据" name="first"> -->
		<!-- <div class="tips">
			  <p>文字过长，文字会自动滚动</p>
			</div> -->
		<div class="style">
			·数据
		</div>
		<div class="list1">
			<div class="title disp">
				<span>公告 </span>
				<span class="tip">文字过长，文字会自动滚动</span>
			</div>
			<el-input placeholder="请填写公告内容" v-model="list.options.notice" size="small"></el-input>
			<div class="row">
				<el-tooltip effect="dark" content="字体色" placement="top">
					<el-color-picker v-model="list.options.textcolor" size="medium"></el-color-picker>
				</el-tooltip>
				<el-tooltip effect="dark" content="背景色" placement="top">
					<el-color-picker v-model="list.options.background" size="medium"></el-color-picker>
				</el-tooltip>
			</div>
			
			
		</div>
		<!-- 文字颜色 -->
		
		<!-- 背景颜色 -->
		
		<!-- 背景图 -->
		<div class="titles">
			<span>背景图 </span>
			<div>
				<el-button type="text" class="reset" @click="reset('bgimg')"><span
						style="color: #FF6060;">重置</span></el-button>
				<el-button type="text" size="mini" @click="funaddbgimg('bgimg')"><span
						style="color: #3388FF;">上传</span></el-button>
			</div>
		</div>
		<div class="">
			<div class="tb full">
				<img :src="list.options.bgimg" v-if="list.options.bgimg && list.options.bgimg.length >0" />
			</div>
		</div>
		<!-- 图标 -->
		<div class="titles">
			<span>图标 </span>
			<div>
				<el-button type="text" class="reset" @click="reset('tbimg')"><span
						style="color: #FF6060;">重置</span></el-button>
				<el-button type="text" size="mini" @click="funaddbgimg('tbimg')"><span
						style="color: #3388FF;">上传</span></el-button>
			</div>
		</div>
		<div>
			<div class="tb2 full">
				<img :src="list.options.tbimg" />
			</div>
		</div>


		<!-- </el-tab-pane> -->
		<!-- <el-tab-pane label="设置" name="set">
			<div class="list">
				<div class="title"><span>数据来源</span> </div>
				<div>
					<el-radio-group v-model=" list.options.source.type" size="mini">
						<el-tooltip class="item" effect="dark" content="常规" placement="top-start">
							<el-radio-button label="static">常规</el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="动态" placement="top-start">
							<el-radio-button label="auto">动态</el-radio-button>
						</el-tooltip>
					</el-radio-group>
				</div>
			</div>
		</el-tab-pane> -->
		<!-- <el-tab-pane label="风格" name="second"> -->
		<div class="style">
			·样式
		</div>
		<!--圆角-->
		<div class="list">
			<div class="title">
				<span>圆角 </span>
			</div>
			<div class="btn2 full">
				<el-slider v-model="list.options.borderradius" show-input :max="200" input-size="mini">
				</el-slider>
			</div>
		</div>
		<!--样式开始-->
		<div class="list">
			<div class="title">
				<span>上边距 </span>
			</div>
			<div class="btn2 full">
				<el-slider v-model="list.options.margintop" show-input :max="200" input-size="mini">
				</el-slider>
			</div>
		</div>
		<div class="list">
			<div class="title">
				<span>下边距 </span>
			</div>
			<div class="btn2 full">
				<el-slider v-model="list.options.marginbottom" show-input :max="200" input-size="mini">
				</el-slider>
			</div>
		</div>

		<div class="list">
			<div class="title">
				<span>页边距 </span>
			</div>
			<div class="btn2 full">
				<el-slider v-model="list.options.marginpage" show-input :max="200" input-size="mini">
				</el-slider>
			</div>
		</div>
		<div class="list">
			<div class="title">
				<span>内上边距 </span>
			</div>
			<div class="btn2 full">
				<el-slider v-model="list.options.paddingtop" show-input :max="200" input-size="mini">
				</el-slider>
			</div>
		</div>
		<div class="list">
			<div class="title">
				<span>内下边距 </span>
			</div>
			<div class="btn2 full">
				<el-slider v-model="list.options.paddingbottom" show-input :max="200" input-size="mini">
				</el-slider>
			</div>
		</div>
		<!--样式结束-->
		<!-- </el-tab-pane> -->
		</el-tabs>

		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import selImgs from "@/components/designer/selImgs";
	export default {
		components: {
			selImgs
		},
		name: "text_notice_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		created() {
			this.list = this.data;
			if (this.list.options.source == undefined) {
				this.$set(this.list.options, 'source', {
					id: '',
					type: 'auto'
				})

			}
		},
		data() {
			return {
				list: {},
				activeName: 'first',
				selzt: ""
			};
		},

		mounted() {},

		methods: {
			reset(key) {
				this.$delete(this.list.options, key)
			},
			funaddbgimg(zt) {
				this.selzt = zt
				this.$refs["selImgs"].funShow();
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				if (this.selzt == 'tbimg') {
					this.$set(this.list.options, 'tbimg', imgitem.url);
				} else {
					this.$set(this.list.options, 'bgimg', imgitem.url);
				}

			}
		},
	};
</script>

<style lang="less" scoped>
	#notice {
		margin: 0px 20px 0 20px;
		font-size: 11px;
	}

	.tips {
		margin-bottom: 20px;

		p {
			font-size: 11px;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;

		.textcolor {
			color: #969799;
		}
	}

	.list1 {
		margin-bottom: 10px;

		.title {
			margin-bottom: 10px;

			span {
				font-size: 12px;
			}
		}
	}

	.title {
		font-size: 12px;
		width: 80px;

		:first-child {
			padding-right: 10px;

			color: #333641;
		}
	}

	.btn {
		width: 70px;
		display: flex;
		align-items: center;
	}

	.reset {
		margin-right: 10px;

		span {
			color: #FF6060;
			font-size: 12px;
		}
	}

	.full {
		flex-grow: 1;
	}

	.tb {
		width: 100%;
		height: 30px;
		overflow: hidden;

		img {
			width: 100%;
		}
	}

	.tb2 {
		width: 20px;
		height: 20px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.titles {
		display: flex;
		align-items: center;
		justify-content: space-between;

	}

	span {
		color: #333641;
		font-size: 12px;
	}

	.mr {
		margin-bottom: 20px;
	}

	.disp {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: space-between;

	}

	.tip {
		color: #969799;
		font-size: 10px !important;
	}

	.style {
		margin: 10px 0;
		font-size: 12px;
		font-weight: 800;
	}

	/deep/ .el-color-picker--medium .el-color-picker__trigger {
		width: 30px !important;
		height: 30px !important;
	}
	.row{
		display: flex;
		flex-direction: row;
		margin-top: 10px;
	}
</style>