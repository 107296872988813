<template>
	<el-dialog :title="title" :visible.sync="editFormVisible" width="50%" @click='editFormVisible=false' :append-to-body="true">
		<div class="main">
			<div class="main-left">
				<div class="main-box" :class="selindex == index ? 'active' : 'normal'" v-for="(item,index) in menulist" :key="index" @click="fundetail(item,index)">
					<div class="cont" v-for="(citem,cindex) in item.sendhtml" :key="cindex">
						<div class="head" v-if="cindex == 0">
							<div class="logo">
								<img :src="citem.logo">
								<div class="title">
									{{citem.title}}
								</div>
							</div>
						</div>
						<div class="main-news" v-else>
							<div class="main-title">
								{{citem.title}}
							</div>
							<div class="main-img">
								<img :src="citem.logo" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div slot="footer" class="dialog-footer">
			<el-button size="small" type="primary" class="title" @click="submitForm()">确定
			</el-button>
		</div>
	</el-dialog>
	
</template>

<script>
	import menuApi from '../../api/cus/menu.js'
	export default{
		data(){
			return{
				mpid:"",
				title:"",
				selindex:0,
				menulist:[],
				editFormVisible:false
			}
		},
		methods:{
			funShow(mpid) {
				this.mpid = mpid
				this.title = "选择图文"
				this.editFormVisible = true
				this.getnewslist()
			},
			getnewslist() {
				let param = {
					mpid: this.mpid,
					pageno: 1,
					pagesize: 50
				}
				menuApi.newslist(param, res => {
					if (res.code == 200) {
						res.data.list.forEach(f => {
							this.menulist.push({
								createdate: f.createdate,
								ispublish: f.ispublish,
								issucc: f.issucc,
								newsid: f.newsid,
								sendhtml: JSON.parse(f.sendhtml),
								title: f.title
							})
						})
						console.log(this.menulist, 'this.menulist');
					}
				})
			},
			fundetail(item,index){
				console.log(item,'item');
				this.selindex = index
				this.editFormVisible = false
				this.$emit('selNewsResult',item)
			}
		}
	}
</script>

<style scoped>
	.main {
		display: flex;
		flex-wrap: wrap;
		height: 600px;
		overflow-y: auto;
	}
	
	.main .main-left {
		height: 100%;
		display: flex;
		flex-direction: row;
		overflow-y: auto;
		flex-wrap: wrap;
		position: relative;
	}
	
	.main .main-left .main-box {
		margin: 10px;
		width: 200px;
		height: 250px;
		overflow: auto;
		border-radius: 5px;
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 8px 0 #e4e7ed;
	}
	
	.main .main-left .main-box .head {
		display: flex;
		align-items: center;
	}
	
	.main .main-left .main-box .head .logo {
		width: 100%;
		height: 100px;
		position: relative;
	}
	
	.main .main-left .main-box .head .logo img {
		width: 100%;
		height: 100%;
	}
	
	.main .main-left .main-box .head .title {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 50px;
		line-height: 50px;
		color: #fff;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
	}
	
	.main .main-left .main-news {
		padding: 0 20px;
		height: 50px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	
	.main .main-left .main-news .main-img {
		width: 30px;
		height: 30px;
		background-color: #E1E1E1;
	}
	
	.main .main-left .main-news .main-img img {
		width: 100%;
		height: 100%;
	}
	.main .active{
		border: 1px solid #55aaff ;
	}
	.main .normal{
		border:none;
	}
</style>