<template>
  <div>
    <div style="height: 1px"></div>
    <div class="zt">
      <div>{{ list.options.name || "这里是专题名称" }}</div>
      <div>查看更多 <i class="el-icon-arrow-right"></i></div>
    </div>
    <van-card
      v-show="list.data.length > 0"
      v-for="(item, index) in list.data"
      :key="index"
      :num="item.num || '0'"
      :price="item.price || '0.00'"
      :desc="item.miaoshu || '描述信息'"
      :title="item.title || '商品标题'"
      thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
    />
    <div>
      <van-card
        v-show="list.data.length == 0"
        num="0"
        price="0.00"
        desc="描述信息"
        title="商品标题"
        thumb="https://img01.yzcdn.cn/vant/ipad.jpeg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "小程序模块话Card",

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { list: "" };
  },

  created() {
    this.list = this.data;
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.zt {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
}
</style>