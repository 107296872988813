<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="24"></leftnav>
			<div class="rightbox" >
				
				<el-card   class="box-card" >
					<!--列表-->
					<div slot="header" class="clearfix">
						<div style="flex-grow: 1;">入群欢迎语</div>
						<el-button @click="funflushshop" type="primary" v-loading.fullscreen.lock="fullshopscreenLoading">同步门店</el-button>
					</div>
					<el-table size="large" :data="shoplist" highlight-current-row v-loading="loading" :border="false"
						element-loading-text="拼命加载中" style="width: 100%;" v-if="showshop">
						<el-table-column align="center" prop="mchno" label="企业号" width="180">
						</el-table-column>
						<el-table-column align="center" prop="mchtype" label="门店类型" width="180">
						</el-table-column>
						<el-table-column align="center" prop="shopname" label="店铺名称" width="180">
						</el-table-column>
						<el-table-column align="center" prop="shopid" label="店铺编号" width="180">
						</el-table-column>
						<el-table-column align="center" prop="updateDate" label="更新时间" width="180">
						</el-table-column>
						<el-table-column label="操作" min-width="200">
							<template slot-scope="scope">
								<el-button size="mini" @click="handshop(scope.$index, scope.row)">配置</el-button>
								
					
							</template>
						</el-table-column>
					</el-table>
					<div slot="header"  v-else>
						<div class="" style="display: flex;justify-content: left;">
							<el-button size="small" type="primary" @click="funAdd">新增</el-button>
						</div>
						<el-table size="small" :data="scanData" highlight-current-row v-loading="loading" :border="false"
							element-loading-text="拼命加载中" style="width: 100%;" >
						
							<el-table-column align="left"  prop="title" label="标题" width="180">
							</el-table-column>
							
							<el-table-column align="left"  prop="template_title" label="模板标题" width="150">
							</el-table-column>
							<el-table-column align="left"  prop="content" label="欢迎语内容" width="200">
							</el-table-column>
							
							<el-table-column align="left"  prop="isticket" label="状态" width="100">
								<template v-slot="scope">
									<div :class="'state'+ scope.row.billstate ">{{scope.row.billstate == 0 ? '待提交' : '已提交'}}</div>
								</template>
							</el-table-column>
							<el-table-column align="left"  prop="template_id" label="企微模板ID" width="200">
							</el-table-column>
							<el-table-column align="left"  prop="createDate" label="创建时间" width="200">
							</el-table-column>
							<el-table-column align="left"  prop="updateDate" label="更新时间" width="200">
							</el-table-column>
							<el-table-column label="操作" min-width="250" fixed="right">
								<template slot-scope="scope">
									<el-button size="mini" type="primary" @click="handup(scope.$index, scope.row)">提交</el-button>
									<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
									<el-button size="mini" type="danger" @click="deleteqr(scope.$index, scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
					
					
				</el-card>
			</div>
		</div>

	</div>
</template>

<script>
	
	import welcomeApi from '../../../../../../api/cus/welcome.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import deskqrcodeApi from '../../../../../../api/cus/deskqrcode.js'
	export default {
		components: {
			
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				loading: false,
				scanData: [],
				shoplist:[],
				showshop:true,
				shopid:'',
				fullshopscreenLoading:false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				let showshop = this.$route.query.showshop
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				if(showshop != undefined){
					if(this.showshop = 'false'){
						this.showshop = false
					}
					
				}
				console.log(this.showshop,'showshop');
				this.getlist()
				this.getshoplist()
			},
			getlist(page) {
				welcomeApi.list(this.mpid, res => {
					console.log(res, 'reslist');
					if (res.code == 200) {
						this.scanData = res.data
					}
				})
			},
			
			getshoplist() {
				deskqrcodeApi.bindshoplist(res => {
					console.log(res, 'reslist');
					if (res.code == 200) {
						this.shoplist = res.data
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			search() {
				
				this.getlist()
			},
			handup(index,row){
				let param ={
					id:row.id
				}
				welcomeApi.submit(param, res => {
						console.log(res, 'resdelete');
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.getlist()
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
				})
			},
			handshop(index,row){
				console.log(row,'row');
				this.showshop = false
				this.getlist()
				this.shopid = row.shopid
			},
			handleEdit(index, row) {
				this.$router.push({
					path: "/customer/home/mp/weixinqiye/welcome/details",
					query: {
						mpid: this.mpid,
						id: row.id,
						shopid:this.shopid
					}
				})
			},
			deleteqr(index, row) {
				let param = {
					id: row.id,
				}
				
				this.$confirm('是否删除该项目?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
				welcomeApi.del(param, res => {
					console.log(res, 'resdelete');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getlist()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
				}).catch(() => {});
				
			},
			funflushshop(){
				this.fullshopscreenLoading = true;
				setTimeout(() => {
					deskqrcodeApi.flushshop(res => {
						console.log(res, 'flushshop.res');
						if (res.code == 200) {
							this.fullshopscreenLoading = false;
							this.shoplist = res.data
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							})
						}
					})
				
				}, 2000);
				
				
			},
			funAdd() {
				this.$router.push({
					path: "/customer/home/mp/weixinqiye/welcome/details",
					query: {
						mpid: this.mpid,
						shopid:this.shopid
					}
				})
			},
			funTest() {
				this.$router.push({
					path: "/published/qiyewx/qrcode/man",
					query: {
						mpid: this.mpid
					}
				})
			}
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
	}

	.page {

		background: #F5F7FA;

	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
		overflow: auto;
		margin: 0 auto;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);

	}
	.state0{
		color:#ff0000;
	}
	.state1{
		color:#00aa00;
	}
	.box-card {
		width: 1038px;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
		}
	}
</style>