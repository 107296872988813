<template>
	<div id="text">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="数据" name="first">
				<p>标题内容</p>
				<el-input v-model="list.options.name" maxlength="10" placeholder="请输入标题"></el-input>
				<p>描述内容</p>
				<el-input v-model="list.options.label" maxlength="500" type="textarea"
					placeholder="描述内容，最多500字"></el-input>
				<div style="height: 1px"></div>
				<p class="division"></p>
				<p>按钮内容</p>
				<el-input v-model="list.options.btntxt" maxlength="10" placeholder="请输入按钮内容"></el-input>
			</el-tab-pane>
			<el-tab-pane label="样式" name="style">
				<div class="details">
					<div class="list">
						<div class="title">
							<span>登录状态 </span>
							<span v-if="list.options.loginstate == 0">未登录</span>
							<span v-if="list.options.loginstate == 1">已登录</span>
							<span v-if="list.options.loginstate == 2">已登录未领卡</span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.loginstate" size="mini">
								<el-tooltip class="item" effect="dark" content="已登录" placement="bottom">
									<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="未登录" placement="bottom">
									<el-radio-button :label="0">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="已登录未领卡" placement="bottom">
									<el-radio-button :label="2">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>
					<div v-if="list.options.loginstate == 1">
						<!-- 名称颜色 -->
						<div class="list">
							<div class="title"><span>会员名颜色</span>{{list.options.clubcolor||'默认' }}<span></span></div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('clubcolor')">重置</el-button><el-color-picker
									v-model="list.options.clubcolor" size="medium"></el-color-picker>
							</div>
						</div>

						<!-- 背景颜色 -->
						<div class="list">
							<div class="title"><span>内背景颜色</span>{{ list.options.background||'默认' }}<span></span></div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('background')">重置</el-button><el-color-picker
									v-model="list.options.background" size="medium"></el-color-picker>
							</div>
						</div>
						<!-- 分割线 -->
						<div class="list">
							<div class="title">
								<span>分割线颜色</span>{{ list.options.linecolor ||'默认' }}<span></span>
							</div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('linecolor')">重置</el-button><el-color-picker
									v-model="list.options.linecolor" size="medium"></el-color-picker>
							</div>
						</div>
						<!-- 二维码图片 -->
						<div class="list">
							<div class="title"><span>二维码图片</span></div>
							<div class="btn">
								<el-button type="text" class="reset" @click="reset('ewm')">重置</el-button><el-button
									type="primary" size="small" @click="funupload('ewm')">上传</el-button>
							</div>
						</div>
						<div class="list">
							<el-image :src="list.options.ewm" style="width: 80px;height: 80px;"></el-image>
						</div>
						<!-- 背景图片 -->
						<div class="list">
							<div class="title"><span>背景图片</span></div>
							<div class="btn">
								<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button><el-button
									type="primary" size="small" @click="funupload('bgimg')">上传</el-button>
							</div>
						</div>
						<div class="list">
							<el-image :src="list.options.bgimg" style="width: 80px;height: 80px;"></el-image>
						</div>
						<!-- 会员头像 -->
						<div class="list">
							<div class="title"><span>会员头像</span></div>
							<div class="btn">
								<el-button type="text" class="reset" @click="reset('logo')">重置</el-button><el-button
									type="primary" size="small" @click="funupload('logo')">上传</el-button>
							</div>
						</div>
						<div class="list">
							<el-image :src="list.options.logo" style="width: 80px;height: 80px;"></el-image>
						</div>
					</div>
					<div v-else-if="list.options.loginstate == 0">
						<!-- 按钮颜色 -->
						<div class="list">
							<div class="title"><span>未登录按钮颜色</span>{{ list.options.btncolor||'默认' }}<span></span></div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('btncolor')">重置</el-button><el-color-picker
									v-model="list.options.btncolor" size="medium"></el-color-picker>
							</div>
						</div>
						<!-- 背景颜色 -->
						<div class="list">
							<div class="title"><span>未登录内背景颜色</span>{{ list.options.backgroundun||'默认' }}<span></span>
							</div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('backgroundun')">重置</el-button><el-color-picker
									v-model="list.options.backgroundun" size="medium"></el-color-picker>
							</div>
						</div>
						<!-- 背景图片 -->
						<div class="list">
							<div class="title"><span>未登录背景图片</span></div>
							<div class="btn">
								<el-button type="text" class="reset" @click="reset('loginimg')">重置</el-button><el-button
									type="primary" size="small" @click="funupload('loginimg')">上传</el-button>
							</div>
						</div>
						<div class="list">
							<el-image :src="list.options.loginimg" style="width: 80px;height: 80px;"></el-image>
						</div>
					</div>
					<div v-else>
						<p>标题内容</p>
						<el-input v-model="list.options.clubname" maxlength="10" placeholder="请输入标题"></el-input>
						<p>描述内容</p>
						<el-input v-model="list.options.clublabel" maxlength="500" type="textarea"
							placeholder="描述内容，最多500字"></el-input>
						<div style="height: 1px"></div>
						<p class="division"></p>
						<p>按钮内容</p>
						<el-input v-model="list.options.clubbtntxt" maxlength="10" placeholder="请输入按钮内容"></el-input>
						<!-- 背景颜色 -->
						<div class="list">
							<div class="title">
								<span>领取会员卡背景颜色</span>{{ list.options.backgroundunclub||'默认' }}<span></span></div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('backgroundunclub')">重置</el-button><el-color-picker
									v-model="list.options.backgroundunclub" size="medium"></el-color-picker>
							</div>
						</div>
						<!-- 背景颜色 -->
						<div class="list">
							<div class="title"><span>领取会员卡内背景颜色</span>{{ list.options.background||'默认' }}<span></span></div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('background')">重置</el-button><el-color-picker
									v-model="list.options.background" size="medium"></el-color-picker>
							</div>
						</div>

						<div class="disps">
							<div class="title">
								<span>领取会员卡圆角 </span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="list.options.clubradius" show-input :max="3000"
									style="margin: 0 10px;">
								</el-slider>
							</div>
						</div>
						<div class="disps">
							<div class="title">
								<span>领取会员卡边距 </span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="list.options.clubmargin" show-input :max="3000"
									style="margin: 0 10px;">
								</el-slider>
							</div>
						</div>
						<!-- 背景图片 -->
						<div class="list">
							<div class="title"><span>背景图片</span></div>
							<div class="btn">
								<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button><el-button
									type="primary" size="small" @click="funupload('bgimg')">上传</el-button>
							</div>
						</div>
						<div class="list">
							<el-image :src="list.options.bgimg" style="width: 80px;height: 80px;"></el-image>
						</div>
						<!-- 背景图片 -->
						<div class="list">
							<div class="title"><span>领取会员卡背景图片</span></div>
							<div class="btn">
								<el-button type="text" class="reset"
									@click="reset('loginimgclub')">重置</el-button><el-button type="primary" size="small"
									@click="funupload('loginimgclub')">上传</el-button>
							</div>
						</div>
						<div class="list">
							<el-image :src="list.options.loginimgclub" style="width: 80px;height: 80px;"></el-image>
						</div>
					</div>
					<!-- 名称颜色 -->
					<div class="list">
						<div class="title"><span>会员名颜色</span>{{list.options.clubcolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('clubcolor')">重置</el-button><el-color-picker
								v-model="list.options.clubcolor" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 大背景颜色 -->
					<div class="list">
						<div class="title"><span>外背景颜色</span>{{ list.options.bgcolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('bgcolor')">重置</el-button><el-color-picker v-model="list.options.bgcolor"
								size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 标题文字颜色 -->
					<div class="list">
						<div class="title"><span>标题颜色</span>{{list.options.titlecolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('titlecolor')">重置</el-button><el-color-picker
								v-model="list.options.titlecolor" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 描述文字颜色 -->
					<div class="list">
						<div class="title"><span>资产颜色</span>{{ list.options.describecolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('describecolor')">重置</el-button><el-color-picker
								v-model="list.options.describecolor" size="medium"></el-color-picker>
						</div>
					</div>


					<div class="disps">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="3000"
								style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>
					<div class="disps">
						<div class="title">
							<span>上内边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="3000" style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>
					<div class="disps">
						<div class="title">
							<span>下内边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="3000"
								style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>

					<div class="disps">
						<div class="title">
							<span>页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.paddingpage" show-input :max="3000"
								style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>

</template>

<script>
	import selImgs from "@/components/designer/selImgs"
	export default {
		components: {
			selImgs
		},
		name: "club_newadd_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
				activeName: "first",
				imgtype: ""
			};
		},

		created() {
			this.list = this.data;
		},

		methods: {
			//  显示的位置
			position(event) {
				this.list.options.position = event;
			},
			reset(key) {
				this.list.options[key] = ''
			},
			// 字体大小
			funupload(zt) {
				this.imgtype = zt
				console.log(123);
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				this.list.options[this.imgtype] = imgitem.url;
			},
		},
	};
</script>

<style lang="less" scoped>
	#text {
		padding: 20px;

		p {
			padding: 10px 0;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #969799;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
		}
	}
</style>