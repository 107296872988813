<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="22"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-card class="box-card" style="width: 1038px;">
					<div slot="header" class="clearfix">
						<span>二维码</span>
					</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
						<el-form-item label="群名称" prop="title">
							<el-input v-model="ruleForm.title" placeholder="请输入群名称" size="small"
								style="width: 300px;"></el-input>
						</el-form-item>

						<el-form-item label="群备注" prop="memo">
							<el-input v-model="ruleForm.memo" placeholder="请输入群备注" size="small"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="场景" prop="memo">
							<el-select v-model="ruleForm.scene" filterable size="small" placeholder="请选择场景">
								<el-option v-for="item in scenelist" :key="item.id" :label="item.mc" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="入群引导语" prop="grouptip">
							<el-input type="textarea" rows="5" v-model="ruleForm.grouptip" placeholder="请输入入群引导语"
								maxlength="260" show-word-limit></el-input>
							
						</el-form-item> -->
						<el-form-item label="选择群聊" prop="xcxurl" >
							<p>1、可添加多个群聊，当前面的群人数达到上限后，自动发送后面的群二维码</p>
							<p>2、群人数上限最多为200人</p>
							<p>3、上传群二维码时请【仔细核对】，群二维码和群聊【对应错误】将导致【拉群任务失败】</p>
							<p>4、具有企业客户权限-客户群-配置「加入群聊」二维码权限，且已购买「加入群聊」高级接口</p>
							<el-button type="primary" style="margin: 20px 0;" @click="funaddewm('ruleForm')">添加</el-button>
							<el-table :data="groupsData" border style="width: 100%">
								<el-table-column prop="pic" label="群活码图片" width="180">
									<template slot-scope="scope">
										<img :src="scope.row.pic" style="width: 80px;height: 80px;" />
									</template>
								</el-table-column>
								<el-table-column prop="chatname" label="群名称">
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="100">
									<template slot-scope="scope">
										<el-button type="text" size="small"
											@click="fundelgroup(scope.$index)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
						<el-form-item>
							<div class="btnbox" v-if="isedit">
								<el-button style="width: 180px;" type="primary" size="medium"
									@click="submitForm('ruleForm')" v-if="isedit">保存活码</el-button>
								<el-button style="width: 160px;" size="medium" @click="funback">返回</el-button>
							</div>
							<div class="btnbox" v-else>
								<el-button style="width: 180px;" type="primary" size="medium"
									@click="editForm('ruleForm')">编辑活码</el-button>
								<el-button style="width: 160px;" size="medium" @click="funback">返回</el-button>
							</div>
						</el-form-item>
					</el-form>
				</el-card>
			</div>
			<el-dialog :visible.sync="groupVisible" width="30%" @click='groupVisible=false'>
				<el-form label-width="150px">
					<el-select v-model="ruleForm.groupchatjson" filterable size="small" placeholder="请选择群聊">
						<el-option v-for="item in groupData" :key="item.id" :label="item.chat_name" :value="item.id">
						</el-option>
					</el-select>
					<!-- <el-form-item label="人数" prop="number">
						<el-input size="small" v-model="ewmForm.number" auto-complete="off" placeholder="请输入人数"></el-input>
					</el-form-item> -->
				</el-form>
				<div slot="footer" class="dialog-footer">
					<el-button size="small" type="primary" class="title" @click="funAddgroup()">确定
					</el-button>
				</div>
			</el-dialog>
		</div>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
		<selaffix ref="selaffix" @selaffixresult="selaffixresult"></selaffix>
	</div>
</template>

<script>
	import selImgs from "@/components/system/selImgs";
	import selaffix from "@/components/customer/selaffix";
	import personApi from '../../../../../../api/cus/person.js';
	import groupApi from '../../../../../../api/cus/group.js'
	import qrcodeApi from '../../../../../../api/cus/qrcode.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			selImgs,
			selaffix,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				mxid: "",
				manData: [],
				personData: [],
				groupData: [],
				groupsData: [],
				ruleForm: {
					"qrid": "",
					"mpid":'',
					"memo": '',
					"title": '',
					"scene": '',
					"groupchatjson": ''
				},
				isedit: false,
				groupVisible: false,
				rules: {},
				scenelist: [{
						id: 1,
						mc: '小程序使用'
					},
					{
						id: 2,
						mc: '二维码'
					}
				]
			};
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				let mxid = this.$route.query.mxid
				console.log(mxid, 'mxidmxidmxid');
				if (mpid != undefined) {
					this.ruleForm.mpid = mpid
				} else {
					this.$router.push("/");
				}
				if (mxid != undefined) {
					this.ruleForm.qrid = mxid
					this.isedit = false
					this.getdetail()
				} else {
					this.isedit = true
				}
				this.getlist()
				this.getgrouplist()
			},
			getlist(page) {
				let param = {
					mpid: this.ruleForm.mpid,
					pageno: 1,
					pagesize: 100,
				}
				this.loading = true
				personApi.list(param, res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.loading = false
						this.manData = res.data.list
					}
				})
			},
			getgrouplist() {
				let param = {
					mpid: this.ruleForm.mpid,
					name: '',
					pageno: 1,
					pagesize: 100
				}
				groupApi.list(param, res => {
					console.log(res, 'reslist');
					if (res.code == 200) {
						this.groupData = res.data.list
					}
				})
			},
			getdetail() {
				let param = {
					mxid: this.ruleForm.qrid,
				}
				groupApi.detail(param, res => {
					console.log(res, 'resdetail');
					if (res.code == 200) {
						this.ruleForm.title = res.data.title
						this.ruleForm.qrtype = res.data.qrtype
						this.ruleForm.memo = res.data.memo
						this.ruleForm.istemp = res.data.istemp == 0 ? false : true
						this.ruleForm.isverify = res.data.isverify == 0 ? false : true
						this.personData = res.data.manjson
						this.ruleForm.grouptip = res.data.grouptip
						this.ruleForm.welcomejson = res.data.welcomejson
						this.groupsData = res.data.groupchatjson
						this.ruleForm.scene =res.data.scene
					}
				})
			},
			changeswitch() {
				this.ruleForm.manjson = ''
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let param = {
							"qrid": "",
							"mpid":this.ruleForm.mpid,
							"memo": this.ruleForm.memo,
							"title": this.ruleForm.title,
							"scene":  this.ruleForm.scene,
							"groupchatjson": this.groupsData
						}
						console.log(param, 'param');
						qrcodeApi.add(param, res => {
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.funback()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let param = {
							"qrid": this.ruleForm.qrid,
							"mpid":this.ruleForm.mpid,
							"memo": this.ruleForm.memo,
							"title": this.ruleForm.title,
							"scene":  this.ruleForm.scene,
							"groupchatjson": this.groupsData
						}
						console.log(param, 'param.editForm');
						qrcodeApi.add(param, res => {
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.funback()
							}else{
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			funaddewm() {
				this.groupVisible = true
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.ruleForm.picurl = imgitem.url
			},
			funAddPerson() {
				let newArr = []
				this.ruleForm.manjson.forEach(f => {
					this.manData.forEach(f2 => {
						if (f == f2.id) {
							newArr.push(f2)
						}
					})
				})
				newArr.forEach(f => {
					console.log(f, 'f');
					this.personData.push({
						name: f.manname,
						manno: f.manno,
					})
				})
			},
			fundelperson(index) {
				this.personData.splice(index, 1)
			},

			funAddgroup() {
				this.groupVisible = false
				console.log(this.groupData, 'this.groupsData');
				let f = this.groupData.find(f => f.id == this.ruleForm.groupchatjson)
				console.log(find, ';');
				if (f != undefined) {
					this.groupsData.push({
						id: f.id,
						chatid: f.chat_id,
						chatname: f.chat_name
					})
				}
			},
			fundelgroup(index) {
				this.groupsData.splice(index, 1)
			},
			funAddaffix() {
				this.$refs['selaffix'].funShow()
			},
			fundelaffix(index) {
				this.ruleForm.welcomejson.splice(index, 1)
			},
			selaffixresult(param) {
				console.log(param, 'param');
				this.ruleForm.welcomejson.push(param)
			},
			funback() {
				this.$router.push({
					path: '/customer/home/mp/weixinqiye/qrcode/list?mpid=' + this.ruleForm.mpid,
				})
			},
		}
	}
</script>

<style scoped>
	.rightbox {
		padding-top: 20px;
		width: 1320px;
		overflow: auto;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);

	}

	.title-tips {
		padding-left: 28px;
		font-weight: 650;
		font-style: normal;
		color: #f59a23;
		font-size: 14px;
	}

	.addaffix {
		cursor: pointer;
		padding-left: 10px;
		border: 1px solid #DCDFE6;
		border-top: 0px solid #DCDFE6;
	}

	.addaffix:hover {
		opacity: 0.8;
	}

	.list {
		display: flex;
		flex-direction: column;
	}

	.list-item {
		position: relative;
	}

	.del {
		position: absolute;
		right: 10px;
		top: 0px;
	}

	.page {

		background: #F5F7FA;

	}

	.btnbox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
</style>