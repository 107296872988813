<template>
	<div class="edit_container">
		<!--  新增时输入 -->
		<quill-editor v-model="content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)" @change="onEditorChange($event)" >
		</quill-editor>
		<button id="upload" @click="uploadSuccess"></button>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>
<script>
	import Vue from 'vue'
	import imgApi from '../../api/common/img'
	import {
		quillEditor,
		Quill
	} from 'vue-quill-editor' // 调用编辑器
	import {
		container,
		ImageExtend,
		QuillWatch
	} from 'quill-image-extend-module'
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import resizeImage from 'quill-image-resize-module' // 图片缩放组件引用
	import {
		ImageDrop
	} from 'quill-image-drop-module'
	import selImgs from "@/components/system/selImgs";
	Quill.register('modules/imageDrop', ImageDrop) // 注册
	Quill.register('modules/resizeImage ', resizeImage)
	Quill.register('modules/ImageExtend', ImageExtend)
	
	export default {
		name: 'RichTextEditor',
		components: {
			selImgs,
			quillEditor
		},
		props: {
			fileUrl: {
				type: String,
				default: ''
			},
			token: {
				type: String,
				default: ''
			},	
		},
		data() {
			return {
				content:'',
				editorOption: {
					placeholder: '请在这里输入',
					modules: {
						ImageExtend: {
							loading: true,
							name: 'fileData',
							// 更多配置见官方文档https://www.kancloud.cn/liuwave/quill/1434141
							// 设置请求头部
							headers: (xhr) => {
								xhr.setRequestHeader('Authorization', this.token)
							},
							action: this.fileUrl, // 这里写入请求后台地址 例如："http://xxx.xxx.xxx.xxx:xxx/api/file/upload/indexFile",
							response: (res) => {
								console.log(res, 'ImageExtend.img');
								return res.url // 这里写入请求返回的数据，也就是一个图片字符串
							}
						},
						imageDrop: true, // 图片拖拽
						imageResize: { // 放大缩小
							displayStyles: {
								backgroundColor: 'black',
								border: 'none',
								color: 'white'
							},
							modules: ['Resize', 'DisplaySize', 'Toolbar']
						},
						toolbar: {
							container: container,
							handlers: {
								image: function(value) {
									if (value) {
										document.querySelector("#upload").click(); // 劫持原来的图片点击按钮事件
									}
								}
							}
						}
					}
				}
			}
		},
		computed: {
			// 当前富文本实例
			editor() {
				return this.$refs.myQuillEditor.quill
			}
		},
		 mounted() {
		    //  自定义粘贴图片功能
		    let quill = this.$refs.myQuillEditor.quill;
		    this.$forceUpdate();
		    quill.root.addEventListener(
		      "paste",
		      (evt) => {
		        if (
		          evt.clipboardData &&
		          evt.clipboardData.files &&
		          evt.clipboardData.files.length
		        ) {
		          evt.preventDefault();
		          [].forEach.call(evt.clipboardData.files, (file) => {
		            if (!file.type.match(/^image\/(gif|jpe?g|a?png|bmp)/i)) {
		              return;
		            }
		            const formData = new FormData();
		            formData.append("file", file);
		            formData.append("clsid", 0);
					imgApi.uploadImgfile(formData,res=>{
						if(res.code ==200){
						let quill = this.$refs.myQuillEditor.quill;
							// const formdata = _.extend({}, this.formdata)
							let length = quill.getSelection().index; //光标位置
							// 插入图片  图片地址
							quill.insertEmbed(length, "image", res.linkurl);
							// 调整光标到最后
							quill.setSelection(length + 1); //光标后移一位
						}
					})
					return
		            makdwnImg(formData)
		              .then((res) => {
		                let quill = this.$refs.myQuillEditor.quill;
		                if (res.data.code == 200) {
		                  // const formdata = _.extend({}, this.formdata)
		                  let length = quill.getSelection().index; //光标位置
		                  // 插入图片  图片地址
		                  quill.insertEmbed(length, "image", res.data.data);
		                  // 调整光标到最后
		                  quill.setSelection(length + 1); //光标后移一位
		                }
		              })
		              .catch((err) => {
		                console.error(err);
		              });
		          });
		        }
		      },
		      false
		    );
		  },
		methods: {
			setcontent(v){
				this.content = v
			},
			onEditorReady(editor) { // 准备编辑器
			},
			onEditorBlur() {}, // 失去焦点事件
			onEditorFocus() {}, // 获得焦点事件
			onEditorChange() {
				this.$emit('change', this.escapeStringHTML(this.content))
			}, // 内容改变事件
			// 转码
			escapeStringHTML(str) {
				str = str.replace(/&lt;/g, '<')
				str = str.replace(/&gt;/g, '>')
				return str
			},
			uploadSuccess(res, file) {
				// res为图片服务器返回的数据
				// 获取富文本组件实例
				// let quill = this.$refs.myQuillEditor.quill;
				// // 如果上传成功
				// if (res.code === 200) {
				// 	// 获取光标所在位置
				// 	let length = quill.getSelection().index;
				// 	// 插入图片  res.info为服务器返回的图片地址
				// 	quill.insertEmbed(length, 'image', res.data.link)
				// 	// 调整光标到最后
				// 	quill.setSelection(length + 1)
				// 	this.$forceupdate()
				// } else {
				// 	this.$message.error('图片插入失败')
				// }
				this.$refs["selImgs"].funShow();
			},
			selImgsresult(imgitem) {
				// res为图片服务器返回的数据
				// 获取富文本组件实例
				console.log(imgitem, 'imgitem');
				let quill = this.$refs.myQuillEditor.quill;
				console.log(quill,'quill')
				console.log(quill.selection,'quill.getSelection()')
				// 如果上传成功
				// 获取光标所在位置
				let length = quill.selection.savedRange.index;
				// 插入图片  res.info为服务器返回的图片地址
				quill.insertEmbed(length, 'image', imgitem.url)
				// 调整光标到最后
				quill.setSelection(length + 1)

			},
		}
	}
</script>
<style scoped>
	img {
		max-width: 100% !important;
		height: auto !important;
	}

	.editor {
		line-height: normal !important;
		height: 500px;
	}

	.ql-container {
		width: 100%;
		height: 500px;
	}

	.ql-snow .ql-tooltip[data-mode=link]::before {
		content: "请输入链接地址:";
	}

	.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
		border-right: 0px;
		content: '保存';
		padding-right: 0px;
	}

	.ql-snow .ql-tooltip[data-mode=video]::before {
		content: "请输入视频地址:";
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item::before {
		content: '14px';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
		content: '10px';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
		content: '18px';
	}

	.ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
	.ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
		content: '32px';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item::before {
		content: '文本';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
		content: '标题1';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
		content: '标题2';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
		content: '标题3';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
		content: '标题4';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
		content: '标题5';
	}

	.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
	.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
		content: '标题6';
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item::before {
		content: '标准字体';
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
		content: '衬线字体';
	}

	.ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
	.ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
		content: '等宽字体';
	}
	.ql-container{
		height: 800px !important;
	}
</style>