<template>
	<div id="text">
		<el-tabs v-model="activeName" type="card">

			<p >标题内容</p>
			
				<el-input size="medium" v-model="list.options.name" maxlength="10" placeholder="请输入标题"></el-input>


				
					
				
				<div style="display: flex;align-items: center;margin-top: 10px;">
				<el-select v-model="list.options.size" filterable allow-create placeholder="请选择标题大小" size="small">
					<el-option v-for="item in fontlist" :key="item" :label="item" :value="item">
					</el-option>
				
				</el-select>
				<el-tooltip effect="dark" content="加粗" placement="top">
					<div class="bbox" style="margin-left: 10px;">
						<img class="blodimg" src="../../../../../assets/image/icon_b1.png"
							v-if="list.options.bold && list.options.bold ==2" @click="funchu(1)"></img>
						<img class="blodimg" src="../../../../../assets/image/icon_b0.png" v-else
							@click="funchu(2)" /></img>
					</div>
				</el-tooltip>
				<el-tooltip effect="dark" content="标题颜色" placement="top">
					<el-color-picker v-model="list.options.titlecolor" size="medium"></el-color-picker>
				</el-tooltip>
				</div>
				<p style="margin-top: 20px;">描述内容</p>
				<div style="display: flex;align-items: center;">
					<el-input v-model="list.options.label" maxlength="500" size="medium" type="textarea"
						placeholder="描述内容，最多500字"></el-input>
						
						
				</div>
				<div style="display: flex;align-items: center;margin-top: 10px;">
					<el-select v-model="list.options.describe" filterable allow-create placeholder="请选择描述大小"
						size="small">
						<el-option v-for="item in fontlist" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
					<el-color-picker style="margin-left: 10px;" v-model="list.options.describecolor" size="medium"></el-color-picker>
				</div>
				<div class="details">
					<!-- 标题文字大小 -->
					

					<!-- 描述文字大小 -->
					
					<!-- 是否显示二维码 -->
					
					<!-- 是否文字加粗 -->
					
					<!-- 标题文字颜色 -->
					
					<!-- 描述文字颜色 -->
					
					<!-- 按钮颜色 -->
					
					
					<!-- 二维码背景图片 -->
					<div class="list" style="margin-top: 20px;">
						<div class="title"><span>二维码图片</span></div>
						<div class="btn">
							<el-button type="text" class="reset" @click="reset('ewmimg')">重置</el-button><el-button
								type="primary" size="small" @click="funupload('ewmimg')">上传</el-button>
						</div>
					</div>
					<div class="list">
						<el-image :src="list.options.ewmimg" style="width: 80px;height: 80px;"></el-image>
					</div>
					<div class="list">
						<div class="title">
							<span>是否显示二维码 </span>
							<span> {{ list.options.ewmshow ? "显示" : "隐藏" }} </span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.ewmshow">
								<el-tooltip class="item" effect="dark" content="显示" placement="bottom">
									<el-radio-button :label="true"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="隐藏" placement="bottom">
									<el-radio-button :label="false">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>
					<!-- 背景图片 -->
					<div class="list" style="margin-top:30px;">
						<div class="title"><span>背景图片</span></div>
						<div class="btn">
							<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button><el-button
								type="primary" size="small" @click="funupload('bgimg')">上传</el-button>
						</div>
					</div>
					<div class="list">
						<el-image :src="list.options.bgimg" style="width: 80px;height: 80px;"></el-image>
					</div>
					<!-- 大背景颜色 -->
					<div class="list">
						<div class="title"><span>大背景颜色</span><span></span></div>
						<div class="center">{{ list.options.bgcolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('bgcolor')">重置</el-button><el-color-picker v-model="list.options.bgcolor"
								size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 背景颜色 -->
					<div class="list">
						<div class="title"><span>背景颜色</span><span></span></div>
						<div class="center">{{ list.options.background||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('background')">重置</el-button><el-color-picker
								v-model="list.options.background" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 底部分割线 -->
					<div class="list">
						<div class="title">
							<span>底部分割线</span><span></span>
						</div>
						<div class="center">{{ list.options.border ? "显示" : "不显示" }}</div>
						<div class="btn">
							<el-switch v-model="list.options.border" active-color="#13ce66" inactive-color="#ff4949">
							</el-switch>
						</div>
					</div>
					<div class="disps">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="3000"
								style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>
					<div class="disps">
						<div class="title">
							<span>上内边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="200" style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>
					<div class="disps">
						<div class="title">
							<span>下内边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="200"
								style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>

					<!-- <div class="disps">
				  <div class="title">
				    <span>内上边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingtop" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
				<div class="disps">
				  <div class="title">
				    <span>内下边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingbottom" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div> -->
					<div class="disps">
						<div class="title">
							<span>外内页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.paddingpage" show-input :max="200" style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>
				</div>
		</el-tabs>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>

</template>

<script>
	import selImgs from "@/components/customer/selImgs"
	export default {
		components: {
			selImgs
		},
		name: "club_newadd_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				fontlist: [10, 11, 12, 14, 16, 18, 20, 24, 32, 36, 40, 48, 64, 96, 128
				],
				list: {},
				imgtype: '',
				activeName: "first"
			};
		},

		created() {
			this.list = this.data;
			
		},

		methods: {
			//  显示的位置
			position(event) {
				// this.$set(this.list.options.position, position, event);
				this.list.options.position = event;
			},
			reset(key) {
				this.list.options[key] = '';
			},
			// 字体大小
			size() {},
			funupload(imgtype) {
				this.imgtype = imgtype
				
				this.$refs['selImgs'].funShow()
			},
			funchu(bold) {
				
				this.list.options.bold = bold
			},
			selImgsresult(imgitem) {
				
				if (this.imgtype == 'bgimg') {
					this.list.options.bgimg = imgitem.url;
				} else {
					
					this.list.options.ewmimg = imgitem.url;
				}

				// console.log(this.list.options.bgimg,'this.list.options.bgimg');
				// this.$set(this.list, 'options', this.list.options);
				// this.$forceUpdate()
			},
		},
	};
</script>

<style lang="less" scoped>
	#text {
		padding: 20px;

		p {
			padding: 10px 0;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 10px;
	}
	.list .center{
		color: #969799;
	}
	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #000000;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
		}
	}

	.bbox {
		cursor: pointer;
		margin-right: 5px;
		width: 32px;
		height: 30px;
		border-radius: 4px;
		background-color: #DEDFE1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.blodimg {
		width: 20px;
		height: 20px;
	}
</style>