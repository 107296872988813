<template>
	<div class="content">
		<!-- 九宫格菜单功能区 -->
		<div class="upView" style="background-color: #F3F3F3;">
			<sudokuMenu class="nineV" @menuClick="myMenuClick(item)" :myFlag="item.flag" :myText='item.mpname'
				v-for="(item,index) in menulist" :key="index" :imgSrc='item.img'>
			</sudokuMenu>
		</div>
	</div>
</template>

<script>
	// 导入请求方法
	import accountApi from '@/api/cus/account.js'
	import sudokuMenu from '@/components/sudokuMenu'
	import {getloginuser} from '../../../../utils/util.js'
	export default {
		components: {
			sudokuMenu
		},
		data() {
			return {
				menulist: [],
				currentIndex:0,
				userInfo: {},
				appData: []
			}
		},
		mounted() {
			let u = getloginuser()
			// let u = sessionStorage.getItem('customeruser')
			console.log(u,'uuu');
			if (u != null) {
				this.userInfo = JSON.parse(u);
				console.log(this.userInfo, 'this.userInfo');
				this.getdata(this.formInline)
			} else {
				this.$router.push("/app/qiyewx/login");
			}
		},
		methods: {
			// 获取数据方法
			getdata(page) {
				// 请求数据开始
				accountApi.list(res => {
					console.log(res, 'accountApi.res');
					if (res.code == 200) {
						let arr = []
						res.data.forEach((item, index) => {
							console.log(item, 'item');
							arr.push({
								mptype:item.mptype,
								mpid:item.mpid,
								mptypename:item.mptypename,
								mpname: item.mpname,
								img: require(`@/assets/app/menu${index+1}.png`)
							})
						})
						this.menulist = arr
						console.log(this.menulist, 'v');

						// this.menulist = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			myMenuClick(row) {
				console.log(row, 'row');
				let path = ''
				switch (row.mptype) {
					case 10:
						//微信公众号
						path = '/app/qiyewx/list/list'
						break;
					case 11:
						//注册小程序
						path = '/app/qiyewx/list/list'
						break;
					case 12:
						//企业微信
						path = '/app/qiyewx/list/list'
						break;
					case 21:
						//支付宝小程序
						path = '/app/qiyewx/list/list'
						break;
					default:
						break;
				}
				this.$router.push({
					path: path,
					query:{
						mpid:row.mpid
					}
				})
		},
		tabChange(index) {
		}
	}
	}
</script>

<style>
	.content {
		display: flex;
		flex-direction: column;

	}

	.upView {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-left: 12px;
		width: calc(100vw - 24px);
		margin-top: 10px;
		padding: 4px 0px;
		background-color: white;

	}

	.headLine {

		height: 30px;
		line-height: 30px;
		margin: 10px 15px;
		font-size: 20px;
	}

	.nineV {
		width: calc((100vw - 24px)/3);
	}
</style>