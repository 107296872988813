var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"img"},[_c('div',{staticClass:"imgcont",style:({
       'margin-left':_vm.marginpage,
        'margin-right':_vm.marginpage
    })},_vm._l((_vm.list.data),function(item,index){return _c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data &&_vm.data.data.length > 0),expression:"data.data &&data.data.length > 0"}],key:index,staticStyle:{"width":"100%","display":"block"},style:({ 
        'border-radius': _vm.borderradius,
        'margin-top':_vm.margintop,
        'margin-bottom':_vm.marginbottom,
       
      }),attrs:{"src":item.url}})}),1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data.length == 0),expression:"data.data.length == 0"}],staticClass:"empty"},[_c('el-empty',{attrs:{"description":"此区域放置图片"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }