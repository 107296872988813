var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"menu_img_title",style:({
        background: _vm.bgcolor,
        height: _vm.height,
      })},[(
          _vm.list.options && _vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
        )?_c('div',{staticClass:"bgcont"},[_c('img',{attrs:{"src":_vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
              ? _vm.list.options.bgimg
              : ''}})]):_vm._e(),_c('div',{staticClass:"imgcont",style:({
          left: _vm.marginpage,
          top: _vm.margintop,
          'padding-bottom': _vm.itempaddingtop,
          background: _vm.contcolor,
          'border-radius': _vm.borderradius,
          width: _vm.contwidth,
        })},[_c('div',{staticClass:"list",class:_vm.direction ? 'flex-row' : 'flex-col'},_vm._l((_vm.list.data),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
              'padding-top': _vm.itempaddingtop,
			  'padding-bottom':_vm.itempaddingtop,
              width: _vm.itemwidth,
							'border-bottom':_vm.linecolor,
            })},[_c('img',{style:({
			      width: _vm.imgsize,
			      height: _vm.imgsize,
				  'padding-left':_vm.direction == false? _vm.itempaddingtop:0
			    }),attrs:{"src":item.url}}),_c('div',{staticClass:"title",style:({
			      'line-height': _vm.lineheight,
			      height: _vm.lineheight,
			      color: _vm.list.options.titlecolor,
			      fontSize: _vm.size,
			      fontWeight: _vm.bold,
			    })},[_vm._v(" "+_vm._s(item.title)+" ")]),(_vm.direction == false)?_c('div',{staticClass:"gt",style:({'padding-right':_vm.itempaddingtop})},[_vm._v(">")]):_vm._e()])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data.length == 0),expression:"data.data.length == 0"}],staticClass:"empty"},[_c('el-empty',{attrs:{"description":"此区域放置图片"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }