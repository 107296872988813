import httpapi from '../../utils/httpapi';
const cardApi = {
	//微信会员卡配置-编辑/新增
	edit(param, funsucc) {
		httpapi.post('/customer/club/config/wzgzh/edit', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//微信会员卡配置详情
	detail(param, funsucc) {
		httpapi.post('/customer/club/config/wzgzh/detail', param).then(res => {
			console.log(res, 'detail.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//微信会员卡配置列表
	list(param, funsucc) {
		httpapi.post('/customer/club/config/wzgzh/list', param).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//创建微信会员卡
	create(param, funsucc) {
		httpapi.post('/customer/club/config/wzgzh/weixin/create', param).then(res => {
			console.log(res, 'create.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//更新微信会员卡
	update(param, funsucc) {
		httpapi.post('/customer/club/config/wzgzh/weixin/update', param).then(res => {
			console.log(res, 'update.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//激活微信会员卡
	active(param, funsucc) {
		httpapi.get('/customer/club/config/wzgzh/weixin/form?id='+param.id, {}).then(res => {
			console.log(res, 'active.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default cardApi