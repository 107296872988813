<template>
	<div class="">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
			<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>总览</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>公众号开发信息</span>
			</div>
			<div class="main">
				<div class="item">
					开发者ID(AppID)：{{appinfo.appID}}
				</div>
				<div class="item">
					开发者密码(AppSecret)：{{appinfo.appSecret}}
				</div>
			</div>
		</el-card>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>服务器配置</span>
			</div>
			<div class="main">
				<div class="item" v-if="appinfo.isOpen == 0">
					服务器地址(URL)：{{appinfo.postURL}}
				</div>
				<div class="item" v-if="appinfo.isOpen == 0">
					令牌(Token)：{{appinfo.token}}
				</div>
				<div class="item" v-if="appinfo.isOpen == 1">
					{{error || "消息提示"}}
					<a :href="authurl" target="_blank"><el-button size="small" type="primary">授权</el-button></a>
				</div>
				<div class="item">
					消息加解密密钥(EncodingAESKey)：{{appinfo.aesKey}}
				</div>
				<div class="item">
					消息加解密方式：明文模式
				</div>
				
			</div>
		</el-card>
	</div>
</template>

<script>
	import accountApi from '../../../../../../api/cus/account'
	export default {
		data() {
			return {
				mpid: "",
				appinfo: {},
				authurl:'',
				error:""
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			if (mpid != undefined) {
			} else {
				this.$router.push("/");
			}
			accountApi.info({
				mpid: this.mpid
			}, res => {
				console.log(res, 'res.info');
				this.appinfo = res.data
				// this.getauth()
			})
		},
		methods: {
			getauth(){
				accountApi.auth(this.mpid, res => {
					console.log(res, 'res.auth');
					if(res.code == 200){
						this.authurl = res.data
					}else{
						this.error = "授权获取失败"
					}
				})
			}
		}
	}
</script>

<style scoped>
	.box-card {
		margin-bottom: 10px;
	}

	.main .item {
		margin-bottom: 10px;
	}
</style>