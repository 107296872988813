<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		<el-tabs v-model="activeName" type="border-card" size="mini" @tab-click="funtabclick">
			<el-tab-pane label="数据" name="first">
				<div class="boxlist">
					<div v-for="(item, index) in list.data" :key="index" @click="selbox(item, index)" class="item"
						:class="index === boxindex ? 'sel' : 'normal'">
						<!-- <img :src="item.url" /> -->
						<div class="img" v-for="(citem,cindex) in item.child" :key="cindex">
							<img :src="citem.url" v-if="citem.url && citem.url.length > 0" />
						</div>
						<div>{{ item && item.name }}</div>
						<div class="delbox" @click.stop="fundelbox(index)">
							<i class="el-icon-close" style="font-size: 10px;"></i>
						</div>
					</div>
					<div class="item normal" @click="funaddbox">
						<div class="addbox">+</div>
					</div>
				</div>

				<el-tabs v-model="activebox" v-if="boxindex > -1">
					<el-tab-pane label="属性" name="pro">
						<div class="details">
							<!--链接-->
							<div class="list">
								<div class="btn2 full disp">
									<span>链接：</span>
									<span class="text"
										style="color: #00aaff;margin: 0 10px;">{{ boxitem && boxitem.link && boxitem.link.name }}</span>
									<el-button @click="urlPopup(boxindex)" size="mini">选择链接</el-button>
								</div>
							</div>
							<div class="line"></div>
							<div class="list">
								<div class="title">
									<span>宽高 </span>
								</div>
								<div class="btn2 full">
									<el-slider v-model="boxitem.width" show-input :max="750">
									</el-slider>
									<el-slider v-model="boxitem.height" show-input :max="4000">
									</el-slider>
								</div>
							</div>
							<!--项目位置-->
							<div class="list">
								<div class="title">
									<span>位置 </span>
								</div>
								<div class="btn2 full">
									<el-slider v-model="boxitem.left" show-input :max="750">
									</el-slider>
									<el-slider v-model="boxitem.top" show-input :max="4000">
									</el-slider>
								</div>
							</div>
						</div>

					</el-tab-pane>
					<el-tab-pane label="组件" name="zujian">
						<!-- zujian start-->
						<!-- <div style="display: flex;">
							<el-button type="primary" icon="el-icon-plus" @click="addItem('image')" class="add-image">
								图片
							</el-button>

							<el-button type="primary" icon="el-icon-plus" @click="addItem('text')" class="add-image">
								文字
							</el-button>

							<el-button type="primary" icon="el-icon-plus" @click="addItem('button')" class="add-image">
								按钮
							</el-button>
						</div> -->

						<div class="zujian-main" v-if="boxitem">
							<div class="zujian-left">
								<div class="zujian-item">
									<el-dropdown @command="addItem" size="medium" placement="bottom-end"
										trigger="click">
										<span class="el-dropdown-link">
											+
										</span>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="image">图片</el-dropdown-item>
											<el-dropdown-item command="text">文字</el-dropdown-item>
											<el-dropdown-item command="button">按钮</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
								<vuedraggable v-model="boxitem.child" tag="div" draggable=".item"
									v-if="list.data && list.data.length > 0" class="zujian-list"
									:class="{ disable: data.tabType == 2 }">
									<div class="item" :class="index === selindex ? 'sel' : 'normal'"
										v-for="(item, index) in boxitem.child" :key="item.cid"
										@click="selitem(item, index)">
										<span class="label" :class="'label' + item.type">{{
                      item.type == "image"
                        ? "图片"
                        : item.type == "button"
                        ? "按钮"
                        : "文字"
                    }}</span>
										<span @click="removeImage(index)" class="el-icon-close"></span>
										<span @click="funcopy(item)" class="el-icon-plus"></span>
										<img :src="item.url" v-if="item.type == 'image'" />
										<div style="margin-top: 23px;font-size: 10px;" v-else>
											{{ item.title }}
										</div>
									</div>
								</vuedraggable>
							</div>
							<div class="zujian-right" v-if="selindex!= null &&selindex > -1">
								<!--图片开始-->
								<div v-if="item.type == 'image'">
									<p>
										<span>图片:</span>
										<span class="text"><el-input type="text" size="small" v-model="item.url"
												style="width: 200px; margin: 10px"></el-input>
											<el-button type="text" size="mini"
												@click="addImage(selindex)">上传</el-button></span>
									</p>
									<div class="line"></div>
									<!-- 圆角 -->
									<div class="list">
										<div class="title">
											<span>圆角</span>
										</div>
										<div class="btn2 full">
											<el-slider v-model="item.borderradius" show-input :max="800">
											</el-slider>
										</div>
									</div>

								</div>
								<!--图片结束-->
								<!--按钮开始-->
								<div v-else-if="item.type == 'button'">
									<div class="list">按钮:</div>
									<div>
										<span class="text"><el-input type="text" v-model="item.title"
												size="small"></el-input></span>
									</div>
									<!-- 标题文字大小 -->
									<div class="list disp">
										<div class="btn">
											<el-select v-model="item.size" filterable allow-create placeholder="请选择字号"
												size="small">
												<el-option v-for="item in fontlist" :key="item" :label="item"
													:value="item">
												</el-option>
											</el-select>
										</div>

										<div class="btn">
											<el-tooltip effect="dark" content="加粗" placement="top">
												<div class="blod"
													@click="item.bold == 1 ? item.bold = 2 : item.bold = 1">
													<img src="../../../../../assets/B1.png" v-if="item.bold == 2" />
													<img src="../../../../../assets/B0.png" v-else />
												</div>
											</el-tooltip>
											<el-tooltip effect="dark" content="字体色" placement="top">
												<el-color-picker v-model="item.titlecolor"
													size="medium"></el-color-picker>
											</el-tooltip>
											<el-tooltip effect="dark" content="背景色" placement="top">
												<el-color-picker v-model="item.bgcolor" size="medium"></el-color-picker>
											</el-tooltip>
										</div>
									</div>
									<!-- 标题文字颜色 -->
									<!-- <div class="list disp">
										<div class="title">
											<span>文字颜色</span>
										</div>
										<div>{{ item.titlecolor || "默认"
										}}</div>
										<div class="btn">
											<el-button type="text" class="reset"
												@click="reset(item,'color')">重置</el-button><el-color-picker
												v-model="item.titlecolor" size="medium"></el-color-picker>
										</div>
									</div> -->
									<!-- 按钮背景颜色 -->

									<div class="line"></div>
									<!-- 圆角 -->
									<div class="list">
										<div class="title">
											<span>圆角</span>
										</div>
										<div class="btn2 full">
											<el-slider v-model="item.borderradius" show-input :max="800">
											</el-slider>
										</div>
									</div>

								</div>
								<!--按钮结束-->
								<!--文字开始-->
								<div v-else>
									<div class="list">标题:</div>
									<div>

										<span class="text"><el-input type="text" v-model="item.title"
												size="small"></el-input></span>
									</div>
									<!-- 标题文字大小 -->
									<div class="list disp">
										<div class="btn">
											<el-select v-model="item.size" filterable allow-create placeholder="请选择标题大小"
												size="small">
												<el-option v-for="item in fontlist" :key="item" :label="item"
													:value="item">
												</el-option>
											</el-select>
										</div>
										<div class="btn">
											<el-color-picker v-model="item.titlecolor" size="medium"></el-color-picker>
										</div>
										<div class="btn">
											<div class="blod" @click="item.bold == 1 ? item.bold = 2 : item.bold = 1">
												<img src="../../../../../assets/B1.png" v-if="item.bold == 2" />
												<img src="../../../../../assets/B0.png" v-else />
											</div>
											<div class="blod"
												@click="item.position == 1 ? item.position = 0 : item.position = 1">
												<img src="../../../../../assets/c1.png" v-if="item.position == 0" />
												<img src="../../../../../assets/c0.png" v-else />
											</div>
										</div>
									</div>
									<!-- 标题文字颜色 -->
									<!-- <div class="list disp">
										<div class="title">
											<span>文字颜色</span>
										</div>
										<div>{{ item.titlecolor || "默认"
										}}</div>
										<div class="btn">
											<el-button type="text" class="reset"
												@click="reset(item,'color')">重置</el-button><el-color-picker
												v-model="item.titlecolor" size="medium"></el-color-picker>
										</div>
									</div> -->
								</div>
								<!--文字结束-->
								<!--zujian-right start-->
								<!-- <div class="more" @click="funMore">
									{{ moreShow == true ? "隐藏边距" : "显示边距" }}
								</div> -->
								<div class="line"></div>
								<div v-if="true">
									<div>
										<div class="list">
											<div class="title">
												<span>宽高</span>
											</div>
											<div class="btn2 full">
												<el-slider v-model="item.width" show-input :max="800">
												</el-slider>
												<el-slider v-model="item.height" show-input :max="800">
												</el-slider>
											</div>
										</div>
									</div>
									<div>
										<div class="list">
											<div class="title">
												<span>位置</span>
											</div>
											<div class="btn2 full">
												<el-slider v-model="item.left" show-input :max="800">
												</el-slider>
												<el-slider v-model="item.top" show-input :max="800">
												</el-slider>
											</div>
										</div>
									</div>


								</div>
								<!-- zujian-right end -->
							</div>
						</div>

						<!-- zujian end -->
					</el-tab-pane>

				</el-tabs>
			</el-tab-pane>
			<el-tab-pane label="样式" name="css">
				<div class="title">
					模版样式：
				</div>
				<div class="list_style">
					<div class="item_style" v-for="(item, index) in image_magic_style_list" :key="index"
						:class="image_magic_style_index === index ? 'sel' : 'normal'" @click="funselstyle(index)">
						<img :src="item.url" />
						<!-- {{ item.mc }} -->
					</div>
				</div>
				<div class="line"></div>
				<div>
					<!-- 背景颜色 -->
					<div class="list disp">
						<div class="title">
							<span>背景色</span>
						</div>
						<div>
							{{ list.options.bgcolor || "默认"}}
						</div>
						<div class="btn" style="display: flex; align-items: center">
							<el-button type="text" class="reset"
								@click="resetbgcolor(item)">重置</el-button><el-color-picker
								v-model="list.options.bgcolor" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 大背景图 -->
					<div class="titles disp">
						<span>背景图 </span>
						<div style="display: flex">
							<el-button type="text" class="reset" @click="resetbg('bgimg')">重置</el-button>
							<el-button type="text" size="mini" @click="funaddbgimg">上传</el-button>
						</div>
					</div>
					<div class="list">
						<div class="btn2 full bgimg">
							<img :src="list.options.bgimg" />
						</div>
					</div>
				</div>
				<div class="line" style="margin-top: 20px;"></div>
				<!--属性设置-->
				<div class="details">
					<div class="list">
						<div class="title">
							<span>高度</span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.height" show-input :max="5000">
							</el-slider>
						</div>
					</div>
					<!-- 圆角 -->
					<div class="list">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="800">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>上边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list">
						<div class="title">
							<span>下边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="200">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginpage" show-input :max="200">
							</el-slider>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import sellink from "@/components/designer/sellink"
	import selImgs from "@/components/designer/selImgs";
	import vuedraggable from "vuedraggable";
	import designTemplataApi from "../../../../../api/cus/designtmplate";
	export default {
		name: "image_row_edit",
		components: {
			sellink,
			selImgs,
			vuedraggable,
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				fontlist: [
					10, 11, 12, 14, 16, 18, 20, 24, 32, 36, 40, 48, 64, 96, 128
				],
				activeName: "first",
				activebox: "pro",

				list: {},
				boxindex: -1,
				boxitem: {},
				item: {},
				url: "",
				index: null,
				selindex: null,
				show: false,
				moreShow: false,
				imageIndex: null,
				image_magic_style_index: 0,
				image_magic_style_list: [{
						mc: "上2下3",
						memo: "图片建议尺寸：。。。",
						url: require('../../../../../assets/image/market/style6.png'),
						data: [{
							"top": 15,
							"left": 22,
							"link": {
								"type": "app",
								"name": "站内链接:扫码点单",
								"key": "diandan",
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单"
								}
							},
							"width": 158,
							"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/0b9e3130593740d5b1065e0e67ff7caf.png?key=123456",
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "1213",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/0b9e3130593740d5b1065e0e67ff7caf.png?key=123456",
								"bgcolor": "#F7420B",
								"top": -1,
								"size": 1,
								"left": 1,
								"width": 158,
								"borderradius": 0,
								"position": 2,
								"cid": "BD31373A0F2ED885",
								"height": 99
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/88c67a7fb31e4ef897893a9478f04f54.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 19,
								"size": 1,
								"left": -5,
								"width": 163,
								"borderradius": 0,
								"position": 2,
								"cid": "D998A8079B4FBDCC",
								"height": 59
							}]
						}, {
							"top": 15,
							"left": 198,
							"link": {
								"type": "app",
								"name": "站内链接:我的预约",
								"key": "booklist",
								"app": {
									"path": "/pages/club/club_booklist",
									"name": "我的预约"
								}
							},
							"width": 157,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/0b9e3130593740d5b1065e0e67ff7caf.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": 1,
								"width": 160,
								"borderradius": 0,
								"position": 2,
								"cid": "D209D3336E2E5844",
								"height": 102
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/f58aa45fa7394b58828c22db4a17565a.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 23,
								"size": 1,
								"left": 3,
								"width": 157,
								"borderradius": 0,
								"position": 2,
								"cid": "F5549DC98324E2E0",
								"height": 58
							}]
						}, {
							"top": 127,
							"left": 22,
							"link": {
								"type": "app",
								"name": "站内链接:礼品卡",
								"key": "ecard",
								"app": {
									"path": "/pages/club/ecardlist",
									"name": "礼品卡"
								}
							},
							"width": 105,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": -1,
								"width": 106,
								"borderradius": 0,
								"position": 2,
								"cid": "58D3435C3AD6B28F",
								"height": 98
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309940aa4f77e074c7780008dbe9c816f2c.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 36,
								"size": 1,
								"left": 28,
								"width": 50,
								"borderradius": 0,
								"position": 2,
								"cid": "69D7FB2CD5FDA575",
								"height": 46
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "text",
								"title": "礼品卡",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 8,
								"size": 2,
								"left": 9,
								"width": 89,
								"borderradius": 0,
								"position": 2,
								"cid": "B700EF36B3265E6C",
								"height": 29
							}]
						}, {
							"top": 127,
							"left": 137,
							"link": {
								"type": "app",
								"name": "站内链接:积分商城",
								"key": "scoreshop",
								"app": {
									"path": "/pages/club/club_scoreshop",
									"name": "积分商城"
								}
							},
							"width": 105,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": 1,
								"width": 106,
								"borderradius": 0,
								"position": 2,
								"cid": "5739EA951A73BE6C",
								"height": 101
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023094304eb37c7d3457bab7d95b05e192429.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 37,
								"size": 1,
								"left": 31,
								"width": 49,
								"borderradius": 0,
								"position": 2,
								"cid": "0094F6E9CCEA3166",
								"height": 48
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "text",
								"title": "积分商城",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 7,
								"size": 2,
								"left": 7,
								"width": 90,
								"borderradius": 0,
								"position": 2,
								"cid": "5CF198FA341817C1",
								"height": 28
							}]
						}, {
							"top": 127,
							"left": 250,
							"link": {
								"type": "app",
								"name": "站内链接:我的礼券",
								"key": "mytickets",
								"app": {
									"path": "/pages/club/club_tickets",
									"name": "我的礼券"
								}
							},
							"width": 105,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": -2,
								"width": 106,
								"borderradius": 0,
								"position": 2,
								"cid": "FCBF5CE607DE378D",
								"height": 100
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309795db6a1c6c74e44971b3f70bd3845dc.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 39,
								"size": 2,
								"left": 28,
								"width": 48,
								"borderradius": 0,
								"position": 2,
								"cid": "42E72420DBC84CA3",
								"height": 45
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "text",
								"title": "优惠券",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 7,
								"size": 2,
								"left": 6,
								"width": 87,
								"borderradius": 0,
								"position": 2,
								"cid": "55F57CD3350DBA20",
								"height": 29
							}]
						}],
					}, {
						style: "left2right2",
						mc: "左2右2",
						url: require('../../../../../assets/image/market/style1.png'),
						"data": [{
							"top": 16,
							"left": 37,
							"link": {
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单"
								},
								"name": "站内链接:扫码点单",
								"type": "app",
								"key": "diandan"
							},
							"width": 142,
							"height": 142,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/bb7078fe09954de8a229571bf71a3141.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 142,
								"borderradius": 12,
								"position": 2,
								"cid": "6D271690984FB27E",
								"height": 142
							}]
						}, {
							"top": 16,
							"left": 194,
							"link": {
								"app": {
									"path": "/pages/club/club_accetpticket",
									"name": "领券中心"
								},
								"name": "站内链接:领券中心",
								"type": "app",
								"key": "handtickets"
							},
							"width": 142,
							"height": 142,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/88526f29cc164ba7ab9795989bf6bdb6.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 142,
								"borderradius": 12,
								"position": 2,
								"cid": "B2279881CF77376E",
								"height": 142
							}]
						}, {
							"top": 171,
							"left": 37,
							"link": {
								"app": {
									"path": "/pages/club/club_scoreshop",
									"name": "积分商城"
								},
								"name": "站内链接:积分商城",
								"type": "app",
								"key": "scoreshop"
							},
							"width": 142,
							"height": 162,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/a45da9087d29401ebc6b9384dd1ac6da.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 142,
								"borderradius": 0,
								"position": 2,
								"cid": "DE303E740D62A7F8",
								"height": 162
							}]
						}, {
							"top": 171,
							"left": 194,
							"link": {
								"app": {
									"path": "/pages/club/club_card",
									"name": "我的会员卡"
								},
								"name": "站内链接:我的会员卡",
								"type": "app",
								"key": "myclubcard"
							},
							"width": 142,
							"height": 162,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/03e0996db9ac4d12ae5fe4c9db15c462.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 142,
								"borderradius": 0,
								"position": 2,
								"cid": "600B1273562D8DEC",
								"height": 162
							}]
						}],
						"options": {
							"marginbottom": 0,
							"marginpage": 0,
							"borderradius": 10,
							"bgimg": "",
							"margintop": 0,
							"height": 693
						},
					},
					{
						style: "left1right2",
						memo: "图片建议尺寸：。。。",
						mc: "左1右2",
						url: require('../../../../../assets/image/market/style2.png'),

						"data": [{
							"top": 15,
							"left": 22,
							"link": {
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单",
									"type": "diandan"
								},
								"name": "站内链接:扫码点单",
								"type": "app"
							},
							"width": 161,
							"height": 160,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202311/d5c8fdfa1bfa455c82a265f13249257f.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 4,
								"size": 1,
								"left": 2,
								"width": 160,
								"borderradius": 11,
								"position": 2,
								"cid": "76550C75CB72B20B",
								"height": 157
							}]
						}, {
							"top": 15,
							"left": 198,
							"link": {
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单",
									"type": "diandan"
								},
								"name": "站内链接:扫码点单",
								"type": "app"
							},
							"width": 157,
							"height": 84,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202311/05c9ee90cd694554bbe12ffe124158f2.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 2,
								"size": 1,
								"left": 4,
								"width": 152,
								"borderradius": 10,
								"position": 2,
								"cid": "3A9BF9A05FF23B1A",
								"height": 84
							}]
						}, {
							"top": 109,
							"left": 198,
							"link": {
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单",
									"type": "diandan"
								},
								"name": "站内链接:扫码点单",
								"type": "app"
							},
							"width": 72,
							"height": 68,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 72,
								"borderradius": 10,
								"position": 2,
								"cid": "50FD9DF95693190D",
								"height": 68
							}, {
								"titlecolor": "#000000",
								"bold": 1,
								"type": "text",
								"title": "礼品卡",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 40,
								"size": 2,
								"left": 1,
								"width": 67,
								"borderradius": 0,
								"position": 2,
								"cid": "90215F364DF3722A",
								"height": 26
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309f132cc921e9743289d38d6547d8b9c66.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 6,
								"size": 1,
								"left": 14,
								"width": 45,
								"borderradius": 0,
								"position": 2,
								"cid": "A634F2343CFB83C9",
								"height": 36
							}]
						}, {
							"top": 109,
							"left": 283,
							"link": {
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单",
									"type": "diandan"
								},
								"name": "站内链接:扫码点单",
								"type": "app"
							},
							"width": 72,
							"height": 68,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 72,
								"borderradius": 10,
								"position": 2,
								"cid": "3C68F3E340A43867",
								"height": 68
							}, {
								"titlecolor": "#000000",
								"bold": 1,
								"type": "text",
								"title": "优惠券",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 40,
								"size": 2,
								"left": 1,
								"width": 72,
								"borderradius": 0,
								"position": 2,
								"cid": "F967E5F43C81E11E",
								"height": 24
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023091d7741cb489d40e6976e124259613dbb.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 6,
								"size": 1,
								"left": 14,
								"width": 42,
								"borderradius": 0,
								"position": 2,
								"cid": "39D8039543969552",
								"height": 38
							}]
						}],
						"options": {
							"marginbottom": 0,
							"marginpage": 0,
							"borderradius": 10,
							"bgimg": "",
							"margintop": 0,
							"height": 386
						}

					},
					{
						style: "top1bottom2",
						memo: "图片建议尺寸：。。。",
						mc: "上1下2",
						url: require('../../../../../assets/image/market/style3.png'),
						"data": [{
							"top": 0,
							"left": 16,
							"link": {
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单",
									"type": "diandan"
								},
								"name": "站内链接:扫码点单",
								"type": "app"
							},
							"width": 343,
							"height": 91,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#06A581",
								"top": 0,
								"size": 1,
								"left": 2,
								"width": 343,
								"borderradius": 5,
								"position": 2,
								"cid": "FB961010DE1EEAF5",
								"height": 91
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202405/8fb20faebf864fae987e47527d9ddb95.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 53,
								"size": 1,
								"left": 0,
								"width": "343",
								"borderradius": 0,
								"position": 2,
								"cid": "1F6B5E47A0188B2E",
								"height": "25"
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 2,
								"type": "text",
								"title": "积分商城",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 7,
								"size": 16,
								"left": 0,
								"width": 100,
								"borderradius": 0,
								"position": 2,
								"cid": "CB64DCD924266D2F",
								"height": 30
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 2,
								"type": "text",
								"title": "APPLY FOR MEMBERSHIP",
								"url": "",
								"bgcolor": "#000000",
								"top": 11,
								"size": 10,
								"left": "160",
								"width": "200",
								"borderradius": 0,
								"position": 2,
								"cid": "164C1CBF5DEFC035",
								"height": 30
							}, {
								"titlecolor": "#36775C",
								"bold": 1,
								"type": "button",
								"title": "立即领取",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 52,
								"size": 12,
								"left": 253,
								"width": 68,
								"borderradius": 14,
								"position": 2,
								"cid": "FEAEF604617EABDD",
								"height": 24
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "text",
								"title": "DRINK",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 12,
								"size": 12,
								"left": 81,
								"width": 59,
								"borderradius": 0,
								"position": 2,
								"cid": "A6DD6374E25AAD9D",
								"height": 23
							}]
						}, {
							"top": 113,
							"left": 16,
							"link": {},
							"width": 162,
							"height": 136,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#06A581",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 162,
								"borderradius": 10,
								"position": 2,
								"cid": "EA92EF49FB800B90",
								"height": 136
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202403/70c3471dce614af08db5ffa7e04fc5a4.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 9,
								"size": 1,
								"left": 9,
								"width": 30,
								"borderradius": 0,
								"position": 2,
								"cid": "1D6335C19E740F14",
								"height": 30
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/ac660560d5c14e628c9762f65acf84fe.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 52,
								"size": 1,
								"left": 4,
								"width": 152,
								"borderradius": 0,
								"position": 2,
								"cid": "3E5B0769764EB218",
								"height": 24
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 2,
								"type": "text",
								"title": "#扫码点单",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 8,
								"size": 16,
								"left": 57,
								"width": 100,
								"borderradius": 0,
								"position": 0,
								"cid": "E7640999882D23DF",
								"height": 30
							}, {
								"titlecolor": "#36775C",
								"bold": 0,
								"type": "button",
								"title": "立即使用",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 94,
								"size": 12,
								"left": 81,
								"width": 68,
								"borderradius": 10,
								"position": 2,
								"cid": "4D27C02C60933315",
								"height": 24
							}]
						}, {
							"top": 113,
							"left": 196,
							"link": {},
							"width": 162,
							"height": 136,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#06A581",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 162,
								"borderradius": 10,
								"position": 2,
								"cid": "419C2276ABCA4076",
								"height": 136
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202403/d8c67c8c17c64e8fbb93659a092d0f05.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 9,
								"size": 1,
								"left": 9,
								"width": 30,
								"borderradius": 0,
								"position": 2,
								"cid": "55BACFC4A11E1762",
								"height": 30
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 2,
								"type": "text",
								"title": "#积分商城",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 8,
								"size": 16,
								"left": 57,
								"width": 100,
								"borderradius": 0,
								"position": 0,
								"cid": "5730E5032ABEBE99",
								"height": 30
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/ac660560d5c14e628c9762f65acf84fe.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 52,
								"size": 1,
								"left": 4,
								"width": 152,
								"borderradius": 0,
								"position": 2,
								"cid": "B99BA213C15EC89E",
								"height": 24
							}, {
								"titlecolor": "#36775C",
								"bold": 1,
								"type": "button",
								"title": "立即使用",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 94,
								"size": 16,
								"left": 81,
								"width": 68,
								"borderradius": 10,
								"position": 2,
								"cid": "9945F1B5233E7949",
								"height": 24
							}]
						}]
					},
					{
						style: "top2bottom1",
						memo: "图片建议尺寸：。。。",
						mc: "上2下1",
						url: require('../../../../../assets/image/market/style4.png'),
						"data": [{
							"top": 16,
							"left": 16,
							"link": {},
							"width": 162,
							"height": 136,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#06A581",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 162,
								"borderradius": 10,
								"position": 2,
								"cid": "EA92EF49FB800B90",
								"height": 136
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202403/70c3471dce614af08db5ffa7e04fc5a4.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 9,
								"size": 1,
								"left": 9,
								"width": 30,
								"borderradius": 0,
								"position": 2,
								"cid": "1D6335C19E740F14",
								"height": 30
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/ac660560d5c14e628c9762f65acf84fe.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 52,
								"size": 1,
								"left": 4,
								"width": 152,
								"borderradius": 0,
								"position": 2,
								"cid": "3E5B0769764EB218",
								"height": 24
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 2,
								"type": "text",
								"title": "#扫码点单",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 8,
								"size": 16,
								"left": 57,
								"width": 100,
								"borderradius": 0,
								"position": 0,
								"cid": "E7640999882D23DF",
								"height": 30
							}, {
								"titlecolor": "#36775C",
								"bold": 0,
								"type": "button",
								"title": "立即使用",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 94,
								"size": 12,
								"left": 81,
								"width": 68,
								"borderradius": 10,
								"position": 2,
								"cid": "4D27C02C60933315",
								"height": 24
							}]
						}, {
							"top": 16,
							"left": 196,
							"link": {},
							"width": 162,
							"height": 136,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#06A581",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 162,
								"borderradius": 10,
								"position": 2,
								"cid": "419C2276ABCA4076",
								"height": 136
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202403/d8c67c8c17c64e8fbb93659a092d0f05.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 9,
								"size": 1,
								"left": 9,
								"width": 30,
								"borderradius": 0,
								"position": 2,
								"cid": "55BACFC4A11E1762",
								"height": 30
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 2,
								"type": "text",
								"title": "#积分商城",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 8,
								"size": 16,
								"left": 57,
								"width": 100,
								"borderradius": 0,
								"position": 0,
								"cid": "5730E5032ABEBE99",
								"height": 30
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202406/ac660560d5c14e628c9762f65acf84fe.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 52,
								"size": 1,
								"left": 4,
								"width": 152,
								"borderradius": 0,
								"position": 2,
								"cid": "B99BA213C15EC89E",
								"height": 24
							}, {
								"titlecolor": "#36775C",
								"bold": 1,
								"type": "button",
								"title": "立即使用",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 94,
								"size": 16,
								"left": 81,
								"width": 68,
								"borderradius": 10,
								"position": 2,
								"cid": "9945F1B5233E7949",
								"height": 24
							}]
						}, {
							"top": 172,
							"left": 16,
							"link": {},
							"width": 343,
							"height": 91,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "button",
								"title": "",
								"url": "",
								"bgcolor": "#06A581",
								"top": 0,
								"size": 1,
								"left": 0,
								"width": 343,
								"borderradius": 10,
								"position": 2,
								"cid": "00206C95FC2F9F47",
								"height": 91
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202405/8fb20faebf864fae987e47527d9ddb95.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 53,
								"size": 1,
								"left": 0,
								"width": 343,
								"borderradius": 0,
								"position": 2,
								"cid": "5CB18344CF2EA492",
								"height": 24
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "https://gzh.dxerp.net/dxgzh/isv1762683941985431553/202403/c8e078aaa1ee42aa898b080599583476.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 10,
								"size": 1,
								"left": 11,
								"width": 39,
								"borderradius": 0,
								"position": 2,
								"cid": "38A360EEB0BD2917",
								"height": 21
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 2,
								"type": "text",
								"title": "#黑金会员",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 5,
								"size": 16,
								"left": 48,
								"width": 100,
								"borderradius": 0,
								"position": 2,
								"cid": "5B9C0FE8F923FA31",
								"height": 30
							}]
						}],
						"options": {
							"bgcolor": "",
							"marginbottom": 0,
							"marginpage": 0,
							"borderradius": 10,
							"bgimg": "",
							"margintop": 0,
							"height": 552
						}



					},
					// {
					// 	mc: "左1上1下2",
					// 	memo: "图片建议尺寸：。。。",
					// 	url: "",
					// 	data: [{
					// 		"top": 15,
					// 		"left": 22,
					// 		"link": {
					// 			"type": "app",
					// 			"name": "站内链接:扫码点单",
					// 			"key": "diandan",
					// 			"app": {
					// 				"path": "/pages/diandan/index",
					// 				"name": "扫码点单"
					// 			}
					// 		},
					// 		"width": 158,
					// 		"height": 212,
					// 		"child": [{
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023095de398fa5293464d8fe53f58ab86675a.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": 4,
					// 			"size": 1,
					// 			"left": 2,
					// 			"width": 154,
					// 			"borderradius": 11,
					// 			"position": 2,
					// 			"cid": "76550C75CB72B20B",
					// 			"height": 204
					// 		}, {
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309d11f167ce6db4697a48df406f421b45c.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": 132,
					// 			"size": 1,
					// 			"left": 17,
					// 			"width": 124,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "13CD769142080528",
					// 			"height": 55
					// 		}, {
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023094ac84538f6404061b68c86e4a1b76043.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": 18,
					// 			"size": 1,
					// 			"left": 25,
					// 			"width": 102,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "157BD129F5639F04",
					// 			"height": 99
					// 		}]
					// 	}, {
					// 		"top": 15,
					// 		"left": 198,
					// 		"link": {
					// 			"type": "app",
					// 			"name": "站内链接:在线订台（座位图）",
					// 			"key": "book",
					// 			"app": {
					// 				"path": "/pages/book/index",
					// 				"name": "在线订台（座位图）"
					// 			}
					// 		},
					// 		"width": 157,
					// 		"height": 100,
					// 		"child": [{
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023095de398fa5293464d8fe53f58ab86675a.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": 2,
					// 			"size": 1,
					// 			"left": 3,
					// 			"width": 149,
					// 			"borderradius": 10,
					// 			"position": 2,
					// 			"cid": "3A9BF9A05FF23B1A",
					// 			"height": 93
					// 		}, {
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 2,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023092fb1aacc8f4845d7aaacd5f48bf2f3ab.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": 14,
					// 			"size": 1,
					// 			"left": 15,
					// 			"width": 67,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "09A7AB5337D67271",
					// 			"height": 66
					// 		}, {
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 2,
					// 			"type": "text",
					// 			"title": "订台",
					// 			"url": "",
					// 			"bgcolor": "#FFFFFF",
					// 			"top": 23,
					// 			"size": 1,
					// 			"left": 93,
					// 			"width": 50,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "9753816485FB3EB4",
					// 			"height": 34
					// 		}, {
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "text",
					// 			"title": "dingtai",
					// 			"url": "",
					// 			"bgcolor": "#FFFFFF",
					// 			"top": 52,
					// 			"size": 3,
					// 			"left": 86,
					// 			"width": 59,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "1358767547CB0C9D",
					// 			"height": 18
					// 		}]
					// 	}, {
					// 		"top": 127,
					// 		"left": 198,
					// 		"link": {
					// 			"type": "app",
					// 			"name": "站内链接:礼品卡",
					// 			"key": "ecard",
					// 			"app": {
					// 				"path": "/pages/club/ecardlist",
					// 				"name": "礼品卡"
					// 			}
					// 		},
					// 		"width": 72,
					// 		"height": 100,
					// 		"child": [{
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": -8,
					// 			"size": 1,
					// 			"left": -3,
					// 			"width": 81,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "CF15709DA5A4344A",
					// 			"height": 110
					// 		}, {
					// 			"titlecolor": "#000000",
					// 			"bold": 1,
					// 			"type": "text",
					// 			"title": "礼品卡",
					// 			"url": "",
					// 			"bgcolor": "#FFFFFF",
					// 			"top": 46,
					// 			"size": 2,
					// 			"left": 3,
					// 			"width": 67,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "90215F364DF3722A",
					// 			"height": 26
					// 		}, {
					// 			"titlecolor": "#9E9E9E",
					// 			"bold": 1,
					// 			"type": "text",
					// 			"title": "LIPINKA",
					// 			"url": "",
					// 			"bgcolor": "#FFFFFF",
					// 			"top": 68,
					// 			"size": 3,
					// 			"left": 2,
					// 			"width": 69,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "6703795CEBD500F6",
					// 			"height": 26
					// 		}, {
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309f132cc921e9743289d38d6547d8b9c66.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": 6,
					// 			"size": 1,
					// 			"left": 14,
					// 			"width": 45,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "A634F2343CFB83C9",
					// 			"height": 36
					// 		}]
					// 	}, {
					// 		"top": 127,
					// 		"left": 283,
					// 		"link": {
					// 			"type": "app",
					// 			"name": "站内链接:我的礼券",
					// 			"key": "mytickets",
					// 			"app": {
					// 				"path": "/pages/club/club_tickets",
					// 				"name": "我的礼券"
					// 			}
					// 		},
					// 		"width": 72,
					// 		"height": 100,
					// 		"child": [{
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": -5,
					// 			"size": 1,
					// 			"left": -6,
					// 			"width": 82,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "0FBEE96CE4D7CB4F",
					// 			"height": 109
					// 		}, {
					// 			"titlecolor": "#000000",
					// 			"bold": 1,
					// 			"type": "text",
					// 			"title": "优惠券",
					// 			"url": "",
					// 			"bgcolor": "#FFFFFF",
					// 			"top": 47,
					// 			"size": 2,
					// 			"left": -2,
					// 			"width": 72,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "F967E5F43C81E11E",
					// 			"height": 24
					// 		}, {
					// 			"titlecolor": "#A5A5A5",
					// 			"bold": 1,
					// 			"type": "text",
					// 			"title": "YOUHUI",
					// 			"url": "",
					// 			"bgcolor": "#FFFFFF",
					// 			"top": 69,
					// 			"size": 3,
					// 			"left": 0,
					// 			"width": 70,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "B92872BF1E0CFA3B",
					// 			"height": 22
					// 		}, {
					// 			"titlecolor": "#FFFFFF",
					// 			"bold": 1,
					// 			"type": "image",
					// 			"title": "",
					// 			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023091d7741cb489d40e6976e124259613dbb.png?key=123456",
					// 			"bgcolor": "#F7420B",
					// 			"top": 3,
					// 			"size": 1,
					// 			"left": 14,
					// 			"width": 42,
					// 			"borderradius": 0,
					// 			"position": 2,
					// 			"cid": "39D8039543969552",
					// 			"height": 38
					// 		}]
					// 	}],
					// },
					{
						mc: "上2下3",
						memo: "图片建议尺寸：。。。",
						url: require('../../../../../assets/image/market/style5.png'),
						data: [{
							"top": 15,
							"left": 22,
							"link": {
								"type": "app",
								"name": "站内链接:扫码点单",
								"key": "diandan",
								"app": {
									"path": "/pages/diandan/index",
									"name": "扫码点单"
								}
							},
							"width": 158,
							"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/0b9e3130593740d5b1065e0e67ff7caf.png?key=123456",
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "1213",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/0b9e3130593740d5b1065e0e67ff7caf.png?key=123456",
								"bgcolor": "#F7420B",
								"top": -1,
								"size": 1,
								"left": 1,
								"width": 158,
								"borderradius": 0,
								"position": 2,
								"cid": "BD31373A0F2ED885",
								"height": 99
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/88c67a7fb31e4ef897893a9478f04f54.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 19,
								"size": 1,
								"left": -5,
								"width": 163,
								"borderradius": 0,
								"position": 2,
								"cid": "D998A8079B4FBDCC",
								"height": 59
							}]
						}, {
							"top": 15,
							"left": 198,
							"link": {
								"type": "app",
								"name": "站内链接:我的预约",
								"key": "booklist",
								"app": {
									"path": "/pages/club/club_booklist",
									"name": "我的预约"
								}
							},
							"width": 157,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/0b9e3130593740d5b1065e0e67ff7caf.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": 1,
								"width": 160,
								"borderradius": 0,
								"position": 2,
								"cid": "D209D3336E2E5844",
								"height": 102
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/f58aa45fa7394b58828c22db4a17565a.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 23,
								"size": 1,
								"left": 3,
								"width": 157,
								"borderradius": 0,
								"position": 2,
								"cid": "F5549DC98324E2E0",
								"height": 58
							}]
						}, {
							"top": 127,
							"left": 22,
							"link": {
								"type": "app",
								"name": "站内链接:礼品卡",
								"key": "ecard",
								"app": {
									"path": "/pages/club/ecardlist",
									"name": "礼品卡"
								}
							},
							"width": 105,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": -1,
								"width": 106,
								"borderradius": 0,
								"position": 2,
								"cid": "58D3435C3AD6B28F",
								"height": 98
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309940aa4f77e074c7780008dbe9c816f2c.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 36,
								"size": 1,
								"left": 28,
								"width": 50,
								"borderradius": 0,
								"position": 2,
								"cid": "69D7FB2CD5FDA575",
								"height": 46
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "text",
								"title": "礼品卡",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 8,
								"size": 2,
								"left": 9,
								"width": 89,
								"borderradius": 0,
								"position": 2,
								"cid": "B700EF36B3265E6C",
								"height": 29
							}]
						}, {
							"top": 127,
							"left": 137,
							"link": {
								"type": "app",
								"name": "站内链接:积分商城",
								"key": "scoreshop",
								"app": {
									"path": "/pages/club/club_scoreshop",
									"name": "积分商城"
								}
							},
							"width": 105,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": 1,
								"width": 106,
								"borderradius": 0,
								"position": 2,
								"cid": "5739EA951A73BE6C",
								"height": 101
							}, {
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023094304eb37c7d3457bab7d95b05e192429.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 37,
								"size": 1,
								"left": 31,
								"width": 49,
								"borderradius": 0,
								"position": 2,
								"cid": "0094F6E9CCEA3166",
								"height": 48
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "text",
								"title": "积分商城",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 7,
								"size": 2,
								"left": 7,
								"width": 90,
								"borderradius": 0,
								"position": 2,
								"cid": "5CF198FA341817C1",
								"height": 28
							}]
						}, {
							"top": 127,
							"left": 250,
							"link": {
								"type": "app",
								"name": "站内链接:我的礼券",
								"key": "mytickets",
								"app": {
									"path": "/pages/club/club_tickets",
									"name": "我的礼券"
								}
							},
							"width": 105,
							"height": 100,
							"child": [{
								"titlecolor": "#FFFFFF",
								"bold": 1,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 1,
								"size": 1,
								"left": -2,
								"width": 106,
								"borderradius": 0,
								"position": 2,
								"cid": "FCBF5CE607DE378D",
								"height": 100
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "image",
								"title": "",
								"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309795db6a1c6c74e44971b3f70bd3845dc.png?key=123456",
								"bgcolor": "#F7420B",
								"top": 39,
								"size": 2,
								"left": 28,
								"width": 48,
								"borderradius": 0,
								"position": 2,
								"cid": "42E72420DBC84CA3",
								"height": 45
							}, {
								"titlecolor": "#414141",
								"bold": 2,
								"type": "text",
								"title": "优惠券",
								"url": "",
								"bgcolor": "#FFFFFF",
								"top": 7,
								"size": 2,
								"left": 6,
								"width": 87,
								"borderradius": 0,
								"position": 2,
								"cid": "55F57CD3350DBA20",
								"height": 29
							}]
						}],
					}

				],
				//定义结束
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;
		},
		methods: {
			close() {
				this.show = false;
				this.url = "";
			},
			selbox(box, index) {
				
				this.boxitem = box;
				this.boxindex = index;
			},
			funtabclick(e) {
				
				if (e.name == "first") {
					if (this.boxindex == -1) {
						if (this.list.data && this.list.data.length > 0) {
							this.boxitem = this.list.data[0];
							this.boxindex = 0;
						}
					}
				}
			},
			funaddbox() {
				this.list.data.push({
					"top": 0,
					"left": 0,
					"link": {},
					"width": 158,
					"height": 100,
					"child": [{
						"cid": "5730E5032ABEBE99",
						"type": "text",
						"left": 0,
						"top": 0,
						"width": 100,
						"height": 30,
						"title": "新容器",
						"url": "",
						"bgcolor": "#FFFFFF",
						"titlecolor": "#000000",
						"position": 2,
						"bold": 1,
						"size": 1,
						"borderradius": 0
					}]
				})
			},
			fundelbox(index) {
				this.$confirm('确认删除容器?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						this.list.data.splice(index, 1)
					})
					.catch(() => {

					})
			},
			selitem(item, index) {
				
				this.item = item;
				this.item.width = parseInt(this.item.width)
				this.item.height = parseInt(this.item.height)
				this.item.top = parseInt(this.item.top)
				this.item.left = parseInt(this.item.left)
				this.selindex = index;
			},

			addItem(type) {
				if (this.boxitem.child == undefined) {
					this.$message({
						type: "error",
						message: "请选择模块"
					})
					return;
				}

				const len = this.list.data.length;
				const col = 2;
				const row = parseInt(len / 3);
				const row_num = len % 2;

				let left = row_num * 25;
				let top = 50 * row;
				if (left < 0) {
					left = 0;
				}
				if (top < 0) {
					top = 0;
				}
				let width = 100;
				let height = 100;
				let url = "";
				let title = "";
				let borderradius = 0;
				let bgcolor = "#F7420B";
				let color = "#FFFFFF";
				switch (type) {
					case "image":
						url =
							"http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/169241699017262694620230911073baca94a48ff8387f775ae0cfdc3.png?key=123456";
						break;
					case "button":
						width = 100;
						height = 40;
						title = "按钮";
						borderradius = 10;
						bgcolor = "#F7420B";
						color = "#FFFFFF";
						break;
					case "text":
						width = 100;
						height = 30;
						title = "文字";
						bgcolor = "#FFFFFF";
						color = "#000000";
						borderradius = 0;
						break;
					default:
						return;
				}
				
				const data = {
					cid: designTemplataApi.createToolBoxId(),
					type: type,
					left: left,
					top: top,
					width: width,
					height: height,
					title: title, //文字
					url: url, //图片地址
					bgcolor: bgcolor, //背景色
					titlecolor: color, //标题色
					position: 2,
					bold: 1,
					size: 1,
					borderradius: borderradius,
				};

				this.boxitem.child.push(data);

				
			},

			funMore() {
				this.moreShow = !this.moreShow;
			},

			funselstyle(index) {
				this.image_magic_style_index = index;
				let data = this.image_magic_style_list[index].data;
				let options = this.image_magic_style_list[index].options
				
				this.list.data = data;
				this.list.options = options
				//this.$set(this.list.data,data);
			},
			reset(item, key) {
				item.url = ''
			},
			resetbg(key) {
				this.list.options.bgimg = ''
			},
			resetbgcolor() {
				this.list.options.bgcolor = ''
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index) {
				this.$refs['sellink'].funShow()
				// this.show = true;
				// this.index = index;
				// this.url = link;
			},
			funcopy(item) {
				
				if (item != undefined && item.cid != undefined) {
					const data = {
						cid: this.uuid2(16, 16),
						type: item.type,
						left: item.left,
						top: item.top,
						width: item.width,
						height: item.height,
						title: item.title, //文字
						url: item.url, //图片地址
						bgcolor: item.bgcolor, //背景色
						titlecolor: item.titlecolor, //标题色
						position: item.position,
						bold: item.bold,
						size: item.size,
						borderradius: item.borderradius,
					};
					this.boxitem.child.push(data);
				} else {
					this.$message.error("未选中");
				}
			},
			// 指定长度和基数
			uuid2(len, radix) {
				var chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
				var uuid = [],
					i;
				radix = radix || chars.length;

				if (len) {
					// Compact form
					for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
				} else {
					// rfc4122, version 4 form
					var r;

					// rfc4122 requires these characters
					uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
					uuid[14] = '4';

					// Fill in random data.  At i==19 set the high bits of clock sequence as
					// per rfc4122, sec. 4.1.5
					for (i = 0; i < 36; i++) {
						if (!uuid[i]) {
							r = 0 | Math.random() * 16;
							uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
						}
					}
				}

				return uuid.join('');
			},
			removeImage(index) {
				this.$confirm("确认删除吗, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "warning",
					})
					.then(() => {
						this.$message({
							type: "success",
							message: "删除成功!",
						});
						
						this.boxitem.child.splice(index, 1);
					})
					.catch(() => {});
			},
			addImage(index) {
				this.imageIndex = index;
				this.$refs["selImgs"].funShow();
				// this.$refs["upload"].$children[0].$refs.input.click();
			},
			funaddbgimg() {
				this.imageIndex = "bgimg";
				this.$refs["selImgs"].funShow();
			},
			sellinkresult(param) {
			
				this.boxitem.link = param;
			},
			selImgsresult(imgitem) {
				if (this.imageIndex === "bgimg") {
					//this.list.options.bgimg = imgitem.url;
					this.$set(this.list.options, "bgimg", imgitem.url);
				} else {
					if (this.imageIndex !== null) {
						
						this.boxitem.child[this.imageIndex].url = imgitem.url
						
					}
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: calc(100% - 20px);
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin-left: 10px;
			margin-bottom: 20px;
		}

		.boxlist {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			position: relative;
		}

		.boxlist .delbox {
			cursor: pointer;
			position: absolute;
			right: 2px;
			top: 2px;
			z-index: 1;
			border-radius: 100%;
			width: 10px;
			height: 10px;
			font-size: 10px;
			line-height: 10px;
			text-align: center;
			overflow: hidden;
			background-color: #fff;
		}

		.boxlist .item {
			width: 120px;
			height: 60px;
			margin: 5px;
			position: relative;
		}

		.boxlist .item .addbox {
			cursor: pointer;
			text-align: center;
			line-height: 60px;
			color: #fff;
			background-color: #00aaff;
			border: none;
			font-size: 30px;
		}

		.boxlist .img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		.boxlist .img img {
			width: 100%;
			height: 100%;
		}

		.zujian-main {
			width: 100%;
			display: flex;
		}

		.zujian-left {
			flex-shrink: 0;
			margin-right: 10px;
			width: 70px;
			border-right: 1px solid #f0f0f0;
		}

		.zujian-item {
			cursor: pointer;
			margin: 2px;
			width: 60px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			background-color: #409EFF;

			.el-dropdown-link {
				color: #fff;
				font-size: 20px;
				width: 60px;
				height: 30px;
				display: inline-block;
			}
		}

		.zujian-right {
			flex-grow: 1;
		}

		.normal {
			border: 1px solid #ccc;
		}

		.sel {
			border: 1px solid #00aaff;
		}

		.zujian-list {
			margin: 0;
			float: left;
			width: 100px;
			height: calc(100vh - 300px);
			overflow-y: auto;

			.item {
				width: 60px;
				height: 40px;
				overflow: hidden;
				margin: 2px;
				position: relative;

				.label {
					color: #fff;
					font-size: 10px;
					border-radius: 5px;
					background-color: #1b8bff;
					position: absolute;
					left: 0;
					padding: 2px;
				}

				.labelimage {
					background-color: #00aaff;
				}

				.labelbutton {
					background-color: #55aa7f;
				}

				.labeltext {
					background-color: #ffaaff;
				}

				.el-icon-close {
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;
				}

				.el-icon-plus {
					position: absolute;
					right: 0px;
					bottom: 0;
					cursor: pointer;
				}

				img {
					width: 100%;
				}
			}
		}

		.line {
			border-bottom: 1px dashed #ccc;
			margin-bottom: 10px;
			width: 100%;
		}

		.list_style {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			justify-content: start;
			margin: 10px auto;

			.item_style {
				cursor: pointer;
				height: 60px;
				width: 120px;

				border-radius: 5px;
				margin: 5px;
				text-align: center;

				img {
					width: 100%;
					height: 100%;
					border-radius: 5px;
				}
			}

			.sel {
				border: 2px solid #7FC0F6;
			}

			.normal {
				border: 1px solid #ccc;
			}
		}


		.list {
			// display: flex;
			// align-items: center;
			// justify-content: space-between;
			margin: 5px auto;

			.title {
				flex-shrink: 0;
				margin-bottom: 5px;
			}

			.full {
				flex-grow: 1;
			}

			.bgimg {
				width: 100%;
				height: 100px;
				overflow-y: hidden;

				img {
					width: 100%;
					height: auto;
				}
			}

			.btn {
				display: flex;
				align-items: center;

				.reset {
					margin-right: 10px;
				}
			}

			.flex-row {
				display: flex;
				flex-direction: row;
				align-items: center;
			}

			.blod {
				cursor: pointer;
				margin-right: 5px;
				width: 32px;
				height: 30px;
				border-radius: 4px;
				background-color: #DEDFE1;
				display: flex;
				align-items: center;
				justify-content: center;

				img {
					width: 20px;
					height: 20px;
					border-radius: 4px;
				}
			}
		}
	}

	.line {
		margin: 10px 0;
		width: 100%;
		height: 1px;
		background-color: #f0f0f0;
	}

	.disp {
		width: 100%;
		display: flex;

		align-items: center;

	}

	/deep/ .el-color-picker__trigger {
		border: none !important;
	}
</style>