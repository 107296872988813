<template>
	<div class="pages">
		<div class="box">
			<div class="left">
				<div class="logo">
					<div class="img">
						<img :src="tempInfo.logo" />
					</div>
					<div class="price">
						价格：￥{{tempInfo.pricesale}}
					</div>
					<div class="">
						{{tempInfo.tmpname}}
					</div>
				</div>
			</div>
			<div class="right">
				<p>模板介绍：<span v-html="tempInfo.memo"></span></p>
				<div class="shop">
					<el-button size="small" type="primary" @click="submit">立即使用</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import designTemplataApi from "../../../api/cus/designtmplate.js";
	export default {
		data() {
			return {
				sysid: "",
				tempInfo: {}
			}
		},
		mounted() {
			let sysid = this.$route.query.sysid
			if (sysid != undefined) {
				this.sysid = sysid
				this.init()
			}
		},
		methods: {
			init() {
				let param = {
					sysid: this.sysid
				}
				designTemplataApi.mytempDetail(param, res => {
					console.log(res, 'res.mytempDetail');
					if (res.code == 200) {
						this.tempInfo = res.data.info
					}
				})
			},
			submit() {
				let param = {
					sys_tempid: this.tempInfo.tmpid
				}
				designTemplataApi.usemb(param, res => {
					console.log(res, 'usemb.res1203');
					if (res.code == 200) {
						this.$router.push({
							path: "/customer/xcxtemplate/index",
						});
						this.$message({
							type: "success",
							message: '模版使用成功'
						})

					}
				})
			}
		}
	}
</script>

<style scoped>
	.pages {
		padding: 10px;
		width: 1200px;
		margin: 0 auto;
	}

	.pages .box {
		display: flex;
	}

	.pages .box .left {
		width: 350px;
	}

	.pages .box .left .logo {
		overflow: hidden;
		cursor: pointer;
		padding: 20px;
		border-radius: 20px;
		box-shadow: 0 2px 6px #ccc;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.pages .box .left .img {
		width: 300px;
		height: auto;
	}

	.pages .box .left .img img {
		width: 100%;
		height: 100%;
	}

	.pages .box .right {
		padding-left: 20px;
		flex-grow: 1;
		position: relative;
	}

	.pages .box .right .shop {
		position: absolute;
		bottom: 0;
		right: 0;
	}
</style>