<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="18"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>链接</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>获取链接</span>
					</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
						<el-form-item label="小程序标题" prop="title">
							<el-input v-model="ruleForm.page_title" placeholder="请输入小程序标题" size="medium"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="小程序路径(带参)" prop="title">
							<el-input v-model="ruleForm.page_url" placeholder="请输入小程序路径(带参数)" size="medium"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="是否永久有效" prop="is_permanent">
							<el-switch v-model="ruleForm.is_permanent" active-color="#13ce66" inactive-color="#bababa">
							</el-switch>
						</el-form-item>
						<el-form-item label="" prop="is_permanent">
							<div class="btnbox">
								<el-button type="primary" @click="addlink" style="width: 200px;" size="medium">生成</el-button>
							</div>
						</el-form-item>
					</el-form>
				</el-card>
			</div>
		</div>
	</div> 


</template>

<script>
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import linkApi from '../../../../../../api/cus/wxapp.js'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				ruleForm:{
					is_permanent:false,
					mpid:'',
					page_title:'',
					page_url:''
				},
				rules: {},
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.ruleForm.mpid = mpid
			} else {
				this.$router.push("/");
			}

		},
		methods: {
				addlink(){
					let param = {
						is_permanent:this.ruleForm.is_permanent == true ? 1 : 0,
						mpid:this.ruleForm.mpid,
						page_title:this.ruleForm.page_title,
						page_url:this.ruleForm.page_url
					}
					console.log(param,'param');
					linkApi.creatshortlink(param,res =>{
						console.log(res,'addlink.res');
						if(res.code == 200){
							
						}else{
							this.$message({
								message:res.msg,
								type:'error'
							})
						}
					})
				}
		}
	}
</script>

<style scoped>
	.page {
		background: #F5F7FA;
	}

	.box-card {
		margin-top: 20px;
	}

	.main .item {
		margin-bottom: 10px;
		font-weight: 700;
		font-size: 16px;
		padding: 18px 0;
		display: flex;
		align-items: center;
	}

	.main .item .btn {
		padding: 5px 10px;
		color: #3388FF;
		border: 1px solid #3388FF;
		font-weight: 500;
	}

	.border {
		border-bottom: 1px solid #E2E2E2;
	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
	}

	.pagecontent {


		height: calc(100vh - 81px);
	}

	.w200 {
		width: 200px;
	}

	.value {
		color: #777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow: 1;
	}
	.btnbox{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 30px;
	}
</style>