var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img"},[_c('div',{staticClass:"imgcont",style:({
         'margin-left':_vm.marginpage,
          'margin-right':_vm.marginpage,
		  'width' : _vm.width,
		  'height' : _vm.height
      })},[(_vm.list.options && _vm.list.options.bgimg && _vm.list.options.bgimg.length > 0)?_c('div',{staticClass:"bgbox"},[_c('img',{staticClass:"bgimg",attrs:{"src":(_vm.list.options.bgimg && _vm.list.options.bgimg.length > 0) ? _vm.list.options.bgimg : ''}})]):_vm._e(),_c('div',{staticClass:"list"},_vm._l((_vm.list.data),function(item,index){return _c('dragBox',{key:item.cid,attrs:{"data":item,"index":index},on:{"deleteHandle":_vm.deleteHandle}},[_c('div',{staticClass:"item",style:({
              height:(item.height - 2) + 'px',
              width:(item.width -2) + 'px',
              top:item.top + 'px',
              left:item.left + 'px',
             })},[_vm._v(" "+_vm._s(item.title)+" ")])])}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }