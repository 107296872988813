<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="11"></leftnav>
			<div class="rightbox" >
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>总览</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>企业微信开发信息</span>
					</div>
					<div class="main">
						<div class="item ">
								<div class="w200">企业ID</div>
								<div class="value">{{appinfo.mpgid}}</div>
						</div> 
						<div class="item ">
								<div class="w200">配置编号</div>
								<div class="value">{{mpid}}</div>
						</div>
					</div>
					
				</el-card>
				<el-card class="box-card" v-if="appinfo.isOpen == 1">
					<div slot="header" class="clearfix">
						<span>企业微信第三方应用</span>
					</div>
					<div class="main">
						<div class="item" v-if="error">
							<div class="w200" style="color: red;">错误{{ error }}</div>
						</div>
						<div class="item" v-else>
							<div class="w200">消息提示</div>
							<div  class="value">{{ auth_state == 0 ? '未授权':(auth_state == 1 ?'已授权':'未知状态') }}</div>
							<a :href="authurl" target="_blank"><div >{{ auth_state == 1 ? '重新授权':'企业微信授权' }}</div></a>
						</div>
						
					</div>
				</el-card>
				<el-card class="box-card" v-else>
					<div slot="header" class="clearfix">
						<span>企业微信自建应用配置</span>
					</div>
					<div class="main">
						<div class="item border">
								<div class="w200">开发者ID(AppID)</div>
								<div class="value">{{appinfo.appID}}</div>
						</div>
						<div class="item">
								<div class="w200">开发者密码(AppSecret)</div>
								<div class="value">{{appinfo.appSecret}}</div>
						</div>
						<div class="item border">
							<div class="w200 ">服务器地址(URL)</div>
							<div class="value">{{appinfo.postURL}}</div>
						</div>
						<div class="item border" >
							<div class="w200">令牌(Token)</div>
							<div class="value">{{appinfo.token}}</div>
						</div>
						
						<div class="item border">
							<div class="w200">消息加解密密钥(EncodingAESKey)</div>
							<div class="value">{{appinfo.aesKey}}</div>
						</div>
						<div class="item">
							<div class="w200">消息加解密方式：明文模式</div>
							<div class="value">{{appinfo.token}}</div>
							
						</div>
						
					</div>
				</el-card>
			</div>
			
		</div>
	</div>
</template>

<script>
	import accountApi from '../../../../../../api/cus/account'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import {
		getloginuser,
		delloginall
	} from '../../../../../../utils/util.js'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				appinfo: {},
				authurl:'',
				auth_state:0,
				error:""
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			if (mpid != undefined) {
			} else {
				this.$router.push("/");
			}
			accountApi.info({
				mpid: this.mpid
			}, res => {
				if(res.code == 200){
					console.log(res, 'res.info');
					this.appinfo = res.data
					this.getauth()
				}else{
					
					delloginall()
					this.$router.push({
						path:'/customer/login'
					})
				}
				
			})
		},
		methods: {
			getauth(){
				accountApi.qywxauthurl(this.mpid, res => {
					console.log(res, 'res.auth');
					if(res.code == 200){
						this.authurl = res.data.auth_url
						this.auth_state = res.data.auth_state
					}else{
						this.error = "授权获取失败," + res.msg
					}
				})
			}
		}
	}
</script>

<style scoped>
	.box-card {
		margin-bottom: 10px;
	}

	.main .item {
		margin-bottom: 10px;
	}
	.page{
		background:#F5F7FA;
	}
	.box-card {
		margin-top: 20px;
	}
	
	.main .item {
		margin-bottom: 10px;
		font-weight: 700;
		font-size: 16px;
		padding:18px 0;
		display: flex;
		align-items: center;
	}
	.main .item  .btn{
		padding:5px 10px;
		color:#3388FF;
		border:1px solid #3388FF;
		font-weight: 500;
	}
	.border{
		border-bottom: 1px solid #E2E2E2;
	}
	.rightbox{
		padding-top:20px;
		margin: 0 auto;
		width: 1320px;
	}
	.pagecontent{
		display: flex;
		overflow: auto;
		height: calc(100vh - 81px);
	}
	.w200{
		width:200px;
	}
	.value{
		color:#777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow:1;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
			
		}
	}
</style>