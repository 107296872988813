<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="24"></leftnav>
			<div class="rightbox" style="margin-left:130px;">

				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>入群欢迎语{{ruleForm.id.length > 0 ? '编辑' : '新增'}}</span>
					</div>
					<div class="typebox" v-if="ruleForm.msgtype.length <= 0">
						<div class="typelist">
							<div class="titem" @click="funseltype('text')"
								:class="ruleForm.msgtype == 'text' ? 'sel':''">文字</div>
							<div class="titem" @click="funseltype('image')"
								:class="ruleForm.msgtype == 'image' ? 'sel':''">图片</div>
							<div class="titem" @click="funseltype('link')"
								:class="ruleForm.msgtype == 'link' ? 'sel':''">链接</div>
							<div class="titem" @click="funseltype('miniprogram')"
								:class="ruleForm.msgtype == 'miniprogram' ? 'sel':''">小程序</div>
								
						</div>
						<div style="width: 50%;margin: 20px auto;">请选择消息类型</div>
					</div>

					<!-- <el-radio-group v-model="ruleForm.msgtype" :disabled="ruleForm.id.length > 0 ? true : false">
						<el-radio label="image">图片</el-radio>
						<el-radio label="link">链接</el-radio>
						<el-radio label="miniprogram">小程序</el-radio>
					</el-radio-group> -->
					<div v-if="ruleForm.msgtype != undefined && ruleForm.msgtype.length > 0">
						<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px"
							class="demo-ruleForm">
							<el-form-item label="消息类型" prop="msgtype">
								{{ruleForm.msgtype == 'image' ? '文字':""}}{{ruleForm.msgtype == 'image' ? '图片':""}}{{ruleForm.msgtype == 'link' ? '链接':""}}{{ruleForm.msgtype == 'miniprogram' ? '小程序':""}}
							</el-form-item>
							<el-form-item label="模板标题" prop="title">
								<el-input v-model="ruleForm.template_title" placeholder="请输入模板标题" size="medium"
									style="width: 300px;"></el-input>
							</el-form-item>
							<el-form-item label="欢迎语内容" prop="title">
								<el-input type="textarea" v-model="ruleForm.content"
									placeholder="持配置多个%NICKNAME%(大小写敏感)形式的欢迎语，当配置了欢迎语占位符后，发送给客户时会自动替换为客户的昵称" :rows="7"
									style="width: 500px;"></el-input>
							</el-form-item>
							<el-form-item label="" prop="title">
								<div>持配置多个%NICKNAME%(大小写敏感)形式的欢迎语，当配置了欢迎语占位符后，发送给客户时会自动替换为客户的昵称</div>
							</el-form-item>
							
							<div v-if=" ruleForm.msgtype == 'link'">
								<el-form-item label="链接标题" prop="title">
									<el-input v-model="ruleForm.title" placeholder="请输入标题" size="medium"
										style="width: 300px;"></el-input>
								</el-form-item>


								<el-form-item label="链接网址" prop="title">
									<el-input v-model="ruleForm.linkurl" placeholder="请输入链接网址https://或http://开头"
										size="medium" style="width: 300px;"></el-input>
								</el-form-item>
								<el-form-item label="描述信息" prop="title">
									<el-input type="textarea" :rows="7" v-model="ruleForm.linkdesc"
										placeholder="请输入描述信息" size="medium" style="width: 500px;"></el-input>
								</el-form-item>
								<el-form-item label="欢迎语图片" prop="title">
									<div v-if="ruleForm.logo != undefined && ruleForm.logo.length > 0">
										<img :src="ruleForm.logo" style="width: 100px;" />
										<el-button style="margin-left: 20px;" type="primary"
											@click="fundelimg">重新上传</el-button>
									</div>

									<el-upload class="upload-demo" ref="upload" drag :action="uploadappurl"
										:on-success="successfile" :headers="headers" :data="importData" :limit="1"
										multiple style="margin-top: 20px;" v-else>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
										<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
									</el-upload>

								</el-form-item>
							</div>

							<div label="" prop="title" v-if="ruleForm.msgtype == 'miniprogram'">
								<el-form-item label="是否送券" prop="isticket" >
									<el-switch v-model="ruleForm.isticket" active-color="#13ce66" inactive-color="#bababa">
									</el-switch>
								</el-form-item>
								<el-form-item label="选择礼券" prop="isticket" v-if="ruleForm.isticket == 1">
									<el-button @click="funselticket">选择礼券</el-button>
								</el-form-item>
								<el-form-item label="" prop="isticket"
									v-if=" ruleForm.isticket == 1 && ruleForm.ticketjson.id != undefined && ruleForm.ticketjson.id.length > 0 ">
									<div class="ticket">
										<img class="timg" :src="ruleForm.ticketjson.logo" />
										<div class="right">
											<div class="title">{{ruleForm.ticketjson.title}}</div>
											<div>{{ruleForm.ticketjson.title2}}</div>
											<div class="memo">{{ruleForm.ticketjson.memo}}</div>
										</div>
									</div>
								</el-form-item>
								<el-form-item label="" prop="isticket" v-if=" ruleForm.isticket == 1">
									
									<img v-if="ruleForm.adlogo != undefined && ruleForm.adlogo.length > 0" :src="ruleForm.adlogo" style="width: 100px;margin-right: 20px;" />
									<el-button  type="primary" @click="funupadlogo">上传礼券图片</el-button>
									
									
								</el-form-item>
								<el-form-item label="小程序APPID" prop="title">
									<el-input v-model="ruleForm.appid" placeholder="请输入APPID" size="medium"
										style="width: 300px;"></el-input>

								</el-form-item>
								<el-form-item label="" prop="title">
									<div>小程序appid，必须是关联到企业的小程序应用</div>
								</el-form-item>

								<el-form-item label="小程序标题" prop="title">
									<el-input v-model="ruleForm.title" placeholder="请输入标题" size="medium"
										style="width: 300px;"></el-input>
								</el-form-item>
								<el-form-item label="跳转页面" prop="title">
									<el-select v-model="ruleForm.apppage" size="medium" placeholder="请选择">
										<el-option v-for="item in topagetype" :key="item.id" :label="item.mc"
											:value="item.id">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="小程序类型" prop="title">
									<el-select v-model="ruleForm.apptype" size="medium" placeholder="请选择">
										<el-option v-for="item in appoption" :key="item.value" :label="item.label"
											:value="item.value">
										</el-option>
									</el-select>
								</el-form-item>
								<el-form-item label="小程序路径" prop="title">
									<el-input v-model="ruleForm.apppath" placeholder="请输入小程序路径" size="medium"
										style="width: 300px;"></el-input>
								</el-form-item>
								<el-form-item label="小程序封面" prop="title">
									<div v-if="ruleForm.logo != undefined && ruleForm.logo.length > 0">
										<img :src="ruleForm.logo" style="width: 100px;" />
										<el-button style="margin-left: 20px;" type="primary"
											@click="fundelimg">重新上传</el-button>
									</div>
									<el-upload class="upload-demo" ref="upload" drag :action="uploadappurl"
										:on-success="successfile" :headers="headers" :data="importData" :limit="1"
										multiple style="margin-top: 20px;" v-else>
										<i class="el-icon-upload"></i>
										<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
										<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
									</el-upload>
								</el-form-item>
							</div>
							<el-form-item label="欢迎语图片" prop="title" v-if="ruleForm.msgtype == 'image'">
								<div v-if="ruleForm.logo != undefined && ruleForm.logo.length > 0">
									<img :src="ruleForm.logo" style="width: 100px;" />
									<el-button type="primary" style="margin-left: 20px;"
										@click="fundelimg">重新上传</el-button>
								</div>

								<el-upload class="upload-demo" ref="upload" drag :action="uploadappurl"
									:on-success="successfile" :headers="headers" :data="importData" :limit="1" multiple
									style="margin-top: 20px;" v-else>
									<i class="el-icon-upload"></i>
									<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
									<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>

								</el-upload>

							</el-form-item>


							<el-form-item>
								<div>
									<div class="btnbox">
										<el-button style="width: 180px;" type="primary" size="medium"
											@click="submitForm('ruleForm')">保存配置</el-button>
										<el-button style="width: 160px" size="medium" @click="funback">返回</el-button>
									</div>

								</div>

							</el-form-item>
						</el-form>
					</div>
				</el-card>




			</div>
		</div>
		<ticket ref="ticket" @ticketresult="ticketresult"></ticket>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import selaffix from "@/components/customer/selaffix";
	import personApi from '../../../../../../api/cus/person.js';
	import groupApi from '../../../../../../api/cus/group.js'
	import qrcodeApi from '../../../../../../api/cus/qrcode.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import welcomApi from '../../../../../../api/cus/welcome.js'
	import ticket from '../../../../../../components/customer/selticket.vue'
	import selImgs from '../../../../../../components/customer/selImgs.vue'
	import {
		getloginuser
	} from "../../../../../../utils/util.js";
	export default {
		components: {
			selaffix,
			leftnav,
			navcon,
			ticket,
			selImgs

		},
		data() {
			return {
				mpid: "",
				mxid: "",
				manData: [],
				personData: [],
				groupData: [],
				groupsData: [],
				ruleForm: {
					"appid": "",
					"apppage": "",
					"apppath": "",
					"isticket": 0,
					"linkdesc": "",
					"linkurl": "",
					"logo": "",
					"media_resid": "",
					"media_url": "",
					"mpid": 0,
					"ticketjson": {
						title: '',
						title2: '',
						logo: '',
						id: '',
						num: 1,
						memo: ''
					},
					"content": "",
					"id": "",
					"msgtype": "",
					"template_title": "",
					"title": "",
					"apptype": '',
					"adlogo":''
				},
				headers: {
					Authorization: ''
				},
				uploadappurl: '',
				importData: {
					name: '图片',
					mpid: 0
				},
				isedit: false,
				groupVisible: false,
				rules: {},
				shopid: '',
				appoption: [{
					value: 'dxjbclub',
					label: '大新酒吧点单'
				}, {
					value: 'yyjbclub',
					label: '夜莺酒吧点单'
				}, {
					value: 'diy',
					label: '自定义'
				}],
				topagetype: [{
						id: 'index',
						mc: '首页'
					},
					{
						id: 'diandan',
						mc: '点单'
					}
				]
			};
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				let id = this.$route.query.id
				let shopid = this.$route.query.shopid
				console.log(shopid, 'init.shopid');
				console.log(id, 'init.id');
				this.uploadappurl = '/api/customer/file/weixinwork/uploadimgtmp'
				this.uploadlogourl = '/api/customer/file/weixinwork/uploadimg'
				this.importData.mpid = this.$route.query.mpid
				let u = getloginuser()
				if (u != null) {
					this.headers.Authorization = 'Bearer ' + u.token
				}

				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				if (shopid != undefined) {
					this.shopid = shopid
				}
				if (id != undefined) {
					this.ruleForm.id = id
					this.getdetail()
				}


			},

			getdetail() {
				welcomApi.detail(this.ruleForm.id, res => {
					console.log(res, 'resdetail');
					if (res.code == 200) {
						this.ruleForm.appid = res.data.appid
						this.ruleForm.apppage = res.data.apppage
						this.ruleForm.apppath = res.data.apppath
						this.ruleForm.isticket = res.data.isticket == 1 ? true : false
						this.ruleForm.linkdesc = res.data.linkdesc
						this.ruleForm.linkurl = res.data.linkurl
						this.ruleForm.logo = res.data.logo
						this.ruleForm.media_resid = res.data.media_resid
						this.ruleForm.media_url = res.data.media_url
						this.ruleForm.mpid = res.data.mpid
						this.ruleForm.ticketjson = res.data.ticketjson
						this.ruleForm.content = res.data.content
						this.ruleForm.id = res.data.id
						this.ruleForm.msgtype = res.data.msgtype
						this.ruleForm.template_title = res.data.template_title
						this.ruleForm.title = res.data.title
						this.ruleForm.adlogo = res.data.adlogo
						if (res.data.isticket == 1) {
							this.ruleForm.ticketjson = JSON.parse(res.data.ticketjson)

						}

					} else if (res.code == 201) {

					} else {
						this.$message({
							message: res.msg,
							type: 'success'
						})
					}
				})
			},
			funseltype(t) {
				this.ruleForm.msgtype = t
				switch (t) {
					case 'image':
					case 'link':
						this.uploadappurl = '/api/customer/file/weixinwork/uploadimg'
						break;
					case 'miniprogram':
						this.uploadappurl = '/api/customer/file/weixinwork/uploadimgtmp'
						break;
				}

			},
			submitForm(formName) {
				let user = getloginuser()
				let manjson = [];
				if (this.ruleForm.isticket == 1) {
					if (this.ruleForm.ticketjson.id.length <= 0) {
						this.$message({
							message: '请选择礼券或关闭礼券选项',
							type: 'error'
						})
						return
					}
				}
				console.log(this.manData, this.ruleForm.manjson, 'sub');
				manjson = this.personData
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let param = {
							"appid": this.ruleForm.appid,
							"apppage": this.ruleForm.apppage,
							"apppath": this.ruleForm.apppath,
							"apptype": this.ruleForm.apptype,
							"isticket": this.ruleForm.isticket == true ? 1 : 0,
							"linkdesc": this.ruleForm.linkdesc,
							"linkurl": this.ruleForm.linkurl,
							"logo": this.ruleForm.logo,
							"media_resid": this.ruleForm.media_resid,
							"media_url": this.ruleForm.media_url,
							"mpid": this.mpid,
							"ticketjson": this.ruleForm.ticketjson,
							"content": this.ruleForm.content,
							"id": this.ruleForm.id,
							"msgtype": this.ruleForm.msgtype,
							"template_title": this.ruleForm.template_title,
							"title": this.ruleForm.title,
							"shopid": this.shopid,
							"adlogo":this.ruleForm.adlogo
						}
						console.log(param, 'param');
						welcomApi.add(param, res => {
							console.log(res, 'submit.res');
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.$confirm('保存成功，是否返回主页面?', '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								}).then(() => {
									this.$router.push({
										path: '/customer/home/mp/weixinqiye/welcome/index?mpid=' +
											this.mpid + '&showshop=false',
									})
								}).catch(() => {});
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			funback() {
				this.$router.push({
					path: '/customer/home/mp/weixinqiye/welcome/index?mpid=' + this.mpid + '&showshop=false',
				})
			},
			fundelimg() {
				this.ruleForm.media_resid = ''
				this.ruleForm.media_url = ''
				this.ruleForm.logo = ''
			},
			funselticket() {
				let param = {
					mpid: this.mpid,
					shopid: this.shopid
				}
				this.$refs['ticket'].funshow(param)
			},
			ticketresult(res) {
				console.log(res, 'selticket.res');
				if (res != undefined) {
					this.ruleForm.ticketjson = {
						title: res.item.title,
						title2: res.item.title2,
						logo: res.item.logo,
						id: res.item.billno,
						num: 1,
						memo: res.item.memo
					}
				}
			},
			funupadlogo(){
				this.$refs["selImgs"].funShow()
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.ruleForm.adlogo = imgitem.url;
			},
			successfile(res, file, fileList) {
				console.log("successfile==>", res, file, fileList);
				if (res.code == 200) {
					this.$message({
						type: 'success',
						message: "上传成功"
					})
					// 提交保存成功后清空已上传的文件
					this.$refs.upload.clearFiles();
					this.ruleForm.media_url = res.data.media_url
					this.ruleForm.logo = res.data.link_url
					this.ruleForm.media_resid = res.data.res_id
				} else {
					this.$message({
						type: 'error',
						message: res.msg + "上传失败"
					})
				}


			},

		}
	}
</script>

<style scoped>
	.page {
		background: #F5F7FA;

	}

	.title-tips {
		padding-left: 28px;
		font-weight: 650;
		font-style: normal;
		color: #f59a23;
		font-size: 14px;
	}

	.addaffix {
		cursor: pointer;
		padding-left: 10px;
		border: 1px solid #DCDFE6;
		border-top: 0px solid #DCDFE6;
	}


	.list {
		display: flex;
		flex-direction: column;
	}

	.list-item {
		position: relative;
	}

	.del {
		position: absolute;
		right: 10px;
		top: 0px;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);
	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
		overflow: auto;
	}

	.btnbox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.qwbgimg {
		max-width: 100px;
		height: auto;
	}

	.typebox {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		margin: 50px auto;
	}

	.typelist {
		display: flex;
		flex-direction: row;
		width: 50%;
		margin: 0 auto;

	}

	.typelist .titem {
		width: 150px;
		height: 75px;
		border-radius: 10px;
		border: 1px solid #e0e0e0;
		line-height: 75px;
		text-align: center;
		margin-right: 20px;
	}

	.typelist .titem:hover {
		border: 1px solid #66b1ff;
		background-color: #66b1ff;
		color: #ffffff;
	}

	.sel {
		width: 100px;
		height: 50px;
		border-radius: 10px;
		border: 1px solid #66b1ff;
		background-color: #66b1ff;
		color: #ffffff;
		line-height: 50px;
		text-align: center;
		margin-right: 20px;
	}

	.ticket {
		width: 400px;
		border-radius: 10px;
		border: 1px solid #e0e0e0;
		display: flex;
		flex-direction: row;
		align-items: center;
		overflow: hidden;
	}

	.ticket .timg {
		width: 80px;
		height: 80px;
		margin-right: 10px;
	}

	.ticket .right {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
	}

	.ticket .right .title {
		font-size: 16px;
		font-weight: 600;
	}

	.ticket .right .memo {
		font-size: 9px;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}
</style>