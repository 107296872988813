var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu_img_title_page"},[_c('div',{staticClass:"menu_img_title"},[_c('div',{staticClass:"imgcont",style:({
         'margin-left':_vm.marginpage,
          'margin-right':_vm.marginpage,
					'margin-top':_vm.margintop,
          'margin-bottom':_vm.marginbottom,
          'padding-top':_vm.paddingtop,
          'padding-bottom':_vm.paddingbottom,
					'width' : _vm.width && _vm.width == 0 ? '100%' : _vm.width,
					'height' : _vm.height,
					'border-radius':_vm.borderradius,
					'background':_vm.list.options && _vm.list.options.stylestate && _vm.list.options.stylestate == 'T' ? _vm.styleview.text2_bg_color:_vm.bgcolor
					})},[_vm._l((_vm.list.data),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
						'border':'50px',
						'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
					}),attrs:{"id":'a'+index},on:{"click":function($event){return _vm.funItem(index)}}},[(_vm.selIndex == index)?_c('div',{style:({'background':_vm.styleview.subject1_bg_color,'border-radius':'50%'})},[_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.data && _vm.list.data.length > 0),expression:"list.data && list.data.length > 0"}],staticStyle:{"display":"block"},style:({
							'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
						}),attrs:{"src":item.url2}})],1):_c('div',[_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.data && _vm.list.data.length > 0),expression:"list.data && list.data.length > 0"}],staticStyle:{"display":"block"},style:({
							'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
						}),attrs:{"src":item.url}})],1),_c('div',{staticClass:"title",style:({
							'margin-top':item.margintop + 'px',
							'color':_vm.list.options && _vm.list.options.stylestate && _vm.list.options.stylestate == 'T' ?  _vm.styleview.text2_txt_color: _vm.list.options.titlecolor,
							fontSize: _vm.size,
          fontWeight: _vm.bold,
						})},[_vm._v(" "+_vm._s(item.title)+" ")])])}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.list.data && _vm.list.data.length == 0),expression:"list.data && list.data.length == 0"}],staticClass:"empty"},[_c('el-empty',{attrs:{"description":"此区域放置图片"}})],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }