<template>
	<!-- 设置背景样式 -->
	<div class="wenben" :style="{'margin-left':  list.options.paddingpage + 'px',
        'margin-right':  list.options.paddingpage + 'px',background: list.options.bgcolor,
          'height':height,'width': width,'border-radius':list.options.borderradius+'px'}">
		<div class="cont" >
			<div class="box" :style="{'padding-top':margintop,
			  'padding-bottom':marginbottom,
			  'height':height}">
				<div class="input-wrap" :style="{'background': list.options.inputbg,'border':`1px solid ${list.options.bordercolor}`}">
					<input type="text" :style="{'background': list.options.inputbg,'color':list.options.titlecolor,'font-weight':bold,fontSize: size,}" />
				</div>
				<div class="ewm-wrap" v-if="list.options.ewmimg&&list.options.ewmimg.length > 0">
					<img :src="list.options.ewmimg " style="width: 100%;" />
				</div>
			</div>
			<div class="bgimg" v-if="list.options.bgimg&&list.options.bgimg.length > 0">
				<img :src="list.options.bgimg " style="width: 100%;" />
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "image_qunqr",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
			};
		},

		created() {
			this.list = this.data;
		},
		computed: {
			//  标题字体大小
			size() {
				if (this.list.options.size == 3) {
					return "12px";
				} else if (this.list.options.size == 2) {
					return "14px";
				} else {
					return "16px";
				}
			},
			// 标题显示位置
			align() {
				if (this.list.options.position == 1) {
					return "left";
				} else if (this.list.options.position == 2) {
					return "center";
				}
			},
			width(){
				if (this.list.options) {
					let w = 750/2-this.list.options.paddingpage *2
					return w + 'px'
				}
			},
			height() {
				if (this.list.options) {
					if (this.list.options.height && this.list.options.height > 0)
						return this.list.options.height + 'px';
				}
			},
			// 描述文字大小
			describesize() {
				if (this.list.options.describe == 3) {
					return "12px";
				} else if (this.list.options.describe == 2) {
					return "14px";
				} else {
					return "12px";
				}
			},
			// 标题加粗
			bold() {
				if (this.list.options.bold == 2) {
					return "900";
				} else {
					return "300";
				}
			},
			// 底部分割线
			border() {
				if (this.list.options.border) {
					return "1px solid #cccccc";
				} else {
					return "";
				}
			},
			margintop() {
				
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + 'px';
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
						return this.list.options.marginbottom + 'px';
				}
			},
			paddingtop() {
				if (this.list.options) {
					if (this.list.options.paddingtop && this.list.options.paddingtop > 0)
						return this.list.options.paddingtop + 'px';
				}
			},
			paddingbottom() {
				if (this.list.options) {
					if (this.list.options.paddingbottom && this.list.options.paddingbottom > 0)
						return this.list.options.paddingbottom + 'px';
				}
			},
			paddingpage() {
				if (this.list.options) {
					if (this.list.options.paddingpage && this.list.options.paddingpage > 0)
						return this.list.options.paddingpage + 'px';
				}
			},
		},
		methods: {},
	};
</script>

<style lang="less" scoped>
	.wenben {
		overflow: hidden;

		box-sizing: border-box;
		position: relative;
	}
	.cont{
		overflow: hidden;
	}
	.box {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 2;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}

	.box .input-wrap {
		color: #737373;
		border-radius: 5px;
		display: flex;
		padding: 10px;
	}

	.box .input-wrap .city {
		padding-right: 10px;
		border-right: 1px solid #737373;
	}

	.box .input-wrap input {
		margin-left: 5px;
		outline: none;
		border: none;
	}

	.box .ewm-wrap {
		margin-top: 10px;
		width: 150px;
		height: 150px;
		border-radius: 10px;
		overflow: hidden;
	}

	.wenben .bgimg {
		width: 100%;
	}

	.box .bgimg img {
		width: 100%;
		height: 100%;
	}


	.box .ewm-wrap img {
		width: 100%;
		height: 100%;
	}
</style>