var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"swiper",style:({ 
   'height':_vm.height,
  'margin-left':  _vm.list.options.marginpage + 'px',
      'margin-right':  _vm.list.options.marginpage + 'px', 
      'margin-top':  _vm.list.options.margintop + 'px',
      'margin-bottom':  _vm.list.options.marginbottom + 'px', 

      'border-radius':_vm.chamfer })},[(_vm.data.data.length == 0)?_c('el-empty',{style:({
      'height':_vm.height
    }),attrs:{"image-size":100,"description":"点击编辑轮播图"}}):_c('el-carousel',{staticClass:"contain",attrs:{"height":_vm.height}},_vm._l((_vm.data.data),function(item,index){return _c('el-carousel-item',{key:index},[_c('el-image',{staticStyle:{"width":"100%"},attrs:{"fit":_vm.fit,"src":item.url}})],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }