<template>
	<div class="content">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="13"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<div class="title">提交审核</div>
				<div class="contbox">
					<div class="modelist">
						<div class="modeitem" :class="item.template_id == templateid ? 'active' : 'normal'"
							v-for="(item,index) in list" @click="funseltmp(item,index)" :key="index">
							<div>{{item.version}}</div>
							<div>{{item.memo}}</div>
							<div>{{item.template_name}}</div>
						</div>
					</div>
					<div class="iptitem">
						<div class="ward">企业号</div>
						<el-input size="medium" placeholder="请输入版本号" v-model="ext_json.mchno"></el-input>
					</div>
					<div class="iptitem">
						<div class="ward">企业名称</div>
						<el-input size="medium" placeholder="请输入版本号" v-model="ext_json.mchname"></el-input>
					</div>
					<div class="iptitem">
						<div class="ward">门店编号</div>
						<el-input size="medium" placeholder="请输入版本号" v-model="ext_json.shopid"></el-input>
					</div>
					<div class="iptitem">
						<div class="ward">门店名称</div>
						<el-input size="medium" placeholder="请输入版本号" v-model="ext_json.shopname"></el-input>
					</div>
					<div class="iptitem">
						<div class="ward">版本编号</div>
						<el-input size="medium" placeholder="请输入版本号" v-model="user_version"></el-input>
					</div>
					<div class="iptitem">
						<div class="ward">版本描述</div>
						<el-input :rows="10" type="textarea" placeholder="请输入内容" v-model="user_desc"></el-input>
					</div>

				</div>
				<div class="btnbox">
					<el-button size="medium" type="primary" @click="funsave">提交并生成体验版</el-button>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
	import accountApi from '../../../../../../api/cus/account'
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				list: [],
				templateid: '',
				ext_json: {
					mchno: '',
					mchname: '',
					shopid: '',
					shopname: ''
				},
				user_desc: '',
				user_version: ''
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			if (mpid != undefined) {} else {
				this.$router.push("/");
			}
			this.getlist()

		},
		methods: {
			getlist() {
				accountApi.tempaltelist(this.mpid, res => {
					console.log(res, 'res.getlist');
					if (res.code == 200) {
						let list = []
						res.data.forEach(item => {
							list.push({
								template_id: item.template_id,
								template_name: item.template_name,
								sysid: item.sysid,
								version: item.version,
								memo: item.memo,
								issel: false
							})
						})
						this.list = list

					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			funseltmp(item) {
				item.issel = !item.issel
				this.templateid = item.template_id
			},
			funsave() {
				let param = {
					mchno: this.ext_json.mchno,
					mchname: this.ext_json.mchname,
					shopid: this.ext_json.shopid,
					shopname: this.ext_json.shopname,
					user_desc: this.user_desc,
					user_version: this.user_version,
					template_id: this.templateid,
					mpid: this.mpid
				}
				accountApi.committest(param, res => {
					console.log(res, 'res.funsave');
					if (res.code == 200) {
						if (res.data.errcode == 0) {
							this.$message({
								message: '提交成功',
								type: 'success'
							})
						} else {
							this.$message({
								message: res.data.errmsg,
								type: 'error'
							})
						}

					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})

			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 100%;
		background-color: #F8F9FB;
		overflow: auto;
	}

	.content .title {
		font-size: 16px;
		font-weight: 700;
		padding: 20px;
	}

	.content .contbox {
		width: 80%;
		margin: 0 auto;
	}

	.content .contbox .modelist {
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
	}

	.content .contbox .modelist .modeitem {
		width: 170px;
		height: 70px;
		padding: 10px;
		margin-right: 20px;
		overflow: hidden;
		border-radius: 5px;
	}

	.content .iptitem {
		display: flex;
		margin-top: 40px;
	}

	.content .iptitem .ward {
		flex-shrink: 0;
		margin-right: 20px;
	}

	.normal {
		background-color: #ffffff;
		color: #000000;
	}

	.active {
		background-color: #00aaff;
		color: #ffffff;
	}

	.row {
		display: flex;
		align-items: center;
	}

	.btnbox {
		display: flex;
		justify-content: center;
		width: 50%;
		margin: 50px auto;
	}
	.page{
		background:#F5F7FA;
	}
	.rightbox{
		padding-top:20px;
		padding-bottom:20px;
		width: 1320px;
		overflow:auto;
	}
	.pagecontent{
		
		
		height: calc(100vh - 81px);
	}
</style>