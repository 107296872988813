var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ borderBottom: _vm.border ,background: _vm.list.options.bgcolor}),attrs:{"id":"wenben"}},[(_vm.list.options.loginstate == 0)?_c('div',{style:({'padding-top':_vm.margintop,
          'padding-bottom':_vm.marginbottom,'padding-left':  _vm.list.options.paddingpage + 'px',
        'padding-right':  _vm.list.options.paddingpage + 'px',})},[_c('div',{staticClass:"box loginstate0",style:({background: _vm.list.options.backgroundun,'padding-top':_vm.paddingtop,
		  'padding-bottom':_vm.paddingbottom,'border-radius':_vm.borderradius})},[_c('div',{staticClass:"cont"},[_c('div',{style:({
		  		  fontSize: _vm.size,
		  		  textAlign: _vm.align,
		  		  fontWeight: _vm.bold,
		  		  color: _vm.list.options.titlecolor,
		  		})},[_vm._v(" "+_vm._s(_vm.list.options.name)+" ")]),_c('div',{staticClass:"describe",style:({ fontSize: _vm.describesize, color: _vm.list.options.describecolor })},[_vm._v(" "+_vm._s(_vm.list.options.label)+" ")])]),(_vm.list.options.btntxt.length > 0)?_c('div',{staticClass:"btns"},[_c('span',{style:({background: _vm.list.options.btncolor})},[_vm._v(_vm._s(_vm.list.options.btntxt))])]):_vm._e(),(_vm.list.options.loginimg && _vm.list.options.loginimg.length>0)?_c('div',{staticClass:"bgimg"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.list.options.loginimg}})]):_vm._e()])]):(_vm.list.options.loginstate == 1)?_c('div',{style:({'padding-top':_vm.margintop,
		  'padding-bottom':_vm.marginbottom,'padding-left':  _vm.list.options.paddingpage + 'px',
		'padding-right':  _vm.list.options.paddingpage + 'px',})},[_c('div',{staticClass:"box loginstate1",style:({background: _vm.list.options.background,'padding-top':_vm.paddingtop,
          'padding-bottom':_vm.paddingbottom,'border-radius':_vm.borderradius})},[_c('div',{staticClass:"cont"},[_c('div',{staticClass:"head"},[(_vm.list.options.logo.length > 0)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.list.options.logo}})]):_vm._e(),_c('div',{staticClass:"center"},[_c('div',{staticClass:"title",style:({'color':_vm.list.options.clubcolor})},[_vm._v("会员名")]),_c('div',{staticClass:"memo",style:({'color':_vm.list.options.clubcolor})},[_vm._v("卡号：***")])]),_c('div',{staticClass:"btn"},[_c('img',{attrs:{"src":_vm.list.options.ewm}})])]),_c('div',{staticClass:"line",style:({background:_vm.list.options.linecolor})}),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"price"},[_c('span',{staticClass:"flg"},[_vm._v("￥")]),_c('span',{style:({'color':_vm.list.options.describecolor})},[_vm._v("1000")])]),_c('div',{staticClass:"op",style:({'color':_vm.list.options.titlecolor})},[_vm._v(" 储值 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"price",style:({'color':_vm.list.options.describecolor})},[_vm._v(" 1000 ")]),_c('div',{staticClass:"op",style:({'color':_vm.list.options.titlecolor})},[_vm._v(" 积分 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"price",style:({'color':_vm.list.options.describecolor})},[_vm._v(" 100 ")]),_c('div',{staticClass:"op",style:({'color':_vm.list.options.titlecolor})},[_vm._v(" 礼券 ")])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"price",style:({'color':_vm.list.options.describecolor})},[_vm._v(" 2 ")]),_c('div',{staticClass:"op",style:({'color':_vm.list.options.titlecolor})},[_vm._v(" 礼品卡 ")])])])]),(_vm.list.options.bgimg.length> 0)?_c('div',{staticClass:"bgimg"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.list.options.bgimg}})]):_vm._e()])]):_c('div',{style:({'padding-top':_vm.margintop,
			  'padding-bottom':_vm.marginbottom,'padding-left':  _vm.list.options.paddingpage + 'px',
			'padding-right':  _vm.list.options.paddingpage + 'px',
			'overflow':'hidden',
			
			
			})},[_c('div',{staticClass:"box-uncard",style:({'border-radius':_vm.borderradius,background: _vm.list.options.background,
			'overflow':'hidden'})},[(_vm.list.options.bgimg.length> 0)?_c('div',{staticClass:"bgimg"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.list.options.bgimg}})]):_vm._e(),_c('div',{staticClass:"box loginstate3",style:({background: _vm.list.options.background,'padding-top':_vm.paddingtop,
				'padding-bottom':_vm.paddingbottom,})},[_c('div',{staticClass:"cont",style:({'width':_vm.conwidth})},[_c('div',{staticClass:"head"},[(_vm.list.options.logo.length > 0)?_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":_vm.list.options.logo}})]):_vm._e(),_c('div',{staticClass:"center"},[_c('div',{staticClass:"title",style:({'color':_vm.list.options.clubcolor})},[_vm._v("会员名")]),_c('div',{staticClass:"memo",style:({'color':_vm.list.options.clubcolor})},[_vm._v("卡号：***")])]),_c('div',{staticClass:"btn"},[_c('img',{attrs:{"src":_vm.list.options.ewm}})])])])]),_c('div',{staticClass:"box loginstate0 ",style:({background: _vm.list.options.backgroundunclub,'padding-top':_vm.paddingtop,
							  'padding-bottom':_vm.paddingbottom,
							  'margin-left':_vm.clubmargin,
							  'margin-right':_vm.clubmargin,
							  'margin-bottom':_vm.clubmargin,
							  
							  'border-radius':_vm.clubradius
							  })},[_c('div',{staticClass:"cont"},[_c('div',{style:({
							  		  fontSize: _vm.size,
							  		  textAlign: _vm.align,
							  		  fontWeight: _vm.bold,
							  		  color: _vm.list.options.titlecolor,
							  		})},[_vm._v(" "+_vm._s(_vm.list.options.clubname)+" ")]),_c('div',{staticClass:"describe",style:({ fontSize: _vm.describesize, color: _vm.list.options.describecolor })},[_vm._v(" "+_vm._s(_vm.list.options.clublabel)+" ")])]),(_vm.list.options.clubbtntxt&&_vm.list.options.clubbtntxt.length > 0)?_c('div',{staticClass:"btns"},[_c('span',{style:({background: _vm.list.options.btncolor})},[_vm._v(_vm._s(_vm.list.options.clubbtntxt))])]):_vm._e(),(_vm.list.options.loginimgclub && _vm.list.options.loginimgclub.length>0)?_c('div',{staticClass:"bgimg",staticStyle:{"width":"100%"}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.list.options.loginimgclub}})]):_vm._e()])])])])}
var staticRenderFns = []

export { render, staticRenderFns }