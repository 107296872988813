<template>
  <div id="search" :style="{ background: list.options.background }">
    <div class="input">
      <i class="el-icon-search left"></i>
      <input
        class="user"
        v-model="value"
        :style="{ background: list.options.framecolor, textAlign: framealign ,color:list.options.textcolor ,borderRadius:radius}"
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        suffix-icon="el-icon-date"
      />
      <i class="el-icon-loading right"></i>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.list = this.data;
  },
  data() {
    return {
      value:"1111"
    };
  },
  computed: {
    // 显示方式
    framealign() {
      if (this.list.options.framealign == 2) {
        return "center";
      } else {
        return "left";
      }
    },
    radius(){
      if(this.list.options.frame==1){
        return "0px"
      }else{
        return "12px"
      }
    }
  },
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
#search {
  .input {
    padding: 10px 20px;
    position: relative;
    input {
      height: 24px;
      width: 88%;
      padding: 6px 20px;
      border: 1px solid #ccc;
    }
    .user {
      padding-left: 20px;
    }
    .left {
      top: 20px;
      left: 26px;
      position: absolute;
    }
    .right {
      top: 20px;
      right: 26px;
      position: absolute;
    }
  }
}
</style>