<template>
	<div class="">
		<el-dialog :title="title" :visible.sync="uploadVisible" width="30%" :before-close="uploadClose"
			:append-to-body="true">
			<div class="box">
				<el-upload
				  class="upload-demo"
				  drag
				  :action="uploadurl"
				  :on-success="successfile"
				  :headers="headers"
				  :data="importData"
				  :limit="1"
				  multiple style="margin-top: 20px;">
				  <i class="el-icon-upload"></i>
				  <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
				  <div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: right;">
				<el-button size="small" @click='uploadClose'>关闭</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import {getloginuser} from '../../utils/util.js'
	export default {
		data() {
			return {
				title: "",
				headers:{Authorization:''},
				uploadVisible: false,
				imgInfo: {},
				uploadurl: '',
				importData:{
					name:'图片',
					mpid:0
				},
			}
		},
		methods: {
			funShow(imgInfo) {
				console.log(imgInfo,'csss');
				this.uploadVisible = true
				let u = getloginuser()
				if(u != null){
					this.headers.Authorization = 'Bearer ' + u.token
				}
				
				
				console.log(this.headers,'this.headers');
				this.imgInfo = imgInfo
				this.title = imgInfo.mc + '(图片上传)'
				this.importData.mpid = imgInfo.mpid;
				this.uploadurl = '/api/customer/file/weixin/uploadimg'
			},
			uploadClose() {
				this.uploadVisible = false
			},
			successfile(res, file, fileList) {
				console.log("successfile==>", res, file, fileList);
				if(res.code == 200){
					this.$message({
						type: 'success',
						message: "上传成功"
					})
					this.$emit('uploadres',{
						link_url:res.data.link_url,
						media_url:res.data.media_url
					})
				}else{
					this.$message({
						type: 'error',
						message: "上传失败"
					})
				}
			},
		}
	}
</script>

<style scoped>
	.box{
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.upload-demo {
		margin-left: 10px;
	}
</style>