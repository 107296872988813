<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="14"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-card class="box-card" style="width: 1038px;">
					<div slot="header" class="clearfix">
						<div class="" style="display: flex;justify-content: left;">
							<el-button type="primary" size="small" @click="handletxt">新增文本关键字</el-button>
							<el-button type="primary" size="small" @click="handleimg">新增图文关键字</el-button>
						</div>
					</div>
					<!--列表-->
					<el-table size="small" :data="keywordData" highlight-current-row v-loading="loading" :border="false"
						element-loading-text="拼命加载中" style="width: 100%;">
						<el-table-column align="center" sortable prop="miniappid" label="微信小程序appid" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="cmdtext" label="消息指令" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="apptype" label="应用类型" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="kfaccount" label="指定客服帐号" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="textcontent" label="普通指令响应消息" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="querytype" label="查询方式" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="miniapppath" label="微信小程序路径" width="180">
						</el-table-column>
						<el-table-column label="操作" min-width="200">
							<template slot-scope="scope">
								<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
								<el-button size="mini" type="danger" @click="deletekeyword(scope.$index, scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
				</el-card>
				<!-- 编辑界面 -->
				<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="查询方式" prop="querytype">
							<el-input size="small" v-model="editForm.querytype" auto-complete="off"
								placeholder="请输入查询方式"></el-input>
						</el-form-item>
						<el-form-item label="消息指令" prop="cmdtext">
							<el-input size="small" v-model="editForm.cmdtext" auto-complete="off"
								placeholder="请输入消息指令"></el-input>
						</el-form-item>
						<el-form-item label="普通指令响应消息" prop="textcontent">
							<el-input size="small" v-model="editForm.textcontent" auto-complete="off"
								placeholder="请输入普通指令响应消息"></el-input>
						</el-form-item>
						<el-form-item label="客服帐号" prop="kfaccount">
							<el-input size="small" v-model="editForm.kfaccount" auto-complete="off"
								placeholder="请输入客服帐号"></el-input>
						</el-form-item>
						<el-form-item label="微信小程序路径" prop="miniapppath">
							<el-input size="small" v-model="editForm.miniapppath" auto-complete="off"
								placeholder="请输入微信小程序路径"></el-input>
						</el-form-item>
						<el-form-item label="微信小程序appid" prop="miniappid">
							<el-input size="small" v-model="editForm.miniappid" auto-complete="off"
								placeholder="请输入微信小程序appid"></el-input>
						</el-form-item>
						<el-form-item label="微信小程序路径" prop="miniappparam">
							<el-input size="small" v-model="editForm.miniappparam" auto-complete="off"
								placeholder="请输入微信小程序路径"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" @click='editFormVisible=false'>取消</el-button>
						<el-button size="small" type="primary" :loading="loading" class="title"
							@click="submitForm('editForm')">保存
						</el-button>
					</div>
				</el-dialog>
				</div>
				</div>
		
	</div>
</template>

<script>
	import keywordApi from '../../../../api/cus/keyword.js'
	import leftnav from '../../../../components/customer/home/mp/weixingzh/leftnav.vue'
	import navcon from '../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				loading: false,
				editFormVisible: false,
				keywordData: [],
				editForm: {
					"id": "",
					"mpid": 0,
					"cmdtext": "",
					"apptype": "",
					"kfaccount": "",
					"textcontent": "",
					"groupid": "",
					"cmdlike": "",
					"querytype": "",
					"querycount": 0,
					"miniappid": "",
					"miniapppath": "",
					"appflag": "",
					"miniappparam": ""
				}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getkeyData()
			},
			getkeyData(){
				let param = {
					mpid: this.mpid,
					pageno: 1,
					pagesize: 20
				}
				keywordApi.page(param, res => {
					console.log(res);
					if (res.code == 200) {
						this.keywordData = res.data.list
					}
				})
			},
			handleEdit(index, row) {
				this.title = "编辑"
				this.editFormVisible = true
				let param = {
					mpid: row.mpid,
					cmdid: row.id,
				}
				keywordApi.detail(param, res => {
					console.log(res,';v');
					if (res.code == 200) {
						this.editForm.id = res.data.id
						this.editForm.mpid = res.data.mpid
						this.editForm.cmdtext = res.data.cmdText
						this.editForm.apptype = res.data.appType
						this.editForm.kfaccount = res.data.kfAccount
						this.editForm.textcontent = res.data.textContent
						this.editForm.groupid = res.data.groupId
						this.editForm.cmdlike = res.data.cmdLike
						this.editForm.querytype = res.data.appType
						this.editForm.querycount = res.data.queryCount
						this.editForm.miniappid = res.data.miniappid
						this.editForm.miniapppath = res.data.miniapppath
						this.editForm.appflag = res.data.appflag
						this.editForm.miniappparam = res.data.miniappparam
						console.log(this.editForm,'this.editForm');
					}
				})
			},
			deletekeyword(index, row){
				let param = {
					mpid: row.mpid,
					cmdid: row.id,
				}
				keywordApi.del(param, res => {
					if(res.code == 200){
						this.$message({
							type: "success",
							message: res.msg
						})
						this.getkeyData()
					}else{
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			handletxt() {
				this.title = "新增文本关键字"
				this.editFormVisible = true
				this.editForm.id = ""
				this.editForm.mpid = this.mpid
				this.editForm.cmdtext = ""
				this.editForm.apptype = "txt"
				this.editForm.kfaccount = ""
				this.editForm.textcontent = ""
				this.editForm.groupid = ""
				this.editForm.cmdlike = "1"
				this.editForm.querytype = ""
				this.editForm.querycount = ""
				this.editForm.miniappid = ""
				this.editForm.miniapppath = ""
				this.editForm.appflag = ""
				this.editForm.miniappparam = ""
			},
			handleimg() {
				this.title = "新增图文关键字"
			},
			submitForm() {
				let param = {
					"id": this.editForm.id,
					"mpid": this.editForm.mpid,
					"cmdtext": this.editForm.cmdtext,
					"apptype": this.editForm.apptype,
					"kfaccount": this.editForm.kfaccount,
					"textcontent": this.editForm.textcontent,
					"groupid": this.editForm.groupid,
					"cmdlike": this.editForm.cmdlike,
					"querytype": this.editForm.querytype,
					"querycount": this.editForm.querycount,
					"miniappid": this.editForm.miniappid,
					"miniapppath": this.editForm.miniapppath,
					"appflag": this.editForm.appflag,
					"miniappparam": this.editForm.miniappparam
				}
				console.log(param, 'param');
				keywordApi.edit(param, res => {
					console.log(res);
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
					}
				})
			}

		}
	}
</script>

<style scoped>
	.pagecontent {
		display: flex;
	
		height: calc(100vh - 81px);
	}
	
	.w200 {
		width: 200px;
	}
	
	.value {
		color: #777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow: 1;
	}
	.btnbox{
		width:100%;
		display:flex;
		justify-content: center;
	}
	.btnbox .btn{
	width: 200px;
	margin: 20px auto;
	}
	.page {
		background: #F5F7FA;
	}
	
	.border {
		border-bottom: 1px solid #E2E2E2;
	}
	
	.rightbox {
		padding-top: 20px;
		width: 1320px;
	}
</style>