<template>
	<div id="text">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="数据" name="first">
				<div class="details">
					<div class="btn">
						<el-select v-model="list.options.size" filterable allow-create  placeholder="请选择标题大小" size="small">
							<el-option v-for="item in fontlist" :key="item.id" :label="item.mc"
								:value="item.id">
							</el-option>
						</el-select>
						<el-tooltip effect="dark" content="加粗" placement="top">
							<div class="bbox" style="margin-left: 10px;">
								<img class="blodimg" src="../../../../../assets/image/icon_b1.png" v-if="list.options.bold && list.options.bold == 2" @click="funchu(1)"></img>
								<img class="blodimg" src="../../../../../assets/image/icon_b0.png" v-else @click="funchu(2)" /></img>
							</div>
						</el-tooltip>
						<el-tooltip effect="dark" content="输入框字体色" placement="top">
							<el-color-picker v-model="list.options.titlecolor" size="medium"></el-color-picker>
						</el-tooltip>
						<el-tooltip effect="dark" content="输入框背景色" placement="top">
							<el-color-picker v-model="list.options.inputbg" size="medium"></el-color-picker>
						</el-tooltip>
						
					</div>
					<!-- 标题文字大小 -->
					<!-- <div class="list">
						<div class="title">
							<span>标题大小 </span>
							<span>{{
				      list.options.size == 1
				        ? "大(16号)"
				        : list.options.size == 2
				        ? "中(14号)"
				        : "小(12号)"
				    }}
							</span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.size" @change="size">
								<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
									<el-radio-button :label="1">
										<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
									<el-radio-button :label="2">
										<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
									<el-radio-button :label="3">
										<div style="font-size: 12px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div> -->
				
					<!-- 是否文字加粗 -->
					<!-- <div class="list">
						<div class="title">
							<span>标题粗细 </span>
							<span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.bold">
								<el-tooltip class="item" effect="dark" content="常规体" placement="bottom">
									<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="加粗体" placement="bottom">
									<el-radio-button :label="2">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div> -->
					<!-- 标题文字颜色 -->
					<!-- <div class="list">
						<div class="title"><span>输入框文字颜色</span>{{list.options.titlecolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('titlecolor')">重置</el-button>
						</div>
					</div> -->
					<!-- 输入框颜色 -->
					<!-- <div class="list">
						<div class="title"><span>输入框背景颜色</span>{{list.options.inputbg||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('inputbg')">重置</el-button><el-color-picker
								v-model="list.options.inputbg" size="medium"></el-color-picker>
						</div>
					</div> -->
					<!-- 输入框颜色 -->
					<!-- <div class="list">
						<div class="title"><span>输入框边框颜色</span>{{list.options.bordercolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('bordercolor')">重置</el-button><el-color-picker
								v-model="list.options.bordercolor" size="medium"></el-color-picker>
						</div>
					</div> -->
					<!-- 大背景颜色 -->
					<div class="list">
						<div class="title"><span>大背景颜色</span>{{ list.options.bgcolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('bgcolor')">重置</el-button><el-color-picker
								v-model="list.options.bgcolor" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 二维码背景图片 -->
					<div class="list">
						<div class="title"><span>二维码图片</span></div>
						<div class="btn">
							<el-button type="text" class="reset" @click="reset('ewmimg')">重置</el-button><el-button
								type="primary" size="small" @click="funupload('ewmimg')">上传</el-button>
						</div>
					</div>
					<div class="list">
						<el-image :src="list.options.ewmimg" style="width: 80px;height: 80px;"></el-image>
					</div>
					<!-- 背景图片 -->
					<div class="list">
						<div class="title"><span>背景图片</span></div>
						<div class="btn">
							<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button><el-button
								type="primary" size="small" @click="funupload('bgimg')">上传</el-button>
						</div>
					</div>
					<div class="list">
						<el-image :src="list.options.bgimg" style="width: 80px;height: 80px;"></el-image>
					</div>
					<div class="disps">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="3000" style="margin: 0 10px;">
							</el-slider>
						</div>
					</div>
					<div class="disps">
					  <div class="title">
					    <span>高度 </span>
					  </div>
					  <div class="btn2 full">
					    <el-slider v-model="list.options.height" show-input :max="3000" style="margin: 0 10px;"> 
					    </el-slider>
					  </div>
					</div>
					<div class="disps">
					  <div class="title">
					    <span>上内边距 </span>
					  </div>
					  <div class="btn2 full">
					    <el-slider v-model="list.options.margintop" show-input :max="200" style="margin: 0 10px;"> 
					    </el-slider>
					  </div>
					</div>
					<div class="disps">
					  <div class="title">
					    <span>下内边距 </span>
					  </div>
					  <div class="btn2 full">
					    <el-slider v-model="list.options.marginbottom" show-input :max="200" style="margin: 0 10px;"> 
					    </el-slider>
					  </div>
					</div>
					<div class="disps">
					  <div class="title">
					    <span>外内页边距 </span>
					  </div>
					  <div class="btn2 full">
					    <el-slider v-model="list.options.paddingpage" show-input :max="200" style="margin: 0 10px;"> 
					    </el-slider>
					  </div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>

</template>

<script>
	import selImgs from "@/components/customer/selImgs"
	export default {
		components:{
			selImgs
		},
		name: "image_qunqr_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
				imgtype:'',
				activeName: "first",
				fontlist:[{
					mc:'12号(小)',
					id:3
				},
				{
					mc:'14号(中)',
					id:2
				},
				{
					mc:'16号(大)',
					id:1
				},
				],
			};
		},

		created() {
			this.list = this.data;
		},

		methods: {
			//  显示的位置
			position(event) {
				// this.$set(this.list.options.position, position, event);
				this.list.options.position = event;
			},
			reset(key) {
				this.list.options[key] = '';
			},
			// 字体大小
			funchu(bold) {
				this.list.options.bold = bold
			},
			size() {},
			funupload(imgtype) {
				this.imgtype=imgtype
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				
				if(this.imgtype == 'bgimg'){
					this.list.options.bgimg = imgitem.url;
				}else{
					this.list.options.ewmimg = imgitem.url;
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	#text {
		padding: 20px;

		p {
			padding: 10px 0;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #969799;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
		}
	}
	.bbox {
		cursor: pointer;
		margin-right: 5px;
		width: 32px;
		height: 30px;
		border-radius: 4px;
		background-color: #DEDFE1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}
	.blodimg {
		width: 20px;
		height: 20px;
	}
</style>