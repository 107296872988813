const lightstyle = {
	
		"shop": {
			"date": "#858585",
			"title": "#111111"
		},
		"ad": {
			"type": "11"
		},
		"loginbtn": {
			"color": "#000000",
			"bg": "#FFC80C"
		},
		"header": {
			"line": "#D9D9D9",
			"boxborder": "#D9D9D9",
			"title": "#1F1F1F",
			iconstyle:'white'
		},
		"cls": {
			"normal": {
				"color": "#383838",
				"bg": "#F5F5F5"
			},
			"size": "",
			"tip": {
				"color": "",
				"bg": ""
			},
			"sel": {
				"color": "#383838",
				"bg": "#E8E8E9"
			}
		},
		"cont": {
			'navstyle':'10', //10 浅色 11深色
			"bgcolor": "#FFFFFF",
			"color": "#E8E8E8",
			"bgimg": ""
		},
		"menu": {
			bg:"#20232B",
			color:'',
			usezt:"T",
			"newsp": {
				"color": null,
				"mc": "新品尝鲜",
				"selcolor": "#383838",
				"linecolor": "#002EA2",
				"zt":'T'
			},
			"tejia": {
				"color": null,
				"mc": "时段特价",
				"selcolor": "#383838",
				"linecolor": "#002EA2",
				"zt":'T'
			},
			"bg": "",
			"tuijian": {
				"color": null,
				"mc": "店长推荐",
				"selcolor": "#383838",
				"linecolor": "#002EA2",
				"zt":'T'
			},
			"jindian": {
				"color": null,
				"mc": "经典菜单",
				"selcolor": "#383838",
				"linecolor": "#002EA2"
			},
			"color": "#666666"
		},
		"sp": {
			contbg:'',
			spbg:'',
			"color": "#110E0F",
			"clschild": {
				"normal": {
					"color": "",
					"bg": ""
				},
				"sel": {
					"color": "",
					"bg": ""
				}
			},
			"bg": "#FFFFFF",
			"clsmc": {
				"color": "#383838",
				"line": "#002EA2"
			},
			"spitem": {
				"spmc": {
					"color": "#110E0F"
				},
				"spremark": {
					"color": "#A9A9A9"
				},
				"price": {
					"clubcolor": "#000000",
					"salecolor": "#FFFFFF",
					"marketcolor": "#908E8F"
				},
				"lab": {
					"bgcolor": "#F5F5F5",
					"color": "#404040",
					"bodercolor": "#2D384A",
					"bordercolor": "#D6D6D6"
				},
				"btn": {
					"color": "#ffffff",
					"bg": "#002EA2",
					"num": {
						"color": "#ffffff"
					},
					"tip": {
						"color": "#ffffff",
						"bg": "#BF9F6D"
					}
				}
			}
		},
		"minsale": {
			"color": "#002EA2",
			"bg": "#F4F7FC",
			"line": "#f0f0f0",
			"zt": {
				"overcolor": "#00aa00",
				"uncolor": "#ff0000"
			},
			"chacolor": "#002EA2"
		},
		"cart": {
			"usezt":"T",
			"bgcolor": "#ffffff",
			"color": "#E8E8E8",
			"num": {
				"color": "#ffffff",
				"bg": "#DEAB6D"
			},
			"icon": "https://gzh.dxerp.net/dxgzh/1706239812474437634/202408/7427fc5890a1461eae3f06d48341d60a.png?key=123456",
			"btn": {
				"color": "#FFFFFF",
				"bg": "#002EA2"
			}
		}
	
}
const deepstyle ={
	
		cont: {
			// bgimg: "https://imgoss.91erp.net/10001/sp/240801104438158.png",
			 bgimg: "",
			bgcolor: "#20232B",
			// bgcolor: "#181920",
			color: "#E8E8E8",
			navstyle:'10', //10 浅色 11深色
		},
		header: {
			line: '#8D8D8D',
			title: "#ffffff",
			boxborder: "#ffffff",
			iconstyle:'white'
		},
		shop: {
			title: "#ffffff",
			date: '#ffffff',
		},
		minsale: {
			bg: "#F5F5F5",
			color: '#000000',
			line: '#f0f0f0',
			chacolor: "#000000",
			zt: {
				overcolor: '#00aa00',
				uncolor: '#ff0000'
			}
		},
		ad: {
			type: '11', //10 券 11广告
	
		},
		loginbtn: {
			color: '#000000',
			bg: '#FFC80C'
		},
	
		menu: {
			bg: "#20232B",
			color:'',
			usezt:"T",
			jindian: {
				mc: "经典菜单",
				color: '#AAA9A9',
				selcolor: '#F1F1F1',
				linecolor: '#F5F5F5'
			},
			tuijian: {
				mc: "店长推荐",
				zt: 'T',
				color: '#AAA9A9',
				selcolor: '#F1F1F1',
				linecolor: '#F5F5F5'
			},
			tejia: {
				mc: "时段特价",
				color: '#AAA9A9',
				selcolor: '#F1F1F1',
				linecolor: '#F5F5F5',
				zt: 'T',
			},
			newsp: {
				mc: "新品尝鲜",
				color: '#AAA9A9',
				selcolor: '#F1F1F1',
				linecolor: '#F5F5F5',
				zt: 'T',
			},
		},
		cls: {
			normal: {
				bg: '#20232B',
				color: "#E8E8E8",
			},
			sel: {
				bg: '#181920',
				color: "#E8E8E8",
			},
			size: '',
			tip: {
				color: '',
				bg: ''
			}
		},
		sp: {
			contbg:'',
			spbg:'',
			bg: '#181920',
			color: "#E8E8E8",
			clsmc: {
				line: '#ffffff',
				color: '#ffffff'
			},
			clschild: {
				normal: {
					bg: '',
					color: ""
				},
				sel: {
					bg: '',
					color: ""
				}
			},
			spitem: {
				spmc: {
					color: '#ffffff',
	
				},
				spremark: {
					color: '#acacac',
	
				},
				price: {
					marketcolor: "#acacac",
					salecolor: "#ffffff",
					clubcolor: "#000000",
				},
				btn: {
					color: '#ffffff',
					bg: '#2D384A',
					num: {
						color: "#ffffff"
					},
					tip: {
						color: '#ffffff',
						bg: '#BF9F6D'
					} //规格按钮右上角数量
				},
				lab: {
					bodercolor: '#2D384A',
					color: "#ffffff",
					bgcolor: '#2D384A'
				}
			}
		},
		cart: {
			usezt:"T",
			icon: 'https://gzh.dxerp.net/dxgzh/1706239812474437634/202408/7427fc5890a1461eae3f06d48341d60a.png?key=123456',
			bgcolor: '#ffffff',
			color: '#E8E8E8',
			btn: {
				color: "#E8E8E8",
				bg: '#20232B'
			},
			num: {
				color: '#ffffff',
				bg: '#DEAB6D'
			}
		}
	
	
}
module.exports = {
	lightstyle,
	deepstyle
}