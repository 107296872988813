<template>
  <div class="content">
    <div class="title">{{ item.tmpname }}</div>
    <div class="list">
      <div class="item" v-for="(item,index) in list" :key="index" @click="funitem(item)">
        <div class="logo"> <img :src="item.logo" /> </div>
        <div class="name">{{ item.pagename }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import designTemplataApi from "../../../api/cus/designtmplate.js";
export default {
  components:{

  },
  data() {
			return {
        id:'',
        item:{},
        list:{}
      }
    },
    
    mounted(){
      let id = this.$route.query.id;
      this.id = id;
      this.initdata();
    },
    methods:{
      initdata:function() {
        console.log('initdata')
        designTemplataApi.getUserPageList({id:this.id},res=>{
          console.log(res);
          if(res.code == 200){
            this.item = {
              tmpname:res.data.tmpname
            }
            this.list = res.data.pagelist
          }
        })
      },
      funitem:function(item){
        this.$router.push({
          path:`/customer/xcxtemplate/design?id=${item.id}&tid=${this.id}&t=${item.pageno}&mc=${item.pagename}`
        })
      }
    }
}
</script>
<style lang="less" scoped>

.content {
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
}

.list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.item {
  text-align: center;
  box-shadow: 0 2px 6px #ccc;
  padding: 20px;
  margin: 20px 20px 0 0;
  &:hover{
    box-shadow: 0 2px 6px #2865e0;
    border-radius: 20px;
  }

  .logo{
    width: 200px;
    height: 350px;
    overflow: hidden;

    img{
      width: 100%;
    }
  }
}
</style>