<template>
	<div>
		<navcon></navcon>
		<div class="pages">
			<div class="box">
				<div class="header">
					<div class="pic">
						<img :src="tempInfo.logo" alt="logo" />
					</div>
					<div class="center">
						<div class="title">
							{{tempInfo.tmpname}}
						</div>
						<div class="hot">
							{{tempInfo.style_name}} 热度：2200
						</div>
						<div class="memo">
							高端时尚简约设计
						</div>
						<div class="price">
							<p>价格：</p>
							<div class="price_style">
								<span>￥{{pricebuy}}</span>
							</div>
						</div>
						<div class="day">
							<p>周期：</p>
							<div class="day_wrap">
								<div class="day_item" :class="priceindex == index ? 'sel' : 'nomal'"
									v-for="(item,index) in pricejson" :key="index" @click="funselprice(item,index)">
									{{item.buyname}}
								</div>
							</div>
						</div>
						<div class="submit">
							<el-button type="primary" @click="payorder()">立即购买</el-button>
						</div>
					</div>
					<div class="ewm">
						<!-- <img src="../../../assets/image/ewm.png"> -->
						<canvas id="QRCode_header" style="width: 100px; height: 100px;margin-left: 10px;margin-top: 10px;"></canvas>
						<p>微信扫码即可预览</p>
						<p>M{{tmpid}}</p>
					</div>
				</div>
				<div class="editcontent">
					<p>详情介绍</p>
					<div v-if="tempInfo.memo != ''">
						<div v-html="tempInfo.memo"></div>
					</div>
					<div class="memo" v-else>
						<span>暂无数据</span>
					</div>
					<div class="freemail">
						<div class="cls">
							<div class="title">
								精品模版
							</div>
							<!-- <div class="cls_wrap">
								<div class="cls_item" v-for="(item,index) in clslist" :key="index">
									<span>{{item.mc}}</span>
								</div>
							</div> -->
						</div>
						<div class="box">
							<div class="box-wrap">
								<div class="box-item" v-for="(item,index) in templist" :key="index"
									@click="fundetail(item)">
									<a  :href=" '/customer/xcxtemplate/marketdetail?tmpid='+ tmpid">
										<img :src="item.logo" />
									</a>
									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<payonline ref="payonline"></payonline>
			<showload :showload="showload"></showload>
		</div>
		<pagebottom ref="pagebottom"></pagebottom> 
	</div>
	
</template>

<script>
	import showload from "@/components/loading";
	import marketApi from '../../../api/cus/market.js';
	import payonline from "@/components/customer/payonline";
	import designTemplataApi from "../../../api/cus/designtmplate.js";
	import QRCode from "qrcode";
	import {getloginuser} from '../../../utils/util.js'
	import navcon from '@/components/customer/navcon'
	import pagebottom from '@/components/pagebottom'
	export default {
		components: {
			showload,
			payonline,
			navcon,
			pagebottom
		},
		data() {
			return {
				tmpid: "",
				showload: false,
				pricesale: 0,
				pricebuy: 0,
				priceindex: 0,
				tempInfo: {},
				templist: [],
				pricejson: [],
				clslist: [],
				userInfo:{}
			}
		},
		mounted() {
			
			let tmpid = this.$route.query.tmpid
			
			if (tmpid != undefined) {
				this.tmpid = tmpid
				this.init()
				this.getQrcode()
			}
		},
		methods: {
			init() {
				let param = {
					sys_tempid: this.tmpid
				}
				this.priceindex = 0
				this.pricesale = 0
				this.pricebuy = 0
				this.showload = true
				marketApi.detail(param, res => {
					this.showload = false
					console.log(res, 'res.detail');
					if (res.code == 200) {
						this.tempInfo = res.data
						if (res.data.pricejson && res.data.pricejson.length > 3) {
							this.pricejson = JSON.parse(res.data.pricejson)
							if (this.pricejson && this.pricejson.length > 0) {
								this.priceindex = 0
								this.pricesale = this.pricejson[0].pricesale
								this.pricebuy = this.pricejson[0].pricebuy
							}
						} else {
							this.pricejson = [{
								buyname: '7天',
								buytime: 7,
								pricebuy: 0,
								pricesale: 0
							}]
						}
						document.title = res.data.tmpname + '-公众号管理平台-大新软件'
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
				marketApi.listbyjingpin({}, res => {
					this.showload = false
					if (res.code == 200) {
						this.templist = res.data
					}
				})

			},
			getQrcode() {
				let opts = {
					errorCorrectionLevel: "H", //容错级别
					type: "image/png", //生成的二维码类型
					quality: 0.3, //二维码质量
					margin: 0, //二维码留白边距
					width: 100, //宽
					height: 100, //高
					text: "", //二维码内容
					color: {
						dark: "#333333", //前景色
						light: "#fff", //背景色
					},
				};
				let mchno = this.userInfo.mchno
				let shopno = this.userInfo.shopno
				if(mchno == null || mchno == undefined || mchno.length<=0){
					mchno = '10001'
				}
				if(shopno == null || shopno == undefined || shopno.length<=0){
					shopno = '1000101'
				}
				this.qrUrl = `http://wxapp.91erp.net/wxty1/?fun=ty&vp=viewtemplatepage&mch=${mchno}&fdid=${shopno}&tcls=sys&tid=${this.tmpid}&t=index`
				console.log(this.qrUrl,'this.qrUrl');
				// this.qrUrl = "http://wxapp.91erp.net/wxty1/?fun=ty&vp=viewtemplatepage&mch=10001&fdid=1000101&tcls=user&tid=" + this
				// 	.templateid + "&t=" + this.pagetype
				let msg = document.getElementById("QRCode_header");
				// 将获取到的数据(val)画到msg(canvas)上
				QRCode.toCanvas(msg, this.qrUrl, opts, function(error) {
					if (error) {
						console.log("二维码加载失败", error);
						this.$message.error("二维码加载失败");
					}
				});
			},
			submit() {
				let _this = this;
				designTemplataApi.regUserTemplate({
						buytime: this.pricejson[this.priceindex].buytime,
						sys_tempid: this.tmpid,
					},
					(res) => {
						console.log(res);
						if (res.code == 200) {
							this.$router.push({
								path: "/customer/order/temporderdetail?billno=" + res.data.billno,
							});
						}
					}
				);
			},
			funselprice(item, index) {
				this.priceindex = index
				this.pricesale = item.pricesale
				this.pricebuy = item.pricebuy
			},
			payorder() {
				let _this = this;
				let u = getloginuser()
				// let u = sessionStorage.getItem('customeruser')
				if (u != null) {
					this.userInfo = u;
					if(this.pricesale == 0){
						designTemplataApi.regUserTemplate({
								buytime: this.pricejson[this.priceindex].buytime,
								sys_tempid: this.tmpid,
							},
							(res) => {
								console.log(res);
								if (res.code == 200) {
									this.$router.push({
										path: "/customer/order/temporderdetail?billno=" + res.data.billno,
									});
								}
							}
						);
					}else{
						this.$refs['payonline'].funShow()
					}
				} else {
					this.$router.push("/customer/login?gourl=" + encodeURIComponent("/customer/xcxtemplate/marketdetail?tmpid=" + this.tmpid));
				}
				
				
			},
			fundetail(item) {
				this.tmpid = item.tmpid
				this.init()
				this.getQrcode()
			},
		}
	}
</script>

<style scoped>
	.pages {
		padding: 10px;
		width: 1200px;
		margin: 0 auto;
	}
	.pages .header {
		
		background: #F6F7F9;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.04);
		border-radius: 10px 10px 10px 10px;
		display: flex;
	}

	.pages .header .pic {
		padding: 20px 40px;
		
	}

	.pages .header .pic img {
		width: 240px;
		height: 480px;
	}

	.pages .header .center {
		width: 600px;
	}

	.pages .header .center .title {
		padding: 20px 0;
		font-weight: 800;
		font-size: 18px;
		color: #000000;
	}

	.pages .header .center .hot {
		color: #555555;
		font-size: 12px;
	}

	.pages .header .center .memo {
		margin: 20px 0;
		font-size: 14px;
		color: #383838;
	}

	.pages .header .center .price p {
		font-weight: 800;
		font-size: 18px;
	}

	.pages .header .center .price .price_style {
		font-size: 23px;
		color: #FF6433;
		font-weight: 800;
	}

	.pages .header .center .day p {
		font-weight: 800;
		font-size: 18px;
	}

	.pages .header .center .day .day_wrap {
		display: flex;
		align-items: center;
	}

	.pages .header .center .day .day_wrap .sel {
		color: #fff;
		background-color: #3388FF;
	}

	.pages .header .center .day .day_wrap .nomal {
		color: #3388FF;
		background-color: #fff;
	}

	.pages .header .center .day .day_wrap .day_item {
		cursor: pointer;
		margin: 10px;
		padding: 5px 10px;
		font-size: 12px;
		border-radius: 4px 4px 4px 4px;
		border: 1px solid #3388FF;
	}

	.pages .header .center .day .day_wrap .day_item:nth-child(1) {
		margin-left: 0;
	}

	.pages .header .ewm {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}

	.pages .header .ewm img {
		width: 133px;
		height: 133px;
	}

	.pages .header .ewm p {
		color: #222;
		margin: 0px auto;
	}

	.pages .editcontent {
		padding: 10px 0;
	}

	.pages .editcontent p {
		font-weight: 800;
		font-size: 18px;
		margin: 10px 0;
	}

	.pages .editcontent img {
		width: 1200px;
	}

	.pages .freemail {
		margin-top: 50px;
	}

	.pages .freemail .cls {
		display: flex;
	}

	.pages .freemail .title {
		font-weight: 800;
		font-size: 18px;
		color: #000000;
	}

	.pages .freemail .cls .cls_wrap {
		margin-left: 30px;
		display: flex;
	}

	.pages .freemail .cls .cls_wrap .cls_item {
		cursor: pointer;
		margin-left: 10px;
		padding: 0 10px;
		width: 64px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		background: #F3F4F7;
		border-radius: 20px 20px 20px 20px;
	}

	.pages .freemail .cls .cls_wrap .cls_item span {
		font-size: 12px;
	}

	.pages .freemail .cls .cls_wrap .sel {
		color: #3388FF;
	}

	.pages .freemail .cls .cls_wrap .nosel {
		color: #383838;
	}

	.pages .freemail .box {
		margin-top: 20px;
	}

	.pages .freemail .box .box-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.pages .freemail .box .box-wrap .box-item {
		margin-left:10px;
		margin-bottom: 10px;
		cursor: pointer;
		padding: 20px;
		background: #F6F7F9;
		border-radius: 10px 10px 10px 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.pages .freemail .box .box-wrap .box-item img {
		width: 240px;
		height: 480px;
	}

	.pages .freemail .box .box-wrap .box-item .box-bg {
		overflow: hidden;
		opacity: 0;
		transition: all .3s;
	}

	.pages .freemail .box .box-wrap .box-item:hover .box-bg {
		opacity: 1;
		width: 100%;
		height: 80px;
		text-align: center;
		background-color: #fff;
		box-shadow: 0 6px 14px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);
	}

	.editcontent .memo {
		text-align: center;
	}

	.editcontent .memo span {
		color: #949494;
		display: block;
		padding: 20px 0;
	}

	p {
		margin: 0;
	}
</style>