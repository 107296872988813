<template>
	<div class="">
		<el-dialog :title="title" :visible.sync="linkVisible" width="60%" :before-close="uploadClose"
			:append-to-body="true">
			<div class="linkhead">
				<span style="margin-right: 20px;">链接类型</span>
				<el-radio v-model="linkradio" :label="item.id" v-for="(item,index) in linklist" :key="index"
					@change="funlinkradio">{{item.mc}}</el-radio>
			</div>
			<div class="linkcont">
				<div class="box-col" v-if="linkradio === 'app'">
					<el-radio class="el-radio" v-model="linkchekradio" :label="item.id" v-for="(item,index) in linkdata"
						:key="index" @change="funchangeapp">{{item.pageName}}</el-radio>
				</div>
				<div class="box-col box-center" v-else-if="linkradio === 'wxapp'">
					<div class="mr"><span>小程序APPID</span><el-input type="text" size="small" v-model="wxpp.appid"></el-input></div>
					<div class="mr"><span>小程序路径</span><el-input type="text" size="small" v-model="wxpp.apppath"></el-input></div>
					<div class="mr"><span>小程序名称</span><el-input type="text" size="small" v-model="wxpp.appname"></el-input></div>
					<el-button type="primary" size="small" @click="funchangewxapp" style="width: 450px">确定</el-button>
				</div>
				<div class="box-col box-center" v-else-if="linkradio === 'web'">
					<div class="mr"><span>站外链接</span><el-input type="text" size="small" v-model="web.url"></el-input></div>
					<el-button type="primary" size="small" @click="funchangeweb" style="width: 450px">确定</el-button>
				</div>
				<!-- <el-tabs v-model="linkName">
					<el-tab-pane label="页面" name="0">
						
					</el-tab-pane>
					<el-tab-pane label="服务及分类" name="1">服务及分类</el-tab-pane>
					<el-tab-pane label="卡项" name="2">卡项</el-tab-pane>
					<el-tab-pane label="产品及分类" name="3">产品及分类</el-tab-pane>
					<el-tab-pane label="表单" name="4">表单</el-tab-pane>
					<el-tab-pane label="文章及分类" name="5">文章及分类</el-tab-pane>
					<el-tab-pane label="公告" name="6">公告</el-tab-pane>
					<el-tab-pane label="体验价" name="7">体验价</el-tab-pane>
					<el-tab-pane label="拼团" name="8">拼团</el-tab-pane>
					<el-tab-pane label="积分商品" name="9">积分商品</el-tab-pane>
					<el-tab-pane label="优惠券" name="10">优惠券</el-tab-pane>
				</el-tabs> -->
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: right;">
				<el-button size="small" @click='uploadClose'>关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import linkApi from '../../api/cus/link.js'
	export default {
		data() {
			return {
				title: "选择链接",
				linkVisible: false,
				linkradio: 'app',
				linklist: [{
						id: 'none',
						mc: "无链接"
					},
					{
						id: 'app',
						mc: "站内链接"
					},
					{
						id: 'dm',
						mc: "营销活动链接"
					},
					{
						id: 'wxapp',
						mc: "站外小程序"
					},
					{
						id: 'web',
						mc: "站外链接"
					},
					{
						id: 'new',
						mc: "新闻链接"
					}
				],
				linkdata: [],
				linkName: '0',
				linkchekradio: '',
				linkchekInfo: {},
				wxpp: {
					appid: "",
					apppath: "",
					appname:""
				},
				web: {
					url: ""
				}
			}
		},
		methods: {
			funShow() {
				this.wxpp= {
					appid: "",
					apppath: "",
					appname:""
				}
				this.web.url = ''
				this.linkradio = 'app'
				this.linkVisible = true
				this.linkchekradio = ''
				this.getlink()
			},
			getlink() {
				linkApi.list(res => {
					console.log(res, 'linkres');
					if (res.code == 200) {
						this.linkdata = res.data
					}
				})
			},
			uploadClose() {
				this.linkVisible = false
			},
			funlinkradio(e) {
				if (e == 'none') {
					this.linkVisible = false
					this.$emit('sellinkresult', {
						type: 'none', //none无链接 app站内链接 web站外链接 wxapp站外小程序 dm营销活动链接 new新闻链接
						name: "无链接",
					})
				} else if (e == 'app') {
					this.wxpp = {
						appid: "",
						apppath: ""
					}
				} else if (e == "web") {
					this.web = {
						url: ""
					}
				}
			},
			funchangewxapp() {
				this.linkVisible = false
				this.$emit('sellinkresult', {
					type: 'wxapp', //none无链接 app站内链接 web站外链接 wxapp站外小程序 dm营销活动链接 new新闻链接
					name: "站外小程序",
					wxpp: {
						apppath: this.wxpp.apppath,
						appid: this.wxpp.appid,
						appname: this.wxpp.appname
					}
				})
			},
			funchangeweb() {
				this.linkVisible = false
				this.$emit('sellinkresult', {
					type: "web", //none无链接 app站内链接 web站外链接 wxapp站外小程序 dm营销活动链接 new新闻链接
					name: "站外链接",
					web: {
						url: this.web.url
					}
				})
			},
			funchangeapp(e) {
				this.linkdata.forEach(f => {
					if (f.id == e) {
						console.log(f, 'fff');
						this.linkchekInfo = f
					}
				})
				let f = this.linkdata.find(f => f.id == e)
				if (f != undefined) {
					this.linkVisible = false
					this.$emit('sellinkresult', {
						type: this.linkradio, //none无链接 app站内链接 web站外链接 wxapp站外小程序 dm营销活动链接 new新闻链接
						name: "站内链接:" + f.pageName,
						key: f.pageType,
						app: {
							path: f.path,
							name: f.pageName,
						}
					})
				}

			}
		}
	}
</script>

<style scoped>
	.box-col {
		height: calc(100vh - 500px);
		overflow-y: auto;
		display: flex;
		flex-direction: column;
		padding-top: 20px;
	}

	.box-col .el-radio {
		margin: 5px 0;
		padding: 10px;
		border: 1px solid #ccc;
	}
	.box-center{
		display: flex;
		align-items: center;
		
	}
	.mr{
		margin:10px 30px;
	}
	.mr .el-input{
		width: 450px;
	}
	.mr span{
		margin: 10px 0 !important;
		display: block;
	}
</style>