<template>
	<div class="regcontent">
		<el-card class="box-card">
			<div v-if="boxShow">
				<div slot="header" class="clearfix">
					<span>{{title}}管理</span>
				</div>
				<div class="main">
					<div class="title">绑定微信{{title}}，集沉淀、运营、唤醒粉丝功能于一体，提高转化率</div>
					<div class="box">
						<div class="item">
							<div class="img">
								<img src="../../../../../../assets/logo.png" />
							</div>
							<div class="btn">
								<el-button size="small" type="primary" @click="funApply(0)"
									v-if="zt == 0 || zt == 1">已有{{title}}，立即绑定</el-button>
								<el-button size="small" type="primary" @click="funApplygzh(0)"
									v-else-if="zt== 3">已有{{title}}，立即绑定</el-button>
								<el-button size="small" type="primary" @click="funApplyqw(0)"
									v-else-if="zt== 4">已有{{title}}，立即绑定</el-button>
							</div>
							<div class="memo">
								<p>为保证所有功能的正常使用</p>
								<p>授权时请把所有权限统一授权给大新</p>
							</div>
						</div>
						<div class="item">
							<div class="img">
								<img src="../../../../../../assets/logo.png" />
							</div>
							<div class="btn">
								<el-button size="small" @click="funApply(1)"
									v-if="zt == 0 || zt == 1">没有{{title}}，立即申请</el-button>
								<el-button size="small" @click="funApplygzh(1)"
									v-if="zt == 3">没有{{title}}，立即申请</el-button>
								<el-button size="small" @click="funApplyqw(1)"
									v-else-if="zt== 4">没有{{title}}，立即申请</el-button>
							</div>
							<div class="memo">
								<p>请在微信公众平台申请新的{{title}}</p>
								<p>成功后再到大新进行绑定</p>
							</div>
						</div>
					</div>
					<div class="footer">
						<div class="item">
							<div class="img">
								<img src="../../../../../../assets/logo.png" />
							</div>
							<div class="op">
								{{title}}绑定教程
							</div>
						</div>
						<div class="item">
							<div class="img">
								<img src="../../../../../../assets/logo.png" />
							</div>
							<div class="op">
								{{title}}绑定注意事项
							</div>
						</div>
						<div class="item">
							<div class="img">
								<img src="../../../../../../assets/logo.png" />
							</div>
							<div class="op">
								不同{{title}}类型的功能区别
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--微信:添加{{title}}-->
			<wxxcx ref="wxxcx" @funCloseReslut="funCloseReslut"></wxxcx>
			<!--微信:注册{{title}}-->
			<wxxcxreg ref="wxxcxreg" @funCloseReslut="funCloseReslut"></wxxcxreg>
			<!--微信:添加企业微信-->
			<wxqywx ref="wxqywx" @funCloseReslut="funCloseReslut"></wxqywx>
			<!--微信:添加公众号-->
			<wxgzh ref="wxgzh" @funCloseReslut="funCloseReslut"></wxgzh>
		</el-card>

	</div>
</template>

<script>
	import wxxcx from "@/components/customer/home/wxxcx.vue"
	import wxgzh from "@/components/customer/home/wxgzh.vue"
	import wxxcxreg from "@/components/customer/home/wxxcxreg.vue"
	import wxqywx from "@/components/customer/home/wxqywx.vue"
	export default {
		// 注册组件
		components: {
			wxxcx,
			wxgzh,
			wxxcxreg,
			wxqywx,
		},
		data() {
			return {
				zt: 4,
				title: '',
				boxShow: true,
				wxxcxShow: false
			}
		},
		mounted() {
			this.title = '企业微信'
		},
		methods: {
			funApply(zt) {
				this.boxShow = false
				if(zt == 0){
					this.$refs["wxxcx"].funShow("创建微信小程序")
				}else{
					this.$refs["wxxcxreg"].funShow("注册微信小程序")
				}
			},
			funApplygzh(zt) {
				this.boxShow = false
				if(zt == 0){
					this.$refs["wxgzh"].funShow("创建公众号")
				}else{
				}
			},
			funApplyqw(zt) {
				this.boxShow = false
				if(zt == 0){
					this.$refs["wxqywx"].funShow("创建企业微信")
				}else{
				}
			},
			funCloseReslut(param) {
				this.boxShow = true
				switch (param.zt) {
					case 'wxxcx':
						this.$refs["wxxcx"].funClose()
						break;
					case 'wxxcxreg':
						this.$refs["wxxcxreg"].funClose()
						break;
					case 'wxqywx':
						this.$refs["wxqywx"].funClose()
						break;
					default:
						break;
				}
			}
		}
	}
</script>

<style scoped>
	.regcontent{
		width: 80%;
		margin: 20px auto;
	}
	.main {
		display: flex;
		flex-direction: column;
	}

	.main .title {
		margin: 20px 0;
		font-size: 16px;
		font-weight: 800;
		text-align: center;
	}

	.main .box {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.main .box .item {
		margin: 0 10px;
		width: 45%;
		padding: 20px 0;
		display: flex;
		border-radius: 5px;
		align-items: center;
		flex-direction: column;
		border: 1px solid #f0f0f0;
	}

	.main .box .memo {
		color: #C8C8CD;
		text-align: center;
	}

	.main .box .item .img {
		width: 80px;
		height: 80px;
	}

	.main .box .item .btn {
		margin: 20px 0;
	}

	.main .box .item .img img {
		width: 100%;
		height: 100%;
	}

	.main .footer {
		border-radius: 5px;
		border: 1px solid #f0f0f0;
		margin: 20px 35px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main .footer .item {
		padding: 25px;
		display: flex;
		align-items: center;
	}

	.main .footer .op {
		color: #6C6B71;
		margin-left: 5px;
	}

	.main .footer .item .img {
		width: 30px;
		height: 30px;
	}

	.main .footer .item .img img {
		width: 100%;
		height: 100%;
	}
</style>