<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix" style="display: flex;">
				<div  style="flex-grow: 1;">用户模板</div>
				
				<el-input type="text" style="width: 200px;margin-right: 10px;" placeholder="请输入企业号" v-model="formInline.mchno" size="medium" class="mr"></el-input>
				<el-button size="small" type="primary" @click.stop="Search">搜索</el-button>
			</div>
			<!--列表-->
			<!-- <el-table size="small" :data="tempData" highlight-current-row v-loading="loading" :border="false"
				element-loading-text="拼命加载中" style="width: 100%;">
				<el-table-column align="center" width="180" label="模板图片">
					<template slot-scope="scope">
					<img :src="scope.row.logo" style="width: 80px;height: auto;"/>
					</template>
				</el-table-column>
				<el-table-column align="center" sortable prop="tmpname" label="模板名称" width="180">
				</el-table-column>
				<el-table-column align="center" sortable prop="mchNo" label="企业号" width="180">
				</el-table-column>
				<el-table-column align="center" sortable prop="mchType" label="企业类型" width="180">
				</el-table-column>
				<el-table-column align="center" sortable prop="mchshopId" label="企业门店号" width="180">
				</el-table-column>
				<el-table-column label="操作" min-width="200">
					<template slot-scope="scope">
						<el-button size="mini" @click="tempEdit(scope.$index, scope.row)">切换风格</el-button>
						<el-button size="small" type="primary" @click="funlinktemp(scope.$index, scope.row)">切换模板</el-button>
					</template>
				</el-table-column>
			</el-table> -->
			<!-- <canvas id="QRCode_header" style="width: 100px; height: 100px;"></canvas> -->
			<div class="list2">
				<div class="item" v-for="(item,index) in tempData" @mouseover="funshowqr(item)" :key="index">
					<div class="mask" v-if="item.ismask"></div>
					<div class="ewmbox" v-if="item.ismask">
						<img class="imgqr" :src="item.imgqr" alt="">
					</div> 
					<div class="logo"> <img :src="item.logo" /> </div>
					<!-- <img class="imgqr" v-if="item.ismask" :src="item.imgqr" alt=""> -->
					<div class="title">{{ item.tmpname }}</div>
					<div class="title">{{ item.createDate }}</div>
					<div class="title">{{item.tmpid}}</div>
					
					<div class="edit">
						<el-dropdown @command="funMenu($event,item)">
							<span class="el-dropdown-link">
								<i class="el-icon-s-operation"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="'infomb'">详情</el-dropdown-item>
								<el-dropdown-item :command="'importtemp'">导入模板</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
			</div>
			<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
		</el-card>
		<!-- 风格界面 -->
		<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
			<div class="main">
				<div class="box" v-for="(item,index) in styleList" :key="index">
					<div :style="{background:item.page_bg_color}" :class="item.style_id == style_id?'curr' : 'cusm'"
						class="item_cont">
						<div class="item" v-if="item.style_id == 0" @click="funCustom(item)">
							自定义
						</div>
						<div class="item" v-else :style="{background:item.subject1_bg_color}"
							@click="funselStyle(item)">
						</div>
					</div>


				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='editFormVisible=false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title"
					@click="submitForm('editForm')">保存
				</el-button>
			</div>
		</el-dialog>
		<!-- 导入界面 -->
		<el-dialog :title="linktitle" :visible.sync="linkVisible" width="80%" @click='linkVisible=false'>
			<div class="maintwo">
				<div class="box" v-for="(item,index) in tempuserData" :key="index" @click="funtempOk(item)">
					<div class="img">
						<img :src="item.logo" />
					</div>
					<div class="">
						{{item.tmpname}}
					</div>
					<div class="bg">
						<span>选择此模板</span>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='linkVisible=false'>关闭</el-button>
			</div>
		</el-dialog>
		<!-- 详情界面 -->
		<el-dialog title="模版详情" :visible.sync="InfoVisible" width="40%" @click='InfoVisible = false'>
			<div class="maintwo">
				<div class="preview">
					<div class="ewmbox2" >
						<img class="imgqr" :src="mbInfo.imgqr" alt="">
					</div>
					<div class="sjx"></div>
					<div class="tip">微信扫码预览</div>
					<div class="tip">{{mbInfo.mchno}}</div>
					<div class="tip">D{{mbInfo.tmpname}}</div>
					<div class="tip">D{{mbInfo.tmpid}}</div>
					
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='InfoVisible = false'>关闭</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import QRCode from "qrcode";
	import styleApi from '../../../../api/cus/style.js'
	import tempuserApi from '../../../../api/sys/tempuser.js'
	import tempsysApi from '../../../../api/sys/tempsys.js'
	import Pagination from "../../../../components/Pagination.vue"
	import designTemplataApi from "../../../../api/cus/designtmplate";
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "",
				loading: false,
				editForm: {},
				editFormVisible: false,
				linktitle: "",
				linkVisible: false,
				InfoVisible: false,
				tempData: [{
					imgqr: "",
					ismask: false
				}],
				styleList: [],
				style_id: "",
				tmpid: "",
				tempuserData: [],
				mbInfo: {},
				imgqr: '',
				showewm: false,
				formInline: {
					mchno: "",
					pageno: 1,
					pagesize: 10,
					tmpname:'',
					user_tmpid:''
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
			}
		},
		mounted() {
			this.init(this.formInline)
		},
		methods: {
			Search(){
				this.init(this.formInline)
			},
			init(formInline) {
				tempuserApi.list({
					pagesize:formInline.pagesize,
					pageno:formInline.pageno,
					mchno:formInline.mchno,
					tmpname:formInline.tmpname,
					user_tmpid:formInline.user_tmpid
				}, res => {
					console.log(res, 'tempuserApi.res');
					if (res.code == 200) {
						//this.tempData = res.data
						
						let mydata = []
						 res.data.list.forEach(item => {

							let qrUrl =
								`http://wxapp.91erp.net/wxty1/?fun=ty&vp=viewtemplatepage&mch=${item.mchno}&fdid=${item.mchshopid}&tcls=app&tid=${item.tmpid}&t=index`
							if(item.mchType == 'you98'){
								qrUrl = `http://wxapp.91erp.net/yeying/?fun=ty&vp=viewtemplatepage&mch=${item.mchno}&fdid=${item.mchshopid}&tcls=app&tid=${item.tmpid}&t=index`
							}
							let imgqr = 'https://wxapi.dxerp.net/open/qrimg.aspx?qr=' + encodeURIComponent(qrUrl) + '&t=1&w=240&h=240'
						
							mydata.push({
								logo:item.logo,
								ismask:false,
								imgqr:imgqr, 
								tmpname:item.tmpname ,
								createDate:item.createDate,
								tmpid:item.tmpid,
								tmpname:item.tmpname
							})
						})
						this.tempData  = mydata;
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
						console.log(this.tempData, 'tempdata');
					}
				})
			},
			getStyle() {
				styleApi.list({}, res => {
					console.log(res, 'styleApi.res');
					if (res.code == 200) {
						res.data.push({
							style_id: 0,
							style_name: '自定义'
						})
						this.styleList = res.data
					}
				})
			},
			funselStyle(item) {
				let param = {
					style_id: item.style_id,
					tmpid: this.tmpid
				}
				console.log(param, 'param.funselStyle');
				tempuserApi.editstyle(param, res => {
					console.log(res, 'tempuserApi.res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.style_id = item.style_id
						this.$message({
							type: "success",
							message: "编辑成功"
						})
					}
				})
			},
			tempEdit(index, row) {
				this.title = "切换风格"
				this.editFormVisible = true
				console.log(row, 'row');
				this.tmpid = row.tmpid
				this.getStyle()
			},
			funlinktemp(index, row) {
				console.log(row, 'row');
				this.linktitle = "切换模板"
				this.linkVisible = true
				this.tmpid = row.tmpid
				tempsysApi.list({}, res => {
					console.log(res, 'list.res');
					if (res.code == 200) {
						this.tempuserData = res.data.list
					}
				})
			},
			funtempOk(item) {
				console.log(item, 'item');
				let param = {
					sys_tempid: item.tmpid,
					tmpName: item.tmpname,
					tmpid: this.tmpid
				}
				console.log(param, 'changetemplate.param');
				tempsysApi.changetemplate(param, res => {
					console.log(res, 'changetemplate.res');
					this.linkVisible = false
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "新增成功"
						})
						this.init(this.formInline)
					} else {
						this.$message({
							type: "error",
							message: "新增失败"
						})
					}
				})
			},
			funMenu(type, item) {
				console.log(item, 'item');
				switch (type) {
					case 'infomb':
						this.funInfo(item)
						break;
					case 'usemb':
						this.funUse(item.sysid)
						break;
					case 'delmb':
						this.fundel(item.sysid)
						break;
					case 'importtemp':
						this.importtemp(item)
						break;
					default:
						break;
				}
			},
			importtemp(item) {
				console.log(item, 'item');
				let param = {
					logo: item.logo,
					tmpName: item.tmpname,
					tmpid: item.tmpid
				}
				tempsysApi.fromuser(param, res => {
					console.log(res, 'fromuser.res');
					this.linkVisible = false
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "新增成功"
						})
						this.init(this.formInline)
					} else {
						this.$message({
							type: "error",
							message: "新增失败"
						})
					}
				})
			},
			hideitem() {
				this.InfoVisible = false
			},
			funshowqr(item) {
				console.log(item, 'funshowqr.item');
				this.tempData.forEach(f => {
					f.ismask = false
				})
				item.ismask = true
				
				console.log(this.tempData, 'this.tempData');
			},
			funInfo(item) {
				console.log(item,'item');
				this.InfoVisible = true
				this.mbInfo = item
				
			},
			funUse(tmpid) {
				let param = {
					user_tempid: tmpid
				}
				designTemplataApi.usemb(param, res => {
					console.log(res, 'delmb.res1203');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: '模版使用成功'
						})
						this.init(this.formInline);
					}
				})
			},
			fundel(sysid) {
				this.$confirm('是否删除此模版, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						sysid: sysid
					}
					designTemplataApi.delmb(param, res => {
						console.log(res, 'delmb.res1203');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: '删除模版成功'
							})
							this.init(this.formInline)
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					})
				}).catch(() => {

				});

			},
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.init(this.formInline)
			},
		}
	}
</script>

<style lang="scss" scoped>
	/* .clearfix{
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.main {
		display: flex;
		flex-wrap: wrap;
		overflow: auto;
	}
	
	.box {
		overflow: auto;
		display: flex;
		flex-wrap: wrap;
	}
	.box .item {
		cursor: pointer;
		width: 50px;
		height: 50px;
		font-size: 10px;
		line-height: 50px;
		text-align: center;
		border-radius: 100%;
	}
	
	.el-input {
		width: 300px !important;
	}
	
	.curr {
		border: 3px solid #55aaff;
	}
	
	.cusm {
		border: 3px solid #ccc;
	}
	
	.maintwo{
		height: 500px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		overflow-y: auto;
	}
	.maintwo .box {
		border: 2px solid #000;
		border-radius: 10px;
		margin: 10px;
		padding: 10px;
		height: 375px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}
	.maintwo .box .bg{
		display: none;
	}
	.maintwo .box:hover .bg{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.6);
	}
	.maintwo .box .img{
		width: 150px;
		height: auto;
	}
	.maintwo .box .img img{
		width: 100%;
		height: 100%;
	} */
	.list2 {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
		height: 750px;
		overflow: auto;
	}

	.list2 .edit {
		position: absolute;
		right: 0;
		top: 0;
		z-index:99;
		padding-right: 10px;
	}

	.ewmbox {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 99;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	}

	.imgqr {
		width: 100px;
		height: 100px;

	}

	.mask {
		width: 100%;
		height: 100%;
		position: absolute;
		background-color: #000;
		top: 0;
		left: 0;
		opacity: 0.5;
		z-index: 10;

	}

	.list2 .item {
		text-align: center;
		box-shadow: 0 2px 6px #ccc;
		padding: 20px;
		margin: 20px 20px 0 0;
		position: relative;

		&:hover {
			box-shadow: 0 2px 6px #2865e0;
			border-radius: 20px;
		}

		.logo {
			width: 200px;
			height: 350px;
			overflow: hidden;

			img {
				width: 100%;
			}
		}
	}

	.preview {
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
</style>