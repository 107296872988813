<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="12"></leftnav>
			<div class="rightbox" >
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>成员管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>成员管理<span style="color:#9a9b9d;">（需在大新客户关系管理应用内设置可见范围方可添加成员）</span></span>
						<div class="" style="float:right">
							<el-button type="primary" size="small" @click="funAddPerson">新增成员</el-button>
						</div>
					</div>
					<div class="main">
						<!--列表-->
						<el-table size="small" :data="personData" highlight-current-row v-loading="loading"
							:border="false" element-loading-text="拼命加载中" style="width: 100%;">
							<el-table-column align="center" prop="manno" label="员工账号" width="120">
							</el-table-column>
							<el-table-column align="center" prop="manname" label="员工姓名" width="120">
							</el-table-column>
							<el-table-column align="center" prop="isbaseauth" label="授权状态" width="160">
								<template v-slot="scope">
									<span :class="'auth' + scope.row.isbaseauth">
										{{scope.row.isbaseauth == 1 ? '基础授权' :'--'}}
									</span>/
									<span :class="'auth' + scope.row.islinkauth">
										{{scope.row.islinkauth == 1 ? '微信授权' :'--'}}
									</span>
								</template>
							</el-table-column>

							<el-table-column align="center" prop="tel" label="员工手机号" width="180">
							</el-table-column>
							<el-table-column label="操作" min-width="450">
								<template slot-scope="scope">
									<el-button size="mini"
										@click="handleauth(scope.$index, scope.row,'base')">基础授权</el-button>
									<el-button size="mini"
										@click="handleauth(scope.$index, scope.row,'link')">微信互通</el-button>
									<el-button size="mini"
										@click="handleauth(scope.$index, scope.row,'link')">转移授权</el-button>
									<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
									<el-button size="mini" type="danger"
										@click="deleteperson(scope.$index, scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
				<el-dialog :title="title" :visible.sync="editFormVisible" :width="showmenu == true ? '30%' : '90%'" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="员工帐号" prop="manno" v-if="editForm.id != undefined && editForm.id.length > 0">
							<el-input disabled size="small" v-model="editForm.manno" auto-complete="off"
								placeholder="请输入员工帐号"></el-input>
						</el-form-item>
						<el-form-item label="员工帐号" prop="manno" v-else>
							<el-input  size="small" v-model="editForm.manno" auto-complete="off"
								placeholder="请输入员工帐号"></el-input>
						</el-form-item>
						<el-form-item label="员工手机号" prop="tel">
							<el-input size="small" v-model="editForm.tel" auto-complete="off"
								placeholder="请输入员工手机号"></el-input>
						</el-form-item>
						<el-form-item label="员工姓名" prop="manname">
							<el-input size="small" v-model="editForm.manname" auto-complete="off"
								placeholder="请输入员工姓名"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="submitForm()">确定
						</el-button>
					</div>
				</el-dialog>
				<el-dialog :title="authtitle" :visible.sync="authVisible" width="40%" @click='authVisible=false'>
					<div>员工姓名:{{autheditForm.manname}}</div>
					<div style="margin: 20px 0;">授权码:{{autheditForm.authcode}}</div>
					<div >选择授权码</div>
					
					<el-table ref="multipleTable" :data="authcodelist" tooltip-effect="dark" @row-click="funselcode"
						:row-class-name="tableRowClassName" style="width: 100%">
						<el-table-column align="center" prop="mxid" label="明细ID" width="120">
						</el-table-column>
						<el-table-column align="center" prop="licenseno" label="授权码" width="260">
						</el-table-column>
						<el-table-column align="center" prop="licensetype" label="授权类型" width="120">
							<template v-slot="scope">
								<span>
									{{scope.row.licensetype == 1 ? '基础授权' :'微信互通'}}
								</span>
							</template>
						</el-table-column>
						<el-table-column align="center" prop="manmc" label="员工账号" width="120">
						</el-table-column>
						<el-table-column align="center" prop="statemc" label="授权状态" width="120">

						</el-table-column>
					</el-table>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="funAuthOk()">确定
						</el-button>
					</div>
				</el-dialog>
			</div>
		</div>

	</div>
</template>

<script>
	import personApi from '../../../../../../api/cus/person.js'
	import license from '../../../../../../api/cus/license.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				authtitle: '',
				loading: false,
				editForm: {
					manname: "",
					manno: "",
					tel: "",
					id: "",
					manno: '',
				},
				licenseData: [],
				autheditForm: {
					id: '', //人员编号
					manname: '',
					order_mxid: "", //明细id
					authcode: '', //授权码
				},
				authVisible: false,
				editFormVisible: false,
				personData: [],
				seltype: '',
				showmenu:true
			}
		},
		mounted() {
			let screenWidth = window.innerWidth
			console.log(screenWidth,'this.screenWidth');
			if(screenWidth <= 750){
				this.showmenu = false
			}else{
				this.showmenu = true
			}
			this.init()
		},
		computed: {
			authcodelist() {

				if (this.seltype == 'base') {
					let templist = this.licenseData.filter(f => {
						return f.licensetype == 1
					})
					if (templist != undefined) {
						return templist
					} else {
						return []
					}

				} else {
					let templist = this.licenseData.filter(f => {
						return f.licensetype == 2
					})
					if (templist != undefined) {
						return templist
					} else {
						return []
					}

				}
			}
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist(this.formInline)
			},
			getlist(page) {
				let param = {
					mpid: this.mpid,
					pageno: 1,
					pagesize: 100,
				}
				this.loading = true
				console.log(param, 'param');
				personApi.list(param, res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.loading = false
						this.personData = res.data.list
					}
				})
			},
			handleauth(index, row, type) {
				console.log(row, 'row');
				this.autheditForm.id = row.id,
					this.autheditForm.manname = row.manname;
				this.autheditForm.authcode = '';
				this.autheditForm.order_mxid = '';

				this.authVisible = true
				this.seltype = type
				if (type == 'base') {
					this.authtitle = '基础授权'
				} else {
					this.authtitle = '微信互通'
				}
				this.getlicense()
			},
			getlicense() {
				license.getall({
					mpid: this.mpid
				}, res => {
					console.log(res, 'res.getlicense');
					if (res.code == 200) {
						this.licenseData = res.data
					}
				})
			},
			handleEdit(index, row) {
				this.title = "成员编辑"
				this.editForm = row
				this.editFormVisible = true
			},
			funAddPerson() {
				this.editForm.id = ''
				this.editForm.mpid = ''
				this.editForm.tel = ''
				this.editForm.manname = ''
				this.editForm.manno = ''
				this.editFormVisible = true
			},
			submitForm() {
				if (this.editForm.manname.length <= 1) {
					this.$message({
						type: 'error',
						message: '请输入员工姓名!'
					})
					return
				}
				if (this.editForm.tel.length != 11) {
					this.$message({
						type: 'error',
						message: '请输入手机号!'
					})
					return
				}
				let param = {
					mpid: this.mpid,
					id: this.editForm.id,
					tel: this.editForm.tel,
					manname: this.editForm.manname,
					manno: this.editForm.manno,
				}
				personApi.manadd(param, res => {
					console.log(res, 'res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '添加成功!'
						})
						this.getlist()
					} else {
						this.$message({
							type: 'error',
							message: '添加失败!' + res.msg
						})
					}
				})
			},
			funAuthOk() {
				let param = {
					mpid: this.mpid,
					order_mxid: this.autheditForm.order_mxid,
					manid: this.autheditForm.id,
				}
				console.log(param, 'param');
				license.active(param, res => {
					console.log(res, 'res');
					this.authVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getlist()
					} else if (res.code == -1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: '添加失败!'
						})
					}
				})
			},
			funselcode(row) {
				console.log(row.licenseno, 'row.licenseno');
				this.autheditForm.order_mxid = row.mxid
				this.autheditForm.authcode = row.licenseno

				console.log(this.autheditForm.order_mxid, 'ordermx');
			},
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (row.mxid === this.autheditForm.order_mxid) {
					return 'rowselect';
				}
				return '';
			},
			deleteperson(index, item) {
				let param = {
					id: item.id
				}
				personApi.mandel(param, res => {
					console.log(res, 'deleteperson.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getlist()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},

		}
	}
</script>

<style scoped>
	.page {
		background: #F5F7FA;

	}

	.box-card {
		width: 1030px;
	}

	.pagecontent {
		display: flex;
		overflow: auto;
		height: calc(100vh - 81px);
	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
		margin: 0 auto;
	}

	.auth0 {
		color: #ff0000;
	}

	.auth1 {
		color: #00aa00;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
			
		}
	}
</style>
<style>
	.rowselect {
		color: #ff5500;
	}
</style>