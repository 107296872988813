<template>
  <div id="video">
    <!-- 视频折叠面板 -->
    <div class="box">
      <el-collapse v-model="activeName">
        <el-collapse-item title="内容设置" name="1">
          <div class="">
            <span> 视频： </span>
            <div>
              <el-button size="small" @click="choice(1)">选择视频</el-button>
              <el-button size="small" @click="choice(2)">视频地址</el-button>
            </div>
            <div class="upload" v-show="show">
              <div>
                <div v-if="list.data.length == 0">
                  <el-upload
                    action="http://192.168.3.5:36/api.php/user/v1/Attachment/upload"
                    class="upload-demo"
                    ref="upload"
                    :on-success="success"
                    :show-file-list="false"
                    :before-upload="beforeAvatarUpload"
                    :data="shuju"
                  >
                    <div class="upbox">
                      <i class="el-icon-plus"></i>
                    </div>
                  </el-upload>
                </div>
                <div v-else>
                  <video
                    width="100%"
                    height="240"
                    controls
                    :src="list.data[0]"
                  ></video>
                </div>
              </div>
            </div>
            <div class="http" v-show="!show">
              <el-input
                v-model="list.options.url"
                placeholder="请输入视频地址"
              ></el-input>
              <div class="video">
                <video
                  width="100%"
                  height="240"
                  controls
                  :src="list.options.url"
                ></video>
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="播放设置" name="2">
          <div class="list">
            <div class="title">
              <span>进度条 </span>
              <span>{{ list.options.show ? "显示" : "不显示" }} </span>
            </div>
            <div class="btn">
              <el-switch
                v-model="list.options.show"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
          </div>
          <div class="list">
            <div class="title">
              <span>自动播放 </span>
              <span>{{ list.options.show1 ? "已关闭" : "已开启" }} </span>
            </div>
            <div class="btn">
              <el-switch
                v-model="list.options.show1"
                active-color="#13ce66"
                inactive-color="#ff4949"
              >
              </el-switch>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      activeName: "1",
      shuju: {
        type: "1",
        group: "0",
      },
      list: [],
      show: true,
    };
  },

  created() {
    this.list = this.data;
  },

  methods: {
    //   上传视频
    beforeAvatarUpload(files) {},
    // 上传成功后
    success(res) {
      this.list.data.push(res.data.file);
      this.$forceUpdate();
    },
    // 选择上传视频的方法
    choice(event) {
      // http://r3oqrdk1e.hb-bkt.clouddn.com/shop_file/c4ca4238a0b923820dcc509a6f75849b/8f841202112241503571882.mp4
      if (event == 1) {
        this.show = true;
      } else {
        this.show = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
#video {
  padding: 0 20px;
}
.el-collapse-item__header {
  padding: 0 20px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.list1 {
  margin-bottom: 20px;
}
.title {
  font-size: 14px;

  :first-child {
    padding-right: 10px;

    color: #969799;
  }
}
.btn {
  display: flex;
  align-items: center;
  :first-child {
  }
  .reset {
    margin-right: 10px;
  }
}
.upbox {
  width: 100px;
  height: 100px;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    font-size: 36px;
    color: #666;
    display: block;
  }
}
.http {
  width: 100%;
  margin: 20px 0;
}
.video {
  margin: 20px 0;
}
.upload{
margin-top: 20px;
}
</style>