var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"img"},[_c('div',{staticClass:"imgcont",style:({
         'margin-left':_vm.marginpage,
          'margin-right':_vm.marginpage,
		  'width' : _vm.width,
		  'height' : _vm.height
      })},[(_vm.list.options  && _vm.list.options.bgimg && _vm.list.options.bgimg.length > 0)?_c('div',{staticClass:"bgbox"},[_c('img',{staticClass:"bgimg",attrs:{"src":(_vm.list.options.bgimg && _vm.list.options.bgimg.length > 0) ? _vm.list.options.bgimg : ''}})]):_vm._e(),_c('div',{staticClass:"list"},_vm._l((_vm.list.data),function(item,index){return _c('dragBox',{key:item.cid,attrs:{"data":item,"index":index,"allowdel":0},on:{"deleteHandle":_vm.deleteHandle,"selectHandler":_vm.selectHandler}},[(item.isdiy != undefined && item.isdiy == 1)?_c('div',{style:({
						 height:(item.height - 2) + 'px',
						 width:(item.width -2) + 'px',
						 top:item.top + 'px',
						 left:item.left + 'px',
						})},[_c('img',{staticClass:"deskimg",attrs:{"src":item.use.bgimg}})]):_c('div',[(_vm.list.options.use.bgimg != undefined && _vm.list.options.use.bgimg.length > 0)?_c('div',{style:({
						 height:(item.height - 2) + 'px',
						 width:(item.width -2) + 'px',
						 top:item.top + 'px',
						 left:item.left + 'px',
						})},[_c('img',{staticClass:"deskimg",attrs:{"src":_vm.list.options.use.bgimg}}),_c('div',{staticClass:"deskimgmc",style:({
								height:(item.height - 2) + 'px',
								width:(item.width -2) + 'px',
								'color':_vm.list.options.use.titlecolor,
							})},[_vm._v(_vm._s(_vm.list.options.showtitle == 1 ? item.title : ''))])]):_c('div',{staticClass:"item",style:({
											 height:(item.height - 2) + 'px',
											 width:(item.width -2) + 'px',
											 top:item.top + 'px',
											 left:item.left + 'px',
											 'border-radius':item.borderradius +'px',
											 'color':_vm.list.options.use.titlecolor,
											 'background':_vm.list.options.use.bgcolor,
											})},[_vm._v(" "+_vm._s(_vm.list.options.showtitle == 1 ? item.title : '')+" ")])])])}),1)])])}
var staticRenderFns = []

export { render, staticRenderFns }