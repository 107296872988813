<template>
	<div class="menu_img_title_page" >
		<div class="menu_img_title" >
			<div class="imgcont"  :style="{
         'margin-left':marginpage,
          'margin-right':marginpage,
					'margin-top':margintop,
          'margin-bottom':marginbottom,
          'padding-top':paddingtop,
          'padding-bottom':paddingbottom,
					'width' : width && width == 0 ? '100%' : width,
					'height' : height,
					'border-radius':borderradius,
					'background':list.options && list.options.stylestate && list.options.stylestate == 'T' ? styleview.text2_bg_color:bgcolor
					}">
					<!--
					'background':list.options.stylestate && list.options.stylestate == 'F' ? styleview.text2_bg_color:bgcolor,-->
					<!--list.data.stylestate && list.data.stylestate == 'F' ? : -->
					<!-- 
					'background-image':`url('list.options.bgimg')` -->
					<!-- <div class="imgbg" v-if="list.options && list.options.bgimg && list.options.bgimg.length > 0">
						<img :src="(list.options.bgimg && list.options.bgimg.length > 0) ? list.options.bgimg : ''" :style="{
							'width' : width,
					'height' : height
						}" />
					</div>
					 -->
					
				<div 	v-for="(item, index) in list.data" :key="index" class="item" 
				 :id="'a'+index"
				 :style="{
						'border':'50px',
						'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
					}" @click="funItem(index)">
					<div :style="{'background':styleview.subject1_bg_color,'border-radius':'50%'}" v-if="selIndex == index">
						<el-image style="display:block"
						:style="{
							'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
						}" :src="item.url2" v-show="list.data && list.data.length > 0" ></el-image>
					</div>
					<div v-else>
						<el-image style="display:block"
						:style="{
							'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
						}" :src="item.url" v-show="list.data && list.data.length > 0" ></el-image>
					</div>
						
						
						
						
						<div class="title" :style="{
							'margin-top':item.margintop + 'px',
							'color':list.options && list.options.stylestate && list.options.stylestate == 'T' ?  styleview.text2_txt_color: list.options.titlecolor,
							fontSize: size,
          fontWeight: bold,
						}">
							{{item.title}}
						</div>
					</div>

			
				<div class="empty" v-show="list.data && list.data.length == 0">
					<el-empty description="此区域放置图片"></el-empty>
				</div>
			</div>
			<!-- <el-image style="width: 100%" :src="url"></el-image> -->
		</div>
		
	</div>
</template>

<script>


	export default {
		name: "foot_nav_cube",
		components: {
			
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
			styleview:{
				type: Object,
				default: () => {},
			}
		},
		data() {
			return {
				url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				list: "",
				mystyle:"",
				selIndex:0
			};
		},

		mounted() {
			this.list = this.data;
		},
		watch:{
			deep:true,
			data(o,v){
				this.list = this.data
			},
		},
		computed: {
			bgcolor(){
				if (this.list && this.list.options) {
					if (this.list.options.bgcolor && this.list.options.bgcolor.length > 0)
						return this.list.options.bgcolor;
				}
				return '#fffff';
			},

			height(){
				if (this.list && this.list.options) {
					if (this.list.options.height && this.list.options.height > 0)
						return (this.list.options.height / 2) + 'px';
				}
			},
			width(){
				
				if (this.list && this.list.options) {
					if (this.list.options.width && this.list.options.width > 0)
						return (this.list.options.width / 2 - this.list.options.marginpage * 2) + 'px';
				}else{
					return '100%'
				}
			},
			margintop() {
				if (this.list && this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + 'px';
				}
			},
			marginbottom() {
				if (this.list && this.list.options) {
					if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
						return this.list.options.marginbottom + 'px';
				}
			},
			paddingtop() {
				if (this.list && this.list.options) {
					if (this.list.options.paddingtop && this.list.options.paddingtop > 0)
						return this.list.options.paddingtop + 'px';
				}
			},
			paddingbottom() {
				if (this.list && this.list.options) {
					if (this.list.options.paddingbottom && this.list.options.paddingbottom > 0)
						return this.list.options.paddingbottom + 'px';
				}
			},
			marginpage() {
				if (this.list && this.list.options) {
					if (this.list.options.marginpage && this.list.options.marginpage > 0)
						return this.list.options.marginpage + 'px';
				}
			},
			borderradius() {
				if (this.list && this.list.options) {
					if (this.list.options.borderradius && this.list.options.borderradius > 0)
						return this.list.options.borderradius + 'px';
				}
			},
			//  标题字体大小
			size() {
      if (this.list.options.size == 3) {
        return "12px";
      } else if (this.list.options.size == 2) {
        return "14px";
      } else {
        return "16px";
      }
    },
			// 标题加粗
			bold() {
      if (this.list.options.bold == 2) {
        return "900";
      } else {
        return "300";
      }
    },
		},

		methods: {
			funItem(index){
				this.selIndex = index
			}
		},
	};
</script>

<style lang="less" scoped>
	// .menu_img_title_page{
	// 	position: absolute;
	// 	z-index:999;
	// 	left: 0;
	// 	bottom: 20px;
	// 	width: 100%;
	// 	background-color: #fff;
	// }
	.menu_img_title {
		// position: absolute;
		// bottom: 0;
		// width: 400px;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between ;
		
		.imgcont {
			overflow: hidden;
		position: relative;
		display: flex;
		justify-content: space-around;
		
		
		.item{
			// background-image:var(--bgimg) !important;
			// background-size: 100%;
			// background-position: center;
		}
	}

	}


	.empty {
		width: 400px;
		// text-align: center;
		// display: flex;
		// flex-wrap: wrap;
	}
	.title{
		white-space: nowrap;
		height: 20px;
		line-height: 20px;
	}
	// .imgbg{
	// 	position: absolute;
	// 	left: 0;
	// 	bottom: 0;
	// }
</style>