<template>
  <div>
    <video :src="list.data[0]||list.options.url" width="100%" height="240"></video>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.list = this.data;
  },
  data() {
    return {
      list: "",
    };
  },

  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
</style>