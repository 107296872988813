<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="14"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>小程序配置</el-breadcrumb-item>
				</el-breadcrumb>
				
				<div class="box-card">
					<h4>扫普通链接二维码打开小程序</h4>
					<table class="ewmtitle">
						<th style="width: 35%;">二维码地址</th>
						<th style="width: 25%;">小程序路径</th>
						<th style="width: 10%;">测试范围	</th>
						<th style="width: 10%;">全网发布</th>
						<th style="width: 20%;">操作</th>
					</table>
					<table class="ewmtitle">
						<th style="width: 35%;">二维码地址</th>
						<th style="width: 25%;">小程序路径</th>
						<th style="width: 10%;">测试范围	</th>
						<th style="width: 10%;">全网发布</th>
						<th style="width: 20%;">操作</th>
					</table>
				</div>
				<!-- 编辑界面 -->
				
			</div>
		</div>
		
	</div>
</template>

<script>
	import webApi from '../../../../../../api/cus/weburl.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				list:[]
			}
		},
		mounted() {
			this.init()
			
			
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
					this.getlist()
				} else {
					this.$router.push("/");
				}
				
			},
			getlist(){
				webApi.getqrcodrulelist(this.mpid,res=>{
					console.log(res,'res');
					if(res.code == 200){
						this.$message({
							message:res.msg,
							type:'success'
						})
						this.list = res.data
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.box-card {
		background-color: #fff;
		border-radius: 4px;
		box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
		padding: 30px 30px;
	}

	.box-card .table {
		color: #9a9a9a;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.box-card .table .tb-mc {
		width: 130px;
	}

	.box-card .table .thead {
		display: flex;
		align-items: center;
	}

	.box-card .table .tbody {
		width: 100%;
		padding: 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #f0f0f0;
	}

	.box-card .table .tbody .titem {
		text-align: left;
	}

	.box-card .table .tbody .titem:nth-child(1) {
		color: #353535;
		width: 16.4em;
	}

	.box-card .table .tbody .titem:nth-child(2) {
		width: 22.4em;
	}

	.box-card .table .tbody .titem:nth-child(3) {
		width: 8.4em;
	}

	.box-card .table .tbody .titem img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.box-card .box-item {
		margin: 10px 0;
		display: flex;
		align-items: center;
	}

	.box-card .box-item .title {
		width: 150px;
	}

	.box-card .box-item>>>.el-input--small {
		margin-left: 10px;
		width: 500px;
	}

	.iptmsg {
		color: #737373;
		margin-left: 15px;
		font-size: 10px;
	}
	.page{
		background:#F5F7FA;
	}
	.rightbox{
		padding-top:20px;
		padding-bottom:20px;
		width: 1320px;
		overflow:auto;
	}
	.pagecontent{
		
		
		height: calc(100vh - 81px);
	}
	.ewmtitle{
		width:100%;
	}
</style>