import httpapi from '../../utils/httpapi';
const deksApi = {
	//桌台列表
	desklist(param,funsucc) {
		httpapi.get('customer/th3app/food/desk/desklist?app_tmpid='+param.app_tmpid + '&areaid=' +param.areaid, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//桌台区域
	arealist(param,funsucc) {
		httpapi.get('customer/th3app/food/desk/arealist?app_tmpid=' + param.app_tmpid, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default deksApi