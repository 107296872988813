import httpapi from "../../utils/httpapi.js"
const imgApi = {
 //获取验证码
 handleUploadFile(param, funsucc) {
   httpapi.handleUploadFile('/common/tool/img/jinjian/imgup', param).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
 handleDeletFile(param, funsucc) {
   let o = {
     id:param.id,
     b:param.billtype,
     t:param.imgtype
   }
   httpapi.post('/common/tool/img/jinjian/imgdel', o).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
 
 //上传图片
 uploadImgfile(param, funsucc) {
   httpapi.handleUploadFile('/isv/file/upload/image',param).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
 //删除图片
 delImg(param, funsucc) {
   httpapi.post('/isv/file/del',param).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
 //新增资源分类
 addcls(param, funsucc) {
   httpapi.post('/isv/resource/cls/add',param).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
 //编辑资源分类
 editcls(param, funsucc) {
   httpapi.post('/isv/resource/cls/edit',param).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
  //查询所有资源分类
 getcls(funsucc) {
   httpapi.get('/isv/resource/cls/list').then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
 //查询图片列表-分页
 getimglist(param, funsucc) {
   httpapi.get('/isv/resource/imgs/list?'+`clsid=${param.clsid}&mc=${param.mc}&pageno=${param.pageno}&pagesize=${param.pagesize}`,param).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
 //删除资源文件
 delImg(param, funsucc) {
   httpapi.post('/isv/resource/res/delete',param).then(res => {
     if (funsucc) {
       funsucc(res)
     }
   }).catch({
 
   })
 },
}
export default imgApi;