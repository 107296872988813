// 导航广告
<template>
  <div id="menuad">
      <div v-for="item,index in list.data" :key="index" v-show="list.data.length>0">
          <p>{{item.title||'秒杀'}}</p>
          <img :src="item.img" alt="" srcset="">
      </div>
      <div v-for="item,index in list2" :key="index"  v-show="list.data.length==0">
          <p>{{item.title}}</p>
          <img :src="item.img" alt="" srcset="">
      </div>
  </div>
</template>

<script>
export default {
  name: "Menuad",
 props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [
       
      ],
      list2:[
           {
          title: "新品",
          img: '',
        },
        {
          title: "秒杀",
         img: '',
        },
        {
          title: "爆品",
         img: '',
        },
        {
          title: "推荐",
         img: '',
        },
      ]
    };
  },

  mounted() {
      this.list=this.data
  },

  methods: {},
};
</script>

<style lang="less" scoped>
#menuad {
  display: flex;
  justify-content: space-between;
  align-content: center;
  text-align: center;
  border-radius: 30px;
  div{
      width: 25%;
      margin: 0 5px;
  }
}
img{
      width: 100%;
    margin-top: 10px;
    // width: 200px;
}
</style>