<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="15"></leftnav>
			<div class="rightbox"  style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>图文</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>图文配置</span>
						<el-button type="primary" size="small" style="float: right;"
								@click="funAddMenu">发布</el-button>
						<el-button type="primary" size="small" style="float: right;margin-right: 20px;"
								@click="isedit = true">编辑</el-button>
						<el-button  size="small" style="float: right;margin-right: 20px;"
								@click="funPreview">预览</el-button>
					</div>
					<div class="main">
						<div class="main-left">
							<div class="main-wrap" v-for="(item,index) in menudata" :key="index"
								@click="funMenuClick(item,index)">
								<div class="main-box1" :class="selIndex == index ? 'sel' : 'normal'" v-if="index == 0">
									<div class="main-title">
										<span>{{item.title}}</span>
									</div>
									<div class="main-bgimg">
										<img :src="item.logo" />
									</div>
								</div>
								<div class="main-news" v-else :class="selIndex == index ? 'sel' : 'normal'">
									<div class="main-title">
										<span>{{item.title}}</span>
									</div>
									<div class="main-img">
										<img :src="item.logo" />
									</div>
								</div>
				
							</div>
							<div class="main-box2" v-if="menudata.length < 5" @click="funaddmsg()">
								<div class="mainadd">
									<p>+</p>
									<span>新建消息</span>
								</div>
							</div>
						</div>
						<div class="main-right">
							<div class="fixedtitles">
								<div class="list" :class="item.tag" v-for="(item,index) in titlelist" :key="index">
									<a :href="'#'+item.id" v-if="isedit == false">{{item.mc}}</a> 
									<span v-else>{{item.mc}}</span>
								</div>
							</div>
							<div class="main-wrap" v-show="isedit == false">
								<div>
								<p class="main-title">{{menufrom.title}}</p>
								<p>作者：{{menufrom.author}}</p>
								</div>
								<div class="newscontent" v-html="menufrom.content">
								</div>
				
							</div>
							<div class="main-wrap" v-show="isedit == true">
								<div class="main-item">
									<span>标题：(必填)</span><el-input v-model="menufrom.title" placeholder="请输入内容"
										size="small" style="width: 300px;"></el-input>
									<p>标题不能为空且长度不能超过64字</p>
								</div>
								<div class="main-item">
									<span>序号（必填）</span><el-input v-model="menufrom.orderno" placeholder="请输入内容"
										size="small" style="width: 100px;"></el-input>
								</div>
								<div class="main-item">
									<span>作者（选填）</span><el-input v-model="menufrom.author" placeholder="请输入内容"
										size="small" style="width: 300px;"></el-input>
								</div>
								<div class="main-item">
									<p>图片（大图片建议尺寸：375像素 * 500像素）</p>
									<el-image :src="menufrom.picurl" style="width: 375px;height: auto;"></el-image>
									<div><el-button size="small" @click.stop="funUpload()">上传图片</el-button></div>
								</div>
								<div class="main-item">
									<span>摘要</span><el-input type="textarea" v-model="menufrom.textdesc" placeholder="请输入内容"
										size="small"></el-input>
								</div>
								<div class="main-item">
									<span>正文 {{issaved ? '已保存':'未保存'}} </span>
									<Editor class="CKEditor" ref="Editor" @change="callbackChangeEditor"
										:fileUrl="richTextFileUrl">
									</Editor>
								</div>
								<div class="main-item">
									<span>链接地址（选填）</span><el-input v-model="menufrom.linkurl" placeholder="请输入内容"
										size="small"></el-input>
								</div>
				
								<div class="main-item">
									<span>状态：{{menufrom.isuse == 1?'启用':'停用'}}</span>
									<el-radio-group v-model="menufrom.isuse" size="small">
										<el-tooltip class="item" effect="dark" content="启用" placement="bottom">
											<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
										</el-tooltip>
										<el-tooltip class="item" effect="dark" content="停用" placement="bottom">
											<el-radio-button :label="0">
												<i class="el-icon-s-operation"></i>
											</el-radio-button>
										</el-tooltip>
									</el-radio-group>
								</div>
								<div class="main-item">
									<el-button type="primary" size="small" @click="funSave">保存</el-button>
									<el-button type="danger" size="small" @click="funDel">删除</el-button>
								</div>
							</div>
						</div>
					</div>
				</el-card>
				<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
				</div>
				</div>
		
	</div>
</template>

<script>
	import {
		genID
	} from '../../../../../../utils/util.js'
	import menuApi from '../../../../../../api/cus/menu.js'
	import selImgs from "@/components/system/selImgs";
	import Editor from '@/components/system/Editor'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			selImgs,
			Editor,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				newsid: "",
				menudata: [],
				menufrom: {
					"id": '',
					"mpid": '',
					"newsid": '',
					"title": '',
					"author": '',
					"newstype": '',
					"textdesc": '',
					"content": '',
					"picurl": '',
					"linkurl": '',
					"isuse": 1,
					"orderno": '',
					"showpic": ''
				},
				titlelist: [],
				newsid: "",
				selIndex: 0,
				richTextFileUrl: '',
				issaved: true,
				isedit: false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				let newsid = this.$route.query.newsid
				if (mpid != undefined || newsid != undefined) {
					this.mpid = mpid
					this.newsid = newsid
				} else {
					this.$router.push("/");
				}

				this.getDetail()
			},
			getDetail() {
				let param = {
					newsid: this.newsid
				}
				menuApi.newsdetail(param, res => {
					if (res.code == 200) {
						if (res.data.state == 'succ') {
							this.menudata = res.data.list
							this.funMenuClick(this.menudata[this.selIndex], this.selIndex)
						}

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
					console.log(this.menudata, 'this.menudata');
				})
			},
			funAddMenu() {
				let param = {
					mpid: this.mpid
				}
				menuApi.newsadd(param, res => {
					console.log(res, 'funAddMenu.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funPreview(){
				this.$router.push({
					path:"/published/news/list",
					query:{
						mpid:this.mpid,
						newsid:this.newsid
					}
				})
			},
			funUpload() {
				this.$refs["selImgs"].funShow()
			},
			funMenuClick(item, index) {
				this.$refs['Editor'].setcontent('')
				let param = {
					id: item.id
				}
				menuApi.newsInfo(param, res => {
					if (res.code == 200) {
						this.menufrom.id = res.data.id
						this.menufrom.mpid = res.data.mpid
						this.menufrom.newsid = res.data.newsid
						this.menufrom.title = res.data.title
						this.menufrom.author = res.data.author
						this.menufrom.newstype = res.data.newsType
						this.menufrom.textdesc = res.data.textDesc
						this.menufrom.content = res.data.content
						this.menufrom.picurl = res.data.picURL
						this.menufrom.linkurl = res.data.linkURL
						this.menufrom.isuse = res.data.isUse
						this.menufrom.orderno = res.data.orderNo
						this.menufrom.showpic = res.data.showPic
						this.$refs['Editor'].setcontent(this.menufrom.content)
						this.isedit = false
						this.$nextTick(r => {
							this.issaved = true;
						})

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
				this.selIndex = index
			},
			funaddmsg() {
				let param = {
					"id": '',
					"mpid": this.mpid,
					"newsid": this.newsid,
					"title": "标题",

				}
				console.log(param, 'funSave.param');
				menuApi.newsedit(param, res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.menudata.push({
							id: res.data.id,
							linkURL: "",
							textDesc: "",
							title: "标题",
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})

			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.menufrom.picurl = imgitem.url
			},
			getHeadings(html) {
				let id = genID();
				let num = 1;
				var doc = new DOMParser().parseFromString(html, "text/html");

				var headings = [];

				function traverse(node, arr) {
					if (node.tagName) {

						switch (node.tagName.toLowerCase()) {

							case 'h1':
							case 'h2':
								num++;
								console.log(node.id, 'noed..id')

								if (node.id != undefined) {
									node.id = id + num
								}
								arr.push({
									id: node.id,
									mc: node.innerText,
									tag: node.tagName.toLowerCase(),
								});
								break;
						}


					}
					if (node.childNodes) {
						for (var i = 0; i < node.childNodes.length; i++) {
							traverse(node.childNodes[i], arr);
						}
					}
				}

				traverse(doc, headings);

				console.log(doc.body.innerHTML, 'doc.innerHTML');
				console.log(headings, 'headings');

				this.titlelist = headings;
				return doc.body.innerHTML;
			},
			callbackChangeEditor(value) {
				if (this.menufrom.content != value) {
					console.log('callbackChangeEditor')
					this.issaved = false;
					this.menufrom.content = this.getHeadings(value)
				}
				//this.menufrom.content = value
			},
			funSave() {
				let param = {
					"id": this.menufrom.id,
					"mpid": this.mpid,
					"newsid": this.newsid,
					"title": this.menufrom.title,
					"author": this.menufrom.author,
					"newstype": this.menufrom.newstype,
					"textdesc": this.menufrom.textdesc,
					"content": this.menufrom.content,
					"picurl": this.menufrom.picurl,
					"linkurl": this.menufrom.linkurl,
					"isuse": this.menufrom.isuse,
					"orderno": this.menufrom.orderno,
					"showpic": this.menufrom.showpic
				}
				console.log(param, 'funSave.param');
				menuApi.newsedit(param, res => {
					console.log(res, 'funSave.res');
					if (res.code == 200) {
						this.issaved = true;
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getDetail()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funDel(){
				let param = {
					"id": this.menufrom.id,
					"newsid":this.menufrom.newsid
				}
				console.log(param, 'funDel.param');
				menuApi.newsinfodel(param, res => {
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getDetail()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.page{
	
	background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
	}
	.pagecontent{
		display: flex;
		
		height: calc(100vh - 81px);
		overflow: auto;
	}
	.main {
		display: flex;
		height: auto;
	}

	.main .main-left {
		width: 370px;
		height: auto;
		background-color: #fff;
	}

	.main .main-left .main-wrap {
		
		display: flex;
		flex-direction: column;
		box-shadow: 0 0 8px 0 #e4e7ed;
	}

	.main .main-left .sel {
		border: 1px solid green !important;
	}

	.main .main-left .normal {
		border: none;
	}

	.main .main-left .main-box1 {
		width: 100%;
		height: 200px;
		color: #fff;
		background-color: #E1E1E1;
		position: relative;
		cursor: pointer;
	}

	.main .main-bgimg {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 200px;
	}

	.main .main-bgimg img {
		width: 100%;
		height: 100%;
	}

	.main .main-left .main-box1 .main-title {
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 999;
		height: 55px;
		line-height: 55px;
		width: 100%;
		background: rgba(0, 0, 0, 0.5);
	}
	.main .main-left .main-box1 .main-title span{
		padding-left: 10px;
	}
	.main .main-left .main-news {
		padding: 0 20px;
		height: 100px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main .main-left .main-news .main-img {
		width: 48px;
		height: 48px;
		background-color: #E1E1E1;
	}

	.main .main-left .main-news .main-img img {
		width: 100%;
		height: 100%;
	}

	.main .main-left .main-box2 {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 130px;
		cursor: pointer;
	}

	.main .main-left .main-box2 .mainadd {
		color: #9A9A9A;
		display: flex;
		align-items: center;
	}

	.main .main-left .main-box2 .mainadd p {
		font-size: 16px;
		font-weight: 800;
	}

	.main .main-left .main-box2 .mainadd span {
		display: inline-block;
		margin-left: 10px;
	}

	.main .main-right .main-wrap {
		margin-left: 20px;
		width: 800px;
	}

	.main .main-right .main-wrap .main-item {
		margin: 5px 0;
		line-height: 2;
	}

	

	.fixedtitles {
		position: fixed;
		right: 0;
		top: 100px;
		width: 200px;
		line-height: 2;
		display: flex;
		flex-direction: column;
		background-color: #fff;
		border-radius: 10px;
		padding-left: 10px;
	}
	.fixedtitles a{
		color: #4e5969;
		text-decoration: none;
	}
	.fixedtitles .h1 {
		font-size: 18px;
	}

	.fixedtitles .h2 {
		padding-left: 20px;
		font-size: 15px;
	}
	.newscontent>>>img{
		width: 100%;
	}
	.main-title{
		font-size: 18px;
		font-weight: 800;
	}
</style>