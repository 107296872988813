<template>
	<div id="text">
		<el-tabs v-model="activeName" type="card">
			<!-- <el-tab-pane label="数据" name="first">
				
			</el-tab-pane> -->
			<p>标题内容</p>
			<el-input v-model="list.options.name" size="medium" maxlength="10" placeholder="请输入标题"></el-input>
			<div class="list">
				<!-- <div class="title">
					<span>标题大小 </span>
					<span>{{
			  list.options.size == 1
			    ? "大(16号)"
			    : list.options.size == 2
			    ? "中(14号)"
			    : "小(12号)"
			}}
					</span>
				</div> -->
				<div class="btn">
					<el-select v-model="list.options.size" filterable allow-create  placeholder="请选择标题大小" size="small">
						<el-option v-for="item in fontlist" :key="item" :label="item"
							:value="item">
						</el-option>
					</el-select>
					<el-tooltip  effect="dark"   content="标题字体色" placement="top">
						<el-color-picker style="margin: 0 10px;" v-model="list.options.titlecolor" size="medium"></el-color-picker>
					</el-tooltip>
					
					<el-tooltip  effect="dark"   content="加粗" placement="top">
						<div class="bbox" >
							<img class="blodimg" src="../../../../../assets/image/icon_b0.png" v-if="list.options.bold == 1"  @click="funchu(2)"></img>
							<img class="blodimg" src="../../../../../assets/image/icon_b1.png" v-else-if="list.options.bold == 2"  @click="funchu(1)"/></img>
						</div>
					</el-tooltip>
					
				</div>
			</div>
			<p>描述内容</p>
			<el-input v-model="list.options.label" maxlength="500" type="textarea"
				placeholder="描述内容，最多500字"></el-input>
				<div class="list">
					<!-- <div class="title">
						<span>描述大小 </span>
						<span>{{
				  list.options.describe == 1 ? "大(16号)" : list.options.describe == 2 ? "中(14号)" : "小(12号)"
				}}
						</span>
					</div> -->
					<div class="btn">
						<el-select v-model="list.options.describe" filterable allow-create  placeholder="请选择描述大小" size="small">
							<el-option v-for="item in fontlist" :key="item" :label="item"
								:value="item">
							</el-option>
						</el-select>
						<el-tooltip  effect="dark"   content="描数字体色" placement="top">
							<el-color-picker style="margin: 0 10px;" v-model="list.options.describecolor" size="medium"></el-color-picker>
						</el-tooltip>
						
					</div>
				</div>
			<div style="height: 1px"></div>
			
			<p>按钮内容</p>
			<div style="display: flex;align-items: center;">
				<el-input size="medium" v-model="list.options.btn" maxlength="10" placeholder="请输入按钮内容"></el-input>
				<el-tooltip  effect="dark"   content="按钮背景色" placement="top">
					<el-color-picker v-model="list.options.btncolor"  size="medium" style="margin: 0 10px;">
					</el-color-picker>
				</el-tooltip>
				
				<el-tooltip  effect="dark"  content="按钮字体色" placement="top">
					<el-color-picker style="margin-right: 10px;" v-model="list.options.btntxtcolor" size="medium"></el-color-picker>
				</el-tooltip>
				<el-tooltip  effect="dark"   content="显示" placement="top">
					<div class="bbox">
						<img class="blodimg2" src="../../../../../assets/image/icon_show1.png" v-if="list.options.btnshow && list.options.btnshow == true"  @click="funsel(false)"></img>
						<img class="blodimg2" src="../../../../../assets/image/icon_show0.png" v-else  @click="funsel(true)"/></img>
					</div>
				</el-tooltip>
			</div>
			
			<div class="details">
				<!-- 标题文字大小 -->
				
			
				<!-- 描述文字大小 -->
				
				<!-- 是否显示按钮 -->
				
				<!-- 是否文字加粗 -->
				<!-- <div class="list">
					<div class="title">
						<span>标题粗细 </span>
						<span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
					</div>
					<div class="btn">
						<el-radio-group v-model="list.options.bold">
							<el-tooltip class="item" effect="dark" content="常规体" placement="bottom">
								<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="加粗体" placement="bottom">
								<el-radio-button :label="2">
									<i class="el-icon-s-operation"></i>
								</el-radio-button>
							</el-tooltip>
						</el-radio-group>
					</div>
				</div> -->
				<!-- 标题文字颜色 -->
				<!-- <div class="list">
					<div class="title"><span>标题颜色</span></div>
					<div class="center">{{list.options.titlecolor||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('titlecolor')">重置</el-button><el-color-picker
							v-model="list.options.titlecolor" size="medium"></el-color-picker>
					</div>
				</div> -->
				<!-- 描述文字颜色 -->
				<!-- <div class="list">
					<div class="title"><span>描述颜色</span></div>
					<div class="center">{{list.options.describecolor||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('describecolor')">重置</el-button><el-color-picker
							v-model="list.options.describecolor" size="medium"></el-color-picker>
					</div>
				</div> -->
				<!-- 按钮颜色 -->
				<!-- <div class="list">
					<div class="title"><span>按钮颜色</span></div>
					<div class="center">{{list.options.btncolor||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('btncolor')">重置</el-button>
					</div>
				</div> -->
				<!-- 按钮字体颜色 -->
				<!-- <div class="list">
					<div class="title"><span>按钮字体颜色</span></div>
					<div class="center">{{list.options.btntxtcolor||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('btntxtcolor')">重置</el-button><el-color-picker
							v-model="list.options.btntxtcolor" size="medium"></el-color-picker>
					</div>
				</div> -->
				<!-- 大背景颜色 -->
				
				<!-- 背景图片 -->
				<div class="list">
					<div class="title"><span>背景图片</span></div>
					<div class="btn">
						<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button>
						<!-- <el-buttontype="primary" size="small" >上传</el-button> -->
							<div class="upbtn" @click="funupload('bgimg')">上传</div>
					</div>
				</div>
				<div class="list">
					<el-image :src="list.options.bgimg" style="width: 80px;height: 80px;"></el-image>
				</div>
				<div class="list">
					<div class="title"><span>大背景颜色</span></div>
					<div class="center">{{list.options.bgcolor||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('bgcolor')">重置</el-button><el-color-picker
							v-model="list.options.bgcolor" size="medium"></el-color-picker>
					</div>
				</div>
				<!-- 背景颜色 -->
				<div class="list">
					<div class="title"><span>背景颜色</span></div>
					<div class="center">{{list.options.background||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('background')">重置</el-button><el-color-picker
							v-model="list.options.background" size="medium"></el-color-picker>
					</div>
				</div>
				<!-- 底部分割线 -->
				<div class="list">
					<div class="title">
						<span>底部分割线</span>{{ list.options.border ? "显示" : "不显示" }}<span></span>
					</div>
					<div class="btn">
						<el-switch v-model="list.options.border" active-color="#13ce66" inactive-color="#ff4949">
						</el-switch>
					</div>
				</div>
				<div class="disps">
					<div class="title">
						<span>圆角 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.borderradius" show-input :max="3000" style="margin: 0 10px;">
						</el-slider>
					</div>
				</div>
				<div class="disps">
				  <div class="title">
				    <span>上内边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.margintop" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
				<div class="disps">
				  <div class="title">
				    <span>下内边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.marginbottom" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
				
				<!-- <div class="disps">
				  <div class="title">
				    <span>内上边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingtop" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
				<div class="disps">
				  <div class="title">
				    <span>内下边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingbottom" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div> -->
				<div class="disps">
				  <div class="title">
				    <span>外内页边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingpage" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
			</div>
		</el-tabs>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>

</template>

<script>
	import selImgs from "@/components/customer/selImgs"
	export default {
		components:{
			selImgs
		},
		name: "club_newadd_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				fontlist:[
					10,11,12,14,16,18,20,24,32,36,40,48,64,96,128
				],
				list: {},
				activeName: "first",
				btnbgtip:true,
				btncolortip:true
			};
		},

		created() {
			this.list = this.data;
		},

		methods: {
			//  显示的位置
			position(event) {
				// this.$set(this.list.options.position, position, event);
				this.list.options.position = event;
			},
			reset(key) {
				this.list.options[key] = '';
			},
			// 字体大小
			size() {},
			funupload() {
				
				this.$refs['selImgs'].funShow()
			},
			funchu(bold){
			
				this.list.options.bold = bold
			},
			showbg(){
			
				this.btnbgtip = true
			},
			funsel(isshow){
				this.list.options.btnshow = isshow
			},
			selImgsresult(imgitem) {
				this.list.options.bgimg = imgitem.url;
			
			},
		},
	};
</script>

<style lang="less" scoped>
	#text {
		padding: 20px;

		p {
			padding: 10px 0;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 20px 0;
	}

	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #969799;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
			color: #ff6060;
		}
	}
	.upbtn{
		color: #00aaff;
	}
	.bbox{
		    cursor: pointer;
		    margin-right: 5px;
		    width: 32px;
		    height: 30px;
		    border-radius: 4px;
		    background-color: #DEDFE1;
		    display: flex;
		    align-items: center;
		    justify-content: center;
			flex-shrink: 0;
	}
	 .blodimg{
			width: 20px;
			height: 20px;
		}
	.blodimg2{
				width: 25px;
				height: 25px;
				
			}	
</style>