<template>
	<div class="stylesetting">
		<section class="menulist">
			<div class="item" v-for="(item, index) in pagelist" :key="index" @click="fungotopage(item)">
				{{ item.pagename }}
			</div>
			<div class="item selected">菜单</div>
			<div class="item" @click="funStyle">风格</div>
			<div class="item" @click="funset">设置</div>
		</section>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>设置</span>
				<el-button style="float: right;margin:0 10px" size="small" @click="funold">还原</el-button>
				<el-button style="float: right" type="primary" size="small" @click="funsave">保存设置</el-button>
			</div>
			<div class="main">
				<div class="main-left">
					<div class="top-nav">
						<img src="../../../assets/design/topNavBlack.png" />
						<span class="tit">首页</span>
					</div>

					<div class="mainapp-cont"></div>
					<foot_nav_cube :data="footer" :styleview="styleview"></foot_nav_cube>
				</div>

				<div class="main-right">
					<foot_nav_cube_edit :data="footer"  @changeTab="tab"></foot_nav_cube_edit>
				</div>
			</div>
		</el-card>
		<showload :showload="showload"></showload>
	</div>
</template>

<script>
	import showload from "@/components/loading";
	import foot_nav_cube_edit from "@/components/applet/toolbox/nav/foot_nav_cube_edit";
	import foot_nav_cube from "@/components/applet/toolbox/nav/foot_nav_cube";
	import designTemplataApi from "../../../api/cus/designtmplate";
	export default {
		components: {
			showload,
			foot_nav_cube,
			foot_nav_cube_edit,
		},
		data() {
			return {
				styleview:{},
				from: {
					logo: "",
					name: "",
				},
				tabType: {
					1: true,
					2: false,
					3: false,
				},
				footer: {

				},
				footerinit: {
		"data": [{
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/760aca66a43647aab255a61d9e0f5f13.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/index/index",
					"name": "首页"
				},
				"name": "站内链接:首页",
				"type": "app",
				"key": "index"
			},
			"title": "首页",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309c51d460fd1e24724b607b632db7a43cd.png?key=123456",
			"height": 50
		}, {
			"form": {},
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/113b5eb6c100415cbceb504cec19b166.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/club/club_savewine",
					"name": "我的存酒"
				},
				"name": "站内链接:我的存酒",
				"type": "app",
				"key": "mysavewine"
			},
			"title": "存酒",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/169241699017262694620230991aee83b2ff7495a8c2cfd9bff47b92c.png?key=123456",
			"height": 50
		}, {
			"form": {},
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/fa2ac1478f614d26ae12e3ef3be0b97c.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/club/club_orders",
					"name": "酒水订单"
				},
				"name": "站内链接:酒水订单",
				"type": "app",
				"key": "myorders"
			},
			"title": "订单",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309483fecdac5434b349a1b36062f8d1450.png?key=123456",
			"height": 50
		}, {
			"form": {},
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/9107a3766ad248d7973508fcb01200a8.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/club/club_index",
					"name": "我的"
				},
				"name": "站内链接:我的",
				"type": "app",
				"key": "my"
			},
			"title": "我的",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/16924169901726269462023092e337a7282f74e0da306ed5c5b3a9aed.png?key=123456",
			"height": 50
		}],
		"options": {
			"size": 3,
			"paddingbottom": 0,
			"marginbottom": 0,
			"marginpage": 0,
			"borderradius": 0,
			"width": 800,
			"bgimg": "",
			"paddingtop": 0,
			"margintop": 0,
			"height": 120
		},
		"type": "foot_nav_cube"
	},
				tabbarpage: [],
				pagelist: [],
				templateid: "",
				showload:false
			};
		},
		mounted() {
			let templateid = this.$route.query.tid;
			this.templateid = templateid;
			this.initdata();
			console.log(this.templateid, "this.templateid");
		},
		methods: {
			tab(key) {
				for (let i in this.tabType) {
					if (key == i) {
						this.tabType[key] = true;
						this.$set(this.data, "tabType", key);
					} else {
						this.tabType[i] = false;
					}
				}
			},

			initdata() {
				let _this = this;
				_this.showload = true
				designTemplataApi.getUserPageList({
						id: this.templateid,
					},
					(res) => {
						_this.showload = false
						console.log(res, "getUserPageList");
						if (res.code == 200) {
							_this.pagelist = res.data.pagelist;
							_this.from.logo = res.data.appTmplate.logo;
							_this.from.name = res.data.appTmplate.tmpname;
						}
					}
				);

				designTemplataApi.getstyle({
						tmpid: this.templateid,
					},
					(res) => {
						if (res.code == 200 && res.data.footer) {
							console.log(res, 'getstyless');
							_this.styleview = res.data.style.item;
							_this.footer = res.data.footer;
							_this.$forceUpdate;
						} else {
							_this.footer = JSON.parse(JSON.stringify(_this.footerinit))
						}

					}
				);
			},
			fungotopage(item) {
				this.$router.replace({
					path: "/customer/xcxtemplate/design",
					query: {
						// id: item.id,
						tid: item.tmpid,
						t: item.pageno,
						mc: item.pagename,
					},
				});
			},
			funback() {
				this.$router.go(-1);
			},
			funold() {
				this.footer = JSON.parse(JSON.stringify(this.footerinit))
			},
			funsave() {
				let mytabbar = [];
				this.footer.data.forEach((item) => {
					console.log(item, "funsave.item");
					if (item.link != undefined && item.link.type == "app") {
						mytabbar.push({
							name: item.link.app.name,
							key: item.link.key,
						});
					}
				});
				this.tabbarpage = mytabbar;
				let param = {
					tmpid: this.templateid,
					tabbarpage: JSON.stringify(mytabbar),
					tabbardesign: JSON.stringify(this.footer),
				};
				designTemplataApi.edittabbar(param, (res) => {
					console.log(res, "edittabbar");
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg,
						});
					} else {
						this.$message({
							type: "error",
							message: res.msg,
						});
					}
				});
			},
			funStyle() {
				this.$router.push({
					path: "/customer/xcxtemplate/style",
					query: {
						tid: this.templateid
					}
				})
			},
			funset() {
				this.$router.push({
					path: "/customer/xcxtemplate/setting",
					query: {
						tid: this.templateid
					}
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.top-nav {
		position: absolute;
		top: 0;
		z-index: 999;
		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}

		.tit {
			position: absolute;
			left: 50%;
			bottom: 10px;
			transform: translateX(-50%);
		}

		img {
			width: 375px;
			image-rendering: -moz-crisp-edges;
			image-rendering: -o-crisp-edges;
			image-rendering: -webkit-optimize-contrast;
			image-rendering: crisp-edges;
			-ms-interpolation-mode: nearest-neighbor;
		}
	}

	.main-left {
		position: relative;
		float: left;
		width: 375px;
		overflow: hidden;
		border-radius: 10px;
		border: 4px solid #1f1c1c;
		margin: 0 20px;
	}

	.mainapp-cont {
		height: calc(100vh - 350px);
		border-bottom: 1px solid #f0f0f0;
	}

	.main-right {
		position: relative;
		margin-left: 20px;
		float: left;
		width: 400;
		overflow: hidden;
	}

	.box-card {}

	.stylesetting {
		margin: 0 auto;
		min-width: 1360px;
		width: 100%;
		overflow: hidden;
	}

	.menulist {
		float: left;
		width: 80px;
		height: calc(100vh - 100px);
		position: relative;
		background: #fff;
		border-right: 1px solid #f0f0f0;
		border-radius: 5px;
		overflow-y: auto;
		overflow-x: hidden;
		box-shadow: 0 2px 6px #ccc;

		.selected {
			background: #3089dc;
			color: #fff;
		}
	}

	.menulist .item {
		cursor: pointer;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-weight: 300;
		font-size: 12pt;
		position: relative;
	}

	.menulist .disp {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.disp /deep/.el-input,
	.el-input__inner {
		margin: 20px;
		width: 300px !important;
	}

	.logo {
		width: 200px;
	}

	.logo img {
		width: 100%;
	}
</style>