<template>
	<div class="">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>图标组件管理</span>
			</div>
			<div class="main">
				<div class="left">
					<div class="item" :class="currIndex == index ? 'sel' : ''" v-for="(item,index) in iconlist"
						:key="index" @click="funicon(item,index)">
						<div class="icon">
							<img :src="item.icon"/>
						</div>
						<div class="name">
							{{item.name}}
						</div>
						<!-- <i class="el-icon-circle-close" @click="fundel(item)"></i> -->
					</div>
				</div>
				<div class="right">
					<div class="title">
						编辑
					</div>
					<div class="cont">
						<div class="disp">
							<div class="label">模板名称</div><el-input size="small" v-model="iconitem.name" style="width: 300px;"></el-input>
						</div>
						<div class="disp">
							<div class="label">组件类名</div><el-input size="small" v-model="iconitem.clsname" style="width: 300px;"></el-input>
						</div>
						<div class="disp">
							<div class="label">组件图标</div><el-input size="small" v-model="iconitem.icon" style="width: 300px;margin-right: 10px;"></el-input><el-button size="small" @click="funimg">上传图标</el-button><el-button size="small" type="primary" @click="funsaveimg">保存</el-button>
						</div>
						<div class="logo">
							<el-image  :src="iconitem.icon" ></el-image>
						</div>
						<div class="disp">
							<div class="label">组件预览图</div><el-input size="small" v-model="iconitem.img" style="width: 300px;"></el-input>
						</div>
						<div class="disp">
							<div class="label">组件说明</div><el-input size="small" v-model="iconitem.memo" style="width: 300px;"></el-input>
						</div>
						<div class="disp">
							<div class="label">组件类型</div><el-input size="small" v-model="iconitem.type" style="width: 300px;"></el-input>
						</div>
					</div>
					<!-- <el-button size="small" @click="funupdate" class="btns" type="primary">修改</el-button> -->
				</div>
			</div>
			<!-- <div class="footer">
				<el-button size="small" >添加组件</el-button>
			</div> -->
		</el-card>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import selImgs from "@/components/customer/selImgs";
	import designTemplataApi from "../../../../api/cus/designtmplate";
	export default {
		components:{
			selImgs
		},
		data() {
			return {
				iconitem:{
					clsname:"",
					icon:"",
					img:"",
					memo:"",
					name:"",
					type:"",
				},
				currIndex: 0,
				iconlist: []
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				designTemplataApi.iconlist(res => {
					console.log(res, 'res。iconlist');
					if (res.code == 200) {
						this.iconlist = res.data
						this.iconitem.name = res.data[0].name
						this.iconitem.clsname = res.data[0].clsname
						this.iconitem.icon = res.data[0].icon
						this.iconitem.img = res.data[0].img
						this.iconitem.memo = res.data[0].memo
						this.iconitem.type = res.data[0].type
					} else {
						this.$message({
							type: 'error',
							message: '获取失败!'
						})
					}

				})
			},
			funimg(){
				this.$refs["selImgs"].funShow()
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.iconitem.icon = imgitem.url;
			},
			fundel(item){
				designTemplataApi.icondel(res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '删除成功'
						})
					} else {
						this.$message({
							type: 'error',
							message: '删除失败!'
						})
					}
				
				})
			},
			funicon(item,index) {
				this.iconitem = item
				console.log(this.iconitem,'this.iconitem');
				this.currIndex = index
			},
			funupdate(){
				let param = {
					clsname:this.iconitem.clsname,
					icon:this.iconitem.icon,
					img:this.iconitem.img,
					memo:this.iconitem.memo,
					name:this.iconitem.name,
					type:this.iconitem.type,
				}
				console.log(param,'paramedit');
				designTemplataApi.iconedit(param,res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '修改成功'
						})
					} else {
						this.$message({
							type: 'error',
							message: '修改失败!'
						})
					}
				
				})
			},
			funsaveimg(){
				let param = {
					icon:this.iconitem.icon,
					type:this.iconitem.type,
				}
				console.log(param,'paramedit');
				designTemplataApi.iconsave(param,res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '保存成功'
						})
					} else {
						this.$message({
							type: 'error',
							message: '保存失败!'
						})
					}
				
				})
			}
		}
	}
</script>

<style scoped>
	.box-card {
		position: relative;
	}

	.main {
		display: flex;
	}

	.main .left {
		width: 150px;
		height: calc(100vh - 300px);
		overflow: auto;
		display: flex;
		text-align: center;
		flex-direction: column;
	}

	.main .left .item {
		cursor: pointer;
		padding: 20px;
		border: 1px solid #ccc;
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
	}
	.main .left .item .icon{
		width: 30px;
		height: 30px;
	}
	.main .left .item .icon img{
		width: 100%;
		height: 100%;
	}
	.el-icon-circle-close{
		position: absolute;
		cursor: pointer;
		top: 0;
		right: 0;
	}
	.main .right{
		padding: 20px;
		width: 100%;
		position: relative;
	}
	.main .right .title{
		font-size: 18px;
		font-weight: 800;
	}
	.cont .label{
		width: 200px;
	}
	.cont .disp{
		margin: 10px 0;
		display: flex;
		align-items: center;
	}
	.footer{
		margin-top: 10px;
	}
	.btns{
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.logo{
		width: 100px;
	}
	.logo img{
		width: 100%;
	}
	.sel {
		border: 1px solid #00aaff !important;
	}
</style>