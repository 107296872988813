<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		<el-tabs v-model="activeName" type="border-card">
			<el-tab-pane label="数据" name="first">
				<!-- <template v-if="list.data && list.data.length < len">
					<el-button type="primary" icon="el-icon-plus" @click="addImage(null)" class="add-image">
						添加图片
					</el-button>
					<p class="size">（建议尺寸：{{ size }}）</p>
				</template> -->
				<!-- <p class="desc">添加图片 (最多{{ len }}张，可拖动排序）</p> -->
				<div class="disp" >
					<vuedraggable v-model="list.data" tag="div" draggable=".item" v-if="list.data && list.data.length > 0"
						class="image-list" :class="{ disable: data.tabType == 2 }">
						<div class="addimg" @click="addImage(null)" v-if="list.data && list.data.length < len">
							添加图片
						</div>
						<div v-for="(item, index) in list.data" :key="index" @click="selitem(item,index)" class="item"
							:class="index === imageIndex? 'sel':'normal'">
							<img :src="item.url" />
							<!-- <div>{{ item && item.name }}</div> -->
							<div class="r-image">
								<span @click="removeImage(index)" class="el-icon-close"></span>
								<div class="image-box">
									<span @click="addImage(index)" class="el-icon-edit-outline"></span>
								</div>
							</div>
						</div>
					</vuedraggable>
					<li style="width: 100%;">
						<div class="l-info" v-if="imageIndex > -1" style="width: 100%;">
							<p>
								<span class="text">{{ item && item.name }}</span>
							</p>
							<p style="display: flex;align-items: center;justify-content: space-between;white-space: nowrap;">
								<span>图片链接：</span>
								<el-button type="text" size="mini" @click="addImage(index)">上传</el-button>
							</p>
								<el-input v-model="item.url" size="small" style="width: 200px;margin: 0 10px;"></el-input>
							<p style="margin-top: 10px;display: flex;align-items: center;justify-content: space-between;">
								<span>链接：</span>
								<span class="text">{{ item && item.link && item.link.name }}</span>
								<el-button type="primary" size="mini" @click="urlPopup(imageIndex, item.link)">选择链接</el-button>
							</p>
						</div>
					</li>
				</div>
				<el-dialog title="请填写图片跳转链接" :visible.sync="show" @close="close">
					<el-form label-width="100px">
						<el-form-item label="跳转链接">
							<el-input v-model="url"></el-input>
						</el-form-item>
						<el-form-item label="">
							<el-button type="primary" @click="confirm">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
			</el-tab-pane>
			<el-tab-pane label="样式" name="style">
				<!--属性设置-->
				<div class="details">
					<!-- 圆角 -->
					<div class="list">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="800">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>上边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list">
						<div class="title">
							<span>下边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="200">
							</el-slider>
						</div>
					</div>

					<div class="list">
						<div class="title">
							<span>页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginpage" show-input :max="200">
							</el-slider>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import sellink from "@/components/designer/sellink"
	import selImgs from "@/components/designer/selImgs"
	import vuedraggable from "vuedraggable";
	export default {
		name: "image_row_edit",
		components: {
			sellink,
			selImgs,
			vuedraggable,
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				list: {},
				item: {},
				url: "",
				index: null,
				show: false,
				imageIndex: -1,
				activeName: "first"
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;
		},
		methods: {
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				this.item = item;
				this.imageIndex = index;
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index,link) {
				this.$refs['sellink'].funShow()
			},
			removeImage(index) {
				this.list.data.splice(index, 1);
			},
			addImage(index) {
				this.imageIndex = index;
				// this.$refs["upload"].$children[0].$refs.input.click();
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				this.upload(imgitem)
			},
			sellinkresult(param) {
				
				const index = this.imageIndex;
				if (index !== null) {
					let data = this.list.data[index];
					data.link = param;
					
					this.$set(this.list.data, index, data);
				}
			},
			upload(imgitem) {
				const index = this.imageIndex;
				const data = {
					name: imgitem.name,
					url: imgitem.url,
				};

				if (index !== null) {
					this.$set(this.list.data, index, data);
				} else {
					this.list.data.push(data);
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: calc(100% - 20px);
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin-left: 10px;
			margin-top: 10px;
		}
		.disp{
			display: flex;
			align-items: unset;
		}
		.addimg{
			cursor: pointer;
			width: 60px;
			height: 30px;
			line-height: 30px;
			text-align: center;
			overflow: hidden;
			margin: 2px;
			color: #fff;
			font-size: 12px;
			border-radius: 4px;
			background: #3388FF;
		}
		.image-list {
			margin: 0;
			width: 120px;
			overflow: auto;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			.item {
				width: 60px;
				height: 40px;
				overflow: hidden;
				margin: 2px;
				border-radius: 4px;
				position: relative;
				&:hover .r-image{
					background: rgba(0, 0, 0, 0.5);
				}
				img {
					border-radius: 4px;
					width: 100%;
				}

				.r-image {
					text-align: right;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					z-index: 999;
					

					.el-icon-close {
						color: #999;
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 6px;
						cursor: pointer;

						&:hover {
							color: red;
						}
					}

					.image-box {
						width: 100%;
						height: 100%;
						border-radius: 5px;
						overflow: hidden;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.5);
							opacity: 0;
							transition: all 0.3s;
						}

						span {
							position: absolute;
							top: 50%;
							left: 50%;
							color: #fff;
							transform: translate(-50%, -50%);
							font-size: 20px;
							cursor: pointer;
							opacity: 0;
							transition: all 0.3s;
						}

						img {
							max-width: 100%;
						}

						&:hover {

							&::before,
							span {
								opacity: 1;
							}
						}
					}
				}
			}

			.normal {
				border: 1px solid #ccc;
			}

			.sel {
				border: 1px solid #1b8bff;
			}

			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			li {
				list-style: none;
				background: #f7f8f9;
				border-radius: 4px;
				padding: 6px 14px 10px;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;

				&.disable div {
					pointer-events: none;
					user-select: none;
				}

				.l-info {
					font-size: 12px;
					padding-top: 8px;
					width: calc(100% - 70px);

					p {
						margin: 12px 0 0;
						white-space: nowrap;
						overflow: hidden;
						display: flex;

						.link {
							color: #1b8bff;
							cursor: pointer;
						}

						.text {
							white-space: nowrap;
							text-align: -webkit-auto;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}

			}
		}

		.list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 20px;

			.title {
				margin-right: 10px;
				flex-shrink: 0;
			}

			.full {
				flex-grow: 1;
			}
		}
		p{
			margin: 0 10px;
		}

	}
	
</style>