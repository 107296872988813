<template>
	<div>
		<navcon></navcon>
		<div class="container">
			
			<div class="freemail">
				<div class="top">
					<div class="search">
						<div class="search-wrap">
							<!-- <div class="search_select">
								<select name="all" id="all" class="selstyle" v-model="form.cls1id" @change="funSaleClick($event)">
									<option :value="item.id" v-for="(item,index) in salelist" :key='index'>{{item.mc}}</option>
								</select>
							</div> -->
							<div class="search_input">
								<input type="text" placeholder="酒吧简约大气小程序" v-model="form.key" />
							</div>
							<div class="search_click" @click="funSearch">
								<img src="../../../assets/image/market/serch.png" />
							</div>
						</div>
					</div>
				</div>
				<div class="alltemp">
					<span>模版市场</span>
					<span>></span>
					<span>搜索：{{form.keyview}}
		
						
					</span>
				</div>
				<div class="cls">
					<div class="title">
						销售
					</div>
					<div class="cls_wrap">
						<div class="cls_item" :class="item.id == form.cls1id ? 'sel' : 'nosel'"
							v-for="(item,index) in salelist" :key="index" @click="funSaleClick(item,index)">
							<span>{{item.mc}}</span>
						</div>
					</div>
				</div>
				<div class="cls">
					<div class="title">
						行业
					</div>
					<div class="cls_wrap">
						<div class="cls_item" :class="item.id == form.cls3id ? 'sel' : 'nosel'"
							v-for="(item,index) in hangyelist" :key="index" @click="funHangyeClick(item,index)">
							<span>{{item.mc}}</span>
						</div>
					</div>
				</div>
				<div class="cls">
					<div class="title">
						风格
					</div>
					<div class="cls_wrap">
						<div class="cls_item" :class="item.id == form.cls2id ? 'sel' : 'nosel'"
							v-for="(item,index) in stylelist" :key="index" @click="funStyleClick(item,index)">
							<span>{{item.mc}}</span>
						</div>
					</div>
				</div>
				<div class="cls">
					<div class="title">
						免费
					</div>
					<div class="cls_wrap">
						<div class="cls_item" :class="freecurrIndex == index ? 'sel' : 'nosel'"
							v-for="(item,index) in freelist" :key="index" @click="funfreeClick(item,index)">
							<span>{{item.mc}}</span>
						</div>
					</div>
				</div>
				<div class="screen">
					<div class="screen_wrap">
						<div class="screen_item">
							综合排序
						</div>
						<div class="screen_item">
							最新上传
						</div>
						<div class="screen_item">
							下载最多
						</div>
					</div>
				</div>
				<div class="box">
					<div class="box-wrap">
						<div class="box-item" v-for="(item,index) in marketlist" :key="index" >
							<a target="_blank" :href="'/customer/xcxtemplate/marketdetail?tmpid=' + item.tmpid"><img :src="item.logo" /></a>
							<div class="box-bg">
								<div class="">
									{{item.tmpname}}
								</div>
								<div class="">
									￥{{item.pricesale}}
								</div>
							</div>
						</div>
					</div>
					<!-- 分页组件 -->
					<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
				</div>
			</div>
			<showload :showload="showload"></showload>
		</div>
		<pagebottom ref="pagebottom"></pagebottom> 
	</div>
	
</template>

<script>
	import showload from "@/components/loading";
	import Pagination from '../../../components/customer/marketPagination.vue'
	import marketApi from '../../../api/cus/market.js';
	import navcon from '@/components/customer/navcon'
	import pagebottom from '@/components/pagebottom'
	export default {
		components: {
			showload,
			Pagination,
			navcon,
			pagebottom
		},
		data() {
			return {
				showload: false,
				hycurrIndex: 0,
				stylecurrIndex: 0,
				salecurrIndex: 0,
				freecurrIndex: 0,
				hangyelist: [],
				stylelist: [],
				salelist: [],
				marketlist: [],
				form: {
					cls1id: '',
					cls2id: '',
					cls3id: '',
					key: '',
					keyview: '',
					freeflag: ''
				},
				freelist: [{
					id: '',
					mc: '全部'
				}, {
					id: 'T',
					mc: '免费'
				}, {
					id: 'F',
					mc: '收费'
				}],
				pageparm: {
					currentPage: 1,
					pageSize: 12,
					total: 0
				},
				salemc: '',
				hangyemc: '',
				stylemc: ''
			}
		},
		mounted() {
			let cls1id = this.$route.query.cls1id
			if (cls1id != undefined) {
				this.form.cls1id = cls1id
				
			}
			let cls2id = this.$route.query.cls2id
			if (cls2id != undefined) {
				this.form.cls2id = cls2id
				
			}
			let cls3id = this.$route.query.cls3id
			if (cls3id != undefined) {
				this.form.cls3id = cls3id
				
			}
			let searchkey = this.$route.query.searchkey
			if (searchkey != undefined) {
				this.form.key = searchkey
				
			}
			this.init()
		},
		methods: {
			init() {
				this.gethangyelist()
				this.getstylelist()
				this.getsalelist()
				this.getmarketlist(this.pageparm)
			},
			getmarketlist(page) {
				let param = {
					cls1id: this.form.cls1id, //销售
					cls2id: this.form.cls2id, //风格
					cls3id: this.form.cls3id, //行业
					freeflag: this.form.freeflag,
					key: this.form.key,
					pageno: page.currentPage,
					pagesize: page.pageSize
				}
				console.log(param, 'search.param');
				this.showload = true
				marketApi.search(param, res => {
					console.log(res, 'search.res');
					this.showload = false
					if (res.code == 200) {
						this.form.keyview = this.form.key
						this.marketlist = res.data.list
						this.pageparm.total = res.data.total
					}
				})
			},
			gethangyelist() {
				marketApi.hangyelist({}, res => {
					console.log(res, 'hangyelist.res');
					if (res.code == 200) {
						res.data.unshift({
							id: '',
							mc: '全部'
						})
						this.hangyelist = res.data
						this.hangyelist.forEach((i, index) => {
							if (i.id == this.form.cls3id) {
								this.hycurrIndex = index
							}
						})
					}
				})
			},
			getstylelist() {
				marketApi.stylelist({}, res => {
					console.log(res, 'stylelist.res');
					if (res.code == 200) {
						res.data.unshift({
							id: '',
							mc: '全部'
						})
						this.stylelist = res.data
						this.stylelist.forEach((i, index) => {
							if (i.id == this.form.cls2id) {
								this.stylecurrIndex = index
							}
						})
					}
				})
			},
			getsalelist() {
				marketApi.salelist({}, res => {
					console.log(res, 'salelist.res');
					if (res.code == 200) {
						res.data.unshift({
							id: '',
							mc: '全部'
						})
						this.salelist = res.data
						this.salelist.forEach((i, index) => {
							if (i.id == this.form.cls1id) {
								this.salecurrIndex = index
							}
						})
					}
				})
			},
			funHangyeClick(item, index) {
				// this.form.cls1id = '';
				// this.form.cls2id = '';
				this.form.cls3id = item.id
				this.hycurrIndex = index
				this.getmarketlist(this.pageparm)
				this.hangyemc = item.mc
			},
			funStyleClick(item, index) {
				// this.form.cls1id = '';
				// this.form.cls3id = '';
				this.form.cls2id = item.id
				this.stylecurrIndex = index
				this.getmarketlist(this.pageparm)
				this.stylemc = item.mc
			},
			funSaleClick(item, index) {
				console.log(item, 'item');
				this.form.cls1id = item.id;
				// this.form.cls3id = '';
				// this.form.cls2id = '';
				this.salecurrIndex = index
				this.salemc = item.mc

				this.getmarketlist(this.pageparm)
			},
			funSearch() {
				this.form.cls1id = '';
				this.form.cls2id = '';
				this.form.cls3id = '';
				this.getmarketlist(this.pageparm)
			},
			funfreeClick(item, index) {
				this.freecurrIndex = index
				this.form.freeflag = item.id
				this.getmarketlist(this.pageparm)
			},
			fundetail(item) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketdetail?tmpid=" + item.tmpid,
				});
			},
			// 分页插件事件
			imgcallFather(parm) {
				console.log(parm, 'parm');
				this.getmarketlist(parm)
			},
		}
	}
</script>

<style scoped>
	.container {
		width: 1280px;
		margin: 0 auto;
	}

	.container .freemail {
		margin-top: 20px;
	}

	.container .freemail .alltemp {
		display: flex;
		align-items: center;
		margin: 20px 0;
	}

	.container .freemail .alltemp span:nth-child(1) {
		color: #9D9A9A;
	}

	.container .freemail .alltemp span:nth-child(2) {
		color: #9D9A9A;
		padding: 0 10px;
	}

	.container .freemail .alltemp span:nth-child(3) {
		font-size: 14px;
		font-weight: 800;
	}

	.container .freemail .cls {
		display: flex;
		align-items: center;
		padding-bottom: 14px;
	}

	.container .freemail .cls:nth-child(5) {
		
	}

	.container .freemail .title {
		font-size: 12px;
		color: #9D9A9A;
	}

	.container .freemail .cls .cls_wrap {
		margin-left: 30px;
		display: flex;
	}

	.container .freemail .cls .cls_wrap .cls_item {
		cursor: pointer;
		margin-left: 10px;
		padding: 0 10px;
		width: 64px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		border-radius: 20px 20px 20px 20px;
	}

	.container .freemail .cls .cls_wrap .cls_item span {
		font-size: 12px;
	}

	.container .freemail .cls .cls_wrap .sel {
		color: #070707;
		font-weight: 800;
		background: #F3F4F7;
	}

	.container .freemail .cls .cls_wrap .nosel {
		color: #383838;
	}


	.container .freemail .box {
		margin-top: 20px;
	}

	.container .freemail .box .box-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.container .freemail .box .box-wrap .box-item {
		margin-left: 30px;
		margin-bottom: 30px;
		cursor: pointer;
		padding: 20px;
		background: #F6F7F9;
		border-radius: 10px 10px 10px 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.container .freemail .box .box-wrap .box-item img {
		width: 250px;
		height: 500px;
	}

	.container .freemail .box .box-wrap .box-item .box-bg {
		overflow: hidden;
		opacity: 0;
		transition: all .3s;
		text-align: center;
		background-color: #fff;
	}

	.container .freemail .box .box-wrap .box-item:hover .box-bg {
		opacity: 1;
		width: 100%;
		height: 45px;
		text-align: center;
		background-color: #fff;
		box-shadow: 0 6px 14px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);
	}

	.container .top {
		height: 110px;
		border-radius: 10px;
		display: flex;
		align-items: center;
		background: url('../../../assets/image/market/icon_searchbg.png') no-repeat;

	}

	.container .screen {
		margin: 20px 0;
	}

	.container .screen .screen_wrap {
		display: flex;
	}

	.container .screen .screen_wrap .screen_item {
		margin: 0 15px;
		color: #A1A1A1;
		font-size: 12px;
		cursor: pointer;
	}

	.container .screen .screen_wrap .screen_item:nth-child(1) {
		margin-left: 0;
		color: #000000;
	}

	.container .search {
		margin: 0 auto;
		/* width: 874px; */
		height: 44px;
		border-radius: 12px;
		border: 1px solid #3388FF;
		background-color: #fff;
	}

	.container .search .search-wrap {
		display: flex;
	}

	.container .search .search-wrap .search_select {
		width: 95px;
		height: 100%;
		position: relative;
	}

	.container .search .search-wrap .search_select::after {
		content: '';
		display: block;
		height: 100%;
		width: 1px;
		background-color: #D4D4D4;
		position: absolute;
		right: 0;
		top: 0;
	}

	.container .search .search-wrap .search_select .selstyle {
		color: #272727;
		outline: none;
		border: none;
		background: none;
		padding: 12px 15px;
	}

	.container .search .search-wrap .search_input input {
		padding-left: 10px;
		width: 700px;
		height: 42px;
		outline: none;
		border: none;
		background: none;
	}

	.container .search .search-wrap .search_click {
		cursor: pointer;
		width: 80px;
		height: 44px;
		background: #3388FF;
		border-radius: 0px 11px 11px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.container .search .search-wrap .search_click img {
		width: 25px;
		height: 25px;
	}
</style>