<template>
	<div class="container">
		<div class="alltemp">
			<span>模版市场</span>
			<span>></span>
			<span>精品模版</span>
		</div>
		<div class="tempone">
			<img src="../../../assets/image/market/tempbg1.png"/>
		</div>
		<div class="temptwo">
			<img src="../../../assets/image/market/tempbg2.png"/>
		</div>
		<div class="tempthree">
			<img src="../../../assets/image/market/tempbg3.png"/>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				
			}
		},
		methods:{
			
		}
	}
</script>

<style scoped>
	.container .alltemp{
		display: flex;
		align-items: center;
		margin-bottom: 30px;
	}
	.container .alltemp span:nth-child(1){
		color: #9D9A9A;
	}
	.container .alltemp span:nth-child(2){
		color: #9D9A9A;
		padding: 0 10px;
	}
	.container .alltemp span:nth-child(3){
		font-size: 14px;
		font-weight: 800;
	}
	.container .tempone{
		margin-top: 16px;
		width: 100%;
		height: 150px;
	}
	.container .tempone img{
		width: 100%;
		height: 100%;
	}
	.container .temptwo{
		margin: 44px 0;
		width: 100%;
		height: 358px;
	}
	.container .temptwo img{
		width: 100%;
		height: 100%;
	}
	.container .tempthree{
		width: 100%;
		height: 358px;
	}
	.container .tempthree img{
		width: 100%;
		height: 100%;
	}
</style>