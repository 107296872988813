<template>
	<div>
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="16"></leftnav>
			<div class="rightbox"  style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>应用管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>应用管理</span>
						<div class="" style="float:right">
							<el-button type="primary" size="small" @click="funAddApp(0)">新增自建应用</el-button>
							<el-button type="primary" size="small" @click="funAddApp(1)">新增第三方应用</el-button>
							<el-button type="primary" size="small" @click="funAddApp(2)">新增待开发应用</el-button>
						</div>
					</div>
					<div class="main">
						<!--列表-->
						<el-table size="small" :data="appData" highlight-current-row v-loading="loading" :border="false"
							element-loading-text="拼命加载中" style="width: 100%;">
				
							<el-table-column align="center" sortable prop="appname" label="应用名称" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="memo" label="说明" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="usestate" label="状态" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="clsmc" label="分类名称" width="180">
							</el-table-column>
							<el-table-column label="操作" min-width="200">
								<template slot-scope="scope">
									<a href=""><el-button size="mini"
											@click="funauth(scope.$index, scope.row)">授权</el-button></a>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
				<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<template v-if="type == 0">
							<el-form-item label="appid" prop="appid">
								<el-input size="small" v-model="editForm.appid" auto-complete="off"
									placeholder="请输入appid"></el-input>
							</el-form-item>
							<el-form-item label="应用名称" prop="appname">
								<el-input size="small" v-model="editForm.appname" auto-complete="off"
									placeholder="请输入应用名称"></el-input>
							</el-form-item>
							<el-form-item label="应用aeskey" prop="aeskey">
								<el-input size="small" v-model="editForm.aeskey" auto-complete="off"
									placeholder="请输入aeskey"></el-input>
							</el-form-item>
							<el-form-item label="应用appsecret" prop="appsecret">
								<el-input size="small" v-model="editForm.appsecret" auto-complete="off"
									placeholder="请输入应用appsecret"></el-input>
							</el-form-item>
							<el-form-item label="应用token" prop="token">
								<el-input size="small" v-model="editForm.token" auto-complete="off"
									placeholder="请输入应用token"></el-input>
							</el-form-item>
						</template>
						<template v-if="type == 1 || type == 2">
							<el-form-item label="应用名称" prop="appname">
								<el-input size="small" v-model="editForm.appname" auto-complete="off"
									placeholder="请输入应用名称"></el-input>
							</el-form-item>
						</template>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="submitForm()">确定
						</el-button>
					</div>
				</el-dialog>
			</div>
			</div>
		
	</div>
</template>

<script>
	import wxqyApi from '../../../../../../api/cus/weixinqy.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				type: 0,
				loading: false,
				editForm: {
					aeskey: "",
					appid: "",
					appname: "",
					appsecret: "",
					token: "",
				},
				editFormVisible: false,
				appData: []
			}
		},
		mounted() {
			// this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
					this.getlist()
				} else {
					this.$router.push("/");
				}

			},
			getlist() {
				this.loading = true
				wxqyApi.list(this.mpid, res => {
					console.log(res, 'list');
					if (res.code == 200) {
						this.loading = false
						this.appData = res.data
					}
				})
			},
			funauth(index, row) {
				wxqyApi.geturl(row.id, res => {
					console.log(res, 'list');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "获取成功"
						})
					}
				})
			},
			funAddApp(type) {
				this.type = type
				this.editForm.aeskey = ""
				this.editForm.appid = ""
				this.editForm.appname = ""
				this.editForm.appsecret = ""
				this.editForm.token = ""
				if (type == 0) {
					this.title = "新增自建应用"
				} else if (type == 1) {
					this.title = "新增第三方应用"
				} else {
					this.title = "新增待开发应用"
				}
				this.editFormVisible = true
			},
			submitForm() {
				if (this.type == 0) {
					this.title = "新增自建应用"
					this.devpadd()
				} else if (this.type == 1) {
					this.title = "新增第三方应用"
					this.isvadd()
				} else {
					this.title = "新增待开发应用"
					this.proxyadd()
				}
			},
			devpadd() {
				let param = {
					aeskey: this.editForm.aeskey,
					appid: this.editForm.appid,
					appsecret: this.editForm.appsecret,
					token: this.editForm.token,
					mpid: this.mpid,
					appname: this.editForm.appname
				}
				wxqyApi.devpadd(param, res => {
					console.log(res, 'devpadd');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "新增自建应用成功"
						})
						this.getlist()
						this.editFormVisible = false
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			isvadd() {
				let param = {
					mpid: this.mpid,
					appname: this.editForm.appname
				}
				wxqyApi.proxyadd(param, res => {
					console.log(res, 'isvadd');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "新增第三方成功"
						})
						this.getlist()
						this.editFormVisible = false
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			proxyadd() {
				let param = {
					mpid: this.mpid,
					appname: this.editForm.appname
				}
				console.log(param,'param');
				wxqyApi.proxyadd(param, res => {
					console.log(res, 'proxyadd');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "新增待开发成功"
						})
						this.getlist()
						this.editFormVisible = false
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.box-card {
		width: 1030px;
	}
	.page{
	
	background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
	}
	.pagecontent{
		display: flex;
		
		height: calc(100vh - 81px);
		overflow: auto;
	}
</style>