import httpapi from '../../utils/httpapi';
const qrcodeApi = {
	//查询二维码配置信息
	list(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/qrcode/man/list?mpid='+param.mpid, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//查询群二维码配置列表
	qunlist(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/qrcode/group/list?mpid='+param.mpid, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//新增二维码配置信息
	add(param, funsucc) {
		httpapi.postjson('/customer/qiyeweixin/qrcode/qywxgroup/add', param).then(res => {
			console.log(res, 'add.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//编辑二维码配置信息
	edit(param, funsucc) {
		httpapi.postjson('/customer/qiyeweixin/qrcode/edit', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	
	//生成企业微信二维码
	create(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/qrcode/create', param).then(res => {
			console.log(res, 'create.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//删除二维码配置信息
	delete(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/qrcode/delete', param).then(res => {
			console.log(res, 'delete.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//获取企业微信二维码
	getqr(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/qrcode/qywxgroup/getqrcode', param).then(res => {
			console.log(res, 'getqr.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default qrcodeApi