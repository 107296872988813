<template>
	<div class="">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>关注消息设置</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<el-button size="small" type="primary" @click="funUseClick('txt')">启用文本</el-button>
				<el-button size="small" type="primary" @click="funUseClick('img')">启用图文</el-button>
			</div>
			<div class="box-cont">
				<div class="box-nav">
					<div class="box-item" :class="currIndex == 0 ? 'active' : ''" @click="funNavClick(0)">文本</div>
					<div class="box-item" :class="currIndex == 1 ? 'active' : ''" @click="funNavClick(1)">图文</div>
				</div>
				<div class="txt" v-if="currIndex==0" style="margin: 10px 0;">
					<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="textarea">
					</el-input>
					<el-button size="small" type="primary" @click="funUseClick('txt')">发布</el-button>
				</div>
				<div class="txt" v-if="currIndex==1">
					
				</div>
			</div>
		</el-card>
	</div>
</template>

<script>
	import gzhconfigApi from '../../../../api/cus/gzhconfig.js'
	export default {
		data() {
			return {
				textarea: "",
				currIndex: 0,
				mpid: ''
			}
		},
		mounted() {
			//lql240703
			this.mpid = this.$route.query.mpid
		},
		methods: {
			funUseClick(zt) {
				if (zt == 'txt') {
					let param = {
						mpid: this.mpid,
						msg: "111"
					}
					gzhconfigApi.txtuse(param, res => {
						console.log(res, 'txtuse.res');
						if(res.code == 200){
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.currIndex = 0
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				} else {
					let param = {
						mpid: this.mpid,
						msg: "111"
					}
					gzhconfigApi.imguse(param, res => {
						console.log(res, 'imguse.res');
						if(res.code == 200){
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.currIndex = 1
						}else{
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}

			},
			funNavClick(zt) {
				this.currIndex = zt
			}
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		justify-content: right;
	}

	.box-card {
		margin-bottom: 10px;
	}

	.box-cont {
		display: flex;
		flex-direction: column;
	}

	.box-cont .box-nav {
		display: flex;
	}

	.box-cont .box-nav .box-item {
		cursor: pointer;
		padding: 10px 20px;
	}

	.active {
		color: #fff;
		border-radius: 5px;
		background-color: #576477;
	}
</style>