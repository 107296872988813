<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		<el-tabs v-model="activeName" type="border-card">
			<el-tab-pane label="数据" name="first">
			<div class="row">
				<div >
					<el-button type="primary" icon="el-icon-plus" @click="addItem()" class="add-image">
									
					</el-button>
					<vuedraggable v-model="list.data" tag="ul" draggable="li" v-if="list.data && list.data.length > 0"
						class="image-list" :class="{ disable: data.tabType == 2 }">
						
						<li v-for="(item, index) in list.data" :key="index" @click="selitem(item,index)"
							:class="selindex == index ? 'sel' :''">
							{{ item && item.title }}
							<span @click="removeImage(selindex)" class="el-icon-close"></span>
						</li>
						
					</vuedraggable>
				</div>
				
				<div class="l-info" v-if="selindex > -1">
				
					
					<div>名称：</div>
					<!-- <span class="text">{{ item && item.title }}</span> -->
					<el-input size="medium" v-model="item.title" style="margin-top: 10px;"></el-input>
					<p class="disp">
						<span>链接：</span>
						<span class="text">{{ item && item.link && item.link.name }}</span>
						<el-button size="mini" type="primary" @click="urlPopup(selindex)">选择链接</el-button>
					</p>
				</div>
			</div>
				


			</el-tab-pane>
			<el-tab-pane label="样式" name="style">
				<!--属性设置-->
				<div class="details">
				
					<div class="list">
						<div class="title">
							<span>高度</span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.height" show-input :max="750">
							</el-slider>
						</div>
					</div>
					<!-- 圆角 -->
					<div class="list">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="800">
							</el-slider>
						</div>
					</div>
				
					<div class="list">
						<div class="title">
							<span>上边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list">
						<div class="title">
							<span>下边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="200">
							</el-slider>
						</div>
					</div>
				
					<div class="list">
						<div class="title">
							<span>页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginpage" show-input :max="200">
							</el-slider>
						</div>
					</div>
				</div>
				<!-- 背景图 -->
				<div class="disp">
					<el-button type="text" @click="funaddbgimg" size="mini">添加背景图</el-button>
					<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button>
				</div>
				<div class=" bgimg">
					<img :src="list.options.bgimg" />
				</div>
				
			</el-tab-pane>
		</el-tabs>

		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import vuedraggable from "vuedraggable";
	import designTemplataApi from "@/api/cus/designtmplate";
	import selImgs from "@/components/designer/selImgs";
	import sellink from "@/components/designer/sellink";
	export default {
		name: "image_row_edit",
		components: {
			vuedraggable,
			selImgs,
			sellink
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				list: {},
				item: {},
				url: "",
				index: null,
				show: false,
				imageIndex: null,
				activeName: "first",
				selindex: -1
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;

			if (this.list.data && this.list.data.length > 0) {
				this.selindex = 0
				this.item = this.list.data[0]
			}
		},
		methods: {
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				this.item = item
				this.selindex = index
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index) {
				this.$refs['sellink'].funShow()
			},
			removeImage(index) {
				this.list.data.splice(index, 1);
				if (this.list.data.length > index) {
					this.selindex = index
					this.item = this.list.data[index]
				} else {
					this.selindex = -1
				}
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			funaddbgimg() {
				this.imageIndex = "bgimg";
				this.$refs["selImgs"].funShow();
			},
			addImage(index) {
				this.imageIndex = index;
				this.$refs["selImgs"].funShow();
				//this.$refs["upload"].$children[0].$refs.input.click();
			},
			addItem() {
				const len = this.list.data.length;
				const col = 15;
				const row = parseInt(len / col);
				const row_num = len % col;

				let width = 25;
				let height = 40;

				let left = row_num * width;
				let top = height * row;
				if (left < 0) {
					left = 0;
				}
				if (top < 0) {
					top = 0;
				}
				const data = {
					cid: designTemplataApi.createToolBoxId(),
					left: left,
					top: top,
					width: width,
					height: height,
					title: len + 1
				};
				this.list.data.push(data);
			},
			selImgsresult(imgitem) {
				if (this.imageIndex === "bgimg") {
					//this.list.options.bgimg = imgitem.url;
					this.$set(this.list.options, 'bgimg', imgitem.url);
				} else {
					// const index = this.imageIndex;
					// const data = {
					//   url: imgitem.url,
					// };

					// if (index !== null) {
					//   this.$set(this.list.data, index, data);
					// } else {
					//   //this.list.data.push(data);
					// }
				}
			},
			sellinkresult(param) {
				const index = this.selindex;
				if (index !== null) {
					let data = this.list.data[index];
					data.link = param;
					
					this.$set(this.list.data, index, data);
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: 60px;
			height: 30px;
			line-height: 30px;
			padding: 0;
			font-size: 12px;
			margin-top: 2px;
			margin-left: 2px;
		}

		.image-list {
			margin: 0;
			width: 80px;
			overflow: auto;
			display: flex;
			flex-direction: column;
			max-height: 500px;
			overflow: auto;
			margin-right: 10px;
			
			&::-webkit-scrollbar-thumb {
				background: #F1F2F4;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #F1F2F4;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			.sel {
				color: #fff;
				background-color: #1b8bff;
			}

			li {
				list-style: none;
				background: #F1F2F4;
				border-radius: 4px;
				margin: 2px;
				width: 60px;
				height: 30px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				position: relative;
				flex-shrink: 0;
				.el-icon-close {
					color: #999;
					font-size: 12px;
					font-weight: 600;
					margin-bottom: 6px;
					cursor: pointer;
					position: absolute;
					top: 0;
					right: 0;

					&:hover {
						color: red;
					}
				}

				&.disable div {
					pointer-events: none;
					user-select: none;
				}



				.r-image {
					text-align: right;

					.el-icon-close {
						color: #999;
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 6px;
						cursor: pointer;

						&:hover {
							color: red;
						}
					}

					.image-box {
						width: 70px;
						height: 70px;
						border-radius: 5px;
						overflow: hidden;
						position: relative;
						background: #fff;
						display: flex;
						justify-content: center;
						align-items: center;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.5);
							opacity: 0;
							transition: all 0.3s;
						}

						span {
							position: absolute;
							top: 50%;
							left: 50%;
							color: #fff;
							transform: translate(-50%, -50%);
							font-size: 20px;
							cursor: pointer;
							opacity: 0;
							transition: all 0.3s;
						}

						img {
							max-width: 100%;
						}

						&:hover {

							&::before,
							span {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.l-info {
			font-size: 12px;
			padding-top: 8px;
			margin: 20px 0;
			border-top: 1px solid #f0f0f0;
			width: 100%;

			p {
				margin: 12px 0 0;
				white-space: nowrap;
				overflow: hidden;
				display: flex;

				.link {
					color: #1b8bff;
					cursor: pointer;
				}

				.text {
					white-space: nowrap;
					text-align: -webkit-auto;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}

		.list {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				margin-right: 10px;
				flex-shrink: 0;
				width: 60px;
			}

			.full {
				flex-grow: 1;

			}
		}
	}

	.disp {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.bgimg{
		width: 100%;
		max-height: 500px;
	}
	.bgimg img {
		width: 100%;
	}
	.row{
		display: flex;
	}
</style>