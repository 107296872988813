<template>
  <div class="menu">
    <p class="tips">
      标签过多，可以滑动标签<br />
      可滑动修改标签的位置
    </p>
    <vuedraggable v-model="list.data">
      <div class="list" v-for="(item, index) in list.data" :key="index">
        <div style="height: 1px"></div>
        <div class="one">
          <span>标签</span>
          <el-input v-model="list.data[index].label" size="small"></el-input>
        </div>
        <div class="two">
          <span>定位</span>
          <el-input v-model="list.data[index].postions" size="small"></el-input>
        </div></div
    ></vuedraggable>
    <div class="add">
      <el-button class="btn" icon="el-icon-plus" @click="add"
        >添加标签</el-button
      >
    </div>
    <div class="list1">
      <div>下划线颜色</div>
      <div class="er">
        <span>重置</span>
        <el-color-picker v-model="list.options.descolor"></el-color-picker>
      </div>
    </div>
    <div class="list1">
      <div>选中文字颜色</div>
      <div class="er">
        <span>重置</span>
        <el-color-picker v-model="list.options.textcolor"></el-color-picker>
      </div>
    </div>
    <div class="list1">
      <div>标签的背景颜色</div>
      <div class="er">
        <span>重置</span>
        <el-color-picker v-model="list.options.backgound"></el-color-picker>
      </div>
    </div>
    <div class="list1">
      <div>文字默认颜色</div>
      <div class="er">
        <span>重置</span>
        <el-color-picker v-model="list.options.morencolor"></el-color-picker>
      </div>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";

export default {
  name: "Menu",
  components: {
    vuedraggable,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: "",
    };
  },

  created() {
    this.list = this.data;
  },

  methods: {
    add() {
      let obj = {};
      obj.label = "";
      obj.postions = "";
      this.list.data.push(obj);
    },
  },
};
</script>

<style lang="less" scoped>
.menu {
  width: 100%;
  background: #f5f5fa;
  font-size: 14px;
}
.add {
  margin: 0 auto;

  .btn {
    margin: 20px;
    max-width: 400px;
    width: 100%;
  }
}
.list {
  width: 300px;
  margin: 0 auto;
  box-shadow: 2px 2px 2px #ccc;
  div {
    display: flex;
    align-items: center;
    span {
      width: 50px;
    }
  }
  .one {
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .two {
    margin: 0 0 10px 0;
    padding-bottom: 10px;
  }
}
.tips {
  margin: 20px;
  font-size: 14px;
}
.list1 {
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .er {
    display: flex;
    align-items: center;
    span {
      margin-right: 20px;
    }
  }
}
</style>