var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"id":"notice"}},[_c('div',{staticClass:"wrap",style:({color:_vm.list.options.textcolor,'margin-left':_vm.marginpage,
          'margin-right':_vm.marginpage,
		  'margin-top':_vm.margintop,
          'margin-bottom':_vm.marginbottom,
          'padding-top':_vm.paddingtop,
		  background:_vm.list.options.background,
          'padding-bottom':_vm.paddingbottom,'border-radius':_vm.borderradius})},[(
		      _vm.list.options && _vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
		    )?_c('div',[_c('img',{staticClass:"bgimg",attrs:{"src":_vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
		          ? _vm.list.options.bgimg
		          : ''}})]):_vm._e(),_c('div',{staticClass:"bgcont"},[_c('img',{staticClass:"tbimg",attrs:{"src":_vm.list.options.tbimg && _vm.list.options.tbimg.length > 0
			        ? _vm.list.options.tbimg
			        : ''}}),_c('span',{staticStyle:{"line-height":"50px"}},[_vm._v(_vm._s(_vm.list.options.notice||"这是一段公告这是一段公告这是一段公告这是一段公告这是一段公告"))])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }