import httpapi from '../../utils/httpapi';
const webApi = {
	domainlist(mpid, funsucc) {
		httpapi.get('/customer/wxapp/open/weixin/app/domain/get?mpid='+ mpid).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	domainset(param,funsucc){
		httpapi.post('/customer/wxapp/open/weixin/app/domain/set',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	webview(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/webview/get?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	webviewdomainset(param,funsucc){
		httpapi.post('/customer/wxapp/open/weixin/app/webview/set',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	confirmfile(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/webview/confirmfile?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	publishqrcode(param,funsucc){
		httpapi.post('/customer/wxapp/open/weixin/app/qrcode/jump/publish' + param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	},
	getqrcodrulelist(mpid,funsucc){
		httpapi.get('/customer/wxapp/open/weixin/app/qrcode/jump/get?mpid=' + mpid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	}
}
export default webApi