<template>
	<div class="main">
		<div class="cont" v-for="(citem,cindex) in menulist" :key="cindex" @click="fundetail(citem)">
			<div class="head" v-if="cindex == 0">
				<div class="logo">
					<img :src="citem.logo">
					<div class="title">
						{{citem.title}}
					</div>
				</div>
			</div>
			<div class="main-news" v-else>
				<div class="main-title">
					{{citem.title}}
				</div>
				<div class="main-img">
					<img :src="citem.logo" />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import menuApi from '../../../api/cus/menu.js'
	export default {
		components: {},
		data() {
			return {
				title: "",
				newsid: "",
				menulist: [],
				editForm: {},
				editFormVisible: false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let newsid = this.$route.query.newsid
				if (newsid != undefined) {
					this.newsid = newsid
				} else {
					this.$router.push("/");
				}
				this.getnewslist()

			},
			getnewslist() {
				let param = {
					newsid: this.newsid
				}
				menuApi.newsdetail(param, res => {
					console.log(res, 'res.newslist');
					if (res.code == 200) {
						this.menulist = res.data.list
						console.log(this.menulist, 'this.menulist');
					}
				})
			},
			fundetail(item) {
				this.$router.push({
					path: "/published/news/detail",
					query: {
						id: item.id
					}
				})
			},
		}
	}
</script>

<style scoped>
	.box-card {
		margin: 20px;
	}

	.main {
		width: 100%;
		border-radius: 5px;
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: column;
	}

	.main .footer {
		width: 100%;
		height: 30px;
		line-height: 30px;
		text-align: center;
	}

	.main .head {
		display: flex;
		align-items: center;
	}

	.main .head .logo {
		width: 100%;
		height: auto;
		max-height: 200px;
		overflow: hidden;
		position: relative;
	}

	.main .head .logo img {
		width: 100%;
	}

	.main .head .title {
		position: absolute;
		left: 0;
		bottom: 0;
		height: 50px;
		line-height: 50px;
		color: #fff;
		width: 100%;
		padding-left: 10px;
		background: rgba(0, 0, 0, 0.5);
	}

	.main-news {
		padding: 0 20px;
		min-height: 70px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main-news .main-img {
		width: 60px;
		height: 60px;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f0f0f0;
		border-radius: 10px;
	}

	.main-news .main-img img {
		width: 100%;
	}
</style>