const options = {
	image_swiper: {
		"data": [{
			"name": "夜店酒吧啤酒",
			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/2e879afdc0bf40db9b6cd99bb3ea6fbf.jpg?key=123456"
		}, {
			"name": "图片",
			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/43432e9d701e450da4218992b5ba039f.jpg?key=123456"
		}],
		"options": {
			"marginpage": 0,
			"marginbottom": 0,
			"borderradius": 0,
			"margintop": 0,
			"height": 400,
			"source": {
				"id": "",
				"type": "auto"
			}
		},
		"name": "图片轮播",
		"type": "image_swiper"
	},
	image_col: {
		"data": [{
			"name": "图片",
			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023096e4593f85eb44895b5a6ec8b26f66e4f.png?key=123456"
		}, {
			"name": "图片",
			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023093c6e60337b9c49eda1e4102fc082aed1.png?key=123456"
		}, {
			"name": "图片",
			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/169241699017262694620230997bae6a589464e928407f956c7b3f4e5.png?key=123456"
		}],
		"options": {
			"itemheight": 400,
			"marginitem": 10,
			"marginbottom": 0,
			"marginpage": 0,
			"borderradius": 0,
			"margintop": 0,
			"itemwidth": 236
		},
		"name": "图片多列",
		"type": "image_col",
	},
	image_row: {
		"data": [{
			"name": "图片",
			"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/c6889b6ced464786b679bf33dba246e0.jpg?key=123456"
		}],
		"options": {
			"marginbottom": 0,
			"marginpage": 0,
			"borderradius": 0,
			"margintop": 0
		},
		"name": "图片多行",
		"type": "image_row"
	},
	image_desk: {
		"data": [{
			"use": {
				"bgcolor": "#FFFFFF",
				"titlecolor": "#0A0A0A",
				"bgimg": ""
			},
			"unuse": {
				"bgcolor": "",
				"titlecolor": "",
				"bgimg": ""
			},
			"title": "222测试台",
			"type": "desk",
			"top": 337,
			"left": 205,
			"isdiy": 0,
			"desk": {
				"gid": "1000120180607154210099",
				"mc": "222测试台",
				"id": "10001201806071542100996"
			},
			"width": 25,
			"borderradius": 331,
			"sel": {
				"bgcolor": "",
				"titlecolor": "",
				"bgimg": ""
			},
			"cid": "CBDC0F2E0109453D",
			"height": 25
		}, {
			"top": 186,
			"left": 91,
			"use": {
				"bgcolor": "#FFFFFF",
				"titlecolor": "#0A0A0A",
				"bgimg": ""
			},
			"desk": {
				"gid": "1000120181029051503155",
				"mc": "2",
				"id": "10001201810290515031552"
			},
			"width": 25,
			"borderradius": 0,
			"unuse": {
				"bgcolor": "",
				"titlecolor": ""
			},
			"sel": {
				"bgcolor": "",
				"titlecolor": "",
				"bgimg": ""
			},
			"title": "2",
			"type": "desk",
			"cid": "EADD43574A20B72B",
			"height": 25
		}, {
			"top": 374,
			"left": 205,
			"use": {
				"bgcolor": "#FFFFFF",
				"titlecolor": "#0A0A0A",
				"bgimg": ""
			},
			"desk": {
				"gid": "1000120210225170815638",
				"mc": "666测试-1【正式】",
				"id": "10001201806071542100993"
			},
			"width": 25,
			"borderradius": 0,
			"unuse": {
				"bgcolor": "",
				"titlecolor": ""
			},
			"sel": {
				"bgcolor": "",
				"titlecolor": "",
				"bgimg": ""
			},
			"title": "666测试-1【正式】",
			"type": "desk",
			"cid": "F7AEE953CF91B73C",
			"height": 25
		}, {
			"cid": "CFEF492576934E23",
			"left": 91,
			"top": 303,
			"width": 25,
			"height": 25,
			"title": 4,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			},
			"borderradius": 0
		}, {
			"cid": "F6066E1962D5A6C5",
			"left": 91,
			"top": 222,
			"width": 25,
			"height": 25,
			"title": 5,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "6BA7B4B8A7DB9DC7",
			"left": 91,
			"top": 259,
			"width": 25,
			"height": 25,
			"title": 6,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "0298D16AC44E35CA",
			"left": 205,
			"top": 196,
			"width": 25,
			"height": 25,
			"title": 7,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			},
			"borderradius": 0
		}, {
			"cid": "D60E0E55CC6E5D51",
			"left": 145,
			"top": 130,
			"width": 25,
			"height": 25,
			"title": 8,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "2B1E7F194E1BD8B8",
			"left": 205,
			"top": 163,
			"width": 25,
			"height": 25,
			"title": 9,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "14A7CBFD35CC19D3",
			"left": 205,
			"top": 300,
			"width": 25,
			"height": 25,
			"title": 10,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "378AA655B0F41CBD",
			"left": 145,
			"top": 263,
			"width": 25,
			"height": 25,
			"title": 11,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "0C70D4E37F01716A",
			"left": 145,
			"top": 196,
			"width": 25,
			"height": 25,
			"title": 12,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			},
			"borderradius": 0
		}, {
			"cid": "AAE9A1F5B22077F1",
			"left": 145,
			"top": 300,
			"width": 25,
			"height": 25,
			"title": 13,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "ED8A67BAE0691885",
			"left": 145,
			"top": 163,
			"width": 25,
			"height": 25,
			"title": 14,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "333E7B6DAF98649F",
			"left": 205,
			"top": 130,
			"width": 25,
			"height": 25,
			"title": 15,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "ADD761E17274C446",
			"left": 204,
			"top": 263,
			"width": 25,
			"height": 25,
			"title": 16,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "70FDBF2F5DF91570",
			"left": 145,
			"top": 374,
			"width": 25,
			"height": 25,
			"title": 17,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "532CEC2A27530D71",
			"left": 145,
			"top": 337,
			"width": 25,
			"height": 25,
			"title": 18,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			},
			"borderradius": 0
		}, {
			"cid": "0BB3AC3D561725A6",
			"left": 91,
			"top": 339,
			"width": 25,
			"height": 25,
			"title": 19,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "8609598133968122",
			"left": 91,
			"top": 375,
			"width": 25,
			"height": 25,
			"title": 20,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "8CA2452634C96E01",
			"left": 260,
			"top": 222,
			"width": 25,
			"height": 25,
			"title": 21,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "A0E9DFEBF63F7FBE",
			"left": 260,
			"top": 259,
			"width": 25,
			"height": 25,
			"title": 22,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "CA77D582ECF026BD",
			"left": 260,
			"top": 303,
			"width": 25,
			"height": 25,
			"title": 23,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "1BCC57E803C25328",
			"left": 260,
			"top": 339,
			"width": 25,
			"height": 25,
			"title": 24,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "16356A3ECD1933B4",
			"left": 260,
			"top": 375,
			"width": 25,
			"height": 25,
			"title": 25,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}, {
			"cid": "B7B675C4C7A162DE",
			"left": 260,
			"top": 186,
			"width": 25,
			"height": 25,
			"title": 26,
			"type": "desk",
			"use": {
				"titlecolor": "#0A0A0A",
				"bgcolor": "#FFFFFF",
				"bgimg": ""
			},
			"unuse": {
				"titlecolor": "",
				"bgcolor": ""
			},
			"sel": {
				"titlecolor": "",
				"bgcolor": "",
				"bgimg": ""
			}
		}],
		"options": {
			"use": {
				"bgcolor": "#FFFFFF",
				"titlecolor": "#0A0A0A",
				"bgimg": "https://gzh.dxerp.net/dxgzh/1706239812474437634/202405/2d176765b2dd43509a960f1509e2d590.png?key=123456"
			},
			"marginbottom": 0,
			"marginpage": 0,
			"borderradius": 0,
			"desknotice": {
				"bgimg": "https://gzh.dxerp.net/dxgzh/1706239812474437634/202310/bbc7676042e543f79cc535b011c4f50e.png?key=123456",
				"title": "1:订金将自动转换为会员余额，现场扫中间酒水码点单时可抵扣(如订金使用则无法退款); 2:每天仅限预定一次，请确认好信息再预定哦，预定成功座位信息无法更换; 3:预订成功后当天到场只需向前台提供开台码核对后方可入场，(预订日周一至周日及节假日21:00前到场 不要迟到哟); 4:如到店人数低于预订人数的一半将不予进场; 5:恶意预订，门店有权设将其设为黑名单，以后将不可预订 6:未满十八岁禁止入内，孕妇慎入，宠物禁止入的"
			},
			"showbg": 1,
			"unuse": {
				"bgcolor": "",
				"titlecolor": "",
				"bgimg": "https://gzh.dxerp.net/dxgzh/1706239812474437634/202405/36002637bbe94cb0a25e539665dc945b.png?key=123456"
			},
			"showtitle": 1,
			"sel": {
				"bgcolor": "",
				"titlecolor": "",
				"bgimg": "https://gzh.dxerp.net/dxgzh/1706239812474437634/202405/5a3589ab00a2483785e5ae5324a9504c.png?key=123456"
			},
			"bgimg": "https://gzh.dxerp.net/dxgzh/1706239812474437634/202405/ae83330a45254a1d955c86615c8bba26.png?key=123456",
			"margintop": 0,
			"height": 1057
		},
		"name": "座位图",
		"type": "image_desk",
		
	},
	coupon_list: {
		"type": "coupon_list",
		"data": [{
			"url": ""
		}, {
			"url": ""
		}, {
			"url": ""
		}, {
			"url": ""
		}],
		"options": {
			"itemwidth": 230,
			"itemheight": 200,
			"borderradius": 10,
			"margintop": 10,
			"marginbottom": 10,
			"marginitem": 9,
			"marginpage": 5,
			"stylebox": 2,
			"btnradius": 0,
			"bgcolor": "#876D6D",
			"btnbgcolor": "#4A4040",
			"color": "#FFFFFF",
			'btncolor': "#FFFFFF"
		},
		"name": "2.优惠券",
	},
	image_qunqr: {
		"type": "image_qunqr",
		"data": [],
		"options": {
			"borderradius": 0,
			"height": 210,
			"margintop": 10,
			"marginbottom": 0,
			"paddingpage": 0,
			"ewmimg": "http://gzh.dxerp.net/dxgzh/1692416990172626946/202309/d28e7df2636745bbb60f5b8ec3d20758.jpg?key=123456",
			"bgimg": "http://gzh.dxerp.net/dxgzh/1692416990172626946/202309/cc655f3495744f56a5fc18425f0a98b5.jpg",
			"bold": 1,
			"titlecolor": "#737373",
			"inputbg": "#FFFFFF",
			"bordercolor": "#737373"
		},
		"name": "4.社群福利"
	},
	menu_img_title: {
		"data": [{
			"form": {},
			"top": 0,
			"left": 0,
			"name": "",
			"width": 200,
			"link": {
				"app": {
					"path": "/pages/club/ecardlist",
					"name": "礼品卡"
				},
				"name": "站内链接:礼品卡",
				"type": "app",
				"key": "ecard"
			},
			"title": "礼品卡",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309653bc1d030ab448ca13a436ad624c2f4.png",
			"height": 200
		}, {
			"form": {},
			"top": 0,
			"left": 275,
			"name": "",
			"width": 200,
			"link": {
				"app": {
					"path": "/pages/club/club_tickets",
					"name": "我的礼券"
				},
				"name": "站内链接:我的礼券",
				"type": "app",
				"key": "mytickets"
			},
			"title": "优惠券",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309595b78fcce5e4768bb875fd6fb8184df.png",
			"height": 200
		}, {
			"form": {},
			"top": 0,
			"left": 550,
			"name": "",
			"width": 200,
			"link": {
				"app": {
					"path": "/pages/club/club_accetpticket",
					"name": "领券中心"
				},
				"name": "站内链接:领券中心",
				"type": "app",
				"key": "handtickets"
			},
			"title": "领券中心",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/16924169901726269462023099f2a93d3967d40e2bc57da74ab9e941d.png",
			"height": 200
		}, {
			"top": 0,
			"left": 0,
			"link": {
				"app": {
					"path": "/pages/club/club_scoreshop",
					"name": "积分商城"
				},
				"name": "站内链接:积分商城",
				"type": "app",
				"key": "scoreshop"
			},
			"width": 200,
			"title": "积分商城",
			"margintop": 20,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/169241699017262694620230955ed009279ce45ceaf74c8219f54275d.png",
			"height": 200
		}, {
			"top": 0,
			"left": 0,
			"link": {
				"app": {
					"path": "/pages/club/club_booklist",
					"name": "我的预约"
				},
				"name": "站内链接:我的预约",
				"type": "app",
				"key": "booklist"
			},
			"width": 200,
			"title": "我的预约",
			"margintop": 20,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/16924169901726269462023094a41b120f800499dbd466f05770d2b75.png",
			"height": 200
		}, {
			"top": 0,
			"left": 0,
			"link": {
				"app": {
					"path": "/pages/paiwei/index",
					"name": "在线排位"
				},
				"name": "站内链接:在线排位",
				"type": "app",
				"key": "paiwei"
			},
			"width": 200,
			"title": "在线排位",
			"margintop": 20,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309b6b8088b8ec44dc09a2cb4085891e9bd.png",
			"height": 200
		}, {
			"title": "我的门票",
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202311/5cd5dbfec6e148e39f1b742973710969.png?key=123456",
			"link": {
				"type": "none",
				"name": "无链接"
			},
			"width": 200,
			"height": 200,
			"left": 0,
			"top": 0,
			"margintop": 20
		}, {
			"top": 0,
			"left": 0,
			"link": {
				"app": {
					"path": "/pages/active/buyticket",
					"name": "预售套餐"
				},
				"name": "站内链接:预售套餐",
				"type": "app",
				"key": "buytickets"
			},
			"width": 200,
			"title": "预售菜单",
			"margintop": 20,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202310/1d693373720c4ca58a782eba960a2a9a.png?key=123456",
			"height": 200
		}, {
			"top": 0,
			"left": 0,
			"link": {
				"app": {
					"path": "",
					"name": "退出登录"
				},
				"name": "站内链接:退出登录",
				"type": "app",
				"key": "loginout"
			},
			"width": 200,
			"title": "退出登录",
			"margintop": 20,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309e5023251ab96451a9147b06dc8af7676.png",
			"height": 200
		}],
		"options": {
			"marginbottom": 0,
			"lineheight": 26,
			"linecolor": "#373737",
			"titlecolor": "#E9E9E9",
			"bgimg": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202310/1d693373720c4ca58a782eba960a2a9a.png?key=123456",
			"imgsize": 38,
			"bold": 2,
			"itemnum": 4,
			"contcolor": "#20232B",
			"bgcolor": "",
			"size": 2,
			"marginpage": 16,
			"bgimgform": {},
			"borderradius": 10,
			"width": 750,
			"itempaddingtop": 12,
			"margintop": 16,
			"height": 1200,
			"direction": 2
		},
		"name": "7.图文导航",
		"type": "menu_img_title",
	},
	image_spot: {
		"type": "image_spot",
		"data": [{
			"cid": "0F6C6BCAEFB05797",
			"left": 43,
			"top": 217,
			"width": 25,
			"height": 40,
			"title": 1
		}, {
			"cid": "127C34F8DD30ED82",
			"left": 88,
			"top": 241,
			"width": 25,
			"height": 40,
			"title": 2
		}, {
			"cid": "EB302D0C2B8B75AC",
			"left": 106,
			"top": 289,
			"width": 25,
			"height": 40,
			"title": 3
		}],
		"options": {
			"height": 0,
			"borderradius": 0,
			"margintop": 0,
			"marginbottom": 0,
			"marginpage": 0,
			"bgimg": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/45a470302758462a8ffcf7e5c6555191.png?key=123456"
		},
		"name": "3.热区切图"
	},
	image_magic: {
		"data": [{
			"top": 15,
			"left": 22,
			"link": {
				"app": {
					"path": "/pages/diandan/index",
					"name": "扫码点单",
					"type": "diandan"
				},
				"name": "站内链接:扫码点单",
				"type": "app"
			},
			"width": 158,
			"height": 212,
			"child": [{
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023095de398fa5293464d8fe53f58ab86675a.png?key=123456",
				"bgcolor": "#F7420B",
				"top": 4,
				"size": 1,
				"left": 2,
				"width": 154,
				"borderradius": 11,
				"position": 2,
				"cid": "76550C75CB72B20B",
				"height": 204
			}, {
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309d11f167ce6db4697a48df406f421b45c.png?key=123456",
				"bgcolor": "#F7420B",
				"top": 132,
				"size": 1,
				"left": 17,
				"width": 124,
				"borderradius": 0,
				"position": 2,
				"cid": "13CD769142080528",
				"height": 55
			}, {
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023094ac84538f6404061b68c86e4a1b76043.png?key=123456",
				"bgcolor": "#F7420B",
				"top": 18,
				"size": 1,
				"left": 25,
				"width": 102,
				"borderradius": 0,
				"position": 2,
				"cid": "157BD129F5639F04",
				"height": 99
			}]
		}, {
			"top": 15,
			"left": 198,
			"link": {
				"app": {
					"path": "/pages/diandan/index",
					"name": "扫码点单",
					"type": "diandan"
				},
				"name": "站内链接:扫码点单",
				"type": "app"
			},
			"width": 157,
			"height": 100,
			"child": [{
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023095de398fa5293464d8fe53f58ab86675a.png?key=123456",
				"bgcolor": "#F7420B",
				"top": 2,
				"size": 1,
				"left": 3,
				"width": 149,
				"borderradius": 10,
				"position": 2,
				"cid": "3A9BF9A05FF23B1A",
				"height": 93
			}, {
				"titlecolor": "#FFFFFF",
				"bold": 2,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023092fb1aacc8f4845d7aaacd5f48bf2f3ab.png?key=123456",
				"bgcolor": "#F7420B",
				"top": 14,
				"size": 1,
				"left": 15,
				"width": 67,
				"borderradius": 0,
				"position": 2,
				"cid": "09A7AB5337D67271",
				"height": 66
			}, {
				"titlecolor": "#FFFFFF",
				"bold": 2,
				"type": "text",
				"title": "订台",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 23,
				"size": 1,
				"left": 93,
				"width": 50,
				"borderradius": 0,
				"position": 2,
				"cid": "9753816485FB3EB4",
				"height": 34
			}, {
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "text",
				"title": "dingtai",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 52,
				"size": 3,
				"left": 86,
				"width": 59,
				"borderradius": 0,
				"position": 2,
				"cid": "1358767547CB0C9D",
				"height": 18
			}]
		}, {
			"top": 127,
			"left": 198,
			"link": {
				"app": {
					"path": "/pages/diandan/index",
					"name": "扫码点单",
					"type": "diandan"
				},
				"name": "站内链接:扫码点单",
				"type": "app"
			},
			"width": 72,
			"height": 100,
			"child": [{
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
				"bgcolor": "#F7420B",
				"top": -8,
				"size": 1,
				"left": -3,
				"width": 81,
				"borderradius": 0,
				"position": 2,
				"cid": "CF15709DA5A4344A",
				"height": 110
			}, {
				"titlecolor": "#000000",
				"bold": 1,
				"type": "text",
				"title": "礼品卡",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 46,
				"size": 2,
				"left": 3,
				"width": 67,
				"borderradius": 0,
				"position": 2,
				"cid": "90215F364DF3722A",
				"height": 26
			}, {
				"titlecolor": "#9E9E9E",
				"bold": 1,
				"type": "text",
				"title": "LIPINKA",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 68,
				"size": 3,
				"left": 2,
				"width": 69,
				"borderradius": 0,
				"position": 2,
				"cid": "6703795CEBD500F6",
				"height": 26
			}, {
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309f132cc921e9743289d38d6547d8b9c66.png?key=123456",
				"bgcolor": "#F7420B",
				"top": 6,
				"size": 1,
				"left": 14,
				"width": 45,
				"borderradius": 0,
				"position": 2,
				"cid": "A634F2343CFB83C9",
				"height": 36
			}]
		}, {
			"top": 127,
			"left": 283,
			"link": {
				"app": {
					"path": "/pages/diandan/index",
					"name": "扫码点单",
					"type": "diandan"
				},
				"name": "站内链接:扫码点单",
				"type": "app"
			},
			"width": 72,
			"height": 100,
			"child": [{
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023099777a932a3c3467f9f2c85c004262171.png?key=123456",
				"bgcolor": "#F7420B",
				"top": -5,
				"size": 1,
				"left": -6,
				"width": 82,
				"borderradius": 0,
				"position": 2,
				"cid": "0FBEE96CE4D7CB4F",
				"height": 109
			}, {
				"titlecolor": "#000000",
				"bold": 1,
				"type": "text",
				"title": "优惠券",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 47,
				"size": 2,
				"left": -2,
				"width": 72,
				"borderradius": 0,
				"position": 2,
				"cid": "F967E5F43C81E11E",
				"height": 24
			}, {
				"titlecolor": "#A5A5A5",
				"bold": 1,
				"type": "text",
				"title": "YOUHUI",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 69,
				"size": 3,
				"left": 0,
				"width": 70,
				"borderradius": 0,
				"position": 2,
				"cid": "B92872BF1E0CFA3B",
				"height": 22
			}, {
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"type": "image",
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/16924169901726269462023091d7741cb489d40e6976e124259613dbb.png?key=123456",
				"bgcolor": "#F7420B",
				"top": 3,
				"size": 1,
				"left": 14,
				"width": 42,
				"borderradius": 0,
				"position": 2,
				"cid": "39D8039543969552",
				"height": 38
			}]
		}],
		"options": {
			"marginbottom": 0,
			"marginpage": 0,
			"borderradius": 10,
			"bgimg": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/47f05b9e0e7340438745f4dafdb09b6a.jpg?key=123456",
			"margintop": 0,
			"height": 490
		},
		"name": "7.魔方图",
		"type": "image_magic",
	},

	shop_info_nav: {
		"data": {
			// "flag": {
			//     "bgcolor": "#F7420B",
			//     "top": 171,
			//     "size": 1,
			//     "left": 102,
			//     "width": 62,
			//     "borderradius": 10,
			//     "titlecolor": "#FFFFFF",
			//     "position": 2,
			//     "bold": 1,
			//     "title": "按钮",
			//     "url": "",
			//     "height": 35
			// },
			"headimg": {
				"bgcolor": "#F7420B",
				"top": 0,
				"size": 1,
				"left": 0,
				"width": 100,
				"borderradius": 354,
				"titlecolor": "#FFFFFF",
				"position": 2,
				"bold": 1,
				"title": "",
				"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/7b3e51ee5ba948bcabb51bc3ee2b772f.jpg",
				"height": 180,
				"isflag": true,
			},
			"phone": {
				"center": 1,
				"isflag": true,
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"title": "",
				"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/6ca20b54cd7e469ca5d6c38fe58377f6.png",
				"bgcolor": "#FFFFFF",
				"top": 78,
				"size": 1,
				"left": 318,
				"width": 32,
				"borderradius": 60,
				"position": 2,
				"height": 32
			},
			"more": {
				"center": 1,
				"isflag": true,
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"title": "更多门店>>",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 85,
				"size": 2,
				"left": 32,
				"width": 80,
				"borderradius": 0,
				"position": 1,
				"height": 40
			},
			"linename": {
				"isflag": false,
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"title": "线路>>",
				"url": "",
				"bgcolor": "#FFFFFF",
				"top": 85,
				"size": 2,
				"left": 32,
				"width": 80,
				"borderradius": 0,
				"position": 1,
				"height": 40,
				"center": 1
			},
			"shopname": {
				"isflag": true,

				"top": 18,
				"size": 18,
				"left": 90,
				"width": 242,
				"borderradius": 0,
				"titlecolor": "#FFFFFF",
				"position": 1,
				"bold": 1,
				"title": "酒吧名称",
				"url": "",
				"height": 25,
				"center": 0
			},
			"deskname": {

				"top": 18,
				"size": 18,
				"left": 150,
				"width": 242,
				"borderradius": 0,
				"titlecolor": "#FFFFFF",
				"position": 1,
				"bold": 1,
				"title": "桌台名称",
				"url": "",
				"height": 25,
				"center": 1,
				"isflag": true
			},
			"logo": {
				"bgcolor": "#FFFFFF",
				"top": 18,
				"size": 1,
				"left": 28,
				"width": 48,
				"borderradius": 60,
				"titlecolor": "#FFFFFF",
				"position": 2,
				"bold": 1,
				"title": "",
				"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/4f99bb1a0d504c54ad93dc8e3a698b99.jpg?key=123456",
				"height": 48,
				"isflag": true,
			},
			"map": {
				"isflag": true,
				"titlecolor": "#FFFFFF",
				"bold": 1,
				"title": "",
				"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/18634dd39dcd4d5c8c5d817c5fc92294.png",
				"bgcolor": "#FFFFFF",
				"top": 79,
				"size": 1,
				"left": 272,
				"width": 32,
				"borderradius": 60,
				"position": 2,
				"height": 32
			},
			"notice": {
				"bgcolor": "#FFFFFF",
				"top": 49,
				"size": 1,
				"left": 90,
				"width": 138,
				"borderradius": 0,
				"titlecolor": "#FFFFFF",
				"position": 1,
				"bold": 1,
				"title": "商家介绍",
				"url": "",
				"height": 23,
				"center": 0,
				"isflag": true,
			}
		},
		"options": {
			"bgcolor": "#EDA50A",
			"borderradius": 0,
			"height": 128,
			"left": 0,
			"marginbottom": 0,
			"marginpage": 0,
			"margintop": 0,
			"paddingbottom": 0,
			"paddingtop": 0,
			"styleid": "s1",
			"top": 0
		},
		"name": "5.店铺信息",
		"type": "shop_info_nav"
	},

	foot_nav_cube: {
		"data": [{
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/760aca66a43647aab255a61d9e0f5f13.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/index/index",
					"name": "首页"
				},
				"name": "站内链接:首页",
				"type": "app",
				"key": "index"
			},
			"title": "首页",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309c51d460fd1e24724b607b632db7a43cd.png?key=123456",
			"height": 50
		}, {
			"form": {},
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/113b5eb6c100415cbceb504cec19b166.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/club/club_savewine",
					"name": "我的存酒"
				},
				"name": "站内链接:我的存酒",
				"type": "app",
				"key": "mysavewine"
			},
			"title": "存酒",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/169241699017262694620230991aee83b2ff7495a8c2cfd9bff47b92c.png?key=123456",
			"height": 50
		}, {
			"form": {},
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/fa2ac1478f614d26ae12e3ef3be0b97c.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/club/club_orders",
					"name": "酒水订单"
				},
				"name": "站内链接:酒水订单",
				"type": "app",
				"key": "myorders"
			},
			"title": "订单",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309483fecdac5434b349a1b36062f8d1450.png?key=123456",
			"height": 50
		}, {
			"form": {},
			"top": 0,
			"left": 0,
			"url2": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/9107a3766ad248d7973508fcb01200a8.png?key=123456",
			"width": 50,
			"link": {
				"app": {
					"path": "/pages/club/club_index",
					"name": "我的"
				},
				"name": "站内链接:我的",
				"type": "app",
				"key": "my"
			},
			"title": "我的",
			"margintop": 0,
			"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/16924169901726269462023092e337a7282f74e0da306ed5c5b3a9aed.png?key=123456",
			"height": 50
		}],
		"options": {
			"size": 3,
			"paddingbottom": 0,
			"marginbottom": 0,
			"marginpage": 0,
			"borderradius": 0,
			"width": 800,
			"bgimg": "",
			"paddingtop": 0,
			"margintop": 0,
			"height": 120,
			"stylestate": ""
		},
		"type": "foot_nav_cube"
	},
	club_newadd: {
		"data": [],
		"options": {
			"margintop": 0,
			"marginbottom": 0,
			"paddingpage": 10,
			"btnshow": true,
			"name": "标题内容",
			"label": "描述内容",
			"btn": "立即入会",
			"bgimg": "",
			"borderradius": 0,
			"background": "#FADCB8",
			"bold": 1
		},
		"name": "1.办理会员",
		"type": "club_newadd"
	},
	account_login: {
		"data": [],
		"options": {
			"margintop": 0,
			"marginbottom": 0,
			"paddingpage": 10,
			"btnshow": true,
			"name": "标题内容",
			"label": "描述内容",
			"btn": "注册/登录",
			"bgimg": "",
			"borderradius": 0,
			"background": "#FADCB8",
			"bold": 1
		},
		"name": "2.账户登录",
		"type": "account_login"
	},
	club_asset: {
		"data": [],
		"options": {
			"btntxt": "",
			"marginbottom": 0,
			"linecolor": "#E7E7E7",
			"titlecolor": "#202020",
			"bgimg": "",
			"backgroundun": "#FADCB8",
			"logo": "https://gzh.dxerp.net/dxgzh/1692416990172626946/16924169901726269462023095f301d083dcd4376b61445b3e143a5f5.png?key=123456",
			"margintop": 16,
			"loginstate": 1,
			"describecolor": "#1E1E1E",
			"clubradius": 0,
			"clubbtntxt": "",
			"label": "",
			"loginimg": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/6d93b3e5b95b4f88b6ac2dc93cc9cf34.png?key=123456",
			"loginimgclub": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/fb6ef0a33d794f969e17e097677fda57.png",
			"clubname": "",
			"paddingpage": 10,
			"background": "#FFFFFF",
			"clublabel": "",
			"name": "",
			"borderradius": 10,
			"ewm": "https://gzh.dxerp.net/dxgzh/1692416990172626946202309471baca5f7b44ef989c52b24f0e46b05.png?key=123456",
			"clubmargin": 0,
			"backgroundunclub": "#FADCB8",
			"clubcolor": "#1C1C1C"
		},
		"name": "10.会员资产",
		"type": "club_asset"
	},
	text_notice: {
		"data": [],
		"options": {
			"tbimg": "http://gzh.dxerp.net/dxgzh/1692416990172626946/1692416990172626946202309351fe68e2cad4e059ac68a7f5bf35d46.png?key=123456",
			"paddingbottom": 0,
			"background": "#000000",
			"marginbottom": 0,
			"marginpage": 0,
			"textcolor": "#FFFFFF",
			"paddingtop": 0,
			"margintop": 0,
			"borderradius": 0,
			"source": {
				"id": "",
				"type": "auto"
			}
		},
		"name": "3.公告",
		"type": "text_notice"
	},
	text_title: {
		"data": [],
		"options": {
			"titlecolor": "#080000",
			"background": "#FFFFFF",
			"name": "标题",
			"size": 12,
			"border": false,
			"label": "",
			"describe": 3,
			"describecolor": "#070000",
			"bold": 1,
			"position": 1

		},
		"name": "标题",
		"type": "text_title"
	},
	news_list: {
		"data": [],
		"options": {
			"borderradius": 10,
			"margintop": 10,
			"marginbottom": 10,
			"paddingpage": 10,
			"bgimg": "http://gzh.dxerp.net/dxgzh/1692416990172626946/202309/c7a76ffed7da4d519aaa1a68fbff692c.jpg",
			"titleshow": true,
			"name": "新闻内容",
			"titlecolor": "#FFFFFF",
			"titlebgcolor": "#000000",
			"height": 80,
			"bold": 1
		},
		"name": "1.门店动态",
		"type": "news_list"
	},
	image_join: {
		"type": "image_join",
		"data": [],
		"options": {
			"borderradius": 0,
			"margintop": 10,
			"marginbottom": 10,
			"paddingpage": 10,
			"ewmimg": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/d28e7df2636745bbb60f5b8ec3d20758.jpg",
			"name": "快速吸粉",
			"describe": 1,
			"size": 16,
			"ewmshow": true,
			"background": "#2A292F",
			"titlecolor": "#FFFFFF",
			"label": "快速吸粉",
			"describecolor": "#FFFFFF",
			"bgimg": "",
			"bold": 1
		},
		"name": "2.快速吸粉",
	},
	image_share: {
		"type": "image_share",
		"data": [],
		"options": {
			"borderradius": 0,
			"margintop": 10,
			"marginbottom": 10,
			"paddingpage": 10,
			"btnshow": true,
			"bold": 1,
			"name": "一键分享",
			"label": "一键分享",
			"btn": "一键分享",
			"titlecolor": "#FFFFFF",
			"describecolor": "#FFFFFF",
			"background": "#FADCB8",
			"bgimg": ""
		},
		"name": "3.一键分享"
	}


}
export default options;