<template>
  <div id="card">
    <!--添加列表 -->
    <div class="" >
      <el-button type="text" @click="add">添加列表</el-button>
      <el-form ref="form" label-width="80px" size="small">
        <el-form-item label="专题名称">
          <el-input v-model="list.options.name"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <p class="tips">最多添加50篇，按照添加顺序排列</p>
    <div class="details">
      <vuedraggable v-model="list.data">
        <div class="list" v-for="(item, index) in list.data" :key="index">
          <div class="pic"><i class="el-icon-plus"></i></div>
          <div class="nr">
            <el-form ref="form" :model="item" label-width="80px" size="small">
              <el-form-item label="标题">
                <el-input v-model="item.title"></el-input>
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="item.miaoshu"></el-input>
              </el-form-item>
              <el-form-item label="价格">
                <el-input v-model="item.price"></el-input>
              </el-form-item>
              <el-form-item label="数量">
                <el-input v-model="item.num"></el-input>
              </el-form-item>
            </el-form>
          </div></div
      ></vuedraggable>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";

export default {
  name: "小程序模块话Card",
  components: {
    vuedraggable,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return { list: "", form: {} };
  },

  created() {
    this.list = this.data;
  },

  methods: {
    add() {
      let obj = {};
      obj.title = "";
      obj.miaoshu = "";
      obj.price = "";
      obj.num = "";
      this.list.data.push(obj);
    },
  },
};
</script>

<style lang="less" scoped>
#card {
  margin: 20px;
  font-size: 14px;
}
.details {
}
.add {
  width: 50px;
  height: 50px;
  display: flex;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  border: 1px solid #ccc;
}
.tips {
  font-size: 14px;
  color: #ccc;
  margin: 10px 0;
}
.list {
  display: flex;
  box-shadow: 0px 0px 1px #000;
  margin-bottom: 20px;
  .pic {
    margin: 10px;
    width: 50px;
    height: 50px;
    border: 1px solid #ccc;
    font-size: 24px;
    display: flex;
    font-size: 24px;
    align-items: center;
    justify-content: center;
  }
  .nr {
    margin: 10px;
  }
}
.box {
  span {
    display: block;
    margin-bottom: 10px;
  }
}
</style>