<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>系统模板分类</span>
				<div class="" style="display: flex;justify-content: left;">
					<el-input type="text" placeholder="请输入分类名称" v-model="formInline.name" size="small" class="mr"></el-input>
					<el-select v-model="formInline.clstype" filterable size="small" placeholder="请选择分类类型" class="mr" @change="funselcls">
						<el-option v-for="item in clsData" :key="item.code" :label="item.name" :value="item.code">
						</el-option>
					</el-select>
					<el-button size="small" type="primary" @click.stop="funSearch">搜索</el-button>
					<el-button size="small" type="primary" @click="funAddcls">新增</el-button>
				</div>
			</div>

			<!--列表-->
			<el-table size="small" :data="clssysData" highlight-current-row v-loading="loading" :border="false"
				element-loading-text="拼命加载中" style="width: 100%;">
				<el-table-column align="center" prop="clsname" label="分类名称" width="180"></el-table-column>
				<el-table-column align="center" sortable prop="typecls" label="分类类型" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.typecls=='style'">风格</span>
						<span v-else-if="scope.row.typecls=='hangye'">行业</span>
						<span v-else>销售</span>
					</template>
				</el-table-column>
				<el-table-column align="center" sortable prop="isuse" label="是否启用" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.isuse==1">启用</span>
						<span v-else>停用</span>
					</template>
				</el-table-column>
				<el-table-column align="center" sortable prop="istj" label="是否推荐首页" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.istj==1">启用</span>
						<span v-else>停用</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="200">
					<template slot-scope="scope">
						<el-button size="mini" @click="clsEdit(scope.$index, scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页组件 -->
			<!-- <Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather" ></Pagination> -->
		</el-card>
		<!-- 编辑界面 -->
		<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
			<el-form label-width="150px" ref="editForm" :model="editForm">
				<el-form-item label="分类名称" prop="clsname">
					<el-input size="small" v-model="editForm.clsname" auto-complete="off" style="width: 200px;"
						placeholder="请输入分类名称"></el-input>
				</el-form-item>
				<el-form-item label="是否推荐首页" prop="istj">
					<el-switch v-model="editForm.istj"></el-switch>
					<!-- <el-input size="small" v-model="editForm.istj" auto-complete="off"></el-input> -->
				</el-form-item>
				<el-form-item label="是否启用" prop="isuse">
					<!-- <el-input size="small" v-model="editForm.isuse" auto-complete="off"></el-input> -->
					<el-switch v-model="editForm.isuse"></el-switch>
				</el-form-item>
				<el-form-item label="选择分类类型" prop="clstype">
					<el-select v-model="editForm.clstype" filterable size="small" placeholder="请选择分类类型" class="mr" @change="funselcls">
						<el-option v-for="item in clsData" :key="item.code" :label="item.name" :value="item.code">
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='editFormVisible=false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title"
					@click="submitForm('editForm')">保存
				</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue'
	import tempsysApi from '../../../../api/sys/tempsys.js'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "",
				loading: false,
				formInline: {
					name: "",
					clstype:'style',
					pageno: 1,
					pagesize: 10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
				clsData: [],
				editForm: {
					clstype:'',
					clsname:'',
					istj:1,
					isuse:1
				},
				editFormVisible: false,
				clssysData: [],
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init(page) {
				this.getclstype()
				this.getclslist()
			},
			getclstype(){
				tempsysApi.clstype({}, res => {
					console.log(res, 'res.clstype');
					if (res.code == 200) {
						this.clsData = res.data
					}
				})
			},
			getclslist() {
				let param = {
					clsname: this.formInline.name,
					typecls: this.formInline.clstype
				}
				console.log(param,'param.clsmanagerlist');
				tempsysApi.clsmanagerlist(param, res => {
					console.log(res, 'res.clsmanagerlist');
					if (res.code == 200) {
						this.clssysData = res.data
					}
				})
			},
			funselcls() {
				this.getclslist()
			},
			funSearch(){
				this.getclslist()
			},
			funAddcls(){
				this.title = "新增分类"
				this.editForm.clsid = ""
				this.editForm.clsname = ""
				this.editForm.istj = 1
				this.editForm.isuse = 1
				this.editForm.orderno = ""
				this.editForm.clstype = ""
				this.editFormVisible = true
			},
			submitForm(){
				if(this.title = "新增分类"){
					let param = {
						clsid:this.editForm.clsid,
						clsname: this.editForm.clsname,
						typecls: this.editForm.clstype,
						isuse: this.editForm.isuse ? 1 : 0,
						istj: this.editForm.istj ? 1 : 0,
					}
					console.log(param,'param.clsedit');
					tempsysApi.clsedit(param, res => {
						console.log(res, 'res.clsedit');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "新增成功"
							})
							this.editFormVisible = false
							this.getclslist()
						}else{
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					})
				}else{
					let param = {
						clsid:this.editForm.clsid,
						clsname: this.editForm.clsname,
						typecls: this.editForm.clstype,
						isuse: this.editForm.isuse ? 1 : 0,
						istj: this.editForm.istj ? 1 : 0,
					}
					console.log(param,'param.clsedit');
					tempsysApi.clsedit(param, res => {
						console.log(res, 'res.clsedit');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "编辑成功"
							})
							this.editFormVisible = false
							this.getclslist()
						}else{
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					})
				}
			},
			clsEdit(index, row) {
				console.log(row,'row');
				this.title = "编辑分类"
				this.editForm.clsid = row.clsid
				this.editForm.clsname = row.clsname
				this.editForm.istj = row.istj == 1 ? true : false
				this.editForm.isuse = row.isuse == 1 ? true : false
				this.editForm.orderno =  row.orderno
				this.editForm.clstype =  row.typecls
				this.editFormVisible = true
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.init(this.formInline)
			},
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main {
		height: 500px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		overflow-y: auto;
	}

	.main .box {
		border: 2px solid #000;
		border-radius: 10px;
		margin: 10px;
		padding: 10px;
		height: 375px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}

	.main .box .bg {
		display: none;
	}

	.main .box:hover .bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.6);
	}

	.main .box .img {
		width: 150px;
		height: auto;
	}

	.main .box .img img {
		width: 100%;
		height: 100%;
	}

	.search {
		padding-bottom: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 10px 20px !important;
	}
	.mr{
		margin: 0 10px;
	}
</style>