import httpapi from '../../utils/httpapi';
const linkApi = {
	//程序内链接-分页
	list(funsucc) {
		httpapi.get('isv/resource/linkpath/list', {}).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},

}
export default linkApi