import httpapi from '../../utils/httpapi';
const gzhconfigApi = {
	//无匹配-添加图文消息
	add(param, funsucc) {
		httpapi.postjson('/customer/account/config/nocmd/img/add', param).then(res => {
			console.log(res, 'add.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//无匹配-发布图文消息
	send(param, funsucc) {
		httpapi.post('/customer/account/config/nocmd/img/send', param).then(res => {
			console.log(res, 'send.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//无匹配-启用图文消息
	imguse(param, funsucc) {
		httpapi.post('/customer/account/config/nocmd/img/use', param).then(res => {
			console.log(res, 'imguse.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//无匹配-启用文本消息
	txtuse(param, funsucc) {
		httpapi.post('/customer/account/config/nocmd/txt/use', param).then(res => {
			console.log(res, 'txtuse.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//无匹配消息-明细
	detail(param, funsucc) {
		console.log(param, 'jobsearch.param');
		httpapi.get('/customer/account/config/nocmd/detail?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//首次关注消息-明细
	gzdetail(param, funsucc) {
		console.log(param, 'jobsearch.param');
		httpapi.get('/customer/account/config/subscribe/detail?mpid=' + param.mpid, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//首次关注消息设置-添加图文消息
	gzadd(param, funsucc) {
		httpapi.post('/customer/account/config/subscribe/img/add', param).then(res => {
			console.log(res, 'use.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//首次关注消息设置-发布图文消息
	gzsend(param, funsucc) {
		httpapi.post('/customer/account/config/subscribe/img/send', param).then(res => {
			console.log(res, 'use.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//首次关注消息设置-启用图文消息
	gzimguse(param, funsucc) {
		httpapi.post('/customer/account/config/subscribe/img/use', param).then(res => {
			console.log(res, 'use.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//首次关注消息设置-启用文本消息
	gztxtuse(param, funsucc) {
		httpapi.post('/customer/account/config/subscribe/txt/use', param).then(res => {
			console.log(res, 'use.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	}

}
export default gzhconfigApi