<template>
	<div class="content">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="17"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<div class="title">大新酒吧会员客户端小程序隐私保护指引</div>
				<div class="memo">本指引是大新酒吧会员客户端小程序开发者 "成都久智大新软件有限公司"（以下简称“开发者”）为处理你的个人信息而制定。</div>
				<div class="title2">1.开发者处理的信息</div>
				<div class="memo2">根据法律规定，开发者仅处理实现小程序功能所必要的信息。</div>
				<div class="iptlist">
					<div class="iptitem" v-for="(item,index) in setting_list" :key="index">
						<div>为了</div>
						<el-input class="ipt" v-model="item.privacy_text"></el-input>
						<div>
							,开发者将在获取你的明示同意后，获取{{item.privacy_key == 'PhoneNumber' ? '手机号' : (item.privacy_key == 'Location' ? '位置信息' : item.privacy_label)}}。
						</div>
						<div @click="fundelset(item,index)"
							v-if="item.privacy_key != 'PhoneNumber' && item.privacy_key != 'Location'" style="color: #ff0000;">
							删除</div>
					</div>
				</div>
				<div style="color: #576B95;margin-top: 10px;" @click="privacyshow = true">增加信息类型</div>
				
				<div class="title2">2.你的权益</div>
				<div class="memo2">关于收集你的位置信息，你可以通过以下路径：小程序主页右上角“…”—“设置”—点击特定信息—点击“不允许”，撤回对开发者的授权。</div>
				<div class="memo2">关于收集你的手机号，你可以通过以下路径：小程序主页右上角“...” — “设置” — “小程序已获取的信息” — 点击特定信息 —
					点击“通知开发者删除”，开发者承诺收到通知后将删除信息。法律法规另有规定的，开发者承诺将停止除存储和采取必要的安全保护措施之外的处理。</div>
				<div class="memo2">关于你的个人信息，你可以通过以下方式与开发者联系，行使查阅、复制、更正、删除等法定权利。</div>
				<div class="memo2">若你在小程序中注册了账号，你可以通过以下方式与开发者联系，申请注销你在小程序中使用的账号。在受理你的申请后，开发者承诺在十五个工作日内完成核查和处理，并按照法律法规要求处理你的相关信息。
				</div>
				<div class="iptlist">
					<div class="iptitem">
						<div>电话</div>
						<el-input class="ipt" v-model="owner_setting.contact_phone"></el-input>
					</div>
					<div class="iptitem">
						<div>微信号</div>
						<el-input class="ipt" v-model="owner_setting.contact_weixin"></el-input>
				
					</div>
					<div class="iptitem">
						<div>邮箱</div>
						<el-input class="ipt" v-model="owner_setting.contact_email"></el-input>
					</div>
					<div class="iptitem">
						<div>qq</div>
						<el-input class="ipt" v-model="owner_setting.contact_qq"></el-input>
					</div>
				</div>
				<div class="title2">3.信息的使用规则</div>
				<div class="memo2">开发者将会在本指引所明示的用途内使用收集的信息</div>
				<div class="iptlist">
					<div class="iptitem">
						<div>如开发者使用你的信息超出本指引目的或合理范围，开发者必须在变更使用目的或范围前，再次以</div>
						<el-input class="ipt" v-model="owner_setting.notice_method"></el-input>
						<div>方式告知并征得你的明示同意。</div>
					</div>
				</div>
				<div class="row" style="display: flex;flex-direction: row;align-items: center;margin: 50px 0;">
					<el-checkbox :checked="isread" @change="funchange"></el-checkbox>
					<div style="margin-left: 10px;"> 本小程序已对用户的信息处理进行了逐一、如实的说明，并保证如有变更会及时更新指引。</div>
				</div>
				
				<div class="btnbox">
					<el-button size="medium" class="savebtn" type="primary" :disabled="!isread"
						@click="funsave">确认并生成协议</el-button>
				</div>
				<el-dialog title="使用用户信息类型" :visible.sync="privacyshow" width="43%" @click='privacyshow = false'>
					<div class="ewmtitle">在《小程序用户隐私保护指引内容介绍》中可查阅用户信息与使用接口对应关系</div>
					<div class="">
						<ul class="checklist">
							<li style="width:26%;" v-for="(item,index) in privacy_list" :class="item.ischeck ? 'active' : 'normal'" :key="index"
								@click="funprivacysel(item)"><span></span>{{item.privacy_desc}}</li>
						</ul>
					</div>
					<div class="footer">
						<el-button size="medium" class="savebtn" style="margin-right: 20px;"  @click='privacyshow = false'>取消</el-button>
						<el-button size="medium" class="savebtn" style="margin-left: 20px;"   type="primary" @click='funprivacy'>确定</el-button>
					</div>
				</el-dialog>
				</div>
				</div>
		
	</div>
</template>

<script>
	import accountApi from '../../../../../../api/cus/account'
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				owner_setting: {
					contact_email: "",
					contact_phone: "",
					contact_qq: "",
					contact_weixin: "",
					ext_file_media_id: "",
					notice_method: "",
					store_expire_timestamp: "",
					store_region: 0
				},
				setting_list: [],
				privacy_desc_list: [],
				privacyshow: false,
				isread: false
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			if (mpid != undefined) {} else {
				this.$router.push("/");
			}
			this.getprivlist()
		},
		computed: {
			privacy_list() {
				let list = []
				this.privacy_desc_list.forEach(item => {
					let temp = this.setting_list.find(f => {
						return item.privacy_key == f.privacy_key
					})
					if (temp != undefined) {

					} else {
						list.push(item)
					}
				})
				return list
			}
		},
		methods: {
			getprivlist() {
				accountApi.getprivacy(this.mpid, res => {
					console.log(res, 'res.getprivlist');
					if (res.code == 200) {
						this.owner_setting = res.data.owner_setting
						this.setting_list = res.data.setting_list

						let privacy_desc_list = []
						res.data.privacy_desc.privacy_desc_list.forEach(item => {
							privacy_desc_list.push({
								ischeck: false,
								privacy_desc: item.privacy_desc,
								privacy_key: item.privacy_key
							})

						})
						this.privacy_desc_list = privacy_desc_list
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			funprivacysel(item) {
				item.ischeck = !item.ischeck
			},
			funprivacy() {
				let list = this.privacy_desc_list.filter((f => {
					return f.ischeck == true
				}))
				console.log(list, 'list');
				list.forEach(item => {
					this.setting_list.push({
						privacy_key: item.privacy_key,
						privacy_label: item.privacy_desc,
						privacy_text: ''
					})
				})
				this.privacyshow = false
			},
			fundelset(item,index) {
				console.log(item,'item')
				item.ischeck = false
				this.setting_list.splice(index, 1)
			},
			funchange(e) {
				console.log(e, 'ee');
				this.isread = e
			},
			funsave() {
				let param = {
					"mpid": this.mpid,
					"privacy": {
						"owner_setting": {
							"contact_email": this.owner_setting.contact_email,
							"contact_phone": this.owner_setting.contact_phone,
							"contact_qq": this.owner_setting.contact_qq,
							"contact_weixin": this.owner_setting.contact_weixin,
							"ext_file_media_id": "",
							"notice_method": this.owner_setting.notice_method,
							"store_expire_timestamp": ""
						},
						"privacy_ver": 2,
						"sdk_privacy_info_list": [],
						"setting_list": this.setting_list
					}
				}
				accountApi.saveprivacyset(param, res => {
					console.log(res, 'res.funsave');
					if (res.code == 200) {
						if(res.data.errcode == 0){
							this.$message({
								message:'保存成功',
								type: 'success'
							})
						}else{
							this.$message({
								message: res.msg,
								type: 'error'
							})
						}
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.content {
		background-color: #F8F9FB;

		height: 100%;
		padding: 30px;
	}

	.content .title {
		width: 100%;
		font-size: 18px;
		font-weight: 600;
		text-align: center;
	}

	.content .memo {
		width: 100%;
		font-size: 12px;
		text-align: center;
		margin: 20px;
	}

	.content .title2 {
		width: 100%;
		font-size: 14px;
		margin-bottom: 10px;
		margin-top: 20px;
		font-weight: 600;
	}

	.content .memo2 {
		width: 100%;
		font-size: 12px;
	}

	.content .iptlist {
		display: flex;
		flex-direction: column;
	}

	.content .iptlist .iptitem .ipt {
		width: 200px;
		margin: 0 5px;
	}

	.content .iptlist .iptitem {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 10px;
	}
	.active{
		margin:10px 25px;
		
	}
	.active span {
		display: inline-block;
		position: relative;
		width: 15px;
		height: 15px;
		border: 1px solid #2ac845;
		background-color: #2ac845;
		border-radius: 3px;
		margin-right: 5px;
	}

	.active span:before,.active span:after {
		content: '';
		pointer-events: none;
		position: absolute;
		color: white;
		border: 1px solid;
		background-color: white;
	}

	.active span:before {
		width: 1px;
		height: 1px;
		left: 25%;
		top: 50%;
		transform: skew(0deg, 50deg);
	}

	.active span:after {
		width: 3px;
		height: 1px;
		left: 45%;
		top: 42%;
		transform: skew(0deg, -50deg);
	}
	.normal span{
		display: inline-block;
		position: relative;
		width: 15px;
		height: 15px;
		border: 1px solid #e0e0e0;
		border-radius: 3px;
		margin-right: 5px;
	}
	.normal {
		color: #000000;
		margin:10px 25px;
	}

	
	.checklist{
		display: flex;
		flex-wrap: wrap;
	}
	.btnbox {
		width: 100%;
		display: flex;
		justify-content: center;
	}
	.footer{
		margin-top:30px;
		display: flex;
		width:100%;
		justify-content: center;

	}
	.page{
		
	}
	.rightbox{
		padding-top:20px;
		padding-bottom:20px;
		width: 1320px;
		overflow:auto;
	}
	.pagecontent{
		
		
		height: calc(100vh - 81px);
	}
</style>