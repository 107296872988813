import httpapi from '../../utils/httpapi';
const msgApi = {
	//新客户欢迎语新增/编辑
	msgedit(param,funsucc) {
		httpapi.post('customer/qiyeweixin/msg/welcome/msgedit', param).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//新客户欢迎语删除
	msgedel(param,funsucc) {
		httpapi.post('customer/qiyeweixin/msg/welcome/msgdel', param).then(res => {
			console.log(res, 'msgedel.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//新客户欢迎语详情
	msgdetail(param,funsucc) {
		httpapi.get('customer/qiyeweixin/msg/welcome/msgdetail?id='+param.id, {}).then(res => {
			console.log(res, 'msgdetail.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//新客户欢迎语列表
	msglist(param,funsucc) {
		httpapi.get('customer/qiyeweixin/msg/welcome/list?mpid=' + param.mpid, {}).then(res => {
			console.log(res, 'msglist.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default msgApi