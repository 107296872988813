<template>
	<div style="display: flex;">
		<!-- {{JSON.stringify(diandanitem)}} -->
		<div class="phone-main" :style="contbgstyle">
			<div class="top-nav" :class="selpagetype == 'nav' ? 'selborder' : ''" @click="funtappage('nav')" v-if="diandanitem.header.iconstyle == 'white'">
				<img src="../../../assets/design/topNavBlack.png" class="navimg" />
				<div class="tit" :style="{color:diandanitem.header.title}">BOSS666</div>
				<div class="backbox" :style="backborderstyle">
					<img src="../../../assets/diypage/icon_back.png" class="searchimg"></img>
					<div class="line" :style="backline"></div>
					<img src="../../../assets/diypage/icon_sao.png" class="searchimg"></img>
					<div class="line" :style="backline"></div>
					<img src="../../../assets/diypage/icon_search.png" class="searchimg"></img>
				</div>
			</div>
			<div class="top-nav" :class="selpagetype == 'nav' ? 'selborder' : ''" @click="funtappage('nav')" v-else>
				<img src="../../../assets/design/topNavBlack.png" class="navimg" />
				<div class="tit" :style="{color:diandanitem.header.title}">BOSS666</div>
				<div class="backbox" :style="backborderstyle">
					<img src="../../../assets/diypage/icon_back1.png" class="searchimg"></img>
					<div class="line" :style="backline"></div>
					<img src="../../../assets/diypage/icon_sao1.png" class="searchimg"></img>
					<div class="line" :style="backline"></div>
					<img src="../../../assets/diypage/icon_search1.png" class="searchimg"></img>
				</div>
			</div>
			
			<div class="view-content-top"></div>
			<div class="w100">
				<!-- <div class="" :style="'background:'  + divstyle2.pagebg + ';height:100rpx'"></div> -->

				<div class="shop" :class="selpagetype == 'title' ? 'selborder' : ''" @click="funtappage('title')">
					<div class="row" style="align-items: center;">
						<div class="" style="flex-grow:1">
							<div class="mc" :style="{color:diandanitem.shop.title}">大新软件</div>
							<div class="time" :style="{color:diandanitem.shop.date}">营业时间:00：00 ~ 23：59
							</div>
						</div>
						<div class="loginbtn"
							:style="{color:diandanitem.loginbtn.color,background:diandanitem.loginbtn.bg}">登录/注册</div>
					</div>

				</div>
				<div class="minsale" :class="selpagetype == 'minsale' ? 'selborder' : ''"
					@click="funtappage('minsale')">
					<div class="dixiao" :style="{background:diandanitem.minsale.bg,color:diandanitem.minsale.color}">
						<div class="" style="flex-grow: 1;">低消:2000,还差:<span
								:style="{color:diandanitem.minsale.chacolor}">200</span></div>
						<div :style="{color:diandanitem.minsale.zt.overcolor}">已达低消</div>
					</div>
					<div class="gonggao"
						:style="{background:diandanitem.minsale.bg,color:diandanitem.minsale.color,'border-top':minsaleline}">
						<div class="noticeimgbox" :style="{background:diandanitem.minsale.bg}">
							<img src="https://imgoss.91erp.net/10001/sp/240801104433676.png" class="ggimg">
							</img>
						</div>
						<div class="ward">门店公告门店公告门店公告门店公告门店公告门店公告门店公告门店公告门店公告门店公告</div>
						<!-- <view class="" :class="shopnotice != undefined && shopnotice.length  >= 20 ? 'ward' : 'ward2'">{{shopnotice}}</view> -->
					</div>
				</div>
				<div class="swiperbox" :class="selpagetype == 'news' ? 'selborder' : ''" @click="funtappage('news')">
					<img src="https://imgoss.91erp.net/10001/sp/240801104156664.png" style="width: 343px;" />
				</div>
				<div class="flsc" v-if="diandanitem.menu.usezt && diandanitem.menu.usezt == 'T'" :style="{background:diandanitem.menu.bg,color:diandanitem.menu.color}" :class="selpagetype == 'menu' ? 'selborder' : ''" @click="funtappage('menu')">
					<!-- <div class="flbox">
						<div class="fitem" :style="selsptype == 'normal' ? 'color:' + diandanitem.menu.jindian.selcolor : 'color:' + diandanitem.menu.jindian.color"
							@click="funselfl('normal')">
							<div class="">经典菜单</div>
							<div class="bline" v-if="selsptype == 'normal'" :style="{background:diandanitem.menu.jindian.linecolor}"></div>
						</div>
						<div class="fitem" :style="selsptype == 'tuijian' ? 'color:' + diandanitem.menu.tuijian.selcolor : 'color:' + diandanitem.menu.tuijian.color"
							@click="funselfl('tuijian')">
							<div class="">店长推荐</div>
							<div class="bline" v-if="selsptype == 'tuijian'" :style="{background:diandanitem.menu.tuijian.linecolor}"></div>
						</div>
						<div class="fitem" :style="selsptype == 'tejia' ? 'color:' + diandanitem.menu.tejia.selcolor : 'color:' + diandanitem.menu.tejia.color"
							@click="funselfl('tejia')">
							<div class="">时段特价</div>
							<div class="bline" v-if="selsptype == 'tejia'" :style="{background:diandanitem.menu.tejia.linecolor}"></div>
						</div>
						<div class="fitem" :style="selsptype == 'newsp' ? 'color:' + diandanitem.menu.new.selcolor : 'color:' + diandanitem.menu.new.color"
							@click="funselfl('newsp')">
							<div class="">新品尝鲜</div>
							<div class="bline" v-if="selsptype == 'newsp'" :style="{background:diandanitem.menu.new.linecolor}"></div>
						</div>
					</div> -->
					<div class="flbox" >
						<div class="fitem"
							:style="selsptype == 'normal' ? 'color:' + diandanitem.menu.jindian.selcolor : 'color:' + diandanitem.menu.jindian.color">
							<div class="">{{diandanitem.menu.jindian.mc}}</div>
							<div class="bline" v-if="selsptype == 'normal'"
								:style="{background:diandanitem.menu.jindian.linecolor}"></div>
						</div>
						<div class="fitem" v-if="diandanitem.menu.tuijian.zt == 'T'"
							:style="selsptype == 'tuijian' ? 'color:' + diandanitem.menu.tuijian.selcolor : 'color:' + diandanitem.menu.tuijian.color">
							<div class="" >{{diandanitem.menu.tuijian.mc}}</div>
							<div class="bline" v-if="selsptype == 'tuijian'"
								:style="{background:diandanitem.menu.tuijian.linecolor}"></div>
						</div>
						<div class="fitem" v-if="diandanitem.menu.tejia.zt == 'T'"
							:style="selsptype == 'tejia' ? 'color:' + diandanitem.menu.tejia.selcolor : 'color:' + diandanitem.menu.tejia.color">
							<div class="">{{diandanitem.menu.tejia.mc}}</div>
							<div class="bline" v-if="selsptype == 'tejia'"
								:style="{background:diandanitem.menu.tejia.linecolor}"></div>
						</div>
						<div class="fitem" v-if="diandanitem.menu.newsp.zt == 'T'"
							:style="selsptype == 'newsp' ? 'color:' + diandanitem.menu.newsp.selcolor : 'color:' + diandanitem.menu.newsp.color">
							<div class="">{{diandanitem.menu.newsp.mc}}</div>
							<div class="bline" v-if="selsptype == 'newsp'"
								:style="{background:diandanitem.menu.newsp.linecolor}"></div>
						</div>
					</div>
				</div>
				<div class="spcont row" :style="{background:diandanitem.sp.bg,color:diandanitem.sp.color}">
					<div class="left_sc" :class="selpagetype == 'cls' ? 'selborder' : ''" @click="funtappage('sp')"
						:style="{background:diandanitem.cls.normal.bg}">
						<div v-for="(item,index) in 10" :key="index" @click="funselcls(index)">
							<div class="list"
								:style="selindex == index ? 'color:' + diandanitem.cls.sel.color + ';background:' + diandanitem.cls.sel.bg + ';' : 'color:' + diandanitem.cls.normal.color + ';background:' + diandanitem.cls.normal.bg + ';'">
								<!-- <image src="../../static/icon_cls.png" class="clsimg" mode="widthFix" ></image> -->
								<img src="https://imgoss.91erp.net/10001/sp/230830114628749.png" class="clsimg"></img>
								<div class="clsmc">商品分类</div>
							</div>

						</div>
					</div>
					<div class="m18" :class="selpagetype == 'sp' ? 'selborder' : ''" @click="funtappage('sp')"
						:style="{background:diandanitem.sp.bg,color:diandanitem.sp.color}">
						<div class="right_sc">

							<div class="row2">
								<div class="row" style="margin: 18rpx 0 10rpx 18rpx;">
									<div class="clsline" :style="{background:diandanitem.sp.clsmc.line}"></div>
									<div class="clsmc2" :style="{color:diandanitem.sp.clsmc.color}">啤酒</div>
								</div>
								<div class="list3 row" :style="{background:diandanitem.sp.spbg}" v-for="(item,index) in 10" :key="index">
									<div class="" style="position: relative;width: 82px;height: 82px;margin: 5px;">
										<img class="img2" src="https://imgoss.91erp.net/10001/sp/230727171054531.png">
										<img>
										<div class="cprice r20">
											<img src="../../../assets/diypage/icon_cprice.png" class="viplogo"></img>
											<div class="cm" :style="{color:diandanitem.sp.spitem.price.clubcolor}">
												会员价:188</div>
										</div>
									</div>
									<div class="kc2" v-if="index == 2">已售完
									</div>
									<div class="cloum">
										<div class="spmc" :style="{color:diandanitem.sp.spitem.spmc.color}">
											威士忌套餐
										</div>
										<div class="row" style="flex-wrap: wrap;">
											<div class="sptip"
												:style="'background:' + diandanitem.sp.spitem.lab.bgcolor + ';color:' + diandanitem.sp.spitem.lab.color + ';border:1px solid' + diandanitem.sp.spitem.lab.bordercolor">
												新品</div>
											<div class="sptip"
												:style="'background:' + diandanitem.sp.spitem.lab.bgcolor + ';color:' + diandanitem.sp.spitem.lab.color + ';border:1px solid' + diandanitem.sp.spitem.lab.bordercolor">
												剩1</div>
											<div class="sptip"
												:style="'background:' + diandanitem.sp.spitem.lab.bgcolor + ';color:' + diandanitem.sp.spitem.lab.color + ';border:1px solid' + diandanitem.sp.spitem.lab.bordercolor">
												限1支</div>

 
										</div>

										<div class="spgg" :style="{color:diandanitem.sp.spitem.spremark.color}">威士忌新品尝鲜
										</div>
										<div class="row" style="margin-top: 5px;">
											<div class="price" :style="{color:diandanitem.sp.spitem.price.salecolor}">
												<span style="font-size: 8pt;">￥</span>100<span
													class="dwmc">/支</span><span
													:style="{color:diandanitem.sp.spitem.price.marketcolor}"
													class="yuanjia">￥120</span>
											</div>
											<!-- <image src="../../static/icon_minsale.png" class="min"
											v-if="item.isminsale == 0">
										</image> -->
											<div class="">
												<div class="btnbox" v-if="index < 3">
													<div class="addbtn"
														:style="{background:diandanitem.sp.spitem.btn.bg,color:diandanitem.sp.spitem.btn.color}">
														-
													</div>
													<div class="num"
														:style="{color:diandanitem.sp.spitem.btn.num.color}">3
													</div>
													<div class="addbtn"
														:style="{background:diandanitem.sp.spitem.btn.bg,color:diandanitem.sp.spitem.btn.color}">
														+
													</div>
												</div>
												<div class="abbox" v-else>
													<div class="ggbtn"
														:style="{background:diandanitem.sp.spitem.btn.bg,color:diandanitem.sp.spitem.btn.color}">
														选规格</div>
													<div class="buysl"
														:style="{color:diandanitem.sp.spitem.btn.tip.color,background:diandanitem.sp.spitem.btn.tip.bg}">
														5
													</div>
												</div>
											</div>
										</div>

									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="shopcar" :class="selpagetype == 'cart' ? 'selborder' : ''" @click="funtappage('cart')"
				:style="{background:diandanitem.cart.bgcolor,color:diandanitem.cart.color}">
				<div class="imgbox">
					<div class="num" :style="{background:diandanitem.cart.num.bg,color:diandanitem.cart.num.color}">10
					</div>
					<img class="carimg" :src="diandanitem.cart.icon != undefined && diandanitem.cart.icon.length > 0 ? diandanitem.cart.icon : caricon"></img>
				</div>
				 <div class="total" v-if="diandanitem.cart.usezt && diandanitem.cart.usezt == 'T'"> 合计：<span style="font-size: 9pt;" :style="{color:diandanitem.cart.color}">￥</span><span>999</span>
				</div> 
				<div class="total" v-else>
				</div>
				<div class="jsbtn" :style="{background:diandanitem.cart.btn.bg,color:diandanitem.cart.btn.color}">结算
				</div>
			</div>
		</div>
		<div class="pagelist">
			<div class="pitem" v-for="(item,index) in menulist" :class="item.id == selpagetype ? 'selstyle': ''"
				@click="funselpage(item)">
				{{item.mc}}
			</div>
		</div>
		<div class="editstyle">
			<div class="details">
				<!-- 顶部导航栏 -->
				<div v-if="selpagetype == 'cont'">
					  
						
					<div class="slist">
						<div class="title"><span>页面背景色</span></div>
						<div class="center">{{diandanitem.cont.bgcolor}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cont.bgcolor" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>页面字体色</span></div>
						<div class="center">{{diandanitem.cont.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cont.color" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>背景图</span></div>
						<!-- <div class="center">{{diandanitem.header.line}}</div> -->
						<img :src="diandanitem.cont.bgimg" class="bgimg" alt="">
						<div class="btn">
							<el-button type="text" class="reset" @click="uploadimg('bgimg')">上传</el-button>
							<el-button type="text" class="reset" @click="resetimg('bgimg')">重置</el-button>
						</div>

					</div>
					<div class="slist">
						<div class="title"><span>整体风格</span></div>
						<el-radio-group v-model="contstyle" @change="changcontstyle()">
						    <el-radio-button label="浅色"></el-radio-button>
						    <el-radio-button label="深色"></el-radio-button>
						   
						  </el-radio-group>
					</div>
					<!-- <el-button @click="funresetstyle">重置样式</el-button> -->
				</div>
				<div v-if="selpagetype == 'nav'">
					<div class="slist">
						<div class="title"><span>标题字体色</span></div>
						<div class="center">{{diandanitem.header.title}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.header.title" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>边框色</span></div>
						<div class="center">{{diandanitem.header.boxborder}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.header.boxborder"
								:value="diandanitem.header.boxborder" @change="onchange($event,'backborder')"
								size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>分割线</span></div>
						<div class="center">{{diandanitem.header.line}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.header.line" :value="diandanitem.header.line"
								@change="onchange($event,'backline')" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>图标色</span></div>
						  <el-radio-group v-model="diandanitem.header.iconstyle">
						    <el-radio :label="'white'">白色</el-radio>
						    <el-radio :label="'black'">黑色</el-radio>
						  </el-radio-group>
					</div>
				</div>
				<!-- 顶部标题 -->
				<div v-else-if="selpagetype == 'title'">
					<div class="slist">
						<div class="title"><span>标题字体色</span></div>
						<div class="center">{{diandanitem.shop.title}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.shop.title" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>日期字体色</span></div>
						<div class="center">{{diandanitem.shop.date}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.shop.date" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>登录按钮背景色</span></div>
						<div class="center">{{diandanitem.shop.bg}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.loginbtn.bg" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>登录按钮字体色</span></div>
						<div class="center">{{diandanitem.loginbtn.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.loginbtn.color" size="medium"></el-color-picker>
						</div>
					</div>
				</div>
				<!-- 低消公告 -->
				<div v-else-if="selpagetype == 'minsale'">
					<div class="slist">
						<div class="title"><span>整体背景色</span></div>
						<div class="center">{{diandanitem.minsale.bg}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.minsale.bg" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>整体字体色</span></div>
						<div class="center">{{diandanitem.minsale.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.minsale.color" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>分割线</span></div>
						<div class="center">{{diandanitem.minsale.line}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.minsale.line" :value="diandanitem.minsale.line"
								@change="onchange($event,'minsaleline')" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>差价金额字体色</span></div>
						<div class="center">{{diandanitem.minsale.chacolor}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.minsale.chacolor" size="medium"></el-color-picker>
						</div>
					</div>

					<div class="slist">
						<div class="title"><span>已达低消状态字体色</span></div>
						<div class="center">{{diandanitem.minsale.zt.overcolor}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.minsale.zt.overcolor" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>未达低消字体色</span></div>
						<div class="center">{{diandanitem.minsale.zt.uncolor}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.minsale.zt.uncolor" size="medium"></el-color-picker>
						</div>
					</div>
				</div>
				<div v-else-if="selpagetype == 'news'">







				</div>
				<div v-else-if="selpagetype == 'menu'">
					<div class="slist">
						<div class="title">启用菜单栏</div>
						<el-switch style="margin-left: 10px;" v-model="diandanitem.menu.usezt" active-color="#13ce66" inactive-color="#9c9c9c" active-value="T"
							inactive-value="F">
						</el-switch>
					
					</div>
					<div class="slist">
						<div class="title"><span>菜单背景色</span></div>
						<div class="center">{{diandanitem.menu.bg}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.menu.bg" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>菜单字体色</span></div>
						<div class="center">{{diandanitem.menu.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.menu.color" size="medium"></el-color-picker>
						</div>
					</div>
					
					<el-tabs type="border-card">
					  <el-tab-pane :label="diandanitem.menu.jindian.mc.length > 0 ? diandanitem.menu.jindian.mc : '经典菜单'">
						  <div class="slist">
						  	<div class="title">标题</div>
						  	<el-input v-model="diandanitem.menu.jindian.mc"></el-input>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>经典菜单字体色</span></div>
						  	<div class="center">{{diandanitem.menu.jindian.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.jindian.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>经典菜单选中字体色</span></div>
						  	<div class="center">{{diandanitem.menu.jindian.selcolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.jindian.selcolor"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>经典菜单底部线条色</span></div>
						  	<div class="center">{{diandanitem.menu.jindian.linecolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.jindian.linecolor"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
					  </el-tab-pane>
					  <el-tab-pane :label="diandanitem.menu.tuijian.mc.length > 0 ? diandanitem.menu.tuijian.mc : '店长推荐' ">
							<div class="slist">
								<div class="title">标题</div>
								<el-input v-model="diandanitem.menu.tuijian.mc"></el-input>
								<el-switch style="margin-left: 10px;" v-model="diandanitem.menu.tuijian.zt" active-color="#13ce66" inactive-color="#9c9c9c" active-value="T"
									inactive-value="F">
								</el-switch>
							
							</div>
							<div class="slist">
								<div class="title"><span>店长推荐字体色</span></div>
								<div class="center">{{diandanitem.menu.tuijian.color}}</div>
								<div class="btn">
									<el-color-picker v-model="diandanitem.menu.tuijian.color" size="medium"></el-color-picker>
								</div>
							</div>
							<div class="slist">
								<div class="title"><span>店长推荐选中字体色</span></div>
								<div class="center">{{diandanitem.menu.tuijian.selcolor}}</div>
								<div class="btn">
									<el-color-picker v-model="diandanitem.menu.tuijian.selcolor"
										size="medium"></el-color-picker>
								</div>
							</div>
							<div class="slist">
								<div class="title"><span>店长推荐底部线条色</span></div>
								<div class="center">{{diandanitem.menu.tuijian.linecolor}}</div>
								<div class="btn">
									<el-color-picker v-model="diandanitem.menu.tuijian.linecolor"
										size="medium"></el-color-picker>
								</div>
							</div>					
					  </el-tab-pane>
					  <el-tab-pane :label="diandanitem.menu.tejia.mc.length > 0 ? diandanitem.menu.tejia.mc :'时段特价'">
						  <div class="slist">
						  	<div class="title">标题</div>
						  	<el-input v-model="diandanitem.menu.tejia.mc"></el-input>
						  	<el-switch style="margin-left: 10px;" v-model="diandanitem.menu.tejia.zt" active-color="#13ce66" inactive-color="#9c9c9c" active-value="T"
						  		inactive-value="F">
						  	</el-switch>
						  
						  </div>
						  <div class="slist">
						  	<div class="title"><span>特价字体色</span></div>
						  	<div class="center">{{diandanitem.menu.tejia.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.tejia.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>特价选中字体色</span></div>
						  	<div class="center">{{diandanitem.menu.tejia.selcolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.tejia.selcolor" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>特价底部线条色</span></div>
						  	<div class="center">{{diandanitem.menu.tejia.linecolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.tejia.linecolor" size="medium"></el-color-picker>
						  	</div>
						  </div>
					  </el-tab-pane>
					  <el-tab-pane :label="diandanitem.menu.newsp.mc.length > 0 ? diandanitem.menu.newsp.mc : '新品尝鲜'">
						  <div class="slist">
						  	<div class="title">标题</div>
						  	<el-input v-model="diandanitem.menu.newsp.mc"></el-input>
						  	<el-switch style="margin-left: 10px;" v-model="diandanitem.menu.newsp.zt" active-color="#13ce66" inactive-color="#9c9c9c" active-value="T"
						  		inactive-value="F">
						  	</el-switch>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>新品字体色</span></div>
						  	<div class="center">{{diandanitem.menu.newsp.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.newsp.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>新品选中字体色</span></div>
						  	<div class="center">{{diandanitem.menu.newsp.selcolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.newsp.selcolor" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>新品底部线条色</span></div>
						  	<div class="center">{{diandanitem.menu.newsp.linecolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.menu.newsp.linecolor" size="medium"></el-color-picker>
						  	</div>
						  </div>
					  </el-tab-pane>
					  
					</el-tabs>
					<!-- <div class="sline"></div>
					
					<div class="sline"></div>


					
					<div class="sline"></div>
					
					<div class="sline"></div> -->
					
				</div>
				<!-- 商品分类 -->
				<!-- <div v-else-if="selpagetype == 'cls'">
					
				</div> -->
				<div v-else-if="selpagetype == 'sp'">
					<div class="slist">
						<div class="title"><span>整体背景色</span></div>
						<div class="center">{{diandanitem.sp.bg}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.sp.bg" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>整体字体色</span></div>
						<div class="center">{{diandanitem.sp.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.sp.color" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="sline"></div>
					<el-tabs type="border-card">
					  <el-tab-pane label="商品分类">
						  <div class="slist">
						  	<div class="title"><span>商品分类背景色</span></div>
						  	<div class="center">{{diandanitem.cls.normal.bg}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.cls.normal.bg" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品分类字体色</span></div>
						  	<div class="center">{{diandanitem.cls.normal.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.cls.normal.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="sline"></div>
						  <div class="slist">
						  	<div class="title"><span>商品分类选中背景色</span></div>
						  	<div class="center">{{diandanitem.cls.sel.bg}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.cls.sel.bg" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品分类选中字体色</span></div>
						  	<div class="center">{{diandanitem.cls.sel.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.cls.sel.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
					  </el-tab-pane>
					  <el-tab-pane label="商品">
						 
						  <div class="slist">
						  	<div class="title"><span>分类名称字体色</span></div>
						  	<div class="center">{{diandanitem.sp.clsmc.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.clsmc.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>分类名称线条色</span></div>
						  	<div class="center">{{diandanitem.sp.clsmc.line}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.clsmc.line" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="sline"></div>
						  <div class="slist">
						  	<div class="title"><span>商品容器背景色</span></div>
						  	<div class="center">{{diandanitem.sp.contbg}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.contbg"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品单项背景</span></div>
						  	<div class="center">{{diandanitem.sp.spbg}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spbg"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品名称字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.spmc.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.spmc.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品备注字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.spremark.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.spremark.color"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品标签背景色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.lab.bgcolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.lab.bgcolor"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品标签字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.lab.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.lab.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>商品标签边框色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.lab.bordercolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.lab.bordercolor"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="sline"></div>
						  <div class="slist">
						  	<div class="title"><span>售价字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.price.salecolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.price.salecolor"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>会员价字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.price.clubcolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.price.clubcolor"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>划线价字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.price.marketcolor}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.price.marketcolor"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="sline"></div>
						  <div class="slist">
						  	<div class="title"><span>按钮背景色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.btn.bg}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.btn.bg" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>按钮字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.btn.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.btn.color" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>已选商品数量字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.btn.num.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.btn.num.color"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>已选商品数量标签背景色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.btn.tip.bg}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.btn.tip.bg" size="medium"></el-color-picker>
						  	</div>
						  </div>
						  <div class="slist">
						  	<div class="title"><span>已选商品数量标签字体色</span></div>
						  	<div class="center">{{diandanitem.sp.spitem.btn.tip.color}}</div>
						  	<div class="btn">
						  		<el-color-picker v-model="diandanitem.sp.spitem.btn.tip.color"
						  			size="medium"></el-color-picker>
						  	</div>
						  </div>
						  
					  </el-tab-pane>
					</el-tabs>
					
					
				</div>
				<!-- 购物车 -->
				<div v-if="selpagetype == 'cart'">
					<div class="slist">
						<div class="title">显示总价金额</div>
						<el-switch style="margin-left: 10px;" v-model="diandanitem.cart.usezt" active-color="#13ce66" inactive-color="#9c9c9c" active-value="T"
							inactive-value="F">
						</el-switch>
					
					</div>
					<div class="slist">
						<div class="title"><span>购物车背景色</span></div>
						<div class="center">{{diandanitem.cart.bgcolor}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cart.bgcolor" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>购物车字体色</span></div>
						<div class="center">{{diandanitem.cart.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cart.color" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="sline"></div>
					<div class="slist">
						<div class="title"><span>按钮背景色</span></div>
						<div class="center">{{diandanitem.cart.btn.bg}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cart.btn.bg" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>按钮字体色</span></div>
						<div class="center">{{diandanitem.cart.btn.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cart.btn.color" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="sline"></div>
					<div class="slist">
						<div class="title"><span>购物车数量标签背景色</span></div>
						<div class="center">{{diandanitem.cart.num.bg}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cart.num.bg" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="slist">
						<div class="title"><span>购物车数量字体色</span></div>
						<div class="center">{{diandanitem.cart.num.color}}</div>
						<div class="btn">
							<el-color-picker v-model="diandanitem.cart.num.color" size="medium"></el-color-picker>
						</div>
					</div>
					<div class="sline"></div>
					<div class="slist">
						<div class="title"><span>购物车图标</span></div>
						<img :src="diandanitem.cart.icon != undefined && diandanitem.cart.icon.length > 0 ? diandanitem.cart.icon : caricon" class="carimg" alt="">
						<div class="btn">
							<el-button type="text" class="reset" @click="uploadimg('cartimg')">上传</el-button>
							<el-button type="text" class="reset" @click="resetimg('cartimg')">重置</el-button>
						</div>
					</div>

				</div>
			</div>
			<!-- <div>
				{{diandanitem}}
			</div> -->
		</div>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
		<selisvImgs ref="selisvImgs" @selisvImgsresult="selisvImgsresult"></selisvImgs>
	</div>
</template>

<script>
	import selImgs from "@/components/customer/selImgs"
	import selisvImgs from '../../designer/selImgs.vue'
	import designTemplataApi from "@/api/cus/designtmplate"
	import modestyle from '../../../api/cus/modestyle.js'
	import util from '../../../utils/util.js'
	export default {
		components: {
			selImgs,
			selisvImgs
		},
		data() {
			return {
				contstyle:'浅色',
				caricon:"https://gzh.dxerp.net/dxgzh/1706239812474437634/202408/7427fc5890a1461eae3f06d48341d60a.png?key=123456",
				diandanitem: {
					cont: {
						bgimg: "https://imgoss.91erp.net/10001/sp/240801104438158.png",
						bgcolor: "#20232B",
						// bgcolor: "#181920",
						color: "#E8E8E8",
						navstyle:'10' //10 浅色 11深色
					},
					
					header: {
						line: '#8D8D8D',
						title: "#ffffff",
						boxborder: "#ffffff",
						iconstyle:'white'
					},
					shop: {
						title: "#ffffff",
						date: '#ffffff',
					},
					minsale: {
						bg: "#F5F5F5",
						color: '#000000',
						line: '#f0f0f0',
						chacolor: "#000000",
						zt: {
							overcolor: '#00aa00',
							uncolor: '#ff0000'
						}
					},
					ad: {
						type: '11', //10 券 11广告

					},
					loginbtn: {
						color: '#000000',
						bg: '#FFC80C'
					},

					menu: {
						bg: "#20232B",
						color:'',
						usezt:"T",
						jindian: {
							mc: "经典菜单",
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5'
							
						},
						tuijian: {
							mc: "店长推荐",
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5',
							zt:'T'
						},
						tejia: {
							mc: "时段特价",
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5',
							zt:'T'
						},
						newsp: {
							mc: "新品尝鲜",
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5',
							zt:'T'
						},
					},
					cls: {
						normal: {
							bg: '#20232B',
							color: "#E8E8E8",
						},
						sel: {
							bg: '#181920',
							color: "#E8E8E8",
						},
						size: '',
						tip: {
							color: '',
							bg: ''
						}
					},
					sp: {
						contbg:'',
						spbg:'',
						bg: '#181920',
						color: "#E8E8E8",
						clsmc: {
							line: '#ffffff',
							color: '#ffffff'
						},
						clschild: {
							normal: {
								bg: '',
								color: ""
							},
							sel: {
								bg: '',
								color: ""
							}
						},
						spitem: {
							spmc: {
								color: '#ffffff',

							},
							spremark: {
								color: '#acacac',

							},
							price: {
								marketcolor: "#acacac",
								salecolor: "#ffffff",
								clubcolor: "#000000",
							},
							btn: {
								color: '#ffffff',
								bg: '#2D384A',
								num: {
									color: "#ffffff"
								},
								tip: {
									color: '#ffffff',
									bg: '#BF9F6D'
								} //规格按钮右上角数量
							},
							lab: {
								bodercolor: '#2D384A',
								color: "#ffffff",
								bgcolor: '#2D384A'
							}
						}
					},
					cart: {
						usezt:"T",
						icon: 'https://gzh.dxerp.net/dxgzh/1706239812474437634/202408/7427fc5890a1461eae3f06d48341d60a.png?key=123456',
						bgcolor: '#ffffff',
						color: '#E8E8E8',
						btn: {
							color: "#E8E8E8",
							bg: '#20232B'
						},
						num: {
							color: '#ffffff',
							bg: '#DEAB6D'
						}
					}

				},
				olddiandanitem: {
					cont: {
						bgimg: "https://imgoss.91erp.net/10001/sp/240801104438158.png",
						bgcolor: "#20232B",
						// bgcolor: "#181920",
						color: "#E8E8E8",
						navstyle:'10', //10 浅色 11深色
					},
					header: {
						line: '#8D8D8D',
						title: "#ffffff",
						boxborder: "#ffffff",
						iconstyle:'white'
					},
					shop: {
						title: "#ffffff",
						date: '#ffffff',
					},
					minsale: {
						bg: "#F5F5F5",
						color: '#000000',
						line: '#f0f0f0',
						chacolor: "#000000",
						zt: {
							overcolor: '#00aa00',
							uncolor: '#ff0000'
						}
					},
					ad: {
						type: '11', //10 券 11广告

					},
					loginbtn: {
						color: '#000000',
						bg: '#FFC80C'
					},

					menu: {
						bg: "#20232B",
						color:'',
						usezt:"T",
						jindian: {
							mc: "经典菜单",
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5'
						},
						tuijian: {
							mc: "店长推荐",
							zt: 'T',
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5'
						},
						tejia: {
							mc: "时段特价",
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5',
							zt: 'T',
						},
						newsp: {
							mc: "新品尝鲜",
							color: '#AAA9A9',
							selcolor: '#F1F1F1',
							linecolor: '#F5F5F5',
							zt: 'T',
						},
					},
					cls: {
						normal: {
							bg: '#20232B',
							color: "#E8E8E8",
						},
						sel: {
							bg: '#181920',
							color: "#E8E8E8",
						},
						size: '',
						tip: {
							color: '',
							bg: ''
						}
					},
					sp: {
						contbg:'',
						spbg:'',
						bg: '#181920',
						color: "#E8E8E8",
						clsmc: {
							line: '#ffffff',
							color: '#ffffff'
						},
						clschild: {
							normal: {
								bg: '',
								color: ""
							},
							sel: {
								bg: '',
								color: ""
							}
						},
						spitem: {
							spmc: {
								color: '#ffffff',

							},
							spremark: {
								color: '#acacac',

							},
							price: {
								marketcolor: "#acacac",
								salecolor: "#ffffff",
								clubcolor: "#000000",
							},
							btn: {
								color: '#ffffff',
								bg: '#2D384A',
								num: {
									color: "#ffffff"
								},
								tip: {
									color: '#ffffff',
									bg: '#BF9F6D'
								} //规格按钮右上角数量
							},
							lab: {
								bodercolor: '#2D384A',
								color: "#ffffff",
								bgcolor: '#2D384A'
							}
						}
					},
					cart: {
						usezt:"T",
						icon: 'https://gzh.dxerp.net/dxgzh/1706239812474437634/202408/7427fc5890a1461eae3f06d48341d60a.png?key=123456',
						bgcolor: '#ffffff',
						color: '#E8E8E8',
						btn: {
							color: "#E8E8E8",
							bg: '#20232B'
						},
						num: {
							color: '#ffffff',
							bg: '#DEAB6D'
						}
					}

				},
				menulist: [{
						mc: "页面",
						id: "cont"
					}, {
						mc: "顶部导航栏",
						id: "nav"
					},
					{
						mc: "顶部标题",
						id: "title"
					},
					{
						mc: "公告/低消",
						id: "minsale"
					},
					{
						mc: "新闻",
						id: "news"
					},
					{
						mc: "一级菜单栏",
						id: "menu"
					},
					// {
					// 	mc: "商品分类",
					// 	id: "cls"
					// },
					{
						mc: "商品",
						id: "sp"
					},
					{
						mc: "购物车",
						id: "cart"
					},

				],
				shopnotice: "门店公告门店公告门店公告门店公告门店公告",
				newslist: ['https://imgoss.91erp.net/10001/sp/240801104156664.png'],
				selsptype: 'normal',
				btnborder: '',
				selpagetype: 'cont',
				bgcolor: "#ff0000",
				backborderstyle: '',
				backline: '',
				minsaleline: '',
				selindex: 0,
				selimgtype: '',
				// contbgstyle:"",
				pageid: '',
				templateid: '',
				//浅色
				diandanitem2: {
					"shop": {
						"date": "#858585",
						"title": "#111111"
					},
					"ad": {
						"type": "11"
					},
					"loginbtn": {
						"color": "#000000",
						"bg": "#FFC80C"
					},
					"header": {
						"line": "#D9D9D9",
						"boxborder": "#D9D9D9",
						"title": "#1F1F1F",
						iconstyle:'white'
					},
					"cls": {
						"normal": {
							"color": "#383838",
							"bg": "#F5F5F5"
						},
						"size": "",
						"tip": {
							"color": "",
							"bg": ""
						},
						"sel": {
							"color": "#383838",
							"bg": "#E8E8E9"
						}
					},
					"cont": {
						'navstyle':'10', //10 浅色 11深色
						"bgcolor": "#FFFFFF",
						"color": "#E8E8E8",
						"bgimg": ""
					},
					"menu": {
						bg:"#20232B",
						color:'',
						usezt:"T",
						"newsp": {
							"color": null,
							"mc": "新品尝鲜",
							"selcolor": "#383838",
							"linecolor": "#2348D2",
							"zt":'T'
						},
						"tejia": {
							"color": null,
							"mc": "时段特价",
							"selcolor": "#383838",
							"linecolor": "#2348D2",
							"zt":'T'
						},
						"bg": "",
						"tuijian": {
							"color": null,
							"mc": "店长推荐",
							"selcolor": "#383838",
							"linecolor": "#2348D2",
							"zt":'T'
						},
						"jindian": {
							"color": null,
							"mc": "经典菜单",
							"selcolor": "#383838",
							"linecolor": "#2348D2"
						},
						"color": "#666666"
					},
					"sp": {
						contbg:'',
						spbg:'',
						"color": "#110E0F",
						"clschild": {
							"normal": {
								"color": "",
								"bg": ""
							},
							"sel": {
								"color": "",
								"bg": ""
							}
						},
						"bg": "#FFFFFF",
						"clsmc": {
							"color": "#383838",
							"line": "#2348D2"
						},
						"spitem": {
							"spmc": {
								"color": "#110E0F"
							},
							"spremark": {
								"color": "#A9A9A9"
							},
							"price": {
								"clubcolor": "#000000",
								"salecolor": "#FFFFFF",
								"marketcolor": "#908E8F"
							},
							"lab": {
								"bgcolor": "#F5F5F5",
								"color": "#404040",
								"bodercolor": "#2D384A",
								"bordercolor": "#D6D6D6"
							},
							"btn": {
								"color": "#ffffff",
								"bg": "#2049D3",
								"num": {
									"color": "#ffffff"
								},
								"tip": {
									"color": "#ffffff",
									"bg": "#BF9F6D"
								}
							}
						}
					},
					"minsale": {
						"color": "#183DF1",
						"bg": "#F4F7FC",
						"line": "#f0f0f0",
						"zt": {
							"overcolor": "#00aa00",
							"uncolor": "#ff0000"
						},
						"chacolor": "#183DF1"
					},
					"cart": {
						"usezt":"T",
						"bgcolor": "#ffffff",
						"color": "#E8E8E8",
						"num": {
							"color": "#ffffff",
							"bg": "#DEAB6D"
						},
						"icon": "https://gzh.dxerp.net/dxgzh/1706239812474437634/202408/7427fc5890a1461eae3f06d48341d60a.png?key=123456",
						"btn": {
							"color": "#FFFFFF",
							"bg": "#2049D3"
						}
					}
				}
			}
		},
		mounted() {
			this.selimgtype = ''
			this.btnborder = 'border:1px solid #2D384A;color:#2D384A;'
			this.backborderstyle = 'border:1px solid' + this.diandanitem.header.boxborder
			this.backline = 'border:1px solid' + this.diandanitem.header.line
			this.minsaleline = "border:1px solid" + this.diandanitem.minsale.line

			// if(this.diandanitem.cont.bgimg != undefined && this.diandanitem.cont.bgimg.length > 0){
			// 	this.contbgstyle =   'background:'+ this.diandanitem.cont.bgcolor +' url(\"' + this.diandanitem.cont.bgimg + '\") no-repeat;color:' + this.diandanitem.cont.color
			// }else{
			// 	this.contbgstyle =   'background:' + this.diandanitem.cont.bgcolor + ';color:' + this.diandanitem.cont.color
			// }
		},
		computed: {

			contbgstyle() {
				let _this = this;
				if(_this.diandanitem.cont){
					if (_this.diandanitem.cont.bgimg != undefined && _this.diandanitem.cont.bgimg.length > 0) {
						return 'background:' + _this.diandanitem.cont.bgcolor + ' url(\"' + _this.diandanitem.cont.bgimg +
							'\") no-repeat;color:' + _this.diandanitem.cont.color
					
					} else {
					
						return 'background:' + _this.diandanitem.cont.bgcolor + ';color:' + _this.diandanitem.cont.color
					}
				}
				
			}


		},

		methods: {
			onchange(color, t) {
				console.log(color, 'color');
				switch (t) {
					case 'backborder':
						this.backborderstyle = 'border:1px solid' + color
						break;
					case 'backline':
						this.backline = 'border:1px solid' + color
						break;
					case 'minsaleline':
						this.minsaleline = '1px solid' + color
						break;
				}
			},
			fundiandandata(param) {
				this.templateid = param.templateid
				this.pageid = param.pageid
				this.__getdesignfromtemplateid()
			}, 
			__getdesignfromtemplateid() {
				let _this = this;



				_this.diandanitem =_this.olddiandanitem;
				if (this.templateid != undefined && this.templateid.length > 0) {
					console.log(this.templateid,  'getdiandan,diandan');
					designTemplataApi.getDesign(this.templateid, 'diandan', (res) => {
						console.log(res, 'diandan.res');

						if (res.code == 200 && res.data.design != null) {
							if(res.data.design.header != undefined && res.data.design.header.title != undefined){
									_this.diandanitem = res.data.design;
							}
							else{
									_this.diandanitem =_this.olddiandanitem;
							}
						}

					});
				}
			},
			funsave() {
				this.$emit('diandanresult', {
					data: this.diandanitem
				})
			},
			setimg() {

			},
			funresetstyle() {
				this.diandanitem = this.olddiandanitem
			},
			funtappage(t) {
				this.selpagetype = t
			},
			changcontstyle(){
				if(this.contstyle == '浅色'){
					this.diandanitem =  modestyle.lightstyle 
					
				}else{
					this.diandanitem =  modestyle.deepstyle 
				}
			},
			funselfl(t) {
				this.selsptype = t
				switch (t) {
					case 'normal':
						break;
					case 'tuijian':

						break;
					case 'newsp':

						break;
					case 'tejia':

						break;
				}
			},
			resetimg(t) {
				switch (t) {
					case 'bgimg':
						this.diandanitem.cont.bgimg = ''
						//this.contbgstyle =   ''
						break;
					case 'cartimg':
						this.diandanitem.cart.icon = ''
						break;
				}
			},
			uploadimg(t) {
				this.selimgtype = t
				
				let user = util.getloginuser()
				let usertype = user.usertype
				console.log(usertype,'usertype')
				if(usertype == 'MCH'){
					this.$refs['selImgs'].funShow()
				}else{
					this.$refs['selisvImgs'].funShow()
				}
				
			},
			selisvImgsresult(imgitem){
				console.log(imgitem, 'selisvImgsresult.imgitem');
				if (this.selimgtype == 'bgimg') {
					this.diandanitem.cont.bgimg = imgitem.url
					// this.contbgstyle =  'background:url(' + this.diandanitem.cont.bgimg + ') no-repeat;color:' + this.diandanitem.cont.color
				} else {
					this.diandanitem.cart.icon = imgitem.url
				}
			},
			selImgsresult(imgitem) {
				if (this.selimgtype == 'bgimg') {
					this.diandanitem.cont.bgimg = imgitem.url
					// this.contbgstyle =  'background:url(' + this.diandanitem.cont.bgimg + ') no-repeat;color:' + this.diandanitem.cont.color
				} else {
					this.diandanitem.cart.icon = imgitem.url
				}
			},
			funselcls(index) {
				console.log(index, 'index');
				this.selindex = index
			},
			funselpage(item) {
				console.log(item,'item');
				this.selpagetype = item.id
			}
		}
	}
</script>

<style lang="scss" scoped>
	.phone-main {
		width: 375px;
		margin-top: 10px;
		position: relative;
		border: 4px solid #1f1c1c;
		border-radius: 20px;
		overflow: hidden;
		height: 800px;

		// background:url( https://imgoss.91erp.net/10001/sp/240801104438158.png) no-repeat;
	}

	.phone-main .top-nav {
		position: absolute;
		top: 0;
		z-index: 999;
		transition: all 0.3s;
		// background: #181920;
		color: #E8E8E8;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}

		.tit {
			position: absolute;
			left: 50%;
			bottom: 10px;
			transform: translateX(-50%);
		}

		.navimg {
			width: 375px;
			image-rendering: -moz-crisp-edges;
			image-rendering: -o-crisp-edges;
			image-rendering: -webkit-optimize-contrast;
			image-rendering: crisp-edges;
			-ms-interpolation-mode: nearest-neighbor;
		}
	}

	.view-content-top {
		height: 69px;
	}

	.backbox {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		width: 120px;
		height: 17px;
		padding: 5px 0;
		border-radius: 22px;
		margin-bottom: 10px;
		// border: 1px solid #ffffff;
		position: absolute;
		left: 70px;
		bottom: 0;
		transform: translateX(-50%);
		/* margin-top: 100rpx; */
	}

	.backbox .line {
		height: 100%;
		margin: 0 11px;
	}

	.backbox .searchimg {
		width: 20px;
		height: 20px;
		flex-shrink: 0;
	}

	.backbox .backimg {
		width: 40px;
		height: 40px;
		flex-shrink: 0;
	}

	.w100 {
		width: 100%;
	}

	.shop {

		padding: 14px 16px;
		margin: 0 auto 16px auto;
		justify-content: center;
		display: flex;
		flex-direction: column;
		color: #ffffff;
		border-radius: 10px;
		font-size: 11pt;
		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}
	}

	.shop .name {
		font-size: 14pt;
		font-weight: 700;
		color: #ffffff;


		margin-bottom: 12px;
	}

	.shop .telimg {
		width: 18px;
		height: 18px;
		flex-shrink: 0;
		margin-right: 10px;

	}

	.shop .addrimg {
		width: 23rpx;
		height: 26rpx;
		margin-right: 5px;
	}

	.shop .dhimg {
		width: 24px;
		height: 24px;

	}

	.shop .tel {
		display: flex;
		flex-direction: row;
		align-items: center;
		margin: 5px 0 5px 0;
		color: #8D8D8D;
		font-size: 10px;
	}

	.shop .addr {
		display: flex;
		flex-direction: row;
		align-items: center;

		font-size: 10pt;
	}

	.shop .addr .left {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		color: #767676;
	}

	.shop .addr .line {
		width: 1px;
		height: 14px;
		// border-right: 1rpx solid #8D8D8D;
		margin: 0 10px;
	}

	.loginbtn {
		width: 65px;
		height: 24px;
		text-align: center;
		line-height: 24px;
		border-radius: 15px;
		margin-left: 10px;
		padding: 3px 5px;
		font-size: 10pt;
		// background-color: #FFC80C;
		// color: #000000;
		font-weight: 600;
	}

	.dixiao {
		padding: 8px;
		width: 327px;
		margin: 13px auto 0 auto;
		// background: #F5F5F5;
		border-radius: 5px 5px 0 0;
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		align-items: center;
		font-size: 11px;
		// color: #000;
		position: relative;
	}

	.gonggao {
		padding: 8px;
		width: 327px;
		margin: 0 auto 6px auto;
		// background: #F5F5F5;
		border-radius: 0 0 5px 5px;
		// border-top: 1px solid #f0f0f0;
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		align-items: center;
		font-size: 11px;
		// color: #000;
		position: relative;
	}

	.noticeimgbox {
		width: 34px;
		height: 30px;
		// background: #F5F5F5;
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		z-index: 2;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.gonggao .ggimg {
		width: 14px;
		height: 14px;
		flex-shrink: 0;

	}

	.ward {
		white-space: nowrap; //设置不换行
		position: relative;
		animation: scroll 30s infinite linear; //滚动速度，值越大越慢

	}

	@keyframes scroll {

		//滚动范围
		0% {
			left: 5%;
		}

		100% {
			left: -100%;
		}
	}

	.swiperbox {
		width: 343px;
		margin: 0 auto;
		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}
	}

	.flsc {
		height: 32px;
		padding-top: 10px;

		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}
	}

	.flbox {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		width: 343px;
		margin: 0 auto;
		overflow: auto;
	}

	.flbox .fitem {
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-shrink: 0;
		width:25%;
		font-size: 11pt;
	}

	.flbox .fitem .mc {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 12px;

		line-height: 18px;
	}

	.flbox .fitem .bline {
		width: 26px;
		height: 3px;
		// background: #F5F5F5;
		margin-top: 5px;
	}

	.flactive {
		color: #F1F1F1;
	}

	.fnormal {
		color: #AAA9A9;
	}

	.el-carousel__container {
		height: 110px !important;
	}

	.spcont {}

	.left_sc {
		height: 500px;
		width: 79px;
		// background: #20232B;
		//     color: #E8E8E8;
		overflow: hidden;
		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}
	}

	.left_sc .list {
		width: 79px;
		height: 51px;
		align-items: center;
		justify-content: center;
		font-family: Source Han Sans SC, Source Han Sans SC;
		font-weight: 400;
		font-size: 11px;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;

	}

	.left_sc .list .clsimg {
		width: 16px;
		height: 16px;
		flex-shrink: 0;
	}

	.left_sc .list .clsmc {
		font-size: 12px;
		margin: 5px 0;
		width: 97%;
		text-align: center;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

	}

	.row2 {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-start;
		position: relative;
		align-items: flex-start;
	}

	.right_sc {
		width: calc(375px - 76px - 18px);
		overflow: auto;
		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}
	}

	.right_sc .list3 .btnbox {
		display: flex;

		align-items: center;
	}

	.right_sc .list3 {
		width: 96%;
		position: relative;
		overflow: hidden;
		margin-bottom: 16px;
		border-radius: 10px;
	}

	.right_sc .list3 .cloum {
		flex-grow: 1;
	}

	.right_sc .list3 .img2 {
		width: 82px;
		height: 82px;
		border-radius: 10px;

		flex-shrink: 0;
		position: relative;
	}

	.right_sc .list3 .kc2 {
		position: absolute;
		left: 5px;
		top: 5px;
		width: 82px;
		height: 82px;
		line-height: 82px;
		text-align: center;
		opacity: 0.6;
		color: #fff;
		font-size: 14px;
		background: #345;
	}

	.right_sc .list3 .cprice2 {
		display: flex;
		align-items: center;
		height: 15px;
		line-height: 15px;
		border-top-left-radius: 10px;
		font-size: 7pt;
		white-space: nowrap;
		padding: 2rpx 5px;
		color: #BF9F6D;
		font-weight: 400;
		font-size: 10px;
	}

	.right_sc .list3 .cprice {
		display: flex;
		align-items: center;
		height: 15px;
		line-height: 15px;
		border-top-left-radius: 10px;
		background: linear-gradient(to bottom right, #F3E6D4, #DEAB6D);
		position: absolute;
		bottom: 6rpx;
		right: 0;

		white-space: nowrap;
		padding: 2px 5px;
		color: #000000;
	}

	.cm {
		font-size: 9px;
		scale: 0.9;
	}

	.right_sc .list3 .viplogo {
		width: 10px;
		height: 10px;
	}

	.right_sc .list3 .kc1 {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		line-height: 110px;
		text-align: center;
		opacity: 0.6;
		color: #fff;
		font-size: 14px;
		background: #345;
	}

	.right_sc .list3 .spmc {
		font-size: 14px;
		max-height: 37px;
		overflow: hidden;

	}

	.right_sc .sptip {
		border-radius: 2px;
		padding: 0 5px;

		height: 16px;
		text-align: center;
		line-height: 16px;
		font-size: 9pt;
		/* margin-right: 20rpx; */
		margin: 5px 10px 5px 0;
		white-space: nowrap;
		overflow: hidden;
	}

	.right_sc .spgg {
		font-size: 12px;

		padding-left: 5px;


		word-break: break-all;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		/* 超出几行省略 */
		overflow: hidden;

	}

	.right_sc .price {

		font-family: 'Times New Roman', Times, serif;
		font-size: 11pt;
		margin-top: 5px;
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-shrink: 0;
	}

	.right_sc .price .dwmc {
		padding-left: 5px;

		font-size: 8pt;
	}

	.right_sc .price .yuanjia {
		text-decoration: line-through;
		padding-left: 5px;

		font-size: 9pt;
	}

	.right_sc .addbtn {
		width: 20px;
		height: 20px;
		display: flex;
		justify-content: center;
		line-height: 18px;
		font-size: 14pt;
		border-radius: 20px;


		margin-right: 5px;

	}

	.right_sc .abbox {
		position: relative;


	}

	.right_sc .abbox .buysl {
		width: 12px;
		height: 12px;



		line-height: 12px;
		text-align: center;
		border-radius: 12px;
		font-size: 8pt;
		font-weight: 600;
		position: absolute;
		top: -6px;
		right: 0px;
	}

	.right_sc .num {
		display: flex;
		align-items: center;
		margin: 0 10px;
		line-height: 20px;
	}

	.right_sc .ggbtn {
		width: 60px;
		height: 25px;
		line-height: 25px;
		text-align: center;
		border-radius: 25px;
		font-size: 10px;
		overflow: hidden;
	}

	.pagelist {
		margin-left: 10px;
		margin-right: 10px;
		box-shadow: 0 2px 6px #ccc;
		margin-top: 10px;
	}

	.pagelist .pitem {
		cursor: pointer;
		box-shadow: 0 1px 1px #f0f0f0;
		height: 50px;
		line-height: 50px;
		text-align: center;
		font-weight: 300;
		font-size: 13px;
		position: relative;
		width: 90px;
		overflow: hidden;
		white-space: nowrap;

	}

	.right_sc .clsline {
		width: 3px;
		height: 16px;
		margin-right: 4px;
	}

	.right_sc .clsmc2 {
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 24rpx;

		line-height: 36rpx;

	}

	.shopcar {
		width: 100%;
		position: absolute;

		left: 0;
		bottom: 0;
		height: 50px;
		display: flex;
		flex-direction: row;
		// background-color: #FFFFFF;
		z-index: 30;
		font-size: 12pt;
		font-weight: 700;
		z-index: 99;
		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}

	}

	.shopcar .imgbox {
		margin-left: 5px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		margin: 0 29px 0 18px;
	}

	.shopcar .imgbox .num {
		width: 20px;
		height: 20px;
		border-radius: 20px;


		position: absolute;
		top: 6px;
		right: -12px;
		text-align: center;
		line-height: 20px;
		font-size: 9pt;
	}

	.carimg {
		width: 24px;
		height: 30px;
	}

	.shopcar .total {
		line-height: 50px;
		flex-grow: 1;
		// color: #333;
		text-align: left;
	}

	.shopcar .jsbtn {
		height: 50px;
		line-height: 50px;
		width: 110px;
		text-align: center;
	}

	.slist {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 20px;

		.title {
			margin-right: 10px;
			flex-shrink: 0;

		}

		.full {
			flex-grow: 1;
		}
	}

	p {
		margin: 10px;
	}


	.wd {
		.title {
			width: 80px;
		}
	}

	.btn {
		display: flex;
		align-items: center;
	}

	.editstyle {
		background-color: #FFFFFF;
		width: 320px;
		box-shadow: 0 2px 6px #ccc;
		padding: 0 17px;
		overflow: auto;
		height: 807px;
		margin-top: 10px;
	}

	.bgimg {
		width: 150px;
		height: auto;
	}

	.selstyle {
		color: #ffffff;
		background: #3089dc;
	}

	.sline {
		border: 1px solid #F1F1F1;
	}

	.r20 {
		border-bottom-right-radius: 10px;
		bottom: 0;
	}

	.m18 {
		margin: 0 9px;
	}

	.row {
		display: flex;
	}

	.selborder {
		border: 1px solid #3089dc;
	}
	.w120 {
		width: 120rpx;
		height: 100%;
	}
	.minsale {
		transition: all 0.3s;

		& * {
			pointer-events: none;
		}

		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}
	}
</style>