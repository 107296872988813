<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="13"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>关键词回复</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<el-tabs v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="收到消息回复" name="second">
							<div class="">
								收到消息匹配类型：{{nomsgInfo.noCmdType}}
							</div>
							<el-input type="textarea" :rows="10" v-model="nomsgInfo.msg"
								style="margin: 10px 0;"></el-input>
							<el-button size="small" type="primary" :loading="loading" class="title"
								@click="submitForm()" style="float: right;">保存
							</el-button>
						</el-tab-pane>
						<el-tab-pane label="被关注回复" name="third">
							<div class="">
								关注回复消息匹配类型：{{gzmsgInfo.subscribeType}}
							</div>
							<el-input type="textarea" :rows="10" v-model="gzmsgInfo.msg"
								style="margin: 10px 0;"></el-input>
							<el-button size="small" type="primary" :loading="loading" class="title"
								@click="gzsubmitForm()" style="float: right;">保存
							</el-button>
						</el-tab-pane>
					</el-tabs>
				</el-card>
			</div>
		</div>

	</div>
</template>

<script>
	import leftnav from '../../../../../../components/customer/home/mp/weixingzh/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import gzhconfigApi from "../../../../../../api/cus/gzhconfig.js"
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				loading: false,
				activeName: "second",
				nomsgInfo: {
					msg: "",
					noCmdType: ""
				},
				gzmsgInfo: {
					msg: "",
					subscribeType: ""
				},
				mpid: ""
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.mpid = mpid
			} else {
				this.$router.push("/");
			}
			this.getnocmdData()
		},
		methods: {
			getnocmdData() {
				let param = {
					mpid: this.mpid
				}
				console.log(param, 'txtuse.param');
				gzhconfigApi.detail(param, res => {
					console.log(res, 'txtuse.res');
					if (res.code == 200) {
						this.nomsgInfo.noCmdType = res.data.noCmdType
						this.nomsgInfo.msg = res.data.noCmdMsg
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
				console.log(param, 'gzdetail.param');
				gzhconfigApi.gzdetail(param, res => {
					console.log(res, 'gzdetail.res');
					if (res.code == 200) {
						this.gzmsgInfo.subscribeType = res.data.subscribeType
						this.gzmsgInfo.msg = res.data.subscribeMsg
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			submitForm() {
				let param = {
					mpid: this.mpid,
					msg: this.nomsgInfo.msg
				}
				console.log(param, 'txtuse.param');
				gzhconfigApi.txtuse(param, res => {
					console.log(res, 'txtuse.res');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.nomsgInfo.msg = this.nomsgInfo.msg
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			gzsubmitForm() {
				let param = {
					mpid: this.mpid,
					msg: this.gzmsgInfo.msg
				}
				console.log(param, 'gztxtuse.param');
				gzhconfigApi.gztxtuse(param, res => {
					console.log(res, 'gztxtuse.res');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.gzmsgInfo.msg = this.gzmsgInfo.msg
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			handleClick(tab, event) {
				// console.log(tab, event);
			}
		}
	}
</script>

<style scoped>
	.box-card {
		margin-bottom: 10px;
	}
	.pagecontent {
		display: flex;
	
		height: calc(100vh - 81px);
	}
	
	.w200 {
		width: 200px;
	}
	
	.value {
		color: #777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow: 1;
	}
	.btnbox{
		width:100%;
		display:flex;
		justify-content: center;
	}
	.btnbox .btn{
	width: 200px;
	margin: 20px auto;
	}
	.page {
		background: #F5F7FA;
	}
	
	.border {
		border-bottom: 1px solid #E2E2E2;
	}
	
	.rightbox {
		padding-top: 20px;
		width: 1320px;
	}
</style>