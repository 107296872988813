<template>
    <div id="store">
        <h5>文案</h5>
        <el-input v-model="list.options.text" placeholder="请输入内容"></el-input>
    </div>
</template>

<script>
export default {
    name: 'Store',
 props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
    data() {
        return {
            list:""
        };
    },

    created() {
        this.list=this.data
    },

    methods: {
        
    },
};
</script>

<style lang="less" scoped>
#store{
    margin: 20px;
}
h5{
    margin-bottom: 20px;
}
</style>