<!--注册小程序-->
<template>
  <el-container class="index-con">
    <el-aside :class="showclass">
      <leftnav></leftnav>
    </el-aside>
    <el-container class="main-con">
      <el-main clss="index-main">
        <headtag></headtag>
        <router-view ></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// 导入组件
import navcon from '../../../../../components/customer/home/mp/zhifubaoapp/navcon.vue'
import leftnav from '../../../../../components/customer/home/mp/zhifubaoapp/leftnav.vue'
import headtag from '../../../../../components/headtag.vue'
export default {
  name: 'home',
  data() {
    return {
      showclass: 'asideshow',
      showtype: false,
	  mpid:''
    }
  },
  // 注册组件
  components: {
    navcon,
    leftnav,
    headtag
  },
  methods: {},
  created() {
	  console.log(this.$route.query.id,'this.$route.query.id');
	  if (this.$route.query.id != undefined) {
	  	this.mpid = this.$route.query.id
		console.log(this.mpid,'this.mpid');
		//sessionStorage.setItem("mpid", JSON.stringify(this.mpid));
	  }
    // 监听
    this.$root.Bus.$on('toggle', value => {
      if (value) {
        this.showclass = 'asideshow'
      } else {
        setTimeout(() => {
          this.showclass = 'aside'
        }, 300)
      }
    })
  },
  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  beforeMount() {
  }
}
</script>
<style lang="less">
.index-con {
  width: 100%;
  box-sizing: border-box;
}

.aside {
  width: 64px !important;
  height: 100%;
  background-color: #FFFFFF;
  margin: 0px;
}
.asideshow {
  width: 240px !important;
  height: calc(100vh - 100px) ;
  background-color: #FFFFFF;
  margin: 0px;
}

.index-main {
  padding: 0px;
  border-left: 2px solid #333;
}
 /deep/ .el-main{
	overflow: inherit !important;
}
</style>
