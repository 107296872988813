<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="11"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>总览</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>公众号开发信息</span>
					</div>
					<div class="main">
						<div class="item border">
							<div class="w200">开发者ID(AppID)</div>
							<div class="value">{{appinfo.appID}}</div>
						</div>
						<div class="item ">
							<div class="w200">开发者密码(AppSecret)</div>
							<div class="value">{{appinfo.appSecret}}</div>
						</div>
					</div>
				</el-card>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>服务器配置</span>
					</div>
					<div class="main" v-if="appinfo.isOpen == 1">
						<div class="item border" v-if="error">
							<div class="w200">错误</div>
							<div class="value">{{error}}</div>
						</div>
						<div class="item" v-else>
							<div class="w200">开发者密码(AppSecret)</div>
							<div class="value">{{appinfo.appSecret}}</div>
						</div>
					</div>

					<div class="main" v-if="appinfo.isOpen == 1">
						<div class="item" v-if="error">
							<div style="color: red;">
								错误:{{ error }}
							</div>
						</div>
						<div class="item" v-else>
							<div class="w200">消息提示</div>
							<div class="value">{{ auth_state == 0 ? '未授权':(auth_state == 1 ?'已授权':'未知状态') }}</div>
							<a :href="authurl" target="_self"><el-button size="small"
									type="primary">{{ auth_state == 1 ? '重新授权':'微信授权' }}</el-button></a>
						</div>
					</div>
					<div class="main" v-else>
						<div class="item" >
							<div class="w200">服务器地址(URL)</div>
							<div class="value">{{appinfo.postURL}}</div>
						</div>
						<div class="item" >
							<div class="w200">令牌(Token)</div>
							<div class="value">{{appinfo.token}}</div>
						</div>
						<div class="item" >
							<div class="w200">消息加解密密钥(EncodingAESKey)</div>
							<div class="value">{{appinfo.aesKey}}</div>
						</div>
						<div class="item" >
							<div class="w200">消息加解密方式：明文模式</div>
						</div>
					</div>
					<div class="main">
						<div class="item" >
							<div class="w200">注册小程序</div>
							<div class="gzhbtn" @click="fungzh">复用公众号注册小程序</div>
							<a :href="applyurl" v-if="applyurl && applyurl.length > 0">跳转授权</a>
						</div>
						
						
					</div>
				</el-card>
			</div>
		</div>

	</div>
</template>

<script>
	import leftnav from '../../../../../../components/customer/home/mp/weixingzh/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import accountApi from '../../../../../../api/cus/account'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				appinfo: {},
				authurl: '',
				auth_state: null,
				authmsg: '',
				error: "",
				applyurl: ""
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			if (mpid != undefined) {} else {
				// this.$router.push("/");
				this.$router.push("/customer/login?flag=gzh&gourl=" + encodeURIComponent("/customer/manage/app"));
			}
			accountApi.info({
				mpid: this.mpid
			}, res => {
				console.log(res, 'res.info');
				this.appinfo = res.data
				this.getauth()
			})
		},
		methods: {
			getauth() {
				accountApi.wexinauthurl(this.mpid, res => {
					console.log(res, 'res.auth');
					if (res.code == 200) {
						this.authurl = res.data.auth_url
						this.auth_state = res.data.auth_state

					} else {
						this.error = "授权获取失败," + res.msg
					}
				})
			},
			fungzh() {
				let _this = this
				let param = {
					wxgzh_mpid: _this.mpid
				}
				accountApi.regwxapp(param, res => {
					console.log(res, 'res.fungzh');
					if (res.code == 200) {
						_this.applyurl = res.data.applyurl

					} else {

					}
				})

			}
		}
	}
</script>

<style scoped>
	.box-card {
		margin-bottom: 10px;

	}

	.main .item {
		margin-bottom: 10px;
		font-weight: 700;
		font-size: 16px;
		padding: 18px 0;
		display: flex;
		align-items: center;
	}

	.title {
		font-size: 14px;
		font-weight: 700;
		margin-top: 30px;
	}

	.gzhbtn {
		width: 160px;
		height: 30px;
		line-height: 30px;
		border-radius: 5px;
		background-color: #00aaff;
		color: #ffffff;
		text-align: center;
		margin-top: 10px;
	}

	.page {
		background: #F5F7FA;
	}

	.border {
		border-bottom: 1px solid #E2E2E2;
	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);
	}

	.w200 {
		width: 200px;
	}

	.value {
		color: #777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow: 1;
	}

	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width: 340px;
		}
	}
</style>