<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="19"></leftnav>
			<div class="rightbox">
				<el-card class="box-card" >
					<div slot="header" class="clearfix">
						<div class="">
							<span>名称：</span><el-input type="text" size="small" v-model="formInline.name"
								style="width:200px;margin: 0 20px;"></el-input>
						</div>
						<div class="" style="display: flex;justify-content: left;">
							<el-button type="primary" size="small" @click="search">搜索</el-button>
						</div>
						.<div class="" style="display: flex;justify-content: left;">
							<el-button size="small" @click="funsync">同步</el-button>
						</div>
					</div>
					<!--列表-->
					<el-table size="small" :data="scanData" highlight-current-row v-loading="loading" :border="false"
						element-loading-text="拼命加载中" style="width: 100%;">
						<!-- 	
								<el-table-column align="center" sortable prop="chat_id" label="id" width="180">
								</el-table-column> -->
						<el-table-column align="center" sortable prop="nickname" label="名称" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="logo" label="logo" width="100">
							<template slot-scope="scope">
								<img :src="scope.row.logo" style="width: 80px;">
							</template>
						</el-table-column>
						<el-table-column align="center" sortable prop="corp_full_name" label="公司副名" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="corp_name" label="公司名" width="100">
						</el-table-column>
						<el-table-column align="center" sortable prop="job" label="职位" width="100">
						</el-table-column>
						<el-table-column align="center" sortable prop="sex" label="性别" width="180">
						</el-table-column>
						<el-table-column align="center" sortable prop="usertype" label="类型" width="180">
						</el-table-column>
						<!-- <el-table-column align="center" sortable prop="createdate" label="创建时间" width="180">
								</el-table-column> -->
						<!-- <el-table-column label="操作" min-width="200" fixed="right">
									<template slot-scope="scope">
										<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
										<el-button size="mini" type="danger"
											@click="deleteScan(scope.$index, scope.row)">删除</el-button>
									</template>
								</el-table-column> -->
					</el-table>
					<!-- 分页组件 -->
					<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
				</el-card>
			</div>
		</div>

	</div>
</template>

<script>
	import Pagination from '@/components/Pagination.vue'
	import customerApi from '../../../../../../api/cus/customer.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			Pagination,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				formInline: {
					name: "",
					pageno: 1,
					pagesize: 10,
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
				loading: false,
				scanData: [

				]
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist(this.formInline)
			},
			search() {
				this.formInline.pageno = 1
				this.getlist(this.formInline)
			},
			getlist(page) {
				let param = {
					mpid: this.mpid,
					name: page.name,
					pageno: page.pageno,
					pagesize: page.pagesize
				}
				customerApi.list(param, res => {
					if (res.code == 200) {
						this.scanData = res.data.list
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				console.log(parm, 'parm');
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.getlist(this.formInline)
			},
			handleEdit(index, row) {
				this.$router.push({
					path: "/customer/home/mp/weixinqiye/group/group-detail"
				})
			},

			funsync() {
				let param = {
					mpid: this.mpid,
				}
				customerApi.synclist(param, res => {
					if (res.code == 200) {
						this.$message({
							type: "message",
							message: res.msg
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			deleteScan() {

			}
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
	}
	.page{
	
	background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
		
	}
	.pagecontent{
		display: flex;
		overflow: auto;
		height: calc(100vh - 81px);
		
	}
	.box-card{
		width: 1038px;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
			
		}
	}
</style>