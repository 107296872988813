<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>企微查询</span>
				<div class="" style="display: flex;justify-content: left;">
					<el-input type="text" placeholder="请输入配置编号" v-model="formInline.mpid" size="small"
						class="mr"></el-input>
					<el-input type="text" placeholder="请输入应用名称" v-model="formInline.mpname" size="small"
						class="mr"></el-input>
					<el-button size="small" type="primary" @click.stop="funSearch">搜索</el-button>
				</div>
			</div>

			<!--列表-->
			<el-table size="small" :data="list" highlight-current-row v-loading="loading" :border="false"
				element-loading-text="拼命加载中" style="width: 100%;">
				<el-table-column align="center" prop="mpid" label="配置编号" width="180"></el-table-column>
				<el-table-column align="center" prop="mpname" label="应用名称" width="180"></el-table-column>
				<el-table-column align="center" prop="appid" label="应用ID" width="180"></el-table-column>
				<el-table-column align="center" prop="mpgid" label="企微ID" width="180"></el-table-column>
				<el-table-column align="center" prop="isuse" label="状态" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.isuse == 0">停用</span>
						<span v-else>启用</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="createdate" label="创建时间" width="180"></el-table-column>
				<el-table-column label="操作" min-width="250">
					<template slot-scope="scope">
						<el-button size="mini" type="primary" @click="clsEdit(scope.row)">查询授权订单</el-button>
						<el-button size="mini" type="primary" @click="funadd(scope.row)">新增订单</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页组件 -->
			<!-- <Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather" ></Pagination> -->
		</el-card>
		<!-- 编辑界面 -->
		<el-dialog title="订单新增" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
			<el-form label-width="150px" ref="editForm" :model="editForm">
				<el-form-item label="购买的月数" prop="buy_months">
					<el-select v-model="editForm.buy_months" placeholder="请选择" size="small" @change="funSelMonth">
						<el-option v-for="item in buy_monthslist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="总费用" prop="base_count">
					{{numtotal}}元
				</el-form-item>
				<el-form-item label="互通账号数" prop="external_count">
					<el-input size="small" v-model="editForm.external_count" auto-complete="off"
						placeholder="请输入互通账号数"></el-input>
				</el-form-item>
				<el-form-item label="备注" prop="memo">
					<el-input size="small" v-model="editForm.memo" auto-complete="off" placeholder="请输入备注"></el-input>
				</el-form-item>
				<el-form-item label="说明" prop="memo">
					<span style="color: orange;">此处支付所花费用来自微生活钱包，请确认钱包余额充足，工作人员会在3-5个工作日之内处理</span>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" class="title" @click="orderadd()">确定
				</el-button>
			</div>
		</el-dialog>
		<Pagination v-bind:child-msg="pageparm" @callFather="callFather" style="float: right;"></Pagination>
	</div>

</template>

<script>
	import Pagination from '@/components/Pagination.vue'
	import wxworkApi from '../../../../api/sys/wxwork.js'
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				title: "",
				loading: false,
				formInline: {
					mpid: '',
					mpname: '',
					mptype: '',
					pageno: 1,
					pagesize: 10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},

				editForm: {
					base_count: "",
					buy_months: "",
					external_count: "",
					memo: ""
				},
				buy_monthslist: [
					{
						id: 1,
						mc: "一个月"
					},
				
					{
						id: 3,
						mc: "三个月"
					},
					{
						id: 6,
						mc: "六个月"
					},
					{
						id: 12,
						mc: "一年"
					},
					{
						id: 24,
						mc: "两年"
					},
				],
				editFormVisible: false,
				list: [],
				numtotal: 0
			}
		},
		mounted() {
			this.getlist(this.formInline)
		},
		methods: {
			getlist(page) {
				let param = {
					mpid: page.mpid,
					mpname: page.mpname,
					mptype: '',
					pageno: page.pageno,
					pagesize: page.pagesize
				}
				console.log(param, 'param.clsmanagerlist');
				wxworkApi.list(param, res => {
					console.log(res, 'res.clsmanagerlist');
					if (res.code == 200) {
						this.list = res.data.list
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			funadd(item) {
				this.editFormVisible = true
				this.mpid = item.mpid
			},
			orderadd() {
				let param = {
					mpid: this.mpid,
					buy_months: this.editForm.buy_months,
					count: this.editForm.external_count,
					memo: this.editForm.memo
				}
				console.log(param, 'submitForm.param');
				wxworkApi.orderadd(param, res => {
					console.log(res, 'res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.$router.push({
							path: "orderlist?mpid=" + this.mpid + '&orderid=' + res.data
						})

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funSelMonth(e) {
				console.log(e, 'ff');
				switch (e) {
					case 3:
						this.numtotal = 100
						break;
					case 6:
						this.numtotal = 200
						break;
					case 12:
						this.numtotal = 300
						break;
					case 24:
						this.numtotal = 600
						break;
					default:
						break;
				}
			},
			funSearch() {
				this.getlist(this.formInline)
			},
			clsEdit(item) {
				this.$router.push({
					path: "orderlist?mpid=" + item.mpid
				})
			},
			// 分页插件事件
			callFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.getlist(this.formInline)
			},
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main {
		height: 500px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		overflow-y: auto;
	}

	.main .box {
		border: 2px solid #000;
		border-radius: 10px;
		margin: 10px;
		padding: 10px;
		height: 375px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}

	.main .box .bg {
		display: none;
	}

	.main .box:hover .bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.6);
	}

	.main .box .img {
		width: 150px;
		height: auto;
	}

	.main .box .img img {
		width: 100%;
		height: 100%;
	}

	.search {
		padding-bottom: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 10px 20px !important;
	}

	.mr {
		margin: 0 10px;
	}

	.box-card {
		width: 1320px;

	}
</style>