<template>
  <div class="drag-main"  :style="stylemain"
  @mouseenter="handleMouseenter"
  @mouseleave="handleMouseleave"
  @click="hanleclick"
  >
  <div
    class="drag-box"
    @mousedown="handleMousedown"
    
    :style="style"
    ref="box"
  ><slot></slot>
  </div>
  <i class="el-icon-sort" v-if="isselbox" @mousedown="handleMousedownSize"></i>
  <i class="el-icon-close" v-if="isselbox && allowdel == 1" @click.stop="handleDel"></i>
    

  </div>
</template>

 <!-- @mousemove="handleMousemove"
    @mouseup="handleMouseup"
    @mouseover="handleMouseover"
    @click.capture="handleClick" -->

<script>
export default {
  components: {},
  name: "vue-drag-box",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    allowdel:{
      type: Number,
      default: 1,
    },
    index: {
      type: Number,
      default: -1,
    },
	dragkey:{
		type: String,
		default: '',
	}
  },
  data() {
    return {
      isPC: true,
      platform: "pc",
      isMouseDown: false,
      isselbox: false,

      startX: 0,
      startY: 0,
      distanceX: 0,
      distanceY: 0,
      oldLeft: 0,
      oldTop: 0,

      oldWidth: 0,
      oldHeight: 0,


      left: 0,
      top: 0,
      //width: 0,
      //height: 0,
      frame: null,

      options: {},
    };
  },
  created() {
    this.options = this.data;
  },
  watch:{
    data: {
      handler(o,v){
      this.options = v
      }
    },
  },

  computed: {
    stylemain() {
      if(this.options){
        if (this.isselbox) {
        return {
          left: `${this.options.left}px`,
          top: `${this.options.top}px`,
          border: "1px solid #00ff00",
          'width':`${this.options.width}px`,
          'height':`${this.options.height}px`,
        };
      } else {
        return {
          left: `${this.options.left}px`,
          top: `${this.options.top}px`,
          'width':`${this.options.width}px`,
          'height':`${this.options.height}px`,
        };
      }
      }
      else{
        return {}
      }
      
    },
    style() {
      if(this.options){
        return {
          'width':`${this.options.width}px`,
          'height':`${this.options.height}px`,
          
        };
      }
      else{
        return {}
      }
      
    },
  },
  watch: {
    // width() {
    //   this.handleResize();
    // },
    // height() {
    //   this.handleResize();
    // },
  },
  mounted() {
    this.handleResize();
  },
  beforeDestroy() {
    this.frame && cancelAnimationFrame(this.frame);
    document.onmousemove = null;
        document.onmouseup = null;
  },
  methods: {
    // 获取容器的大小
    getSize() {
      const box = this.$refs.box;
      const width = box.offsetWidth;
      const height = box.offsetHeight;

      // if (width !== this.width) this.width = width;
      // if (height !== this.height) this.height = height;
      this.frame = requestAnimationFrame(this.getSize);
      if (this.options.width !== 0) {
        cancelAnimationFrame(this.frame);
      }
    },
    hanleclick(e){
		
		let p = {
          index:this.index,
		  dragkey:this.dragkey
        }
        this.$emit('selectHandler',p)
    },

    // 窗口大小变化事件
    handleResize() {
      this.left = this.$parent.innerWidth - this.options.width - this.initLeft;
      this.bottom = this.initBottom;
      this.isDown = false;
      //this.recordPosition()
      this.getSize();
    },
    handleDel(e){
      this.$confirm('确认删除吗?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
          this.$emit('deleteHandle',{
			  index:this.index,
			  dragkey:this.dragkey
		  })
				}).catch(() => {});

      
    },
    handleMousemove(e) {
    },
    handleMouseenter(e){
      this.isselbox = true;
    },
    handleMouseleave(e) {
      this.isselbox = false;
    },

    handleMousedownSize(e) {
      e.preventDefault();
      
    

      this.oldWidth = this.options.width;
      this.oldHeight = this.options.height;

      this.startX = e.clientX || e.touches[0].clientX;
      this.startY = e.clientY || e.touches[0].clientY;

      this.isMouseDown = true;
      

      document.onmousemove = (ev) => {
       
        if (!this.isMouseDown) return false;
        const w = this.width;
        // 获取拖拽移动的距离
        const eventX = ev.clientX || ev.touches[0].clientX;
        const eventY = ev.clientY || ev.touches[0].clientY;

        this.$set(this.options,'width',eventX - (this.startX - this.oldWidth))
        this.$set(this.options,'height',eventY - (this.startY - this.oldHeight))

        //this.options.width = eventX - (this.startX - this.oldWidth);
        //this.options.height = eventY - (this.startY - this.oldHeight);

      };
      document.onmouseup = (ev) => {
       

        if (!this.isMouseDown) return false;
        this.isMouseDown = false;
        //this.isselbox = false;

        const eventX = ev.clientX || ev.changedTouches[0].clientX;
        if (eventX === this.startX) return false;
        document.onmousemove = null;
        document.onmouseup = null;
      };

      return false;
    },

    //鼠标按下
    handleMousedown(e) {
      e.preventDefault();
      // 获取拖拽起始位置坐标
   
      this.oldLeft = this.options.left;
      this.oldTop = this.options.top;

      this.startX = e.clientX || e.touches[0].clientX;
      this.startY = e.clientY || e.touches[0].clientY;
      this.isMouseDown = true;
      
      document.onmousemove = (ev) => {
       
        if (!this.isMouseDown) return false;
        //const w = this.width;
        // 获取拖拽移动的距离
        const eventX = ev.clientX || ev.touches[0].clientX;
        const eventY = ev.clientY || ev.touches[0].clientY;

        //if (moveX < 0 || moveX + 40 >= w) return false;

        //console.log("movex:" + moveX);

        //this.startX = this.options.left + moveX;

        this.options.left = eventX - (this.startX - this.oldLeft);
        this.options.top = eventY - (this.startY - this.oldTop);
       
        // this.slider.style.left = moveX + "px";
        // this.block.style.left = moveX + "px";
        // this.sliderMask.style.width = moveX + "px";
      };
      document.onmouseup = (ev) => {
       

        if (!this.isMouseDown) return false;

        this.isMouseDown = false;
        const eventX = ev.clientX || ev.changedTouches[0].clientX;
        //if (eventX === this.startX) return false;

        //this.options.left = eventX - (this.startX - this.oldLeft ) ;

        //this.options.left =  moveX;

        document.onmousemove = null;
        document.onmouseup = null;
      };

      // return false;
      //event.preventDefault()

      // console.log('handleMousedown')
      // this.startX = event.clientX
      // this.startY = event.clientY
      // this.isDown = true
    },

    handleClick(event) {
      if (this.isPC && !event.button) {
        event.stopImmediatePropagation();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.drag-main{
  cursor: pointer;
  position: absolute;
  overflow: hidden;

  .el-icon-sort{
    height: 10px;
  width: 10px;
  position: absolute;
    display: block;
    color: #ff7300; 
    right: 2px;
  bottom: 1px;
  }
  .el-icon-close{
    height: 10px;
  width: 10px;
  position: absolute;
    display: block;
    color: #048bf9; 
    right: 2px;
    top: 0px;
  }
  
}
.drag-box {
 
  line-height: 0;
  width: 100%;
}

</style>