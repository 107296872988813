<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="23"></leftnav>
			<div class="rightbox" >
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<div style="flex-grow: 1;">企业门店信息</div>
						<el-button @click="funflushshop" type="primary" v-loading.fullscreen.lock="fullshopscreenLoading">同步门店</el-button>
					</div>
					<!--列表-->
					<el-table size="large" :data="list" highlight-current-row v-loading="loading" :border="false"
						element-loading-text="拼命加载中" style="width: 100%;">
						<el-table-column align="center" prop="mchno" label="企业号" width="100">
						</el-table-column>
						<el-table-column align="center" prop="mchtype" label="门店类型" width="100">
						</el-table-column>
						<el-table-column align="center" prop="shopname" label="店铺名称" width="180">
						</el-table-column>
						
						<el-table-column align="center" prop="updateDate" label="更新时间" width="180">
						</el-table-column>
						<el-table-column label="操作" min-width="200">
							<template slot-scope="scope">
								<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">配置</el-button>
								<el-button type="primary" size="mini"
									@click="handdesk(scope.$index, scope.row)">桌台</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页组件 -->
					<!-- <Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather" ></Pagination> -->
				</el-card>
				<el-dialog title="桌台列表 " top="2%" :width="showmenu == true ? '60%' : '90%'" :visible.sync="showdesk" @close="showdesk = false">
					<el-button type="primary" size="mini" @click="flushdesk()"
						v-loading.fullscreen.lock="fullscreenLoading">同步桌台</el-button>
						
						&nbsp;&nbsp;&nbsp;
						<a :href=" '/api/customer/qiyeweixin/qrdesk/zip?qrid=' + qrid" target="_blank">
						<el-button type="primary" size="mini"  v-loading.fullscreen.lock="fullscreenLoading">二维码下载</el-button>
						</a>
					<el-table size="large" max-height="700px" :data="desklist" highlight-current-row v-loading="loading"
						:border="false" element-loading-text="拼命加载中" style="width: 100%;">
						<el-table-column align="left" prop="deskmc" label="序" width="80">
							<template v-slot="scope">
								{{scope.$index +1}}
							</template>
						</el-table-column>
						<el-table-column align="center" prop="deskmc" label="桌台名称" width="240">
						</el-table-column>
						<el-table-column align="center" prop="deskid" label="桌台编号" width="240">
						</el-table-column>
						<el-table-column align="center" prop="updateDate" label="更新时间" width="180">
						</el-table-column>
						<el-table-column label="操作" align="right" min-width="300">
							<template slot-scope="scope">
								<el-button type="primary" size="mini"
									@click="handewm(scope.$index, scope.row)">获取企微二维码</el-button>
								<el-button size="mini" @click="handtest(scope.row)">测试</el-button>

							</template>
						</el-table-column>

					</el-table>
				</el-dialog>
				<el-dialog title="企微二维码 " top="5%" :width="showmenu == true ? '30%' : '90%'" :visible.sync="ewmshow" @close="ewmshow = false">
					<div style="width: 100%;display: flex;justify-content: center;">
						<el-image style="margin: 20px auto;" :src="qr_code"></el-image>
					</div>

				</el-dialog>
				<el-dialog title="企微桌台二维码 " top="5%" :width="showmenu == true ? '30%' : '90%'":visible.sync="testewmshow" @close="testewmshow = false">
					<div style="width: 100%;display: flex;justify-content: center;">
						<el-image style="margin: 20px auto;" :src="deskqrcode"></el-image>
						
					</div>
					<div>{{deskqrurl}}</div>
				</el-dialog>
			</div>
		</div>

	</div>
</template>

<script>
	import Pagination from '@/components/Pagination.vue'
	import deskqrcodeApi from '../../../../../../api/cus/deskqrcode.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import signApi from '../../../../../../api/cus/sign.js'
	export default {
		components: {
			Pagination,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				loading: false,
				list: [],
				desklist: [],
				showdesk: false,
				qrid: '',
				fullscreenLoading: false,
				fullshopscreenLoading:false,
				qr_code: '',
				ewmshow: false,
				deskqrcode: '',
				testewmshow: false,
				shopshow:'',
				qrshopid:'',
			qrmchno:'' ,
				qrshopname:'' ,
				showmenu:true,
				deskqrurl:''
			}
		},
		mounted() {
			let screenWidth = window.innerWidth
			console.log(screenWidth,'this.screenWidth');
			if(screenWidth <= 750){
				this.showmenu = false
			}else{
				this.showmenu = true
			}
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist()
			},
			getlist(page) {
				deskqrcodeApi.bindshoplist(res => {
					console.log(res, 'reslist');
					if (res.code == 200) {
						this.list = res.data
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			// 分页插件事件

			search() {

				this.getlist()
			},

			handleEdit(index, row) {
				
				this.$router.push({
					path: "/customer/home/mp/weixinqiye/deskqrcode/detail",
					query: {
						mpid: this.mpid,
						shopid: row.shopid,
						mchno: row.mchno,
						mchshopid: row.mchshopid,
						mchshopname: row.mchshopname,
						mchshopno: row.mchshopno,
					}
				})
			},
			flushdesk() {
				
				console.log(this.qrid,'flushdesk.qrid');
				if(this.qrid != undefined && this.qrid != null && this.qrid.length > 0){
					
				}else{
					this.$message({
						message:'请先配置桌台',
						type:'error'
					})
					return
				}
				let param = {
					qrid: this.qrid,
				}
				this.fullscreenLoading = true;
				console.log(param, 'flushdesk.param');
				setTimeout(() => {
					deskqrcodeApi.flushdesk(param, res => {
						console.log(res, 'flushdesk.res');
						if (res.code == 200) {
							this.fullscreenLoading = false;
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							})
						}
					})

				}, 2000);


			},
			fundabao(){
				let param ={
					qrid:this.qrid
				}
				deskqrcodeApi.daobao(param,res => {
					console.log(res, 'reslist');
					if (res.code == 200) {
						
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			funflushshop(){
				this.fullshopscreenLoading = true;
				setTimeout(() => {
					deskqrcodeApi.flushshop(res => {
						console.log(res, 'flushshop.res');
						if (res.code == 200) {
							this.fullshopscreenLoading = false;
							this.list = res.data
						} else {
							this.$message({
								message: res.msg,
								type: 'error'
							})
						}
					})
				
				}, 2000);
				
				
			},
			handtest(item) {
			
				 let qrUrl = `https://gzh.dxerp.net/published/qiyewx/qrcode/man?desk=${item.deskid}`
				let imgqr = 'https://wxapi.dxerp.net/open/qrimg.aspx?qr=' + encodeURIComponent(qrUrl) + '&t=1&w=240&h=240'
				console.log(qrUrl,'qrUrl');
				this.deskqrurl = qrUrl
				this.deskqrcode = imgqr
				this.testewmshow = true
				
				
				
				// console.log(item,'test.item');
				// this.$router.push({
				// 	path:"/published/qiyewx/qrcode/man",
				// 	query:{
				// 		desk:item.deskid,
				// 		}
				// })
			},
			handewm(index,row) {
				console.log(row,'handewm');
				let param = {
					sysid: row.sysid
				}
				console.log(param, 'param');
				deskqrcodeApi.getqwewm(param, res => {
					console.log(res, 'handewm.res');
					if (res.code == 200) {
						this.qr_code = res.data.qr_code
						this.ewmshow = true
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			handdesk(index, row) {
				console.log(row,'row');
				this.qrid = ''
				this.qrshopid = row.mchshopid
				this.qrmchno = row.mchno
				
				console.log(this.qrmchno,'this.qrmchno');
				this.showdesk = true
				let param = {
					shopid: row.shopid
				}
				console.log(param, 'param');
				deskqrcodeApi.desklist(param, res => {
					console.log(res, 'handdesk.res');
					if (res.code == 200) {
						this.desklist = res.data.list
						this.qrid = res.data.qritem != null ? res.data.qritem.qrid : ''

					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},

		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
	}

	.page {

		background: #F5F7FA;

	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
		overflow: auto;
		margin: 0 auto;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);

	}
	.box-card {
		width: 1038px;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
		}
	}
</style>