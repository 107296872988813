<template>
  <div>
    <!-- 文字型 -->
    <van-tabs
      v-model="active"
      :title-active-color="list.options.textcolor"
      swipeable
      :background="list.options.backgound"
      :title-inactive-color="list.options.morencolor"
      ellipsis
      :color="list.options.descolor || 'red'"
      v-if="list.data.length > 0"
    >
      <van-tab
        :title="item.label || '标签'+(index+1)"
        v-for="(item, index) in list.data"
        :name="index"
        :key="index"
      ></van-tab>
    </van-tabs>
    <van-tabs v-model="active" v-else>
      <van-tab title="标签 1"></van-tab>
      <van-tab title="标签 2"></van-tab>
      <van-tab title="标签 3"></van-tab>
      <van-tab title="标签 4"></van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      active: "",
      list: "",
    };
  },

  created() {
    this.list = this.data;
  },

  methods: {},
};
</script>

<style lang="less" scoped>
</style>