<template>
	<!-- 设置背景样式 -->
	<div id="wenben" :style="{ borderBottom: border ,background: list.options.bgcolor}">
		
		<div :style="{'padding-top':margintop,
          'padding-bottom':marginbottom,'padding-left':  list.options.paddingpage + 'px',
        'padding-right':  list.options.paddingpage + 'px',}"  v-if="list.options.loginstate == 0">
		
		<div class="box loginstate0" :style="{background: list.options.backgroundun,'padding-top':paddingtop,
		  'padding-bottom':paddingbottom,'border-radius':borderradius}">
			<div class="cont">
				<div :style="{
		  		  fontSize: size,
		  		  textAlign: align,
		  		  fontWeight: bold,
		  		  color: list.options.titlecolor,
		  		}">
					{{ list.options.name }}
				</div>
				<div class="describe" :style="{ fontSize: describesize, color: list.options.describecolor }">
					{{ list.options.label}}
				</div>
			</div>

			<div class="btns" v-if="list.options.btntxt.length > 0">
				<span :style="{background: list.options.btncolor}">{{list.options.btntxt}}</span>
			</div>
			<div class="bgimg" v-if="list.options.loginimg && list.options.loginimg.length>0">
				<img :src="list.options.loginimg" style="width: 100%;" />
			</div>
		</div>
		
		</div>
		
		<div :style="{'padding-top':margintop,
		  'padding-bottom':marginbottom,'padding-left':  list.options.paddingpage + 'px',
		'padding-right':  list.options.paddingpage + 'px',}"  v-else-if="list.options.loginstate == 1">
			<div class="box loginstate1" :style="{background: list.options.background,'padding-top':paddingtop,
          'padding-bottom':paddingbottom,'border-radius':borderradius}" >
				<div class="cont">
					<div class="head">
						<div class="logo" v-if="list.options.logo.length > 0">
							<img :src="list.options.logo" />
						</div>
						<div class="center">
							<div class="title" :style="{'color':list.options.clubcolor}">会员名</div>
							<div class="memo" :style="{'color':list.options.clubcolor}">卡号：***</div>
						</div>
						<div class="btn">
							<img :src="list.options.ewm" />
						</div>
					</div>
					<div class="line" :style="{background:list.options.linecolor}"></div>
					<div class="footer">
						<div class="item">
							<div class="price">
								<span class="flg">￥</span><span
									:style="{'color':list.options.describecolor}">1000</span>
							</div>
							<div class="op" :style="{'color':list.options.titlecolor}">
								储值
							</div>
						</div>
						<div class="item">
							<div class="price" :style="{'color':list.options.describecolor}">
								1000
							</div>
							<div class="op" :style="{'color':list.options.titlecolor}">
								积分
							</div>
						</div>
						<div class="item">
							<div class="price" :style="{'color':list.options.describecolor}">
								100
							</div>
							<div class="op" :style="{'color':list.options.titlecolor}">
								礼券
							</div>
						</div>
						<div class="item">
							<div class="price" :style="{'color':list.options.describecolor}">
								2
							</div>
							<div class="op" :style="{'color':list.options.titlecolor}">
								礼品卡
							</div>
						</div>
					</div>
				</div>
				<div class="bgimg" v-if="list.options.bgimg.length> 0">
					<img :src="list.options.bgimg" style="width: 100%;" />
				</div>
			</div>
			
			</div>
			
			
			<div :style="{'padding-top':margintop,
			  'padding-bottom':marginbottom,'padding-left':  list.options.paddingpage + 'px',
			'padding-right':  list.options.paddingpage + 'px',
			'overflow':'hidden',
			
			
			}" v-else>
			
			
			
			<div class="box-uncard" :style="{'border-radius':borderradius,background: list.options.background,
			'overflow':'hidden'}">
			
			<div class="bgimg" v-if="list.options.bgimg.length> 0">
				<img :src="list.options.bgimg" style="width: 100%;" />
			</div>
			
				<div class="box loginstate3" :style="{background: list.options.background,'padding-top':paddingtop,
				'padding-bottom':paddingbottom,}" >
								<div class="cont" :style="{'width':conwidth}">
									<div class="head">
										<div class="logo" v-if="list.options.logo.length > 0">
											<img :src="list.options.logo" />
										</div>
										<div class="center">
											<div class="title" :style="{'color':list.options.clubcolor}">会员名</div>
											<div class="memo" :style="{'color':list.options.clubcolor}">卡号：***</div>
										</div>
										<div class="btn">
											<img :src="list.options.ewm" />
										</div>
									</div>
								</div>
								
								
								
							</div>
							
							<div class="box loginstate0 " :style="{background: list.options.backgroundunclub,'padding-top':paddingtop,
							  'padding-bottom':paddingbottom,
							  'margin-left':clubmargin,
							  'margin-right':clubmargin,
							  'margin-bottom':clubmargin,
							  
							  'border-radius':clubradius
							  }" >
								<div class="cont">
									<div :style="{
							  		  fontSize: size,
							  		  textAlign: align,
							  		  fontWeight: bold,
							  		  color: list.options.titlecolor,
							  		}">
										{{ list.options.clubname }}
									</div>
									<div class="describe" :style="{ fontSize: describesize, color: list.options.describecolor }">
										{{ list.options.clublabel}}
									</div>
								</div>
							
								<div class="btns" v-if="list.options.clubbtntxt&&list.options.clubbtntxt.length > 0">
									<span :style="{background: list.options.btncolor}">{{list.options.clubbtntxt}}</span>
								</div>
								<div class="bgimg" style="width: 100%;" v-if="list.options.loginimgclub && list.options.loginimgclub.length>0">
									<img :src="list.options.loginimgclub" style="width: 100%;" />
								</div>
							</div>
				
			</div>
			
			
			</div>
		</div>
</template>

<script>
	export default {
		name: "club_newadd",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
			};
		},

		created() {
			this.list = this.data;
		},
		computed: {
			//  内容宽度
			conwidth() {
				return  (750/2 - this.list.options.paddingpage * 2 - 32) +'px'
			},
			//  标题字体大小
			size() {
				if (this.list.options.size == 3) {
					return "12px";
				} else if (this.list.options.size == 2) {
					return "14px";
				} else {
					return "16px";
				}
			},
			// 标题显示位置
			align() {
				if (this.list.options.position == 1) {
					return "left";
				} else if (this.list.options.position == 2) {
					return "center";
				}
			},
			// 描述文字大小
			describesize() {
				if (this.list.options.describe == 3) {
					return "12px";
				} else if (this.list.options.describe == 2) {
					return "14px";
				} else {
					return "12px";
				}
			},
			// 标题加粗
			bold() {
				if (this.list.options.bold == 2) {
					return "900";
				} else {
					return "300";
				}
			},
			// 底部分割线
			border() {
				if (this.list.options.border) {
					return "1px solid #cccccc";
				} else {
					return "";
				}
			},
			margintop() {
				console.log(this.list.options, 'this.list.options');
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + 'px';
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
						return this.list.options.marginbottom + 'px';
				}
			},
			paddingtop() {
				if (this.list.options) {
					if (this.list.options.paddingtop && this.list.options.paddingtop > 0)
						return this.list.options.paddingtop + 'px';
				}
			},
			paddingbottom() {
				if (this.list.options) {
					if (this.list.options.paddingbottom && this.list.options.paddingbottom > 0)
						return this.list.options.paddingbottom + 'px';
				}
			},
			borderradius() {
				if (this.list.options) {
					if (this.list.options.borderradius && this.list.options.borderradius > 0)
						return this.list.options.borderradius + 'px';
				}
			},
			clubmargin(){
				if (this.list.options) {
					if (this.list.options.clubmargin && this.list.options.clubmargin > 0)
						return  this.list.options.clubmargin + 'px ' ;
				}
			},
			clubradius(){
				if (this.list.options) {
					if (this.list.options.clubradius && this.list.options.clubradius > 0)
						return this.list.options.clubradius + 'px';
				}
			},
			paddingpage() {
				if (this.list.options) {
					if (this.list.options.paddingpage && this.list.options.paddingpage > 0)
						return this.list.options.paddingpage + 'px';
				}
			},
		},
		methods: {},
	};
</script>

<style lang="less" scoped>
	#wenben {
		width: 100%;
		box-sizing: border-box;
	}

	.box {
		padding: 16px;
		
		position: relative;
		overflow: hidden;
	}
	.box-uncard{
		overflow: hidden;
		position: relative;
	}
	.box-uncard .bgimg{
		position: absolute;
		left: 0;
		top: 0;
		width: 375px;
		height: 100%;
	}

	.loginstate0 {
		height: 60px;
	}

	.loginstate1 {
		height: 130px;
	}
	.loginstate3 {
		height: 60px;
	}
	.mr{
		margin: 10px 0;
	}
	.box .cont {
		position: relative;
		z-index: 999;
		display: flex;
		flex-direction: column;
	}

	.box .cont .head {
		padding-bottom: 10px;
		color: #fff;
		display: flex;
		position: relative;
	}

	.box .cont .head .center {
		padding: 10px 0 0 10px;
	}

	.box .cont .head .center .title {
		font-size: 16px;
		font-weight: 600;
	}

	.box .cont .head .center .memo {
		font-size: 10px;
		opacity: 0.8;
	}

	.box .cont .head .btn {
		width: 30px;
		position: absolute;
		right: 0;
		top: 0;
	}

	.box .cont .head .btn img {
		width: 100%;
		height: 100%;
	}

	.box .cont .head .logo {
		width: 50px;
		height: 50px;
		border-radius: 50px;
		overflow: hidden;
	}

	.box .cont .head .logo img {
		width: 100%;
		height: 100%;
	}

	.box .cont .line {
		width: 100%;
		height: 1px;
		background-color: #6c6c6c;
	}

	.box .bgimg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.box .bgimg img {
		width: 100%;
		height: 100%;
	}

	.box .btns {
		position: absolute;
		right: 20px;
		top: 30px;
		overflow: hidden;
	}

	.box .btns span {
		display: block;
		color: #fff;
		width: 56px;
		min-width: 56px;
		height: 20px;
		font-size: 14px;
		line-height: 20px;
		padding: 2px 10px;
		border-radius: 12px;
		font-weight: 600;
		white-space: nowrap;
		background-image: linear-gradient(90deg, #feae79, #ff3d3d);
	}

	.box .footer {
		color: #fff;
		height: 80px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}

	.box .footer .price {
		margin-bottom: 5px;
		font-size: 16px;
		font-weight: 600;
	}

	.box .footer .flg {
		font-size: 10px;
		color: #ccc;
	}

	.box .footer .item {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	.text {
		overflow: hidden;
		line-height: 30px;
		font-size: 16px;

		p {
			margin: 0 20px;
		}
	}

	.describe {
		line-height: 30px;
		font-size: 12px;
		word-wrap: break-word;
	}

	.box .btns {
		position: absolute;
		right: 20px;
		top: 30px;
		z-index: 9;
		text-align: center;
		overflow: hidden;
	}

	.box .btns span {
		display: block;
		color: #fff;
		width: 70px;
		min-width: 70px;
		height: 20px;
		font-size: 14px;
		line-height: 20px;
		padding: 2px 10px;
		border-radius: 12px;
		font-weight: 600;
		white-space: nowrap;
		background-image: linear-gradient(90deg, #feae79, #ff3d3d);
	}
</style>