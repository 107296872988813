<template>
  <div>
    <div id="notice" >
      <div  :style="{color:list.options.textcolor,'margin-left':marginpage,
          'margin-right':marginpage,
		  'margin-top':margintop,
          'margin-bottom':marginbottom,
          'padding-top':paddingtop,
		  background:list.options.background,
          'padding-bottom':paddingbottom,'border-radius':borderradius}" class="wrap">
		 
		  <div
		    v-if="
		      list.options && list.options.bgimg && list.options.bgimg.length > 0
		    "
		  >
		    <img
		      :src="
		        list.options.bgimg && list.options.bgimg.length > 0
		          ? list.options.bgimg
		          : ''
		      "
		      class="bgimg"
		    />
		  </div>
		  <div class="bgcont" >
			  <img
			    :src="
			      list.options.tbimg && list.options.tbimg.length > 0
			        ? list.options.tbimg
			        : ''
			    "
			    class="tbimg"
			  />
			  <span style="line-height: 50px;">{{list.options.notice||"这是一段公告这是一段公告这是一段公告这是一段公告这是一段公告" }}</span>
		  </div>
		
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "text_notice",
props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
   created() {
    this.list = this.data;
  },
  data() {
    return {
      list: {},

    };
  },
	computed:{
		margintop() {
			console.log(this.list.options,'this.list.options');
			if (this.list.options) {
				if (this.list.options.margintop && this.list.options.margintop > 0)
					return this.list.options.margintop + 'px';
			}
		},
		marginbottom() {
			if (this.list.options) {
				if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
					return this.list.options.marginbottom + 'px';
			}
		},
		paddingtop() {
			if (this.list.options) {
				if (this.list.options.paddingtop && this.list.options.paddingtop > 0)
					return this.list.options.paddingtop + 'px';
			}
		},
		paddingbottom() {
			if (this.list.options) {
				if (this.list.options.paddingbottom && this.list.options.paddingbottom > 0)
					return this.list.options.paddingbottom + 'px';
			}
		},
		marginpage() {
			if (this.list.options) {
				if (this.list.options.marginpage && this.list.options.marginpage > 0)
					return this.list.options.marginpage + 'px';
			}
		},
		borderradius() {
			if (this.list.options) {
				if (this.list.options.borderradius && this.list.options.borderradius > 0)
					return this.list.options.borderradius + 'px';
			}
		},
	},
  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
#notice {
  background-color: #fff;
  div {
    // margin: 0px 20px;
    padding: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
	line-height: 24px;
  }
}
.bgcont{
	position: absolute;
	z-index: 999;
	display: flex;
	align-items: center;
}
.wrap{
	position: relative;
	display: flex;
	align-items: center;
	padding-left:20px;
	padding-right: 20px;
}
.bgimg{
	position: absolute;
	left: 0px;
	top: 0;
	width: 100%;
	height: 100%;
}
.tbimg{
	margin-left: 10px;
	margin-right: 10px;
	width: 15px;
	height: 15px;
}
</style>