<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="18"></leftnav>
			<div class="rightbox" >
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>授权码配置</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>授权码管理</span>
						<div class="" style="float:right">
							<el-button type="primary" size="small" @click="funAddorder">新增授权码</el-button>
						</div>
					</div>
					<div class="main">
						<!--列表-->
						<div style="color: #909399;">许可账号，也即接口调用许可账号，按照能力的不同，分为两种类型：基础账号与互通账号。</br>
							服务商为企业提供服务时，至少需要为企业成员开通基础账号，才能为该成员调用身份验证、应用发消息等接口。</br>
							服务商为企业提供微信客户联系相关的功能时，需要为企业成员开通互通账号。开通了互通账号，自动拥有了基础账号的能力。<br/>
							<span style="color: #ff0000;">若成员的互通账号失效，则该成员有新添加的客户时，不回调事件</span>
							</div>
						<el-table size="small" :data="orderData" highlight-current-row v-loading="loading"
							:border="false" element-loading-text="拼命加载中" style="width: 100%;">

							<el-table-column align="center" sortable prop="base_count" label="基础账号数" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="external_count" label="互通账号数" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="buy_months" label="购买的月数" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="account_duration" label="账号购买时长" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="cg_paydate" label="采购付款时间" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="cg_createdate" label="采购下单时间" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="kf_paydate" label="客户付款时间" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="kf_createdate" label="客户下单时间" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="cg_payno" label="采购付款单号" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="cg_orderstate" label="采购购买状态" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="kf_payno" label="客户付款单号" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="pricein" label="采购成本" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="pricesale" label="客户购买价格" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="createDate" label="创建日期" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="memo" label="备注" width="180">
							</el-table-column>
							<el-table-column label="操作" min-width="200" fixed="right">
								<template slot-scope="scope">
									<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">详情</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
				<!--编辑界面-->
				<el-dialog :title="title" :visible.sync="editFormVisible" :width="showmenu == true ? '30%' : '90%'" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="购买的月数" prop="buy_months">
							<el-select v-model="editForm.buy_months" placeholder="请选择" size="small"
								@change="funSelMonth">
								<el-option v-for="item in buy_monthslist" :key="item.id" :label="item.mc"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="总费用" prop="base_count">
							{{numtotal}}元
						</el-form-item>
						<el-form-item label="互通账号数" prop="external_count">
							<el-input size="small" v-model="editForm.external_count" auto-complete="off"
								placeholder="请输入互通账号数"></el-input>
						</el-form-item>
						<el-form-item label="备注" prop="memo">
							<el-input size="small" v-model="editForm.memo" auto-complete="off"
								placeholder="请输入备注"></el-input>
						</el-form-item>
						<el-form-item label="说明" prop="memo">
							<span style="color: orange;">此处支付所花费用来自微生活钱包，请确认钱包余额充足，工作人员会在3-5个工作日之内处理</span>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="submitForm()">确定
						</el-button>
					</div>
				</el-dialog>
				<!--详情界面-->
				<el-dialog :title="title" top="5%" :visible.sync="InfoVisible" width="50%" @click='InfoVisible=false'>
					<el-form label-width="150px" ref="InfoeditForm" :model="InfoeditForm">
						<el-form-item label="基础账号数" prop="base_count">
							{{account.base_count}}
						</el-form-item>
						<el-form-item label="购买的月数" prop="buy_months">
							{{account.buy_months}}
						</el-form-item>
						<el-form-item label="互通账号数" prop="external_count">
							{{account.external_count}}
						</el-form-item>
						<el-form-item label="账号购买时长" prop="account_duration">
							{{account.account_duration}}
						</el-form-item>
						<el-form-item label="金额" prop="pricesale">
							{{InfoeditForm.pricesale}}
						</el-form-item>
						<el-form-item label="单号" prop="billno">
							{{InfoeditForm.billno}}
						</el-form-item>
						<el-form-item label="下单时间" prop="kf_createdate">
							{{InfoeditForm.kf_createdate}}
						</el-form-item>
						<el-form-item label="备注" prop="memo">
							{{InfoeditForm.memo}}
						</el-form-item>

					</el-form>
					<el-table size="small" :data="zhlist" highlight-current-row v-loading="loading" :border="false"
						element-loading-text="拼命加载中" height="300px" style="width: 100%;">
						<el-table-column align="center" prop="licenseNo" label="授权码" width="240">
						</el-table-column>
						<el-table-column align="center" prop="licenseType" label="授权类型" width="120">
							<template v-slot="scope">
								{{scope.row.licenseType == 1 ? '基础账号' : '微信互通'}}
							</template>
						</el-table-column>
						<el-table-column align="center" prop="licensestate" label="授权状态" width="120">
							<template v-slot="scope">
								{{scope.row.licensestate == 1 ? '已授权' : '未授权'}}
							</template>
						</el-table-column>
						<el-table-column align="center" prop="manMc" label="授权人" width="180">
						</el-table-column>
						<el-table-column align="center" prop="updateDate" label="更新时间" width="180">
						</el-table-column>
					</el-table>
				</el-dialog>
			</div>
		</div>

	</div>
</template>

<script>
	import licenseApi from "../../../../../../api/cus/license.js";
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				loading: false,
				editForm: {
					base_count: "",
					buy_months: "",
					external_count: "",
					memo: ""
				},
				account: {},
				InfoeditForm: {

				},
				sendVisible: false,
				InfoVisible: false,
				editFormVisible: false,
				orderData: [],
				buy_monthslist: [{
						id: 3,
						mc: "三个月"
					},
					{
						id: 6,
						mc: "六个月"
					},
					{
						id: 12,
						mc: "一年"
					},
					{
						id: 24,
						mc: "两年"
					},
				],
				rebotid: "",
				numtotal: 0,
				zhlist: [],
				showmenu:true
			}
		},
		mounted() {
			let screenWidth = window.innerWidth
			console.log(screenWidth,'this.screenWidth');
			if(screenWidth <= 750){
				this.showmenu = false
			}else{
				this.showmenu = true
			}
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist()
			},
			getlist() {
				this.loading = true
				licenseApi.list(this.mpid, res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.loading = false
						this.orderData = res.data
					}
				})
			},
			funSelMonth(e) {
				console.log(e, 'ff');
				switch (e) {
					case 3:
						this.numtotal = 100
						break;
					case 6:
						this.numtotal = 200
						break;
					case 12:
						this.numtotal = 300
						break;
					case 24:
						this.numtotal = 600
						break;
					default:
						break;
				}
			},
			handleEdit(index, row) {
				console.log(row, 'rows');
				this.title = "详情"
				this.InfoVisible = true
				let param = {
					mpid: this.mpid,
					billno: row.billno,
				}
				licenseApi.getdetail(param, res => {
					console.log(res, 'res.handleEdit');
					if (res.code == 200) {
						this.InfoeditForm = res.data
						this.zhlist = res.data.list
						this.account = res.data.account
					} else {
						this.$message({
							type: 'error',
							message: '失败!'
						})
					}
				})
			},
			funAddorder() {
				this.title = '新增'
				this.editForm.id = ''
				this.editForm.mpid = ''
				this.editForm.base_count = ''
				this.editForm.buy_months = ''
				this.numtotal = 0
				this.editFormVisible = true
			},
			submitForm() {
				let param = {
					mpid: this.mpid,
					buy_months: this.editForm.buy_months,
					count: this.editForm.external_count,
					memo: this.editForm.memo
				}
				console.log(param,'submitForm.param');
				licenseApi.buynew(param, res => {
					console.log(res, 'res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '添加成功!'
						})
						this.getlist()
					} else {
						this.$message({
							type: 'error',
							message: '添加失败!'
						})
					}
				})
			},
		}
	}
</script>

<style scoped>
	.box-card {
		width: 1030px;
	}

	.el-form-item {
		margin-bottom: 5px;
	}

	.page {

		background: #F5F7FA;

	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
		margin:0 auto;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);
		overflow: auto;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		.main .item .mc {
			margin-right: 10px;
			width: 120px;
			flex-shrink:0;
		}
		
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
			
		}
	}
</style>