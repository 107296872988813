<template>
	<div id="text">
		<el-tabs v-model="activeName" type="card">
			
			<p>标题</p>
			
			<div style="display: flex;align-items: center;margin-top: 10px;">
				<el-select style="" size="medium" v-model="list.options.size" filterable allow-create placeholder="请选择标题字体大小">
					<el-option v-for="item in msfontlist" :key="item.id" :label="item.mc" :value="item.id">
					</el-option>
				</el-select>
				<el-tooltip effect="dark" content="加粗" placement="top">
					<div class="bbox">
						<img class="blodimg" src="../../../../assets/image/icon_b0.png" v-if="list.options.bold == 1"  @click="funchu(2)"></img>
						<img class="blodimg" src="../../../../assets/image/icon_b1.png" v-else-if="list.options.bold == 2"  @click="funchu(1)"/></img>
					</div>
				</el-tooltip>
				<el-tooltip effect="dark" content="标题背景色" placement="top">
					<el-color-picker v-model="list.options.titlebgcolor" style="margin-right: 10px;" size="medium"></el-color-picker>
				</el-tooltip>
				<el-tooltip effect="dark" content="标题字体色" placement="top">
					<el-color-picker v-model="list.options.titlecolor" size="medium"></el-color-picker>
				</el-tooltip>
				<el-tooltip effect="dark" content="显示" placement="top">
					<div class="bbox">
						<img class="blodimg2" src="../../../../assets/image/icon_show1.png" v-if="list.options.titleshow && list.options.titleshow == true"  @click="funsel(false)"></img>
						<img class="blodimg2" src="../../../../assets/image/icon_show0.png" v-else  @click="funsel(true)"/></img>
					</div>
				</el-tooltip>
			</div>
			
			
			<div style="height: 1px"></div>
			<div class="details">
				<!-- 标题文字大小 -->
				<!-- <div class="list">
					<div class="title">
						<span>标题大小 </span>
						<span>{{
			      list.options.size == 1
			        ? "大(16号)"
			        : list.options.size == 2
			        ? "中(14号)"
			        : "小(12号)"
			    }}
						</span>
					</div>
					<div class="btn">
						<el-radio-group v-model="list.options.size" @change="size">
							<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
								<el-radio-button :label="1">
									<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
								</el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
								<el-radio-button :label="2">
									<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
								</el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
								<el-radio-button :label="3">
									<div style="font-size: 12px;width: 11px;height:16px;line-height: 16px;"> A</div>
								</el-radio-button>
							</el-tooltip>
						</el-radio-group>
					</div>
				</div> -->
				<!-- 是否显示按钮 -->
				<!-- <div class="list">
					<div class="title">
						<span>是否显示标题 </span>
						<span> {{ list.options.titleshow ? "显示" : "隐藏" }} </span>
					</div>
					<div class="btn">
						<el-radio-group v-model="list.options.titleshow">
							<el-tooltip class="item" effect="dark" content="显示" placement="bottom">
								<el-radio-button :label="true"><i class="el-icon-s-fold"></i></el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="隐藏" placement="bottom">
								<el-radio-button :label="false">
									<i class="el-icon-s-operation"></i>
								</el-radio-button>
							</el-tooltip>
						</el-radio-group>
					</div>
				</div> -->
				<!-- 是否文字加粗 -->
				<!-- <div class="list">
					<div class="title">
						<span>标题粗细 </span>
						<span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
					</div>
					<div class="btn">
						<el-radio-group v-model="list.options.bold">
							<el-tooltip class="item" effect="dark" content="常规体" placement="bottom">
								<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="加粗体" placement="bottom">
								<el-radio-button :label="2">
									<i class="el-icon-s-operation"></i>
								</el-radio-button>
							</el-tooltip>
						</el-radio-group>
					</div>
				</div> -->
				<!-- 标题背景颜色 -->
				<!-- <div class="list">
					<div class="title"><span>标题背景颜色</span></div>
					<div class="center">{{list.options.titlebgcolor||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('titlebgcolor')">重置</el-button><el-color-picker
							v-model="list.options.titlebgcolor" size="medium"></el-color-picker>
					</div>
				</div> -->
				<!-- 标题文字颜色 -->
				<!-- <div class="list">
					<div class="title"><span>标题颜色</span></div>
					<div class="center">{{list.options.titlecolor||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('titlecolor')">重置</el-button><el-color-picker
							v-model="list.options.titlecolor" size="medium"></el-color-picker>
					</div>
				</div> -->
				<!-- 背景颜色 -->
				<div class="list">
					<div class="title"><span>背景颜色</span></div>
					<div class="center">{{list.options.background||'默认' }}</div>
					<div class="btn">
						<el-button type="text" class="reset"
							@click="reset('background')">重置</el-button><el-color-picker
							v-model="list.options.background" size="medium"></el-color-picker>
					</div>
				</div>
				<!-- 背景图片 -->
				<div class="list">
					<div class="title"><span>背景图片</span></div>
					<div class="btn">
						<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button>
						<!-- <el-buttontype="primary" size="small" >上传</el-button> -->
						<div class="upbtn" @click="funupload('bgimg')">上传</div>
					</div>
				</div>
				<div class="list">
					<el-image :src="list.options.bgimg" style="width: 80px;height: 80px;"></el-image>
				</div>
				<div class="disps">
					<div class="title">
						<span>高度 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.height" show-input :max="3000" style="margin: 0 10px;">
						</el-slider>
					</div>
				</div>
				<div class="disps">
					<div class="title">
						<span>圆角 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.borderradius" show-input :max="3000" style="margin: 0 10px;">
						</el-slider>
					</div>
				</div>
				<div class="disps">
				  <div class="title">
				    <span>上内边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.margintop" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
				<div class="disps">
				  <div class="title">
				    <span>下内边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.marginbottom" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
				
				<!-- <div class="disps">
				  <div class="title">
				    <span>内上边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingtop" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
				<div class="disps">
				  <div class="title">
				    <span>内下边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingbottom" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div> -->
				<div class="disps">
				  <div class="title">
				    <span>外内页边距 </span>
				  </div>
				  <div class="btn2 full">
				    <el-slider v-model="list.options.paddingpage" show-input :max="200" style="margin: 0 10px;"> 
				    </el-slider>
				  </div>
				</div>
			</div>
		</el-tabs>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>

</template>

<script>
	import selImgs from "@/components/designer/selImgs"
	export default {
		components:{
			selImgs
		},
		name: "news_list_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
				msfontlist:[{
					mc:'12号(小)',
					id:3
				},
				{
					mc:'14号(中)',
					id:2
				},
				{
					mc:'16号(大)',
					id:1
				},
				],
				activeName: "first"
			};
		},

		created() {
			this.list = this.data;
			
		},

		methods: {
			//  显示的位置
			position(event) {
				// this.$set(this.list.options.position, position, event);
				this.list.options.position = event;
			},
			funsel(isshow){
				this.list.options.titleshow = isshow
			},
			reset(key) {
				this.list.options[key] = '';
			},
			// 字体大小
			funchu(bold){
				
				this.list.options.bold = bold
			},
			size() {},
			funupload() {
				
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				this.list.options.bgimg = imgitem.url;
				
			},
		},
	};
</script>

<style lang="less" scoped>
	#text {
		padding: 20px;

		p {
			padding: 10px 0;
		}
	}
	.rowbox{
		display: flex;
		flex-direction: row;
		margin-top: 4px;
	}
	 .blodimg{
		width: 20px;
		height: 20px;
	}
	.blodimg2{
		width: 25px;
		height: 25px;
	}
	.bbox{
		    cursor: pointer;
		    margin-right: 5px;
		    width: 32px;
		    height: 30px;
		    border-radius: 4px;
		    background-color: #DEDFE1;
		    display: flex;
		    align-items: center;
		    justify-content: center;
			margin-left: 10px;
			flex-shrink: 0;
	}
	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #969799;
		}
	}
	.showbox{
		border: 1px solid #D7D7D7 ;
		width: 204px;
		height: 30px;
		border-radius: 4px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		margin-bottom: 10px;
	}
	 .active{
		width: 100rpx;
		height: 30px;
		line-height: 30px;
		text-align: center;
		border: 1px solid #3388FF;
	}
	 .normal{
		width: 100rpx;
		height: 30px;
		line-height: 30px;
		text-align: center;
	}
	.upbtn{
		color: #3388FF;
	}
	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
			color: #FF6060;
		}
	}
</style>