import httpapi from '../../utils/httpapi';
const signApi = {
	//查询二维码配置信息
	wxgzh_getsignature(param, funsucc) {
		httpapi.post('/openapi/weixin/api/jsapi/signature', param).then(res => {
			console.log(res, 'getsignature.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//获取企业微信桌台信息
	qywx_getdeskqrconfig(param, funsucc) {
		httpapi.post('/openapi/qyweixin/api/qrcode/desk', param).then(res => {
			console.log(res, 'qywx_getdeskqrconfig.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},

}
export default signApi