import httpapi from '../../utils/httpapi';
const orderApi = {
	//模版订单查询
	list(param,funsucc) {
		httpapi.get('customer/order/template/list?pageno='+param.pageno+'&pagesize='+param.pagesize, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	orderDetail: function(billno, funsucc) {
		httpapi.get('/customer/order/template/detail?billno='+billno,{}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//发起支付
	requestpay(param,funsucc) {
		httpapi.post('customer/order/template/pay/request', param).then(res => {
			console.log(res, 'request.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//支付结果查询
	payquery(param,funsucc) {
		httpapi.get('customer/order/template/pay/query', param).then(res => {
			console.log(res, 'query.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//订单取消
	paycancel(param,funsucc) {
		httpapi.post('customer/order/template/cancel', param).then(res => {
			console.log(res, 'cancel.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default orderApi