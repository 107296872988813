<template>
	<section>
		<!--工具属性设置-->
		<div class="tab-content">
			<h2>{{ type && list[type]["tit"] }}</h2>
			<!-- <div class="tab" v-if="type != 'info'">
        <span
          v-for="(val, key, index) in tabType"
          :key="index"
          @click="tab(key)"
          :class="{ active: val }"
          ><i class="el-icon-s-data"></i> {{ key }}</span
        >
      </div> -->
	  <i class="el-icon-star-on" @click="funlikeadd" v-if="userType === 'ISV'"></i>
		</div>

		<hb_image_docker ref="image_docker" v-if="type==='image_docker'" :data="data" @changeTab="tab">
		</hb_image_docker>
		<hb_image_desk ref="image_desk" v-else-if="type==='image_desk'" :data="data" @changeTab="tab"></hb_image_desk>
		<hb_shop_info_nav ref="shop_info_nav" v-else-if="type==='shop_info_nav'" :data="data" @changeTab="tab">
		</hb_shop_info_nav>
		<component v-else :is="type && list[type]['com']" :data="data" @changeTab="tab"></component>
		
		<el-dialog title="收藏" :visible.sync="collectshow" @close="collectshow=false" width="30%">
			<el-form label-width="100px">
				<el-form-item label="组件名称">
					<el-input v-model="collectInfo.name" size="small" style="width: 300px;"></el-input>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="hadleSubmit" size="small" style="float: right;">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		
		<div style="width:100%;border:1px solod #ccc;background:#fff;color:#00007f; margin-top:50px;" v-show="showdata">{{data}}</div>
		<div style="position: absolute;right:5px;bottom:20px;" @click.stop="showdata = !showdata">-</div>
		
	</section>
</template>

<script>
	import hb_pageinfo from "@/components/applet/toolbox/pageinfo/pageinfo_edit";
	import hb_text_title from "@/components/applet/toolbox/base/text/text_title_edit";
	import hb_text_notice from "@/components/applet/toolbox/base/text/text_notice_edit";

	import hb_image_row from "@/components/applet/toolbox/base/img/image_row_edit";
	import hb_image_col from "@/components/applet/toolbox/base/img/image_col_edit";
	import hb_menu_img_title from "@/components/applet/toolbox/base/img/menu_img_title_edit";
	import hb_image_swiper from "@/components/applet/toolbox/base/img/image_swiper_edit";
	import hb_image_magic from "@/components/applet/toolbox/base/img/image_magic_edit";
	import hb_image_spot from "@/components/applet/toolbox/base/img/image_spot_edit";
	import hb_image_desk from "@/components/applet/toolbox/base/img/image_desk_edit";

	import hb_image_docker from "@/components/applet/toolbox/base/img/image_docker_edit";

	import hb_goods_list_sp from "@/components/applet/toolbox/goods/goods_list_sp_edit";

	import hfoot_nav_cube from "@/components/applet/toolbox/nav/foot_nav_cube_edit";
	import hfoot_nav_circle from "@/components/applet/toolbox/nav/foot_nav_circle_edit";


	import hb_line_place from "@/components/applet/toolbox/base/line/line_place_edit";

	import hb_account_login from "@/components/applet/toolbox/club/login/account_login_edit";

	import hb_shop_info_nav from "@/components/applet/toolbox/shop/shop_info_nav_edit";

	import hb_club_newadd_edit from "@/components/applet/toolbox/club/account/club_newadd_edit";

	import hb_club_asset_edit from "@/components/applet/toolbox/club/account/club_asset_edit";

	import hb_coupon_list_edit from "@/components/applet/toolbox/coupon/coupon_list_edit";

	import hb_news_list_edit from "@/components/applet/toolbox/shop/news_list_edit";

	import hb_image_share_edit from "@/components/applet/toolbox/base/img/image_share_edit";

	import hb_image_join_edit from "@/components/applet/toolbox/base/img/image_join_edit";

	import hb_image_qunqr_edit from "@/components/applet/toolbox/base/img/image_qunqr_edit";
	
	import collectApi from '@/api/isv/collect.js'
	export default {
		name: "EditForm",
		components: {
			hb_pageinfo,
			hb_text_title,
			hb_account_login,
			hb_line_place,
			hb_text_notice,
			hb_image_row,
			hb_image_col,
			hb_menu_img_title,
			hb_image_swiper,
			hb_image_magic,
			hb_image_spot,
			hb_image_desk,
			hb_image_docker,
			hfoot_nav_cube,
			hfoot_nav_circle,
			hb_goods_list_sp,
			hb_shop_info_nav,
			hb_club_newadd_edit,
			hb_club_asset_edit,
			hb_coupon_list_edit,
			hb_news_list_edit,
			hb_image_share_edit,
			hb_image_join_edit,
			hb_image_qunqr_edit
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
			userType: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				type: "",
				collectshow:false,
				collectInfo:{
					name:''
				},
				showdata:false,
				
				list: {
					info: {
						tit: "页面信息",
						com: hb_pageinfo,
					},
					pageinfo: {
						tit: "页面信息",
						com: hb_pageinfo,
					},
					text_title: {
						tit: "标题",
						com: hb_text_title,
					},
					account_login: {
						tit: "帐户登录",
						com: hb_account_login,
					},
					image_row: {
						tit: "图片多行",
						com: hb_image_row,
					},
					image_col: {
						tit: "图片多列",
						com: hb_image_col,
					},
					menu_img_title: {
						tit: "图文导航",
						com: hb_menu_img_title,
					},
					image_swiper: {
						tit: "轮播图",
						com: hb_image_swiper,
					},
					image_spot: {
						tit: "热区切图",
						com: hb_image_spot,
					},
					image_desk: {
						tit: "台位图",
						com: hb_image_desk,
					},
					image_docker: {
						tit: "自由容器",
						com: hb_image_docker,
					},

					foot_nav_cube: {
						tit: "底部导航栏",
						com: hfoot_nav_cube,
					},
					goods_list_sp: {
						tit: "指定商品",
						com: hb_goods_list_sp,
					},
					shop_info_nav: {
						tit: "店铺信息",
						com: hb_shop_info_nav,
					},
					club_newadd: {
						tit: "办理会员",
						com: hb_club_newadd_edit,
					},
					club_asset: {
						tit: "会员资产",
						com: hb_club_asset_edit,
					},
					coupon_list: {
						tit: "优惠券",
						com: hb_coupon_list_edit,
					},
					news_list: {
						tit: "门店动态",
						com: hb_news_list_edit,
					},
					image_magic: {
						tit: "魔方图",
						com: hb_image_magic,
					},
					line_place: {
						tit: "辅助分割线",
						com: hb_line_place,
					},
					text_notice: {
						tit: "公告",
						com: hb_text_notice,
					},
					image_share: {
						tit: "一键分享",
						com: hb_image_share_edit,
					},
					image_join: {
						tit: "快速吸粉",
						com: hb_image_join_edit,
					},
					image_qunqr: {
						tit: "社群福利",
						com: hb_image_qunqr_edit,
					},
				},
				tabType: {
					1: true,
					2: false,
					3: false,
				},
			};
		},
		mounted() {
			this.type = this.data.type;
			if (this.data.tabType) {
				this.tab(this.data.tabType);
			}
		},
		methods: {
			tab(key) {
				for (let i in this.tabType) {
					if (key == i) {
						this.tabType[key] = true;
						this.$set(this.data, "tabType", key);
					} else {
						this.tabType[i] = false;
					}
				}
			},
			selectItemHandler(param) {
				console.log("proety...", param, this.type);
				if (param && param.type) {
					switch (param.type) {
						case 'image_docker':
							console.log('docket:', this.$refs.image_docker)
							if (this.$refs.image_docker != undefined) {
								this.$refs.image_docker.selectItemHandler(param.data);
							}
							break;
						case 'image_desk':
							console.log('desk:', this.$refs.image_desk)
							if (this.$refs.image_desk != undefined) {
								this.$refs.image_desk.selectItemHandler(param.data);
							}
							break;
						case 'shop_info_nav':
							console.log('shop_info_nav:', this.$refs.shop_info_nav)
							if (this.$refs.shop_info_nav != undefined) {
								this.$refs.shop_info_nav.selectItemHandler(param.data);
							}
							break;
					}
				}
			},
			funlikeadd(){
				this.collectInfo.name = ''
				this.collectshow = true
			},
			hadleSubmit(){
				let param = {
					content:JSON.stringify(this.data),
					title:this.collectInfo.name,
					type:this.data.type
				}
				console.log(param,'param');
				collectApi.add(param,res=>{
					console.log(res,'res');
					if(res.code == 200){
						this.collectshow = false
						this.$message({
							type:"success",
							message:res.msg
						})
					}
				})
			}
		},
	};
</script>

<style scoped lang="less">
	section {
		height: 100%;
		overflow: hidden;
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;
	}

	.tab-content {
		margin-top: 20px;
		margin-bottom: 10px;
		padding:0 0 15px 0px;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #f0f0f0;
		.el-icon-star-on{
			cursor: pointer;
			// position: absolute;
			// right: 0;
			font-size: 20px;
		}
		h2 {
			font-size: 16px;
			color: #333;
		}

		.tab {
			display: flex;
			justify-content: space-around;
			border: 1px solid #ddd;
			border-radius: 6px;

			span {
				width: 33.33%;
				text-align: center;
				font-size: 14px;
				color: #666;
				display: block;
				height: 36px;
				line-height: 36px;
				cursor: pointer;

				&.active {
					color: #fff;
					background: #409eff;
					border-radius: 2px;
				}

				&:nth-of-type(2) {
					border-left: 1px solid #ddd;
					border-right: 1px solid #ddd;
				}
			}
		}
	}
	section /deep/ .el-dialog__body{
		padding: 15px !important;
	}
</style>