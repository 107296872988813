<template>
	<div>
		<!--选择图片-->
		<el-dialog title="我的图片" :visible.sync="ImgVisible" width="1200px" :before-close="handleClose" 
			:append-to-body="true">
			<div class="head">
				搜索：<el-input type="text" size="mini" v-model="imgformInline.mc" style="width: 300px;"><el-button slot="append" icon="el-icon-search" @click="funsearch"></el-button>
</el-input>
			</div>
			<div class="main">
				<div class="main-left">
					<div class="item" :class="currIndex == index ? 'active' : ''" v-for="(item,index) in clslist" :key="index" @click="funclsClick(item,index)">
						<span>{{item.mc}}</span>
						<span v-if="currIndex == index && index != 0" style="float: right;font-size: 12px;" @click="funedit(item)">编辑</span>
					</div>
				</div>
				<div class="main-right">
					<div class="imglist">
						<div class="item" v-for="(item,index) in imglist" :key="index">
							<div style="display: flex;flex-direction: column;justify-content: center;">
								<el-image :src="item.linkurl" :fit="fit" style="width: 100px;height: 100px;"/>
								<div class="bg"><span @click="funselitem(item)" style="margin-right: 10px;">选择</span><span
										@click="fundelimg(item.resid,index)">删除</span></div>
								<div style="height: 30px;">
									{{item.resmc}}
								</div>
							</div>
							
						</div>
					</div>
					<!-- 分页组件 -->
					<Pagination v-bind:child-msg="imgpageparm" @callFather="imgcallFather" style="position: absolute;right: 0;bottom: 0;"></Pagination>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<div class="f_left">
					<el-button size="small" @click="funAddGroup">添加分组</el-button>
					<el-button size="small" @click="funfile">上传图片</el-button>
					<el-button size="small" @click="funlink">外部链接</el-button>
				</div>
				<el-button size="small" @click='handleClose'>关闭</el-button>
			</div>
		</el-dialog>
		<!--新增分组-->
		<el-dialog :title="clstitle" :visible.sync="groupVisible" width="30%" :before-close="groupClose"
			:append-to-body="true">
			<div class="" style="display: flex;align-items: center;">
				分组名称：<el-input type="text" size="small" v-model="groupInfo.mc" style="width: 300px;"></el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='groupClose'>取消</el-button>
				<el-button size="small" type="primary" class="title" @click="funGroupOk">确定</el-button>
			</div>
		</el-dialog>
		<!--外部链接-->
		<el-dialog :title="title" :visible.sync="linkVisible" width="30%" 
			:append-to-body="true">
			<div class="" style="display: flex;align-items: center;">
				外部链接：<el-input type="text" size="small" v-model="linkurl" style="width: 300px;"></el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='linkVisible = false'>取消</el-button>
				<el-button size="small" type="primary" class="title" @click="funlinkOK">确定</el-button>
			</div>
		</el-dialog>
		<cusuploadImg ref="cusuploadImg" @uploadres="uploadres"></cusuploadImg>
		<isvuploadImg ref="isvuploadImg" @uploadres="uploadres"></isvuploadImg>
	</div>
</template>

<script>
	import isvimgApi from '../../api/isv/img'
	import cusimgApi from '../../api/common/img'
	import cusuploadImg from '@/components/customer/uploadImg.vue'
	import isvuploadImg from '@/components/designer/uploadImg.vue'
	import Pagination from '@/components/Pagination.vue'
	import {getloginuser} from '../../utils/util.js'
	export default {
		components: {
			cusuploadImg,
			isvuploadImg,
			Pagination
		},
		data() {
			return {
				usertype:'',
				loading: false,
				imglist: [],
				imgpageparm: {
					currentPage: 1,
					pageSize: 30,
					total: 20
				},
				// 请求数据参数
				imgformInline: {
					page: 1,
					limit: 30,
					mc: '',
				},
				clslist: [],
				title: "",
				clstitle:'',
				groupInfo:{
					id:"",
					mc: "",
				},
				fit:"cover",
				currIndex:0,
				ImgVisible: false,
				groupVisible: false,
				linkurl:"",
				linkVisible:false
			}
		},
		methods: {
			getcls() {
				if(this.usertype == 'MCH'){
					cusimgApi.getcls(res => {
						console.log(res, 'res');
						if (res.code == 200) {
							this.clslist = res.data
							this.clslist.unshift({
								id:'',
								mc:'全部'
							})
							this.currIndex =  0
							// this.groupInfo.id = res.data[0].id
							// this.groupInfo.mc = res.data[0].mc
							
							this.getimglist(this.imgformInline)
						}
					})
				}else{
					isvimgApi.getcls(res => {
						console.log(res, 'res');
						if (res.code == 200) {
							this.clslist = res.data
							this.clslist.unshift({
								id:'',
								mc:'全部'
							})
							this.currIndex =  0
							// this.groupInfo.id = res.data[0].id
							// this.groupInfo.mc = res.data[0].mc
							
							this.getimglist(this.imgformInline)
						}
					})
				}
				
			},
			funShow() {
				// let usertype = sessionStorage.getItem("usertype");
				let u = getloginuser()
				if(u != null){
					this.usertype = u.usertype
				}
				this.ImgVisible = true
				if(this.clslist && this.clslist.length >0){
					this.getimglist(this.imgformInline)
				}else{
					this.getcls()
				}
				
				
			},
			handleClose() {
				this.ImgVisible = false
			},
			groupClose() {
				this.groupVisible = false
			},
			funAddGroup() {
				this.clstitle = '新增分类'
				this.groupVisible = true
				this.groupInfo.id = ''
				this.groupInfo.mc = ''
			},
			funfile(){
				
				console.log(this.groupInfo,'this.groupInfo');
				if(this.groupInfo.id && this.groupInfo.id.length >0){
					if(this.usertype == 'MCH'){
						this.$refs['cusuploadImg'].funShow(this.groupInfo)
					}else{
						this.$refs['isvuploadImg'].funShow(this.groupInfo)
					}
					
				}else{
					this.$message({
						type: 'error',
						message: "请选择分类"
					})
				}
				
			},
			funlink(){
				this.linkVisible = true
			},
			funlinkOK(){
				this.linkVisible = false
				this.ImgVisible = false
				this.$emit('selImgsresult', {
					name:'',
					url:this.linkurl
				})
			},
			funclsClick(item,index){
				this.groupInfo = item
				this.currIndex = index
				this.imgformInline.page = 1
				this.getimglist(this.imgformInline)
			},
			funGroupOk() {
				if(this.clstitle == '新增分类'){
					if(this.usertype == 'MCH'){
						let param = {
							mc: this.groupInfo.mc
						}
						console.log(param, 'addcls.paramde');
						cusimgApi.addcls(param, res => {
							console.log(res, 'res');
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								// this.clslist.push(res.data)
								this.getcls()
								this.groupVisible = false
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						})
					}else{
						let param = {
							mc: this.groupInfo.mc
						}
						console.log(param, 'addcls.paramde');
						isvimgApi.addcls(param, res => {
							console.log(res, 'res');
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: res.msg
								})
								// this.clslist.push(res.data)
								this.getcls()
								this.groupVisible = false
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						})
					}
				}else{
					let param = {
						id:this.groupInfo.id,
						mc: this.groupInfo.mc
					}
					console.log(param, 'editcls.paramde');
					isvimgApi.editcls(param, res => {
						console.log(res, 'res');
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							// this.clslist.push(res.data)
							this.getcls()
							this.groupVisible = false
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				
				
			},
			funedit(item){
				this.groupVisible = true
				this.clstitle = '编辑分类'
				this.groupInfo.id = item.id
				this.groupInfo.mc = item.mc
			},
			uploadres(param){
				this.getimglist(this.imgformInline)
			},
			funsearch(){
				this.imgformInline.page = 1
				this.getimglist(this.imgformInline)
			},
			getimglist(page) {
				if(this.usertype == 'MCH'){
					this.loading = true
					// 请求数据开始
					let param = {
						pageno: page.page,
						pagesize: page.limit,
						mc: page.mc,
						clsid:this.groupInfo.id
					}
					console.log(this.groupInfo,'this.groupInfo');
					console.log(param,'getimglist.param');
					cusimgApi.getimglist(param, res => {
						console.log(res, 'user.res');
						if (res.code == 200) {
							this.loading = false
							this.imglist = res.data.list
							this.imgpageparm.currentPage = res.data.pageno
							this.imgpageparm.pageSize = res.data.pagesize
							this.imgpageparm.total = res.data.total
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}else{
					this.loading = true
					// 请求数据开始
					let param = {
						pageno: page.page,
						pagesize: page.limit,
						mc: page.mc,
						clsid:this.groupInfo.id
					}
					console.log(this.groupInfo,'this.groupInfo');
					console.log(param,'getimglist.param');
					isvimgApi.getimglist(param, res => {
						console.log(res, 'user.res');
						if (res.code == 200) {
							this.loading = false
							this.imglist = res.data.list
							this.imgpageparm.currentPage = res.data.pageno
							this.imgpageparm.pageSize = res.data.pagesize
							this.imgpageparm.total = res.data.total
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
				}
				
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.imgformInline.page = parm.currentPage
				this.imgformInline.limit = parm.pageSize
				this.getimglist(this.imgformInline)
			},
			funselitem(item) {
				this.ImgVisible = false
				this.$emit('selisvImgsresult', {
					name:item.resmc,
					url:item.linkurl
				})
			},
			
			netupload() {

			},
			fundelimg(id, index) {
				console.log(id,'id');
				this.$confirm('此操作将永久删除图片, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						id: id,
					}
					imgApi.delImg(param, res => {
						console.log(res,'del.res')
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.imglist.splice(index, 1)
						}
					})
				}).catch(() => {
				console.log(11,'1111')
				});
			},
			funOk() {

			}
		}
	}
</script>

<style lang="less" scoped>
	.main {
		display: flex;
		height:calc(100vh - 400px);
		.main-left {
			width: 100px;
			flex-shrink: 0;
			border-right: 1px solid #f0f0f0;
			height:calc(100vh - 400px);
			overflow: auto;
			.item{
				white-space: nowrap;
				cursor: pointer;
				padding: 0 10px;
				height: 30px;
				line-height: 30px;
				margin: 5px 0;
			}
		}

		.main-right {
			flex-grow: 1;
			position: relative;
		}
	}

	.f_left {
		position: absolute;
		left: 20px;
		bottom: 20px;
		display: flex;
	}

	.f_left .el-button {
		margin: 0 10px;
	}

	.imglist {
		display: flex;
		flex-wrap: wrap;
		height: calc(100vh - 450px);
		overflow-y: auto;
	}

	.imglist .item {
		margin: 5px;
		width: 120px;
		height: 120px;
		text-align: center;
		border: 1px solid #ccc;
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
	}
.imglist .item img{
	width: 100%;
}
	.imglist .item .bg {
		position: absolute;
		left: 0;
		top: 0;
		color: #fff;
		width: 100%;
		height: 100%;
		cursor: pointer;
		padding-top: 50px;
		background: rgba(0, 0, 0, 0.5);
		display: none;
	}

	.imglist .item:hover .bg {
		display: block;
	}
	.active{
		background-color: #F2F3F5;
	}
	/deep/.el-dialog__body{
		padding-top: 10px;
		padding-bottom:5px;
	}
</style>