<template>
	<div class="page">
		<div class="top">
			<div class="background"></div>
		</div>
		<div class="user-card">
			<div class="card">
				<div class="top">
					<div class="userimg">
						<!-- <open-data type="userAvatarUrl"></open-data> -->
						<img  src="../../../assets/design/2.jpeg"/>
					</div>
				</div>
				<div class="bottom">
					<div class="left">
						<div class="user-span">
							{{userInfo.nickname}}
							<!-- <open-data type="userNickName"></open-data> -->
						</div>
						<div class="user-phone"> {{userInfo.mchno}} </div>
					</div>
					<div class="right flex-center">
						>
					</div>
				</div>
			</div>
		</div>
		<div class="list-card">
			<div class="card">
				<div class="item item-bottom-solid">
					<div class="left flex-center">
						<img src="../../../assets/app/menu1.png"></img>
					</div>
					<div class="center">
						<span>修改密码</span>
					</div>
					<div class="right flex-center">
						>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="item item-bottom-solid">
					<div class="left flex-center">
						<img src="../../../assets/app/menu2.png"></img>
					</div>
					<div class="center">
						<span>模版市场</span>
					</div>
					<div class="right flex-center">
						>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="item">
					<div class="left flex-center">
						<img src="../../../assets/app/menu3.png"></img>
					</div>
					<div class="center">
						<span>设置</span>
					</div>
					<div class="right flex-center">
						>
					</div>
				</div>
			</div>
		</div>
		<div class="quit flex-center">
			<div class="btn flex-center" @click="exit">
				退出登录
			</div>
		</div>
		<hansTabber :list="list" :currentIndex="2" style="position:fixed;bottom:0;width:100%;left:0;right:0;"
			@tabChange="tabChange">
		</hansTabber>
	</div>
</template>
<style lang="scss" scoped>
	.top {
		height: 125px;
		position: relative;

		.background {
			background-color: #5199ff;
			border-bottom-left-radius: 22px;
			border-bottom-right-radius: 22px;
			position: absolute;
			height: 90px;
			width: 100%;
		}
	}

	.icon {
		color: #96a1ae;
		font-size: 20px;
	}

	.user-card {
		height: 85px;
		padding: 0 15px;

		.card {
			position: relative;
			bottom: 62px;
			height: 125px;
			background-color: white;
			border-radius: 5px;

			.top {
				height: 30%;
				position: relative;

				.userimg {
					position: absolute;
					bottom: 24%;
					left: 10%;
					width: 75px;
					height: 75px;
					overflow: hidden;
					border-radius: 50%;
					border: 2px solid white;
					img{
						width: 100%;
						height: 100%;
					}
				}
			}

			.bottom {
				display: flex;
				height: 70%;

				.left {
					width: 80%;
					height: 100%;
					position: relative;

					.user-span {
						width: 100%;
						font-size: 1.6em;
						padding-left: 40px;
						height: 50%;
					}

					.user-phone {
						color: #96a1ae;
						padding-left: 40px;
						height: 50%;
						width: 100%;
						font-size: 0.9em;
					}
				}

				.right {
					width: 20%;
					height: 50%;
				}
			}
		}
	}

	.list-card {
		padding: 0 15px;

		.card {
			border-radius: 5px;
			position: relative;
			background-color: white;
			border-radius: 5px;
			padding: 5px 30px;

			.item {
				display: flex;
				height: 60px;

				.left {
					width: 15%;

					img {
						width: 35px;
						height: 35px;
					}
				}

				.center {
					width: 65%;
					display: flex;
					justify-content: start;
					align-items: center;
					font-size: 1.1em;
				}

				.right {
					width: 20%;
					justify-content: flex-end;
				}
			}
		}
	}

	.item-bottom-solid {
		border-bottom: 1px solid #d4d6da;
	}

	.quit {
		height: 50px;
		margin-top: 50px;

		.btn {
			background-color: #4f99ff;
			border-radius: 30px;
			width: 80%;
			color: white;
			font-size: 1.2em;
			height: 100%;
		}
	}

	.flex-center {
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>

<script>
	//import {  } from "@/common/api/{$}.js";
	import hansTabber from '@/components/hans-tabbar/hans-tabbar.vue'
	import {getloginuser} from '../../../utils/util.js'
	export default {
		components: {
			hansTabber
		},
		data() {
			return {
				list: [{
						"text": "首页",
						"iconPath": require('@/assets/app/home.png'),
						"selectedIconPath": require('@/assets/app/home_active.png')
					},
					{
						"text": "模版市场",
						"iconPath": require('@/assets/app/work.png'),
						"selectedIconPath": require('@/assets/app/work_active.png')
					},
					{
						"text": "我的",
						"iconPath": require('@/assets/app/my.png'),
						"selectedIconPath": require('@/assets/app/my_active.png')
					}
				],
				userInfo: {}
			};
		},
		//监听页面初始化，其参数同 onLoad 参数，为上个页面传递的数据，参数类型为 Object（用于页面传参），触发时机早于 onLoad
		onInit() {},
		//监听页面加载，其参数为上个页面传递的数据，参数类型为 Object（用于页面传参）
		mounted() {
			this.init()
		},
		//监听页面初次渲染完成。注意如果渲染速度快，会在页面进入动画完成前触发
		onReady() {},
		//监听页面显示。页面每次出现在屏幕上都触发，包括从下级页面点返回露出当前页面
		beforeDestroy() {},
		//页面滚动到底部的事件（不是scroll-div滚到底），常用于下拉下一页数据。
		onReachBottom() {},
		onShareAppMessage(res) {},
		created() {},
		methods: {
			init() {
				let u = getloginuser()
				// let u = sessionStorage.getItem('customeruser')
				if (u != null) {
					this.userInfo = u;
				} else {
					this.$router.push("/app/qiyewx/login");
				}
			},
			// 退出登录
			exit() {
				// this.$store.commit('customerloginout', "")
				this.$router.push({
					path: '/app/qiyewx/login'
				})
				// delCookie("login_uid");
				// delCookie("login_uname");
				sessionStorage.clear()
				this.$message({
					type: 'success',
					message: '已退出登录!'
				})
			},
			tabChange(index) {
				console.log(index, 'indexmy');
				// if(index == 0){
				// 	this.$router.push({
				// 		path:'/app/qiyewx/index'
				// 	})
				// }
			}
		},
	};
</script>