var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"menu_img_title"},[_c('div',{staticClass:"imgcont",style:({
        'margin-left': _vm.marginpage,
        'margin-right': _vm.marginpage,
		'margin-top':_vm.margintop,
		'margin-bottom':_vm.marginbottom,
		'height' : _vm.height,
    'background':_vm.bgcolor,
      })},[(
          _vm.list.options && _vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
        )?_c('div',[_c('img',{staticClass:"bgimg",style:({
			  'height' : _vm.height,
			  
		  }),attrs:{"src":_vm.list.options.bgimg && _vm.list.options.bgimg.length > 0
              ? _vm.list.options.bgimg
              : ''}})]):_vm._e(),_vm._l((_vm.list.data),function(pitem,pindex){return _c('div',{key:pindex,staticClass:"pitem",style:({
						'left':(pitem.left) + 'px',
						'top':(pitem.top) + 'px',
						'width':(pitem.width) + 'px',
						'height':(pitem.height) + 'px'
					})},_vm._l((pitem.child),function(item,index){return _c('dragBox',{key:item.cid,attrs:{"dragkey":pindex + '',"data":item,"index":index},on:{"deleteHandle":_vm.deleteHandle,"selectHandler":_vm.selectHandler}},[(item.type === 'button')?_c('div',{staticClass:"item item-button",style:({
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              background: item.bgcolor,
              'line-height':(item.height-2) + 'px',
              'border-radius':item.borderradius + 'px'
            })},[(item.title && item.title.length > 0)?_c('span',{style:({
               color: item.titlecolor,
               'text-align':item.position == 1 ? 'left':'center',
               'font-weight':item.bold == 2 ? 700:300,
               'font-size':_vm.getsize(item.size)
            })},[_vm._v(_vm._s(item.title))]):_vm._e()]):(item.type === 'text')?_c('div',{staticClass:"item item-text",style:({
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              color: item.titlecolor,
              'text-align':item.position == 1 ? 'left':'center',
              'font-weight':item.bold == 2 ? 700:300,
              'line-height':(item.height-2) + 'px',
              'font-size':_vm.getsize(item.size)
            })},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e(),(item.type == 'image' && item.url.length > 0)?_c('img',{staticClass:"item",style:({
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              'border-radius':item.borderradius + 'px'
            }),attrs:{"src":item.url}}):_vm._e()])}),1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data.length == 0),expression:"data.data.length == 0"}],staticClass:"empty"},[_c('el-empty',{attrs:{"description":"此区域放置图片"}})],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }