var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ borderBottom: _vm.border,'padding-top':_vm.margintop,
          'padding-bottom':_vm.marginbottom,'padding-left':  _vm.list.options.paddingpage + 'px',
        'padding-right':  _vm.list.options.paddingpage + 'px',background: _vm.list.options.bgcolor
          }),attrs:{"id":"wenben"}},[_c('div',{staticClass:"box",style:({background: _vm.list.options.background,'padding-top':_vm.paddingtop,
          'padding-bottom':_vm.paddingbottom,'border-radius':_vm.list.options.borderradius + 'px','height':_vm.list.options.height + 'px'})},[(_vm.list.options.titleshow)?_c('div',{staticClass:"cont"},[_c('div',{staticClass:"bottom",style:({
				  fontSize: _vm.size,
				  textAlign: _vm.align,
				  fontWeight: _vm.bold,
				  color: _vm.list.options.titlecolor,
				  'background-color':_vm.list.options.titlebgcolor
				})},[_vm._v(" "+_vm._s(_vm.list.options.name)+" ")])]):_vm._e(),(_vm.list.options.bgimg.length > 0)?_c('div',{staticClass:"bgimg"},[_c('img',{style:({'border-radius':_vm.list.options.borderradius + 'px'}),attrs:{"src":_vm.list.options.bgimg}})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }