<template>
	<div class="content">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="15"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<h3>项目成员</h3>
				<div class="row">
					<div class="memo" style="flex-grow: 1;">管理员可添加小程序成员、配置成员权限，当前已添加6个成员，还可以添加84个。</div>
					<el-button @click="funrefuse">刷新体验者</el-button>
					<el-button type="primary" @click="funshowadd">添加</el-button>
				</div>
				<div class="title">
					<div class="titem">全部成员</div>
					<div class="titem">用户名</div>
					<div class="titem" style="text-align: right;">操作</div>
				</div>
				<div class="userlist">
					<div class="uitem" v-for="(item,index) in list" :key="index">
						<div class="row w30">
							<img src="../../../../../../assets/image/icon_tester.png" class="userlogo" alt="">
							<div class="usrid">{{item.chatid}}</div>
						</div>
						<div class="usrname w30">李奇龙</div>
						<div class="btnbox row w30" style="justify-content: flex-end;">
							<div class="btn1" @click="funedit">编辑备注</div>
							<div class="btn2">删除</div>
						</div>
					</div>

				</div>
				<el-dialog title="添加体验成员" :visible.sync="mcShow" width="30%" @click='mcShow = false'>
					<el-input size="large" v-model="wxid" auto-complete="off" placeholder="输入需要添加的微信号"></el-input>
					<el-input size="large" v-model="memo" style="margin-top: 20px;" auto-complete="off"
						placeholder="请输入备注"></el-input>
					<div class="dialog-footer">
						<el-button size="medium" class="diabtn" @click='mcShow = false'>取消</el-button>
						<el-button size="medium" class="diabtn" type="primary" @click="testerbind">保存</el-button>
					</div>
				</el-dialog>
				<el-dialog title="编辑备注" :visible.sync="memoShow" width="30%" @click='memoShow = false'>
					<el-input size="large" v-model="edit.memo" auto-complete="off" placeholder="输入备注"></el-input>
					<div class="dialog-footer">
						<el-button size="medium" class="diabtn" @click='memoShow = false'>取消</el-button>
						<el-button size="medium" class="diabtn" type="primary">保存</el-button>
					</div>
				</el-dialog>
			</div>
		</div>

	</div>
</template>

<script>
	import accountApi from '../../../../../../api/cus/account.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: '',
				list: [],
				wxid: '',
				mcShow: false,
				memoShow: false,
				memo: '',
				edit: {
					memo: '',
					sysid: ''
				}
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.mpid = mpid
			} else {
				this.$router.push("/");
			}
			this.getlist()
		},
		methods: {
			getlist() {
				accountApi.getteseterlist(this.mpid, res => {
					console.log(res, 'res.getlist');
					if (res.code == 200) {
						this.list = res.data

					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			testerbind() {
				let that = this
				let param = {
					wxid: that.wxid,
					mpid: that.mpid,
					memo: that.memo
				}
				accountApi.testerbind(param, res => {
					console.log(res, 'res.testerbind');
					if (res.code == 200) {
						if (res.data.errcode == 0) {
							that.$message({
								message: '绑定成功',
								type: 'success'
							})
							that.mcShow = false
							that.getlist()
						} else {
							that.$message({
								message: res.data.errmsg,
								type: 'error'
							})
						}

					} else {
						that.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			editmemo() {
				let that = this
				let param = {
					sysid: that.edit.sysid,
					mpid: that.mpid,
					memo: that.edit.memo
				}
				accountApi.updatamemo(param, res => {
					console.log(res, 'res.updatamemo');
					if (res.code == 200) {
						that.$message({
							message: '编辑成功',
							type: 'success'
						})
						that.memoShow = false
						that.getlist()
					} else {
						that.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			},
			funedit() {
				this.memoShow = true
			},
			funshowadd() {
				this.mcShow = true
			},
			funrefuse() {
				let that = this
				accountApi.flushfromwx(that.mpid, res => {
					console.log(res, 'res.funrefuse');
					if (res.code == 200) {
						that.$message({
							message: '刷新成功',
							type: 'success'
						})
						that.list = res.data
						that.getlist()

					} else {
						that.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.content {}

	.content .memo {
		color: #9A9A9A;
		font-size: 12px;
		margin: 10px 0;
	}

	.content .title {
		width: 100%;
		border-bottom: 1px solid #E3E4E5;
		padding-bottom: 3px;
		display: flex;
		margin-top: 30px;
	}

	.content .title .titem {
		width: 33.3%;
		text-align: left;
		line-height: 40px;
	}

	.content .userlist {
		overflow: auto;
		align-items: center;
		width: 100%;
	}

	.content .userlist .uitem {
		display: flex;
		justify-content: flex-start;
		margin-top: 25px;
		width: 100%;
	}

	.content .userlist .uitem .userlogo {
		width: 50px;
		height: 50px;
	}

	.content .userlist .uitem .btnbox {}

	.content .userlist .uitem .btnbox .btn1 {
		margin-right: 15px;
		color: #00aaff;
	}

	.content .userlist .uitem .btnbox .btn2 {

		color: #ff0000;
	}

	.content .userlist .uitem .usrname {
		line-height: 50px;
	}

	.dialog-footer {
		display: flex;
		margin-top: 50px;
		width: 100%;
		justify-content: center;
	}

	.dialog-footer .diabtn {
		margin: 0 20px;
	}

	.w30 {
		width: 33.3%;
		overflow: hidden;
	}

	.row {
		display: flex;
		align-items: center;
	}
	.page{
		background:#F5F7FA;
	}
	.rightbox{
		padding-top:20px;
		padding-bottom:20px;
		width: 1320px;
		overflow:auto;
	}
	.pagecontent{
		
		
		height: calc(100vh - 81px);
	}
</style>