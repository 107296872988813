<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="17"></leftnav>
			<div class="rightbox">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>机器人配置</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>机器人管理</span>
						<div class="" style="float:right">
							<el-button type="primary" size="small" @click="funAddchat">新增机器人</el-button>
						</div>
					</div>
					<div class="main">
						<!--列表-->
						<el-table size="small" :data="chatData" highlight-current-row v-loading="loading" :border="false"
							element-loading-text="拼命加载中" style="width: 100%;">
							<el-table-column align="center" sortable prop="name" label="机器人名称" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="state" label="状态" width="180">
								<template slot-scope="scope">
									{{scope.row.state == 1 ? '启用' : '停用'}}
								</template>
							</el-table-column>
							<el-table-column align="center" sortable prop="updatedate" label="日期" width="180">
							</el-table-column>
							<el-table-column label="操作" min-width="200">
								<template slot-scope="scope">
									<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
									<el-button size="mini" type="primary" v-if="scope.row.state == 1"
										@click="funchatzt(scope.$index, scope.row,'F')">停用</el-button>
									<el-button size="mini" type="primary" v-else
										@click="funchatzt(scope.$index, scope.row,'T')">启用</el-button>
										<el-button size="mini" type="info" 
											@click="funsengmsg(scope.$index, scope.row)">发送消息</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
				<!--编辑界面-->
				<el-dialog :title="title" :visible.sync="editFormVisible" :width="showmenu == true ? '30%' : '90%'" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="机器人名称" prop="name">
							<el-input size="small" v-model="editForm.name" auto-complete="off"
								placeholder="请输入机器人名称"></el-input>
						</el-form-item>
						<el-form-item label="机器人编号" prop="sn">
							<el-input size="small" v-model="editForm.sn" auto-complete="off"
								placeholder="请输入机器人编号"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="submitForm()">确定
						</el-button>
					</div>
				</el-dialog>
				<!--发送消息界面-->
				<el-dialog :title="title" :visible.sync="sendVisible" width="30%" @click='sendVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="消息" prop="msg">
							<el-input size="small" type="textarea" v-model="sendeditForm.msg" auto-complete="off"
								placeholder="请输入消息"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="sendSubmit()">确定
						</el-button>
					</div>
				</el-dialog>
			</div>
			</div>
		
	</div>
</template>

<script>
	import chatApi from "../../../../../../api/cus/chat.js";
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title:"",
				loading: false,
				editForm:{
					name:"",
					sn:""
				},
				sendeditForm:{
					msg:""
				},
				sendVisible:false,
				editFormVisible:false,
				chatData: [],
				rebotid:"",
				showmenu:true
			}
		},
		mounted() {
			let screenWidth = window.innerWidth
			console.log(screenWidth,'this.screenWidth');
			if(screenWidth <= 750){
				this.showmenu = false
			}else{
				this.showmenu = true
			}
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist()
			},
			getlist(){
				let param = {
					mpid: this.mpid
				}
				this.loading = true
				chatApi.list(param, res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.loading = false
						this.chatData = res.data
					}
				})
			},
			handleEdit(index,row) {
				console.log(row,'rows');
				this.title = "编辑"
				this.rebotid = row.id
				this.editForm = JSON.parse(JSON.stringify(row)) 
				this.editFormVisible = true
			},
			funAddchat() {
				this.title = '新增'
				this.editForm.id = ''
				this.editForm.mpid = ''
				this.editForm.name = ''
				this.editForm.sn = ''
				this.editFormVisible = true
			},
			submitForm(){
				if(this.title == "编辑"){
					let param = {
						rebotid: this.rebotid,
						name: this.editForm.name,
						sn: this.editForm.sn,
					}
					chatApi.edit(param, res => {
						console.log(res, 'res');
						this.editFormVisible = false
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '编辑成功!'
							})
							this.getlist()
						}else{
							this.$message({
								type: 'error',
								message: '编辑失败!'
							})
						}
					})
				}else{
					let param = {
						mpid: this.mpid,
						name: this.editForm.name,
						sn: this.editForm.sn,
					}
					chatApi.rebotadd(param, res => {
						console.log(res, 'res');
						this.editFormVisible = false
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: '添加成功!'
							})
							this.getlist()
						}else{
							this.$message({
								type: 'error',
								message: '添加失败!'
							})
						}
					})
				}
			},
			sendSubmit(){
				let param = {
					rebotid: this.rebotid,
					msg: this.sendeditForm.msg,
				}
				chatApi.sendmsg(param, res => {
					console.log(res, 'res');
					this.sendVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '发送成功!'+res.msg
						})
					}else{
						this.$message({
							type: 'error',
							message: '发送失败!'
						})
					}
				})
			},
			funchatzt(index,row,type) {
				let param = {
					rebotid: row.id,
					zt: type,
				}
				chatApi.usestate(param, res => {
					console.log(res, 'res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.getlist()
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funsengmsg(index,row){
				this.title = '发送消息'
				this.rebotid = row.id
				this.sendVisible = true
			}
		}
	}
</script>

<style scoped>
	.page{
	
	background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
		margin: 0 auto;
	}
	.pagecontent{
		display: flex;
		height: calc(100vh - 81px);
		overflow: auto;
	}
	.box-card {
		width: 1030px;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
			
		}
	}
</style>