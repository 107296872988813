<template>
	<div class="">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
			<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>设置</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>服务器配置</span>
			</div>
			<div class="main">
				<div class="item" v-if="editForm.isOpen == 0">
					服务器地址(URL)：{{editForm.postURL}}
				</div>
				<div class="item" v-if="editForm.isOpen == 0">
					令牌(Token)：{{editForm.token}}
				</div>
				<!-- <div class="item" v-if="editForm.isOpen == 1">
					<a :href="authurl" target="_blank">授权</a>
				</div> -->
				<div class="item">
					消息加解密方式：明文模式
				</div>
				
			</div>
		</el-card>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>公众号开发配置(微信公众号->设置与开发->基本配置)</span>
			</div>
			
			<div class="main">
				
				<div class="item">
					<div class="mc">应用id:</div>
					<div class="txt">
						<el-input type="text" size="small" v-model="editForm.appid" />
					</div>
				</div>
				
				
				
				<div class="item">
					<div class="mc">AES内容加密密钥:</div>
					<div class="txt">
						<el-input type="text" size="small" v-model="editForm.aeskey" style="width: 500px;"></el-input>
					</div>
				</div>
				<div class="item">
					<div class="mc">支付宝RSA公钥:</div>
					<div class="txt">
						<el-input type="textarea" :rows="5" size="small" v-model="editForm.platRsaPubKey" style="width: 500px;"></el-input>
					</div>
				</div>
				<div class="item">
					<div class="mc">小程序RSA公钥:</div>
					<div class="txt">
						<el-input type="textarea" :rows="5" size="small" v-model="editForm.appRsaPubKey" style="width: 500px;"></el-input>
					</div>
				</div>
				<div class="item">
					<div class="mc">小程序RSA私钥:</div>
					<div class="txt">
						<el-input type="textarea" :rows="5" size="small" v-model="editForm.appRsaPriKey" style="width: 500px;"></el-input>
					</div>
				</div>
				<div class="item">
					<div class="mc">签名类型:</div>
					<div class="txt">
						<el-input type="text" size="small" v-model="editForm.signtype" style="width: 500px;"></el-input>
					</div>
				</div>
				
			</div>
			<el-button size="mini" type="primary" @click="sumbmitSet()"  style="float: right;margin-bottom: 20px;">提交</el-button>
		</el-card>
	</div>
</template>

<script>
	import accountApi from "../../../../../../api/cus/account.js"
	export default {
		data() {
			return {
				mpid: "",
				editForm: {
					appid: "",
					mpgid: "",
					mpid: "",
					appsecret: "",
					signtype: "RSA2",
					aeskey: "",
					appRsaPubKey: "",
					platRsaPubKey: "",
					appRsaPriKey: "",
					isOpen:0,
					token:'',
					postURL:'',
					
				},
				signList: [{
						id: "0",
						mc: 'md5'
					},
					{
						id: "1",
						mc: 'RSA2'
					},
				]
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.mpid = mpid
			} else {
				this.$router.push("/");
			}
			this.init()
		},
		methods: {
			init() {
				accountApi.info({
					mpid: this.mpid
				}, res => {
					console.log(res, 'res.info');
					this.editForm.mpgid = res.data.mpgid
					this.editForm.appid = res.data.appID
					this.editForm.appsecret = res.data.appSecret
					this.editForm.signtype = res.data.signtype
					this.editForm.aeskey = res.data.aesKey
					this.editForm.platRsaPubKey = res.data.platRsaPubKey
					this.editForm.appRsaPubKey = res.data.appRsaPriKey
					this.editForm.appRsaPriKey = res.data.appRsaPubKey
					this.editForm.token = res.data.token
					this.editForm.isOpen = res.data.isOpen
					this.editForm.postURL = res.data.postURL
				})
			},
			sumbmitSet() {
				let param = {
					"mpid": this.mpid,
					"mpgid": this.editForm.mpgid,
					"appid": this.editForm.appid,
					"appsecret": this.editForm.appsecret,
					"signtype": this.editForm.signtype,
					"aeskey": this.editForm.aeskey,
					"platRsaPubKey": this.editForm.platRsaPubKey,
					"appRsaPubKey": this.editForm.appRsaPubKey,
					"appRsaPriKey": this.editForm.appRsaPriKey
				}
				console.log(param, 'gzhsecretset.param');
				accountApi.gzhsecretset(param, res => {
					console.log(res, 'gzhsecretset.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '设置成功!'
						})
					} else {
						this.$message({
							type: 'error',
							message: '设置失败!'
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.box-card {
		margin-bottom: 10px;
	}

	.main {
		display: flex;
		flex-direction: column;
	}

	.main .item {
		margin: 10px 0;
		display: flex;
		align-items: center;
	}

	.main .item .mc {
		margin-right: 10px;
		width: 150px;
	}
</style>