<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="数据" name="first">
				<div style="display: flex;">
					<div>
						<el-button type="primary" icon="el-icon-plus" @click="addItem()" class="add-image"></el-button>

						<vuedraggable v-model="list.data" tag="ul" draggable="li"
							v-if="list.data && list.data.length > 0" class="image-list"
							:class="{ disable: data.tabType == 2 }">

							<li v-for="(item, index) in list.data" :key="index" @click="selitem(item,index)"
								:class="selindex == index ? 'sel' :''">
								{{ item && item.title }}
								<span @click="removeImage(selindex)" class="el-icon-close"></span>
							</li>
						</vuedraggable>
					</div>
					<div class="l-info" v-if="selindex > -1">
						<p style="display: flex;align-items: center;">
							<span>名称：</span>
							<!-- <span class="text">{{ item && item.title }}</span> -->
							<el-input v-model="item.title" size="medium"></el-input>
						</p>
						<p style="display: flex;align-items: center;margin: 20px 0;">
							<span>链接：</span>
							<span class="text">{{ item && item.desk && item.desk.mc }}</span>
							<el-button size="small" @click="urlPopup(selindex)">选择桌台</el-button>
						</p>
						<div class="" style="display: flex;align-items: center;white-space: nowrap;">
							<div @click="" style="flex-grow: 1;">对齐设置</div>
							<el-tooltip effect="dark" content="左对齐" placement="top">
								<div class="bbox" style="margin-left: 10px;" @click="funmoreset(item,3,'左对齐')">
									<img class="blodimg"
										src="../../../../../assets/image/market/icon_allstyle1.png"></img>
								</div>
							</el-tooltip>
							<el-tooltip effect="dark" content="顶对齐" placement="top">
								<div class="bbox" style="margin-left: 10px;" @click="funmoreset(item,4,'顶对齐')">
									<img class="blodimg"
										src="../../../../../assets/image/market/icon_allstyle2.png"></img>
								</div>
							</el-tooltip>
							<el-tooltip effect="dark" content="宽度相同" placement="top">
								<div class="bbox" style="margin-left: 10px;" @click="funmoreset(item,0,'宽度相同')">
									<img class="blodimg"
										src="../../../../../assets/image/market/icon_allstyle3.png"></img>
								</div>
							</el-tooltip>
							<el-tooltip effect="dark" content="高度相同" placement="top">
								<div class="bbox" style="margin-left: 10px;" @click="funmoreset(item,1,'高度相同')">
									<img class="blodimg"
										src="../../../../../assets/image/market/icon_allstyle4.png"></img>
								</div>
							</el-tooltip>
							<el-tooltip effect="dark" content="大小相同" placement="top">
								<div class="bbox" style="margin-left: 10px;" @click="funmoreset(item,2,'大小相同')">
									<img class="blodimg"
										src="../../../../../assets/image/market/icon_allstyle5.png"></img>
								</div>
							</el-tooltip>
							<el-tooltip effect="dark" content="圆角相同" placement="top">
								<div class="bbox" style="margin-left: 10px;" @click="funmoreset(item,5,'圆角相同')">
									<img class="blodimg"
										src="../../../../../assets/image/market/icon_allstyle6.png"></img>
								</div>
							</el-tooltip>
						</div>
						<div class="list">
							<div>
								<span>个性化 {{item.isdiy == 0 ?'通用':'个性化'}} </span>
							</div>
							<div class="btn">
								<el-radio-group v-model="item.isdiy" size="mini">
									<el-tooltip class="item" effect="dark" content="个性化" placement="bottom">
										<el-radio-button :label="1">
											<i class="el-icon-s-operation"></i>
										</el-radio-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="通用" placement="bottom">
										<el-radio-button :label="0"><i class="el-icon-s-fold"></i></el-radio-button>
									</el-tooltip>

								</el-radio-group>
							</div>
						</div>

						<div class="list">
							<div>
								<span>状态控制</span>
								<span v-if="selztindex == 0">可选</span>
								<span v-if="selztindex == 1">不可选</span>
								<span v-if="selztindex == 2">选中</span>
							</div>
							<div class="btn">
								<el-radio-group v-model="selztindex" size="mini">
									<el-tooltip class="item" effect="dark" content="可选" placement="bottom">
										<el-radio-button :label="0"><i class="el-icon-s-fold"></i></el-radio-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="不可选" placement="bottom">
										<el-radio-button :label="1">
											<i class="el-icon-s-operation"></i>
										</el-radio-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="选中" placement="bottom">
										<el-radio-button :label="2">
											<i class="el-icon-s-operation"></i>
										</el-radio-button>
									</el-tooltip>
								</el-radio-group>
							</div>
						</div>
						<div v-if="item.use != undefined">
							<div v-if="selztindex == 0">
								<div class="list">

									<div class="title ztop">
										<span>文字色</span>
									</div>
									<div class="center">{{item.use.titlecolor||'默认' }}</div>
									<div class="btn"><el-color-picker v-model="item.use.titlecolor"
											size="medium"></el-color-picker>
									</div>
								</div>
								<div class="list">
									<div class="title"><span>背景色</span>
									</div>
									<div class="center">{{item.use.bgcolor||'默认' }}</div>
									<div class="btn"><el-color-picker v-model="item.use.bgcolor"
											size="medium"></el-color-picker>
									</div>
								</div>
								<div class="list">
									<div class="title"><span>图片</span></div>
									<div style="display: flex;align-items: center;">
										<div @click="funczdeskimg(item,0)" style="color: #ff0000;margin-right: 10px;">重置
										</div>
										<el-button type="primary" @click="funadddeskimg(item,0)"
											size="small">添加图片</el-button>
									</div>

								</div>
								<div class="imgbox" v-if="item.use.bgimg && item.use.bgimg.length > 0">
									<img :src="item.use.bgimg" alt="" />
								</div>



							</div>
							<div v-if="selztindex == 1">
								<div class="list ">
									<div class="title ztop">
										<span>不可选 文字色</span>{{item.unuse.titlecolor||'默认' }}<span></span>
									</div>
									<div class="btn"><el-color-picker v-model="item.unuse.titlecolor"
											size="medium"></el-color-picker>
									</div>
								</div>
								<div class="list">
									<div class="title"><span>背景色</span>{{item.unuse.bgcolor||'默认' }}<span></span>
									</div>
									<div class="btn"><el-color-picker v-model="item.unuse.bgcolor"
											size="medium"></el-color-picker>
									</div>
								</div>
								<div class="list">
									<div class="title"><span>图片</span></div>
									<div style="display: flex;align-items: center;">
										<div @click="funczdeskimg(item,1)" style="color: #ff0000;margin-right: 10px;">重置
										</div>
										<el-button type="primary" @click="funadddeskimg(item,1)"
											size="small">添加图片</el-button>
									</div>


								</div>
								<div class="imgbox" v-if="item.unuse.bgimg && item.unuse.bgimg.length > 0">
									<img :src="item.unuse.bgimg" alt="" />
								</div>


							</div>
							<div v-if="selztindex == 2">
								<div class="list">
									<div class="title ztop">
										<span>选中 文字色</span>{{item.sel.titlecolor||'默认' }}<span></span>
									</div>
									<div class="btn"><el-color-picker v-model="item.sel.titlecolor"
											size="medium"></el-color-picker>
									</div>
								</div>
								<div class="list">
									<div class="title"><span>背景色</span>{{item.sel.bgcolor||'默认' }}<span></span>
									</div>
									<div class="btn"><el-color-picker v-model="item.sel.bgcolor"
											size="medium"></el-color-picker>
									</div>
								</div>

								<div class="list">
									<div class="title"><span>图片</span></div>
									<div style="display: flex;align-items: center;">
										<div @click="funczdeskimg(item,2)" style="color: #ff0000;margin-right: 10px;">重置
										</div>
										<el-button type="primary" @click="funadddeskimg(item,2)"
											size="small">添加图片</el-button>
									</div>


								</div>
								<div class="imgbox" v-if="item.sel.bgimg && item.sel.bgimg.length > 0">
									<img :src="item.sel.bgimg" alt="" />
								</div>

							</div>
						</div>

						<!-- 圆角 -->
						<div class="list">
							<div class="title">
								<span>圆角</span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="item.borderradius" show-input :max="800">
								</el-slider>
							</div>
						</div>
						<!--宽-->
						<div class="list">
							<div class="title">
								<span>宽</span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="item.width" show-input :max="2000">
								</el-slider>
							</div>
						</div>
						<!--高-->
						<div class="list">
							<div class="title">
								<span>高</span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="item.height" show-input :max="2000">
								</el-slider>
							</div>
						</div>
						<div class="list">
							<div class="title">
								<span>位置</span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="item.left" show-input :max="2000">
								</el-slider>
								<el-slider v-model="item.top" show-input :max="2000">
								</el-slider>
							</div>
						</div>
					</div>
				</div>


				<el-dialog :title="selstyletitle" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
					<div style="display: flex;align-items: center;margin-bottom: 10px;">
						<div style="margin-right: 10px;">可选桌台</div>
						<el-button type="success" size="mini" @click="funselall">全选</el-button>
					</div>
					<div class="copydesk">
						<div v-for="(item, index) in list.data" :key="index" @click="funselstyledesk(item,index)"
							class="ditem2">
							{{ item && item.title }}
						</div>
					</div>

					<div class="h50"></div>
					<div style="margin-bottom: 10px;">已选桌台</div>
					<div class="copydesk">
						<div v-for="(item, index) in deskcopy" :key="index" @click="selitem(item,index)" class="ditem">
							{{ item && item.title }}
							<span @click="removestyledesk(index)" class="el-icon-close"></span>
						</div>
					</div>



					<span slot="footer" class="dialog-footer">
						<el-button @click="dialogVisible = false">取 消</el-button>
						<el-button type="primary" @click="funsave">确 定</el-button>
					</span>
				</el-dialog>
			</el-tab-pane>

			<el-tab-pane label="风格" type="border-card">
				<!--属性设置-->
				
				<div class="details">

					<div class="list">
						<div>
							<span>桌台名称 {{list.options.showtitle == 0 ?'隐藏':'显示'}} </span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.showtitle" size="mini">
								<el-tooltip class="item" effect="dark" content="隐藏" placement="bottom">
									<el-radio-button :label="0"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="显示" placement="bottom">
									<el-radio-button :label="1">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
							
						</div>
					</div>
					<div>
						<div>
							<span>预定提示</span>
						</div>
						<div class="btn" style="margin-top: 20px;margin-bottom: 20px;">
							<el-input v-model="list.options.desknotice.title" :rows="7" maxlength="500" size="medium"
								type="textarea" placeholder="描述内容，最多500字"></el-input>
								<div style="text-align: right;">每行文字以;号分隔</div>
						</div>
					</div>


					
					<!-- 状态 -->
					<div class="list">
						<div>
							<span>预定状态设置 </span>
							<span v-if="selztindex == 0">可选</span>
							<span v-if="selztindex == 1">不可选</span>
							<span v-if="selztindex == 2">选中</span>
						</div>
						<div class="btn">
							<el-radio-group v-model="selztindex" size="mini">
								<el-tooltip class="item" effect="dark" content="可选" placement="bottom">
									<el-radio-button :label="0"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="不可选" placement="bottom">
									<el-radio-button :label="1">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="选中" placement="bottom">
									<el-radio-button :label="2">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>
					<div>
						<div class="list">
							<div>
								<span>桌台图 {{list.options.showbg == 0 ?'隐藏':'显示'}} </span>
							</div>
							<div class="btn">
								<el-radio-group v-model="list.options.showbg" size="mini">
									<el-tooltip class="item" effect="dark" content="隐藏" placement="bottom">
										<el-radio-button :label="0"><i class="el-icon-s-fold"></i></el-radio-button>
									</el-tooltip>
									<el-tooltip class="item" effect="dark" content="显示" placement="bottom">
										<el-radio-button :label="1">
											<i class="el-icon-s-operation"></i>
										</el-radio-button>
									</el-tooltip>
								</el-radio-group>
							</div>
						</div>
						<div v-if="selztindex == 0">
							<div class="list">

								<div class="title ztop">
									<span>预定状态文字色</span>
								</div>
								<div class="center">{{list.options.use.titlecolor||'默认' }}</div>
								<div class="btn"><el-color-picker v-model="list.options.use.titlecolor"
										size="medium"></el-color-picker>
								</div>
							</div>
							<div class="list">
								<div class="title"><span>预定状态背景色</span>
								</div>
								<div class="center">{{list.options.use.bgcolor||'默认' }}</div>
								<div class="btn"><el-color-picker v-model="list.options.use.bgcolor"
										size="medium"></el-color-picker>
								</div>
							</div>
							<div class="list">
								<div class="title"><span>桌台图片</span></div>
								<div style="display: flex;align-items: center;">
									<div @click="funczbgimg(0)" style="color: #ff0000;margin-right: 10px;">重置</div>
									<el-button type="primary" @click="funadduseimg(0)" size="small">添加图片</el-button>
								</div>

							</div>
							<div class="imgbox" v-if="list.options.use.bgimg && list.options.use.bgimg.length > 0">
								<img :src="list.options.use.bgimg" alt="" />
							</div>


						</div>
						<div v-if="selztindex == 1">
							<div class="list ">
								<div class="title ztop">
									<span>不可选 文字色</span>{{list.options.unuse.titlecolor||'默认' }}<span></span>
								</div>
								<div class="btn"><el-color-picker v-model="list.options.unuse.titlecolor"
										size="medium"></el-color-picker>
								</div>
							</div>
							<div class="list">
								<div class="title"><span>背景色</span>{{list.options.unuse.bgcolor||'默认' }}<span></span>
								</div>
								<div class="btn"><el-color-picker v-model="list.options.unuse.bgcolor"
										size="medium"></el-color-picker>
								</div>
							</div>
							<div class="list">
								<div class="title"><span>图片</span></div>
								<div style="display: flex;align-items: center;">
									<div @click="funczbgimg(1)" style="color: #ff0000;margin-right: 10px;">重置</div>
									<el-button type="primary" @click="funadduseimg(1)" size="small">添加图片</el-button>
								</div>


							</div>
							<div class="imgbox" v-if="list.options.unuse.bgimg && list.options.unuse.bgimg.length > 0">
								<img :src="list.options.unuse.bgimg" alt="" />
							</div>


						</div>
						<div v-if="selztindex == 2">
							<div class="list">
								<div class="title ztop">
									<span>选中 文字色</span>{{list.options.sel.titlecolor||'默认' }}<span></span>
								</div>
								<div class="btn"><el-color-picker v-model="list.options.sel.titlecolor"
										size="medium"></el-color-picker>
								</div>
							</div>
							<div class="list">
								<div class="title"><span>背景色</span>{{list.options.sel.bgcolor||'默认' }}<span></span>
								</div>
								<div class="btn"><el-color-picker v-model="list.options.sel.bgcolor"
										size="medium"></el-color-picker>
								</div>
							</div>
							
							<div class="list">
								<div class="title"><span>图片</span></div>
								<div style="display: flex;align-items: center;">
									<div @click="funczbgimg(2)" style="color: #ff0000;margin-right: 10px;">重置</div>
									<el-button type="primary" @click="funadduseimg(2)" size="small">添加图片</el-button>
								</div>


							</div>
							<div class="imgbox" v-if="list.options.sel.bgimg && list.options.sel.bgimg.length > 0">
								<img :src="list.options.sel.bgimg" alt="" />
							</div>


						</div>
					</div>

				
				</div>
				
				<el-tabs type="border-card">
					<el-tab-pane label="背景">
						
						<!-- 背景图 -->
						<el-button type="primary" @click="funaddbgimg" size="small">添加背景图</el-button>
						<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button>
						<div class="list deskbg">
							<img :src="list.options.bgimg" />
						</div>
						<div style="margin: 10px 0;font-size: 15px;font-weight: 600;">全局设置</div>
						<div class="list">
							<div class="title">
								<span>高</span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="list.options.height" show-input :max="2000">
								</el-slider>
							</div>
						</div>
						
						<div class="list">
							<div class="title">
								<span>上边距 </span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="list.options.margintop" show-input :max="200">
								</el-slider>
							</div>
						</div>
						<div class="list">
							<div class="title">
								<span>下边距 </span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="list.options.marginbottom" show-input :max="200">
								</el-slider>
							</div>
						</div>
						
						<div class="list">
							<div class="title">
								<span>页边距 </span>
							</div>
							<div class="btn2 full">
								<el-slider v-model="list.options.marginpage" show-input :max="200">
								</el-slider>
							</div>
						</div>
					</el-tab-pane>
					<el-tab-pane label="店内环境">
						<el-button type="primary" @click="funaddtipbgimg" size="small">添加选台背景图</el-button>
						<el-button type="text" class="reset" @click="resettipimg">重置</el-button>
						<div class="list deskbg">
							<img :src="list.options.desknotice.bgimg" />
						</div>
				
					</el-tab-pane>
				</el-tabs>
				
			</el-tab-pane>
		</el-tabs>
		<seldesk ref="seldesk" @seldeskresult="seldeskresult"></seldesk>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import vuedraggable from "vuedraggable";
	import designTemplataApi from "@/api/cus/designtmplate";
	import selImgs from "@/components/customer/selImgs";
	import seldesk from "@/components/customer/seldesk";
	export default {
		name: "image_row_edit",
		components: {
			vuedraggable,
			selImgs,
			seldesk
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				list: {},
				item: {},
				url: "",
				index: null,
				show: false,
				imageIndex: null,
				activeName: "first",

				selindex: -1,
				tid: "",
				selztindex: 0,
				seldeskitem: {},
				dialogVisible: false,
				allstyle: {
					height: 0,
					width: 0,
					isleft: false,
					left: 0
				},
				deskcopy: [],
				settype: 0,
				selstyletitle: ''
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;
			
			this.init()
			if (this.list.data && this.list.data.length > 0) {
				this.selindex = 0
				this.item = this.list.data[0]
			}
		},
		methods: {
			selectItemHandler(param) {
				if (this.list.data && this.list.data.length > param.index) {
					this.item = this.list.data[param.index];
					this.selindex = param.index
				}

			},
			init() {
				let url = window.location.href;
				let params = url.split('?')[1];
				let paramsObj = {};
				if (params) {
					let paramsArr = params.split('&');
					for (let i = 0; i < paramsArr.length; i++) {
						let param = paramsArr[i].split('=');
						paramsObj[param[0]] = param[1];
					}
				}
				let tid = paramsObj['tid'];
				
				this.tid = tid
			},
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				
				this.item = item
				this.selindex = index
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index) {
				this.$refs['seldesk'].funShow(this.tid)
			},
			removeImage(index) {
				this.list.data.splice(index, 1);
				if (this.list.data.length > index) {
					this.selindex = index
					this.item = this.list.data[index]
				} else {
					this.selindex = -1
				}
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			resettipimg() {
				this.list.options.desknotice.bgimg = ''
			},
			handleClose() {
				this.dialogVisible = false
			},
			funsave() {
				
				switch (this.settype) {
					case 0:
						this.list.data.forEach(item => {
							let tempdesk = this.deskcopy.find(f => {
								return item.cid == f.cid
							})
							
							if (tempdesk != undefined) {
								item.width = this.allstyle.width
							}
						})
						break;
					case 1:
						this.list.data.forEach(item => {
							let tempdesk = this.deskcopy.find(f => {
								return item.cid == f.cid
							})
							
							if (tempdesk != undefined) {
								item.height = this.allstyle.height
							}
						})
						break;
					case 2:
						this.list.data.forEach(item => {
							let tempdesk = this.deskcopy.find(f => {
								return item.cid == f.cid
							})
							
							if (tempdesk != undefined) {
								item.height = this.allstyle.height
								item.width = this.allstyle.width
							}
						})
						break;
					case 3:
						this.list.data.forEach(item => {
							let tempdesk = this.deskcopy.find(f => {
								return item.cid == f.cid
							})
							
							if (tempdesk != undefined) {
								item.left = this.allstyle.left

							}
						})
						break;
					case 4:
						this.list.data.forEach(item => {
							let tempdesk = this.deskcopy.find(f => {
								return item.cid == f.cid
							})
							
							if (tempdesk != undefined) {
								item.top = this.allstyle.top

							}
						})
						break;
					case 5:
						this.list.data.forEach(item => {
							let tempdesk = this.deskcopy.find(f => {
								return item.cid == f.cid
							})
							
							if (tempdesk != undefined) {
								item.borderradius = this.allstyle.borderradius

							}
						})
						break;
				}
				this.dialogVisible = false

			},
			funaddbgimg() {
				this.imageIndex = "bgimg";
				this.$refs["selImgs"].funShow();
			},
			funaddtipbgimg() {
				this.imageIndex = "tipbgimg";
				this.$refs["selImgs"].funShow();
			},
			funadddeskimg(item, t) {
				this.seldeskitem = item
				this.imageIndex = "deskuseimg" + t;
				this.$refs["selImgs"].funShow();
			},
			funadduseimg(t) {
				this.imageIndex = "useimg" + t;
				this.$refs["selImgs"].funShow();
			},
			addImage(index) {
				this.imageIndex = index;
				this.$refs["selImgs"].funShow();
				//this.$refs["upload"].$children[0].$refs.input.click();
			},
			selImgsresult(imgitem) {
				
				if (this.imageIndex === "bgimg") {
					//this.list.options.bgimg = imgitem.url;
					this.$set(this.list.options, 'bgimg', imgitem.url);
				} else if (this.imageIndex === "useimg0") {
					this.$set(this.list.options.use, 'bgimg', imgitem.url);
				} else if (this.imageIndex === "useimg1") {
					this.$set(this.list.options.unuse, 'bgimg', imgitem.url);
				} else if (this.imageIndex === "useimg2") {
					this.$set(this.list.options.sel, 'bgimg', imgitem.url);
				} else if (this.imageIndex === "deskuseimg0") {
					this.$set(this.seldeskitem.use, 'bgimg', imgitem.url);
				} else if (this.imageIndex === "deskuseimg1") {
					this.$set(this.seldeskitem.unuse, 'bgimg', imgitem.url);
				} else if (this.imageIndex === "deskuseimg2") {
					this.$set(this.seldeskitem.sel, 'bgimg', imgitem.url);
				} else if (this.imageIndex === "tipbgimg") {
					this.list.options.desknotice.bgimg = imgitem.url;
					// this.$set(this.list.options.desknotice.bgimg, 'tipbgimg', imgitem.url);
				} else {
					// const index = this.imageIndex;
					// const data = {
					//   url: imgitem.url,
					// };

					// if (index !== null) {
					//   this.$set(this.list.data, index, data);
					// } else {
					//   //this.list.data.push(data);
					// }
				}
			},
			funczbgimg(t) {
				switch (t) {
					case 0:
						this.list.options.use.bgimg = ''
						break;
					case 1:
						this.list.options.unuse.bgimg = ''
						break;
					case 2:
						this.list.options.sel.bgimg = ''
						break;
				}

			},
			funczdeskimg(item, t) {
				switch (t) {
					case 0:
						item.use.bgimg = ''
						break;
					case 1:
						item.unuse.bgimg = ''
						break;
					case 2:
						item.sel.bgimg = ''
						break;
				}

			},
			funselall() {
				
				this.deskcopy = []
				this.list.data.forEach(item => {
					this.deskcopy.push(item)
				})

			},
			funselstyledesk(item) {
				
				let tempdesk = this.deskcopy.find(f => {
					return f.cid == item.cid
				})

				if (tempdesk != undefined) {
					this.$message({
						type: "error",
						message: "请勿重复添加!",
					});
				} else {
					this.deskcopy.push(item)
				}

			},
			removestyledesk(index) {
				this.deskcopy.splice(index, 1);

			},

			funmoreset(item, t, msg) {
				this.settype = t
				this.deskcopy = []
				this.dialogVisible = true
				this.allstyle.width = item.width
				this.allstyle.height = item.height
				this.allstyle.left = item.left
				this.allstyle.top = item.top
				this.allstyle.borderradius = item.borderradius
				this.selstyletitle = msg + ',参考' + item.title
			},
			addItem() {
				const len = this.list.data.length;
				const col = 15;
				const row = parseInt(len / col);
				const row_num = len % col;

				let width = 25;
				let height = 40;

				let left = row_num * width;
				let top = height * row;
				if (left < 0) {
					left = 0;
				}
				if (top < 0) {
					top = 0;
				}

				
				const data = {
					cid: designTemplataApi.createToolBoxId(),
					left: left,
					top: top,
					width: width,
					height: height,
					title: len + 1,
					type: 'desk',
					"use": {
						"titlecolor": "#0A0A0A",
						"bgcolor": "#FFFFFF",
						"bgimg": '',
					},
					"unuse": {
						"titlecolor": "",
						"bgcolor": "",
					},
					"sel": {
						"titlecolor": "",
						"bgcolor": "",
						"bgimg": '',
					},
				};
				this.list.data.push(data);
			},

			seldeskresult(param) {
				
				const index = this.selindex;
				if (index !== null) {
					let data = this.list.data[index];
					data.desk = param;
					data.title = param.mc;
					
					this.$set(this.list.data, index, data);
					
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: 60px;
			height: 30px;
			line-height: 30px;
			padding: 0;
			font-size: 12px;
			margin-top: 2px;
			margin-left: 2px;
		}

		.image-list {
			margin: 0;

			overflow: auto;
			display: flex;
			flex-direction: column;
			width: 80px;
			max-height: 500px;
			overflow: auto;


			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			.sel {
				color: #fff;
				background-color: #1b8bff;
			}

			li {
				list-style: none;
				background: #ccc;
				border-radius: 4px;
				margin: 2px;
				width: 60px;
				height: 30px;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				position: relative;
				flex-shrink: 0;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				font-size: 9px;

				.el-icon-close {
					color: #999;
					font-size: 12px;
					font-weight: 600;
					margin-bottom: 6px;
					cursor: pointer;
					position: absolute;
					top: 0;
					right: 0;

					&:hover {
						color: red;
					}
				}

				&.disable div {
					pointer-events: none;
					user-select: none;
				}



				.r-image {
					text-align: right;

					.el-icon-close {
						color: #999;
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 6px;
						cursor: pointer;

						&:hover {
							color: red;
						}
					}

					.image-box {
						width: 70px;
						height: 70px;
						border-radius: 5px;
						overflow: hidden;
						position: relative;
						background: #fff;
						display: flex;
						justify-content: center;
						align-items: center;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.5);
							opacity: 0;
							transition: all 0.3s;
						}

						span {
							position: absolute;
							top: 50%;
							left: 50%;
							color: #fff;
							transform: translate(-50%, -50%);
							font-size: 20px;
							cursor: pointer;
							opacity: 0;
							transition: all 0.3s;
						}

						img {
							max-width: 100%;
						}

						&:hover {

							&::before,
							span {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.l-info {
			font-size: 12px;
			padding-top: 8px;
			margin: 20px;
			border-top: 1px solid #f0f0f0;
			width: calc(100% - 70px);

			p {
				margin: 12px 0 0;
				white-space: nowrap;
				overflow: hidden;
				display: flex;

				.link {
					color: #1b8bff;
					cursor: pointer;
				}

				.text {
					white-space: nowrap;
					text-align: -webkit-auto;
					text-overflow: ellipsis;
					overflow: hidden;
				}
			}
		}

		.list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 20px 0;

			.title {
				margin-right: 10px;
				flex-shrink: 0;
				width: 60px;
			}

			.full {
				flex-grow: 1;

			}
		}
	}

	.bgimg img {
		width: 100%;
	}

	.deskbg {
		height: 300px;
		overflow: auto;
	}

	.deskbg img {
		height: 100%;
	}

	.ztop {
		// width: 200px !important;
	}

	.center {
		color: #969799;
	}

	.imgbox {
		width: 80px;
		height: 80px;
		margin: 0 auto;
	}

	.imgbox img {
		width: 100%;
		height: 100%;
	}

	.copydesk {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
		flex-wrap: wrap;
		flex-shrink: 0;
		min-height: 100px;
	}

	.copydesk .ditem {
		width: 60px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		margin: 2px;
		background-color: #00aa00;
		color: #ffffff;
	}

	.copydesk .ditem2 {
		width: 60px;
		height: 30px;
		text-align: center;
		line-height: 30px;
		border-radius: 5px;
		margin: 2px;
		background-color: #1b8bff;
		color: #ffffff;
	}

	.bbox {
		cursor: pointer;
		margin-right: 5px;
		width: 32px;
		height: 30px;
		border-radius: 4px;
		background-color: #DEDFE1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.blodimg {
		width: 32px;
		height: 32px;
	}

	.h50 {
		width: 100%;
		height: 30px;
	}
</style>