<template>
  <div id="fenge">
    <div class="list">
      <div class="first">
        <span>分割类型</span
        ><span>{{ list.options.type == 1 ? "辅助空白" : "辅助线" }}</span>
      </div>
      <div>
        <el-radio-group v-model="list.options.type">
          <el-tooltip
            class="item"
            effect="dark"
            content="辅助空白"
            placement="bottom"
          >
            <el-radio-button :label="1">辅助空白</el-radio-button>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="辅助线"
            placement="bottom"
          >
            <el-radio-button :label="2">辅助线</el-radio-button>
          </el-tooltip>
        </el-radio-group>
      </div>
    </div>
    <div class="list1" v-show="list.options.type == 1">
      <div>空白高度</div>
      <div>
        <el-slider
          v-model="list.options.height"
          show-input
          :max="100"
          :min="20"
        >
        </el-slider>
      </div>
    </div>
    <div v-show="list.options.type == 2">
      <div class="list">
        <div class="first">
          <span>选择样式</span
          ><span>{{
            list.options.line == 1
              ? "实线"
              : list.options.line == 2
              ? "虚线"
              : "点线"
          }}</span>
        </div>
        <div>
          <el-radio-group v-model="list.options.line">
            <el-tooltip
              class="item"
              effect="dark"
              content="实线"
              placement="bottom"
            >
              <el-radio-button :label="1">实线</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="虚线"
              placement="bottom"
            >
              <el-radio-button :label="2">虚线</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="点线"
              placement="bottom"
            >
              <el-radio-button :label="3">点线</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <div class="list">
        <div class="first">
          <span>左右边距</span
          ><span>{{ list.options.about == 1 ? "无边距" : "左右边距" }}</span>
        </div>
        <div>
          <el-radio-group v-model="list.options.about">
            <el-tooltip
              class="item"
              effect="dark"
              content="无边距"
              placement="bottom"
            >
              <el-radio-button :label="1">无边距</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="左右边距"
              placement="bottom"
            >
              <el-radio-button :label="2">左右边距</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <div class="list">
        <div class="first">
          <span>辅助线颜色</span>
          <span>{{ list.options.color || "默认" }}</span>
        </div>
        <div class="two">
          <el-button type="text">重置</el-button>
          <el-color-picker v-model="list.options.color"></el-color-picker>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "小程序模块话Divider",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: "",
    };
  },

  created() {
    this.list = this.data;
  },

  methods: {},
};
</script>

<style lang="less" scoped>
#fenge {
  margin: 0 20px;
  font-size: 14px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .first {
    :first-child {
      color: #969799;
      margin-right: 20px;
    }
  }
}
.list1 {
  :first-child {
    color: #969799;
  }
}
.two {
  display: flex;
  align-items: center;
  :first-child {
    margin-right: 10px;
  }
}
</style>