<template>
  <section>
    <div class="tab-content">
      <h2>{{ type && list[type]["tit"] }}</h2>
      <!-- <div class="tab" v-if="type != 'info'">
        <span
          v-for="(val, key, index) in tabType"
          :key="index"
          @click="tab(key)"
          :class="{ active: val }"
          ><i class="el-icon-s-data"></i> {{ key }}</span
        >
      </div> -->
    </div>
    <component
      :is="type && list[type]['com']"
      :data="data"
      @changeTab="tab"
    ></component>
  </section>
</template>

<script>
import hb_Info from "@/components/applet/Edit/Info";
import hb_Images from "@/components/applet/Edit/Image";
import hb_Product from "@/components/applet/Edit/Product";
import hb_text from "@/components/applet/Edit/text";
import hb_video from "@/components/applet/Edit/video";
import hb_search from "@/components/applet/Edit/search";
import hb_notice from "@/components/applet/Edit/notice";
import hb_swiper from "@/components/applet/Edit/swiper";
import hb_divider from "@/components/applet/Edit/divider";
import hb_store from "@/components/applet/Edit/store";
import hb_card from "@/components/applet/Edit/card";
import hb_menu from "@/components/applet/Edit/menu";
import hb_menuad from "@/components/applet/Edit/menuad";




export default {
  name: "EditForm",
  components: {
    hb_Info,
    hb_Images,
    hb_Product,
    hb_text,
    hb_video,
    hb_search,
    hb_notice,
    hb_swiper,
    hb_store,
    hb_card
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      type: "",
      list: {
        search: {
          tit: "商品搜索",
          com: hb_search,
        },
        notice: {
          tit: "公告",
          com: hb_notice,
        },
        text: {
          tit: "文本",
          com: hb_text,
        },
        product: {
          tit: "商品列表",
          com: hb_Product,
        },
        info: {
          tit: "页面信息",
          com: hb_Info,
        },
        images: {
          tit: "图片",
          com: hb_Images,
        },
        swiper: {
          tit: "轮播图",
          com: hb_swiper,
        },
        video: {
          tit: "视频",
          com: hb_video,
        },
        divider: {
          tit: "辅助空白",
          com: hb_divider,
        },
        store: {
          tit: "进入店铺",
          com: hb_store,
        },
        card: {
          tit: "内容卡片",
          com: hb_card,
        },
        menu: {
          tit: "导航模块",
          com: hb_menu,
        },
        menuad: {
          tit: "导航广告",
          com: hb_menuad,
        },
      },
      tabType: {
        1: true,
        2: false,
        3: false,
      },
    };
  },
  mounted() {
    this.type = this.data.type;
    if (this.data.tabType) {
      this.tab(this.data.tabType);
    }
  },
  methods: {
    tab(key) {
      console.log('tab',key);
      for (let i in this.tabType) {
        if (key == i) {
          this.tabType[key] = true;
          this.$set(this.data, "tabType", key);
        } else {
          this.tabType[i] = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
section {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}
.tab-content {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 15px;
  h2 {
    font-size: 16px;
    color: #333;
  }
  .tab {
    display: flex;
    justify-content: space-around;
    border: 1px solid #ddd;
    border-radius: 6px;
    span {
      width: 33.33%;
      text-align: center;
      font-size: 14px;
      color: #666;
      display: block;
      height: 36px;
      line-height: 36px;
      cursor: pointer;
      &.active {
        color: #fff;
        background: #409eff;
        border-radius: 2px;
      }
      &:nth-of-type(2) {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
      }
    }
  }
}
</style>