import httpapi from '../../utils/httpapi';
const tempsysApi = {
	//系统模版列表
	list(param, funsucc) {
		httpapi.post('/system/design/tmplate/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//系统模版列表-待审批
	listchecking(param, funsucc) {
		httpapi.post('/system/design/tmplate/listchecking', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	},
	//系统模版列表-设计中
	listdesign(param, funsucc) {
		httpapi.post('/system/design/tmplate/listdesign', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	},
	//系统模版编辑
	edit(param, funsucc) {
		httpapi.post('/system/design/tmplate/edit', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//系统模版设置价格
	setprice(param, funsucc) {
		httpapi.postjson('/system/design/tmplate/price/set', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	},
	//系统模版详情
	detail(param, funsucc) {
		httpapi.post('/system/design/tmplate/detail', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//设置为默认模版
	default(param, funsucc) {
		httpapi.post('/system/design/tmplate/set/default', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	},
	//系统模版删除
	delete(param, funsucc) {
		httpapi.post('/system/design/tmplate/delete', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//系统模版注册/将用户模版添加到系统模版
	fromuser(param, funsucc) {
		httpapi.post('/system/design/tmplate/reg/fromuser', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	},
	//用户程序模版更换系统模版
	changetemplate(param, funsucc) {
		httpapi.post('/system/design/usertmplate/changetemplate', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//系统模版注册/添加
	regnew(param, funsucc) {
		httpapi.post('/system/design/tmplate/reg/new', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//系统模版分类下拉选
	clslist(param, funsucc) {
		httpapi.post('/system/design/class/select/clslist', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//分类新增/编辑
	clsedit(param, funsucc) {
		httpapi.post('/system/design/class/manager/edit', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//分类列表
	clsmanagerlist(param, funsucc) {
		httpapi.post('/system/design/class/manager/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//分类下拉选
	clssellist(param, funsucc) {
		httpapi.post('/system/design/class/select/clslist', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//分类类型
	clstype(param, funsucc) {
		httpapi.get('/system/design/class/select/type', {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//模版下架
	tempdown(param, funsucc) {
		httpapi.post('/system/design/tmplate/temp/online/down', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//模版上架
	tempup(param, funsucc) {
		httpapi.post('/system/design/tmplate/temp/online/up', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	designerlist(funsucc){
		httpapi.get('/system/isv/user/list').then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	},
	changeisv(param,funsucc){
		httpapi.post('/system/design/tmplate/change/isv',param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
		
	}
	
}
export default tempsysApi