var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"product",class:_vm.className},[(_vm.data.data && _vm.data.data.length > 0)?_vm._l((_vm.data.data),function(item,index){return _c('div',{key:index,staticClass:"product-item",style:({
          'margin-left': _vm.marginpage,
          'margin-right': _vm.marginpage,
          'margin-top': _vm.margintop,
          'margin-bottom': _vm.marginbottom,
		  'background':_vm.data.options.bgcolor,
		  'margin-left': index > 0 ? _vm.marginitem : '0px'
        })},[_c('div',{staticClass:"image",style:({
			width: _vm.colWidth,
            height: _vm.height
            })},[_c('img',{style:({
				'border-radius': _vm.borderradius,
			}),attrs:{"src":item.logo}})]),_c('div',{staticClass:"info"},[(_vm.data.options.showmc)?_c('p',{staticClass:"name",style:({
						'color':_vm.data.options.titlecolor,
						fontSize: _vm.size,
          fontWeight: _vm.bold})},[_vm._v(_vm._s(item.mc))]):_vm._e(),(_vm.data.options.showmemo)?_c('p',{staticClass:"memo",style:({
						'color':_vm.data.options.desccolor,
					fontSize: _vm.descsize,
		  fontWeight: _vm.descbold})},[_vm._v(_vm._s(item.memo))]):_vm._e(),_c('p',{staticClass:"price"},[(_vm.data.options.pricesale)?_c('span',[_vm._v("￥"+_vm._s(item.pricesale))]):_vm._e(),(_vm.options.pricemarket)?_c('span',[_vm._v("￥"+_vm._s(item.pricemarket))]):_vm._e()])])])}):_vm._l((3),function(index){return _c('div',{key:index,staticClass:"product-item product-default"},[_vm._m(0,true),_vm._m(1,true)])})],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"image"},[_c('img',{attrs:{"src":"https://img.quanminyanxuan.com/other/21188f7a1e9340759c113aa569f96699.jpg?x-oss-process=image/resize,h_600,m_lfit","alt":""}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"info"},[_c('p',{staticClass:"name"},[_vm._v("这是商品名称")]),_c('p',{staticClass:"num"},[_vm._v(" 12124 已购买 | 99% ")]),_c('p',{staticClass:"price"},[_c('span',[_vm._v("￥9.99")]),_c('span',[_vm._v("￥9.99")])])])}]

export { render, staticRenderFns }