import httpapi from '../../utils/httpapi';
const cusApi = {
	//客户分页查询
	list(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/customer/page?mpid='+param.mpid+'&name='+param.name+'&pageno='+param.pageno+'&pagesize='+param.pagesize, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//向企业微信同步客户
	synclist(param, funsucc) {
		httpapi.get('/customer/qiyeweixin/customer/sync/list?mpid='+param.mpid, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default cusApi