<template>
	<div id="notice">

		<el-tabs v-model="activeName" type="border-card">
			<el-tab-pane label="数据" name="first">
				<div class="row">
					<div class="boxdisp">
						<div class="toolbox" :class="selbox.type == item.type?'sel':''" @click="funtoolbox(item,index)"
							v-for="(item,index) in listbox" :key="index">
							<span>{{item.name}}</span>
						</div>
					</div>
					<ul>
						<li>
							<div class="l-info" v-if="selbox">
								<!-- 图标 -->

								<template v-if="item && selbox.isimg == 1">

									<div>
										<div class="titles">
											<div>图标 </div>
											<div style="color: #00aaff;" @click="funsetlogo(item)">重置</div>
											<el-button type="primary" size="mini"
												@click="funaddbgimg('logo')">上传</el-button>
										</div>
										<div class="">

											<div class="tb full">
												<!-- <img :src="item.url" /> -->
												<img :src="item.url" />
											</div>
										</div>
									</div>
									<!-- 圆角 -->
									<div class="list">
										<div class="title">
											<span>圆角</span>
										</div>
										<div class="btn2 full">
											<el-slider v-model="item.borderradius" show-input :max="800"
												style="width: 300px;">
											</el-slider>
										</div>
									</div>
								</template>
								<!-- 是否隐藏 -->


								<template v-if="item && selbox.istxt == 1">
									<span>
										<el-input style="" type="text" v-model="item.title" placeholder="请输入桌台名称"
											size="medium" style="width: 200px;"></el-input>
									</span>
									<!-- 标题文字大小 -->
									<div class="list" style="margin-top: 10px;">

										<div class="btn">
											<el-select v-model="item.size" filterable allow-create placeholder="请选择标题大小"
												size="small">
												<el-option v-for="citem in fontlist" :key="citem" :label="citem"
													:value="citem">
												</el-option>
											</el-select>
											<el-tooltip effect="dark" content="加粗" placement="top">
												<div class="bbox" style="margin-left: 10px;">
													<img class="blodimg" src="../../../../assets/image/icon_b1.png"
														v-if="item.bold && item.bold == 1"
														@click="funchu(item,0)"></img>
													<img class="blodimg" src="../../../../assets/image/icon_b0.png"
														v-else @click="funchu(item,1)" /></img>
												</div>
											</el-tooltip>
											<el-tooltip effect="dark" content="居中" placement="top">
												<div class="bbox">
													<img class="blodimg" src="../../../../assets/c1.png"
														v-if="item.center && item.center == 1"
														@click="funcenter(item,2)"></img>
													<img class="blodimg" src="../../../../assets/c0.png" v-else
														@click="funcenter(item,1)" /></img>
												</div>
											</el-tooltip>
											<el-tooltip effect="dark" content="显示" placement="top">
												<div class="bbox" v-if="item.isflag != undefined">
													<img class="blodimg2" src="../../../../assets/image/icon_show1.png"
														v-if="item.isflag && item.isflag == true"
														@click="funflag(item,false)"></img>
													<img class="blodimg2" src="../../../../assets/image/icon_show0.png"
														v-else @click="funflag(item,true)" /></img>
												</div>
											</el-tooltip>
											<el-tooltip effect="dark" content="字体颜色" placement="top">
												<el-color-picker v-model="item.titlecolor"
													size="medium"></el-color-picker>
											</el-tooltip>
										</div>
									</div>


									<!-- 是否文字居中 -->

									<!-- 是否文字加粗 -->

									<!-- 标题文字颜色 -->
									<!-- <div class="list">
										<div class="title"><span>标题颜色</span>{{item.titlecolor||'默认' }}<span></span>
										</div>
										<div class="btn">
											<el-button type="text" class="reset"
												@click="resetitem('titlecolor')">重置</el-button><el-color-picker
												v-model="item.titlecolor" size="medium"></el-color-picker>
										</div>
									</div> -->
									<!-- 按钮背景颜色 -->
									<div class="list" v-if="item.bgcolor != undefined">
										<div class="title"><span>背景颜色</span>{{item.bgcolor||'默认' }}<span></span></div>
										<div class="btn">
											<el-button type="text" class="reset"
												@click="resetitem('bgcolor')">重置</el-button><el-color-picker
												v-model="item.bgcolor" size="medium"></el-color-picker>
										</div>
									</div>
								</template>
								<template v-else>
									<div style="display: flex;flex-direction: row;align-items: center;justify-content: space-between;">
										<div>是否显示</div>
										<el-tooltip effect="dark" content="显示" placement="top">
											<div class="bbox" v-if="item.isflag != undefined">
												<img class="blodimg2" src="../../../../assets/image/icon_show1.png"
													v-if="item.isflag && item.isflag == true"
													@click="funflag(item,false)"></img>
												<img class="blodimg2" src="../../../../assets/image/icon_show0.png"
													v-else @click="funflag(item,true)" /></img>
											</div>
										</el-tooltip>
									</div>
								
								</template>
								

								<div class="more" style="color: #00aaff;" @click="funMore">
									{{moreShow == true ? '隐藏' : '显示'}}
								</div>
								<div v-if="moreShow">
									<div class="list">
										<div class="title">
											<span>左</span>
										</div>
										<div class="btn2 full">
											<el-slider v-model="item.left" show-input :max="4000" style="width: 300px;">
											</el-slider>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>上</span>
										</div>
										<div class="btn2 full">
											<el-slider v-model="item.top" show-input :max="4000" style="width: 300px;">
											</el-slider>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>宽</span>
										</div>
										<div class="btn2 full">
											<el-slider v-model="item.width" show-input :max="750" style="width: 300px;">
											</el-slider>
										</div>
									</div>
									<div class="list">
										<div class="title">
											<span>高</span>
										</div>
										<div class="btn2 full">
											<el-slider v-model="item.height" show-input :max="4000"
												style="width: 300px;">
											</el-slider>
										</div>
									</div>
								</div>

							</div>

						</li>
					</ul>
				</div>


			</el-tab-pane>
			<el-tab-pane label="风格" name="second">
				<div class="stylebox">
					<div class="styleitem" v-for="(item,index) in stylelist" :key="item.id" @click="funStyle(item)">
						<div class="list_style">
							<div class="item_style" :class="item.id == list.options.styleid ? 'sel':'normal'">
								<img :src="item.styleimg" />
								<!-- {{ item.mc }} -->

							</div>
						</div>
					</div>
				</div>
				<!-- 背景颜色 -->
				<div class="list">
					<div class="title">
						<span>背景颜色</span>{{ list.options.bgcolor || "默认"
			    }}<span></span>
					</div>
					<div class="btn">
						<el-button type="text" class="reset" @click="reset('bgcolor')">重置</el-button><el-color-picker
							v-model="list.options.bgcolor" size="medium"></el-color-picker>
					</div>
				</div>
				<!-- 文字颜色 -->
				<div class="list">
					<div class="title">
						<span>文字颜色</span>{{ list.options.textcolor || "默认" }}<span></span>
					</div>
					<div class="btn">
						<el-button type="text" class="reset" @click="reset('textcolor')">重置</el-button><el-color-picker
							v-model="list.options.textcolor" size="medium"></el-color-picker>
					</div>
				</div>
				<!--样式开始-->
				<div class="list">
					<div class="title">
						<span>高 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.height" show-input :max="4000" style="width: 300px;">
						</el-slider>
					</div>
				</div>

				<div class="list">
					<div class="title">
						<span>上边距 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.margintop" show-input :max="200" style="width: 300px;">
						</el-slider>
					</div>
				</div>
				<div class="list">
					<div class="title">
						<span>下边距 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.marginbottom" show-input :max="200" style="width: 300px;">
						</el-slider>
					</div>
				</div>

				<!-- <div class="list">
					<div class="title">
						<span>页边距 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.marginpage" show-input :max="200">
						</el-slider>
					</div>
				</div> -->
				<div class="list">
					<div class="title">
						<span>内上边距 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.paddingtop" show-input :max="200" style="width: 300px;">
						</el-slider>
					</div>
				</div>
				<div class="list">
					<div class="title">
						<span>内下边距 </span>
					</div>
					<div class="btn2 full">
						<el-slider v-model="list.options.paddingbottom" show-input :max="200" style="width: 300px;">
						</el-slider>
					</div>
				</div>
				<!--样式结束-->
			</el-tab-pane>
		</el-tabs>
		<!-- {{list}} -->
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>

	</div>
</template>

<script>
	import selImgs from "@/components/designer/selImgs";
	export default {
		components: {
			selImgs
		},
		name: "text_notice_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				fontlist: [
					10, 11, 12, 14, 16, 18, 20, 24, 32, 36, 40, 48, 64, 96, 128
				],
				list: {},
				item: {},
				activeName: 'first',
				selbox: "",
				moreShow: true,
				currIndex: 1,
				listbox: [],
				stylelist: [{
						id: "s1",
						mc: "样式一",
						styleimg: require('../../../../assets/image/market/icon_shopstyle1.png'),
						listbox: [{
								type: 'headimg',
								name: '店铺图片',
								isimg: 1,
								istxt: 0
							}, {
								type: 'logo',
								name: '店铺图标',
								isimg: 1,
								istxt: 0
							},
							{
								type: 'deskname',
								name: '桌台名称',
								isimg: 0,
								istxt: 1,


							},
							{
								type: 'shopname',
								name: '商家名称',
								isimg: 0,
								istxt: 1
							},
							{
								type: 'notice',
								name: '商家介绍',
								isimg: 0,
								istxt: 1
							}, {
								type: 'phone',
								name: '客服电话',
								isimg: 1,
								istxt: 0,
								isflag: true
							},
							{
								type: 'map',
								name: '导航',
								isimg: 1,
								istxt: 0,
								isflag: true
							},
							{
								type: 'more',
								name: '更多门店',
								isimg: 0,
								istxt: 1,
								isflag: true

							},
							{
								type: 'linename',
								name: '线路',
								isimg: 0,
								istxt: 1,
								isflag: false

							}
						]
					},
					{
						id: "s2",
						mc: "样式二",
						styleimg: require('../../../../assets/image/market/icon_shopstyle2.png'),
						listbox: [{
								type: 'headimg',
								name: '店铺图片',
								isimg: 1,
								istxt: 0
							}, {
								type: 'logo',
								name: '店铺图标',
								isimg: 1,
								istxt: 0
							},
							{
								type: 'deskname',
								name: '桌台名称',
								isimg: 0,
								istxt: 1,
								isflag: true
							},
							{
								type: 'shopname',
								name: '商家名称',
								isimg: 0,
								istxt: 1
							},
							{
								type: 'notice',
								name: '商家介绍',
								isimg: 0,
								istxt: 1
							}, {
								type: 'phone',
								name: '客服电话',
								isimg: 1,
								istxt: 0,
								isflag: true
							},
							{
								type: 'map',
								name: '导航',
								isimg: 1,
								istxt: 0,
								isflag: true
							},
							{
								type: 'more',
								name: '更多门店',
								isimg: 0,
								istxt: 1,
								isflag: true

							},
							{
								type: 'linename',
								name: '线路',
								isimg: 0,
								istxt: 1,
								isflag: false

							}
						]
					},
					{
						id: "s3",
						mc: "样式三",
						styleimg: require('../../../../assets/image/market/icon_shopstyle3.png'),
						listbox: [{
								type: 'headimg',
								name: '店铺图片',
								isimg: 1,
								istxt: 0
							}, {
								type: 'logo',
								name: '店铺图标',
								isimg: 1,
								istxt: 0
							},
							{
								type: 'deskname',
								name: '桌台名称',
								isimg: 0,
								istxt: 1,
								isflag: true
							},
							{
								type: 'shopname',
								name: '商家名称',
								isimg: 0,
								istxt: 1
							},
							{
								type: 'notice',
								name: '商家介绍',
								isimg: 0,
								istxt: 1
							}, {
								type: 'phone',
								name: '客服电话',
								isimg: 1,
								istxt: 0,
								isflag: true
							},
							{
								type: 'map',
								name: '导航',
								isimg: 1,
								istxt: 0,
								isflag: true
							},
							{
								type: 'more',
								name: '更多门店',
								isimg: 0,
								istxt: 1,
								isflag: true

							},
							{
								type: 'linename',
								name: '线路',
								isimg: 0,
								istxt: 1,
								isflag: false

							}
						]
					},


				],
				styleitem1: {
					"data": {
						"headimg": {
							"bgcolor": "#F7420B",
							"top": 0,
							"size": 1,
							"left": 0,
							"width": 100,
							"borderradius": 354,
							"titlecolor": "#FFFFFF",
							"position": 2,
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/7b3e51ee5ba948bcabb51bc3ee2b772f.jpg",
							"height": 180,
							"isflag": true,
						},
						"phone": {
							"center": 1,
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/6ca20b54cd7e469ca5d6c38fe58377f6.png",
							"bgcolor": "#FFFFFF",
							"top": 78,
							"size": 1,
							"left": 318,
							"width": 32,
							"borderradius": 60,
							"position": 2,
							"height": 32
						},
						"more": {
							"center": 1,
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "更多门店>>",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 85,
							"size": 2,
							"left": 32,
							"width": 80,
							"borderradius": 0,
							"position": 1,
							"height": 40
						},
						"linename": {
							"isflag": false,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "线路>>",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 85,
							"size": 2,
							"left": 32,
							"width": 80,
							"borderradius": 0,
							"position": 1,
							"height": 40,
							"center": 1,
						},
						"shopname": {
							
							"top": 18,
							"size": 18,
							"left": 90,
							"width": 242,
							"borderradius": 0,
							"titlecolor": "#FFFFFF",
							"position": 1,
							"bold": 1,
							"title": "酒吧名称",
							"url": "",
							"height": 25,
							"center": 0,
							"isflag": true,

						},
						"deskname": {
							
							"top": 18,
							"size": 18,
							"left": 150,
							"width": 242,
							"borderradius": 0,
							"titlecolor": "#FFFFFF",
							"position": 1,
							"bold": 1,
							"title": "桌台名称",
							"url": "",
							"height": 25,
							"center": 1,
							"isflag": true

						},
						"logo": {
							"bgcolor": "#FFFFFF",
							"top": 18,
							"size": 1,
							"left": 28,
							"width": 48,
							"borderradius": 60,
							"titlecolor": "#FFFFFF",
							"position": 2,
							"bold": 1,
							"title": "",
							"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/202309/4f99bb1a0d504c54ad93dc8e3a698b99.jpg?key=123456",
							"height": 48,
							"isflag": true,
						},
						"map": {
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/18634dd39dcd4d5c8c5d817c5fc92294.png",
							"bgcolor": "#FFFFFF",
							"top": 79,
							"size": 1,
							"left": 272,
							"width": 32,
							"borderradius": 60,
							"position": 2,
							"height": 32
						},
						"notice": {
							"bgcolor": "#FFFFFF",
							"top": 49,
							"size": 1,
							"left": 90,
							"width": 138,
							"borderradius": 0,
							"titlecolor": "#FFFFFF",
							"position": 1,
							"bold": 1,
							"title": "商家介绍",
							"url": "",
							"height": 23,
							"center": 0,
							"isflag": true,

						}
					},
					"options": {
						'bgcolor': "#EDA50A",
						'borderradius': 0,
						'height': 128,
						'left': 0,
						'marginbottom': 0,
						'marginpage': 0,
						'margintop': 0,
						'paddingbottom': 0,
						'paddingtop': 0,
						'styleid': "s1",
						'top': 0
					},
					"name": "1.店铺信息",
					"type": "shop_info_nav"
				},
				styleitem2: {
					"data": {
						"linename": {
							"isflag": false,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "线路>>",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 85,
							"size": 2,
							"left": 32,
							"width": 80,
							"borderradius": 0,
							"position": 1,
							"height": 40,
							"center": 1,
						},
						"headimg": {
							"bgcolor": "#F7420B",
							"top": 0,
							"size": 1,
							"left": 0,
							"width": 100,
							"borderradius": 240,
							"titlecolor": "#FFFFFF",
							"position": 2,
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202310/7c0ae9007b41493d84994fe444d333e3.png?key=123456",
							"height": 180,
							"isflag": true,
						},
						"phone": {
							"center": 1,
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/6ca20b54cd7e469ca5d6c38fe58377f6.png",
							"bgcolor": "#FFFFFF",
							"top": 17,
							"size": 1,
							"left": 81,
							"width": 32,
							"borderradius": 60,
							"position": 2,
							"height": 32
						},
						"more": {
							"center": 1,
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "更多门店>>",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 147,
							"size": 2,
							"left": 277,
							"width": 84,
							"borderradius": 0,
							"position": 1,
							"height": 21
						},
						"shopname": {
							"center": 1,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "酒吧名称",
							"url": "",
							
							"top": 77,
							"size": 18,
							"left": 36,
							"width": 301,
							"borderradius": 0,
							"position": 1,
							"height": 23,
							"isflag": true,
						},
						"logo": {
							"bgcolor": "#FFFFFF",
							"top": 17,
							"size": 1,
							"left": 168,
							"width": 48,
							"borderradius": 800,
							"titlecolor": "#FFFFFF",
							"position": 2,
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/4f99bb1a0d504c54ad93dc8e3a698b99.jpg",
							"height": 48,
							"isflag": true,
						},
						"deskname": {
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "桌台名称",
							"url": "",
							
							"top": 141,
							"size": 18,
							"left": 29,
							"width": 86,
							"borderradius": 0,
							"position": 1,
							"height": 22
						},
						"map": {
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/18634dd39dcd4d5c8c5d817c5fc92294.png",
							"bgcolor": "#FFFFFF",
							"top": 16,
							"size": 1,
							"left": 30,
							"width": 32,
							"borderradius": 60,
							"position": 2,
							"height": 32
						},
						"notice": {
							"center": 1,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "商家介绍",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 105,
							"size": 1,
							"left": 0,
							"width": 369,
							"borderradius": 0,
							"position": 1,
							"height": 20,
							"isflag": true,
						}
					},
					"options": {
						"bgcolor": "#EDA50A",
						"top": 0,
						"left": 0,
						"styleid": "s2",
						"paddingbottom": 0,
						"marginbottom": 0,
						"marginpage": 0,
						"borderradius": 0,
						"paddingtop": 0,
						"margintop": 0,
						"height": 179
					},
					"name": "2.店铺信息",
					"type": "shop_info_nav"

				},
				styleitem3: {
					"data": {
						"linename": {
							"isflag": false,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "线路>>",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 85,
							"size": 2,
							"left": 32,
							"width": 80,
							"borderradius": 0,
							"position": 1,
							"height": 40,
							"center": 1,
						},
						"headimg": {
							"bgcolor": "#F7420B",
							"top": 0,
							"size": 1,
							"left": 0,
							"width": 100,
							"borderradius": 354,
							"titlecolor": "#FFFFFF",
							"position": 2,
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202310/e2ebb5194b09485da2d084c629a46834.jpg?key=123456",
							"height": 180,
							"isflag": true,
						},
						"phone": {
							"center": 1,
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/6ca20b54cd7e469ca5d6c38fe58377f6.png",
							"bgcolor": "#FFFFFF",
							"top": 128,
							"size": 1,
							"left": 321,
							"width": 32,
							"borderradius": 60,
							"position": 2,
							"height": 32
						},
						"more": {
							"center": 1,
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "更多门店>>",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 133,
							"size": 2,
							"left": 157,
							"width": 80,
							"borderradius": 0,
							"position": 1,
							"height": 40
						},
						"shopname": {
							
							"top": 18,
							"size": 18,
							"left": 90,
							"width": 242,
							"borderradius": 0,
							"titlecolor": "#FFFFFF",
							"position": 1,
							"bold": 1,
							"title": "酒吧名称",
							"url": "",
							"height": 25,
							"isflag": true,
						},
						"logo": {
							"bgcolor": "#FFFFFF",
							"top": 18,
							"size": 1,
							"left": 28,
							"width": 48,
							"borderradius": 60,
							"titlecolor": "#FFFFFF",
							"position": 2,
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202310/4d730439eecd4404b7869ed716ea7558.jpg?key=123456",
							"height": 48,
							"isflag": true,
						},
						"deskname": {
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "桌台名称",
							"url": "",
							
							"top": 12,
							"size": 18,
							"left": 279,
							"width": 242,
							"borderradius": 0,
							"position": 1,
							"height": 25
						},
						"map": {
							"isflag": true,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "",
							"url": "https://gzh.dxerp.net/dxgzh/1692416990172626946/202309/18634dd39dcd4d5c8c5d817c5fc92294.png",
							"bgcolor": "#FFFFFF",
							"top": 125,
							"size": 1,
							"left": 30,
							"width": 32,
							"borderradius": 60,
							"position": 2,
							"height": 32
						},
						"notice": {
							"bgcolor": "#FFFFFF",
							"top": 49,
							"size": 1,
							"left": 90,
							"width": 263,
							"borderradius": 0,
							"titlecolor": "#FFFFFF",
							"position": 1,
							"bold": 1,
							"title": "商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍商家介绍",
							"url": "",
							"height": 60,
							"isflag": true,
						}
					},
					"options": {
						"bgcolor": "#FFFFFF",
						"top": 0,
						"left": 0,
						"styleid": "s3",
						"paddingbottom": 0,
						"marginbottom": 0,
						"marginpage": 0,
						"borderradius": 0,
						"paddingtop": 0,
						"margintop": 0,
						"height": 179
					},
					"name": "3.店铺信息",
					"type": "shop_info_nav",
					"boxid": ""
				},


			};
		},

		created() {
			
			this.list = this.data;
			if (this.list.options != undefined) {
				let styleid = this.list.options.styleid
				let f = this.stylelist.find(f => f.id == styleid)
				if (f != undefined) {
					this.listbox = f.listbox
				}
				
			}
		},
		mounted() {},

		methods: {
			selectItemHandler(param) {
				let type = param.dragkey;
				let boxitem = this.listbox.find(t => {
					return t.type == type
				});
				if (boxitem != undefined) {
					this.selbox = boxitem;
					if (this.list.data[type] != undefined) {
						
						this.item = this.list.data[type]

						
					} else {
						
						if (this.styleitem1.data[type] != undefined) {
							this.$set(this.list.data, type, this.styleitem1.data[type])
							this.item = this.list.data[type]
							
						}
					}
				}


			},
			funchu(item, bold) {
				item.bold = bold
				
			},
			funcenter(item, center) {
				item.center = center
				
			},
			funflag(item, isshow) {
				item.isflag = isshow
				
			},
			reset(key) {
				this.$set(this.list.options, key, '');
			},
			resetimg(key) {
				this.item.url = '';
				this.$set(this.list.data, this.selbox.type, this.item);
			},
			resetbg(key) {
				this.$delete(this.list.data.headimg, 'url', key)

				//this.list.data.headimg.url = '';
			},
			resetitem(key) {
				this.item[key] = ''
				//this.$delete(this.item, key)
			},
			funtoolbox(boxitem, index) {
				
				let type = boxitem.type;
				this.selbox = boxitem;
				if (this.list.data[type] != undefined) {
					
					this.item = this.list.data[type]
					this.item.type = type
					
				} else {
					
					if (this.styleitem1.data[type] != undefined) {
						this.$set(this.list.data, type, this.styleitem1.data[type])
						this.item = this.list.data[type]
						this.item.type = type

						
					}
				}
			},
			funMore() {
				this.moreShow = !this.moreShow
			},
			funsetlogo(item){
				item.url = ''
			},
			funaddbgimg() {
				this.$refs["selImgs"].funShow();
			},
			selImgsresult(imgitem) {
				
				this.item.url = imgitem.url;
				this.$set(this.list.data, this.selbox.type, this.item);

				// if (this.selzt == 'logo') {

				// } else if (this.selzt == 'bgimg') {
				// 	// this.$set(this.list.options, 'bgimg', imgitem.url);
				// 	this.$set(this.list.data.headimg, 'url', imgitem.url);
				// }
			},
			funStyle(item) {
				
				//this.currIndex = index
				let list = {}
				if (item.id == "s1") {
					list = JSON.parse(JSON.stringify(this.styleitem1))
					//this.$set(this.list, 'data', this.list);
				} else if (item.id == "s2") {
					
					list = JSON.parse(JSON.stringify(this.styleitem2))
					//this.$set(this.list, 'data', this.list);
				} else if (item.id == "s3") {
					list = JSON.parse(JSON.stringify(this.styleitem3))
					//this.$set(this.list, 'data', this.list);
				}


				let f = this.stylelist.find(f => f.id == list.options.styleid)
				if (f != undefined) {
					this.listbox = f.listbox
				}
				// this.list = list
				// this.list.options = list.options


				console.log(list, 'list_tmp');
				console.log(this.list.data,'this.list.data.style');

				this.list.data.headimg.bgcolor = list.data.headimg.bgcolor;
				this.list.data.headimg.top = list.data.headimg.top;
				this.list.data.headimg.size = list.data.headimg.size;
				this.list.data.headimg.left = list.data.headimg.left;
				this.list.data.headimg.width = list.data.headimg.width;
				this.list.data.headimg.borderradius = list.data.headimg.borderradius;
				this.list.data.headimg.titlecolor = list.data.headimg.titlecolor;
				this.list.data.headimg.position = list.data.headimg.position;
				this.list.data.headimg.bold = list.data.headimg.bold;
				this.list.data.headimg.title = list.data.headimg.title;
				this.list.data.headimg.url = list.data.headimg.url;
				this.list.data.headimg.height = list.data.headimg.height;

				this.list.data.phone.center = list.data.phone.center;
				this.list.data.phone.isflag = list.data.phone.isflag;
				this.list.data.phone.titlecolor = list.data.phone.titlecolor;
				this.list.data.phone.bold = list.data.phone.bold;
				this.list.data.phone.title = list.data.phone.title;
				this.list.data.phone.url = list.data.phone.url;
				this.list.data.phone.bgcolor = list.data.phone.bgcolor;
				this.list.data.phone.top = list.data.phone.top;
				this.list.data.phone.size = list.data.phone.size;
				this.list.data.phone.left = list.data.phone.left;
				this.list.data.phone.width = list.data.phone.width;
				this.list.data.phone.borderradius = list.data.phone.borderradius;
				this.list.data.phone.position = list.data.phone.position;
				this.list.data.phone.height = list.data.phone.height;

				this.list.data.more.center = list.data.more.center;
				this.list.data.more.isflag = list.data.more.isflag;
				this.list.data.more.titlecolor = list.data.more.titlecolor;
				this.list.data.more.bold = list.data.more.bold;
				this.list.data.more.title = list.data.more.title;
				this.list.data.more.url = list.data.more.url;
				this.list.data.more.bgcolor = list.data.more.bgcolor;
				this.list.data.more.top = list.data.more.top;
				this.list.data.more.size = list.data.more.size;
				this.list.data.more.left = list.data.more.left;
				this.list.data.more.width = list.data.more.width;
				this.list.data.more.borderradius = list.data.more.borderradius;
				this.list.data.more.position = list.data.more.position;
				this.list.data.more.height = list.data.more.height;
				if(this.list.data.linename == undefined){
					this.list.data.linename = {
							"isflag": false,
							"titlecolor": "#FFFFFF",
							"bold": 1,
							"title": "线路>>",
							"url": "",
							"bgcolor": "#FFFFFF",
							"top": 85,
							"size": 2,
							"left": 32,
							"width": 80,
							"borderradius": 0,
							"position": 1,
							"height": 40,
							"center": 1,
					}
				}else{
					this.list.data.linename.isflag = list.data.linename.isflag;
					this.list.data.linename.titlecolor = list.data.linename.titlecolor;
					this.list.data.linename.bold = list.data.linename.bold;
					this.list.data.linename.title = list.data.linename.title;
					this.list.data.linename.url = list.data.linename.url;
					this.list.data.linename.bgcolor = list.data.linename.bgcolor;
					this.list.data.linename.top = list.data.linename.top;
					this.list.data.linename.size = list.data.linename.size;
					this.list.data.linename.left = list.data.linename.left;
					this.list.data.linename.width = list.data.linename.width;
					this.list.data.linename.borderradius = list.data.linename.borderradius;
					this.list.data.linename.position = list.data.linename.position;
					this.list.data.linename.height = list.data.linename.height;
					this.list.data.linename.center = list.data.linename.center;
				}
				

				
				this.list.data.shopname.top = list.data.shopname.top;
				this.list.data.shopname.size = list.data.shopname.size;
				this.list.data.shopname.left = list.data.shopname.left;
				this.list.data.shopname.width = list.data.shopname.width;
				this.list.data.shopname.borderradius = list.data.shopname.borderradius;
				this.list.data.shopname.titlecolor = list.data.shopname.titlecolor;
				this.list.data.shopname.position = list.data.shopname.position;
				this.list.data.shopname.bold = list.data.shopname.bold;
				this.list.data.shopname.title = list.data.shopname.title;
				this.list.data.shopname.url = list.data.shopname.url;
				this.list.data.shopname.height = list.data.shopname.height;
				this.list.data.shopname.center = list.data.shopname.center;

				if(this.list.data.deskname == undefined){
					this.list.data.deskname ={
						"isflag": true,
						"titlecolor": "#FFFFFF",
						"bold": 1,
						"title": "桌台名称",
						"url": "",
						"top": 12,
						"size": 18,
						"left": 279,
						"width": 242,
						"borderradius": 0,
						"position": 1,
						"height": 25
					}
				}else{
					this.list.data.deskname.top = list.data.deskname.top;
					this.list.data.deskname.size = list.data.deskname.size;
					this.list.data.deskname.left = list.data.deskname.left;
					this.list.data.deskname.width = list.data.deskname.width;
					this.list.data.deskname.borderradius = list.data.deskname.borderradius;
					this.list.data.deskname.titlecolor = list.data.deskname.titlecolor;
					this.list.data.deskname.position = list.data.deskname.position;
					this.list.data.deskname.bold = list.data.deskname.bold;
					this.list.data.deskname.title = list.data.deskname.title;
					this.list.data.deskname.url = list.data.deskname.url;
					this.list.data.deskname.height = list.data.deskname.height;
					this.list.data.deskname.center = list.data.deskname.center;
					this.list.data.deskname.isflag = list.data.deskname.isflag;
				}
				

				this.list.data.logo.bgcolor = list.data.logo.bgcolor;
				this.list.data.logo.top = list.data.logo.top;
				this.list.data.logo.size = list.data.logo.size;
				this.list.data.logo.left = list.data.logo.left;
				this.list.data.logo.width = list.data.logo.width;
				this.list.data.logo.borderradius = list.data.logo.borderradius;
				this.list.data.logo.titlecolor = list.data.logo.titlecolor;
				this.list.data.logo.position = list.data.logo.position;
				this.list.data.logo.bold = list.data.logo.bold;
				this.list.data.logo.title = list.data.logo.title;
				this.list.data.logo.url = list.data.logo.url;
				this.list.data.logo.height = list.data.logo.height;

				this.list.data.map.isflag = list.data.map.isflag;
				this.list.data.map.titlecolor = list.data.map.titlecolor;
				this.list.data.map.bold = list.data.map.bold;
				this.list.data.map.title = list.data.map.title;
				this.list.data.map.url = list.data.map.url;
				this.list.data.map.bgcolor = list.data.map.bgcolor;
				this.list.data.map.top = list.data.map.top;
				this.list.data.map.size = list.data.map.size;
				this.list.data.map.left = list.data.map.left;
				this.list.data.map.width = list.data.map.width;
				this.list.data.map.borderradius = list.data.map.borderradius;
				this.list.data.map.position = list.data.map.position;
				this.list.data.map.height = list.data.map.height;

				this.list.data.notice.bgcolor = list.data.notice.bgcolor;
				this.list.data.notice.top = list.data.notice.top;
				this.list.data.notice.size = list.data.notice.size;
				this.list.data.notice.left = list.data.notice.left;
				this.list.data.notice.width = list.data.notice.width;
				this.list.data.notice.borderradius = list.data.notice.borderradius;
				this.list.data.notice.titlecolor = list.data.notice.titlecolor;
				this.list.data.notice.position = list.data.notice.position;
				this.list.data.notice.bold = list.data.notice.bold;
				this.list.data.notice.title = list.data.notice.title;
				this.list.data.notice.url = list.data.notice.url;
				this.list.data.notice.height = list.data.notice.height;
				this.list.data.notice.center = list.data.notice.center;
				this.list.data.notice.isflag = list.data.notice.isflag;

				this.list.options.bgcolor = list.options.bgcolor
				this.list.options.borderradius = list.options.borderradius
				this.list.options.height = list.options.height
				this.list.options.left = list.options.left
				this.list.options.marginbottom = list.options.marginbottom
				this.list.options.marginpage = list.options.marginpage
				this.list.options.margintop = list.options.margintop
				this.list.options.paddingbottom = list.options.paddingbottom
				this.list.options.paddingtop = list.options.paddingtop
				this.list.options.styleid = list.options.styleid
				this.list.options.top = list.options.top

				//  this.$set(this.list, 'data', list.data);
				// this.$set(this.list, 'options', list.options);
				// this.$forceUpdate()
			}
		},
	};
</script>

<style lang="less" scoped>
	#notice {}

	.tips {
		margin-bottom: 20px;
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.list1 {
		margin-bottom: 20px;

		.title {
			margin-bottom: 10px;
		}
	}

	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #969799;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
		}
	}

	.full {
		flex-grow: 1;
	}
	.titles{
		display: flex;
		justify-content: space-between;
	}
	.tb {
		width: 50px;
		height: 50px;
		margin-top: 10px;

		img {
			width: 100%;
			height: 100%;
		}
	}

	.row {
		display: flex;

	}

	.boxdisp {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		margin-right: 20px;
	}

	.toolbox:hover span {
		cursor: pointer;
	}

	.toolbox {
		width: 100px;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		border:1px solid #ccc;
	}
	.toolbox :last-child{
		
	}
	.sel {
		border: 1px solid #00aaff;
	}

	.stylebox {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	.styleitem {}

	.active {
		border: 1px solid #00aaff;
	}

	.bbox {
		cursor: pointer;
		margin-right: 5px;
		width: 32px;
		height: 30px;
		border-radius: 4px;
		background-color: #DEDFE1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;
	}

	.blodimg {
		width: 20px;
		height: 20px;
	}

	.blodimg2 {
		width: 25px;
		height: 25px;

	}

	.list_style {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: start;
		margin: 10px auto;

		.item_style {
			cursor: pointer;
			height: 60px;
			width: 120px;

			border-radius: 5px;
			margin: 5px;
			text-align: center;

			img {
				width: 100%;
				height: 100%;
				border-radius: 5px;
			}
		}

		.sel {
			border: 2px solid #7FC0F6;
		}

		.normal {
			border: 1px solid #ccc;
		}
	}
</style>