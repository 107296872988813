<template>
	<div class="" v-if="editFormVisible">
		<!-- 编辑界面 -->
		<p class="title">{{title}}</p>
		<!-- <el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'> -->
			<el-form label-width="150px" ref="editForm" :model="editForm">
				<el-form-item label="小程序名称" prop="mpname">
					<el-input size="small" v-model="editForm.mpname" auto-complete="off"
						placeholder="请输入小程序名称"></el-input>
				</el-form-item>
				<el-form-item label="企业名称" prop="companyname">
					<el-input size="small" v-model="editForm.companyname" auto-complete="off"
						placeholder="请输入企业名称"></el-input>
				</el-form-item>
				<el-form-item label="企业代码" prop="companyno">
					<el-input size="small" v-model="editForm.companyno" auto-complete="off"
						placeholder="请输入企业代码"></el-input>
				</el-form-item>
				<el-form-item label="企业代码类型" prop="companytype">
					<!-- <el-input size="small" v-model="editForm.companytype" auto-complete="off"
						placeholder="请输入企业代码类型"></el-input> -->
						<el-select v-model="editForm.companytype" placeholder="请选择企业代码类型" size="small">
							<el-option v-for="item in companylist" :key="item.id" :label="item.mc" :value="item.id">
							</el-option>
						</el-select>
				</el-form-item>
				<el-form-item label="法人姓名" prop="personaname">
					<el-input size="small" v-model="editForm.personaname" auto-complete="off"
						placeholder="请输入法人姓名"></el-input>
				</el-form-item>
				<el-form-item label="法人微信号" prop="personawechat">
					<el-input size="small" v-model="editForm.personawechat" auto-complete="off"
						placeholder="请输入法人微信号"></el-input>
				</el-form-item>
				<el-form-item label="第三方联系电话" prop="companytel">
					<el-input size="small" v-model="editForm.companytel" auto-complete="off"
						placeholder="请输入第三方联系电话"></el-input>
				</el-form-item>
				<el-form-item label="头像" prop="headlogo">
					<div style="display: flex;align-items: center;">
						<el-image style="width: 100px; height: 100px" :src="editForm.headlogo" fit="contain"></el-image>
						<el-button type="primary" @click="funUpload('headlogo')" size="mini">上传</el-button>
					</div>
				</el-form-item>
				<el-form-item label="营业执照" prop="comppic">
					<div style="display: flex;align-items: center;">
						<el-image style="width: 100px; height: 100px" :src="editForm.comppic" fit="contain"></el-image>
						<el-button type="primary" @click="funUpload('comppic')" size="mini">上传</el-button>
					</div>
				</el-form-item>
				<el-form-item label="许可证图片" prop="xkzpic">
					<div style="display: flex;align-items: center;">
						<el-image style="width: 100px; height: 100px" :src="editForm.xkzpic" fit="contain"></el-image>
						<el-button type="primary" @click="funUpload('xkzpic')" size="mini">上传</el-button>
					</div>
				</el-form-item>
				<el-form-item label="许可证名称" prop="xkzmc">
					<el-input size="small" v-model="editForm.xkzmc" auto-complete="off"
						placeholder="请输入许可证名称"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='funClose'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title"
					@click="submitForm('editForm')">保存
				</el-button>
			</div>
		<!-- </el-dialog> -->
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import selImgs from "@/components/system/selImgs";
	import accountApi from '../../../api/cus/account.js'
	export default {
		components: {
			selImgs
		},
		data() {
			return {
				title: '',
				loading: false,
				editForm: {
					"mpid": 0,
					"unitno": 0,
					"mpname": "",
					"companyname": "",
					"companyno": "",
					"companytype": "",
					"personaname": "",
					"personawechat": "",
					"companytel": "",
					"headlogo": "",
					"comppic": "",
					"xkzpic": "",
					"xkzmc": ""
				},
				companylist:[
					{id:1,mc:'统一社会信用代码(18 位)'},
					{id:2,mc:'组织机构代码(9 位 xxxxxxxx-x)'},
					{id:3,mc:'营业执照注册号(15 位)'},
				],
				imgtype: "",
				editFormVisible: false
			}
		},
		methods: {
			funShow(title) {
				this.title = title
				this.editFormVisible = true
			},
			funClose() {
				this.editFormVisible = false
				this.$emit('funCloseReslut',{zt:'wxxcxreg'})
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				if (this.imgtype == 'comppic') {
					this.editForm.comppic = imgitem.url
				} else if (this.imgtype == 'headlogo') {
					this.editForm.headlogo = imgitem.url
				} else {
					this.editForm.xkzpic = imgitem.url
				}

			},
			submitForm() {
				let param = {
					"mpid": this.editForm.mpid,
					"unitno": this.editForm.unitno,
					"mpname": this.editForm.mpname,
					"companyname": this.editForm.companyname,
					"companyno": this.editForm.companyno,
					"companytype": this.editForm.companytype,
					"personaname": this.editForm.personaname,
					"personawechat": this.editForm.personawechat,
					"companytel": this.editForm.companytel,
					"headlogo": this.editForm.headlogo,
					"comppic": this.editForm.comppic,
					"xkzpic": this.editForm.xkzpic,
					"xkzmc": this.editForm.xkzmc
				}
				console.log(param, 'addwxappreg.param');
				accountApi.addwxappreg(param, res => {
					console.log(res, 'addwxappreg.res');
					this.editFormVisible = false
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: res.msg
						})
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			funUpload(type) {
				this.imgtype = type
				this.$refs["selImgs"].funShow()
			}
		}
	}
</script>

<style scoped>
	.title {
		margin: 10px 0;
		text-align: center;
	}
	
	.dialog-footer {
		text-align: center;
	}
</style>