<template>
	<!--台位图-->
	<div class="img">
		<div class="imgcont" :style="{
         'margin-left':marginpage,
          'margin-right':marginpage,
		  'width' : width,
		  'height' : height
      }">
			<div class="bgbox" v-if="list.options  && list.options.bgimg && list.options.bgimg.length > 0">
				<img :src="(list.options.bgimg && list.options.bgimg.length > 0) ? list.options.bgimg : ''"
					class="bgimg" />
			</div>

			<div class="list">
				<dragBox v-for="(item,index) in list.data" :key="item.cid" :data="item" :index="index"
					@deleteHandle="deleteHandle" @selectHandler="selectHandler" :allowdel="0">
					<!-- <div v-if="item.use.bgimg != undefined && item.use.bgimg.length > 0" :style="{
						 height:(item.height - 2) + 'px',
						 width:(item.width -2) + 'px',
						 top:item.top + 'px',
						 left:item.left + 'px',
						}">
						<img :src="item.use.bgimg" class="deskimg" />
					</div>
					<div v-else>
						<div v-if="list.options.use.bgimg != undefined && list.options.use.bgimg.length > 0" :style="{
						 height:(item.height - 2) + 'px',
						 width:(item.width -2) + 'px',
						 top:item.top + 'px',
						 left:item.left + 'px',
						}">
							<img :src="list.options.use.bgimg" class="deskimg" />
						</div>

						<div class="item" :style="{
											 height:(item.height - 2) + 'px',
											 width:(item.width -2) + 'px',
											 top:item.top + 'px',
											 left:item.left + 'px',
											 'border-radius':item.borderradius +'px',
											 'color':list.options.use.titlecolor,
											 'background':list.options.use.bgcolor,
											}" v-else>
							{{list.options.use.showtitle == 1 ?  item.title  : ''}}
						</div>
					</div> -->
					<div v-if="item.isdiy != undefined && item.isdiy == 1" :style="{
						 height:(item.height - 2) + 'px',
						 width:(item.width -2) + 'px',
						 top:item.top + 'px',
						 left:item.left + 'px',
						}">
						<img :src="item.use.bgimg" class="deskimg" />
					</div>
					<div v-else>
						<div  v-if="list.options.use.bgimg != undefined && list.options.use.bgimg.length > 0" :style="{
						 height:(item.height - 2) + 'px',
						 width:(item.width -2) + 'px',
						 top:item.top + 'px',
						 left:item.left + 'px',
						}">
							<img :src="list.options.use.bgimg" class="deskimg" />
							<div :style="{
								height:(item.height - 2) + 'px',
								width:(item.width -2) + 'px',
								'color':list.options.use.titlecolor,
							}" class="deskimgmc">{{list.options.showtitle == 1 ?  item.title  : ''}}</div>
						</div>
					
						<div class="item" :style="{
											 height:(item.height - 2) + 'px',
											 width:(item.width -2) + 'px',
											 top:item.top + 'px',
											 left:item.left + 'px',
											 'border-radius':item.borderradius +'px',
											 'color':list.options.use.titlecolor,
											 'background':list.options.use.bgcolor,
											}" v-else>
											
							{{list.options.showtitle == 1 ?  item.title  : ''}}
						</div>
					</div>

				</dragBox>

			</div>

		</div>



		<!-- <el-image style="width: 100%" :src="url"></el-image> -->
	</div>
</template>

<script>
	import dragBox from "@/components/applet/toolbox/dragbox.vue";

	export default {
		name: "image_row",
		components: {
			dragBox
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				list: "",
			};
		},

		mounted() {
			this.list = this.data;
			
		},
		computed: {
			height() {
				if (this.list.options) {
					if (this.list.options.height && this.list.options.height > 0)
						return (this.list.options.height / 2) + 'px';
				}
			},
			width() {
				if (this.list.options) {
					if (this.list.options.width && this.list.options.width > 0)
						return (this.list.options.width / 2) + 'px';
				}
			},
			margintop() {
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + 'px';
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
						return this.list.options.marginbottom + 'px';
				}
			},
			marginpage() {
				if (this.list.options) {
					if (this.list.options.marginpage && this.list.options.marginpage > 0)
						return this.list.options.marginpage + 'px';
				}
			},
			borderradius() {
				if (this.list.options) {
					if (this.list.options.borderradius && this.list.options.borderradius > 0)
						return this.list.options.borderradius + 'px';
				}
			}
		},

		methods: {
			deleteHandle(index) {
				this.$delete(this.list.data, index);
			},
			selectHandler(param) {
				this.$emit('selectItemHandler', {
					type: 'image_desk',
					data: param
				})
			},
		},
	};
</script>

<style lang="less" scoped>
	.img {
		width: 100%;
		text-align: center;
		display: flex;
		flex-wrap: wrap;

		.imgcont {
			position: relative;

			.bgbox {
				width: 100%;
			}
		}

		.bgimg {
			width: 100%;
		}

		.list {
			position: absolute;
			width: 100%;
			left: 0;
			top: 0;

			.item {

				border: 1px dashed #ccc;
				opacity: 0.6;
				align-items: center;
				display: flex;
				justify-content: center;
				font-size: 8pt;
				overflow: hidden;
			}
		}
	}

	.empty {
		width: 400px;
		// text-align: center;
		// display: flex;
		// flex-wrap: wrap;
	}

	.deskimg {
		width: 100%;
		height: 100%;
	}
	
	.deskimgmc{
		position: absolute;
		top: 0;
		left: 0;
		text-align: center;
		line-height: 1;
		font-size: 9px;
	}
</style>