<template>
  <div>
    <!-- 编辑导航广告 -->
    <el-button type="text" @click="add">添加推广</el-button>
    <!-- 列表 -->
    <div class="list">
      <!-- 文本 -->
      <div class="text" v-for="(item, index) in list.data" :key="index">
        <div>
          <el-form ref="form" :model="item" label-width="80px" size="mini">
            <!-- 添加图片 -->
            <el-form-item label="图片">
              <el-upload
                class="upload-demo"
                action="https://jsonplaceholder.typicode.com/posts/"
                multiple
                :on-success="success"
                :limit="1"
              >
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip" class="el-upload__tip">
                  只能上传jpg/png文件，且不超过500kb
                </div>
              </el-upload>
            </el-form-item>
            <el-form-item label="文本">
              <el-input v-model="item.title"></el-input>
            </el-form-item>
            <el-form-item label="跳转页面">
              <el-input v-model="item.name"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menuad",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      form: "",
      list: [],
    };
  },

  mounted() {},
  created() {
    this.list = this.data;
  },
  methods: {
    add() {
      let obj = {};
      obj.title = "";
      obj.img = "";
      obj.lj = "";
      this.list.data.push(obj);
    },
    success(e,file){
        console.log(e,file);
    }
  },
};
</script>

<style lang="less" scoped>
.list {
  width: 100%;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .text {
    width: 100%;
    margin: 10px;
    margin-bottom: 10px;
    .pic {
      width: 60px;
      height: 60px;
      border: 1px solid black;
      font-size: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    div {
      // margin:  10px 0;
    }
  }
}
</style>