<template>
  <div id="store">
    <div class="list">
      <div><i class="el-icon-s-shop"></i> 伙伴科技</div>
      <div>{{list.options.text||'进入店铺'}}<i class="el-icon-arrow-right"></i></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "小程序模块话Store",
 props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
        list:""
    };
  },

  created() {
      this.list=this.data
  },

  methods: {},
};
</script>

<style lang="less" scoped>
#store {
  height: 50px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
.list {
  width: 100%;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}
</style>