<template>
	<!-- 组件库 -->
	<section class="toolboxleft">
		<div style="display:flex ;">
			<div style="width: 370px;">
				<div v-if="currId == 0">
					<div class="nav">
						<div class="nav-item" v-for="(item,index) in navlist" :key="index"
							@click="funNavClick(item,index)">
							<div class="nav_text" :class="currIndex == index ? 'sel' : 'nom'">{{item.text}}</div>

						</div>
					</div>

					<div v-if="currIndex == 0">
						<div class="column">
							<h4 @click="funsjxClick">
								<img src="../../../assets/design/sjx.png" />
								基础组件
							</h4>
							<ul>
								<!-- 添加自定义属性 data-type -->
								<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
									:key="index + 1" v-if="val.cls === '基础组件'">
									<div class="icon">
										<el-image :src="val.icon" style="width:100%" />
									</div>
									<p>{{ val.name }}</p>
								</li>
							</ul>
						</div>
						<div class="column">
							<h4><img src="../../../assets/design/sjx.png" />商品组件</h4>
							<ul>
								<!-- 添加自定义属性 data-type -->
								<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
									:key="index + 1" v-if="val.cls === '商品组件'">
									<div class="icon">
										<el-image :src="val.icon" style="width:100%" />
									</div>
									<p>{{ val.name }}</p>
								</li>
							</ul>
						</div>

						<!-- <div class="column">
								<h4>页脚导航</h4>
								<ul @dragstart="dragStart" @dragend="dragEnd">
									<li v-for="(val, key, index) in typeList" draggable :data-type="key" :key="index + 1"
										v-if="val.cls === '导航'">
										<div class="icon">
											<el-image :src="val.icon" style="width:100%" />
										</div>
										<p>{{ val.name }}</p>
									</li>
								</ul>
							</div> -->

						<div class="column">
							<h4><img src="../../../assets/design/sjx.png" />店铺组件</h4>
							<ul>
								<!-- 添加自定义属性 data-type -->
								<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
									:key="index + 1" v-if="val.cls === '店铺组件'">
									<div class="icon">
										<el-image :src="val.icon" style="width:100%" />
									</div>
									<p>{{ val.name }}</p>
								</li>
							</ul>
						</div>

						<div class="column">
							<h4><img src="../../../assets/design/sjx.png" />营销</h4>
							<ul>
								<!-- 添加自定义属性 data-type -->
								<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
									:key="index + 1" v-if="val.cls === '营销活动'">
									<div class="icon">
										<el-image :src="val.icon" style="width:100%" />
									</div>
									<p>{{ val.name }}</p>
								</li>
							</ul>
						</div>

						<div class="column">
							<h4><img src="../../../assets/design/sjx.png" />会员</h4>
							<ul>
								<!-- 添加自定义属性 data-type -->
								<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
									:key="index + 1" v-if="val.cls === '会员'">
									<div class="icon">
										<el-image :src="val.icon" style="width:100%" />
									</div>
									<p>{{ val.name }}</p>
								</li>
							</ul>
						</div>

					</div>


					<!--标题-->
					<div class="column" v-if="currIndex == 1">
						<h4 @click="funsjxClick">
							<img src="../../../assets/design/sjx.png" />
							基础组件
						</h4>
						<ul>
							<!-- 添加自定义属性 data-type -->
							<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
								:key="index + 1" v-if="val.cls === '基础组件'">
								<div class="icon">
									<el-image :src="val.icon" style="width:100%" />
								</div>
								<p>{{ val.name }}</p>
							</li>
						</ul>
					</div>
					<div class="column" v-if="currIndex == 1">
						<h4><img src="../../../assets/design/sjx.png" />商品组件</h4>
						<ul>
							<!-- 添加自定义属性 data-type -->
							<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
								:key="index + 1" v-if="val.cls === '商品组件'">
								<div class="icon">
									<el-image :src="val.icon" style="width:100%" />
								</div>
								<p>{{ val.name }}</p>
							</li>
						</ul>
					</div>

					<!-- <div class="column">
							<h4>页脚导航</h4>
							<ul @dragstart="dragStart" @dragend="dragEnd">
								<li v-for="(val, key, index) in typeList" draggable :data-type="key" :key="index + 1"
									v-if="val.cls === '导航'">
									<div class="icon">
										<el-image :src="val.icon" style="width:100%" />
									</div>
									<p>{{ val.name }}</p>
								</li>
							</ul>
						</div> -->

					<div class="column" v-if="currIndex == 2">
						<h4><img src="../../../assets/design/sjx.png" />店铺组件</h4>
						<ul>
							<!-- 添加自定义属性 data-type -->
							<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
								:key="index + 1" v-if="val.cls === '店铺组件'">
								<div class="icon">
									<el-image :src="val.icon" style="width:100%" />
								</div>
								<p>{{ val.name }}</p>
							</li>
						</ul>
					</div>

					<div class="column" v-if="currIndex == 3">
						<h4><img src="../../../assets/design/sjx.png" />营销</h4>
						<ul>
							<!-- 添加自定义属性 data-type -->
							<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
								:key="index + 1" v-if="val.cls === '营销活动'">
								<div class="icon">
									<el-image :src="val.icon" style="width:100%" />
								</div>
								<p>{{ val.name }}</p>
							</li>
						</ul>
					</div>

					<div class="column" v-if="currIndex == 4">
						<h4><img src="../../../assets/design/sjx.png" />会员</h4>
						<ul>
							<!-- 添加自定义属性 data-type -->
							<li v-for="(val, key, index) in typeList" @click.stop="funaddbox(val)" :data-type="key"
								:key="index + 1" v-if="val.cls === '会员'">
								<div class="icon">
									<el-image :src="val.icon" style="width:100%" />
								</div>
								<p>{{ val.name }}</p>
							</li>
						</ul>
					</div>
				</div>
				<div v-if="currId == 3">
					<!-- 组件库 -->
					<section class="left">
						<div class="moban">
							<div class="moban-search">
								<div class="moban-img">
									<i class="el-icon-search"></i>
								</div>
								<input type="text" placeholder="搜索模版" />
							</div>
							<!-- <div class="moban-menu">
								<div class="moban-item" :class="mbIndex == index ? 'sel' : 'nom'"
									v-for="(item,index) in menulist" :key="index" @click="funmenuClick(item,index)">
									{{item.text}}
								</div>
							</div> -->
							<div class="moban-cont">
								<div class="moban-item" v-for="(item,index) in marketlist" @click="funseltemp(item)"
									:key="index">
									<img :src="item.logo" />
									<div style="white-space: nowrap;text-align: center;color: #474747;">{{item.tmpname}}
									</div>
								</div>
								<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
							</div>

						</div>

					</section>
				</div>
				<div v-if="currId == 4">
					<!-- 页面 -->
					<!-- <section class="menu-conts">
						<div class="menu-items" :class="pagetype == item.pageno ? 'selected':''"
							v-for="(item,index) in pagelist" :key="index" @click="fungotopage(item)">
							{{ item.pagename }}
						</div>
					</section> -->
				</div>
				<div v-if="currId == 1">
					<!-- 收藏 -->
					<div class="moban">
						<div class="moban-search">
							<div class="moban-img">
								<i class="el-icon-search"></i>
							</div>
							<input type="text" placeholder="搜索模版" v-model="collectInfo.key" />
							<div class="moban-searchbtn" @click="funcollect(1)">搜索</div>
						</div>
						<div class="like-cont">
							<template v-for="(item, index) in collectlist">
								<div :key="index" :class="collectIndex == index ? 'collSel' : 'collnom'"
									class="like-item" @click.stop="funselLike(index)">
									<div style="transform: scaleX(0.95);transform-origin:left top;">
										<div class="itemcont">
											<component :is="typeList[item.type]['com']" :data="item.content">
											</component>
										</div>
									</div>

									<div class="bottom">
										<div class="title">
											{{item.title}}
										</div>
										<div class="islike" v-if="collectIndex == index">
											<span @click.stop="funaddlike(item,index)">应用</span>&nbsp;|&nbsp;<span
												@click.stop="funcancel(item)">取消收藏</span>
										</div>
									</div>
								</div>
							</template>
							<div class="more" v-if="collectInfo.pageall>collectInfo.pageno" @click="funcollectmore">
								加载下一页</div>
							<div class="more" v-else>没有更多的数据</div>
						</div>
					</div>
				</div>
				<div class="" v-if="currId == 5">
					<!-- 风格 -->
					<div class="stitle">风格配色</div>
					<div class="clslist">
						<div class="clsitem" v-for="(item,index) in styleclslist" @click="funselstylecls(item)" :key="index">{{item.mc}}</div>
					</div>
					<div class="main">
						<div class="box" v-for="(item,index) in stylelist_cls" :key="index">

							<div :style="{background:item.style_color}"
								:class="item.style_id==style_id?'curr' : 'cusm'" class="item_cont">
								<!-- <div class="item" v-if="item.style_id == 0" @click="funCustom(item)">
									自定义
								</div> -->
								<!-- <div class="item" v-if="item.style_id == 0" @click="funCustom(item)">
									自定义
								</div> -->
								<div class="item"  :style="{background:item.subject1_bg_color}"
									@click="funselStyle(item)">
									<i class="el-icon-check" v-if="item.style_id==style_id" style="color: #fff;"></i>
								</div>

							</div>
							<!-- <div class="stylemc" :style="{color:item.style_id==style_id?item.subject1_bg_color : ''}">
								{{item.style_name}}
							</div> -->

						</div>
						<div class="boxs" style="display: flex;flex-direction: column;margin-top: 30px;">
							<div>
								<el-tabs type="border-card">
									<el-tab-pane label="主体背景">
										<div class="item">
											<span><span
													style="color:#ff0000">***</span>风格背景颜色</span>{{styleitem.subject1_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.subject1_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('subject1_bg_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span><span
													style="color:#ff0000">***</span>风格字体色</span>{{styleitem.subject1_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.subject1_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('subject1_txt_color')">重置</span>
											</div>
										</div>
										<!-- <div class="item">
											<span><span
													style="color:#ff0000">***</span>设计页背景颜色</span>{{styleitem.subject2_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.subject2_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('subject2_bg_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span><span
													style="color:#ff0000">***</span>设计页字体色</span>{{styleitem.subject2_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.subject2_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('subject2_txt_color')">重置</span>
											</div>
										</div> -->
										<div class="item">
											<span><span style="color:#ff0000">***</span>页面背景色</span>{{styleitem.page_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.page_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('page_bg_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span><span style="color:#ff0000">***</span>页面字体色</span>{{styleitem.page_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.page_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('page_txt_color')">重置</span>
											</div>
										</div>
									</el-tab-pane>
									<el-tab-pane label="页头">
										<div class="item" style="flex-shrink: 0;white-space: nowrap;">
											<span>顶部字体色（白/黑）</span>{{styleitem.page_title_txt_color}}
											<div class="btn">
												<!-- <el-color-picker v-model="styleitem.page_title_txt_color"
														size="medium"></el-color-picker> -->
												<el-radio-group v-model="styleitem.page_title_txt_color" size="mini">
													<el-tooltip  class="item" effect="dark" content="黑色" placement="bottom">
														<el-radio-button label="#000000"><i
																class="el-icon-s-fold"></i></el-radio-button>
													</el-tooltip>
													<el-tooltip class="item" effect="dark" content="白色" placement="bottom">
														<el-radio-button label="#FFFFFF">
															<i class="el-icon-s-operation"></i>
														</el-radio-button>
													</el-tooltip>
												</el-radio-group>
												<!-- <span @click="setkey('page_title_txt_color')">重置</span> -->
											</div>
										</div>
										<div class="item">
											<span><span
													style="color:#ff0000">***</span>顶部背景色</span>{{styleitem.page_title_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.page_title_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('page_title_bg_color')">重置</span>
											</div>
										</div>
									</el-tab-pane>
									
									<el-tab-pane label="组件">
										<div class="item">
											<span><span style="color:#ff0000">***</span>页面前景色</span>{{styleitem.front_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.front_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('front_bg_color')">重置</span>
											</div>
										
										</div>
										<div class="item">
											<span><span
													style="color:#ff0000">***</span>页面前景字体色</span>{{styleitem.front_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.front_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('front_txt_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span>一级菜单栏字体色</span>{{styleitem.title1_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.title1_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('title1_bg_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span>一级菜单栏选中字体色</span>{{styleitem.title1_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.title1_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('title1_txt_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span>二级菜单栏字体色</span>{{styleitem.title2_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.title2_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('title2_bg_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span>二级菜单栏选中字体色</span>{{styleitem.title2_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.title2_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('title2_txt_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span><span
													style="color:#ff0000">***</span>页脚字体色(备注/简介)</span>{{styleitem.text2_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.text2_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('text2_txt_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span><span style="color:#ff0000">***</span>边框色</span>{{styleitem.text3_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.text3_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('text3_bg_color')">重置</span>
											</div>
										</div>
									</el-tab-pane>
									<el-tab-pane label="其他">
										<div class="item">
											<span>销售价格(标题3背景色)</span>{{styleitem.title3_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.title3_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('title3_bg_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span>划线价(标题3字体色)</span>{{styleitem.title3_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.title3_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('title3_txt_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span>数量</span>{{styleitem.text2_bg_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.text2_bg_color"
													size="medium"></el-color-picker><span
													@click="setkey('text2_bg_color')">重置</span>
											</div>
										</div>
										<div class="item">
											<span>总金额</span>{{styleitem.text3_txt_color}}
											<div class="btn">
												<el-color-picker v-model="styleitem.text3_txt_color"
													size="medium"></el-color-picker><span
													@click="setkey('text3_txt_color')">重置</span>
											</div>
										</div>
									</el-tab-pane>
								</el-tabs>
								<span slot="footer" class="dialog-footer">
									<!-- <el-button @click="editstyleVisible = false">取 消</el-button> -->
									<el-button type="primary" class="savebtn" size="medium" @click="funOk">保存</el-button>
								</span>
								<!-- <div class="sline"></div>
								<div class="stitle">功能组件</div>
								
								<div class="sline"></div>
								<div class="stitle">组件</div>
								
								<div class="sline"></div>
								<div class="stitle">主体背景</div>
								
								<div class="sline"></div>
								<div class="stitle">抬头</div> -->
								
							</div>





							<!-- <div class="sel-menu" style="display: flex;">
								<div class="black" :class="selcolor == 'black'?'sel':''" @click="funblack">黑色</div>
								<div class="black" :class="selcolor == 'white'?'sel':''" @click="funwhite">白色</div>
							</div> -->
							<!-- <div class="" @click="moreShow = !moreShow">
								<el-button size="small" type="primary">{{moreShow ?'关闭高级选项':'显示高级选项'}}</el-button>
							</div> -->

						</div>
					</div>
					
				</div>
				<div class="" v-if="currId == 2">
					<!-- 设置 -->
					<div class="main">
						<div class="disp">
							模板id：{{from.tmpid}}
						</div>
						<div class="disp">
							<div>模板名称：</div>
							<div><el-input size="mini" v-model="from.name" class="input"
									style="margin-right: 10px;"></el-input></div><el-button size="mini" type="primary"
								@click="funsavemc">保存</el-button>
						</div>
						<div class="disp">
							LOGO：<el-input size="mini" v-model="from.logo" class="input"
								style="margin-right: 10px;"></el-input>
							<el-button size="mini" type="primary" @click="funupload">上传</el-button>
							<el-button size="mini" type="primary" @click="funupsave">保存</el-button>
						</div>
						<div class="logo">
							<el-image :src="from.logo"></el-image>
						</div>
					</div>
				</div>
			</div>
			<div class="modelist" v-if="currId == 5">
				<div class="modeitem" :style="{background:styleitem.page_bg_color,color:styleitem.page_txt_color}">
					<div class="top-nav"
						:style="{background:styleitem.page_title_bg_color,color:styleitem.page_title_txt_color}">
						<img src="../../../assets/design/topNavBlack.png" />
						<div class="tit">顶部标题</div>
					</div>
					<div class="w250">
						<div class="cloum">
							<div class="right1" :style="{color:styleitem.title1_txt_color}">
								酒水单</div>
							<div class="line" :style="{background:styleitem.title1_txt_color}">
							</div>

						</div>
						<div class="cloum">
							<div class="right1" :style="{color:styleitem.title1_bg_color}">礼券
							</div>
							<div class="line">
							</div>
							<div class="line"></div>
						</div>
						<div class="cloum">
							<div class="right1" :style="{color:styleitem.title1_bg_color}">预定
							</div>
							<div class="line">
							</div>
							<div class="line"></div>
						</div>
					</div>
					<div class="item" :style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}"
						v-for="(item,index) in 4">
						<div class="orderno row">
							<div class="left"
								:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
								已存入
							</div>
							<div class="right">NO.10572</div>

						</div>
						<div class="p30 row">
							<div class="rightbox">
								<div class="spmc">测试存酒</div>
								<div class="sl" :style="{color:styleitem.text2_bg_color}">
									数量：1(整支)</div>
							</div>
							<div class="take">
								<div class="btn less">
									<div class="i"
										:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
										-
									</div>
								</div>
								<div class="val num" :style="{color:styleitem.text2_bg_color}">3</div>
								<div class="btn add">
									<div class="i"
										:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
										+
									</div>
								</div>
							</div>
						</div>
						<div class="bottom row">
							<div class="date" :style="{color:styleitem.text2_txt_color}">有效期至：2024-09-16</div>

						</div>
					</div>
				</div>
				<div class="modeitem" :style="{background:styleitem.page_bg_color,color:styleitem.page_txt_color}">
					<div class="top-nav"
						:style="{background:styleitem.page_title_bg_color,color:styleitem.page_title_txt_color}">
						<img src="../../../assets/design/topNavBlack.png" />
						<div class="tit">顶部标题</div>
					</div>
					<div class="payment-body">
						<div class="title row"
							:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
							<div class="" style="flex-grow: 1;">大新软件</div>
							<div class="" style="margin-right: 10px;">测试桌台 点单明细</div>
						</div>
						<div class="body-top"
							:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
							<div class="top-sp" v-for="(item,index) in 1" :key="index">
								<div class="spcont">
									<!-- <div class="sp-left">
										<image :src="item.logo" mode=""></image>
									</div> -->
									<div class="sp-center">
										<div>券码：435434</div>
										<div class="mc">
											<span></span>
											<span class="txt">特价券</span>
											龙舌兰
										</div>
										<div class="dj">
											<!-- <div class="gmj">{{item.pricebuy | shouwPrice}}</div>
											<div class="lsj" :style="{color:divstyle.textcolor2}"
												v-if="item.pricesale > item.pricebuy">￥{{item.pricesale}}
											</div> -->
										</div>
										<div class="memo">
											备注
										</div>
										<div class="dj">
											促销：管理员</div>

									</div>
									<div class="sp-right">

										<div class="sp-right-num" :style="{color:styleitem.text2_bg_color}">x1 套</div>
										<div class="sp-right-money" :style="{color:styleitem.title3_bg_color}">￥199
										</div>
									</div>
								</div>
								<div class="groupmemo">
									荷兰宝华利无醇啤酒桃子味330ml*6瓶/套 x 3;黑桃A1（白）【正式】 x 1;
								</div>

							</div>
							<div class="moremsg" :style="{color:styleitem.text2_txt_color}">共12道，点击显示更多</div>
						</div>

						<div class="bottbox"
							:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
							<div class="moneybox">
								<div class="total" :style="{color:styleitem.text3_txt_color}">总计：￥1000</div>
								<div class="totoalmoney" :style="{color:styleitem.title3_txt_color}">
									￥100</div>
							</div>
							<div class="offOrder"
								:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
								<span>取消订单</span>
							</div>
						</div>
					</div>
					<div class="content-header"
						:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
						<!-- <div class="title" :style="{background:divstyle.btnbg,color:divstyle.btncolor}">{{mchname}}</div> -->
						<div class="spdetail">
							<div class="payment-head">
								<div class="left">
									单号：JXSC1000101240830101
								</div>
								<div class="right">
									待收款
								</div>
							</div>
							<div class="pitem ">
								桌号：测试台
							</div>
							<div class="pitem">
								会员：测试客人 15883568512
							</div>
							<div class="pitem">
								积分：12
							</div>
							<div class="pitem">
								客户经理：管理员
							</div>
							<div class="pitem">
								服务员：管理员
							</div>
							<div class="pitem">
								备注：下单备注
							</div>
							<div class="pitem" style="margin-bottom: 0;">
								下单时间：08-30 17：14
							</div>

						</div>
					</div>
					<div class="w_100"></div>
					<div class="payment-footer"
						:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
						<div class="">
						</div>
						<div class="w100">
							<!-- <div class="totoalmoney" v-if="orderinfo.moneysale > orderinfo.moneyfact">
								总额：￥{{orderinfo.moneysale}}</div> -->
							<div class="bottom-color" :style="{color:styleitem.text3_txt_color}"><span
									style="font-size:10pt">待付款：</span><span style="font-size: 9pt;">￥</span>1588</div>
							<!-- <div class="sale" :style="{color:divstyle.btnbg}" >会员立减 -52.80元</div> -->
						</div>
						<div class="pitem receipt"
							:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
							付款
						</div>
					</div>
				</div>
				<div class="modeitem" :style="{background:styleitem.page_bg_color,color:styleitem.page_txt_color}">
					<div class="top-nav"
						:style="{background:styleitem.page_title_bg_color,color:styleitem.page_title_txt_color}">
						<img src="../../../assets/design/topNavBlack.png" />
						<div class="tit">顶部标题</div>
					</div>
					<div v-for="(item,index) in 3" :key="index">
						<div class="list"
							:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
							<div class="imgbox">
								<img src="https://imgoss.91erp.net/10001/sp/230506170852228.png" class="spimg"></img>
							</div>
							<div class="right">
								<div class="spmc">0.1元购买券包活动</div>
								<div class="remark" :style="{color:styleitem.text2_txt_color}">开始日期2023-08-01 00:00:00
								</div>
								<div class="remark" :style="{color:styleitem.text2_txt_color}">结束日期2024-09-30 00:00:00
								</div>
								<div class="tip">
									<div class="t1"
										:style="'background:' + styleitem.subject1_bg_color +  ';color:' + styleitem.subject1_txt_color + ';border:1px solid' + styleitem.subject1_bg_color">
										商品券</div>
									<div class="t2"
										:style="'color:' + styleitem.subject1_bg_color + ';border:1px solid' + styleitem.subject1_bg_color">
										限购1次</div>
								</div>
								<div class="row" style="display: flex;align-items: center;">
									<div class="price" :style="{color:styleitem.title3_bg_color}"><span
											style="font-size: 9pt;">￥</span>99<span class="f10"
											:style="{color:styleitem.title3_txt_color}">￥199</span></div>
									<div class="btn" v-if="index == 1">已售罄</div>
									<div class="btn" v-else
										:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
										去购买</div>

								</div>
							</div>
						</div>
						</block>
					</div>
				</div>
			</div>
		</div>

		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
		<cusselImgs ref="cusselImgs" @selImgsresult="selImgsresult"></cusselImgs>
	</section>


</template>

<script>
	// 文本
	import h_text_title from "@/components/applet/toolbox/base/text/text_title";
	import h_text_notice from "@/components/applet/toolbox/base/text/text_notice";
	//img
	import h_image_row from "@/components/applet/toolbox/base/img/image_row";

	import h_image_col from "@/components/applet/toolbox/base/img/image_col";
	import h_menu_img_title from "@/components/applet/toolbox/base/img/menu_img_title";
	import h_image_swiper from "@/components/applet/toolbox/base/img/image_swiper";
	import h_image_magic from "@/components/applet/toolbox/base/img/image_magic";
	import h_image_spot from "@/components/applet/toolbox/base/img/image_spot";
	import h_image_desk from "@/components/applet/toolbox/base/img/image_desk";
	import h_image_docker from "@/components/applet/toolbox/base/img/image_docker";

	import h_foot_nav_cube from "@/components/applet/toolbox/nav/foot_nav_cube";
	import h_foot_nav_circle from "@/components/applet/toolbox/nav/foot_nav_circle";


	import h_goods_list_sp from "@/components/applet/toolbox/goods/goods_list_sp";
	//
	import h_line_place from "@/components/applet/toolbox/base/line/line_place";

	import h_account_login from "@/components/applet/toolbox/club/login/account_login";
	//店铺信息
	import h_shop_info_nav from "@/components/applet/toolbox/shop/shop_info_nav";
	//办理会员
	import h_club_newadd from "@/components/applet/toolbox/club/account/club_newadd";
	//会员资产
	import h_club_asset from "@/components/applet/toolbox/club/account/club_asset";

	import h_coupon_list from "@/components/applet/toolbox/coupon/coupon_list";

	import h_news_list from "@/components/applet/toolbox/shop/news_list";
	import h_image_share from "@/components/applet/toolbox/base/img/image_share";
	import h_image_join from "@/components/applet/toolbox/base/img/image_join";
	import h_image_qunqr from "@/components/applet/toolbox/base/img/image_qunqr";

	import collectApi from '@/api/isv/collect.js'
	import marketApi from '@/api/cus/market.js';
	import styleApi from '../../../api/isv/style.js'
	import cusstyleApi from '../../../api/cus/style.js'
	import designTemplataApi from "../../../api/isv/designtmplate";
	import cusTemplataApi from "../../../api/cus/designtmplate";
	import cusselImgs from "@/components/customer/selImgs";
	import selImgs from "@/components/designer/selImgs";
	import Pagination from '@/components/Pagination.vue'
	import util from '../../../utils/util.js'
	export default {
		components: {
			h_text_title,
			h_text_notice,
			h_image_row,
			h_image_col,
			h_menu_img_title,
			h_image_swiper,
			h_image_magic,
			h_image_spot,
			h_image_desk,
			h_image_docker,

			h_goods_list_sp,

			h_foot_nav_cube,
			h_foot_nav_circle,

			h_line_place,
			h_account_login,

			h_shop_info_nav,
			h_club_newadd,
			h_club_asset,
			h_coupon_list,
			h_news_list,
			h_image_share,
			h_image_join,
			h_image_qunqr,
			selImgs,
			cusselImgs,
			Pagination

		},
		props: ['typeList', 'currId', 'pagelist'],
		data() {
			return {
				title: "编辑",
				navlist: [{
						id: 0,
						text: '全部'
					},
					{
						id: 1,
						text: '常用'
					},
					{
						id: 2,
						text: '店铺'
					},
					{
						id: 3,
						text: '营销'
					},
					{
						id: 4,
						text: '会员'
					},
				],
				currIndex: 0,
				menulist: [{
						id: 0,
						text: '推荐模版'
					},
					{
						id: 1,
						text: '酒吧'
					},
					{
						id: 2,
						text: 'KTV'
					},
				],
				likeIndex: 0,
				mbIndex: 0,

				pagetype: "index", //页面类型
				collectlist: [],
				collectIndex: 0,


				collectInfo: {
					key: "",
					pageno: 1,
					pageall: 1
				},


				form: {
					cls1id: '',
					cls2id: '',
					cls3id: '',
					key: '',
					keyview: '',
					freeflag: ''
				},
				marketlist: [],
				styleList: [],
				styleclslist:[],
				selstyleitem:{},
				styleclsid:'',
				style_id: "",
				styleitem: {},
				editstyleVisible: false,
				selcolor: "",
				moreShow: false,
				templateid: "",
				from: {
					logo: "",
					tmpid: "",
					name: ""
				},
				defaultstyle: {
					white: {
						front_bg_color: "#FFFFFF",
						front_txt_color: "#282828",
						page_bg_color: "#F3F3F3",
						page_title_bg_color: "#FFFFFF",
						page_title_txt_color: "#000000",
						page_txt_color: "#303030",
						// subject1_bg_color: "#002EA2",
						// subject1_txt_color: "#FFFFFF",
						// subject2_bg_color: "#717171",
						// subject2_txt_color: "#4F4F4F",
						text1_bg_color: "#EB0C0C",
						text1_txt_color: "#565656",
						text2_bg_color: "#EF0A0A",
						text2_txt_color: "#6A6A6A",
						text3_bg_color: "#E1E1E1",
						text3_txt_color: "#E1E1E1",
						title1_bg_color: "#B5B5B5",
						title1_txt_color: "#777777",
						title2_bg_color: "#525252",
						title2_txt_color: "#9D9D9D",
						title3_bg_color: "#FFFFFF",
						title3_txt_color: "#FFFFFF",
					},
					black: {
						front_bg_color: "#20232B",
						front_txt_color: "#E8E8E8",
						page_bg_color: "#181920",
						page_title_bg_color: "#94B2D6",
						page_title_txt_color: "#FFFFFF",
						page_txt_color: "#E8E8E8",
						// subject1_bg_color: "#94B2D6",
						// subject1_txt_color: "#181920",
						// subject2_bg_color: "#FFFFFF",
						// subject2_txt_color: "#8E5555",
						text1_bg_color: "#B75050",
						text1_txt_color: "#B83535",
						text2_bg_color: "#B2B2B2",
						text2_txt_color: "#CFCFCF",
						text3_bg_color: "#A5A5A5",
						text3_txt_color: "#292A34",
						title1_bg_color: "#DA2C2C",
						title1_txt_color: "#B03838",
						title2_bg_color: "#E5E5E5",
						title2_txt_color: "#EEA60B",
						title3_bg_color: "#CDCDCD",
						title3_txt_color: "#C2C2C2",
					},
				},
				usertype: "",
				formInline: {
					name: "",
					pageno: 1,
					pagesize: 12
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 0
				},

			}
		},
	
		computed:{
			stylelist_cls(){
				
				if(this.styleclsid != undefined && this.styleclsid.length > 0){
					let templist = this.styleList.filter(f =>{
						return f.clsid == this.styleclsid
					})
					console.log(templist,'templist')
					return templist
				}else{
					return this.styleList
				}
				
			}
		},
		mounted() {
			let user = util.getloginuser()
			this.usertype = user.usertype
			// let usertype = sessionStorage.getItem("usertype");
			// this.usertype = usertype
			this.getStyle()
			this.getstylecls()
			//this.getmarketlist(this.pageparm)
			this.marketlist = []
			if(this.usertype == 'MCH'){
				this.getmytemplist(this.formInline)
			}
			
		},
		methods: {
			funshow(tid, usertype) {
				console.log(tid, 'tid');
				this.templateid = tid
				this.usertype = usertype
				this.initdata()
				this.setdata()
			},
			initdata() {
				let _this = this;
				if (this.usertype == "MCH") {
					let param = {
						tmpid: this.templateid
					}
					this.showload = true

					cusTemplataApi.getstyle(param, res => {

						if (res.code == 200) {

							this.styleitem = JSON.parse(JSON.stringify(res.data.style.item))
						}
					})
				} else {
					let param = {
						tmpid: this.templateid
					}
					this.showload = true

					designTemplataApi.getstyle(param, res => {
					console.log(res,'inindata.res.design');
						if (res.code == 200) {

							this.style_id = res.data.style.id
							this.styleitem = JSON.parse(JSON.stringify(res.data.style.item))
						}
					})
				}


			},
			setdata() {

				if (this.usertype == "MCH") {
					cusTemplataApi.getUserPageList({
						id: this.templateid
					}, res => {

						if (res.code == 200) {
							// this.pagelist = res.data.pagelist
							this.from.tmpid = res.data.appTmplate.tmpid
							this.from.name = res.data.appTmplate.tmpname
							this.from.logo = res.data.appTmplate.logo
						}
					})
				} else {
					designTemplataApi.getUserPageList({
						id: this.templateid
					}, res => {

						if (res.code == 200) {
							// this.pagelist = res.data.pagelist
							this.from.tmpid = res.data.appTmplate.tmpid
							this.from.name = res.data.appTmplate.tmpname
							this.from.logo = res.data.appTmplate.logo
						}
					})
				}

			},
			funupload() {
				if (this.usertype == "MCH") {
					this.$refs.cusselImgs.funShow();
				} else {
					this.$refs.selImgs.funShow();
				}
			},
			selImgsresult(imgitem) {
				this.from.logo = imgitem.url;
			},
			funNavClick(item, index) {
				this.currIndex = index
			},
			funsjxClick() {

			},
			funmenuClick(item, index) {
				this.mbIndex = index
			},
			funlikeClick(item, index) {
				this.likeIndex = index
			},
			funaddbox(val) {

				this.$emit('funaddbox', val)
			},
			dragStart(e) {
				this.$emit('dragStart', e)
			},
			dragEnd(e) {
				this.$emit('dragEnd', e)
			},
			fungotopage(item) {

				this.pagetype = item.pageno;
				this.$emit('fungotopage', item)
			},
			funaddlike(item, index) {
				this.$emit('funlikeadd', item.content)
			},
			funselLike(index) {
				this.collectIndex = index
			},
			funcollect(pageno) {
				let param = {
					pageno: pageno,
					title: this.collectInfo.key,
					pagesize: 20
				}

				collectApi.list(param, res => {

					if (res.code == 200) {
						this.collectInfo.pageno = pageno
						this.collectInfo.pageall = Number(res.data.pagetotal)
						if (pageno == 1) {
							this.collectlist = res.data.list
						} else {
							this.collectlist.push(...res.data.list)
						}

					}
				})
			},
			funcollectmore() {
				this.funcollect(this.collectInfo.pageno + 1)
			},
			funcancel(item, index) {
				let param = {
					id: item.id
				}

				collectApi.del(param, res => {

					if (res.code == 200) {
						this.funcollect(1)
					}
				})
			},
			getmarketlist(page) {
				let param = {
					cls1id: this.form.cls1id, //销售
					cls2id: this.form.cls2id, //行业
					cls3id: this.form.cls3id, //风格
					freeflag: this.form.freeflag,
					key: this.form.key,
					pageno: page.pageno,
					pagesize: page.pagesize
				}

				marketApi.search(param, res => {
					console.log(res, 'marketlist.res');
					if (res.code == 200) {
						this.form.keyview = this.form.key
						this.marketlist = res.data.list
						this.pageparm.total = res.data.total
					}
				})
			},
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.getmytemplist(this.formInline)
			},
			funselstylecls(item){
				console.log(item,'funselstylecls.item');
				this.styleclsid = item.id
			},
			getstylecls(){
				if (this.usertype == "MCH") {
					cusstyleApi.clslist( res => {
						console.log(res,'cus.clslist');
						if (res.code == 200) {
							this.styleclslist = res.data
						}
					})
				}else{
					console.log('isv.clslist22');
					
					styleApi.clslist( res => {
						console.log(res,'isv.clslist');
						if (res.code == 200) {
							this.styleclslist = res.data
						}
					})
				}
				
			},
			getStyle() {
				if (this.usertype == "MCH") {
					cusstyleApi.list({}, res => {
					console.log(res,'getStyle.res');
						if (res.code == 200) {
							// res.data.push({
							// 	style_id: 0,
							// 	style_name: '自定义'
							// })
							this.styleList = res.data
						}
					})
				} else {
					styleApi.list({}, res => {

						if (res.code == 200) {
							// res.data.push({
							// 	style_id: 0,
							// 	style_name: '自定义'
							// })
							this.styleList = res.data
						}
					})
				}

			},
			funCustom(item) {
				this.style_id = 0
				//this.styleitem.tmpid = this.templateid
				this.editstyleVisible = true
			},
			funselStyle(item) {
				console.log(item,'funselStyle.item')
				this.styleitem = JSON.parse(item.stylejson)
				this.selstyleitem = item
				// if (this.usertype == "MCH") {
				// 	let param = {
				// 		style_id: item.style_id,
				// 		tmpid: this.templateid
				// 	}

				// 	cusstyleApi.edit(param, res => {

				// 		if (res.code == 200) {
				// 			// sessionStorage.setItem('style_id',item.style_id)
				// 			// console.log(this.styleList,'this.styleList');
				// 			this.style_id = item.style_id
				// 			this.$message({
				// 				type: "success",
				// 				message: "编辑成功"
				// 			})
				// 		}
				// 	})
				// } else {
				// 	let param = {
				// 		style_id: item.style_id,
				// 		tmpid: this.templateid
				// 	}

				// 	styleApi.edit(param, res => {

				// 		if (res.code == 200) {
				// 			// sessionStorage.setItem('style_id',item.style_id)
				// 			// console.log(this.styleList,'this.styleList');
				// 			this.style_id = item.style_id
				// 			this.$message({
				// 				type: "success",
				// 				message: "编辑成功"
				// 			})
				// 		}
				// 	})
				// }

			},
			setkey(key) {

				this.$delete(this.styleitem, key)
			},
			funblack() {

				this.selcolor = 'black'
				this.styleitem.front_bg_color = this.defaultstyle.black.front_bg_color,
					this.styleitem.front_txt_color = this.defaultstyle.black.front_txt_color
				this.styleitem.page_bg_color = this.defaultstyle.black.page_bg_color
				this.styleitem.page_title_bg_color = this.defaultstyle.black.page_title_bg_color
				this.styleitem.page_title_txt_color = this.defaultstyle.black.page_title_txt_color
				this.styleitem.page_txt_color = this.defaultstyle.black.page_txt_color
				this.styleitem.text1_bg_color = this.defaultstyle.black.text1_bg_color
				this.styleitem.text1_txt_color = this.defaultstyle.black.text1_txt_color
				this.styleitem.text2_bg_color = this.defaultstyle.black.text2_bg_color
				this.styleitem.text2_txt_color = this.defaultstyle.black.text2_txt_color
				this.styleitem.text3_bg_color = this.defaultstyle.black.text3_bg_color
				this.styleitem.text3_txt_color = this.defaultstyle.black.text3_txt_color
				this.styleitem.title1_bg_color = this.defaultstyle.black.title1_bg_color
				this.styleitem.title1_txt_color = this.defaultstyle.black.title1_txt_color
				this.styleitem.title2_bg_color = this.defaultstyle.black.title2_bg_color
				this.styleitem.title2_txt_color = this.defaultstyle.black.title2_txt_color
				this.styleitem.title3_bg_color = this.defaultstyle.black.title3_bg_color
				this.styleitem.title3_txt_color = this.defaultstyle.black.title3_txt_color
			},
			funwhite() {
				this.selcolor = 'white'
				this.styleitem.front_bg_color = this.defaultstyle.white.front_bg_color,
					this.styleitem.front_txt_color = this.defaultstyle.white.front_txt_color
				this.styleitem.page_bg_color = this.defaultstyle.white.page_bg_color
				this.styleitem.page_title_bg_color = this.defaultstyle.white.page_title_bg_color
				this.styleitem.page_title_txt_color = this.defaultstyle.white.page_title_txt_color
				this.styleitem.page_txt_color = this.defaultstyle.white.page_txt_color
				this.styleitem.text1_bg_color = this.defaultstyle.white.text1_bg_color
				this.styleitem.text1_txt_color = this.defaultstyle.white.text1_txt_color
				this.styleitem.text2_bg_color = this.defaultstyle.white.text2_bg_color
				this.styleitem.text2_txt_color = this.defaultstyle.white.text2_txt_color
				this.styleitem.text3_bg_color = this.defaultstyle.white.text3_bg_color
				this.styleitem.text3_txt_color = this.defaultstyle.white.text3_txt_color
				this.styleitem.title1_bg_color = this.defaultstyle.white.title1_bg_color
				this.styleitem.title1_txt_color = this.defaultstyle.white.title1_txt_color
				this.styleitem.title2_bg_color = this.defaultstyle.white.title2_bg_color
				this.styleitem.title2_txt_color = this.defaultstyle.white.title2_txt_color
				this.styleitem.title3_bg_color = this.defaultstyle.white.title3_bg_color
				this.styleitem.title3_txt_color = this.defaultstyle.white.title3_txt_color
			},
			funOk() {
				// if (this.styleitem.front_bg_color == undefined || this.styleitem.front_bg_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页面前景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.front_txt_color == undefined || this.styleitem.front_txt_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页面前景字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.page_bg_color == undefined || this.styleitem.page_bg_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页面背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.page_title_txt_color == undefined || this.styleitem.page_title_txt_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页面标题字体色（白/黑）"
				// 	})
				// 	return
				// }
				// if (this.styleitem.page_txt_color == undefined || this.styleitem.page_txt_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页面字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.page_txt_color == undefined || this.styleitem.page_txt_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页面字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.subject1_bg_color == undefined || this.styleitem.subject1_bg_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择主题1背景颜色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.subject1_txt_color == undefined || this.styleitem.subject1_txt_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择主题1字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.subject2_bg_color == undefined || this.styleitem.subject2_bg_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择设计页背景颜色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.subject2_txt_color == undefined || this.styleitem.subject2_txt_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择设计页字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.text1_bg_color == undefined || this.styleitem.text1_bg_color <= 0) {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择点单分类背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.text1_txt_color == undefined || this.styleitem.text1_txt_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择点单分类字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.text2_bg_color == undefined || this.styleitem.text2_bg_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页脚背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.text2_txt_color == undefined || this.styleitem.text2_txt_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择页脚字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.title1_bg_color == undefined || this.styleitem.title1_bg_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择标题1背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.title1_txt_color == undefined || this.styleitem.title1_txt_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择标题1字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.title2_bg_color == undefined || this.styleitem.title2_bg_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择标题2背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.title2_txt_color == undefined || this.styleitem.title2_txt_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择标题2字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.page_title_bg_color == undefined || this.styleitem.page_title_bg_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择标题页头标题背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.title3_bg_color == undefined || this.styleitem.title3_bg_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择标题3背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.title3_txt_color == undefined || this.styleitem.title3_txt_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择标题3字体色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.text3_bg_color == undefined || this.styleitem.text3_bg_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择文本3背景色"
				// 	})
				// 	return
				// }
				// if (this.styleitem.text3_txt_color == undefined || this.styleitem.text3_txt_color == "") {
				// 	this.$message({
				// 		type: "info",
				// 		message: "请选择文本3字体色(边框色)"
				// 	})
				// 	return
				// }
				//this.styleitem.tmpid = this.templateid
				
				console.log(this.styleitem, 'this.styleitem');
				if (this.usertype == "MCH") {

					cusstyleApi.editstyle(this.selstyleitem.style_id,this.selstyleitem.style_name,this.templateid,this.styleitem, res => {
						console.log(res, 'editstyle.res');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "编辑成功"
							})
							this.editstyleVisible = false
						}
					})
				} else {

					styleApi.editstyle(this.selstyleitem.style_id,this.selstyleitem.style_name,this.templateid,this.styleitem, res => {

						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "编辑成功"
							})
							this.editstyleVisible = false
						}
					})
				}

			},
			funsavemc() {
				let param = {
					tmpid: this.templateid,
					name: this.from.name,
				};
				if (this.usertype == "MCH") {
					cusTemplataApi.editname(param, (res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg,
							});
							this.from.name = this.from.name;
						} else {
							this.$message({
								type: "error",
								message: res.msg,
							});
						}
					});
				} else {
					designTemplataApi.editname(param, (res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg,
							});
							this.from.name = this.from.name;
						} else {
							this.$message({
								type: "error",
								message: res.msg,
							});
						}
					});
				}

			},
			funseltemp(item) {
				// 	console.log(item,'item');
				// 	this.$confirm('你还没保存正在修改的模板,请先保存模板?', '提示', {
				// 		confirmButtonText: '确定',
				// 		cancelButtonText: '取消',
				// 		type: 'warning'
				// 	}).then(() => {
				// 		// this.$emit('toolboxlistresult',{
				// 		// 	code:200
				// 		// })

				// 		// this.fbsubmit(item)
				// 	}).catch(() => {

				// 	});
				console.log(item, 'funseltemp.item');
				this.$emit('selusertemp', {
					tempitem: item
				})

			},
			getmytemplist: function(page) {

				let param = {
					pageno: page.pageno,
					pagesize: page.pagesize
				}
				cusTemplataApi.getUserMytemplist(param, res => {
					this.showload = false
					if (res.code == 200) {
						if (res.data.list.length == 0) {
							this.datamsg = '无数据'
						} else {
							// res.data.list.push({
							// 	tmpname: "更多模版"
							// })
							this.marketlist = res.data.list
							this.pageparm.currentPage = res.data.pageno
							this.pageparm.pageSize = res.data.pagesize
							this.pageparm.total = res.data.total
						}
					}
				})
			},
			fbsubmit(item) {
				let param = {
					user_tempid: item.sysid
				}

				cusTemplataApi.usemb(param, res => {

					if (res.code == 200) {
						this.$message({
							type: "success",
							message: '模版发布成功'
						})

					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})

			},
			// 提交


			funupsave() {
				let param = {
					tmpid: this.templateid,
					logo: this.from.logo,
				};
				if (this.usertype == "MCH") {
					cusTemplataApi.editlogo(param, (res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg,
							});
							this.from.logo = this.from.logo;
						} else {
							this.$message({
								type: "error",
								message: res.msg,
							});
						}
					});
				} else {
					designTemplataApi.editlogo(param, (res) => {
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg,
							});
							this.from.logo = this.from.logo;
						} else {
							this.$message({
								type: "error",
								message: res.msg,
							});
						}
					});
				}

			},
		}
	}
</script>

<style lang="less" scoped>
	.toolboxleft {

		position: relative;
		background: #fafaf5;

		height: calc(100vh - 61px);
		overflow: auto;
		background: #fff;
		// width:370px;
		flex-shrink: 0;

		h4 {
			cursor: pointer;
			margin: 20px;
			position: relative;

			.sjx {
				position: absolute;
				left: 0;
				top: 0;
			}
		}

		.property {
			width: 100%;
		}

		section {
			width: 100%;
		}
	}

	.toolboxleft {
		// border-right: 1px solid #f0f0f0;

		.column {
			display: flex;
			flex-direction: column;
		}

		.nav {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.nav-item {
				width: 20%;


				white-space: nowrap;

				.nav_text {
					text-align: center;
					margin: 0 5px;
					padding: 5px 0;
					border-radius: 2px;
				}
			}
		}

		ul {
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;

			li {
				width: 33.33%;
				height: 80px;
				display: flex;
				justify-content: center;
				align-items: center;
				flex-direction: column;
				cursor: default;
				list-style: none;
				font-size: 14px;
				color: #666;


				border-radius: 6px;
				transition: all 0.3s;
				cursor: pointer;

				&:hover {
					background: #efefef;
				}

				.icon {
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
				}

				span {
					display: block;
					font-size: 40px;
					margin-bottom: 8px;
					color: #999;
				}

				p {
					display: block;
					margin: 0;
					font-size: 12px;
				}
			}
		}

		.sel {
			color: #fff;
			background-color: #3388FF;
		}

		.nom {
			cursor: pointer;
			color: #999999;
			background-color: #F5F5F5;
		}
	}

	.moban {


		.moban-search {
			margin: 10px;
			border-radius: 4px 4px 4px 4px;
			border: 1px solid #D7D7D7;
			display: flex;
			align-items: center;
			position: relative;

			.moban-searchbtn {
				color: #3089dc;
				cursor: pointer;
				position: absolute;
				right: 10px;
			}

			.moban-img {
				padding-left: 10px;
				display: flex;
				align-items: center;
			}

			input {
				padding: 10px;
				border: none;
				outline: none;
			}

		}

		.moban-menu {
			display: flex;
			flex-wrap: wrap;

			.moban-item {

				margin: 0 10px;
				padding: 5px 10px;
				border-radius: 2px 2px 2px 2px;

			}

			.sel {
				color: #fff;
				background: #3388FF;
			}

			.nom {
				color: #999999;
				background-color: #F5F5F5;
			}
		}

		.moban-cont {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-start;
			height: calc(100vh - 140px);
			overflow: auto;

			.moban-item {
				cursor: pointer;
				width: 46%;
				margin-bottom: 10px;
				width: 160px;
				flex-shrink: 0;
				margin: 10px;

				img {
					width: 100%;

				}
			}
		}

		.like-cont {
			height: calc(100vh - 140px);
			overflow: auto;

			.like-item {
				margin: 10px 0;
				border-bottom: 1px solid #f0f0f0;

				.itemcont {
					max-height: 200px;
					overflow: hidden;
					width: 375px;
				}

				img {
					width: 204px;
					height: 150px;
				}

				.bottom {
					padding: 10px;
					width: 90%;
					display: flex;
					align-items: center;
					justify-content: space-between;

					.islike {
						cursor: pointer;
						color: #F30000;
						font-size: 12px;

						:nth-child(1) {
							color: #3388FF;
						}
					}
				}
			}

			.collSel {
				border: 2px solid #3089dc !important;
			}

			.collSel {
				border: none
			}

			.more {
				cursor: pointer;
				text-align: center;
			}
		}
	}

	.menu-items {
		width: 80%;
		margin: 10px auto;
		padding: 10px;
		border: 1px solid #f0f0f0;
	}

	.selected {
		color: #3089dc;
		border: 1px solid #3089dc;
	}

	.el-image {
		margin: 10px;
		width: 100px !important;
	}

	.main {
		display: flex;
		flex-wrap: wrap;
	}

	.main .disp {
		margin: 10px;
		white-space: nowrap;
	}

	.main .box {
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		position: relative;
	}

	.main .box .stylemc {
		white-space: nowrap;
		font-size: 12px;
	}

	.main .box .item {
		/* margin: 10px 0;
		display: flex;
		align-items: center; */
		cursor: pointer;
		width: 32px;
		height: 20px;
		font-size: 10px;
		line-height: 20px;
		text-align: center;
		border-radius: 5px;
		white-space: nowrap;
	}

	.item_cont {
		margin: 5px 10px;
		width: 32px;
		height: 20px;
		/* overflow: hidden; */
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
	}

	.boxs {
		height: calc(100vh - 350px);
		width: 500px;
		overflow-y: auto;
	}

	.boxs .item {
		line-height: 3.5;
		// border-bottom: 1px solid #f0f0f0;
	}

	.boxs .btn {
		margin-right: 20px;
		float: right;
		display: flex;
		align-items: center;
	}

	.sel-menu .black {
		border: 1px solid #ccc;
		width: 100px;
		height: 50px;
		line-height: 50px;
		margin: 10px;
		cursor: pointer;
		text-align: center;
	}

	.curr {
		border: 3px solid #3388FF;
	}

	.cusm {
		border: 2px solid #ccc;
	}

	.w250 {

		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 60%;
		margin: 16px auto 0 auto;
		// color: #A5A5A5;
		padding: 20px 0;
	}

	.w250 .cloum {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.w250 .cloum .line {
		width: 20px;
		height: 4px;
		border-radius: 5px;
	}

	.w250 .right1 {
		width: 48px;
		text-align: center;
		line-height: 24px;
		height: 24px;
		font-size: 12pt;
		font-weight: 700;
		margin: 4px 0;

	}

	.modelist {
		width: calc(100vw - 500px);
		margin-left: 20px;
		display: flex;
		margin-top: 20px;
	}

	.modeitem {
		width: 375px;
		height: 812px;
		// background-color: #181920;
		// color: #ffffff;
		margin: 0 20px;
		position: relative;
		overflow: hidden;
		box-shadow: 0 2px 6px #ccc;
	}

	.modeitem .item {
		width: 311px;
		border-radius: 10px;
		padding: 10px 16px;
		position: relative;
		margin: 0 auto 16px auto;

	}

	.modeitem .item .icon {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 20px;
		top: 67px;
	}

	.modeitem .item .orderno {
		display: flex;
		align-items: center;
		font-size: 11pt;

	}

	.modeitem .item .orderno .left {
		padding: 3px 5px;
		font-size: 8pt;

		border-radius: 3px;
		flex-shrink: 0;
		margin-right: 10px;
	}

	.modeitem .item .orderno .zt0 {}

	.modeitem .item .orderno .zt1 {
		background: #C3C3C3;
		color: #4E4E4E;
	}

	.modeitem .item .orderno .zt2 {
		background: #C3C3C3;
		color: #4E4E4E;
	}

	.modeitem .item .spimg {
		width: 50px;
		height: 50px;
		margin-right: 15px;
		flex-shrink: 0;
	}

	.modeitem .item .rightbox {
		display: flex;
		flex-direction: column;

		justify-content: center;
	}

	.modeitem .item .rightbox .sl {

		font-size: 10pt;
		margin-top: 5px;
	}

	.modeitem .item .bottom {

		align-items: center;
	}

	.modeitem .item .date {
		font-size: 10pt;

		flex-grow: 1;
	}

	.take {
		display: flex;
		flex-direction: row;
		position: absolute;
		right: 5px;
		bottom: 10px;
		width: 120px;
		height: 50px;
	}

	.take .btn {
		width: 50px;
		height: 35px;
		flex-shrink: 0;
		padding-top: 15px;
	}

	.take .add {
		position: absolute;
		right: 0;
	}

	.take .less {
		position: absolute;
		left: 0;
	}

	.take .num {
		position: absolute;
		left: 35px;
		width: 50px;
		font-size: 12pt;
		text-align: center;

	}

	.take .val {
		height: 20px;
		line-height: 20px;
		padding-top: 15px;
		flex-grow: 2;
		text-align: center;
	}

	.take .i {
		margin: 0 auto;
		width: 20px;
		height: 20px;
		line-height: 17px;
		border-radius: 20px;
		font-size: 17px;
		text-align: center;
	}



	.payment-body {
		width: 343px;
		margin: 0 auto;
		height: auto;
		overflow: auto;
		border-radius: 10px;
		margin-top: 16px;
	}

	.payment-body .row {
		display: flex;

	}

	.payment-body .body-head {
		height: 45px;
		line-height: 45px;
		font-size: 14px;
		margin-bottom: 12px;
		border-bottom: 1rpx solid #fff;
	}

	.payment-body .body-top {
		padding: 16px;
		background: #20232B;
	}

	.payment-body .body-top .top-sp {
		display: flex;
		flex-direction: column;
		margin: 0 0 10px;
	}

	.payment-body .body-top .top-sp .spcont {
		display: flex;
		flex-direction: row;
	}

	.groupmemo {
		font-size: 10pt;
		padding: 10px 0rpx;

	}

	.payment-body .body-top .top-sp .sp-left {
		margin-right: 20px;
	}

	.payment-body .body-top .top-sp .sp-left image {
		height: 60px;
		width: 60px;
		background-size: 100%;
		border-radius: 20px;
	}

	.payment-body .body-top .top-sp .sp-center {
		flex-grow: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 11pt;
	}

	.payment-body .body-top .top-sp .sp-center .dj {
		margin-top: 10px;
		display: flex;
		flex-direction: row;
	}

	.payment-body .body-top .top-sp .sp-center .dj .gmj {}

	.payment-body .body-top .top-sp .sp-center .dj .lsj {
		text-decoration: line-through;

		margin-left: 10px;
	}

	.payment-body .body-top .top-sp .sp-center .memo {
		font-size: 9pt;

	}

	.payment-body .body-top .top-sp .sp-right {
		text-align: right;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		margin-left: 20px;
	}

	.payment-body .body-top .top-sp .sp-right .sp-right-money {
		font-size: 14px;
	}

	.payment-body .body-top .top-sp .sp-right .sp-right-num {

		font-size: 12px;
	}

	.payment-body .body-bottom {
		height: 40px;
		line-height: 40px;
		border-top: 1px solid #d0cfd4;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 14px;
	}

	.bottom-color {
		font-weight: 700;
		font-size: 12pt;

	}

	.sale {
		font-size: 9pt;
		margin-top: 5px;
	}

	.totoalmoney {
		text-decoration: line-through;
		font-size: 10pt;


		margin-left: 10px;
	}

	.payment-footer {
		display: flex;
		flex-direction: row;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #FFFFFF;
		height: 48px;
		align-items: center;
		justify-content: center;
		z-index: 2;
		color: #000;
	}

	.payment-footer .pitem {
		flex-grow: 1;
	}

	.payment-footer .w100 {
		flex-grow: 1;
		margin-left: 16px;
	}

	.w_100 {
		height: 100px;
	}

	.bottbox {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 16px;
		background: #20232B;
	}

	.moneybox {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		font-size: 11pt;
		font-weight: 700;
	}

	.offOrder {
		display: flex;
		border-radius: 12px;
	}

	.offOrder span {
		height: 24px;
		line-height: 24px;
		width: 77px;

		text-align: center;
		font-size: 10pt;
	}

	.payment-footer .receipt {
		height: 100%;
		line-height: 48px;
		width: 90px;
		height: 48px;
		text-align: center;


	}

	.title {
		text-align: left;
		height: 44px;
		line-height: 44px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		font-size: 11pt;
		font-weight: 700;
		padding-left: 16px;


	}

	.content-header {
		margin: 16px auto;
		border-radius: 10px;
		width: 343px;

		.payment-head {
			width: 100%;
			font-size: 11pt;
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
		}

		.spdetail {
			padding: 16px;


			.pitem {
				font-size: 11pt;
				margin-bottom: 10px;

			}
		}
	}

	.list {
		width: 343px;
		margin: 16px auto 0 auto;
		padding-bottom: 16px;

		overflow: hidden;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.list .imgbox {
		max-height: 148px;
		overflow: hidden;
	}

	.list .imgbox .date {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 5px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		font-size: 9pt;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.list .spimg {
		width: 100%;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		flex-shrink: 0;
	}

	.list .right {
		padding: 10px 12px;
	}

	.list .right .spmc {
		font-size: 11pt;
		font-weight: 700;
	}

	.list .right .remark {
		font-size: 10pt;
		color: #A6A6A6;
		margin: 8px 0;
	}

	.list .right .tip {
		display: flex;
		flex-direction: row;
		font-size: 10pt;
		margin-bottom: 12px;

	}

	.list .right .tip .t1 {
		width: 45px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
	}

	.list .right .tip .t2 {
		padding: 0 2px;
		height: 16px;
		line-height: 16px;
		text-align: center;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
	}

	.list .price {
		font-size: 12pt;
		font-weight: 700;
		flex-grow: 1;
	}

	.list .btn {
		width: 58px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		border-radius: 12px;



	}

	.f10 {

		text-decoration: line-through;
		font-size: 10pt;
		margin-left: 10px;
		font-weight: 500;
	}

	.dialog-footer {
		width: 100%;
		justify-content: center;
	}

	.savebtn {
		width: 100%;
		height: 40px;
		margin-top: 20px;
	}

	.moremsg {
		font-size: 9pt;
		width: 100%;
		text-align: center;

		padding: 10rpx 0;
	}

	.top-nav {
		position: relative;
	}

	.top-nav img {
		width: 375px;
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		image-rendering: crisp-edges;
		-ms-interpolation-mode: nearest-neighbor;

	}

	.tit {
		position: absolute;
		width: 375px;
		text-align: center;
		left: 0;
		bottom: 10px;

	}

	.stitle {
		font-size: 14px;
		font-weight: 600;
		flex-shrink: 0;
	}

	.sline {
		border-bottom: 1px solid #9d9d9d;
		margin: 12px 0;
	}
	.clslist{
		display:flex;
		
	}
	.clslist .clsitem{
		margin-right: 20px;
		padding: 10px;
	}
</style>