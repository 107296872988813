<template>
  <div id="notice">
    <div class="tips">
      <p>文字过长，文字会自动滚动</p>
    </div>
    <div class="list1">
      <div class="title">
        <span>公告 </span>
      </div>
      <el-input
        placeholder="请填写公告内容"
        v-model="list.options.notice"
        size="small"
      ></el-input>
    </div>
    <!-- 背景颜色 -->
    <div class="list">
      <div class="title">
        <span>背景颜色</span>{{ list.options.background || "默认"
        }}<span></span>
      </div>
      <div class="btn">
        <el-button type="text" class="reset">重置</el-button
        ><el-color-picker
          v-model="list.options.background"
          size="medium"
        ></el-color-picker>
      </div>
    </div>
    <!-- 文字颜色 -->
    <div class="list">
      <div class="title">
        <span>文字颜色</span>{{ list.options.textcolor || "默认" }}<span></span>
      </div>
      <div class="btn">
        <el-button type="text" class="reset">重置</el-button
        ><el-color-picker
          v-model="list.options.textcolor"
          size="medium"
        ></el-color-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "小程序模块话Notice",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.list = this.data;
  },
  data() {
    return {
      list: {},
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
#notice {
  margin: 20px;
}
.tips {
  margin-bottom: 20px;
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.list1 {
  margin-bottom: 20px;
  .title {
    margin-bottom: 10px;
  }
}
.title {
  font-size: 14px;

  :first-child {
    padding-right: 10px;

    color: #969799;
  }
}
.btn {
  display: flex;
  align-items: center;
  :first-child {
  }
  .reset {
    margin-right: 10px;
  }
}
</style>