<template>
	<div>
		<div class="menu_img_title" :style="{
        background: bgcolor,
        height: height,
      }">
			<div class="bgcont" v-if="
          list.options && list.options.bgimg && list.options.bgimg.length > 0
        ">
				<img :src="
            list.options.bgimg && list.options.bgimg.length > 0
              ? list.options.bgimg
              : ''
          " />
			</div>

			<div class="imgcont" :style="{
          left: marginpage,
          top: margintop,
          'padding-bottom': itempaddingtop,
          background: contcolor,
          'border-radius': borderradius,
          width: contwidth,
        }">
				<div class="list" :class="direction ? 'flex-row' : 'flex-col'">
					<div v-for="(item, index) in list.data" :key="index" class="item" :style="{
              'padding-top': itempaddingtop,
			  'padding-bottom':itempaddingtop,
              width: itemwidth,
							'border-bottom':linecolor,
            }">
						<img :style="{
			      width: imgsize,
			      height: imgsize,
				  'padding-left':direction == false? itempaddingtop:0
			    }" :src="item.url" />
						<div class="title" :style="{
			      'line-height': lineheight,
			      height: lineheight,
			      color: list.options.titlecolor,
			      fontSize: size,
			      fontWeight: bold,
			    }">
							{{ item.title }}
						</div>

						<div :style="{'padding-right':itempaddingtop}" class="gt" v-if="direction == false">&gt;</div>


					</div>
				</div>

				<div class="empty" v-show="data.data.length == 0">
					<el-empty description="此区域放置图片"></el-empty>
				</div>
			</div>

			<!-- <el-image style="width: 100%" :src="url"></el-image> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "image_row",
		components: {},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				list: "",
			};
		},

		mounted() {
			this.list = this.data;
		},
		computed: {
			direction() {
				if (this.list.options) {
					if (this.list.options.direction)
						return this.list.options.direction == 1; //row
				}
				return true;
			},
			itempaddingtop() {
				if (this.list.options) {
					if (
						this.list.options.itempaddingtop &&
						this.list.options.itempaddingtop >= 0
					)
						return this.list.options.itempaddingtop + "px";
				}
				return "auto";
			},
			imgsize() {
				if (this.list.options) {
					if (this.list.options.imgsize && this.list.options.imgsize > 0)
						return this.list.options.imgsize + "px";
				}
				return "40px";
			},
			itemwidth() {
				if (this.list.options) {
					if (this.direction) {
						if (this.list.options.itemnum && this.list.options.itemnum > 0) {
							if (this.list.options.itemnum == 5) {
								return "20%";
							} else if (this.list.options.itemnum == 4) {
								return "25%";
							} else if (this.list.options.itemnum == 2) {
								return "50%";
							}
						}
					} else {
						return "100%";
					}
				}

				return "33.3%";
			},
			lineheight() {
				if (this.list.options) {
					if (this.list.options.lineheight && this.list.options.lineheight > 0)
						return this.list.options.lineheight + "px";
				}
				return "24px";
			},
			bgcolor() {
				if (this.list.options) {
					if (this.list.options.bgcolor && this.list.options.bgcolor.length > 0)
						return this.list.options.bgcolor;
				}
			},
			contcolor() {
				if (this.list.options) {
					if (
						this.list.options.contcolor &&
						this.list.options.contcolor.length > 0
					)
						return this.list.options.contcolor;
				}
			},
			linecolor() {
				if (this.list.options) {
					if (this.direction) {
						return 'none';
					} else {
						if (
							this.list.options.linecolor &&
							this.list.options.linecolor.length > 0
						)
							return '1px solid ' + this.list.options.linecolor;
					}

				} else {
					return 'border:1px solid #f0f0f0';
				}
			},
			contwidth() {
				if (this.list.options) {
					let w = 750 / 2 - this.list.options.marginpage * 2;
					return w + "px";
				}
				return "auto";
			},
			height() {
				if (this.list.options) {
					if (this.list.options.height && this.list.options.height > 0)
						return this.list.options.height / 2 + "px";
				}
			},
			width() {
				if (this.list.options) {
					if (this.list.options.width && this.list.options.width > 0)
						return this.list.options.width / 2 + "px";
				}
			},
			margintop() {
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + "px";
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (
						this.list.options.marginbottom &&
						this.list.options.marginbottom > 0
					)
						return this.list.options.marginbottom + "px";
				}
			},
			marginpage() {
				if (this.list.options) {
					if (this.list.options.marginpage && this.list.options.marginpage > 0)
						return this.list.options.marginpage + "px";
				}
				return "0px";
			},
			borderradius() {
				if (this.list.options) {
					if (
						this.list.options.borderradius &&
						this.list.options.borderradius > 0
					)
						return this.list.options.borderradius + "px";
				}
			},
			//  标题字体大小
			size() {
				if (this.list.options.size == 3) {
					return "12px";
				} else if (this.list.options.size == 2) {
					return "14px";
				} else {
					return "16px";
				}
			},
			// 标题加粗
			bold() {
				if (this.list.options.bold == 2) {
					return "900";
				} else {
					return "300";
				}
			},
		},

		methods: {},
	};
</script>

<style lang="less" scoped>
	.menu_img_title {
		position: relative;
		width: 100%;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		overflow: hidden;
		justify-content: space-between;

		.bgcont {
			width: 100%;

			img {
				width: 100%;
			}
		}

		.imgcont {
			overflow: hidden;
			position: absolute;
			width: 100%;

			.flex-row {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-start;
			}

			.flex-col {
				flex-wrap: wrap;
			}

			.item {
				margin: 0 auto;

				&:nth-last-child(1) {
					border: none !important;
				}
			}

			.flex-col .item {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				padding-left: 2px;

			}

			.flex-col .item .title {
				flex-grow: 1;
				text-align: left;
				padding-left: 10px;
			}

			.flex-col .item .gt {
				color: #d9d7d7;
				padding-right: 5px;
			}

			.item img {
				margin: 0 auto;
			}
		}
	}

	.empty {
		width: 400px;
		// text-align: center;
		// display: flex;
		// flex-wrap: wrap;
	}

	.title {
		height: 40px;
		line-height: 40px;
	}
</style>