/**

* 头部菜单
*/

<template>
	<div class="navcont"  v-if="showmenu">
		<el-menu class="el-menu-demo" mode="horizontal" background-color="#ffffff" text-color="#222222"
			active-text-color="#3388FF" collapse-transition router :default-active="$route.path" unique-opened>
			<div class="logo" @click="funback">
				<img src="../../assets/image/logo.png" alt="logo" />
			</div>
			<el-menu-item v-for="(menu,cindex) in allmenu" :index="'/'+ menu.url" :key="cindex">
				<!-- <i class="iconfont" :class="menu.icon"></i> -->
				<span>{{menu.menuname}}</span>
			</el-menu-item>



		</el-menu>
		<div class="posibox" @mouseleave="funhideitem" @mouseover="funshowitem">
			<div style="display: flex;align-items: center;height:61px ;">
				<img class="headlogo" src="../../assets/image/icon_defaultlogo.jpg" />
				<div>{{userInfo.unitName}}</div>
			</div>
			
			<div class="userbox" v-if="showitem">
				<div class="row">
					<img class="headlogo2" src="../../assets/image/icon_defaultlogo.jpg" />
					<div class="row_right">
			
						<div>{{userInfo.unitName}} </div>
						<div style="font-size: 12px;margin-top: 10px;">单位编号：{{userInfo.unitno}}</div>
					</div>
				</div>
				<div class="selitem" style="border-bottom: 1px solid #EBEEF5;" @click="funmarket">
					<div style="flex-grow: 1;">模板市场</div>
				</div>
				<div class="selitem" style="border-bottom: 1px solid #EBEEF5" @click="funorder">
					<div style="flex-grow: 1;">我的订单</div>
				</div>
				<div class="selitem" style="border-bottom: 1px solid #EBEEF5" @click="funapp">
					<div style="flex-grow: 1;">我的小程序</div>
				</div>
				<div class="selitem" style="border-bottom: 1px solid #EBEEF5" @click="exit">
					<div style="flex-grow: 1;">退出登录</div>
				</div>
				
			</div>
		</div>
		
		
	</div>


	</div>
</template>
<script>
	import {
		delloginall,
		getloginuser
	} from '../../utils/util.js'
	export default {
		name: 'navcon',
		data() {
			return {
				collapsed: true,
				imgshow: require('../../assets/img/show.png'),
				imgsq: require('../../assets/img/sq.png'),
				userInfo: {},
				allmenu: [],
				showitem:false,
				showmenu:true
			}
		},
		// 创建完毕状态(里面是操作)
		created() {
			let screenWidth = window.innerWidth
			console.log(screenWidth,'navcon.screenWidth');
			if(screenWidth <= 750){
				this.showmenu = false
			}else{
				this.showmenu = true
			}
			this.init();
			let res = {
				success: true,
				data: [
					{
						menuid: 24,
						icon: 'icon-order-manage',
						menuname: '首页',
						hasThird: 'N',
						url: "",
						menus: null,
					},
					{
						menuid: 11,
						icon: 'icon-order-manage',
						menuname: '应用管理',
						hasThird: 'N',
						url: 'customer/manage/app',
						menus: null,
					}, {
						menuid: 21,
						icon: 'icon-order-manage',
						menuname: '我的小程序',
						hasThird: 'N',
						url: 'customer/xcxtemplate/index',
						menus: null,
					},
					// {
					// 	menuid: 22,
					// 	icon: 'icon-order-manage',
					// 	menuname: '我的模板',
					// 	hasThird: 'N',
					// 	url: 'customer/xcxtemplate/templatebase',
					// 	menus: null,
					// },
					{
						menuid: 23,
						icon: 'icon-order-manage',
						menuname: '我的订单',
						hasThird: 'N',
						url: 'customer/order/temporderlist',
						menus: null,
					}, {
						menuid: 25,
						icon: 'icon-order-manage',
						menuname: '我的模板',
						hasThird: 'N',
						url: 'customer/xcxtemplate/mytemplata',
						menus: null,
					},
				{
					menuid: 26,
					icon: 'icon-order-manage',
					menuname: '模板市场',
					hasThird: 'N',
					url: 'customer/xcxtemplate/marketlist',
					menus: null,
				}

					// {
					// 	menuid: 25,
					// 	icon: 'icon-order-manage',
					// 	menuname: '我的模版',
					// 	hasThird: 'N',
					// 	url: 'customer/xcxtemplate/templatebase',
					// 	menus: null,
					// },
				],
				msg: 'success',
				
			}
			this.allmenu = res.data

		},
		methods: {
			init() {
				//let u = sessionStorage.getItem('customeruser')
				let u = getloginuser()
				if (u != null) {
					this.userInfo = u;
				} else {
					//this.$router.push("/");
				}
			},
			// 退出登录
			exit() {
				this.$confirm('退出登录, 是否继续?', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						setTimeout(() => {
							// this.$store.commit('customerloginout', "")
							this.$router.push({
								path: '/customer/login'
							})
							// delCookie("login_uid");
							// delCookie("login_uname");
							// sessionStorage.clear()
							console.log('delall');
							delloginall()
							this.$message({
								type: 'success',
								message: '已退出登录!'
							})
						}, 1000)
					})
					.catch(() => {

					})
			},
			funmarket(){
			this.$router.push({
				path: '/customer/xcxtemplate/marketlist'
			})
			},
			funorder(){
				this.$router.push({
					path: '/customer/order/temporderlist'
				})
			},
			funapp(){
				this.$router.push({
					path: '/customer/xcxtemplate/index'
				})
			},
			funshowitem() {
				this.showitem = true
				console.log('showshowshow');
			},
			funhideitem() {
				this.showitem = false
				console.log('showshowshow');
			},
			handleCommand(command) {
				if (command == 'quit') {
					this.exit()
				}
			},
			funback() {

			},
			// 切换显示
			toggle(showtype) {
				this.collapsed = !showtype
				this.$root.Bus.$emit('toggle', this.collapsed)
			}

		}
	}
</script>

<style scoped>
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		border: none;
	}

	.submenu {
		position: absolute;
		top: 0;
		right: 0;

	}

	.submenu>>>.el-submenu__title {
		background-color: transparent !important;
	}

	.buttonimg {
		height: 60px;
		background-color: transparent;
		border: none;
	}

	.showimg {
		width: 26px;
		height: 26px;
		position: absolute;
		top: 17px;
		left: 17px;
	}

	.showimg:active {
		border: none;
	}

	.el-menu-demo {
		position: relative;
		display: flex;
		align-items: center;

		flex-shrink: 0;
	}

	.el-menu-demo .logo {
		padding: 0 30px;
		width: 175px;
		height: 40px;
	}

	.el-menu-demo .logo img {
		width: 100%;
		height: 100%;
	}

	.navcont {
		position: relative;
	}

	.posibox {
		position: absolute;
		top: 0px;
		right: 20px;
		cursor: pointer;
	}

	.headlogo {
		width: 30px;
		height: 30px;
		border-radius: 30px;
		margin-right: 20px;
	}

	.userbox {
		width: 300px;
		height: 300px;
		background-color: #FFFFFF;
		color: #4c4c4c;
		position: absolute;
		right: 0;
		top: 60px;
		font-size: 14px;
		box-shadow: 0 1px 11px rgba(0, 0, 0, .15) !important;
		border-bottom-right-radius: 3px;
		border-bottom-left-radius: 3px;
		z-index: 9999;
	}


	.headlogo2 {
		height: 60px;
		width: 60px;
		border-radius: 60px;
		margin-right: 10px;
		cursor: pointer;
	}





	.selitem {
		padding: 16px 8px;
		display: flex;
		align-items: center;
		border-bottom: 1px solid #E9ECEF;
		cursor: pointer;
	}

	.row {
		display: flex;
		align-items: center;
		padding: 8px;
		border-bottom: 1px solid #E9ECEF;
	}

	@media screen and (max-width: 414px) {}
</style>