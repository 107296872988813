<template>
  <!-- 设置背景样式 -->
  <div
    id="wenben"
    :style="{ background: list.options.background, borderBottom: border }"
  >
    <!-- 标题文字 -->
    <!-- 绑定标题样式 -->
    <div class="text">
      <p
        :style="{
          fontSize: size,
          textAlign: align,
          fontWeight: bold,
          color: list.options.titlecolor,
        }"
      >
        {{ list.options.name || "标题内容区域" }}
      </p>
    </div>
    <!-- 描述文字 -->
    <p
      class="describe"
      :style="{ fontSize: describesize, color: list.options.describecolor }"
    >
      {{ list.options.label }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      list: {},
    };
  },

  created() {
    this.list = this.data;
  },
  computed: {
    //  标题字体大小
    size() {
      if (this.list.options.size == 3) {
        return "12px";
      } else if (this.list.options.size == 2) {
        return "14px";
      } else {
        return "16px";
      }
    },
    // 标题显示位置
    align() {
      if (this.list.options.position == 1) {
        return "left";
      } else if (this.list.options.position == 2) {
        return "center";
      }
    },
    // 描述文字大小
    describesize() {
      if (this.list.options.describe == 3) {
        return "12px";
      } else if (this.list.options.describe == 2) {
        return "14px";
      } else {
        return "12px";
      }
    },
    // 标题加粗
    bold() {
      if (this.list.options.bold == 2) {
        return "900";
      } else {
        return "300";
      }
    },
    // 底部分割线
    border() {
      if (this.list.options.border) {
        return "1px solid #cccccc";
      } else {
        return "";
      }
    },
  },
  methods: {},
};
</script>

<style lang="less" scoped>
#wenben {
  width: 400px;
  box-sizing: border-box;
}
.text {
  overflow: hidden;
  line-height: 30px;
  font-size: 16px;
  p {
    margin: 0 20px;
  }
}
.describe {
  line-height: 30px;
  margin: 0 20px;
  font-size: 12px;
  word-wrap: break-word;
}
</style>