<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="21"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>二维码</span>
					</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
						<el-form-item label="小程序APPID" prop="title">
							<el-input v-model="ruleForm.appid" placeholder="请输入APPID" size="small"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="二维码名称" prop="title">
							<el-input v-model="ruleForm.title" placeholder="请输入二维码名称" size="small"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="是否需要验证" prop="isverify">
							<el-switch v-model="ruleForm.isverify" active-color="#13ce66" inactive-color="#ff4949">
							</el-switch>
						</el-form-item>
						<el-form-item label="是否临时二维码" prop="istemp">
							<el-switch v-model="ruleForm.istemp" active-color="#13ce66" @change="changeswitch"
								inactive-color="#ff4949">
							</el-switch>
						</el-form-item>
						<el-form-item label="使用成员"  prop="manjson" v-if="!ruleForm.istemp">
							<el-select style="margin-bottom: 20px;margin-right: 20px;" v-model="ruleForm.manjson" multiple filterable allow-create default-first-option
								size="small" placeholder="请选择成员">
								<el-option v-for="item in manData" :key="item.id" :label="item.manname" :value="item.id">
									<span style="float: left">{{ item.manname }}</span>
									<span style="  font-size: 13px ;margin-right: 5px;margin-left: 15px;" :class="'auth' + item.islinkauth">{{ item.islinkauth  == 1 ? '微信授权' : '--'}}</span>
									<span style="  font-size: 13px" :class="'auth' + item.isbaseauth">{{ item.isbaseauth  == 1 ? '基础授权' : '--'}}</span>
								</el-option>
							</el-select>
							<el-button type="primary" size="small" @click="funAddPerson">添加</el-button>
							<el-table :data="personData" stripe style="width: 100%" border>
								<el-table-column prop="name" label="名称" width="180">
								</el-table-column>
								<!-- <el-table-column prop="manno" label="名称" width="180">
								</el-table-column> -->
								<!-- <el-table-column prop="num" label="客户上限" width="180">
									<template slot-scope="scope">
										<el-input-number v-model="scope.row.num" :min="1" :max="10"
											label="请输入客户上限"></el-input-number>
									</template>
								</el-table-column> -->
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-button type="text" size="small" @click="fundelperson(scope.$index)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
						<el-form-item label="使用成员" prop="grouptip" v-else="ruleForm.istemp">
							<el-select v-model="ruleForm.manjson" size="small" placeholder="请选择成员" @change="funselPerson">
								<el-option v-for="item in manData" :key="item.id" :label="item.manname" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="入群引导语" prop="grouptip">
							<el-input type="textarea" rows="5" v-model="ruleForm.grouptip" placeholder="请输入入群引导语"
								maxlength="260" show-word-limit></el-input>
							<div class="addaffix" @click="funAddaffix">
								<div class="list">
									<div class="list-item" v-for="(item,index) in ruleForm.welcomejson" :key="index">
										<span v-if="item.msgtype == 'image'">【图片】</span><span
											v-if="item.msgtype == 'link'">【链接】</span><span
											v-if="item.msgtype == 'miniprogram'">【小程序】</span>{{item.title}}
											<!-- <img :src="item" style="width: 50px;"> -->
										<div class="del" @click.stop="fundelaffix(index)">
											删除
										</div>
									</div>
								</div>
								<span>添加附件</span>
							</div>
						</el-form-item>
						<!-- <el-form-item label="选择群聊" prop="xcxurl">
							<p>1、可添加多个群聊，当前面的群人数达到上限后，自动发送后面的群二维码</p>
							<p>2、群人数上限最多为200人</p>
							<p>3、上传群二维码时请【仔细核对】，群二维码和群聊【对应错误】将导致【拉群任务失败】</p>
							<el-button type="primary" @click="funaddewm('ruleForm')">添加</el-button>
							<el-table :data="groupsData" border style="width: 100%">
								<el-table-column prop="pic" label="群活码图片" width="180">
									<template slot-scope="scope">
										<img :src="scope.row.pic" style="width: 80px;height: 80px;" />
									</template>
								</el-table-column>
								<el-table-column prop="chatname" label="群名称">
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="100">
									<template slot-scope="scope">
										<el-button type="text" size="small" @click="fundelgroup(scope.$index)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item> -->
						<el-form-item>
							<div>
								<div class="btnbox">
									<el-button style="width: 180px;" type="primary" size="medium"
										@click="submitForm('ruleForm')" v-if="isedit">保存配置</el-button>
										<el-button style="width: 180px;" type="primary" size="medium"
											@click="editForm('ruleForm')" v-else>编辑配置</el-button>
									<el-button style="width: 160px" size="medium" @click="funback">返回</el-button>
								</div>
						
							</div>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')" v-if="isedit">保存活码</el-button>
							<el-button type="primary" @click="editForm('ruleForm')" v-else>编辑活码</el-button>
						</el-form-item>
					</el-form>
				</el-card>
				<el-dialog :visible.sync="groupVisible" width="30%" @click='groupVisible=false'>
					<el-form label-width="150px">
						<el-select v-model="ruleForm.groupchatjson" filterable size="small" placeholder="请选择群聊">
							<el-option v-for="item in groupData" :key="item.id" :label="item.chat_name" :value="item.id">
							</el-option>
						</el-select>
						<!-- <el-form-item label="人数" prop="number">
							<el-input size="small" v-model="ewmForm.number" auto-complete="off" placeholder="请输入人数"></el-input>
						</el-form-item> -->
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" type="primary" class="title" @click="funAddgroup()">确定
						</el-button>
					</div>
				</el-dialog>
				</div>
				</div>
		
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
		<selaffix ref="selaffix" @selaffixresult="selaffixresult"></selaffix>
	</div>
</template>

<script>
	import selImgs from "@/components/system/selImgs";
	import selaffix from "@/components/customer/selaffix";
	import personApi from '../../../../../../api/cus/person.js';
	import groupApi from '../../../../../../api/cus/group.js'
	import qrcodeApi from '../../../../../../api/cus/qrcode.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			selImgs,
			selaffix,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				mxid: "",
				manData: [],
				personData: [],
				groupData: [],
				groupsData: [],
				ruleForm: {
					title: '',
					appid:'',
					memo: '',
					scene: 2,
					isverify: 0,
					istemp: 0,
					grouptip: '你好，我是XXXX客服，欢迎扫码进群，享受更多福利',
					welcomejson: [],
					manjson: [],
					groupchatjson: [],
				},
				isedit: false,
				groupVisible: false,
				rules: {}
			};
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				let mxid = this.$route.query.mxid
				console.log(mxid, 'mxidmxidmxid');
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				if (mxid != undefined) {
					this.mxid = mxid
					this.isedit = false
					this.getdetail()
				} else {
					this.isedit = true
				}
				this.getlist()
				this.getgrouplist()
			},
			getlist(page) {
				let param = {
					mpid: this.mpid,
					pageno: 1,
					pagesize: 100,
				}
				this.loading = true
				personApi.list(param, res => {
					console.log(res, 'res');
					if (res.code == 200) {
						this.loading = false
						res.data.list.forEach(item => {
							this.manData.push({
								id: item.id,
								manno:item.manno,
								manname: item.manname,
								islinkauth: item.islinkauth,
								isbaseauth: item.isbaseauth
							})
						})
						this.manData = res.data.list
					}
				})
			},
			getgrouplist() {
				let param = {
					mpid: this.mpid,
					name: '',
					pageno: 1,
					pagesize: 100
				}
				groupApi.list(param, res => {
					console.log(res, 'reslist');
					if (res.code == 200) {
						this.groupData = res.data.list
					}
				})
			},
			getdetail() {
				let param = {
					mxid: this.mxid,
				}
				groupApi.detail(param, res => {
					console.log(res, 'resdetail');
					if (res.code == 200) {
						this.ruleForm.title = res.data.title
						this.ruleForm.istemp = res.data.istemp == 0 ? false : true
						this.ruleForm.isverify = res.data.isverify == 0 ? false : true
						this.personData = res.data.manjson
						this.ruleForm.grouptip = res.data.grouptip
						this.ruleForm.welcomejson = res.data.welcomejson
					}
				})
			},
			changeswitch() {
				this.ruleForm.manjson = ''
			},
			funback() {
				this.$router.push({
					path: '/customer/home/mp/weixinqiye/qrcode/index?mpid=' + this.mpid,
				})
			},
			submitForm(formName) {
				let manjson =[];
				console.log(this.manData,this.ruleForm.manjson,'sub');
				if(this.ruleForm.istemp){
				 let __man =	this.manData.find(f=>{
						return f.id == this.ruleForm.manjson;
					})
					if(__man != undefined){
						manjson.push({
						name: __man.manname,
						manno: __man.manno,
					});
					}
					
				}
				else{
					 manjson = this.personData
				}
			
				


				this.$refs[formName].validate((valid) => {
					if (valid) {
						let param = {
							mpid: this.mpid,
							title: this.ruleForm.title,
							appid:this.ruleForm.appid,
							scene: 2,
							isverify: this.ruleForm.istemp == false ? 1 : 2,
							manid: '',
							manmc: '',
							manno: '',
							istemp: this.ruleForm.istemp == false ? 0 : 1,
							groupchatjson: this.groupsData,
							qrtype: 1,
							manjson: manjson,
							welcomejson: this.ruleForm.welcomejson,
							grouptip: this.ruleForm.grouptip,
							appid:'',
							isticket:1,
						}
						console.log(param, 'param');
						qrcodeApi.add(param, res => {
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: res.msg
								})
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			editForm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let param = {
							id: this.mxid,
							mpid: this.mpid,
							title: this.ruleForm.title,
							scene: 2,
							isverify: this.ruleForm.istemp == false ? 1 : 2,
							manid: '',
							manmc: '',
							manno: '',
							istemp: this.ruleForm.istemp == false ? 0 : 1,
							groupchatjson: this.groupsData,
							qrtype: 1,
							manjson: this.personData,
							welcomejson: this.ruleForm.welcomejson,
							grouptip: this.ruleForm.grouptip
						}
						console.log(param, 'param');
						qrcodeApi.edit(param, res => {
							if (res.code == 200) {
								this.$message({
									type: 'success',
									message: res.msg
								})
							}
						})

					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			funaddewm() {
				this.groupVisible = true
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.ruleForm.picurl = imgitem.url
			},
			funAddPerson() {
				let newArr = []
				this.ruleForm.manjson.forEach(f => {
					this.manData.forEach(f2 => {
						if (f == f2.id) {
							newArr.push(f2)
						}
					})
				})
				newArr.forEach(f => {
					console.log(f, 'f');
					this.personData.push({
						name: f.manname,
						manno: f.manno,
					})
				})
			},
			funselPerson(v){
					console.log(v,this.ruleForm.manjson);
					// let item = this.ruleForm.manjson.find(f=>{
					// 	return f.id == v;
					// })

					// if(item != undefined){
					// 	this.personData = [{
					// 	name: item.manname,
					// 	manno: item.manno,
					// }]
					// }
					
			},
			fundelperson(index) {
				this.personData.splice(index, 1)
			},
			
			funAddgroup() {
				this.groupVisible = false
				console.log(this.groupData, 'this.groupsData');
				let f = this.groupData.find(f => f.id == this.ruleForm.groupchatjson)
				console.log(find, ';');
				if (f != undefined) {
					this.groupsData.push({
						id: f.id,
						chatid: f.chat_id,
						chatname: f.chat_name
					})
				}
			},
			fundelgroup(index) {
				this.groupsData.splice(index, 1)
			},
			funAddaffix() {
				this.$refs['selaffix'].funShow(this.mpid)
			},
			fundelaffix(index){
				this.ruleForm.welcomejson.splice(index, 1)
			},
			selaffixresult(param) {
				console.log(param, 'param');
				this.ruleForm.welcomejson.push(param)
			}
		}
	}
</script>

<style scoped>
	.title-tips {
		padding-left: 28px;
		font-weight: 650;
		font-style: normal;
		color: #f59a23;
		font-size: 14px;
	}

	.addaffix {
		cursor: pointer;
		padding-left: 10px;
		border: 1px solid #DCDFE6;
		border-top: 0px solid #DCDFE6;
	}

	.addaffix:hover {
		opacity: 0.8;
	}

	.list {
		display: flex;
		flex-direction: column;
	}
	.list-item{
		position: relative;
	}
	.del{
		position: absolute;
		right: 10px;
		top: 0px;
	}
	.page {
	
		background: #F5F7FA;
	
	}
	
	.rightbox {
		padding-top: 20px;
		width: 1320px;
		overflow: auto;
	}
	
	.pagecontent {
		display: flex;
	
		height: calc(100vh - 81px);
	
	}
	.auth1{
		color: #00aa00;
	}
	.btnbox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}
</style>