import { render, staticRenderFns } from "./divider.vue?vue&type=template&id=58d0eefe&scoped=true&"
import script from "./divider.vue?vue&type=script&lang=js&"
export * from "./divider.vue?vue&type=script&lang=js&"
import style0 from "./divider.vue?vue&type=style&index=0&id=58d0eefe&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58d0eefe",
  null
  
)

export default component.exports