<template>
	<div class="stylesetting">
		<section class="menulist">
			<div class="item" v-for="(item,index) in pagelist" :key="index" @click="fungotopage(item)">
				{{ item.pagename }}</div>
			<div class="item" @click="funtabbar()">菜单</div>
			<div class="item" @click="funStyle">风格</div>
			<div class="item selected">设置</div>
		</section>
		<el-card class="box-card">

			<div slot="header" class="clearfix">
				<span>设置</span>
				<el-button style="float: right; padding: 3px 0" type="text" @click="funback">返回上一页</el-button>
			</div>
			<div class="main">
				<!-- <div class="disp">
					企业号：<el-input size="small" v-model="from.mchno" class="input"></el-input>
				</div>
				<div class="disp">
					门店号：<el-input size="small" v-model="from.mdid" class="input"></el-input>
				</div>
				<div class="disp">
					企业类型：<el-select v-model="from.mchtype" placeholder="请选择" size="small">
						<el-option v-for="item in mchtypelist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
					<el-button size="small" type="primary" @click="funsavemch">保存企业信息</el-button>
				</div> -->
				<div class="disp">
					模板id：{{from.tmpid}}
				</div>
				<div class="disp">
					修改模板名称：<el-input size="small" v-model="from.name" class="input"></el-input><el-button size="small"
						type="primary" @click="funsavemc">保存</el-button>
				</div>
				<div class="disp">
					修改模板logo：<el-input size="small" v-model="from.logo" class="input"></el-input><el-button size="small"
						type="primary" @click="funupload">上传</el-button><el-button size="small" type="primary"
						@click="funupsave">保存</el-button>
				</div>
				<div class="logo">
					<el-image :src="from.logo"></el-image>
				</div>
			</div>
		</el-card>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
		<showload :showload="showload"></showload>
	</div>
</template>

<script>
	import showload from "@/components/loading";
	import selImgs from "@/components/designer/selImgs";
	import designTemplataApi from "../../../api/isv/designtmplate";
	export default {
		components: {
			selImgs,
			showload
		},
		data() {
			return {
				from: {
					logo: "",
					tmpid:"",
					name: ""
				},
				mchtypelist: [{
						id: '91erp',
						mc: '大新酒吧系统'
					},
					{
						id: 'yeying',
						mc: '夜莺酒吧系统'
					},
				],
				showload:false,
				pagelist: [],
				templateid: "",
			}
		},
		mounted() {
			let templateid = this.$route.query.tid
			this.templateid = templateid
			this.initdata()
			console.log(this.templateid, 'this.templateid');
		},
		methods: {


			initdata() {
				let _this = this;
				this.showload = true
				designTemplataApi.getUserPageList({
					id: this.templateid
				}, res => {
					this.showload = false
					console.log(res, 'getUserPageList');
					if (res.code == 200) {
						this.pagelist = res.data.pagelist
						this.from.tmpid = res.data.appTmplate.tmpid
						this.from.name = res.data.appTmplate.tmpname
						this.from.logo = res.data.appTmplate.logo
					}
				})
			},
			fungotopage(item) {
				this.$router.replace({
					path: "/designer/xcxtemplate/design",
					query: {
						id: item.id,
						tid: item.tmpid,
						t: item.pageno,
						mc: item.pagename,
					}
				})
			},
			funupload() {
				this.$refs.selImgs.funShow();
			},
			selImgsresult(imgitem) {
				this.from.logo = imgitem.url;
			},
			funsavemc() {
				let param = {
					tmpid: this.templateid,
					name: this.from.name,
				};
				designTemplataApi.editname(param, (res) => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg,
						});
						this.from.name = this.from.name;
					} else {
						this.$message({
							type: "error",
							message: res.msg,
						});
					}
				});
			},
			funupsave() {
				let param = {
					tmpid: this.templateid,
					logo: this.from.logo,
				};
				designTemplataApi.editlogo(param, (res) => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg,
						});
						this.from.logo = this.from.logo;
					} else {
						this.$message({
							type: "error",
							message: res.msg,
						});
					}
				});
			},
			funback() {
				this.$router.go(-1)
			},

			funStyle() {
				this.$router.push({
					path: "/designer/xcxtemplate/style",
					query: {
						tid: this.templateid
					}
				})
			},
			funtabbar() {
				this.$router.push({
					path: "/designer/xcxtemplate/tabbar",
					query: {
						tid: this.templateid
					}
				})
			},
			funsavemch() {
				designTemplataApi.mchsave({
					id: this.templateid,
					mchno: this.from.mchno,
					mdid: this.from.mdid,
					mchtype: this.from.mchtype
				}, res => {
					console.log(res, 'mchsave');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg,
						});
					}
				})
			}
		},

	}
</script>

<style lang="less" scoped>
	.box-card {}

	.stylesetting {
		margin: 0 auto;
		min-width: 1360px;
		width: 100%;
		overflow: hidden;
	}

	.menulist {
		float: left;
		width: 80px;
		height: calc(100vh - 100px);
		position: relative;
		background: #fff;
		border-right: 1px solid #f0f0f0;
		border-radius: 5px;
		overflow-y: auto;
		overflow-x: hidden;
		box-shadow: 0 2px 6px #ccc;

		.selected {
			background: #3089dc;
			color: #fff;
		}
	}

	.menulist .item {
		cursor: pointer;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-weight: 300;
		font-size: 12pt;
		position: relative;
	}

	.menulist .disp {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.disp /deep/.el-input,
	.el-input__inner {
		margin: 20px;
		width: 300px !important;
	}

	.logo {
		width: 200px;
	}

	.logo img {
		width: 100%;
	}
</style>