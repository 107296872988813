<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="17"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>模板消息</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>模板消息</span>
						<div style="float: right;">
							<el-button size="mini" type="primary" @click="handleuse()" v-if="state == 0">启用</el-button>
							<el-button size="mini" @click="handleflush()" v-else>刷新</el-button>
						</div>
					</div>
					<div class="">
						<!--列表-->
						<el-table size="small" :data="tempData" highlight-current-row v-loading="loading"
							:border="false" element-loading-text="拼命加载中" style="width: 100%;">
							<el-table-column align="center" sortable label="模板名称" width="180">
								<template slot-scope="scope">
									<div>{{scope.row.templateNo}}-{{scope.row.templateName}}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" sortable prop="tno" label="模板编号" width="180">
							</el-table-column>
							<el-table-column align="center" sortable prop="isuse" label="状态" width="180">
								<template slot-scope="scope">
									<div>{{scope.row.isuse == 1? "启用":"停用"}}</div>
								</template>
							</el-table-column>
							<el-table-column align="center" sortable prop="createDate" label="日期" width="180">
							</el-table-column>
							<el-table-column label="操作" min-width="100">
								<template slot-scope="scope">
									<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>
				</el-card>
				<!-- 编辑界面 -->
				<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="模板名称" prop="querytype">
							{{editForm.templateNo}}-{{editForm.templateName}}
						</el-form-item>
						<el-form-item label="模板编号" prop="no">
							<el-input size="small" v-model="no" auto-complete="off" placeholder="模板编号"></el-input>
						</el-form-item>
						<el-form-item label="说明" prop="memo">
							{{editForm.memo}}
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" @click='editFormVisible=false'>关闭</el-button>
						<el-button size="small" type="primary" :loading="loading" class="title"
							@click="submitForm('editForm')">保存
						</el-button>
					</div>
				</el-dialog>
			</div>
		</div>

	</div>
</template>

<script>
	import tempApi from '../../../../../../api/cus/template.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixingzh/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				loading: false,
				editFormVisible: false,
				editForm: {},
				state: 0,
				tempData: [],
				no: ""
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.mpid = mpid
			} else {
				this.$router.push("/");
			}
			this.init()
		},
		methods: {
			init() {
				this.loading = true
				tempApi.list(this.mpid, res => {
					console.log(res, 'temp');
					this.loading = false
					if (res.code == 200) {
						this.state = res.data.state
						this.tempData = res.data.list
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			handleEdit(index, row) {
				console.log(row, 'row');
				this.editFormVisible = true
				this.editForm = row
				this.no = row.tno
			},
			handleflush() {
				let param = {
					mpid: this.mpid
				}
				tempApi.flsuh(param, res => {
					console.log(res, 'temp');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.init()
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			handleuse() {
				let param = {
					mpid: this.mpid
				}
				tempApi.open(param, res => {
					console.log(res, 'temp');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "启用成功"
						})
						this.init()
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			submitForm() {
				let param = {
					mpid: this.mpid,
					no: this.no,
					tno: this.editForm.templateNo
				}
				tempApi.edit(param, res => {
					console.log(res, 'temp');
					if (res.code == 200) {
						this.editForm.tno = this.no
						this.$message({
							type: "success",
							message: "编辑成功"
						})
						this.editFormVisible = false
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.pagecontent {
		display: flex;
	
		height: calc(100vh - 81px);
	}
	
	.w200 {
		width: 200px;
	}
	
	.value {
		color: #777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow: 1;
	}
	.btnbox{
		width:100%;
		display:flex;
		justify-content: center;
	} 
	.btnbox .btn{
	width: 200px;
	margin: 20px auto;
	}
	.page {
		background: #F5F7FA;
	}
	
	.border {
		border-bottom: 1px solid #E2E2E2;
	}
	
	.rightbox {
		padding-top: 20px;
		width: 1320px;
	}
</style>