import httpapi from '../../utils/httpapi';
const wxworkApi = {
	list(param, funsucc) {
		httpapi.post('/system/mp/mpaccount/wxwork/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	orderlist(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})


	},
	orderdetail(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/detail', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	cgorderadd(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/cg/editwxorderno', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	orderdel(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/del', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})


	},
	orderadd(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/add', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	orderpayconfim(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/payconfim', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	orderbuyapply(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/buy/apply', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	orderpayreq(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/buy/payreq', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	orderpayquery(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/buy/buy/payquery', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	cancelcgorder(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/buy/cancel', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	wxorderdetail(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/cg/wxorderdetail', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})


	},
	wxorderactive(param, funsucc) {
		httpapi.post('/system/qiyeweixin/license/order/cg/wxorderactive', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	
	}

}
export default wxworkApi