import httpapi from '../../utils/httpapi';
const styleApi = {
	//用户风格列表
	list(param, funsucc) {
		httpapi.post('/customer/design/style/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	clslist(funsucc) {
		httpapi.post('/customer/design/style/cls').then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//用户风格编辑
	edit(param, funsucc) {
		httpapi.post('/customer/design/style/edittemplate', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//编辑自定义风格设置
	editstyle(styleid,stylename,tmpid,param, funsucc) {
		let o ={
			tmpid:tmpid,
			style_id:styleid,
			style_name:stylename,
			style:param
		}
		console.log(o,'editstyle.o');
		httpapi.postjson('/customer/design/style/editstyle',o).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//系统模版市场
	syslist(param, funsucc){
		httpapi.post('/customer/design/market', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
		
		})
	},
	//系统模版市场
	mylist(param, funsucc){
		httpapi.post('/customer/design/mytemp/list', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
		
		})
	},
	//用户程序模版更换系统模版
	changetemplate(param, funsucc) {
		httpapi.post('/customer/design/template/change', param).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	
	},
}
export default styleApi
