<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="25"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-card class="box-card" style="width: 1038px;">
							
							<!--列表-->
							<el-table size="small" :data="list" highlight-current-row v-loading="loading" :border="false"
								element-loading-text="拼命加载中" style="width: 100%;">
								<el-table-column align="center"  prop="resmc" label="名称" width="180">
								</el-table-column> 
								<el-table-column align="center"  prop="linkurl" label="图片" width="580">
									<template v-slot="scope">
										<img style="width: 50px;height: 50px;" :src="scope.row.linkurl" />
									</template>
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="100">
									<template slot-scope="scope">
										<el-button type="text" size="small" @click="fundelimg(scope.row)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<!-- 分页组件 -->
							<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather" ></Pagination>
						</el-card>
			</div>
			</div>
		
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination.vue'
	import resouresApi from '../../../../../../api/cus/resoures.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			Pagination,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				formInline:{
					clsid:0,
					mc:'',
					pageno:1,
					pagesize:10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
				loading: false,
				list:[]
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist(this.formInline)
			},
			getlist(page){
				let param = {
					clsid:page.clsid,
					mc:page.mc,
					pageno:page.pageno,
					pagesize:page.pagesize
				}
				console.log(param,'getlist.param');
				resouresApi.list(param,res=>{
					console.log(res,'reslist');
					if(res.code == 200){
					this.list = res.data.list
					this.pageparm.currentPage = res.data.pageno
					this.pageparm.pageSize = res.data.pagesize
					this.pageparm.total = res.data.total
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.getlist(this.formInline)
			},
			search(){
				this.formInline.page = 1
				this.getlist(this.formInline)
			},
			fundelimg(item){
				let param = {
					id:item.resid
				}
				console.log(param,'getlist.param');
				resouresApi.del(param,res=>{
					console.log(res,'reslist');
					if(res.code == 200){
						this.$message({
							message:res.msg,
							type:'success'
						})
						this.search()
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			}
			
		}
	}
</script>

<style scoped>
	.clearfix{
		display: flex;
		align-items: center;
	}
	.page{
	
	background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
		overflow: auto;
	}
	.pagecontent{
		display: flex;
		
		height: calc(100vh - 81px);
		
	}
</style>