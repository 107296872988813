import httpapi from '../../utils/httpapi';
const chatApi = {
	//A1100.编辑聊天机器人
	edit(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/rebot/edit', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//A1100.设置启用状态
	usestate(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/rebot/usestate/set', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//发送消息到聊天机器人
	sendmsg(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/rebot/sendmsg', param).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//查询消息模版列表
	list(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/rebot/list', param).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//A1100.添加聊天机器人
	rebotadd(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/rebot/add', param).then(res => {
			console.log(res, 'rebot.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default chatApi