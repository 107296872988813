<template>
	<div class="">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>系统风格管理</span>
				<el-button type="primary" size="small" @click="funAdd()">新增</el-button>
			</div>
			<div class="main">
				<el-table :data="styleList" style="width: 100%">
					<!-- <el-table-column prop="tmpid" label="模板编号">
					</el-table-column>
					<el-table-column prop="pageno" label="页面编码">
					</el-table-column>
					<el-table-column prop="systempid" label="系统模板编号" width="120">
					</el-table-column> -->
					<el-table-column prop="style_id" label="页面风格编号" width="200">
					</el-table-column>
					<el-table-column prop="style_name" label="页面风格名称" width="120">
						<template slot-scope="scope">
							<span :style="{color:scope.row.subject1_bg_color}">{{scope.row.style_name}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="clsmc" label="分类名称" width="180">
					</el-table-column>
					<el-table-column prop="style_color" label="主题色" width="180">
						<template slot-scope="scope">
							<div style="width: 50px;height: 20px;border-radius: 5px;" :style="{background:scope.row.style_color}"></div>
						</template>
					</el-table-column>
					
					<el-table-column fixed="right" label="操作" width="100">
						<template slot-scope="scope">
							<el-button type="text" size="small" @click="funedit(scope.row)">编辑</el-button>
							<el-button type="text" size="small" @click="funcopy(scope.row)">复制</el-button>
						</template>
					</el-table-column>
				</el-table>
				<el-dialog :title="title" :visible.sync="dialogVisible" width="80%" style="margin-top: -130px;">
					<div style="display: flex;">
						
					
					<div class="boxs" style="display: flex;flex-direction: column;margin-top: 30px;width: 500px;flex-shrink: 0;">
						<div class="row">
							<span style="margin-right: 10px;">分类</span><el-select size="medium" v-model="clsid"  placeholder="请选择分类">
							   <el-option
							     v-for="item in clslist"
							     :key="item.id"
							     :label="item.mc"
							     :value="item.id">
							   </el-option>
							 </el-select>
						</div>
						<div class="row" style="margin-top: 20px;margin-bottom: 20px;">
							<span style="margin-right: 10px;">页面风格名称</span>
							<el-input size="medium" v-model="style_name"></el-input>
						</div>
						
						 
						<div>
							<el-tabs type="border-card">
								<el-tab-pane label="主体背景">
									<div class="item">
										<span><span
												style="color:#ff0000">***</span>风格背景颜色</span>{{styleitem.subject1_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.subject1_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('subject1_bg_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span><span
												style="color:#ff0000">***</span>风格字体色</span>{{styleitem.subject1_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.subject1_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('subject1_txt_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span><span
												style="color:#ff0000">***</span>设计页背景颜色</span>{{styleitem.subject2_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.subject2_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('subject2_bg_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span><span
												style="color:#ff0000">***</span>设计页字体色</span>{{styleitem.subject2_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.subject2_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('subject2_txt_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span><span style="color:#ff0000">***</span>页面背景色</span>{{styleitem.page_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.page_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('page_bg_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span><span style="color:#ff0000">***</span>页面字体色</span>{{styleitem.page_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.page_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('page_txt_color')">重置</span>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane label="页头">
									<div class="item" style="flex-shrink: 0;white-space: nowrap;">
										<span>顶部字体色（白/黑）</span>{{styleitem.page_title_txt_color}}
										<div class="btn">
											<!-- <el-color-picker v-model="styleitem.page_title_txt_color"
													size="medium"></el-color-picker> -->
											<el-radio-group v-model="styleitem.page_title_txt_color" size="mini">
												<el-tooltip  class="item" effect="dark" content="黑色" placement="bottom">
													<el-radio-button label="#000000"><i
															class="el-icon-s-fold"></i></el-radio-button>
												</el-tooltip>
												<el-tooltip class="item" effect="dark" content="白色" placement="bottom">
													<el-radio-button label="#FFFFFF">
														<i class="el-icon-s-operation"></i>
													</el-radio-button>
												</el-tooltip>
											</el-radio-group>
											<!-- <span @click="setkey('page_title_txt_color')">重置</span> -->
										</div>
									</div>
									<div class="item">
										<span><span
												style="color:#ff0000">***</span>顶部背景色</span>{{styleitem.page_title_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.page_title_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('page_title_bg_color')">重置</span>
										</div>
									</div>
								</el-tab-pane>
								
								<el-tab-pane label="组件">
									<div class="item">
										<span><span style="color:#ff0000">***</span>页面前景色</span>{{styleitem.front_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.front_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('front_bg_color')">重置</span>
										</div>
									
									</div>
									<div class="item">
										<span><span
												style="color:#ff0000">***</span>页面前景字体色</span>{{styleitem.front_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.front_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('front_txt_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span>一级菜单栏字体色</span>{{styleitem.title1_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.title1_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('title1_bg_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span>一级菜单栏选中字体色</span>{{styleitem.title1_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.title1_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('title1_txt_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span>二级菜单栏字体色</span>{{styleitem.title2_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.title2_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('title2_bg_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span>二级菜单栏选中字体色</span>{{styleitem.title2_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.title2_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('title2_txt_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span><span
												style="color:#ff0000">***</span>页脚字体色(备注/简介)</span>{{styleitem.text2_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.text2_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('text2_txt_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span><span style="color:#ff0000">***</span>边框色</span>{{styleitem.text3_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.text3_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('text3_bg_color')">重置</span>
										</div>
									</div>
								</el-tab-pane>
								<el-tab-pane label="其他">
									<div class="item">
										<span>销售价格(标题3背景色)</span>{{styleitem.title3_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.title3_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('title3_bg_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span>划线价(标题3字体色)</span>{{styleitem.title3_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.title3_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('title3_txt_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span>数量</span>{{styleitem.text2_bg_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.text2_bg_color"
												size="medium"></el-color-picker><span
												@click="setkey('text2_bg_color')">重置</span>
										</div>
									</div>
									<div class="item">
										<span>总金额</span>{{styleitem.text3_txt_color}}
										<div class="btn">
											<el-color-picker v-model="styleitem.text3_txt_color"
												size="medium"></el-color-picker><span
												@click="setkey('text3_txt_color')">重置</span>
										</div>
									</div>
								</el-tab-pane>
							</el-tabs>
							
							<!-- <div class="sline"></div>
							<div class="stitle">功能组件</div>
							
							<div class="sline"></div>
							<div class="stitle">组件</div>
							
							<div class="sline"></div>
							<div class="stitle">主体背景</div>
							
							<div class="sline"></div>
							<div class="stitle">抬头</div> -->
							
						</div>
					
					
					
					
					
						<!-- <div class="sel-menu" style="display: flex;">
							<div class="black" :class="selcolor == 'black'?'sel':''" @click="funblack">黑色</div>
							<div class="black" :class="selcolor == 'white'?'sel':''" @click="funwhite">白色</div>
						</div> -->
						<!-- <div class="" @click="moreShow = !moreShow">
							<el-button size="small" type="primary">{{moreShow ?'关闭高级选项':'显示高级选项'}}</el-button>
						</div> -->
					
					</div>
					
					<div class="modeitem" :style="{background:styleitem.page_bg_color,color:styleitem.page_txt_color}">
						<div class="top-nav"
							:style="{background:styleitem.page_title_bg_color,color:styleitem.page_title_txt_color}">
							<img src="../../../../assets/design/topNavBlack.png" />
							
						</div>
						<div class="tit">顶部标题</div>
						<view class="" style="height: 200px;">
							
						</view>
						<div class="w250">
							<div class="cloum">
								<div class="right1" :style="{color:styleitem.title1_txt_color}">
									酒水单</div>
								<div class="line" :style="{background:styleitem.title1_txt_color}">
								</div>
					
							</div>
							<div class="cloum">
								<div class="right1" :style="{color:styleitem.title1_bg_color}">礼券
								</div>
								<div class="line">
								</div>
								<div class="line"></div>
							</div>
							<div class="cloum">
								<div class="right1" :style="{color:styleitem.title1_bg_color}">预定
								</div>
								<div class="line">
								</div>
								<div class="line"></div>
							</div>
						</div>
						<div class="item" :style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}"
							v-for="(item,index) in 4">
							<div class="orderno row">
								<div class="left"
									:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
									已存入
								</div>
								<div class="right">NO.10572</div>
					
							</div>
							<div class="p30 row">
								<div class="rightbox">
									<div class="spmc">测试存酒</div>
									<div class="sl" :style="{color:styleitem.text2_bg_color}">
										数量：1(整支)</div>
								</div>
								<div class="take">
									<div class="btn less">
										<div class="i"
											:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
											-
										</div>
									</div>
									<div class="val num" :style="{color:styleitem.text2_bg_color}">3</div>
									<div class="btn add">
										<div class="i"
											:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
											+
										</div>
									</div>
								</div>
							</div>
							<div class="bottom row">
								<div class="date" :style="{color:styleitem.text2_txt_color}">有效期至：2024-09-16</div>
					
							</div>
						</div>
					</div>
					
					<div class="modeitem" :style="{background:styleitem.page_bg_color,color:styleitem.page_txt_color}">
						<div class="top-nav"
							:style="{background:styleitem.page_title_bg_color,color:styleitem.page_title_txt_color}">
							<img src="../../../../assets/design/topNavBlack.png" />
							
						</div>
						<div class="tit">顶部标题</div>
						<div class="payment-body">
							<div class="title row"
								:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
								<div class="" style="flex-grow: 1;">大新软件</div>
								<div class="" style="margin-right: 10px;">测试桌台 点单明细</div>
							</div>
							<div class="body-top"
								:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
								<div class="top-sp" v-for="(item,index) in 1" :key="index">
									<div class="spcont">
										<!-- <div class="sp-left">
											<image :src="item.logo" mode=""></image>
										</div> -->
										<div class="sp-center">
											<div>券码：435434</div>
											<div class="mc">
												<span></span>
												<span class="txt">特价券</span>
												龙舌兰
											</div>
											<div class="dj">
												<!-- <div class="gmj">{{item.pricebuy | shouwPrice}}</div>
												<div class="lsj" :style="{color:divstyle.textcolor2}"
													v-if="item.pricesale > item.pricebuy">￥{{item.pricesale}}
												</div> -->
											</div>
											<div class="memo">
												备注
											</div>
											<div class="dj">
												促销：管理员</div>
					
										</div>
										<div class="sp-right">
					
											<div class="sp-right-num" :style="{color:styleitem.text2_bg_color}">x1 套</div>
											<div class="sp-right-money" :style="{color:styleitem.title3_bg_color}">￥199
											</div>
										</div>
									</div>
									<div class="groupmemo">
										荷兰宝华利无醇啤酒桃子味330ml*6瓶/套 x 3;黑桃A1（白）【正式】 x 1;
									</div>
					
								</div>
								<div class="moremsg" :style="{color:styleitem.text2_txt_color}">共12道，点击显示更多</div>
							</div>
					
							<div class="bottbox"
								:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
								<div class="moneybox">
									<div class="total" :style="{color:styleitem.text3_txt_color}">总计：￥1000</div>
									<div class="totoalmoney" :style="{color:styleitem.title3_txt_color}">
										￥100</div>
								</div>
								<div class="offOrder"
									:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
									<span>取消订单</span>
								</div>
							</div>
						</div>
						<div class="content-header"
							:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
							<!-- <div class="title" :style="{background:divstyle.btnbg,color:divstyle.btncolor}">{{mchname}}</div> -->
							<div class="spdetail">
								<div class="payment-head">
									<div class="left">
										单号：JXSC1000101240830101
									</div>
									<div class="right">
										待收款
									</div>
								</div>
								<div class="pitem ">
									桌号：测试台
								</div>
								<div class="pitem">
									会员：测试客人 15883568512
								</div>
								<div class="pitem">
									积分：12
								</div>
								<div class="pitem">
									客户经理：管理员
								</div>
								<div class="pitem">
									服务员：管理员
								</div>
								<div class="pitem">
									备注：下单备注
								</div>
								<div class="pitem" style="margin-bottom: 0;">
									下单时间：08-30 17：14
								</div>
					
							</div>
						</div>
						<div class="w_100"></div>
						<div class="payment-footer"
							:style="{background:styleitem.front_bg_color,color:styleitem.front_txt_color}">
							<div class="">
							</div>
							<div class="w100">
								<!-- <div class="totoalmoney" v-if="orderinfo.moneysale > orderinfo.moneyfact">
									总额：￥{{orderinfo.moneysale}}</div> -->
								<div class="bottom-color" :style="{color:styleitem.text3_txt_color}"><span
										style="font-size:10pt">待付款：</span><span style="font-size: 9pt;">￥</span>1588</div>
								<!-- <div class="sale" :style="{color:divstyle.btnbg}" >会员立减 -52.80元</div> -->
							</div>
							<div class="pitem receipt"
								:style="{background:styleitem.subject1_bg_color,color:styleitem.subject1_txt_color}">
								付款
							</div>
						</div>
					</div>
					</div>
					<span slot="footer" class="dialog-footer">
						<el-button size="medium" @click="dialogVisible = false">取 消</el-button>
						<el-button size="medium" type="primary" @click="funOk">保存</el-button>
					</span>
					
					
				</el-dialog>
			</div>
		</el-card>

	</div>
</template>

<script>
	import styleApi from '../../../../api/sys/style.js'
	export default {
		data() {
			return {
				title: "编辑",
				styleList: [],
				style_id:'',
				style_name:'',
				clsid:'',
				clsmc:'',
				clslist:[],
				styleitem: {
					front_bg_color: "#FFFFFF",
					front_txt_color: "#282828",
					page_bg_color: "#F3F3F3",
					page_title_bg_color: "#FFFFFF",
					page_title_txt_color: "#000000",
					page_txt_color: "#303030",
					subject1_bg_color: "#002EA2",
					subject1_txt_color: "#FFFFFF",
					subject2_bg_color: "#717171",
					subject2_txt_color: "#4F4F4F",
					text1_bg_color: "#EB0C0C",
					text1_txt_color: "#565656",
					text2_bg_color: "#EF0A0A",
					text2_txt_color: "#6A6A6A",
					text3_bg_color: "#E1E1E1",
					text3_txt_color: "#E1E1E1",
					title1_bg_color: "#B5B5B5",
					title1_txt_color: "#777777",
					title2_bg_color: "#525252",
					title2_txt_color: "#9D9D9D",
					title3_bg_color: "#FFFFFF",
					title3_txt_color: "#FFFFFF",
				},
				dialogVisible: false,
				moreShow: false,
				selcolor: "",
				defaultstyle: {
					white: {
						front_bg_color: "#FFFFFF",
						front_txt_color: "#282828",
						page_bg_color: "#F3F3F3",
						page_title_bg_color: "#FFFFFF",
						page_title_txt_color: "#000000",
						page_txt_color: "#303030",
						// subject1_bg_color: "#002EA2",
						// subject1_txt_color: "#FFFFFF",
						// subject2_bg_color: "#717171",
						// subject2_txt_color: "#4F4F4F",
						text1_bg_color: "#EB0C0C",
						text1_txt_color: "#565656",
						text2_bg_color: "#EF0A0A",
						text2_txt_color: "#6A6A6A",
						text3_bg_color: "#E1E1E1",
						text3_txt_color: "#E1E1E1",
						title1_bg_color: "#B5B5B5",
						title1_txt_color: "#777777",
						title2_bg_color: "#525252",
						title2_txt_color: "#9D9D9D",
						title3_bg_color: "#FFFFFF",
						title3_txt_color: "#FFFFFF",
					},
					black: {
						front_bg_color: "#20232B",
						front_txt_color: "#E8E8E8",
						page_bg_color: "#181920",
						page_title_bg_color: "#20232B",
						page_title_txt_color: "#FFFFFF",
						page_txt_color: "#E8E8E8",
						// subject1_bg_color: "#94B2D6",
						// subject1_txt_color: "#181920",
						// subject2_bg_color: "#FFFFFF",
						// subject2_txt_color: "#8E5555",
						text1_bg_color: "#B75050",
						text1_txt_color: "#B83535",
						text2_bg_color: "#B2B2B2",
						text2_txt_color: "#CFCFCF",
						text3_bg_color: "#A5A5A5",
						text3_txt_color: "#292A34",
						title1_bg_color: "#DA2C2C",
						title1_txt_color: "#B03838",
						title2_bg_color: "#E5E5E5",
						title2_txt_color: "#EEA60B",
						title3_bg_color: "#CDCDCD",
						title3_txt_color: "#C2C2C2",
					},
				}
			}
		},
		mounted() {
			this.getStyle()
			this.getclslist()
		},
		methods: {
			getStyle() {
				styleApi.list({}, res => {
					console.log(res, 'styleApi.res');
					if (res.code == 200) {
						this.styleList = res.data
					}
				})
			},
			setkey(key) {
				console.log(this.styleitem, 'this.styleitem');
				this.$delete(this.styleitem, key)
			},
			funedit(row) {
				console.log(row, 'row');
				this.style_id = row.style_id
				this.style_name = row.style_name
				this.clsid = row.clsid
				this.clsmc = row.clsmc
				this.title = "编辑" + "（" + row.style_id + "）"
				this.styleitem = JSON.parse(row.stylejson)
				this.dialogVisible = true
			},
			funcopy(row){
				this.title = "新增" + "（" + row.style_id + "）"
				this.styleitem = JSON.parse(row.stylejson)
				this.styleitem.style_id = ''
				this.styleitem.style_name = '复制'+this.styleitem.style_name
				this.funOk()
			},
			funAdd() {
				this.title = "新增"
				this.moreShow = false
				this.dialogVisible = true
				this.styleitem.front_bg_color = ""
				this.styleitem.front_txt_color = ""
				this.styleitem.page_bg_color = ""
				this.styleitem.page_title_txt_color = ""
				this.styleitem.page_txt_color = ""
				this.styleitem.style_id = ""
				this.styleitem.style_name = ""
				this.styleitem.subject1_bg_color = ""
				this.styleitem.subject1_txt_color = ""
				this.styleitem.subject2_bg_color = ""
				this.styleitem.subject2_txt_color = ""
				this.styleitem.tempid = ""
				this.styleitem.text1_bg_color = ""
				this.styleitem.text1_txt_color = ""
				this.styleitem.text2_bg_color = ""
				this.styleitem.text3_bg_color = ""
				this.styleitem.text2_txt_color = ""
				this.styleitem.text3_txt_color = ""
				this.styleitem.title1_bg_color = ""
				this.styleitem.title1_txt_color = ""
				this.styleitem.title2_bg_color = ""
				this.styleitem.title3_bg_color = ""
				this.styleitem.title2_txt_color = ""
				this.styleitem.title3_txt_color = ""
				this.styleitem.page_title_bg_color = ""
			},
			funblack() {
				this.selcolor = 'black'
				this.styleitem.front_bg_color = this.defaultstyle.black.front_bg_color,
					this.styleitem.front_txt_color = this.defaultstyle.black.front_txt_color
				this.styleitem.page_bg_color = this.defaultstyle.black.page_bg_color
				this.styleitem.page_title_bg_color = this.defaultstyle.black.page_title_bg_color
				this.styleitem.page_title_txt_color = this.defaultstyle.black.page_title_txt_color
				this.styleitem.page_txt_color = this.defaultstyle.black.page_txt_color
				this.styleitem.text1_bg_color = this.defaultstyle.black.text1_bg_color
				this.styleitem.text1_txt_color = this.defaultstyle.black.text1_txt_color
				this.styleitem.text2_bg_color = this.defaultstyle.black.text2_bg_color
				this.styleitem.text2_txt_color = this.defaultstyle.black.text2_txt_color
				this.styleitem.text3_bg_color = this.defaultstyle.black.text3_bg_color
				this.styleitem.text3_txt_color = this.defaultstyle.black.text3_txt_color
				this.styleitem.title1_bg_color = this.defaultstyle.black.title1_bg_color
				this.styleitem.title1_txt_color = this.defaultstyle.black.title1_txt_color
				this.styleitem.title2_bg_color = this.defaultstyle.black.title2_bg_color
				this.styleitem.title2_txt_color = this.defaultstyle.black.title2_txt_color
				this.styleitem.title3_bg_color = this.defaultstyle.black.title3_bg_color
				this.styleitem.title3_txt_color = this.defaultstyle.black.title3_txt_color
			},
			funwhite() {
				this.selcolor = 'white'
				this.styleitem.front_bg_color = this.defaultstyle.white.front_bg_color,
					this.styleitem.front_txt_color = this.defaultstyle.white.front_txt_color
				this.styleitem.page_bg_color = this.defaultstyle.white.page_bg_color
				this.styleitem.page_title_bg_color = this.defaultstyle.white.page_title_bg_color
				this.styleitem.page_title_txt_color = this.defaultstyle.white.page_title_txt_color
				this.styleitem.page_txt_color = this.defaultstyle.white.page_txt_color
				this.styleitem.text1_bg_color = this.defaultstyle.white.text1_bg_color
				this.styleitem.text1_txt_color = this.defaultstyle.white.text1_txt_color
				this.styleitem.text2_bg_color = this.defaultstyle.white.text2_bg_color
				this.styleitem.text2_txt_color = this.defaultstyle.white.text2_txt_color
				this.styleitem.text3_bg_color = this.defaultstyle.white.text3_bg_color
				this.styleitem.text3_txt_color = this.defaultstyle.white.text3_txt_color
				this.styleitem.title1_bg_color = this.defaultstyle.white.title1_bg_color
				this.styleitem.title1_txt_color = this.defaultstyle.white.title1_txt_color
				this.styleitem.title2_bg_color = this.defaultstyle.white.title2_bg_color
				this.styleitem.title2_txt_color = this.defaultstyle.white.title2_txt_color
				this.styleitem.title3_bg_color = this.defaultstyle.white.title3_bg_color
				this.styleitem.title3_txt_color = this.defaultstyle.white.title3_txt_color
			},
			funOk() {
				if (this.styleitem.front_bg_color == undefined || this.styleitem.front_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面前景色"
					})
					return
				}
				if (this.styleitem.front_txt_color == undefined || this.styleitem.front_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面前景字体色"
					})
					return
				}
				if (this.styleitem.page_bg_color == undefined || this.styleitem.page_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面背景色"
					})
					return
				}
				if (this.styleitem.page_title_txt_color == undefined || this.styleitem.page_title_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面标题字体色（白/黑）"
					})
					return
				}
				if (this.styleitem.page_txt_color == undefined || this.styleitem.page_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面字体色"
					})
					return
				}
				if (this.styleitem.page_txt_color == undefined || this.styleitem.page_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面字体色"
					})
					return
				}
				if (this.styleitem.subject1_bg_color == undefined || this.styleitem.subject1_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择主题1背景颜色"
					})
					return
				}
				if (this.styleitem.subject1_txt_color == undefined || this.styleitem.subject1_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择主题1字体色"
					})
					return
				}
				if (this.styleitem.subject2_bg_color == undefined || this.styleitem.subject2_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择设计页背景颜色"
					})
					return
				}
				if (this.styleitem.subject2_txt_color == undefined || this.styleitem.subject2_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择设计页字体色"
					})
					return
				}
				if (this.styleitem.text1_bg_color == undefined || this.styleitem.text1_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择点单分类背景色"
					})
					return
				}
				if (this.styleitem.text1_txt_color == undefined || this.styleitem.text1_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择点单分类字体色"
					})
					return
				}
				if (this.styleitem.text2_bg_color == undefined || this.styleitem.text2_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择页脚背景色"
					})
					return
				}
				if (this.styleitem.text2_txt_color == undefined || this.styleitem.text2_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择页脚字体色"
					})
					return
				}
				if (this.styleitem.title1_bg_color == undefined || this.styleitem.title1_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题1背景色"
					})
					return
				}
				if (this.styleitem.title1_txt_color == undefined || this.styleitem.title1_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题1字体色"
					})
					return
				}
				if (this.styleitem.title2_bg_color == undefined || this.styleitem.title2_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题2背景色"
					})
					return
				}
				if (this.styleitem.title2_txt_color == undefined || this.styleitem.title2_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题2字体色"
					})
					return
				}
				if (this.styleitem.page_title_bg_color == undefined || this.styleitem.page_title_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题页头标题背景色"
					})
					return
				}
				if (this.styleitem.title3_bg_color == undefined || this.styleitem.title3_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题3背景色"
					})
					return
				}
				if (this.styleitem.title3_txt_color == undefined || this.styleitem.title3_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题3字体色"
					})
					return
				}
				if (this.styleitem.text3_bg_color == undefined || this.styleitem.text3_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择文本3背景色"
					})
					return
				}
				if (this.styleitem.text3_txt_color == undefined || this.styleitem.text3_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择文本3字体色(边框色)"
					})
					return
				}
				 let temp = this.clslist.find(f =>{
					return f.id == this.clsid
				})
				if(temp != undefined){
					this.clsmc = temp.mc
				}else{
					this.$message({
						type: "error",
						message: "请选择分类"
					})
					return
				}
				let param = {
					style_id:this.style_id,
					style_name:this.style_name,
					clsid:this.clsid,
					clsmc:this.clsmc,
					style_json:this.styleitem
				}
				styleApi.edit(param, res => {
					console.log(res, 'styleApi.res');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.getStyle()
						this.dialogVisible = false
					}
				})
			},
			getclslist(){
				styleApi.clslist(res => {
					console.log(res, 'clslist.res');
					if (res.code == 200) {
						this.clslist = res.data
					}
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.clearfix {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.menulist {
		float: left;
		width: 80px;
		height: calc(100vh - 100px);
		position: relative;
		background: #fff;
		border-right: 1px solid #f0f0f0;
		border-radius: 5px;
		overflow-y: auto;
		overflow-x: hidden;
		box-shadow: 0 2px 6px #ccc;


	}

	.selected {
		background: #3089dc !important;
		color: #fff;
	}

	.menulist .item {
		cursor: pointer;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-weight: 300;
		font-size: 12pt;
		position: relative;
	}

	.menulist .disp {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.box-card {
		position: relative;
		width: 100%;
	}

	.main {
		display: flex;
		flex-wrap: wrap;
	}

	.box {
		overflow: auto;
		display: flex;
		flex-wrap: wrap;
	}

	.box .item {
		/* margin: 10px 0;
		display: flex;
		align-items: center; */
		margin: 10px;
		cursor: pointer;
		width: 40px;
		height: 40px;
		font-size: 10px;
		line-height: 40px;
		border-radius: 100%;
	}

	/* 
	.box .item span {
		margin: 0 20px;
	} */
	.el-input {
		width: 300px !important;
	}

	.curr {
		border: 3px solid #55aaff;
	}

	.cusm {
		border: 3px solid #ccc;
	}

	.btn {
		margin-right: 20px;
		float: right;
		display: flex;
		align-items: center;
	}

	.boxs {
		height: calc(100vh - 300px);
		overflow-y: auto;
	}

	.boxs .item {
		line-height: 3.5;
		border-bottom: 1px solid #f0f0f0;
	}

	.sel-menu .black {
		border: 1px solid #ccc;
		width: 100px;
		height: 50px;
		line-height: 50px;
		margin: 10px;
		cursor: pointer;
		text-align: center;
	}

	.sel {
		color: #fff;
		background-color: #3089dc;
	}
	
	
	.modeitem {
		width: 375px;
		height: 712px;
		
		margin: 0 20px;
		position: relative;
		overflow: hidden;
		box-shadow: 0 2px 6px #ccc;
	}
	
	.modeitem .item {
		width: 311px;
		border-radius: 10px;
		padding: 10px 16px;
		position: relative;
		margin: 0 auto 16px auto;
	
	}
	
	.modeitem .item .icon {
		width: 50px;
		height: 50px;
		position: absolute;
		right: 20px;
		top: 67px;
	}
	
	.modeitem .item .orderno {
		display: flex;
		align-items: center;
		font-size: 11pt;
	
	}
	
	.modeitem .item .orderno .left {
		padding: 3px 5px;
		font-size: 8pt;
	
		border-radius: 3px;
		flex-shrink: 0;
		margin-right: 10px;
	}
	
	.modeitem .item .orderno .zt0 {}
	
	.modeitem .item .orderno .zt1 {
		background: #C3C3C3;
		color: #4E4E4E;
	}
	
	.modeitem .item .orderno .zt2 {
		background: #C3C3C3;
		color: #4E4E4E;
	}
	
	.modeitem .item .spimg {
		width: 50px;
		height: 50px;
		margin-right: 15px;
		flex-shrink: 0;
	}
	
	.modeitem .item .rightbox {
		display: flex;
		flex-direction: column;
	
		justify-content: center;
	}
	
	.modeitem .item .rightbox .sl {
	
		font-size: 10pt;
		margin-top: 5px;
	}
	
	.modeitem .item .bottom {
	
		align-items: center;
	}
	
	.modeitem .item .date {
		font-size: 10pt;
	
		flex-grow: 1;
	}
	
	.w250 {
	
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		width: 60%;
		margin: 16px auto 0 auto;
		
		padding: 20px 0;
	}
	
	.w250 .cloum {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	
	.w250 .cloum .line {
		width: 20px;
		height: 4px;
		border-radius: 5px;
	}
	
	.w250 .right1 {
		width: 48px;
		text-align: center;
		line-height: 24px;
		height: 24px;
		font-size: 12pt;
		font-weight: 700;
		margin: 4px 0;
	
	}
	.take {
		display: flex;
		flex-direction: row;
		position: absolute;
		right: 5px;
		bottom: 10px;
		width: 120px;
		height: 50px;
	}
	
	.take .btn {
		width: 50px;
		height: 35px;
		flex-shrink: 0;
		padding-top: 15px;
	}
	
	.take .add {
		position: absolute;
		right: 0;
	}
	
	.take .less {
		position: absolute;
		left: 0;
	}
	
	.take .num {
		position: absolute;
		left: 35px;
		width: 50px;
		font-size: 12pt;
		text-align: center;
	
	}
	
	.take .val {
		height: 20px;
		line-height: 20px;
		padding-top: 15px;
		flex-grow: 2;
		text-align: center;
	}
	
	.take .i {
		margin: 0 auto;
		width: 20px;
		height: 20px;
		line-height: 17px;
		border-radius: 20px;
		font-size: 17px;
		text-align: center;
	}
	.tit {
		position: absolute;
		left: 0;
		top: 30px;
		width: 100%;
		text-align: center;
	}
	.top-nav {
		
		
		z-index: 999;
		transition: all 0.3s;
	
		& * {
			pointer-events: none;
		}
	
		&:hover {
			transform: scale(0.95);
			border-radius: 10px;
			overflow: hidden;
			box-shadow: 0 0 10px #afafaf;
		}
	
		
	
		img {
			width: 375px;
			image-rendering: -moz-crisp-edges;
			image-rendering: -o-crisp-edges;
			image-rendering: -webkit-optimize-contrast;
			image-rendering: crisp-edges;
			-ms-interpolation-mode: nearest-neighbor;
		}
	}
	
	
	.payment-body {
		width: 343px;
		margin: 0 auto;
		height: auto;
		overflow: auto;
		border-radius: 10px;
		margin-top: 16px;
	}
	
	.payment-body .row {
		display: flex;
	
	}
	
	.payment-body .body-head {
		height: 45px;
		line-height: 45px;
		font-size: 14px;
		margin-bottom: 12px;
		border-bottom: 1rpx solid #fff;
	}
	
	.payment-body .body-top {
		padding: 16px;
		background: #20232B;
	}
	
	.payment-body .body-top .top-sp {
		display: flex;
		flex-direction: column;
		margin: 0 0 10px;
	}
	
	.payment-body .body-top .top-sp .spcont {
		display: flex;
		flex-direction: row;
	}
	
	.groupmemo {
		font-size: 10pt;
		padding: 10px 0rpx;
	
	}
	
	.payment-body .body-top .top-sp .sp-left {
		margin-right: 20px;
	}
	
	.payment-body .body-top .top-sp .sp-left image {
		height: 60px;
		width: 60px;
		background-size: 100%;
		border-radius: 20px;
	}
	
	.payment-body .body-top .top-sp .sp-center {
		flex-grow: 2;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-size: 11pt;
	}
	
	.payment-body .body-top .top-sp .sp-center .dj {
		margin-top: 10px;
		display: flex;
		flex-direction: row;
	}
	
	.payment-body .body-top .top-sp .sp-center .dj .gmj {}
	
	.payment-body .body-top .top-sp .sp-center .dj .lsj {
		text-decoration: line-through;
	
		margin-left: 10px;
	}
	
	.payment-body .body-top .top-sp .sp-center .memo {
		font-size: 9pt;
	
	}
	
	.payment-body .body-top .top-sp .sp-right {
		text-align: right;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		margin-left: 20px;
	}
	
	.payment-body .body-top .top-sp .sp-right .sp-right-money {
		font-size: 14px;
	}
	
	.payment-body .body-top .top-sp .sp-right .sp-right-num {
	
		font-size: 12px;
	}
	
	.payment-body .body-bottom {
		height: 40px;
		line-height: 40px;
		border-top: 1px solid #d0cfd4;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		font-size: 14px;
	}
	.title {
		text-align: left;
		height: 44px;
		line-height: 44px;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
		font-size: 11pt;
		font-weight: 700;
		padding-left: 16px;
	
	
	}
	
	.content-header {
		margin: 16px auto;
		border-radius: 10px;
		width: 343px;
	
		.payment-head {
			width: 100%;
			font-size: 11pt;
			display: flex;
			justify-content: space-between;
			margin-bottom: 10px;
		}
	
		.spdetail {
			padding: 16px;
	
	
			.pitem {
				font-size: 11pt;
				margin-bottom: 10px;
	
			}
		}
	}
	.bottbox {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding: 16px;
		background: #20232B;
	}
	.moneybox {
		display: flex;
		flex-direction: row;
		align-items: center;
		flex-grow: 1;
		font-size: 11pt;
		font-weight: 700;
	}
	
	.offOrder {
		display: flex;
		border-radius: 12px;
	}
	
	.offOrder span {
		height: 24px;
		line-height: 24px;
		width: 77px;
	
		text-align: center;
		font-size: 10pt;
	}
	
	.payment-footer .receipt {
		height: 100%;
		line-height: 48px;
		width: 90px;
		height: 48px;
		text-align: center;
	
	
	}
	.payment-footer {
		display: flex;
		flex-direction: row;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #FFFFFF;
		height: 48px;
		align-items: center;
		justify-content: center;
		z-index: 2;
		color: #000;
	}
	
	.payment-footer .pitem {
		flex-grow: 1;
	}
	
	.payment-footer .w100 {
		flex-grow: 1;
		margin-left: 16px;
	}
	
	.w_100 {
		height: 100px;
	}
</style>