import httpapi from '../../utils/httpapi';
const wxqyApi = {
	//获取企业微信应用列表
	list(mpid,funsucc) {
		httpapi.get('customer/wxgzh/qiye/account/list?mpid='+mpid, {}).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//添加企业微信第三方应用
	isvadd(param,funsucc) {
		httpapi.post('customer/wxgzh/qiye/account/isv/add', param).then(res => {
			console.log(res, 'isvadd.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//添加企业微信自建应用
	devpadd(param,funsucc) {
		httpapi.post('customer/wxgzh/qiye/account/devp/add', param).then(res => {
			console.log(res, 'devpadd.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//添加企业微信代开发应用
	proxyadd(param,funsucc) {
		httpapi.post('customer/wxgzh/qiye/account/proxy/add', param).then(res => {
			console.log(res, 'devpadd.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//获取企业微信应用授权网址
	geturl(agentid,funsucc) {
		httpapi.get('customer/wxgzh/qiye/auth/geturl?agentid='+agentid, {}).then(res => {
			console.log(res, 'geturl.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default wxqyApi