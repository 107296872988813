<template>
	<div>
		<div class="menu_img_title" >
			<div class="imgcont"  :style="{
        'margin-left': marginpage,
        'margin-right': marginpage,
		'margin-top':margintop,
		'margin-bottom':marginbottom,
		'height' : height,
    'background':bgcolor,
      }">
<div
        v-if="
          list.options && list.options.bgimg && list.options.bgimg.length > 0
        "
      >
        <img
          :src="
            list.options.bgimg && list.options.bgimg.length > 0
              ? list.options.bgimg
              : ''
          "
          class="bgimg" :style="{
			  'height' : height,
			  
		  }"
        />
      </div>



				<div 	v-for="(pitem, pindex) in list.data" :key="pindex" class="pitem"
				 :style="{
						'left':(pitem.left) + 'px',
						'top':(pitem.top) + 'px',
						'width':(pitem.width) + 'px',
						'height':(pitem.height) + 'px'
					}">
						<dragBox
          v-for="(item, index) in pitem.child"
          :key="item.cid"
					:dragkey = "pindex + ''"
          :data="item"
          :index="index"
          @deleteHandle="deleteHandle"
          @selectHandler="selectHandler"
        >
          <div class="item item-button"
            v-if="item.type === 'button'"
            :style="{
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              background: item.bgcolor,
              'line-height':(item.height-2) + 'px',
              'border-radius':item.borderradius + 'px'
            }" 
          >
            <span v-if="item.title && item.title.length > 0" :style="{
               color: item.titlecolor,
               'text-align':item.position == 1 ? 'left':'center',
               'font-weight':item.bold == 2 ? 700:300,
               'font-size':getsize(item.size)
            }">{{ item.title }}</span> 
        </div>
          <div  class="item item-text"
            v-else-if="item.type === 'text'"
            :style="{
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              color: item.titlecolor,
              'text-align':item.position == 1 ? 'left':'center',
              'font-weight':item.bold == 2 ? 700:300,
              'line-height':(item.height-2) + 'px',
              'font-size':getsize(item.size)
            }"
          >
            {{ item.title }} 
          </div>
          <img  class="item"
            v-if="item.type == 'image' && item.url.length > 0"
            :style="{
              height: item.height - 2 + 'px',
              width: item.width - 2 + 'px',
              'border-radius':item.borderradius + 'px'
            }"
            :src="item.url"
          />
        </dragBox>

					</div>

			
				<div class="empty" v-show="data.data.length == 0">
					<el-empty description="此区域放置图片"></el-empty>
				</div>
			</div>

			<!-- <el-image style="width: 100%" :src="url"></el-image> -->
		</div>
	</div>
</template>

<script>
import dragBox from "@/components/applet/toolbox/dragbox.vue";

	export default {
		name: "image_row",
		components: {
			dragBox
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				list: "",
			};
		},

		mounted() {
			this.list = this.data;
			
		},
		computed: {
			bgcolor(){
				if (this.list.options) {
					if (this.list.options.bgcolor && this.list.options.bgcolor.length > 0)
						return this.list.options.bgcolor;
				}
			},

			height(){
				if (this.list.options) {
					if (this.list.options.height && this.list.options.height > 0)
						return (this.list.options.height / 2) + 'px';
				}
			},
			width(){
				if (this.list.options) {
					if (this.list.options.width && this.list.options.width > 0)
						return (this.list.options.width / 2) + 'px';
				}
			},
			margintop() {
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + 'px';
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
						return this.list.options.marginbottom + 'px';
				}
			},
			marginpage() {
				if (this.list.options) {
					if (this.list.options.marginpage && this.list.options.marginpage > 0)
						return this.list.options.marginpage + 'px';
				}
			},
			borderradius() {
				if (this.list.options) {
					if (this.list.options.borderradius && this.list.options.borderradius > 0)
						return this.list.options.borderradius + 'px';
				}
			},
			
		},

		methods: {
			getsize(size){
					  if(size == 1){
						   return '16px'
					  }
					  else  if(size == 2)
					  {
						   return '14px'
					  }
					  else if(size == 3){
						   return '12px'
					  }
					  else{
						  return size + 'px'
					  }
			},
			selectHandler(param){
      this.$emit('selectItemHandler',{
        type:'image_docker',
        data:param
      })
    },
    deleteHandle(param) {
		let index = param.index;
		let key = parseInt( param.dragkey);
      this.$delete(this.list.data[key].child,index);
      //this.list.data.splice(index,1);
      //this.$forceUpdate(this.list.data)
      //this.$set(this.list,'data',this.list.data)
     
    },

			// 标题加粗
			bold(b) {
      if (b == 2) {
        return "900";
      } else {
        return "300";
      }
    },
			test(){
				alert(1);
			}
		},
	};
</script>

<style lang="less" scoped>
	.menu_img_title {
		  width: 100%;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.imgcont {
			overflow: hidden;
		position: relative;
		width: 100%;
		.pitem{
			position: absolute;
      border:1px dashed #ccc;
			overflow: hidden;
		}
	}
 
	}

	.bgimg{
		width: 100%;
		
	}

	.empty {
		width: 400px;
		// text-align: center;
		// display: flex;
		// flex-wrap: wrap;
	}
  .img{
    z-index: 10;
    position: absolute;
  }
	.title{
    position: absolute;
		height: 40px;
		line-height: 40px;
    z-index: 20;
	}
  .desc{
    z-index: 30;
    position: absolute;
		height: 40px;
		line-height: 40px;
	}
</style>