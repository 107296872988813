<template>
	<div id="text">
		<!-- <el-tabs v-model="activeName" type="card"> -->
		<!-- <el-tab-pane label="数据" name="first"> -->
		<p>标题内容</p>
		<el-input v-model="list.options.name" size="medium" maxlength="10" placeholder="请输入标题"></el-input>
		<!-- 标题文字大小 -->
		<div class="list">
			<div class="btn" style="margin-top: 10px;">
				<el-select v-model="list.options.size" filterable allow-create placeholder="请选择标题大小" size="small">
					<el-option v-for="item in fontlist" :key="item.id" :label="item.mc" :value="item.id">
					</el-option>
				</el-select>
				<el-tooltip effect="dark" content="标题字体色" placement="top">
					<el-color-picker v-model="list.options.titlecolor" size="medium"></el-color-picker>
				</el-tooltip>
				<el-tooltip effect="dark" content="加粗" placement="top">
					<div class="blod">
						<img src="../../../../../assets/B1.png" v-if="list.options.bold && list.options.bold == 2"
							@click="funchu(1)" />
						<img src="../../../../../assets/B0.png" v-else @click="funchu(2)" />
					</div>
				</el-tooltip>
				
			</div>

		</div>
		<div class="line"></div>
		<p>描述内容</p>
		<el-input v-model="list.options.label" maxlength="500" :rows="5" type="textarea"
			placeholder="描述内容，最多500字"></el-input>

		<div class="btn" style="margin-top: 10px;">
			<el-select v-model="list.options.describe" filterable allow-create placeholder="请选择描述字大小" size="small">
				<el-option v-for="item in msfontlist" :key="item.id" :label="item.mc" :value="item.id">
				</el-option>
			</el-select>
			<el-tooltip effect="dark" content="描述字体色" placement="top">
				<el-color-picker v-model="list.options.describecolor" size="medium"></el-color-picker>
			</el-tooltip>
			
			

		</div>
		<div style="height: 1px"></div>
		<p class="division"></p>
		<p>按钮内容</p>
		
		<div class="btn" style="margin-top: 10px;">
			<el-input v-model="list.options.btn" maxlength="10" size="medium" placeholder="请输入按钮内容"></el-input>
			<el-tooltip effect="dark" content="按钮背景色" placement="top">
				<el-color-picker v-model="list.options.btncolor" size="medium"></el-color-picker>
			</el-tooltip>
			<el-tooltip effect="dark" content="按钮字体色" placement="top">
				<el-color-picker v-model="list.options.btntxtcolor" size="medium"></el-color-picker>
			</el-tooltip>
			<div class="blod">
				<img class="boldimg2" src="../../../../../assets/image/icon_show1.png" v-if="list.options.btnshow && list.options.btnshow == true"
					@click="funshow(false)" />
				<img class="boldimg2"  src="../../../../../assets/image/icon_show0.png" v-else @click="funshow(true)" />
			</div>
		</div>
		<!-- </el-tab-pane> -->
		<!-- <el-tab-pane label="样式" name="style"> -->
		<div class="line"></div>
		<div class="details">

			<!-- 大背景颜色 -->
			
			<!-- 背景图片 -->
			<div class="list">
				<div class="title"><span>背景图片</span></div>
				<div class="btn">
					<el-button type="text" class="reset" @click="reset('bgimg')">重置</el-button><el-button type="primary"
						size="small" @click="funupload('bgimg')">上传</el-button>
				</div>
			</div>
			<div class="list">
				<el-image :src="list.options.bgimg" style="width: 80px;height: 80px;"></el-image>
			</div>
			<!-- 底部分割线 -->
			<div class="list">
				<div class="title"><span>大背景颜色</span>{{ list.options.bgcolor||'默认' }}<span></span></div>
				<div class="btn">
					<el-button type="text" class="reset" @click="reset('bgcolor')">重置</el-button><el-color-picker
						v-model="list.options.bgcolor" size="medium"></el-color-picker>
				</div>
			</div>
			<!-- 背景颜色 -->
			<div class="list">
				<div class="title"><span>背景颜色</span>{{ list.options.background||'默认' }}<span></span></div>
				<div class="btn">
					<el-button type="text" class="reset" @click="reset('background')">重置</el-button><el-color-picker
						v-model="list.options.background" size="medium"></el-color-picker>
				</div>
			</div>
			<div class="list">
				<div class="title">
					<span>底部分割线</span>{{ list.options.border ? "显示" : "不显示" }}<span></span>
				</div>
				<div class="btn">
					<el-switch v-model="list.options.border" active-color="#13ce66" inactive-color="#ff4949">
					</el-switch>
				</div>
			</div>
			<div class="disps">
				<div class="title">
					<span>圆角 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.borderradius" show-input :max="3000" style="margin: 0 10px;">
					</el-slider>
				</div>
			</div>
			<div class="disps">
				<div class="title">
					<span>上边距 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.margintop" show-input :max="200" style="margin: 0 10px;">
					</el-slider>
				</div>
			</div>
			<div class="disps">
				<div class="title">
					<span>下边距 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.marginbottom" show-input :max="200" style="margin: 0 10px;">
					</el-slider>
				</div>
			</div>

			<!-- <div class="disps">
					  <div class="title">
					    <span>内上边距 </span>
					  </div>
					  <div class="btn2 full">
					    <el-slider v-model="list.options.paddingtop" show-input :max="200" style="margin: 0 10px;"> 
					    </el-slider>
					  </div>
					</div>
					<div class="disps">
					  <div class="title">
					    <span>内下边距 </span>
					  </div>
					  <div class="btn2 full">
					    <el-slider v-model="list.options.paddingbottom" show-input :max="200" style="margin: 0 10px;"> 
					    </el-slider>
					  </div>
					</div> -->
			<div class="disps">
				<div class="title">
					<span>外内页边距 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.paddingpage" show-input :max="200" style="margin: 0 10px;">
					</el-slider>
				</div>
			</div>
		</div>
		<!-- </el-tab-pane> -->
		<!-- </el-tabs> -->
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>

</template>

<script>
	import selImgs from "@/components/customer/selImgs"
	export default {
		components: {
			selImgs
		},
		name: "club_newadd_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
				activeName: "first",
				fontlist: [{
						mc: '12号(小)',
						id: 3
					},
					{
						mc: '14号(中)',
						id: 2
					},
					{
						mc: '16号(大)',
						id: 1
					},
				],
				msfontlist: [{
						mc: '12号(小)',
						id: 3
					},
					{
						mc: '14号(中)',
						id: 2
					},
					{
						mc: '16号(大)',
						id: 1
					},
				],
			};
		},

		created() {
			this.list = this.data;
		},
		methods: {
			//  显示的位置
			position(event) {
				// this.$set(this.list.options.position, position, event);
				this.list.options.position = event;
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			funshow(isshow){
				this.list.options.btnshow = isshow
			},
			// 字体大小
			funchu(bold) {
				this.list.options.bold = bold
			},
			size() {},
			funupload() {
				console.log(123);
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				this.list.options.bgimg = imgitem.url;
				// console.log(this.list.options.bgimg,'this.list.options.bgimg');
				this.$set(this.list.options, 'bgimg', imgitem.url);
				this.$forceUpdate()
			},
		},
	};
</script>

<style lang="less" scoped>
	#text {
		padding: 0 20px;

		p {
			margin: 0 0 10px 0 !important;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 20px;
	}

	.title {
		font-size: 14px;

		:first-child {
			padding-right: 10px;

			color: #969799;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
		}
	}

	.blod {
		cursor: pointer;
		margin-right: 5px;
		width: 32px;
		height: 30px;
		border-radius: 4px;
		background-color: #DEDFE1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-shrink: 0;

		img {
			width: 20px;
			height: 20px;
			border-radius: 4px;
		}
		.boldimg2{
			width: 25px;
			height: 25px;
		}
	}

	.line {
		margin: 20px 0;
		width: 100%;
		height: 1px;
		background-color: #f0f0f0;
	}

	/deep/ .el-color-picker__trigger {
		border: none;
	}
</style>