import httpapi from '../../utils/httpapi';
const tempApi = {
	//模版消息编辑模版编号
	edit(param, funsucc) {
		httpapi.post('/customer/msg/template/editno', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//模版消息刷新
	flsuh(param, funsucc) {
		httpapi.post('/customer/msg/template/flsuh', param).then(res => {
			console.log(res, 'flsuh.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//查询消息模版列表
	list(mpid, funsucc) {
		httpapi.get('/customer/msg/template/list?mpid='+mpid, {}).then(res => {
			console.log(res, 'flsuh.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//模版消息启用
	open(param, funsucc) {
		httpapi.post('/customer/msg/template/open', param).then(res => {
			console.log(res, 'flsuh.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default tempApi