<template>
	<div class="">
		<el-dialog :title="title" :visible.sync="uploadVisible" width="30%" :before-close="uploadClose"
			:append-to-body="true">
			<div class="box">
				<div class="" style="display: flex;align-items: center;">
					图片名称：<el-input type="text" size="small" v-model="importData.name" style="width: 300px;"></el-input>
				</div>
				<el-upload class="upload-demo" ref="upload" drag :action="uploadurl" :on-success="successfile" :headers="headers"
					:data="importData" :limit="5" multiple style="margin-top: 20px;">
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
					<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
				</el-upload>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: right;">
				<el-button size="small" @click='uploadClose'>关闭</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import imgApi from '../../api/common/img'
	import {getloginuser} from '../../utils/util.js'
	export default {
		data() {
			return {
				title: "",
				headers: {
					Authorization: ''
				},
				uploadVisible: false,
				clsInfo: {},
				uploadurl: '',
				importData: {
					name: '图片',
					clsid: 0
				}
			}
		},
		methods: {
			funShow(clsInfo) {
				console.log(clsInfo, 'csss');
				this.uploadVisible = true
				let u = getloginuser()
				if(u != null){
					this.headers.Authorization = 'Bearer ' + u.token
				}
				// let token = sessionStorage.getItem("token");
				
				console.log(this.headers, 'this.headers');
				this.clsInfo = clsInfo
				this.title = clsInfo.mc + '(图片上传)'
				this.importData.clsid = clsInfo.id;
				this.uploadurl = '/api/customer/file/upload/image'
			},
			uploadClose() {
				this.uploadVisible = false
			},
			successfile(res, file, fileList) {
				console.log("successfile==>", res, file, fileList);
				if (res.code == 200) {
					this.$message({
						type: 'success',
						message: "上传成功"
					})
					// 提交保存成功后清空已上传的文件
					this.$refs.upload.clearFiles();
					this.$emit('uploadres', {
						clsid: this.clsInfo.id
					})
				} else {
					this.$message({
						type: 'error',
						message: res.msg + "上传失败"
					})
				}
			},
		}
	}
</script>

<style scoped>
	.box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.upload-demo {
		margin-left: 10px;
	}
</style>