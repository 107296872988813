<template>
	<div style="width: 100%;">
		<navcon></navcon>
		<div class="content">
			<h1>我的小程序</h1>
			<div style="display: flex;align-items: center;">
				<div class="list" >
					<div class="item" v-for="(item,index) in list" :key="index" @click="funitem(item)">
						<div class="logo"> <img :src="item.logo" /></div>
						<div class="title">{{ item.tmpname }}</div>
						<div class="title">{{ item.createDate }}</div>
					</div>
					
				</div>
				<el-carousel style="width: 950px;margin-left: 100px;box-shadow: 0 2px 6px #eaeaea;border-radius: 8px;" :interval="5000" arrow="always">
				    <el-carousel-item v-for="(item,index) in newsimglist" :key="index">
				      <img :src="item.url" @click="funtopage"/>
				    </el-carousel-item>
				  </el-carousel>
				
			</div>
			
			<h1>我的模版</h1>
			<div class="list2">
				<div class="item" v-for="(item,index) in list2" :key="index" @click="funmyitem(item)">
					<div class="logo"> <img :src="item.logo" /> </div>
					<div class="title">{{ item.tmpname }}</div>
					<div class="title">{{ item.createDate }}</div>
					<div class="edit">
						<el-dropdown @command="funMenu($event,item)">
							<span class="el-dropdown-link">
								<i class="el-icon-s-operation"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<!-- <el-dropdown-item :command="'yanqi'">延期</el-dropdown-item> -->
								<el-dropdown-item :command="'usemb'">发布模版</el-dropdown-item>
								<el-dropdown-item :command="'delmb'">删除模版</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</div>
				</div>
				<!-- <div class="item" @click="funmore">
					<div class="logo">  </div>
					<div class="title">更多模版></div>
				</div> -->
			</div>
			<div style="width: 100%;text-align: center">
				<!-- 分页组件 -->
				<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
			</div>
			<div class="msg">
				{{datamsg}}
			</div>
			
			<showload :showload="showload"></showload>
		</div>
		<pagebottom ref="pagebottom"></pagebottom> 
	</div>
	
</template>
<script>
	import QRCode from "qrcode";
	import designTemplataApi from "../../../api/cus/designtmplate.js";
	import showload from "@/components/loading";
	import dragBox from "@/components/applet/toolbox/dragbox.vue";
	import Pagination from '@/components/Pagination.vue'
	import pagebottom from '../../../components/pagebottom.vue'
	import navcon from '../../../components/customer/navcon.vue'
	import {getloginuser,delloginall} from '../../../utils/util.js'
	export default {
		components: {
			dragBox,
			showload,
			Pagination,
			pagebottom,
			navcon
		},
		data() {
			return {
				list: [],
				list2:[],
				ispermissions:true,
				showload:false,
				datamsg: '',
				formInline: {
					name: "",
					pageno: 1,
					pagesize: 12
				},
				pageparm: {
					currentPage: 1,
					pageSize: 12,
					total: 20
				},
				newsimglist:[{
					url:require('../../../assets/image/market/icon_tempnews1.png'),
					path:''
				},
				{
					url:require('../../../assets/image/market/icon_tempnews2.png'),
					path:''
				}]
			}
		},

		mounted() {
			document.title = '我的小程序-公众号管理平台-大新软件'
			let u = getloginuser()
			// let u = sessionStorage.getItem('customeruser')
			if (u != null) {
				this.userInfo = u;
				console.log(this.userInfo, 'this.userInfo');
				if (this.userInfo.permissions != undefined && this.userInfo.permissions[0] == "admin") {
					this.ispermissions = true
				} else {
					this.ispermissions = false
				}
			} else {
				this.$router.push("/");
			}
			
			this.initdata();
			this.initdata2(this.formInline);
		},
		methods: {
			initdata: function() {
				console.log('initdata')
				this.showload = true
				designTemplataApi.getUserTemplate({}, res => {
					console.log(res, 'designTemplataApi.res');
					this.showload = false
					if (res.code == 200) {
						this.list = res.data
					}else if(res.code == 401){
						delloginall()
						this.$router.push({
							path:'/customer/login'
						})
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
					console.log(res);
				})
			},
			funitem: function(item) {
				let id = item.usertmpid
				console.log(item,'item22');
				// this.$router.push({
				// 	path: `/customer/xcxtemplate/list?id=${id}`
				// })
				this.$router.push({
					path: `/customer/xcxtemplate/design?tid=${id}`
				})
			},
			funtempbase(){
				this.$router.push({
					path: '/customer/xcxtemplate/templatebase'
				})
			},
			initdata2: function(page) {
				console.log('initdata')
				let param = {
					pageno: page.pageno,
					pagesize: page.pagesize
				}
				designTemplataApi.getUserMytemplist(param, res => {
					console.log(res, 'designTemplataApi.res1203');
					this.showload = false
					if (res.code == 200) {
						if (res.data.list.length == 0) {
							this.datamsg = '无数据'
						} else {
							// res.data.list.push({
							// 	tmpname: "更多模版"
							// })
							this.list2 = res.data.list
							this.pageparm.currentPage = res.data.pageno
							this.pageparm.pageSize = res.data.pagesize
							this.pageparm.total = res.data.total
						}
					}else if(res.code == 401){
						delloginall()
						this.$router.push({
							path:'/customer/login'
						})
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.initdata2(this.formInline)
			},
			funMenu(type, item) {
				switch (type) {
					case 'yanqi':
						break;
					case 'usemb':
						this.funUse(item.sysid)
						break;
					case 'delmb':
						this.fundel(item.sysid)
						break;
					default:
						break;
				}
			},
			funUse(tmpid) {
				let param = {
					user_tempid: tmpid
				}
				designTemplataApi.usemb(param, res => {
					console.log(res, 'delmb.res1203');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: '模版使用成功'
						})
						this.initdata();
					}
				})
			},
			fundel(sysid) {
				this.$confirm('是否删除此模版, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						sysid: sysid
					}
					designTemplataApi.delmb(param, res => {
						console.log(res, 'delmb.res1203');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: '删除模版成功'
							})
							this.initdata(this.formInline)
						}else{
						this.$message({
							type: "error",
							message: res.msg
						})
					}
					})
				}).catch(() => {
				
				});
				
			},
			funmyitem: function(item) {
				let sysid = item.sysid
				console.log(item, 'item33');
				this.$router.push({
					path: `/customer/xcxtemplate/design?tid=${sysid}`
				})
			},
			funmore(){
				this.$router.push({
					path: '/customer/xcxtemplate/marketindex'
				})
			},
			funtopage(){
				
			}
		}
	}
</script>
<style lang="less" scoped>

	.content {
		width: 90%;
		min-width: 1200px;
		margin: 0 auto;
	}

	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin-bottom:20px;
	}

	.item {
		text-align: center;
		box-shadow: 0 2px 6px #ccc;
		padding: 20px;
		margin: 20px 20px 0 0;
		position: relative;

		&:hover {
			box-shadow: 0 2px 6px #2865e0;
			border-radius: 20px;
		}

		.logo {
			width: 200px;
			height: 350px;
			overflow: hidden;

			img {
				width: 100%;
			}
		}
	}
	.list2 {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
	}
	
	.list2 .edit {
		position: absolute;
		right: 0;
		top: 0;
		padding-right: 10px;
	}
	
	.list2 .item {
		text-align: center;
		box-shadow: 0 2px 6px #ccc;
		padding: 20px;
		margin: 20px 20px 0 0;
		position: relative;
	
		&:hover {
			box-shadow: 0 2px 6px #2865e0;
			border-radius: 20px;
		}
	
		.logo {
			width: 200px;
			height: 350px;
			overflow: hidden;
	
			img {
				width: 100%;
			}
		}
	}
	.el-carousel__item h3 {
	    color: #475669;
	    font-size: 18px;
	    opacity: 0.75;
	    line-height: 300px;
	    margin: 0;
	  }
	  
	  
</style>