<template>
	<div class="content">
		<div class="list">
			<div class="item" v-for="(item,index) in list" :key="index" @click="funitem(item)">
				<div class="logo"> <img :src="item.logo" /> </div>
				<div class="title">{{ item.tmpname }}</div>
				<div class="title">{{ item.createDate }}</div>
				<div class="edit">
					<el-dropdown @command="funMenu($event,item)">
						<span class="el-dropdown-link">
							<i class="el-icon-s-operation"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<!-- <el-dropdown-item :command="'yanqi'">延期</el-dropdown-item> -->
							<el-dropdown-item :command="'usemb'">发布模版</el-dropdown-item>
							<el-dropdown-item :command="'delmb'">删除模版</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
			</div>
		</div>
		<div style="width: 100%;text-align: center">
			<!-- 分页组件 -->
			<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
		</div>
		<div class="msg">
			{{datamsg}}
		</div>
		<showload :showload="showload"></showload>
	</div>
</template>
<script>
	import designTemplataApi from "../../../api/cus/designtmplate.js";
	import Pagination from '@/components/Pagination.vue'
	import dragBox from "@/components/applet/toolbox/dragbox.vue";
	import showload from "@/components/loading";
	import {getloginuser} from '../../../utils/util.js'
	export default {
		components: {
			showload,
			dragBox,
			Pagination
		},
		data() {
			return {
				list: [],
				datamsg: '',
				ispermissions: true,
				formInline: {
					name: "",
					pageno: 1,
					pagesize: 10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
				showload:false
			}
		},

		mounted() {
			let u = getloginuser()
			// let u = sessionStorage.getItem('customeruser')
			if (u != null) {
				this.userInfo = u;
				console.log(this.userInfo, 'this.userInfo');
				if (this.userInfo.permissions != undefined && this.userInfo.permissions[0] == "admin") {
					this.ispermissions = true
				} else {
					this.ispermissions = false
				}
			} else {
				this.$router.push("/");
			}
			this.initdata(this.formInline);
		},
		methods: {
			initdata: function(page) {
				console.log('initdata')
				let param = {
					pageno: page.pageno,
					pagesize: page.pagesize
				}
				this.showload = true
				designTemplataApi.getUserMytemplist(param, res => {
					console.log(res, 'designTemplataApi.res1203');
					this.showload = false
					if (res.code == 200) {
						if (res.data.list.length == 0) {
							this.datamsg = '无数据'
						} else {
							this.list = res.data.list
							this.pageparm.currentPage = res.data.pageno
							this.pageparm.pageSize = res.data.pagesize
							this.pageparm.total = res.data.total
						}
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.initdata(this.formInline)
			},
			funMenu(type, item) {
				switch (type) {
					case 'yanqi':
						break;
					case 'usemb':
						this.funUse(item.tmpid)
						break;
					case 'delmb':
						this.fundel(item.sysid)
						break;
					default:
						break;
				}
			},
			funUse(tmpid) {
				let param = {
					sys_tempid: tmpid
				}
				designTemplataApi.usemb(param, res => {
					console.log(res, 'delmb.res1203');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: '模版使用成功'
						})
						
					}
				})
			},
			fundel(sysid) {
				this.$confirm('是否删除此模版, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						sysid: sysid
					}
					designTemplataApi.delmb(param, res => {
						console.log(res, 'delmb.res1203');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: '删除模版成功'
							})
							this.initdata(this.formInline)
						}else{
						this.$message({
							type: "error",
							message: res.msg
						})
					}
					})
				}).catch(() => {
				
				});
				
			},
			funitem: function(item) {
				let sysid = item.sysid
				console.log(item, 'item');
				this.$router.push({
					path: `/customer/xcxtemplate/mytempdetail?sysid=${sysid}`
				})
			},
		}
	}
</script>
<style lang="less" scoped>
	.content {
		width: 90%;
		min-width: 1200px;
		margin: 0 auto;
	}

	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
	}

	.list .edit {
		position: absolute;
		right: 0;
		top: 0;
		padding-right: 10px;
	}

	.item {
		text-align: center;
		box-shadow: 0 2px 6px #ccc;
		padding: 20px;
		margin: 20px 20px 0 0;
		position: relative;

		&:hover {
			box-shadow: 0 2px 6px #2865e0;
			border-radius: 20px;
		}

		.logo {
			width: 200px;
			height: 350px;
			overflow: hidden;

			img {
				width: 100%;
			}
		}
	}
</style>