<template>
	<div class="content">
		<div class="item" v-for="(item, index) in list" :key="index" @click="funreg(item)">
			<div class="logo"><img :src="item.logo" /> </div>
			<div class="title">{{ item.tmpname }}</div>
		</div>
		<!-- 分页组件 -->
		<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather" ></Pagination>
	</div>
</template>
<script>
	import Pagination from '@/components/Pagination.vue'
	import designTemplataApi from "../../../api/cus/designtmplate.js";
	export default {
		components: {
			Pagination
		},
		data() {
			return {
				list: [],
				formInline:{
					name:"",
					pageno:1,
					pagesize:10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
			};
		},

		mounted() {
			this.initdata(this.formInline);
		},
		methods: {
			initdata: function(page) {
				console.log("initdata");
				designTemplataApi.getSysTemplate({
					pageno: page.pageno,
					pagesize: page.pagesize
				}, (res) => {
					console.log(res);
					if (res.code == 200) {
						this.list = res.data.list;
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					}
				});
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.initdata(this.formInline)
			},
			funreg: function(item) {
				this.$router.push({
					path: "/designer/xcxtemplate/marketdetail",
					query: {
						tmpid: item.tmpid
					}
				})
			},
		},
	};
</script>
<style lang="less" scoped>
	.content {
		width: 80%;
		min-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
	}

	.item {

		overflow: hidden;
		text-align: center;
		margin: 50px 40px 0 0;
		cursor: pointer;
		border-radius: 20px;
		box-shadow: 0 2px 6px #ccc;

		&:hover {
			box-shadow: 0 2px 6px #519bdc;
		}

		.title {
			height: 50px;
			line-height: 50px;
		}
	}

	.item .logo {
		width: 180px;
		height: 320px;
		padding: 20px;
		overflow: hidden;


	}

	.item .logo img {
		width: 100%;
	}
</style>