var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ borderBottom: _vm.border,'padding-top':_vm.margintop,
          'padding-bottom':_vm.marginbottom,'padding-left':  _vm.list.options.paddingpage + 'px',
        'padding-right':  _vm.list.options.paddingpage + 'px',background: _vm.list.options.bgcolor
          }),attrs:{"id":"wenben"}},[_c('div',{staticClass:"box",style:({background: _vm.list.options.background,'padding-top':_vm.paddingtop,
          'padding-bottom':_vm.paddingbottom,'border-radius':_vm.list.options.borderradius+'px'})},[_c('div',{staticClass:"cont"},[_c('div',{style:({
				  fontSize: _vm.getsize(_vm.list.options.size),
				  textAlign: _vm.align,
				  fontWeight: _vm.bold,
				  color: _vm.list.options.titlecolor,
				})},[_vm._v(" "+_vm._s(_vm.list.options.name)+" ")]),_c('div',{staticClass:"describe",style:({ fontSize: _vm.getsize(_vm.list.options.describe), color: _vm.list.options.describecolor })},[_vm._v(" "+_vm._s(_vm.list.options.label)+" ")])]),(_vm.list.options.ewmshow)?_c('div',{staticClass:"btns"},[(_vm.list.options.ewmimg&&_vm.list.options.ewmimg.length > 0)?_c('div',{staticClass:"ewmimg"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.list.options.ewmimg}})]):_vm._e()]):_vm._e(),(_vm.list.options.bgimg&&_vm.list.options.bgimg.length > 0)?_c('div',{staticClass:"bgimg"},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.list.options.bgimg}})]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }