import httpapi from '../../utils/httpapi';
const userApi = {
  list(funsucc) {
    httpapi.get('/system/usr/sys/page').then(res => {
      if (funsucc) {
        funsucc(res)
      }
    }).catch({

    })

  },
  edit(param, funsucc) {
    let o = {
     isuse: param.isuse,
     logincode: param.logincode,
     loginpwd: param.loginpwd,
     menulist: param.menulist,
     nickname: param.nickname,
     uid: param.uid,
     wxwork_userid: param.wxwork_userid
    }
    console.log(o, 'ooo');
    httpapi.post('/system/usr/sys/edit',o).then(res => {
      console.log(res,'add.res');
      if (funsucc) {
        funsucc(res)
      }
    }).catch({

    })
  },
  del(id, funsucc) {
    console.log(id, 'del.id');
    httpapi.post('/api/sys/user/del',{
      uid:id
    }).then(res => {
      if (funsucc) {
        funsucc(res)
      }
    }).catch({

    })
  },

  detail(param, funsucc) {
    httpapi.post('/system/usr/sys/detail',param).then(res => {
      if (funsucc) {
        funsucc(res)
      }
    }).catch({

    })
  },

}
export default userApi
