<template>
	<div class="" style="width: 1360px;display: flex;">
		<section class="menulist">
			<div class="item" v-for="(item,index) in pagelist" :key="index" @click="fungotopage(item)">
				{{ item.pagename }}
			</div>
			<div class="item" @click="funtabbar()">菜单</div>
			<div class="item selected" @click="funStyle">风格</div>
			<div class="item " @click="funset">设置</div>
		</section>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>系统风格管理</span>
				<!-- <el-button type="text" @click="changetmp" style="position: absolute;right: 20px;">更换模板</el-button> -->
			</div>
			<div class="main">
				<div class="box" v-for="(item,index) in styleList" :key="index">
					<div :style="{background:item.page_bg_color}" :class="item.style_id==style_id?'curr' : 'cusm'"
						class="item_cont">
						<div class="item" v-if="item.style_id == 0" @click="funCustom(item)">
							自定义
						</div>
						<div class="item" v-else :style="{background:item.subject1_bg_color}"
							@click="funselStyle(item)">
							<i class="el-icon-check" v-if="item.style_id==style_id" style="color: #fff;"></i>
						</div>

					</div>
					<div class="stylemc" :style="{color:item.style_id==style_id?item.subject1_bg_color : ''}">
						{{item.style_name}}
					</div>

				</div>
			</div>
		</el-card>
		<el-dialog :title="title" :visible.sync="editstyleVisible" width="50%" style="margin-top: -130px;">
			<div class="boxs" style="display: flex;flex-direction: column;">
				<div>
					<div class="item">
						<span>主题背景颜色</span>{{styleitem.subject1_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.subject1_bg_color" size="medium"></el-color-picker><span
								@click="setkey('subject1_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>主题字体色</span>{{styleitem.subject1_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.subject1_txt_color"
								size="medium"></el-color-picker><span @click="setkey('subject1_txt_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>设计页背景颜色</span>{{styleitem.subject2_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.subject2_bg_color" size="medium"></el-color-picker><span
								@click="setkey('subject2_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>设计页字体色</span>{{styleitem.subject2_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.subject2_txt_color"
								size="medium"></el-color-picker><span @click="setkey('subject2_txt_color')">重置</span>
						</div>
					</div>
				</div>
				<div class="sel-menu" style="display: flex;">
					<div class="black" :class="selcolor == 'black'?'sel':''" @click="funblack">黑色</div>
					<div class="black" :class="selcolor == 'white'?'sel':''" @click="funwhite">白色</div>
				</div>
				<div class="" @click="moreShow = !moreShow">
					<el-button size="small" type="primary">{{moreShow ?'关闭高级选项':'显示高级选项'}}</el-button>
				</div>
				<div class="more" v-if="moreShow">
					<div class="item">
						<span>页面背景色</span>{{styleitem.page_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.page_bg_color" size="medium"></el-color-picker><span
								@click="setkey('page_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>页面字体色</span>{{styleitem.page_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.page_txt_color" size="medium"></el-color-picker><span
								@click="setkey('page_txt_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>页面前景色</span>{{styleitem.front_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.front_bg_color" size="medium"></el-color-picker><span
								@click="setkey('front_bg_color')">重置</span>
						</div>

					</div>
					<div class="item">
						<span>页面前景字体色</span>{{styleitem.front_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.front_txt_color" size="medium"></el-color-picker><span
								@click="setkey('front_txt_color')">重置</span>
						</div>
					</div>

					<div class="item">
						<span>页面标题字体色（白/黑）</span>{{styleitem.page_title_txt_color}}
						<div class="btn">
							<!-- <el-color-picker v-model="styleitem.page_title_txt_color"
								size="medium"></el-color-picker> -->
							<el-radio-group v-model="styleitem.page_title_txt_color" size="small">
								<el-tooltip class="item" effect="dark" content="黑色" placement="bottom">
									<el-radio-button label="#000000"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="白色" placement="bottom">
									<el-radio-button label="#FFFFFF">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
							<span @click="setkey('page_title_txt_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>页头标题背景色</span>{{styleitem.page_title_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.page_title_bg_color"
								size="medium"></el-color-picker><span @click="setkey('page_title_bg_color')">重置</span>
						</div>
					</div>



					<div class="item">
						<span>点单分类背景色</span>{{styleitem.text1_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.text1_bg_color" size="medium"></el-color-picker><span
								@click="setkey('text1_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>点单分类字体色</span>{{styleitem.text1_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.text1_txt_color" size="medium"></el-color-picker><span
								@click="setkey('text1_txt_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>页脚背景色</span>{{styleitem.text2_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.text2_bg_color" size="medium"></el-color-picker><span
								@click="setkey('text2_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>页脚字体色</span>{{styleitem.text2_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.text2_txt_color" size="medium"></el-color-picker><span
								@click="setkey('text2_txt_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>文本3背景色</span>{{styleitem.text3_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.text3_bg_color" size="medium"></el-color-picker><span
								@click="setkey('text3_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>文本3字体色(边框色)</span>{{styleitem.text3_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.text3_txt_color" size="medium"></el-color-picker><span
								@click="setkey('text3_txt_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>标题1背景色</span>{{styleitem.title1_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.title1_bg_color" size="medium"></el-color-picker><span
								@click="setkey('title1_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>标题1字体色</span>{{styleitem.title1_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.title1_txt_color" size="medium"></el-color-picker><span
								@click="setkey('title1_txt_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>标题2背景色</span>{{styleitem.title2_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.title2_bg_color" size="medium"></el-color-picker><span
								@click="setkey('title2_bg_color')">重置</span>
						</div>
					</div>

					<div class="item">
						<span>标题3背景色</span>{{styleitem.title3_bg_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.title3_bg_color" size="medium"></el-color-picker><span
								@click="setkey('title3_bg_color')">重置</span>
						</div>
					</div>
					<div class="item">
						<span>标题3字体色</span>{{styleitem.title3_txt_color}}
						<div class="btn">
							<el-color-picker v-model="styleitem.title3_txt_color" size="medium"></el-color-picker><span
								@click="setkey('title3_txt_color')">重置</span>
						</div>
					</div>

				</div>

			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="editstyleVisible = false">取 消</el-button>
				<el-button type="primary" @click="funOk">保存</el-button>
			</span>
		</el-dialog>
		<!-- 切换界面 -->
		<el-dialog :title="linktitle" :visible.sync="linkVisible" width="80%" @click='linkVisible=false'>
			<div class="maintwo">
				<div class="box" v-for="(item,index) in tempuserData" :key="index" @click="funtempOk(item)">
					<div class="img">
						<img :src="item.logo" />
					</div>
					<div class="">
						{{item.tmpname}}
					</div>
					<div class="bg">
						<span>选择此模板</span>
					</div>
				</div>
			</div>
			<!-- 分页组件 -->
			<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='linkVisible=false'>关闭</el-button>
			</div>
		</el-dialog>
		<showload :showload="showload"></showload>
	</div>
</template>

<script>
	import showload from "@/components/loading";
	import Pagination from '@/components/Pagination.vue'
	import tempsysApi from '../../../api/sys/tempsys.js'
	import styleApi from '../../../api/isv/style.js'
	import designTemplataApi from "../../../api/isv/designtmplate";
	export default {
		components: {
			showload,
			Pagination
		},
		data() {
			return {
				title: "编辑",
				linktitle: "",
				linkVisible: false,
				tempuserData: [],
				styleList: [],
				styleitem: {},
				pagelist: [],
				dialogVisible: false,
				templateid: "",
				style_id: "",
				moreShow: false,
				editstyleVisible: false,
				selcolor: "",
				showload: false,
				formInline: {
					name: "",
					pageno: 1,
					pagesize: 10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
				defaultstyle: {
					white: {
						front_bg_color: "#FFFFFF",
						front_txt_color: "#282828",
						page_bg_color: "#F3F3F3",
						page_title_bg_color: "#FFFFFF",
						page_title_txt_color: "#000000",
						page_txt_color: "#303030",
						// subject1_bg_color: "#002EA2",
						// subject1_txt_color: "#FFFFFF",
						// subject2_bg_color: "#717171",
						// subject2_txt_color: "#4F4F4F",
						text1_bg_color: "#EB0C0C",
						text1_txt_color: "#565656",
						text2_bg_color: "#EF0A0A",
						text2_txt_color: "#6A6A6A",
						text3_bg_color: "#E1E1E1",
						text3_txt_color: "#E1E1E1",
						title1_bg_color: "#B5B5B5",
						title1_txt_color: "#777777",
						title2_bg_color: "#525252",
						title2_txt_color: "#9D9D9D",
						title3_bg_color: "#FFFFFF",
						title3_txt_color: "#FFFFFF",
					},
					black: {
						front_bg_color: "#20232B",
						front_txt_color: "#E8E8E8",
						page_bg_color: "#181920",
						page_title_bg_color: "#94B2D6",
						page_title_txt_color: "#FFFFFF",
						page_txt_color: "#E8E8E8",
						// subject1_bg_color: "#94B2D6",
						// subject1_txt_color: "#181920",
						// subject2_bg_color: "#FFFFFF",
						// subject2_txt_color: "#8E5555",
						text1_bg_color: "#B75050",
						text1_txt_color: "#B83535",
						text2_bg_color: "#B2B2B2",
						text2_txt_color: "#CFCFCF",
						text3_bg_color: "#A5A5A5",
						text3_txt_color: "#292A34",
						title1_bg_color: "#DA2C2C",
						title1_txt_color: "#B03838",
						title2_bg_color: "#E5E5E5",
						title2_txt_color: "#EEA60B",
						title3_bg_color: "#CDCDCD",
						title3_txt_color: "#C2C2C2",
					},
				}
			}
		},
		mounted() {
			let templateid = this.$route.query.tid
			this.templateid = templateid
			this.getStyle()
			this.initdata()
		},
		methods: {
			initdata() {
				let _this = this;
				let param = {
					tmpid: this.templateid
				}
				this.showload = true
				designTemplataApi.getstyle(param, res => {
					console.log(res, 'designTemplataApi.res');
					if (res.code == 200) {
						this.style_id = res.data.style.id
						this.styleitem = JSON.parse(JSON.stringify(res.data.style.item))
					}
				})
				designTemplataApi.getUserPageList({
					id: this.templateid
				}, res => {
					this.showload = false
					console.log(res, 'getUserPageList');
					if (res.code == 200) {
						this.pagelist = res.data.pagelist
					}
				})
			},
			getStyle() {
				styleApi.list({}, res => {
					console.log(res, 'styleApi.res');
					if (res.code == 200) {
						res.data.push({
							style_id: 0,
							style_name: '自定义'
						})
						this.styleList = res.data
					}
				})
			},
			fungotopage(item) {
				this.$router.replace({
					path: "/designer/xcxtemplate/design",
					query: {
						tid: item.tmpid,
						t: item.pageno,
						mc: item.pagename,
					}
				})
			},
			funblack() {
				this.selcolor = 'black'
				this.styleitem.front_bg_color = this.defaultstyle.black.front_bg_color,
					this.styleitem.front_txt_color = this.defaultstyle.black.front_txt_color
				this.styleitem.page_bg_color = this.defaultstyle.black.page_bg_color
				this.styleitem.page_title_bg_color = this.defaultstyle.black.page_title_bg_color
				this.styleitem.page_title_txt_color = this.defaultstyle.black.page_title_txt_color
				this.styleitem.page_txt_color = this.defaultstyle.black.page_txt_color
				this.styleitem.text1_bg_color = this.defaultstyle.black.text1_bg_color
				this.styleitem.text1_txt_color = this.defaultstyle.black.text1_txt_color
				this.styleitem.text2_bg_color = this.defaultstyle.black.text2_bg_color
				this.styleitem.text2_txt_color = this.defaultstyle.black.text2_txt_color
				this.styleitem.text3_bg_color = this.defaultstyle.black.text3_bg_color
				this.styleitem.text3_txt_color = this.defaultstyle.black.text3_txt_color
				this.styleitem.title1_bg_color = this.defaultstyle.black.title1_bg_color
				this.styleitem.title1_txt_color = this.defaultstyle.black.title1_txt_color
				this.styleitem.title2_bg_color = this.defaultstyle.black.title2_bg_color
				this.styleitem.title2_txt_color = this.defaultstyle.black.title2_txt_color
				this.styleitem.title3_bg_color = this.defaultstyle.black.title3_bg_color
				this.styleitem.title3_txt_color = this.defaultstyle.black.title3_txt_color
			},
			funwhite() {
				this.selcolor = 'white'
				this.styleitem.front_bg_color = this.defaultstyle.white.front_bg_color,
					this.styleitem.front_txt_color = this.defaultstyle.white.front_txt_color
				this.styleitem.page_bg_color = this.defaultstyle.white.page_bg_color
				this.styleitem.page_title_bg_color = this.defaultstyle.white.page_title_bg_color
				this.styleitem.page_title_txt_color = this.defaultstyle.white.page_title_txt_color
				this.styleitem.page_txt_color = this.defaultstyle.white.page_txt_color
				this.styleitem.text1_bg_color = this.defaultstyle.white.text1_bg_color
				this.styleitem.text1_txt_color = this.defaultstyle.white.text1_txt_color
				this.styleitem.text2_bg_color = this.defaultstyle.white.text2_bg_color
				this.styleitem.text2_txt_color = this.defaultstyle.white.text2_txt_color
				this.styleitem.text3_bg_color = this.defaultstyle.white.text3_bg_color
				this.styleitem.text3_txt_color = this.defaultstyle.white.text3_txt_color
				this.styleitem.title1_bg_color = this.defaultstyle.white.title1_bg_color
				this.styleitem.title1_txt_color = this.defaultstyle.white.title1_txt_color
				this.styleitem.title2_bg_color = this.defaultstyle.white.title2_bg_color
				this.styleitem.title2_txt_color = this.defaultstyle.white.title2_txt_color
				this.styleitem.title3_bg_color = this.defaultstyle.white.title3_bg_color
				this.styleitem.title3_txt_color = this.defaultstyle.white.title3_txt_color
			},
			funset() {
				this.$router.push({
					path: "/designer/xcxtemplate/setting",
					query: {
						tid: this.templateid
					}
				})
			},
			funStyle() {
				this.$router.push({
					path: "/designer/xcxtemplate/style",
					query: {
						tid: this.templateid
					}
				})
			},
			funtabbar() {
				this.$router.push({
					path: "/designer/xcxtemplate/tabbar",
					query: {
						tid: this.templateid
					}
				})
			},
			funselStyle(item) {
				let param = {
					style_id: item.style_id,
					tmpid: this.templateid
				}
				console.log(param, 'param.funselStyle');
				styleApi.edit(param, res => {
					console.log(res, 'styleApi.res');
					if (res.code == 200) {
						// sessionStorage.setItem('style_id',item.style_id)
						// console.log(this.styleList,'this.styleList');
						this.style_id = item.style_id
						this.$message({
							type: "success",
							message: "编辑成功"
						})
					}
				})
			},
			changetmp() {
				this.linktitle = "切换模板"
				this.linkVisible = true
				this.stylelist(this.formInline)
			},
			stylelist(page) {
				styleApi.syslist({
					pageno: page.pageno,
					pagesize: page.pagesize
				}, res => {
					console.log(res, 'list.res');
					if (res.code == 200) {
						this.tempuserData = res.data.list
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.stylelist(this.formInline)
			},
			funtempOk(item) {
				console.log(item, 'item');
				let param = {
					sys_tempid: item.tmpid,
					tmpName: item.tmpname,
					tmpid: this.templateid
				}
				console.log(param, 'changetemplate.param');
				styleApi.changetemplate(param, res => {
					console.log(res, 'changetemplate.res');
					this.linkVisible = false
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "切换成功"
						})
					} else {
						this.$message({
							type: "error",
							message: "切换失败"
						})
					}
				})
			},
			funCustom(item) {
				this.style_id = 0
				this.styleitem.tmpid = this.templateid
				this.editstyleVisible = true
			},
			funOk() {
				if (this.styleitem.front_bg_color == undefined || this.styleitem.front_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面前景色"
					})
					return
				}
				if (this.styleitem.front_txt_color == undefined || this.styleitem.front_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面前景字体色"
					})
					return
				}
				if (this.styleitem.page_bg_color == undefined || this.styleitem.page_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面背景色"
					})
					return
				}
				if (this.styleitem.page_title_txt_color == undefined || this.styleitem.page_title_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面标题字体色（白/黑）"
					})
					return
				}
				if (this.styleitem.page_txt_color == undefined || this.styleitem.page_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面字体色"
					})
					return
				}
				if (this.styleitem.page_txt_color == undefined || this.styleitem.page_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择页面字体色"
					})
					return
				}
				if (this.styleitem.subject1_bg_color == undefined || this.styleitem.subject1_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择主题1背景颜色"
					})
					return
				}
				if (this.styleitem.subject1_txt_color == undefined || this.styleitem.subject1_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择主题1字体色"
					})
					return
				}
				if (this.styleitem.subject2_bg_color == undefined || this.styleitem.subject2_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择设计页背景颜色"
					})
					return
				}
				if (this.styleitem.subject2_txt_color == undefined || this.styleitem.subject2_txt_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择设计页字体色"
					})
					return
				}
				if (this.styleitem.text1_bg_color == undefined || this.styleitem.text1_bg_color <= 0) {
					this.$message({
						type: "info",
						message: "请选择点单分类背景色"
					})
					return
				}
				if (this.styleitem.text1_txt_color == undefined || this.styleitem.text1_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择点单分类字体色"
					})
					return
				}
				if (this.styleitem.text2_bg_color == undefined || this.styleitem.text2_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择页脚背景色"
					})
					return
				}
				if (this.styleitem.text2_txt_color == undefined || this.styleitem.text2_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择页脚字体色"
					})
					return
				}
				if (this.styleitem.title1_bg_color == undefined || this.styleitem.title1_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题1背景色"
					})
					return
				}
				if (this.styleitem.title1_txt_color == undefined || this.styleitem.title1_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题1字体色"
					})
					return
				}
				if (this.styleitem.title2_bg_color == undefined || this.styleitem.title2_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题2背景色"
					})
					return
				}
				if (this.styleitem.title2_txt_color == undefined || this.styleitem.title2_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题2字体色"
					})
					return
				}
				if (this.styleitem.page_title_bg_color == undefined || this.styleitem.page_title_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题页头标题背景色"
					})
					return
				}
				if (this.styleitem.title3_bg_color == undefined || this.styleitem.title3_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题3背景色"
					})
					return
				}
				if (this.styleitem.title3_txt_color == undefined || this.styleitem.title3_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择标题3字体色"
					})
					return
				}
				if (this.styleitem.text3_bg_color == undefined || this.styleitem.text3_bg_color == "") {
					this.$message({
						type: "info",
						message: "请选择文本3背景色"
					})
					return
				}
				if (this.styleitem.text3_txt_color == undefined || this.styleitem.text3_txt_color == "") {
					this.$message({
						type: "info",
						message: "请选择文本3字体色(边框色)"
					})
					return
				}
				console.log(this.styleitem, 'this.styleitem');
				styleApi.editstyle(this.styleitem, res => {
					console.log(res, 'styleApi.res');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "编辑成功"
						})
						this.editstyleVisible = false
					}
				})
			},
			setkey(key) {
				console.log(this.styleitem, 'this.styleitem');
				this.$delete(this.styleitem, key)
			},
			funedit(row) {
				console.log(row, 'row');
				this.title = "编辑" + "（" + row.style_id + "）"
				this.styleitem = JSON.parse(JSON.stringify(row))
				this.dialogVisible = true
			}
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
	}

	.menulist {
		float: left;
		width: 80px;
		height: calc(100vh - 100px);
		position: relative;
		background: #fff;
		border-right: 1px solid #f0f0f0;
		border-radius: 5px;
		overflow-y: auto;
		overflow-x: hidden;
		box-shadow: 0 2px 6px #ccc;


	}

	.selected {
		background: #3089dc !important;
		color: #fff;
	}

	.menulist .item {
		cursor: pointer;
		height: 60px;
		line-height: 60px;
		text-align: center;
		font-weight: 300;
		font-size: 12pt;
		position: relative;
	}

	.menulist .disp {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.box-card {
		position: relative;
		width: 100%;
	}

	.main {
		display: flex;
		flex-wrap: wrap;
	}

	.box {
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		position: relative;
	}

	.box .stylemc {
		white-space: nowrap;
	}

	.box .item {
		/* margin: 10px 0;
		display: flex;
		align-items: center; */
		cursor: pointer;
		width: 50px;
		height: 50px;
		font-size: 10px;
		line-height: 50px;
		text-align: center;
		border-radius: 100%;
	}

	/* 
	.box .item span {
		margin: 0 20px;
	} */
	.el-input {
		width: 300px !important;
	}

	.curr {
		border: 3px solid #55aaff;
	}

	.cusm {
		border: 3px solid #ccc;
	}

	.btn {
		margin-right: 20px;
		float: right;
		display: flex;
		align-items: center;
	}

	.boxs {
		height: calc(100vh - 300px);
		overflow-y: auto;
	}

	.boxs .item {
		line-height: 3.5;
		border-bottom: 1px solid #f0f0f0;
	}



	.sel-menu .black {
		border: 1px solid #ccc;
		width: 100px;
		height: 50px;
		line-height: 50px;
		margin: 10px;
		cursor: pointer;
		text-align: center;
	}

	.sel {
		color: #fff;
		background-color: #3089dc;
	}

	.item_cont {
		margin: 0 10px;
		width: 58px;
		height: 58px;
		/* overflow: hidden; */
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100px;
	}

	.maintwo {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		overflow-y: auto;
	}

	.maintwo .box {
		border: 2px solid #000;
		border-radius: 10px;
		margin: 10px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}

	.maintwo .box .bg {
		display: none;
	}

	.maintwo .box:hover .bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.6);
	}

	.maintwo .box .img {
		width: 150px;
		height: auto;
	}

	.maintwo .box .img img {
		width: 100%;
		height: 100%;
	}

	
</style>