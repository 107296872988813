var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"img"},[(_vm.list.options!= undefined && _vm.list.options.stylebox == 1)?_c('div',{staticClass:"imgcont",style:({
          'margin-left': _vm.marginpage,
          'margin-right': _vm.marginpage,
          'margin-top': _vm.margintop,
          'margin-bottom': _vm.marginbottom,
        })},[_c('div',{staticClass:"box"},_vm._l((_vm.list.data),function(item,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data && _vm.data.data.length > 0),expression:"data.data && data.data.length > 0"}],key:index,staticClass:"box-item",style:({
		    width: _vm.colWidth,
		    height: _vm.height,
			'border-radius':_vm.list.options.borderradius + 'px',
			'background':_vm.list.options.bgcolor,
		    'margin-left': index > 0 ? _vm.marginitem : '0px',
		  })},[(item.url.length > 0)?_c('el-image',{staticStyle:{"display":"block","flex-shrink":"0"},style:({
			      width: _vm.colWidth,
			      height: _vm.height,
			      'margin-left': index > 0 ? _vm.marginitem : '0px',
			    }),attrs:{"src":item.url}}):_vm._e(),_c('div',{staticClass:"op",style:({'color':_vm.list.options.color})},[_vm._m(0,true),_c('div',{staticClass:"memo"},[_vm._v(" 无门槛使用 ")])]),_c('div',{staticClass:"line",style:({'border': _vm.linecolor})}),_c('div',{staticClass:"l-radius",style:({background:_vm.circlecolor})}),_c('div',{staticClass:"r-radius",style:({background:_vm.circlecolor})})],1)}),0)]):_c('div',{staticClass:"box2",style:({
		    'margin-top': _vm.margintop,
		    'margin-bottom': _vm.marginbottom,
			'margin-left': _vm.marginpage,
			'margin-right': _vm.marginpage,
		  })},_vm._l((_vm.list.data),function(item,index){return _c('div',{key:index,staticClass:"box2-item",style:({
					'margin-bottom': _vm.marginitem,width: _vm.colWidth2,'background':_vm.list.options.bgcolor,'color':_vm.list.options.color,'border-radius':_vm.list.options.borderradius + 'px'})},[_vm._m(1,true),_c('div',{staticClass:"box2-right"},[_c('div',{staticClass:"title"},[_vm._v(" 满199使用 ")]),_c('div',{staticClass:"btn",style:({'background':_vm.list.options.btnbgcolor,'color':_vm.list.options.btncolor,'border-radius':_vm.list.options.btnradius + 'px'})},[_vm._v(" 立即领用 ")])])])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data.length == 0),expression:"data.data.length == 0"}],staticClass:"empty",style:({
          height: _vm.height,
          overflow: 'hidden', 
        })},[_c('el-empty',{style:({
            height: _vm.height,
          }),attrs:{"description":"此区域放置图片"}})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("￥")]),_vm._v("1000 ")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box2-left"},[_c('span',[_vm._v("￥")]),_c('span',{staticClass:"price"},[_vm._v("10")])])}]

export { render, staticRenderFns }