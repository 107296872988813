<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="11"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>小程序配置</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="box-card">
					<h4>基本信息</h4>
					<div class="table">
						<div class="thead">
							<div class="tb-mc">小程序名称</div>
							<div class="tbody">
								<div class="titem">{{wxInfo.nickname}}</div>
								<div class="titem">小程序发布前，可修改2次名称。当前还能修改2次。发布后，个人账号可一年内修改2次名称。</div>
								<div class="titem">
									<el-button type="text" @click="funUpName">修改</el-button>
								</div>
							</div>
						</div>
						<!-- <div class="thead">
							<div class="tb-mc">小程序简称</div>
							<div class="tbody">
								<div class="titem">{{nickname_info.nickname}}</div>
								<div class="titem"> 一年内可申请修改2次，今年还可修改2次</div>
								<div class="titem">
									<el-button type="text">修改</el-button>
								</div>
							</div>
						</div> -->
						<div class="thead">
							<div class="tb-mc">小程序头像</div>
							<div class="tbody">
								<div class="titem"><img :src="head_image_info.head_image_url" /></div>
								<div class="titem">
									一年内可申请修改5次，今年还可修改5次
								</div>
								<div class="titem">
									<el-button type="text" @click="funUpLogo">修改</el-button>
								</div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">介绍</div>
							<div class="tbody">
								<div class="titem">{{signature_info.signature}}</div>
								<div class="titem">
									一个月内可{{signature_info.modify_quota}}次修改，本月还可修改{{signature_info.modify_used_count}}次
								</div>
								<div class="titem"><el-button type="text" @click="funIntroduce">修改</el-button></div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">服务类目</div>
							<div class="tbody">
								<div class="titem">工具 > 图片处理</div>
								<div class="titem">
									一个月内可申请修改5次，本月还可修改5次
								</div>
								<div class="titem"><el-button type="text" @click="funcls">详情</el-button></div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">主体信息</div>
							<div class="tbody">
								<div class="titem">{{wxInfo.principal_name}}</div>
								<div class="titem">
									个人
								</div>
								<div class="titem">
									<div><el-button type="text">小程序主体变更</el-button></div>
									<div><el-button type="text">详情</el-button></div>
								</div>
							</div>
						</div>
				
						<div class="thead">
							<div class="tb-mc">微信认证</div>
							<div class="tbody">
								<div class="titem">未认证</div>
								<div class="titem">
									个人号无法认证
								</div>
								<div class="titem">
								</div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">微信认证主体类型</div>
							<div class="tbody">
								<div class="titem">未认证</div>
								<div class="titem">
								</div>
								<div class="titem">
								</div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">相关小程序</div>
							<div class="tbody">
								<div class="titem">暂无</div>
								<div class="titem">
									默认展示同个微信开放平台账号下的小程序，可添加团队的其他小程序
								</div>
								<div class="titem">
									<el-button type="text">修改</el-button>
								</div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">相关公众号</div>
							<div class="tbody">
								<div class="titem">暂无</div>
								<div class="titem">
									默认展示同个微信开放平台账号下的公众号，可添加团队的其他公众号
								</div>
								<div class="titem">
									<el-button type="text">修改</el-button>
								</div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">小程序备案</div>
							<div class="tbody">
								<div class="titem">未备案</div>
								<div class="titem">
								</div>
								<div class="titem">
									<a target="_blank" href="https://mp.weixin.qq.com/"
										style="color: #00aaff;flex-grow: 1;text-align: right;">
										去备案
									</a>
								</div>
							</div>
						</div>
						<div class="thead">
							<div class="tb-mc">用户隐私保护指引</div>
							<div class="tbody">
								<div class="titem"></div>
								<div class="titem">
								</div>
								<div class="titem">
									<el-button type="text" @click="funprivacy">去更新</el-button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="box-card">
					<h4>服务器域名</h4>
					<div class="box-item">
						<div class="title">request合法域名</div>
						<div class="ipt">
							<el-input type="text" size="small" v-model="iptdata.requestdomain"
								placeholder="以 https:// 开头。可填写200个域名，域名间请用 ; 分割"></el-input>
						</div>
					</div>
				
					<div class="box-item">
						<div class="title">uploadFile合法域名</div>
						<div class="ipt">
							<el-input type="text" size="small" v-model="iptdata.uploaddomain"
								placeholder="以 https:// 开头。可填写200个域名，域名间请用 ; 分割"></el-input>
						</div>
					</div>
					<div class="box-item">
						<div class="title">downloadFile合法域名</div>
						<div class="ipt">
							<el-input type="text" size="small" v-model="iptdata.downloaddomain"
								placeholder="以 https:// 开头。可填写200个域名，域名间请用 ; 分割"></el-input>
						</div>
					</div>
					<div class="box-item">
						<div class="title">socket合法域名</div>
						<div class="ipt">
							<el-input type="text" size="small" v-model="iptdata.wsrequestdomain"
								placeholder="以 wss:// 开头。可填写200个域名，域名间请用 ; 分割"></el-input>
						</div>
					</div>
					<div class="box-item">
						<div class="title"></div>
						<div class="ipt">
							<div class="iptmsg">以 wss:// 开头。可填写200个域名，域名间请用 ; 分割</div>
						</div>
				
					</div>
					<div class="box-item">
						<div class="title">udp合法域名</div>
						<div class="ipt">
							<el-input type="text" size="small" v-model="iptdata.udpdomain"
								placeholder="以 https:// 开头。可填写200个域名，域名间请用 ; 分割"></el-input>
						</div>
				
					</div>
					<div class="box-item">
						<div class="title"></div>
						<div class="ipt">
							<div class="iptmsg">以 udp:// 开头。可填写200个域名，域名间请用 ; 分割</div>
						</div>
				
					</div>
					<div class="box-item">
						<div class="title">tcp合法域名</div>
						<div class="ipt">
							<el-input type="text" size="small" v-model="iptdata.tcpdomain"
								placeholder="以 https:// 开头。可填写200个域名，域名间请用 ; 分割"></el-input>
				
						</div>
				
					</div>
					<div class="box-item">
						<div class="title"></div>
						<div class="ipt">
							<div class="iptmsg">以 tcp:// 开头。可填写200个域名，域名间请用 ; 分割</div>
						</div>
				
					</div>
				
				
					<div class="box-btn">
						<el-button size="small" type="primary" @click="funsavedomain">保存并提交</el-button>
					</div>
				</div>
				<div class="box-card">
					<h4>DNS域名</h4>
					<div class="box-item">
						<div class="title">DNS预解析域名</div>
						<div class="ipt" style="margin-right: 20px;">
							<el-input type="text" size="small" v-model="dnsdomain"
								placeholder="该域名无需填写协议头，最多填写5个域名，域名间请用 ; 分割"></el-input>
						</div>
						<el-button size="small" type="primary">保存并提交</el-button>
					</div>
				
				</div>
				
				<div class="box-card">
					<h4>业务域名</h4>
					<div class="box-item">
						<div class="title">域名</div>
						<div class="ipt" style="margin-right: 20px;">
							<el-input type="text" v-model="webviewiptdata.webviewdomain" size="small"
								placeholder="以 https:// 开头。可填写200个域名，域名间请用 ; 分割"></el-input>
						</div>
						<div style="margin:0 10px;color: #00aaff;" @click="funconfirmfile">获取校验文件</div>
						<el-button size="small" type="primary" @click="funwebviewdomain">保存并提交</el-button>
					</div>
				
				</div>
				<div class="box-card">
					<h4>扫普通链接二维码打开小程序</h4>
					<table class="ewmtitle">
						<th style="width: 35%;">二维码地址</th>
						<th style="width: 25%;">小程序路径</th>
						<th style="width: 10%;">测试范围	</th>
						<th style="width: 10%;">全网发布</th>
						<th style="width: 20%;">操作</th>
					</table>
					<table class="ewmtitle">
						<th style="width: 35%;">二维码地址</th>
						<th style="width: 25%;">小程序路径</th>
						<th style="width: 10%;">测试范围	</th>
						<th style="width: 10%;">全网发布</th>
						<th style="width: 20%;">操作</th>
					</table>
				</div>
				<!-- 编辑界面 -->
				<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
					<el-form label-width="150px" ref="editForm" :model="editForm">
						<el-form-item label="小程序名称" prop="nickname">
							<el-input size="small" v-model="editForm.nickname" auto-complete="off"
								placeholder="请输入小程序名称"></el-input>
						</el-form-item>
						<el-form-item label="营业执照" prop="license_img">
							<el-image style="width: 100px; height: 100px" :src="editForm.license_img" fit="contain"></el-image>
							<el-button size="small" type="primary" @click="funUpImg('license_img')">上传</el-button>
						</el-form-item>
						<el-form-item label="其他图片" prop="naming_other_img">
							<el-image style="width: 100px; height: 100px" :src="editForm.naming_other_img"
								fit="contain"></el-image>
							<el-button size="small" type="primary" @click="funUpImg('naming_other_img')">上传</el-button>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" @click='editFormVisible=false'>取消</el-button>
						<el-button size="small" type="primary" class="title" @click="submitForm('nickname')">保存
						</el-button>
					</div>
				</el-dialog>
				<!-- 编辑Logo界面 -->
				<el-dialog :title="title" :visible.sync="logoVisible" width="30%" @click='logoVisible=false'>
					<el-form label-width="150px" ref="wxlogoForm" :model="wxlogoForm">
						<el-form-item label="其他图片" prop="naming_other_img">
							<el-image style="width: 100px; height: 100px" :src="wxlogoForm.wxlogo" fit="contain"></el-image>
							<el-button size="small" type="primary" @click="funUpImg('wxlogo')">上传</el-button>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" @click='logoVisible=false'>取消</el-button>
						<el-button size="small" type="primary" class="title" @click="submitForm('wxlogo')">保存
						</el-button>
					</div>
				</el-dialog>
				<!-- 编辑小程序介绍界面 -->
				<el-dialog :title="title" :visible.sync="IntroduceVisible" width="30%" @click='IntroduceVisible=false'>
					<el-form label-width="150px" ref="wxIntroduceForm" :model="wxIntroduceForm">
						<el-form-item label="介绍" prop="memo">
							<el-input size="small" v-model="wxIntroduceForm.memo" auto-complete="off"
								placeholder="请输入介绍"></el-input>
						</el-form-item>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" @click='IntroduceVisible=false'>取消</el-button>
						<el-button size="small" type="primary" class="title" @click="submitForm('introduce')">保存
						</el-button>
					</div>
				</el-dialog>
				<el-dialog :title="title" :visible.sync="clsVisible" width="30%" @click='clsVisible=false'>
					<el-form label-width="150px">
						<el-cascader v-model="value" :options="firstlist" @change="handleChange"></el-cascader>
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="small" @click='IntroduceVisible=false'>取消</el-button>
						<el-button size="small" type="primary" class="title" @click="submitForm('introduce')">保存
						</el-button>
					</div>
				</el-dialog>
				<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
			</div>
		</div>
		
	</div>
</template>

<script>
	import selImgs from "@/components/system/selImgs";
	import wxappApi from "../../../../../../api/cus/wxapp.js"
	import webApi from '../../../../../../api/cus/weburl.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			selImgs,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				wxInfo: {},
				nickname_info: {},
				head_image_info: {},
				signature_info: {},
				wxlogoForm: {
					wxlogo: ""
				},
				wxIntroduceForm: {
					memo: ""
				},
				editForm: {
					nickname: '',
					license_img: "",
					naming_other_img: ""
				},
				imgtype: "",
				editFormVisible: false,
				logoVisible: false,
				IntroduceVisible: false,
				clsVisible: false,
				allcls: [],
				firstlist: [],
				secondlist: [],
				thirdlist: [],
				value: [],
				webviewdomain: '',
				olddata: {
					requestdomain: '',
					downloaddomain: '',
					tcpdomain: '',
					udpdomain: '',
					uploaddomain: '',
					wsrequestdomain: '',
					dnsdomain: ''
				},
				iptdata: {
					requestdomain: '',
					downloaddomain: '',
					tcpdomain: '',
					udpdomain: '',
					uploaddomain: '',
					wsrequestdomain: '',

				},
				webviewolddata: {
					webviewdomain: ''
				},
				webviewiptdata: {
					webviewdomain: ''
				},
				dnsdomain: '',
				filename: '',
				filepath: ''
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getbaseinfowx()
				this.getdomainurllist()
				this.getwebview()
				this.getpublishqrcode()
			},
			getbaseinfowx() {
				let param = {
					mpid: this.mpid,
				}
				console.log(param, 'param');
				wxappApi.baseinfofromweixin(param, res => {
					console.log(res, 'baseinfofromweixin');
					if (res.code == 200) {
						this.wxInfo = res.data
						this.nickname_info = res.data.nickname_info
						this.signature_info = res.data.signature_info
						this.head_image_info = res.data.head_image_info
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			funUpName() {
				this.title = "修改小程序名称"
				this.editFormVisible = true
			},
			funUpLogo() {
				this.title = "修改小程序logo"
				this.logoVisible = true
			},
			funIntroduce() {
				this.title = "修改小程序介绍"
				this.IntroduceVisible = true
			},
			funcls() {
				this.title = "服务类目"
				this.getclslist()
			},
			handleChange(value) {

				let clsitem = this.allcls.find(f => {
					return f.id == value[value.length - 1]
				})
				let clsitem2 = this.firstlist.find(f => {
					return f.value == value[value.length - 1]
				})
				console.log(value);
				console.log(clsitem, 'clsitem');
				console.log(clsitem2, 'clsitem2');
			},
			submitForm(type) {
				switch (type) {
					case 'nickname':
						this.saveName()
						break;
					case 'wxlogo':
						this.saveLogo()
						break;
					case 'introduce':
						this.saveIntroduce()
						break;
					default:
						break;
				}

			},
			getclslist() {
				let param = {
					mpid: this.mpid,
				}
				console.log(param, 'getclslist.param');
				wxappApi.wxclslist(param, res => {
					console.log(res, 'getclslist.res');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						let all = []
						let tempfirstid = []
						tempfirstid = res.data.categories_list.categories[0].children
						all = res.data.categories_list
						this.allcls = res.data.categories_list.categories

						// res.data.categories_list.filter(f =>{
						//  return f.level == 1
						// })

						let __rootlevel = res.data.categories_list.categories.filter(f => {
							return f.level == 1
						})
						console.log(all, 'all');
						__rootlevel.forEach(item => {
							let __tmplist = this.__findcls(item)
							if (__tmplist != undefined) {
								this.firstlist.push({
									value: item.id,
									label: item.name,
									clsitem: item,
									children: __tmplist
								})
							} else {
								this.firstlist.push({
									value: item.id,
									label: item.name,
									clsitem: item,
								})
							}

						})
						console.log(this.firstlist, 'this.firstlist444');
						this.clsVisible = true
						return;

						tempfirstid.forEach(item => {
							let tempfirst = all.categories.find(f => {
								return item == f.id
							})

							if (tempfirst != undefined) {



								this.firstlist.push({
									value: tempfirst.id,
									label: tempfirst.name,
									childrenidlist: tempfirst.children,
									children: []
								})


							}

						})
						console.log(this.firstlist, 'firstlist');
						let children = []
						if (this.firstlist != undefined && this.firstlist.length > 0) {
							this.firstlist.forEach(item => {
								item.childrenidlist.forEach(item2 => {
									let tempsecond = all.categories.find(f => {
										return item2 == f.id
									})
									console.log(tempsecond, 'tempsecond');
									if (tempsecond != undefined) {
										item.children.push({
											value: tempsecond.id,
											label: tempsecond.name,
											//childrenidlist: tempsecond.children,
											//children: []
										})

									}

									// item.children.childrenidlist.forEach(item3 =>{


									console.log(this.firstlist, 'firstlist2');
								})
							})
						}
						// this.firstlist.forEach(item => {
						// 	item.children.forEach(item2 => {
						// 			console.log(item2,'item22222')

						// 			item2.childrenidlist.forEach(itemid =>{
						// 				let tempthird = all.categories.find(f => {
						// 					return itemid == f.id
						// 				})
						// 				if (tempthird != undefined) {
						// 					item2.children.push({
						// 						id: tempthird.id,
						// 						name: tempthird.name,
						// 						childrenidlist: tempthird.children,
						// 					})

						// 				}

						// 			})
						// 	})
						// })
						console.log(this.firstlist, 'this.firstlist444');

						this.clsVisible = true
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},

			__findcls(clsitem) {

				if (clsitem == undefined || clsitem.children == undefined || clsitem.children.length <= 0) {
					return undefined
				}

				let templist = []

				let childlist = this.allcls.filter((f) => {
					return f.father == clsitem.id
				})

				if (childlist != undefined && childlist.length > 0) {
					childlist.forEach(item => {
						let childrenlist = this.__findcls(item)

						if (childrenlist != undefined && childrenlist.length > 0) {

							templist.push({
								value: item.id,
								label: item.name,
								children: childrenlist,
								clsitem: item
							})
						} else {
							templist.push({
								value: item.id,
								label: item.name,
								clsitem: item
							})
						}


					})
				}

				// childrenidlist.forEach(item => {
				// 	let tempfirst = allcls.categories.find(f => {
				// 		return item.clsitem == f.father 
				// 	})

				// 	if (tempfirst != undefined) {
				// 		let childrenlist =  this.__findcls(allcls,tempfirst.children)
				// 		if(childrenlist != undefined && childrenlist != null){
				// 			templist.push({
				// 				value: tempfirst.id,
				// 				label: tempfirst.name,
				// 				childrenidlist: tempfirst.children,
				// 				children:childrenlist
				// 			})
				// 		}
				// 		else{
				// 			templist.push({
				// 				value: tempfirst.id,
				// 				label: tempfirst.name
				// 			})
				// 		}
				// 	}
				// })

				return templist.length > 0 ? templist : undefined;
			},


			saveName() {
				let param = {
					license_img: this.editForm.license_img,
					mpid: this.mpid,
					naming_other_img: this.editForm.naming_other_img,
					nickname: this.editForm.nickname,
				}
				console.log(param, 'param');
				wxappApi.wxmcset(param, res => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.editFormVisible = false
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			saveLogo() {
				let param = {
					mpid: this.mpid,
					wxlogo: this.wxlogoForm.wxlogo,
				}
				console.log(param, 'param');
				wxappApi.webwxlogoset(param, res => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.logoVisible = false
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			saveIntroduce() {
				let param = {
					mpid: this.mpid,
					memo: this.wxIntroduceForm.memo,
				}
				console.log(param, 'param');
				wxappApi.webwxmemoset(param, res => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.IntroduceVisible = false
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},
			funUpImg(type) {
				this.imgtype = type
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				switch (this.imgtype) {
					case 'license_img':
						this.editForm.license_img = imgitem.url
						break;
					case 'naming_other_img':
						this.editForm.naming_other_img = imgitem.url
						break;
					case 'wxlogo':
						this.wxlogoForm.wxlogo = imgitem.url
						break;
					default:
						break;
				}
			},
			funprivacy() {
				this.$router.push({
					path: '../privacy/index?mpid=' + this.mpid
				})
			},
			getdomainurllist() {
				webApi.domainlist(this.mpid, res => {
					console.log(res, 'urllist');
					if (res.code == 200) {
						let requestdomain = ''
						res.data.requestdomain.forEach(item => {
							requestdomain += item + ';'
						})

						let tcpdomain = ''
						res.data.tcpdomain.forEach(item => {
							tcpdomain += item + ';'
						})

						let downloaddomain = ''
						res.data.downloaddomain.forEach(item => {
							downloaddomain += item + ';'
						})
						let uploaddomain = ''
						res.data.uploaddomain.forEach(item => {
							uploaddomain += item + ';'
						})
						let udpdomain = ''
						res.data.udpdomain.forEach(item => {
							udpdomain += item + ';'
						})

						let wsrequestdomain = ''
						res.data.udpdomain.forEach(item => {
							wsrequestdomain += item + ';'
						})

						this.olddata = {

							requestdomain: requestdomain,
							downloaddomain: downloaddomain,
							tcpdomain: tcpdomain,
							udpdomain: udpdomain,
							uploaddomain: uploaddomain,
							wsrequestdomain: wsrequestdomain,
						}
						this.iptdata = {
							requestdomain: requestdomain,
							downloaddomain: downloaddomain,
							tcpdomain: tcpdomain,
							udpdomain: udpdomain,
							uploaddomain: uploaddomain,
							wsrequestdomain: wsrequestdomain,
						}

					} else {
						this.$message({
							type: "error",
							message: res.msg
						})
					}
				})
			},

			funsavedomain() {
				let adddata = {
					requestdomain: '',
					downloaddomain: '',
					tcpdomain: '',
					udpdomain: '',
					uploaddomain: '',
					wsrequestdomain: '',

				}


				let deldata = {
					requestdomain: '',
					downloaddomain: '',
					tcpdomain: '',
					udpdomain: '',
					uploaddomain: '',
					wsrequestdomain: '',
				}

				adddata.requestdomain = this.domains_check(this.olddata.requestdomain, this.iptdata.requestdomain, 'add')
				adddata.downloaddomain = this.domains_check(this.olddata.downloaddomain, this.iptdata.downloaddomain,
					'add')
				adddata.tcpdomain = this.domains_check(this.olddata.tcpdomain, this.iptdata.tcpdomain, 'add')
				adddata.udpdomain = this.domains_check(this.olddata.udpdomain, this.iptdata.udpdomain, 'add')
				adddata.uploaddomain = this.domains_check(this.olddata.uploaddomain, this.iptdata.uploaddomain, 'add')
				adddata.wsrequestdomain = this.domains_check(this.olddata.wsrequestdomain, this.iptdata.wsrequestdomain,
					'add')

				deldata.requestdomain = this.domains_check(this.olddata.requestdomain, this.iptdata.requestdomain, 'del')
				deldata.downloaddomain = this.domains_check(this.olddata.downloaddomain, this.iptdata.downloaddomain,
					'del')
				deldata.tcpdomain = this.domains_check(this.olddata.tcpdomain, this.iptdata.tcpdomain, 'del')
				deldata.udpdomain = this.domains_check(this.olddata.udpdomain, this.iptdata.udpdomain, 'del')
				deldata.uploaddomain = this.domains_check(this.olddata.uploaddomain, this.iptdata.uploaddomain, 'del')
				deldata.wsrequestdomain = this.domains_check(this.olddata.wsrequestdomain, this.iptdata.wsrequestdomain,
					'del')

				console.log(this.olddata, 'olddata');
				console.log(this.iptdata, 'iptdata');
				console.log(adddata, 'adddata');
				console.log(deldata, 'deldata');


				this.__funsavedomain(adddata, 'add');
				//this.__funsavedomain(deldata,'del');

			},

			domains_check(d1, d2, t) {
				let domains_old = d1.split(';')
				let domains_input = d2.split(';')

				let domains_new = ''

				if (t == 'add') {
					console.log('domains_check', domains_input)
					domains_input.forEach(item => {

						if (domains_old.find(f => {
								return item == f
							}) == undefined) {
							console.log(item)
							domains_new += item + ';'
						}
					})
				} else {
					domains_old.forEach(item => {

						if (domains_input.find(f => {
								return item == f
							}) == undefined) {
							domains_new += item + ';'
						}
					})
				}
				return domains_new



			},

			__funsavedomain(item, t) {
				let param = {
					mpid: this.mpid,
					action: t,
					requestdomain: encodeURIComponent(item.requestdomain),
					downloaddomain: encodeURIComponent(item.downloaddomain),
					tcpdomain: encodeURIComponent(item.tcpdomain),
					udpdomain: encodeURIComponent(item.udpdomain),
					uploaddomain: encodeURIComponent(item.uploaddomain),
					wsrequestdomain: encodeURIComponent(item.wsrequestdomain),
				}
				console.log(param, 'funsavedomain.param');
				webApi.domainset(param, res => {
					console.log(res, '__funsavedomain.res');
					if (res.data.errcode = 0) {
						this.$message({
							type: "success",
							message: res.msg
						})

					} else {
						this.$message({
							type: "error",
							message: res.msg
						})

					}

				})
			},
			getwebview() {
				webApi.webview(this.mpid, res => {
					console.log(res, 'getwebview.ress')
					if (res.data.errcode == 0) {
						let webviewdomain = ''
						res.data.webviewdomain.forEach(item => {
							webviewdomain += item + ';'
						})
						this.webviewdomain = webviewdomain
						this.webviewolddata = {
							webviewdomain: webviewdomain,
						}
						this.webviewiptdata = {
							webviewdomain: webviewdomain,
						}
						console.log(webviewdomain, 'webviewdomain');
					} else {
						this.$message({
							message: res.errmsg,
							type: 'error'
						})
					}
				})
			},
			getpublishqrcode(){
				let param ={
					mpid:this.mpid,
					prefix:''
				}
				console.log(param,'publishparam');
				webApi.publishqrcode(param, res => {
					console.log(res, 'getpublishqrcode.ress')
					if (res.data.errcode == 0) {
						
					} else {
						this.$message({
							message: res.errmsg,
							type: 'error'
						}) 
					}
				})
			},
			funwebviewdomain() {
				let adddata = {
					webviewdomain: ''

				}
				let deldata = {
					webviewdomain: ''
				}
				adddata.webviewdomain = this.webviewdomains_check(this.webviewolddata.webviewdomain, this.webviewiptdata
					.webviewdomain, 'add')
				deldata.webviewdomain = this.webviewdomains_check(this.webviewolddata.webviewdomain, this.webviewiptdata
					.webviewdomain, 'del')
				console.log(adddata, 'adddata');
				console.log(deldata, 'deldata');
				if (adddata.length > 0) {
					this.__funwebviewdomain(adddata, 'add');
				} else {
					this.__funwebviewdomain(deldata, 'del');
				}

			},
			webviewdomains_check(d1, d2, t) {
				let domains_old = d1.split(';')
				let domains_input = d2.split(';')

				let domains_new = ''

				if (t == 'add') {
					console.log('domains_check', domains_input)
					domains_input.forEach(item => {

						if (domains_old.find(f => {
								return item == f
							}) == undefined) {
							console.log(item)
							domains_new += item + ';'
						}
					})
				} else {
					domains_old.forEach(item => {

						if (domains_input.find(f => {
								return item == f
							}) == undefined) {
							domains_new += item + ';'
						}
					})
				}
				return domains_new



			},
			__funwebviewdomain(item, t) {
				console.log(item, 'item');
				let param = {
					action: t,
					mpid: this.mpid,
					webviewdomain: encodeURIComponent(item.webviewdomain),
				}
				console.log(param, 'funwebviewdomain.param');
				webApi.webviewdomainset(param, res => {
					console.log(res, 'funwebviewdomain.res');
					if (res.data.errcode = 0) {
						this.$message({
							type: "success",
							message: res.msg
						})
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})

					}

				})
			},
			funconfirmfile() {
				webApi.confirmfile(this.mpid, res => {
					console.log(res, 'funconfirmfile.res');
					if (res.data.errcode == 0) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.filename = res.data.file_name
						this.filepath = '/path/to/' + res.data.file_nam
						//this.downloadfile(res.data.file_name)
					} else {
						this.$message({
							type: "error",
							message: res.msg
						})

					}

				})
			},
			downloadfile(filename) {
				console.log(filename, 'filename');
				const fileurl = '/path/to/' + filename
				this.$download(fileurl, filename)
			}
		}
	}
</script>

<style scoped>
	.box-card {
		background-color: #fff;
		border-radius: 4px;
		box-shadow: 0 1px 2px rgb(150 150 150 / 30%);
		padding: 30px 30px;
	}

	.box-card .table {
		color: #9a9a9a;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}

	.box-card .table .tb-mc {
		width: 130px;
	}

	.box-card .table .thead {
		display: flex;
		align-items: center;
	}

	.box-card .table .tbody {
		width: 100%;
		padding: 10px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #f0f0f0;
	}

	.box-card .table .tbody .titem {
		text-align: left;
	}

	.box-card .table .tbody .titem:nth-child(1) {
		color: #353535;
		width: 16.4em;
	}

	.box-card .table .tbody .titem:nth-child(2) {
		width: 22.4em;
	}

	.box-card .table .tbody .titem:nth-child(3) {
		width: 8.4em;
	}

	.box-card .table .tbody .titem img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}

	.box-card .box-item {
		margin: 10px 0;
		display: flex;
		align-items: center;
	}

	.box-card .box-item .title {
		width: 150px;
	}

	.box-card .box-item>>>.el-input--small {
		margin-left: 10px;
		width: 500px;
	}

	.iptmsg {
		color: #737373;
		margin-left: 15px;
		font-size: 10px;
	}
	.page{
		background:#F5F7FA;
	}
	.rightbox{
		padding-top:20px;
		padding-bottom:20px;
		width: 1320px;
		overflow:auto;
	}
	.pagecontent{
		
		
		height: calc(100vh - 81px);
	}
	.ewmtitle{
		width:100%;
	}
</style>