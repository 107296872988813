import { render, staticRenderFns } from "./tempfine.vue?vue&type=template&id=55db8a2b&scoped=true&"
import script from "./tempfine.vue?vue&type=script&lang=js&"
export * from "./tempfine.vue?vue&type=script&lang=js&"
import style0 from "./tempfine.vue?vue&type=style&index=0&id=55db8a2b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55db8a2b",
  null
  
)

export default component.exports