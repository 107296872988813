<template>
	<div class="content">
		<div class="list">
			<div class="item" v-for="(item,index) in list" :key="index" @click="funitem(item)">
				<div class="logo"> <img :src="item.logo" /> </div>
				<div class="title">{{ item.tmpname }}</div>
				<div class="title">{{ item.createDate }}</div>
			</div>
		</div>

	</div>
</template>
<script>
	import designTemplataApi from "../../../api/cus/designtmplate.js";

	import dragBox from "@/components/applet/toolbox/dragbox.vue";
	import getloginuser from '../../../utils/util.js'
	export default {
		components: {
			dragBox
		},
		data() {
			return {
				list: [],
				options1: {
					left: 0,
					top: 0,
					width: 100,
					height: 100
				},
				options2: {
					left: 50,
					top: 50,
					width: 100,
					height: 100
				},
				ispermissions:true
			}
		},

		mounted() {
			let u = getloginuser()
			// let u = sessionStorage.getItem('customeruser')
			if (u != null) {
				this.userInfo = u;
				console.log(this.userInfo, 'this.userInfo');
				if (this.userInfo.permissions != undefined && this.userInfo.permissions[0] == "admin") {
					this.ispermissions = true
				} else {
					this.ispermissions = false
				}
			} else {
				this.$router.push("/");
			}
			this.initdata();
		},
		methods: {
			initdata: function() {
				console.log('initdata')
				let param = {
					pageno:1,
					pagesize:40
				}
				designTemplataApi.getUserMytemplist(param, res => {
					console.log(res, 'designTemplataApi.res1203');
					if (res.code == 200) {
						this.list = res.data
					}
					console.log(res);
				})
			},
		}
	}
</script>
<style lang="less" scoped>
	.content {
		width: 90%;
		min-width: 1200px;
		margin: 0 auto;
	}

	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.item {
		text-align: center;
		box-shadow: 0 2px 6px #ccc;
		padding: 20px;
		margin: 20px 20px 0 0;
		position: relative;

		&:hover {
			box-shadow: 0 2px 6px #2865e0;
			border-radius: 20px;
		}

		.logo {
			width: 200px;
			height: 350px;
			overflow: hidden;

			img {
				width: 100%;
			}
		}
	}
</style>