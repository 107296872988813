<template>
  <div>
    <div class="img">
      <div
        class="imgcont"
        :style="{
          'margin-left': marginpage,
          'margin-right': marginpage,
          'margin-top': margintop,
          'margin-bottom': marginbottom,
          'overflow-y': overflow,
        }"
      >
        <el-image
          style="display: block; flex-shrink: 0"
          :style="{
            width: colWidth,
            height: height,
            'border-radius': borderradius,
            'margin-left': index > 0 ? marginitem : '0px',
          }"
          :src="item.url"
          v-for="(item, index) in list.data"
          :key="index"
          v-show="data.data && data.data.length > 0"
        ></el-image>
      </div>

      <div
        class="empty"
        v-show="data.data.length == 0"
        :style="{
          height: height,
          overflow: 'hidden',
        }"
      >
        <el-empty
          description="此区域放置图片"
          :style="{
            height: height,
          }"
        ></el-empty>
      </div>
      <!-- <el-image style="width: 100%" :src="url"></el-image> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "image_row",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
      list: "",
    };
  },

  mounted() {
    this.list = this.data;
  },
  computed: {
    overflow() {
      return "auto";
    },
    colWidth() {
      if (this.list.options) {
        if (this.list.options.itemwidth && this.list.options.itemwidth > 0) {
          return this.list.options.itemwidth / 2 + "px";
        } else {
          return "100%";
        }
      }
    },

    height() {
      if (this.list.options) {
        if (this.list.options.itemheight && this.list.options.itemheight > 0) {
          return this.list.options.itemheight / 2 + "px";
        }
      }
    },

    margintop() {
      if (this.list.options) {
        if (this.list.options.margintop && this.list.options.margintop > 0)
          return this.list.options.margintop + "px";
      }
    },
    marginbottom() {
      if (this.list.options) {
        if (
          this.list.options.marginbottom &&
          this.list.options.marginbottom > 0
        )
          return this.list.options.marginbottom + "px";
      }
    },
    marginitem() {
      if (this.list.options) {
        if (this.list.options.marginitem && this.list.options.marginitem > 0)
          return this.list.options.marginitem + "px";
      }
    },

    marginpage() {
      if (this.list.options) {
        if (this.list.options.marginpage && this.list.options.marginpage > 0)
          return this.list.options.marginpage + "px";
      }
    },
    borderradius() {
      if (this.list.options) {
        if (
          this.list.options.borderradius &&
          this.list.options.borderradius > 0
        )
          return this.list.options.borderradius + "px";
      }
    },
  },

  methods: {},
};
</script>

<style lang="less" scoped>
.img {
  width: 100%;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
}

.imgcont {
  display: flex;
  flex-direction: row;
}

.empty {
  width: 100%;
  // text-align: center;
  // display: flex;
  // flex-wrap: wrap;
}
</style>