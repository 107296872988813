<template>
	<div class="">
		<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
			<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>微信会员卡</el-breadcrumb-item>
		</el-breadcrumb>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>新增微信会员卡</span>
				<div style="float: right;">
					<el-button type="primary" size="small" @click="funsave" style="margin-right: 10px;">保存</el-button>
					<el-button size="small" @click="goback">返回</el-button>
				</div>

			</div>
			<div class="main">
				<div class="main-left">
					<div class="main-box" :style="{background:editForm.wxcolor}">
						<div class="cont">
							<div class="head">
								<div class="logo" @click.stop="funmclick('logo')">
									<img :src="editForm.logo_url" v-if="editForm.logo_url.length > 0">
								</div>
								<div class="title">
									<div class="name" @click.stop="funmclick('name')">
										{{editForm.brand_name || '卡券商户助手'}}
									</div>
									<div class="op" @click.stop="funmclick('op')">
										{{editForm.title || '微信会员卡体验'}}
									</div>
								</div>
							</div>
							<div class="footer">

							</div>
						</div>
						<div class="bgimg" @click.stop="funmclick('bgimg')">
							<img :src="editForm.background_pic_url" v-if="editForm.background_pic_url.length > 0" />
						</div>
					</div>

					<div class="colorlist mr">
						<div class="wcont" v-for="(item,index) in colorlist"
							:class="selindex == index ? 'sel' : 'normal'" :key="index">
							<div class="wbox" :style="{background:item.val}" @click="funvalclick(item,index)">
							</div>
						</div>
					</div>
				</div>
				<div class="main-right">
					<div class="mr">
						商户号:<el-input type="text" size="small" v-model="editForm.mchno"></el-input>
					</div>
					<div class="mr">
						商户类型:<el-input type="text" size="small" v-model="editForm.mchtype"></el-input>
					</div>
					<div class="mr">
						品牌名称:<el-input type="text" size="small" v-model="editForm.brand_name"></el-input>
					</div>
					<div class="mr">
						电话:<el-input type="text" size="small" v-model="editForm.service_phone"></el-input>
					</div>
					<div class="mr">
						标题:<el-input type="text" size="small" v-model="editForm.title"></el-input>
					</div>

					<div class="mr">
						品牌logo:<el-button size="mini" type="text" @click="funUplogo('logo')">上传</el-button>
					</div>
					<div class="mr">
						会员卡背景图:<el-button size="mini" type="text" @click="funUplogo('bgimg')">上传</el-button><el-button
							size="mini" type="text" @click="funset()">重置</el-button>
					</div>
					<div class="mr">
						特权说明:<el-input type="textarea" size="small" v-model="editForm.prerogative" rows="5"></el-input>
					</div>

					<div class="mr">
						使用须知:<el-input type="textarea" size="small" v-model="editForm.wxdescription"
							rows="5"></el-input>
					</div>
					<div class="mr">
						使用提醒:<el-input type="textarea" size="small" v-model="editForm.notice" rows="5"></el-input>
					</div>
				</div>
			</div>
		</el-card>
		<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='editFormVisible=false'>
			<el-form label-width="150px" ref="editForm" :model="editForm">
				<el-form-item label="品牌名称" prop="brand_name" v-if="title == '品牌名称'">
					<el-input size="small" v-model="editForm.brand_name" auto-complete="off"
						placeholder="请输入品牌名称"></el-input>
				</el-form-item>
				<el-form-item label="标题" prop="title" v-if="title == '标题'">
					<el-input size="small" v-model="editForm.title" auto-complete="off" placeholder="请输入标题"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" type="primary" class="title" @click="submitForm()">确定
				</el-button>
			</div>
		</el-dialog>
		<!-- <selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs> -->
		<uploadImgwx ref="uploadImgwx" @uploadres="uploadres"></uploadImgwx>
	</div>
</template>

<script>
	import uploadImgwx from "@/components/customer/uploadImgwx";
	import cardApi from '../../../../../../api/cus/card.js'
	export default {
		components: {
			uploadImgwx
		},
		data() {
			return {
				title: "",
				mpid: "",
				editForm: {
					background_pic_wx: '',
					background_pic_url: "",
					brand_name: "",
					colorvalue: "",
					id: '',
					logo_wx: '',
					logo_url: "",
					mchno: '',
					mchtype: '',
					mpid: "",
					notice: '',
					prerogative: "",
					service_phone: "",
					title: "",
					wxcolor: "",
					wxdescription: "",
				},
				editFormVisible: false,
				colorlist: [{
						id: 'Color010',
						val: "#55BD47"
					},
					{
						id: 'Color020',
						val: "#10AD61"
					},
					{
						id: 'Color030',
						val: "#35A4DE"
					},
					{
						id: 'Color040',
						val: "#3D78DA"
					},
					{
						id: 'Color050',
						val: "#9058CB"
					},
					{
						id: 'Color060',
						val: "#DE9C33"
					},
					{
						id: 'Color070',
						val: "#EBAC16"
					},
					{
						id: 'Color080',
						val: "#F9861F"
					},
					{
						id: 'Color090',
						val: "#E75735"
					},
					{
						id: 'Color100',
						val: "#D54036"
					},
				],
				imgtype: "",
				selindex: 0
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.editForm.wxcolor = this.colorlist[0].val
			},
			goback() {
				this.$router.go(-1)
			},
			resetitem() {
				this.editForm.colorvalue = ''
			},
			funmclick(zt) {
				console.log(zt, 'zt');
				switch (zt) {
					case 'logo':
						this.title = "logo"
						break;
					case 'bgimg':
						this.title = "背景图"
						break;
					case 'name':
						this.title = "品牌名称"
						this.editFormVisible = true
						break;
					case 'op':
						this.title = "标题"
						this.editFormVisible = true
						break;
					default:
						break;
				}
			},
			funUplogo(zt) {
				let imgInfo = {
					mc: '上传',
					mpid: this.mpid
				}
				this.imgtype = zt
				if (zt == 'logo') {
					this.$refs['uploadImgwx'].funShow(imgInfo)
				} else {
					this.$refs['uploadImgwx'].funShow(imgInfo)
				}
			},
			funset() {
				this.editForm.background_pic_url = ''
				this.editForm.background_pic_wx = ''
			},
			uploadres(param) {
				if (this.imgtype == 'logo') {
					this.editForm.logo_url = param.link_url
					this.editForm.logo_wx = param.media_url
				} else {
					this.editForm.background_pic_url = param.link_url
					this.editForm.background_pic_wx = param.media_url
				}
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				if (this.title == "logo") {
					this.editForm.logo_url = imgitem.url;
				} else {
					this.editForm.background_pic_url = imgitem.url;
				}

			},
			funvalclick(item, index) {
				console.log(item, 'item');
				this.editForm.colorvalue = item.id
				this.editForm.wxcolor = item.val
				this.selindex = index
			},
			submitForm() {
				this.editFormVisible = false
			},
			funsave() {
				let param = {
					id: '',
					mpid: this.mpid,
					background_pic_wx: this.editForm.background_pic_wx,
					background_pic_url: this.editForm.background_pic_url,
					prerogative: this.editForm.prerogative,
					title: this.editForm.title,
					brand_name: this.editForm.brand_name,
					logo_wx: this.editForm.logo_wx,
					logo_url: this.editForm.logo_url,
					wxcolor: this.editForm.wxcolor,
					colorvalue: this.editForm.colorvalue,
					notice: this.editForm.notice,
					wxdescription: this.editForm.wxdescription,
					service_phone: this.editForm.service_phone,
					mchno: this.editForm.mchno,
					mchtype: this.editForm.mchtype,
				}
				console.log(param, 'param');
				cardApi.edit(param, res => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg
						})
						this.$router.go(-1)
					}
				})
			}
		}
	}
</script>

<style scoped>
	.main {
		display: flex;
		height: 650px
	}

	.main .main-left {
		width: 350px;
		height: 100%;
		display: flex;
		align-items: center;
		flex-direction: column;
		overflow-y: auto;
		position: relative;
		background-color: #f0f0f0;
	}

	.main .main-left .main-box {
		position: relative;
		width: 300px;
		top: 10px;
		height: 200px;
		border-radius: 10px;
		border: 1px dashed #ccc;

		margin-bottom: 50px;
	}

	.main .main-left .main-box .cont {
		width: 100%;
		color: #fff;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 999;
	}

	.main .main-left .main-box .head {
		padding-left: 10px;
		padding-top: 10px;
		display: flex;
		align-items: center;
	}

	.main .main-left .main-box .head .title .name {
		font-size: 14pt;
		font-weight: 800;
		width: 150px;
		cursor: pointer;
		border: 1px dashed #ccc;
	}

	.main .main-left .main-box .head .title .op {
		margin-top: 5px;
		cursor: pointer;
		border: 1px dashed #ccc;
	}

	.main .main-left .main-box .head .logo {
		width: 40px;
		height: 40px;
		cursor: pointer;
		border: 1px dashed #ccc;
		margin-right: 10px;
	}

	.main .main-left .main-box .head .logo img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.main .main-left .main-box .head .ewm img {
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}

	.main .main-left .main-box .bgimg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.main .main-left .main-box .bgimg img {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}

	.main .main-right {
		flex-grow: 1;
		padding: 0 20px;
		height: 100%;
		overflow-y: auto;
	}

	.main .main-right .footer {
		margin-top: 20px;
		text-align: right;
	}

	.disp {
		display: flex;
		align-items: center;
	}

	.wbox {
		width: 50px;
		height: 50px;
		cursor: pointer;
		border-radius: 5px;
	}

	.mr {
		margin: 10px 0;
	}

	.sel {
		overflow: hidden;
		border: 2px solid #0055ff;
		border-radius: 5px;
	}

	.normal {
		border: 2px solid #f0f0f0;
	}

	.wcont {
		margin: 3px;
		padding: 5px;
	}

	.colorlist {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}
</style>