<template>
  <!--企业微信群二维码-->
	<div class="container">
		<div class="box">
			<div class="top">
				<div class="img">
					<img src="../../../../assets/image/qiyewx.png"/>
				</div>
				<div class="right">
					<div class="title">
						{{ruleForm.title}}
					</div>
					<div class="memo">
						{{ruleForm.memo}}
					</div>
				</div>
			</div>
			<div class="ewm">
				<img :src="ruleForm.qrurl" />
			</div>
		</div>
	</div>
</template>

<script>
	import groupApi from '../../../../api/cus/group.js'
	import qrcodeApi from '../../../../api/cus/qrcode.js'
	export default {
		data() {
			return {
				mpid:'',
				mxid:'',
				ruleForm:{
					title:"",
					memo:"",
					qrurl:""
				}
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				let mxid = this.$route.query.mxid
				if (mpid != undefined) {
					this.mpid = mpid
					this.mxid = mxid
					this.getdetail()
					this.handleqr()
				} else {
					this.$router.push("/");
				}
			},
			getdetail() {
				let param = {
					mxid: this.mxid,
				}
				groupApi.detail(param, res => {
					console.log(res, 'resdetail');
					if (res.code == 200) {
						this.ruleForm.title = res.data.title
						this.ruleForm.memo = res.data.memo
					}
				})
			},
			handleqr(){
				let param = {
					mxid:this.mxid,
				}
				qrcodeApi.getqr(param,res=>{
					console.log(res,'reshandleqr');
					if(res.code == 200){
						this.ruleForm.qrurl = res.data.qrurl
					}else{
						this.$message({
							type:'error',
							message:res.msg
						})
					}
				})
			},
		}
	}
</script>

<style scoped>
	.container{
		height: 100vh;
		overflow: auto;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f0f0f0;
	}
	.container .box{
		margin: 0 30px;
		width: 90%;
		height: 320px;
		box-shadow:1px 2px 2px 5px #fff;
		border-radius: 10px;
		background-color: #fff;
	}
	.container .box .top{
		padding: 20px 0 0 20px;
		display: flex;
		align-items: center;
	}
	.container .box .top img{
		width: 60px;
		height: 60px;
	}
	.container .box .top .right{
		margin-left: 20px;
		display: flex;
		flex-direction: column;
	}
	.container .box .top .right .title{
		color: #a7a7a7;
		font-size: 14px;
	}
	.container .box .top .right .memo{
		color: #a7a7a7;
		font-size: 12px;
	}
	.container .box  .ewm{
		margin: 0 auto;
		margin-top: 40px;
		width: 150px;
		height: 150px;
	}
	.container .box  .ewm img{
		width: 100%;
		height: 100%;
	}
</style>