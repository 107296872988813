<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="13"></leftnav>
			<div class="rightbox"  >
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>设置</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>企业微信配置</span>
					</div>
					<div class="main">
						<div class="item border" v-if="editForm.isOpen == 0">
								<div class="w200">服务器地址(URL)</div>
								<div class="value">{{editForm.postURL}}</div>
						</div>
						<div class="item border" v-if="editForm.isOpen == 0">
								<div class="w200">令牌(Token)</div>
								<div class="value">{{editForm.token}}</div>
						</div>
						<div class="item " >
								<div class="w200">消息加解密方式</div>
								<div class="value">明文模式</div>
						</div>
					</div>
					
					
				</el-card>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>企业微信开发配置</span>
					</div>
					<div class="main">
						<div class="item">
							<div class="mc">企业id:</div>
							<div class="txt">
								<el-input type="text" size="medium" v-model="editForm.mpgid" />
							</div>
						</div>
						
						<div class="item">
							<div class="mc">应用id:</div>
							<div class="txt">
								<el-input type="text" size="medium" v-model="editForm.appid" />
							</div>
						</div>
						
						<div class="item">
							<div class="mc">应用密钥:</div>
							<div class="txt">
								<el-input type="text" size="medium" v-model="editForm.appsecret" style="width: 500px;"/>
							</div>
						</div>
						
						<div class="item">
							<div class="mc">AES内容加密密钥:</div>
							<div class="txt">
								<el-input type="text" size="medium" v-model="editForm.aeskey" style="width: 500px;"></el-input>
							</div>
						</div>
						<div class="item">
							<div class="mc">通讯录密钥:</div>
							<div class="txt">
								<el-input type="text" size="medium" v-model="editForm.bookSecret" style="width: 500px;"></el-input>
							</div>
						</div>
						<div class="item">
							<div class="mc">联系客户密钥:</div>
							<div class="txt">
								<el-input type="text" size="medium" v-model="editForm.contactSecret" style="width: 500px;"></el-input>
							</div>
						</div>
						
					</div>
					<div class="btnbox">
						<el-button size="medium" type="primary" @click="sumbmitSet()" class="btn" >提交</el-button>
					</div>
				</el-card>
			</div>
		</div>
		
	</div>
</template>

<script>
	import accountApi from "../../../../../../api/cus/account.js"
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				editForm: {
					appid: "",
					mpgid: "",
					mpid: "",
					appsecret: "",
					signtype: "",
					aeskey: "",
					appRsaPubKey: "",
					platRsaPubKey: "",
					appRsaPriKey: "",
					isOpen:0,
					token:'',
					postURL:'',
					bookSecret:"",
					contactSecret:""
				},
				signList: [{
						id: "0",
						mc: 'md5'
					},
					{
						id: "1",
						mc: 'RSA2'
					},
				]
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			if (mpid != undefined) {
				this.mpid = mpid
			} else {
				this.$router.push("/");
			}
			this.init()
		},
		methods: {
			init() {
				accountApi.info({
					mpid: this.mpid
				}, res => {
					console.log(res, 'res.info');
					this.editForm.mpgid = res.data.mpgid
					this.editForm.appid = res.data.appID
					this.editForm.appsecret = res.data.appSecret
					this.editForm.signtype = res.data.signtype
					this.editForm.aeskey = res.data.aesKey
					this.editForm.platRsaPubKey = res.data.platRsaPubKey
					this.editForm.appRsaPubKey = res.data.appRsaPriKey
					this.editForm.appRsaPriKey = res.data.appRsaPubKey
					this.editForm.bookSecret = res.data.bookSecret
					this.editForm.contactSecret = res.data.contactSecret
					this.editForm.token = res.data.token
					this.editForm.isOpen = res.data.isOpen
					this.editForm.postURL = res.data.postURL
				})
			},
			sumbmitSet() {
				let param = {
					"mpid": this.mpid,
					"mpgid": this.editForm.mpgid,
					"appid": this.editForm.appid,
					"appsecret": this.editForm.appsecret,
					"signtype": this.editForm.signtype,
					"aeskey": this.editForm.aeskey,
					"platRsaPubKey": this.editForm.platRsaPubKey,
					"appRsaPubKey": this.editForm.appRsaPubKey,
					"appRsaPriKey": this.editForm.appRsaPriKey,
					"bookSecret": this.editForm.bookSecret,
					"contactSecret": this.editForm.contactSecret,
				}
				console.log(param, 'gzhsecretset.param');
				accountApi.gzhsecretset(param, res => {
					console.log(res, 'gzhsecretset.res');
					if (res.code == 200) {
						this.$message({
							type: 'success',
							message: '设置成功!'
						})
					} else {
						this.$message({
							type: 'error',
							message: '设置失败!'
						})
					}
				})
			}
		}
	}
</script>

<style scoped>
	.page{
		background:#F5F7FA;
		
	}
	.box-card {
		margin-bottom: 10px;
	}

	.main {
		display: flex;
		flex-direction: column;
	}

	.main .item {
		margin: 10px 0;
		display: flex;
		align-items: center;
	}

	.main .item .mc {
		margin-right: 10px;
		width: 150px;
	}
	.main .item {
		margin-bottom: 10px;
		font-weight: 700;
		font-size: 16px;
		padding:18px 0;
		display: flex;
		align-items: center;
	}
	
	.border{
		border-bottom: 1px solid #E2E2E2;
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
		
		margin: 0 auto;
	}
	.pagecontent{
		display: flex;
		overflow: auto;
		height: calc(100vh - 81px);
		
	}
	.w200{
		width:200px;
	}
	.value{
		color:#777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow:1;
	}
	.btnbox{
		width:100%;
		display:flex;
		justify-content: center;
	}
	.btnbox .btn{
	width: 200px;
	margin: 20px auto;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		.main .item .mc {
			margin-right: 10px;
			width: 120px;
			flex-shrink:0;
		}
		
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
			
		}
	}
</style>