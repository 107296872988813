<template>
	<div>
		
		<div class="container">
			<navcon v-if="islogin"></navcon>
			<div class="f-topBar f-fkw-topBar" v-else>
				<div class="fkw-topbar-box">
					<div class="dxlogo">
						<router-link to="/"><img src="../assets/image/logo.png" alt="logo"/></router-link> 
					</div>
					<div class="f-topbar-right">
						<div class="opPanel">
							<div class="topbar-item topbar-item-registerCenter">
								<div class="topbar_btn topbar_btn_registerCenter">
									<i class="topbar_btn_icon_before"></i>
									
									<i class="topbar_btn_icon_after"></i>
								</div>
							</div>
							<div class="topbar-item topbar-item-loginCenter">
								<div class="topbar_btn topbar_btn_loginCenter">
									<i class="topbar_btn_icon_before"></i>
									<router-link class="topbar_btn_text" to="/customer/login">用户登录</router-link>
									<i class="topbar_btn_icon_after"></i>
								</div>
							</div>
							<div class="topbar-item topbar-item-registerCenter designer" >
								<div class="topbar_btn topbar_btn_registerCenter" >
									<i class="topbar_btn_icon_before"></i>
									<router-link class="topbar_btn_text" to="/designer/login" style="background-color: #fff;color: #333;">设计师登录</router-link>
									<i class="topbar_btn_icon_after"></i>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			
			<div class="markpage">
				
				<div class="search">
					<div class="search-wrap">
						<!-- <div class="search_select">
							<select name="all" id="all" class="selstyle" v-model="form.cls1id" @change="funSaleClick($event)">
								<option :value="item.id" v-for="(item,index) in salelist" :key='index'>{{item.mc}}</option>
							</select>
						</div> -->
						<div class="search_input">
							<input type="text" placeholder="酒吧简约大气小程序" v-model="searchkey" />
						</div>
						<div class="search_click" @click="funSearch">
							<img src="../assets/image/market/serch.png" />
						</div>
					</div>
				</div>
				<div class="banner" @click.stop="funjoin">
					<img src="../assets/image/market/join.png" />
				</div>
				<div class="menu">
					<div class="menu-wrap">
						<div class="menu-item" v-for="(item,index) in salehotlist" :key="index"
							@click="funsaleclsclick(item)">
							<p class="title">{{item.mc}}</p>
							<div class="memo">
								Marketing Hot
							</div>
						</div>
					</div>
				</div>
				<div class="pliohyrax">
					<div class="pliohyrax_left">
						<div class="title">
							今日推荐
						</div>
		
						<div class="wrap">
							<div class="item" @click="funmarklist">
								<img src="../assets/image/market/tj1.png" />
								<div class="tmpmsg">推荐 每日上新</div>
							</div>
		
							<div class="item" @click="funtempfine">
								<img src="../assets/image/market/tj2.png" />
								<div class="tmpmsg2">精品 热门下载</div>
							</div>
						</div>
					</div>
					<div class="pliohyrax_right">
						<div class="title">
							节日上新
						</div>
						<div class="wrap">
							<div class="left">
								<div class="item" @click="funmarkethot">
									<p>国庆节</p>
									<p>National</p>
								</div>
								<!-- <div class="img" @click="funmarkethot">
									<img src="../../../assets/image/market/icon_market1.png" />
								</div> -->
							</div>
							<div class="right">
								<div class="item qm">
									<p>清明节</p>
									<p>Valentine’s Day</p>
		
								</div>
								<div class="item ws">
									<p>万圣节</p>
									<p>Valentine’s Day</p>
		
								</div>
								<div class="item sd">
									<p>圣诞节</p>
									<p>Valentine’s Day</p>
		
								</div>
								<div class="item yd">
									<p>元旦节</p>
									<p>Valentine’s Day</p>
		
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="freemail">
					<div class="cls">
						<div class="title">
							免费专区·精美模板限时免费
						</div>
						<div class="cls_wrap">
							<div class="cls_item" :class="currIndex == index ? 'sel' : 'nosel'"
								v-for="(item,index) in hangyehotlist" :key="index" @click="funclsclick(item)">
								<span>{{item.mc}}</span>
							</div>
						</div>
					</div>
					<div class="box">
						<div class="box-wrap">
							<div class="box-item" v-for="(item,index) in listbyfreelist" :key="index"
								>
								<a target="_blank" :href="'/customer/xcxtemplate/marketdetail?tmpid=' + item.tmpid"><img :src="item.logo" /></a>
								
								<div class="box-bg">
									<div class="">
										{{item.tmpname}}
									</div>
								</div>
								<!-- <img class="bgk" src="../../../assets/image/market/bgk.png" /> -->
							</div>
						</div>
					</div>
				</div>
				<div class="freemail">
					<div class="cls">
						<div class="title">
							小程序·高端酒吧必用
						</div>
						<div class="cls_wrap">
							<div class="cls_item" :class="currIndex == index ? 'sel' : 'nosel'"
								v-for="(item,index) in stylehotlist" :key="index" @click="funstyleclsclick(item)">
								<span>{{item.mc}}</span>
							</div>
						</div>
					</div>
					<div class="box">
						<div class="box-wrap">
							<div class="box-item" v-for="(item,index) in listbypaylist" :key="index"
								>
								
								<a target="_blank" :href="'/customer/xcxtemplate/marketdetail?tmpid=' + item.tmpid"><img :src="item.logo" /></a>
								<div class="box-bg">
									<div class="">
										{{item.tmpname}}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div style="color:#000;margin-left: 10px;">
				<router-link  to="/system/login" >L</router-link>
			</div>
			<showload :showload="showload"></showload>
		</div>
		<pagebottom></pagebottom>
	</div>
	
</template>

<script>
	import showload from "@/components/loading";
	import marketApi from '../api/cus/market.js'
	import util from '../utils/util.js'
	import pagebottom from '../components/pagebottom.vue'
	import navcon from '../components/customer/navcon.vue'
	export default {
		components: {
			showload,
			pagebottom,
			navcon
		},
		data() {
			return {
				searchkey: '',
				showload: false,
				isHoliday: false,
				currIndex: 0,
				form: {
					cls1id: ''
				},
				salelist: [],
				salehotlist: [],
				holidayInfo: {},
				hangyehotlist: [],
				salehotlist: [],
				stylehotlist: [],
				listbypaylist: [],
				listbyfreelist: [],
				islogin:false
			}
		},
		mounted() {
			 this.init()
			 let u = util.getloginuser()
			 console.log(u,'uuuu');
			 if(u != null){
				 this.islogin = true
			 }else{
				 this.islogin = false
			 }
			 
			 
		},
		methods: {
			init() {
				document.title = '模版市场-公众号管理平台-大新软件'
				
				this.funIsHoliday()
				this.gethangyehot()
				this.getsalehot()
				this.getstylehot()
				this.getlistbypaylist()
				this.getlistbyfreelist()
				this.getsalelist()
			},
			getsalelist() {
				marketApi.salelist({}, res => {
					console.log(res, 'salelist.res');
					if (res.code == 200) {
						res.data.unshift({
							id: '',
							mc: '全部'
						})
						this.salelist = res.data
					}else if(res.code == 401){
						delloginall()
						this.$router.push({
							path:'/customer/login'
						})
					}else{
						this.$message({
							message:res.msg,
							type:'error'
						})
					}
				})
			},
			gethangyehot() {
				this.showload = true
				marketApi.hangyehot({}, res => {
					console.log(res, 'hangyehot.res');
					if (res.code == 200) {
						this.hangyehotlist = res.data
					}
				})
			},
			getsalehot() {
				marketApi.salehot({}, res => {
					console.log(res, 'salehot.res');
					if (res.code == 200) {
						this.salehotlist = res.data
					}
				})
			},
			getstylehot() {
				marketApi.stylehot({}, res => {
					console.log(res, 'stylehot.res');
					if (res.code == 200) {
						this.stylehotlist = res.data
					}
				})
			},
			getlistbypaylist() {
				marketApi.listbypay({}, res => {
					console.log(res, 'listbypay.res');
					if (res.code == 200) {
						this.listbypaylist = res.data
					}
				})
			},
			getlistbyfreelist() {
				marketApi.listbyfree({}, res => {
					console.log(res, 'listbyfree.res');
					if (res.code == 200) {
						this.showload = false
						this.listbyfreelist = res.data
					}
				})
			},
			funSaleClick(event) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls1id=" + this.form.cls1id,
				});
			},
			funIsHoliday() {
				const currentDate = new Date(); // 获取当前日期
				const month = currentDate.getMonth(); // 月份从0开始计数，所以需要加1
				const day = currentDate.getDay(); // 星期从0到6分别表示周日至周六
				console.log(month, 'month');
				console.log(day, 'day');
				// if (month === 3 && day === 4) { // 判断是否为清明节（四月四号）
				//   this.isHoliday = true;
				// } else if (/* 添加更多的节日判断逻辑 */) {
				//   /* ... */
				// } else {
				//   this.isHoliday = false;
				// }
			},
			funSearch() {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?searchkey=" + this.searchkey,
				});
			},
			funjoin() {
				this.$router.push({
					path: "/designer/login"
				});
			},
			funmarklist() {
				console.log(11,'11');
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls1id=" + '1770732807256776705',
				});
				
			},
			funtempfine() {
				this.$router.push({
					path: "/customer/xcxtemplate/tempfine?cls1id=" + '1770732807256776705',
				})
			},
			funsaleclsclick(item) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls1id=" + item.id,
				});
			},
			funclsclick(item, index) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls3id=" + item.id,
				});
			},
			funstyleclsclick(item, index) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketlist?cls2id=" + item.id,
				});
			},
			fundetail(item) {
				this.$router.push({
					path: "/customer/xcxtemplate/marketdetail?tmpid=" + item.tmpid,
				});
			},

			funmarkethot() {
				return
				this.$router.push({
					path: "/customer/xcxtemplate/markethot",
				});
			}
		}
	}
</script>

<style scoped>
	@import '../assets/css/header.min.css';
	@import '../assets/css/comm.min.css';
	@import '../assets/css/home.min.css';
	@import '../assets/css/fkwComm.min.css';
	.container {
		width: 100%;
		height: auto;
	}

	.markpage {
		margin: 0 auto;
		padding-top: 30px;
		width: 1480px;
	}

	.container .search {
		margin: 0 auto;
		width: 874px;
		height: 44px;
		border-radius: 12px;
		border: 1px solid #3388FF;
		background-color: #fff;
	}

	.container .search .search-wrap {
		display: flex;
	}

	.container .search .search-wrap .search_select {
		width: 95px;
		height: 100%;
		position: relative;
	}

	.container .search .search-wrap .search_select::after {
		content: '';
		display: block;
		height: 100%;
		width: 1px;
		background-color: #D4D4D4;
		position: absolute;
		right: 0;
		top: 0;
	}

	.container .search .search-wrap .search_select .selstyle {
		color: #272727;
		outline: none;
		border: none;
		background: none;
		padding: 12px 15px;
	}

	.container .search .search-wrap .search_input {
		width: 100%;
	}

	.container .search .search-wrap .search_input input {
		padding-left: 10px;
		width: 700px;
		height: 42px;
		outline: none;
		border: none;
		background: none;
	}

	.container .search .search-wrap .search_click {
		cursor: pointer;
		width: 80px;
		height: 44px;
		background: #3388FF;
		border-radius: 0px 11px 11px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.container .search .search-wrap .search_click img {
		width: 25px;
		height: 25px;
	}

	.container .banner {
		margin-top: 38px;
		width: 100%;
		height: 264px;
	}

	.container .banner img {
		width: 100%;
		height: 100%;
	}

	.container .menu {
		margin-top: 38px;
	}

	.container .menu .menu-wrap {
		display: flex;
		justify-content: flex-start;
	}

	.container .menu .menu-wrap .menu-item {
		margin-left: 10px;
		cursor: pointer;
		color: #fff;
		width: 162px;
		height: 80px;
		border-radius: 10px;
		padding: 10px 0 0 10px;
		display: flex;
		flex-direction: column;
		background-color: #3388FF;
	}

	.container .menu .menu-wrap .menu-item .title {
		font-weight: 800;
		font-size: 20px;
	}

	.container .menu .menu-wrap .menu-item .memo {
		margin-top: 20px;
		font-weight: 800;
		font-size: 18px;
		font-family: Source Han Sans CN, Source Han Sans CN;
	}

	.container .menu .menu-wrap .menu-item:nth-child(2) {
		background-color: #FF64D4;
	}

	.container .menu .menu-wrap .menu-item:nth-child(3) {
		background-color: #1FDE60;
	}

	.container .menu .menu-wrap .menu-item:nth-child(4) {
		background-color: #FD5660;
	}

	.container .menu .menu-wrap .menu-item:nth-child(5) {
		background-color: #3388FF;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(5) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(5) .memo {}

	.container .menu .menu-wrap .menu-item:nth-child(6) {
		background-color: #FF64D4;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(6) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(6) .memo {}

	.container .menu .menu-wrap .menu-item:nth-child(7) {
		background-color: #1FDE60;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(7) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(7) .memo {}

	.container .menu .menu-wrap .menu-item:nth-child(8) {
		background-color: #FD5660;
		color: #ffffff;
	}

	.container .menu .menu-wrap .menu-item:nth-child(8) .title {}

	.container .menu .menu-wrap .menu-item:nth-child(8) .memo {}

	.container .pliohyrax {
		margin-top: 38px;
		display: flex;
		justify-content: space-between;
	}

	.container .pliohyrax .pliohyrax_left {
		width: 50%;
		margin-right: 10px;
	}

	.container .pliohyrax .pliohyrax_left .wrap {
		display: flex;
		justify-content: space-between;


	}

	.container .pliohyrax .pliohyrax_left .wrap .item:hover {
		cursor: pointer;
		transition: all .7s;
		transform: scale(1.1);
	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(1) {
		width: 476px;
		height: 206px;
		background-color: #E3E8E2;
		position: relative;

	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(1) img {
		width: 100%;
		height: 100%;
		
	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(2) {
		width: 228px;
		height: 206px;
		background-color: #DAF1E3;
		position: relative;
	}

	.container .pliohyrax .pliohyrax_left .wrap .item:nth-child(2) img {
		width: 100%;
		height: 100%;

	}

	.tmpmsg {
		background-color: #EDEFF0;
		padding-left: 10px;
		line-height: 30px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.tmpmsg2 {
		background-color: #EDEFF0;
		padding-left: 10px;
		line-height: 30px;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
	}

	.container .pliohyrax .pliohyrax_left .title,
	.container .pliohyrax .pliohyrax_right .title {
		font-weight: 800;
		font-size: 18px;
		color: #000000;
		margin-bottom: 10px;
	}

	.container .pliohyrax .pliohyrax_right {
		margin-left: 10px;
		width: 50%;
		position: relative;
	}

	.container .pliohyrax_right .wrap {
		display: flex;
		justify-content: space-between;
	}

	.container .pliohyrax_right .left {
		margin-right: 20px;
		position: relative;
		width: 228px;
		height: 206px;
	}

	.container .pliohyrax_right .left .item {
		width: 228px;
		height: 206px;
		background: url("../assets/image/market/icon_market1.png") ;
		color: #ffffff;
	}

	.container .pliohyrax_right .right {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.container .pliohyrax_right .right .item {
		width: 226px;
		height: 92px;
		color: #000000;

	}

	.container .pliohyrax_right .left .item p {

		color: #000000;
	}

	.container .pliohyrax_right .left .item p:nth-child(1) {
		font-weight: 800;
		font-size: 22px;
		padding: 20px 0 0 20px;
	}

	.container .pliohyrax_right .left .item p:nth-child(2) {
		font-weight: 400;
		font-size: 16px;
		padding: 10px 0 0 20px;
	}

	.container .pliohyrax_right .right .item p {
		padding: 10px 0 0 10px;

	}

	.container .pliohyrax_right .right .item p:nth-child(1) {
		font-weight: 800;
		font-size: 22px;
	}

	.container .pliohyrax_right .right .item p:nth-child(2) {
		font-weight: 400;
		font-size: 16px;
	}

	.qm {
		background: url('../assets/image/market/icon_market5.png') no-repeat;
	}

	.ws {
		background: url('../assets/image/market/icon_market4.png') no-repeat;
	}

	.sd {
		background: url('../assets/image/market/icon_market2.png') no-repeat;
		margin-top: 18px;

	}

	.yd {
		background: url('../assets/image/market/icon_market3.png') no-repeat;
		margin-top: 18px;

	}

	.container .pliohyrax_right .left .item .img,
	.container .pliohyrax_right .right .item .img {}

	.container .pliohyrax_right .right .item .img {
		width: 80px;
		height: 80px;
	}

	.container .pliohyrax_right .right .item .img img {
		width: 100%;
		height: 100%;
	}

	.container .freemail {
		margin-top: 50px;
	}

	.container .freemail .cls {
		display: flex;
	}

	.container .freemail .title {
		font-weight: 800;
		font-size: 18px;
		color: #000000;
	}

	.container .freemail .cls .cls_wrap {
		margin-left: 30px;
		display: flex;
	}

	.container .freemail .cls .cls_wrap .cls_item {
		cursor: pointer;
		margin-left: 10px;
		padding: 0 10px;
		width: 64px;
		height: 24px;
		line-height: 24px;
		text-align: center;
		background: #F3F4F7;
		border-radius: 20px 20px 20px 20px;
	}

	.container .freemail .cls .cls_wrap .cls_item span {
		font-size: 12px;
	}

	.container .freemail .cls .cls_wrap .sel {
		color: #3388FF;
	}

	.container .freemail .cls .cls_wrap .nosel {
		color: #383838;
	}

	.container .freemail .box {
		margin-top: 20px;
	}

	.container .freemail .box .box-wrap {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
	}

	.container .freemail .box .box-wrap .box-item {
		margin-left: 10px;
		margin-bottom: 10px;
		cursor: pointer;
		width: 230px;
		height: 358px;
		background: #F6F7F9;
		border-radius: 10px 10px 10px 10px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: relative;
	}

	.container .freemail .box .box-wrap .box-item img {
		width: 150px;
		height: 305px;
	}

	.container .freemail .box .box-wrap .box-item .bgk {
		position: absolute;
		left: 0;
		top: 0;
	}

	.container .freemail .box .box-wrap .box-item .box-bg {
		overflow: hidden;
		opacity: 0;
		transition: all .3s;
	}

	.container .freemail .box .box-wrap .box-item:hover .box-bg {
		opacity: 1;
		width: 100%;
		height: 45px;
		text-align: center;
		background-color: #fff;
		box-shadow: 0 6px 14px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%);
	}

	/* .container .freemail .box .box-wrap .box-item:hover img {
		transition: transform .3s ease;
		transform: translate(0px, -5px);
	} */

	.container p {
		margin: 0 !important;
	}
	.h100{
		width: 100%;
		height: 100px;
	}
	
</style>








<!-- <template>
  <div class="mainindex">
	<div class="f-topBar f-fkw-topBar">
		<div class="fkw-topbar-box">
			
			<div class="dxlogo">
				<router-link to="/"><img src="../assets/image/logo.png" alt="logo"/></router-link> 
			</div>
			<div class="f-topbar-right">
	
				
	
				<div class="opPanel">
					<div class="topbar-item topbar-item-registerCenter">
						<div class="topbar_btn topbar_btn_registerCenter">
							<i class="topbar_btn_icon_before"></i>
							
							<i class="topbar_btn_icon_after"></i>
						</div>
					</div>
					<div class="topbar-item topbar-item-loginCenter">
						<div class="topbar_btn topbar_btn_loginCenter">
							<i class="topbar_btn_icon_before"></i>
							<router-link class="topbar_btn_text" to="/customer/login">用户登录</router-link>
							<i class="topbar_btn_icon_after"></i>
						</div>
					</div>
					<div class="topbar-item topbar-item-registerCenter designer" >
						<div class="topbar_btn topbar_btn_registerCenter" >
							<i class="topbar_btn_icon_before"></i>
							<router-link class="topbar_btn_text" to="/designer/login" style="background-color: #fff;color: #333;">设计师登录</router-link>
							<i class="topbar_btn_icon_after"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	 -->
	
	
	
	<!-- 
	<div id="main" class="main">
		<div class="banner-container">
			<div class="container clearfix">
				<div class="statistics-con float-l">
					<div class="statistics-title">
						<div>13年专注</div>
						<div>让经营更简单</div>
					</div>
					<div class="statistics-data" data-title="注册用户">
						<div class="statistics-data-num">
							<span class="statistics-num format" >1000000</span>
						</div>
					</div>
					<div class="statistics-data" data-title="安全运行">
						<div class="statistics-data-num">
							<span class="statistics-num" data-num="4729">9999</span>
							<span class="statistics-num-unit">天</span>
						</div>
					</div>
					<div class="statistics-data" data-title="覆盖行业">
						<div class="statistics-data-num">
							<span class="statistics-num" data-num="100">999</span>
							<span class="statistics-num-more">+</span>
						</div>
					</div>
				</div>
				<div class="banner-con float-r">
					<div  class="swiper-container">
						<ul class="swiper-wrapper banner-list">
							<li class="banner-item swiper-slide">
								<a href="#">
									<img src="../assets/image/banner.gif" alt="大新网">
								</a>
							</li>
						</ul>
					</div>
					<div class="banner-footer"></div>
				</div>
			</div>
		</div>
	
		<div class="product-container">
			<div class="container">
				<h1 class="con-title">大新网产品矩阵</h1>
				<div class="con-desc">全经营场景覆盖，快速高效解决经营难题</div>
				<ul class="product-list clearfix">
					<li class="product-item float-r yx">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新客户通</div>
							<div class="product-desc">客户运营专家</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r edu new">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新教育</div>
							<div class="product-desc">一站式招生、运营、管理系统</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r jz">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新建站</div>
							<div class="product-desc">快速建站，效果丰富</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r ktu">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新快图</div>
							<div class="product-desc">简单好用在线制图工具</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r hd">
						<a class="product-card" href="//hd.fkw.com" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新互动</div>
							<div class="product-desc">玩转主流营销活动</div>
						</a>
						<a class="product-link" href="//hd.fkw.com" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r mall">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新商城</div>
							<div class="product-desc">海量模板，轻松搭建线上商城</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r more">
						<div class="product-more-title">更多产品服务</div>
						
						<a class="product-more-btn" href="#" target="blank">免费使用</a>
						
					</li>
					<li class="product-item float-r flyer">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新微传单</div>
							<div class="product-desc">趣味H5，热门微场景营销</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r qz">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新轻站小程序</div>
							<div class="product-desc">3分钟制作专属小程序</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r wxast">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新公众号助手</div>
							<div class="product-desc">微信公众号营销好帮手</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
					<li class="product-item float-r yk">
						<a class="product-card" href="#" target="_blank">
							<i class="product-icon"></i>
							<div class="product-name">大新门店通</div>
							<div class="product-desc">线下门店数字化运营系统</div>
						</a>
						<a class="product-link" href="#" target="_blank">免费使用</a>
					</li>
				</ul>
			</div>
		</div>
	
		<div class="service-container">
			<div class="container">
				<h2 class="con-title">服务保障</h2>
				<ul class="service-list clearfix">
					<li class="service-item float-l">五星售后服务保障</li>
					<li class="service-item float-l">13年沉淀系统稳定</li>
					<li class="service-item float-l">上市企业质量保证</li>
					<li class="service-item float-l">多重认证安全可靠</li>
					<li class="service-item float-l">专利技术品效更佳</li>
				</ul>
			</div>
		</div>
	
		<div class="use-container">
			<div class="container">
				<h2 class="con-title">他们都在用大新</h2>
				<div class="use-con" >
					<div class="use-wp">
						<ul class="use-list clearfix" >
							<li class="use-item float-l"></li>
							<li class="use-item float-l"></li>
							<li class="use-item float-l"></li>
							<li class="use-item float-l"></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
	
	<div class="fkw-footer">
		<div class="fkw-footer-container">
			<div class="fkw-footer-parents">
				<h5 class="fkw-footer-parents-title">合作伙伴<router-link class="topbar_btn_text" to="/system/login">L</router-link></h5>
				
				<div class="fkw-footer-parents-con"></div>
			</div>
			<div class="fkw-footer-copyright">
				<a hidefocus="true" href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">
					<i class="fkw-footer-beian"></i>
					<span>蜀ICP备14021816号</span>
				</a>
				<span class="fkw-footer-spec">|</span>
				<span>Copyright</span>
				<font style="font-family: 微软雅黑;">©</font>
				<span>2010-2023 成都久智大新科技有限公司</span>
				<a hidefocus="true" href="https://beian.miit.gov.cn/" target="_blank" rel="nofollow">蜀ICP备14021816号</a>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
</script>

<style scoped>
	@import '../assets/css/comm.min.css';
	@import '../assets/css/home.min.css';
	@import '../assets/css/fkwComm.min.css';
	@import '../assets/css/header.min.css';
	.designer{
		margin-left: 20px;
	}
</style> -->
