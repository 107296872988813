<template>
	<div class="product-content">
		<p class="tit">商品列表 <span>（可拖动排序）</span></p>
		<div class="boxs">
			<div class="item" v-for="(item,index) in list.data" :key="index">
				<p>商品名称：<el-input v-model="item.mc" size="small" /></p>
				<p>商品图片:<el-input v-model="item.logo" size="small" /></p>
				<div>
					<span @click="fundelsp(index)" class="el-icon-close"></span>
				</div>
			</div>
		</div>
		<el-button class="add-btn" type="primary" @click="toggleSearchPopup"><i class="el-icon-plus"></i>
			添加商品</el-button>
		<template v-if="list.data && list.data.length > 0">
			<vuedraggable v-model="list.data" tag="ul" draggable="li" v-if="list.data && list.data.length > 0"
				class="list">
				<li class="item" v-for="(item, index) in list.data" :key="index">
					<img :src="item.productImg">
					<i class="el-icon-error" @click="deleteItem(index)"></i>
				</li>
			</vuedraggable>
		</template>


		<!--属性设置-->
		<div class="details">
			<div class="list">
				<div class="title">
					<span>模块标题 </span>
				</div>
				<div class="btn2 full">
					<el-input type="text" size="small" style="margin: 0 10px;" maxlength="10" v-model="list.options.mc"
						show-word-limit></el-input>
					<el-checkbox v-model="list.options.showmc">{{list.options.showmc ? '显示中' : '隐藏中'}}</el-checkbox>
				</div>
			</div>
			<div class="list">
				<div class="title">
					<span>副标题标题 </span>
				</div>
				<div class="btn2 full">
					<el-input type="text" size="small" style="margin: 0 10px;" maxlength="10"
						v-model="list.options.memo" show-word-limit></el-input>
					<el-checkbox v-model="list.options.showmemo">{{list.options.showmemo ? '显示中' : '隐藏中'}}</el-checkbox>
				</div>
			</div>
			<!-- 背景颜色 -->
			<div class="list">
				<div class="title"><span>背景颜色</span>{{list.options.bgcolor||'默认' }}<span></span></div>
				<div class="btn">
					<el-button type="text" class="reset" @click="reset('bgcolor')">重置</el-button><el-color-picker
						v-model="list.options.bgcolor" size="medium"></el-color-picker>
				</div>
			</div>
			<!-- 标题文字大小 -->
			<div class="list">
				<div class="title">
					<span>标题大小 </span>
					<span>{{
			              list.options.size == 1
			                ? "大(16号)"
			                : list.options.size == 2
			                ? "中(14号)"
			                : "小(12号)"
			            }}
					</span>
				</div>
				<div class="btn">
					<el-radio-group v-model="list.options.size" @change="size">
						<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
							<el-radio-button :label="1">
								<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
							<el-radio-button :label="2">
								<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
							<el-radio-button :label="3">
								<div style="font-size: 12px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
					</el-radio-group>
				</div>
			</div>

			<!-- 是否文字加粗 -->
			<div class="list">
				<div class="title">
					<span>标题粗细 </span>
					<span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
				</div>
				<div class="btn">
					<el-radio-group v-model="list.options.bold">
						<el-tooltip class="item" effect="dark" content="常规体" placement="bottom">
							<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="加粗体" placement="bottom">
							<el-radio-button :label="2">
								<i class="el-icon-s-operation"></i>
							</el-radio-button>
						</el-tooltip>
					</el-radio-group>
				</div>
			</div>

			<!-- 标题文字颜色 -->
			<div class="list">
				<div class="title"><span>标题颜色</span>{{list.options.titlecolor||'默认' }}<span></span></div>
				<div class="btn">
					<el-button type="text" class="reset" @click="reset('titlecolor')">重置</el-button><el-color-picker
						v-model="list.options.titlecolor" size="medium"></el-color-picker>
				</div>
			</div>

			<!--副标题内容-->
			<!-- 标题文字大小 -->
			<div class="list">
				<div class="title">
					<span>副标题大小 </span>
					<span>{{
				          list.options.descsize == 1
				            ? "大(16号)"
				            : list.options.descsize == 2
				            ? "中(14号)"
				            : "小(12号)"
				        }}
					</span>
				</div>
				<div class="btn">
					<el-radio-group v-model="list.options.descsize" @change="descsize">
						<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
							<el-radio-button :label="1">
								<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
							<el-radio-button :label="2">
								<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
							<el-radio-button :label="3">
								<div style="font-size: 12px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
					</el-radio-group>
				</div>
			</div>

			<!-- 是否文字加粗 -->
			<div class="list">
				<div class="title">
					<span>标题粗细 </span>
					<span> {{ list.options.descbold == 1 ? "常规体" : "加粗体" }} </span>
				</div>
				<div class="btn">
					<el-radio-group v-model="list.options.descbold">
						<el-tooltip class="item" effect="dark" content="常规体" placement="bottom">
							<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="加粗体" placement="bottom">
							<el-radio-button :label="2">
								<i class="el-icon-s-operation"></i>
							</el-radio-button>
						</el-tooltip>
					</el-radio-group>
				</div>
			</div>

			<!-- 标题文字颜色 -->
			<div class="list">
				<div class="title"><span>标题颜色</span>{{list.options.desccolor||'默认' }}<span></span></div>
				<div class="btn">
					<el-button type="text" class="reset" @click="reset('desccolor')">重置</el-button><el-color-picker
						v-model="list.options.desccolor" size="medium"></el-color-picker>
				</div>
			</div>



			<div class="list">
				<div class="title">
					<span>样式 </span>
				</div>
				<div class="box">
					<div class="item" :class="currIndex == index ? 'active':''" v-for="(item,index) in stylelist"
						:key="index" @click="funCurrClick(item,index)">
						<div>{{item.mc}}</div>
					</div>
				</div>
			</div>
			<div class="lists">
				<div class="title">
					<span>选择产品 </span>
				</div>
				<div class="box">
					<el-radio-group v-model="proradio" v-for="(item,index) in prolist" style="margin: 0 10px;">
						<el-radio :label="item.id">{{item.mc}}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="lists">
				<div class="title">
					<span>查看更多 </span>
				</div>
				<div class="box">
					<el-radio-group v-model="moreradio" v-for="(item,index) in morelist" style="margin: 0 10px;">
						<el-radio :label="item.id">{{item.mc}}</el-radio>
					</el-radio-group>
				</div>
			</div>
			<div class="lists">
				<div class="title">
				</div>
				<div class="btn2 ">
					<div class="disp">
						<span>展示产品数</span>
						<div class="add">
							<el-input size="small" type="number" v-model="pronum"
								style="width: 120px;margin: 10px;"></el-input>
						</div>
					</div>
					<div class="disp">
						<span>选择分类</span>
						<div class="">
							<el-select v-model="value" placeholder="请选择" size="small">
								<el-option v-for="item in options" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</div>
					</div>
				</div>
			</div>
			<div class="list">
				<div class="title">
					<span>划线价 </span>
				</div>
				<div class="btn2 full">
					<el-switch v-model="list.options.pricemarket"
						@change="optionsChange('pricemarket', $event)"></el-switch>
				</div>
			</div>
			<div class="list">
				<div class="title">
					<span>价格 </span>
				</div>
				<div class="btn2 full">
					<el-switch v-model="list.options.pricesale"
						@change="optionsChange('pricesale', $event)"></el-switch>
				</div>
			</div>
			<div class="list">
				<div class="title">
					<span>宽度 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.itemwidth" show-input :max="760">
					</el-slider>
				</div>
			</div>

			<div class="list">
				<div class="title">
					<span>高度 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.itemheight" show-input :max="2000">
					</el-slider>
				</div>
			</div>


			<!-- 圆角 -->
			<div class="list">
				<div class="title">
					<span>圆角 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.borderradius" show-input :max="800">
					</el-slider>
				</div>
			</div>

			<div class="list">
				<div class="title">
					<span>上边距 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.margintop" show-input :max="200">
					</el-slider>
				</div>
			</div>
			<div class="list">
				<div class="title">
					<span>下边距 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.marginbottom" show-input :max="200">
					</el-slider>
				</div>
			</div>
			<div class="list">
				<div class="title">
					<span>项目间距 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.marginitem" show-input :max="200">
					</el-slider>
				</div>
			</div>

			<div class="list">
				<div class="title">
					<span>页边距 </span>
				</div>
				<div class="btn2 full">
					<el-slider v-model="list.options.marginpage" show-input :max="200">
					</el-slider>
				</div>
			</div>
		</div>

		<el-dialog title="添加商品" :visible.sync="show" @close="close">
			<el-form label-width="100px">
				<el-form-item label="选择商品">
					<el-select v-model="selectProduct" filterable remote reserve-keyword placeholder="请输入商品名称"
						:remote-method="searchProductList" @change="addProduct" :loading="loading">
						<el-option v-for="item in productList" :key="item.productId" :label="item.productName"
							:value-key="item.productName" :value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="confirm">确定</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

	</div>
</template>

<script>
	// import { searchProduct } from '@/api/pageDecoration.js'
	import vuedraggable from 'vuedraggable'
	export default {
		name: 'shop_list_edit',
		components: {
			vuedraggable
		},
		props: {
			data: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				list: {},
				productList: [],
				loading: false,
				show: false,

				selectItem: null,
				selectProduct: '',
				stylelist: [{
						id: 'row',
						mc: '纵向排列',
						url: ''
					},
					{
						id: 'col',
						mc: '横向排列',
						url: ''
					},
					{
						id: 'list',
						mc: '列表排列',
						url: ''
					},
					{
						id: 'big',
						mc: '大图排列',
						url: ''
					},
				],
				prolist: [{
						id: '0',
						mc: '自动获取'
					},
					{
						id: '1',
						mc: '直接添加'
					},
					{
						id: '2',
						mc: '按分类显示'
					},
				],
				morelist: [{
						id: '1',
						mc: "显示"
					},
					{
						id: '0',
						mc: "隐藏"
					},
				],
				options: [{
					value: '1',
					label: '套餐'
				}, {
					value: '2',
					label: '啤酒'
				}, ],
				value: '',
				pronum: "",
				moreradio: "1",
				proradio: '0',
				currIndex: 0,
				checked: false,
				loadingOption: false
			}
		},
		mounted() {
			this.list = this.data

			if (!this.data.tabType) {
				this.$emit('changeTab', 2)
			}

			// 默认开启所有选项
			for (let key in this.options) {
				if (this.data.options[key] == undefined) {
					this.$set(this.list.options, key, true)
				}
			}
			this.loadingOption = true
		},
		methods: {
			optionsChange(key, result) {
				this.$set(this.list.options, key, result)
			},
			deleteItem(index) {
				this.list.data.splice(index, 1)
			},
			// 搜索商品
			searchProductList(productName) {
				this.productList = productList
			},
			confirm() {
				this.list.data.push(this.selectItem)
				this.close()
			},
			toggleSearchPopup() {
				// this.show = true
				this.list.data.push({
					id: '',
					logo: '',
					mc: '商品名称',
					memo: '副标题',
					pricemarket: 300,
					pricesale: 100,
					priceclub: 80
				})
			},
			fundelsp(index) {
				this.list.data.splice(index, 1)
			},
			close() {
				this.show = false
				this.selectItem = null
				this.selectProduct = ''
			},
			addProduct(data) {
				this.selectItem = data
			},
			funCurrClick(item,index) {
				console.log(item, 'item');
				// this.list.options.selstyle = item.id
				this.currIndex = index
			},
			reset(key){
			  this.$delete(this.list.options,key)
			},
			//  标题字体大小
			size() {
				if (this.list.options.size == 3) {
					return "12px";
				} else if (this.list.options.size == 2) {
					return "14px";
				} else {
					return "16px";
				}
			},
			// 标题加粗
			bold() {
				if (this.list.options.bold == 2) {
					return "900";
				} else {
					return "300";
				}
			},
			//副标题字体大小
			descsize(){
				if (this.list.options.descsize == 3) {
					return "12px";
				} else if (this.list.options.descsize == 2) {
					return "14px";
				} else {
					return "16px";
				}
			},
			// 副标题加粗
			descbold() {
				if (this.list.options.descbold == 2) {
					return "900";
				} else {
					return "300";
				}
			},
		}
	}
	// // 模拟产品列表
	// var productList = [{
	// 	"productId": 3601,
	// 	"productName": "驼大大新疆正宗骆驼奶粉初乳骆驼乳粉蓝罐礼盒装120g*4罐",
	// 	"productImg": "https://img.quanminyanxuan.com/excel/f6860885547648d9996474bbf21fdca9.jpg",
	// 	"productPrice": 299,
	// 	"originalPrice": 598,
	// 	"volumeStr": "741",
	// 	"goodRatio": 98
	// }, {
	// 	"productId": 3268,
	// 	"productName": "百合28件套新骨质瓷餐具",
	// 	"productImg": "https://img.quanminyanxuan.com/excel/185e7365f65543f2b4ebc67036d6a78f.jpg",
	// 	"productPrice": 370,
	// 	"originalPrice": 1388,
	// 	"volumeStr": "400",
	// 	"goodRatio": 99
	// }, {
	// 	"productId": 3343,
	// 	"productName": "和商臻品槐花蜜250克/瓶",
	// 	"productImg": "https://img.quanminyanxuan.com/excel/4626c8c628d04935b0262d04991416b2.jpg",
	// 	"productPrice": 34.5,
	// 	"originalPrice": 72,
	// 	"volumeStr": "258",
	// 	"goodRatio": 98
	// }, {
	// 	"productId": 3330,
	// 	"productName": "鲍参翅肚浓羹350g袋装",
	// 	"productImg": "https://img.quanminyanxuan.com/excel/58a0c968dc7d42c3ac21e09d1862aa6f.jpg",
	// 	"productPrice": 75,
	// 	"originalPrice": 128,
	// 	"volumeStr": "258",
	// 	"goodRatio": 98
	// }, ]
</script>

<style lang="less">
	.product-content {
		.tit {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.add-btn {
			width: calc(100% - 30px);
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin-left: 15px;
			margin-top: 5px;
		}

		.options {
			padding: 15px;
			border-radius: 6px;

			.el-form {
				background: #f7f8f9;
				overflow: hidden;
				padding: 10px 0;

				.el-form-item {
					margin: 0;

					label {
						font-size: 12px;
					}
				}
			}
		}

		.list {
			display: flex;
			align-items: center;
			justify-content: space-between;

			.title {
				margin-right: 10px;
				flex-shrink: 0;
			}

			.full {
				flex-grow: 1;
			}
		}

		.box {
			display: flex;
			align-items: center;

			.item {
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				border: 1px solid #ddd;
			}
		}

	}

	.active {
		border: 1px solid #409EFF !important;
	}

	.lists {
		margin: 20px;
		display: flex;

		.box {
			margin: 0px 10px;
		}
	}

	.disp {
		display: flex;
		align-items: center;
	}
</style>