<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content" v-if="list.options">
		<el-tabs v-model="activeName" type="card">

			<el-tab-pane label="数据" name="first">
				<!-- <p class="desc">添加图片 (最多{{ len }}张，可拖动排序）</p> -->
				<div class="img-cont">
					<vuedraggable v-model="list.data" tag="div" draggable=".item"
						v-if="list.data && list.data.length > 0" class="image-list"
						:class="{ disable: data.tabType == 2 }">
						<div v-for="(item, index) in list.data" :key="index" @click="selitem(item,index)" class="item"
							:class="index === imageIndex? 'sel':'normal'">
							<img :src="item.url" />
							<div>{{ item && item.name }}</div>
							<div class="r-image">
								<span @click="removeImage(index)" class="el-icon-close"></span>
								<div class="image-box">
									<span @click="addImage('url',index)" class="el-icon-edit-outline"></span>
								</div>
							</div>
						</div>
						<div v-if="list.data && list.data.length < len" class="btnadd" 
							@click="addItem()">
							添加
						</div>
					</vuedraggable>




					<div v-if="imageIndex > -1">
						<div class="l-info">
							<div class="" style="margin: 10px 20px;">
								标题：<el-input type="text" size="small" v-model="item.title"
									style="width: 150px;"></el-input>
							</div>
							<p>
								<img :src="item.url" style="width: 50px;height: 50px;" />
							</p>
							<p>
								<span>图片链接：</span>
								<el-input v-model="item.url" size="small" style="width: 200px;"></el-input><el-button
									type="primary" size="small" @click="addImage('url',imageIndex)">上传</el-button>
							</p>
							<p>
								<img :src="item.url2" style="width: 50px;height: 50px;" />
							</p>
							<p>
								<span>选中图片链接：</span>
								<el-input v-model="item.url2" size="small" style="width: 200px;"></el-input><el-button
									type="primary" size="small" @click="addImage('url2',imageIndex)">上传</el-button>
							</p>
							<p style="margin: 20px ;">
								<span>链接：</span>
								<span class="text">{{ item && item.link && item.link.name }}</span>
								<el-button size="small" @click="urlPopup(imageIndex, item.link)">选择链接</el-button>
							</p>
							<p><el-button type="primary" @click="setShow = !setShow" size="small">高级设置</el-button></p>
							<template v-if="setShow">
								<div class="list">
									<div class="title">
										<span>宽高 </span>
									</div>
									<div class="btn2 full">
										<el-slider v-model="item.width" show-input :max="800">
										</el-slider>
										<el-slider v-model="item.height" show-input :max="800">
										</el-slider>
									</div>
								</div>
								<div class="list">
									<div class="title">
										<span>位置 </span>
									</div>
									<div class="btn2 full">
										<el-slider v-model="item.left" show-input :max="800">
										</el-slider>
										<el-slider v-model="item.top" show-input :max="800">
										</el-slider>
									</div>
								</div>

								<div class="list">
									<div class="title">
										<span>文字上边距 </span>
									</div>
									<div class="btn2 full">
										<el-slider v-model="item.margintop" show-input :max="200">
										</el-slider>
									</div>
								</div>
							</template>
						</div>
					</div>
				</div>


				<el-dialog title="请填写图片跳转链接" :visible.sync="show" @close="close">
					<el-form label-width="100px">
						<el-form-item label="跳转链接">
							<el-input v-model="url"></el-input>
						</el-form-item>
						<el-form-item label="">
							<el-button type="primary" @click="confirm">确定</el-button>
						</el-form-item>
					</el-form>
				</el-dialog>
				<!-- {{list}} -->
			</el-tab-pane>
			<el-tab-pane label="背景" name="bg">
				<div class="list">
					<div class="title">
						<span>背景图片 </span>
					</div>
					<div class="btn2 full">
						<el-button size="small" type="primary" @click="addImage('bg',null)">
							选择图片
						</el-button>
					</div>
				</div>
			</el-tab-pane>
			<el-tab-pane label="样式" name="style">
				<!--属性设置-->
				<div class="details">
					<div class="list">
						<div class="title">
							<span>宽高 </span>
						</div>
						<div class="btn2 full"
							v-if="list.options && list.options.width != undefined && list.options.height != undefined">
							<el-slider v-model="list.options.width" show-input :max="800">
							</el-slider>
							<el-slider v-model="list.options.height" show-input :max="800">
							</el-slider>
						</div>
					</div>
					<div class="list">
						<div class="title"><span>自定义背景色</span></div>
						<el-radio-group v-model="list.options.stylestate">
							<el-tooltip class="item" effect="dark" content="风格" placement="bottom">
								<el-radio-button :label="'T'">风格</el-radio-button>
							</el-tooltip>
							<el-tooltip class="item" effect="dark" content="页面" placement="bottom">
								<el-radio-button :label="'F'">页面</el-radio-button>
							</el-tooltip>

						</el-radio-group>
					</div>

					<!-- 背景颜色 -->
					<div class="list" v-if="(list.options.stylestate &&  list.options.stylestate == 'F') ">
						<div class="title"><span>背景颜色</span>{{list.options.bgcolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('bgcolor')">重置</el-button><el-color-picker v-model="list.options.bgcolor"
								size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 标题文字大小 -->
					<div class="list">
						<div class="title">
							<span>标题大小 </span>
							<span>{{
			            list.options.size == 1
			              ? "大(16号)"
			              : list.options.size == 2
			              ? "中(14号)"
			              : "小(12号)"
			          }}
							</span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.size" @change="size">
								<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
									<el-radio-button :label="1">
										<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
									<el-radio-button :label="2">
										<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
									<el-radio-button :label="3">
										<div style="font-size: 12px;width: 11px;height:16px;line-height: 16px;"> A</div>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>

					<!-- 是否文字加粗 -->
					<div class="list">
						<div class="title">
							<span>标题粗细 </span>
							<span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.bold">
								<el-tooltip class="item" effect="dark" content="常规体" placement="bottom">
									<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="加粗体" placement="bottom">
									<el-radio-button :label="2">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div>

					<!-- 标题文字颜色 -->
					<div class="list" v-if="(list.options.stylestate &&  list.options.stylestate == 'F') ">
						<div class="title"><span>标题颜色</span>{{list.options.titlecolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('titlecolor')">重置</el-button><el-color-picker
								v-model="list.options.titlecolor" size="medium"></el-color-picker>
						</div>
					</div>

					<!-- 圆角 -->
					<div class="list wd">
						<div class="title ">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="800">
							</el-slider>
						</div>
					</div>

					<div class="list wd">
						<div class="title ">
							<span>上边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list wd">
						<div class="title ">
							<span>下边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="200">
							</el-slider>
						</div>
					</div>

					<div class="list wd">
						<div class="title ">
							<span>页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginpage" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list wd">
						<div class="title ">
							<span>内上边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.paddingtop" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list wd">
						<div class="title ">
							<span>内下边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.paddingbottom" show-input :max="200">
							</el-slider>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>
		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import vuedraggable from "vuedraggable";
	import sellink from "@/components/designer/sellink"
	import selImgs from "@/components/designer/selImgs"
	export default {
		name: "foot_nav_cube_edit",
		components: {
			sellink,
			selImgs,
			vuedraggable,
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				list: {},
				item: {},
				imgtype: "",
				url: "",
				index: null,
				show: false,
				imageIndex: -1,
				activeName: "first",
				setShow: false
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 5 : 5;
			},
		},
		created() {
			this.list = this.data;
		},
		watch: {
			data() {
				this.list = this.data;
			},
		},
		methods: {
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				this.item = item;
				this.imageIndex = index;
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index, link) {
				this.$refs['sellink'].funShow()
			},
			removeImage(index) {
				this.list.data.splice(index, 1);
			},
			addImage(imgtype, index) {
				this.imgtype = imgtype
				this.imageIndex = index;
				if (this.list.data) {
					if (this.list.data.length < 6) {
						this.$refs['selImgs'].funShow()
					}
				} else {
					this.$message({
						type: 'error',
						message: "最多只能上传5张图片"
					})
				}

			},
			sellinkresult(param) {
				console.log(param, 'param,');
				console.log(this.imageIndex, 'this.imageIndex');
				const index = this.imageIndex;
				if (index !== null) {
					let data = this.list.data[index];
					data.link = param;
					console.log(data, 'data.url')
					this.$set(this.list.data, index, data);
				}
			},
			selImgsresult(imgitem) {
				if (this.imgtype == 'bg') {
					this.list.options.bgimg = imgitem.url;
				} else if (this.item) {
					if (this.imgtype == 'url') {
						this.item.url = imgitem.url
					} else {
						this.item.url2 = imgitem.url
					}
					let data = this.list.data[this.imageIndex];
					//data.url = imgitem.url;
					this.$set(this.list.data, this.imageIndex, data);
				}

			},

			addItem(imgitem) {
				console.log(imgitem, 'imgitem');
				const data = {
					"top": 0,
					"left": 0,
					"url2": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309f945ddbc3a6247ba8c8d0d636d12dbd7.png?key=123456",
					"width": 50,
					"link": {
						"app": {
							"path": "/pages/index/index",
							"name": "菜单",
							"type": "index"
						},
						"name": "站内链接:菜单",
						"type": "app"
					},
					"title": "菜单",
					"margintop": 0,
					"url": "http://minio.file.dxerp.net/common/file/get/dxgzh/file/1692416990172626946/1692416990172626946202309c51d460fd1e24724b607b632db7a43cd.png?key=123456",
					"height": 50
				};

				this.list.data.push(data);
			}

		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: calc(100% - 20px);
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin-left: 10px;
			margin-top: 10px;
		}

		.img-cont {
			display: flex;
			flex-direction: row;
		}
		.img-cont .btnadd{
			color: #fff;
			padding: 5px 10px;
			text-align: center;
			border-radius: 5px;
			background-color: #1b8bff;
			margin: 5px;
		}

		.image-list {
			margin: 0;
			overflow: auto;
			.normal {
				border: 1px solid #ccc;
			}

			.sel {
				border: 1px solid #1b8bff;
			}

			.item {
				width: 20px;
				height: 20px;
				padding: 20px;
				overflow: hidden;
				margin: 5px;
				position: relative;

				&:hover .r-image {
					background: rgba(0, 0, 0, 0.5);
				}

				img {
					width: 100%;
				}

				.r-image {
					text-align: right;
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					z-index: 999;

					.el-icon-close {
						color: #999;
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 6px;
						cursor: pointer;

						&:hover {
							color: red;
						}
					}

					.image-box {
						width: 100%;
						height: 100%;
						border-radius: 5px;
						overflow: hidden;
						position: relative;
						display: flex;
						justify-content: center;
						align-items: center;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							opacity: 0;
							transition: all 0.3s;
						}

						span {
							position: absolute;
							top: 50%;
							left: 50%;
							color: #fff;
							transform: translate(-50%, -50%);
							font-size: 20px;
							cursor: pointer;
							opacity: 0;
							transition: all 0.3s;
						}

						img {
							max-width: 100%;
						}

						&:hover {

							&::before,
							span {
								opacity: 1;
							}
						}
					}
				}
			}

			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			li {
				list-style: none;
				background: #f7f8f9;
				border-radius: 4px;
				padding: 6px 14px 10px;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;

				&.disable div {
					pointer-events: none;
					user-select: none;
				}

				.l-info {
					font-size: 12px;
					padding-top: 8px;
					width: calc(100% - 70px);

					p {
						margin: 12px 0 0;
						white-space: nowrap;
						overflow: hidden;
						display: flex;

						.link {
							color: #1b8bff;
							cursor: pointer;
						}

						.text {
							white-space: nowrap;
							text-align: -webkit-auto;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
			}
		}

		.list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 20px;

			.title {
				margin-right: 10px;
				flex-shrink: 0;
			}

			.full {
				flex-grow: 1;
			}

			.btn {
				display: flex;
				align-items: center;

				.reset {
					margin-right: 10px;
				}
			}

			.flex-row {
				display: flex;
				flex-direction: row;
				align-items: center;
			}
		}

	}

	p {
		margin: 0 20px;
	}

	.wd {
		.title {
			width: 80px;
		}
	}
</style>