<template>
  <div id="text">
    <p>标题内容</p>
    <el-input
      v-model="list.options.name"
      maxlength="10"
      placeholder="请输入标题"
    ></el-input>
    <p>描述内容</p>
    <el-input
      v-model="list.options.label"
      maxlength="500"
      type="textarea"
      placeholder="描述内容，最多500字"
    ></el-input>
    <div style="height: 1px"></div>
    <p class="division"></p>
    <div class="details">
      <!-- 标题文字大小 -->
      <div class="list">
        <div class="title">
          <span>标题大小 </span>
          <span
            >{{
              list.options.size == 1
                ? "大(16号)"
                : list.options.size == 2
                ? "中(14号)"
                : "小(12号)"
            }}
          </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.size" @change="size">
            <el-tooltip
              class="item"
              effect="dark"
              content="大(16号)"
              placement="bottom"
            >
              <el-radio-button :label="1">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="中(14号)"
              placement="bottom"
            >
              <el-radio-button :label="2">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="小(12号)"
              placement="bottom"
            >
              <el-radio-button :label="3">A</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 显示位置 -->
      <div class="list">
        <div class="title">
          <span>显示位置 </span>
          <span> {{ list.options.position == 1 ? "居左显示" : "居中显示" }} </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.position" @change="position">
            <el-tooltip
              class="item"
              effect="dark"
              content="居左显示"
              placement="bottom"
            >
              <el-radio-button :label="1"
                ><i class="el-icon-s-fold"></i
              ></el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="居中显示"
              placement="bottom"
            >
              <el-radio-button :label="2">
                <i class="el-icon-s-operation"></i>
              </el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 描述文字大小 -->
      <div class="list">
        <div class="title">
          <span>描述大小 </span>
          <span
            >{{
              list.options.describe == 1 ? "大(16号)" : list.options.describe == 2 ? "中(14号)" : "小(12号)"
            }}
          </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.describe">
            <el-tooltip
              class="item"
              effect="dark"
              content="大(16号)"
              placement="bottom"
            >
              <el-radio-button :label="1">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="中(14号)"
              placement="bottom"
            >
              <el-radio-button :label="2">A</el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="小(12号)"
              placement="bottom"
            >
              <el-radio-button :label="3">A</el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 是否文字加粗 -->
      <div class="list">
        <div class="title">
          <span>标题粗细 </span>
          <span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.bold">
            <el-tooltip
              class="item"
              effect="dark"
              content="常规体"
              placement="bottom"
            >
              <el-radio-button :label="1"
                ><i class="el-icon-s-fold"></i
              ></el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="加粗体"
              placement="bottom"
            >
              <el-radio-button :label="2">
                <i class="el-icon-s-operation"></i>
              </el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      <!-- 标题文字颜色 -->
      <div class="list">
        <div class="title"><span>标题颜色</span>{{list.options.titlecolor||'默认' }}<span></span></div>
        <div class="btn">
          <el-button type="text" class="reset" @click="reset('titlecolor')">重置</el-button
          ><el-color-picker v-model="list.options.titlecolor" size="medium"></el-color-picker>
        </div>
      </div>
      <!-- 描述文字颜色 -->
      <div class="list">
        <div class="title"><span>描述颜色</span>{{ list.options.describecolor||'默认' }}<span></span></div>
        <div class="btn">
          <el-button type="text" class="reset" @click="reset('describecolor')">重置</el-button
          ><el-color-picker v-model="list.options.describecolor" size="medium"></el-color-picker>
        </div>
      </div>
      <!-- 背景颜色 -->
      <div class="list">
        <div class="title"><span>背景颜色</span>{{ list.options.background||'默认' }}<span></span></div>
        <div class="btn">
          <el-button type="text" class="reset" @click="reset('background')">重置</el-button
          ><el-color-picker v-model="list.options.background" size="medium"></el-color-picker>
        </div>
      </div>
      <!-- 底部分割线 -->
      <div class="list">
        <div class="title">
          <span>底部分割线</span>{{ list.options.border ? "显示" : "不显示" }}<span></span>
        </div>
        <div class="btn">
          <el-switch
            v-model="list.options.border"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "h-text",
  props: {
    data: {
      type: Object,
      default: () => {},
      /**
       * [{"type":"info","title":"页面标题"},{"data":[],"options":{},"type":"text"},{"data":[],"options":{},"type":"notice"}
       * 
       */
    },
  },

  data() {
    return {
      list: {},
    };
  },

  created() {
    this.list = this.data;
  },

  methods: {
    //  显示的位置
    position(event) {
      // this.$set(this.list.options.position, position, event);
      this.list.options.position = event;
    },
    reset(key){
      this.$delete(this.list.options,key)
    },
    // 字体大小
    size() {},
  },
};
</script>

<style lang="less" scoped>
#text {
  padding: 20px;
  p {
    padding: 10px 0;
  }
}
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.title {
  font-size: 14px;

  :first-child {
    padding-right: 10px;

    color: #969799;
  }
}
.btn {
  display: flex;
  align-items: center;
  :first-child {
  }
  .reset {
    margin-right: 10px;
  }
}
</style>