<template>
	<div class="content">
		<div class="title">提交审核</div>
		<div class="contbox">
			<div class="memo">你正在提交开发版 2.7.48。所填信息只用于审核，不会展示给用户。</div>
			<div class="iptitem">
				<div class="ward">版本描述</div>
				<el-input :rows="10" type="textarea" v-model="submitform.version_desc" placeholder="请输入内容"></el-input>
			</div>
			<div class="iptitem">
				<div class="ward">
					<div>图片预览</div>
					<div style="text-align: center;">(选填)</div>
				</div>
				<div>
					<el-upload :action="uploadurl" ref="upload"  :headers="headers" list-type="picture-card"
						:on-preview="handlePictureCardPreview" :on-success="successfile" :data="importData"
					:on-error="handleError"  :on-remove="handleRemove">
						<i class="el-icon-plus"></i>
					</el-upload>
					<el-dialog :visible.sync="dialogVisible">
						<img width="100%" :src="dialogImageUrl" alt="">
					</el-dialog>

				</div>
			</div>
			<div class="iptitem">
				<div class="ward">
					<div>视频预览</div>
					<div style="text-align: center;">(选填)</div>
				</div>
				<el-upload class="video-uploader" action="https://your-upload-api" :on-success="handleSuccess"
					:on-error="handleError" :before-upload="beforeUpload" accept="video/*">
					<el-button slot="trigger" size="small" type="primary">选择视频</el-button>
					<div slot="tip" class="el-upload__tip">只能上传视频文件</div>
				</el-upload>
			</div>
			<div class="iptitem">
				<div class="ward">
					<div>小程序订单中心path</div>
					<div style="text-align: center;">(选填)</div>
				</div>
				<el-input style="width: 430px;" v-model="submitform.order_path" size="medium"
					placeholder="请输入小程序订单中心path设置"></el-input>
			</div>
			<div class="iptitem">
				<div class="ward">
					<div>用户隐私保护指引设置</div>
				</div>
				<div>
					<el-radio v-model="isprivacy" label="1">采集用户隐私</el-radio>
					<el-radio v-model="isprivacy" label="2">未采集用户隐私</el-radio>
				</div>
				<div style="color: #00aaff;margin-left: 20px;" @click="funrefusepriv">点击更新</div>
				<a target="_blank" href="https://developers.weixin.qq.com/miniprogram/dev/framework/user-privacy/"
					style="color: #00aaff;flex-grow: 1;text-align: right;">
					查看详情
				</a>
			</div>
		</div>
		<div class="btnbox">

			<el-button style="width: 140px;" type="primary" size="medium" @click="funsubmit">提交审核</el-button>
		</div>
	</div>
</template>

<script>
	import accountApi from '../../../../../../api/cus/account'
	import {getloginuser} from '../../../../../../utils/util.js'
	export default {
		data() {
			return {
				mpid: "",
				list: [],
				historylist: [],
				isprivacy: '1',
				dialogImageUrl: '', 
				dialogVisible: false,
				headers: {
					Authorization: ''
					
				},
				uploadurl:'',
				importData:{
					mpid: '',
					type: ''
				},
				submitform: {
					"feedback_info": "",
					"feedback_stuff": "",

					"order_path": "",
					"preview": {
						"pic_id_list": [],
						"video_id_list": []
					},
					"privacy_api_not_use": true,
					"ugc_declare": {
						"audit_desc": "",
						"has_audit_team": 0,
						"method": [],
						"other_scene_desc": "",
						"scene": []
					},
					"version_desc": ""
				}
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			let cusinfo = getloginuser()
			// let cusinfo = sessionStorage.getItem("customeruser");
			// cusinfo = JSON.parse(cusinfo)
			this.headers.Authorization = 'Bearer ' + cusinfo.token
			console.log(this.headers,'this.headers');
			this.uploadurl = '/api/customer/wxapp/open/weixin/app/coder/uploadmediatocodeaudit'
			if (mpid != undefined) {} else {
				this.$router.push("/");
			}
			this.importData = {
				mpid:this.mpid,
				type:'image'
			}

		},
		methods: {
			funrefusepriv() {
				this.$router.push({
					path: '../privacy/index?mpid=' + this.mpid
				})
			},
			
			funsubmit() {
				let _this = this
				let param = {
					"feedback_info": "",
					"feedback_stuff": "",
					"order_path": _this.submitform.order_path,
					"preview": {
						"pic_id_list": _this.submitform.preview.pic_id_list,
						"video_id_list": _this.submitform.preview.video_id_list
					},
					"mpid": _this.mpid,
					"privacy_api_not_use": _this.isprivacy == 1 ? true : false,
					"ugc_declare": {
						"audit_desc": "",
						"has_audit_team": 0,
						"method": [],
						"other_scene_desc": "",
						"scene": []
					},
					"version_desc": _this.submitform.version_desc
				}
				console.log(param,'param');
				accountApi.audit_submit(param,res =>{
					console.log(res,'submit.res');
					if(res.data.errcode == 0){
						// auditid
						this.$message({
							type: 'success',
							message: "提交成功"
						})
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			handleRemove(file, fileList) {
				console.log(file, fileList);
			},
			handlePictureCardPreview(file) {
				console.log(file, 'file');
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
				
			},

			successfile(response, file, fileList){
				console.log('response.Vsuccessfile:', response);
				console.log('file:', file);
				if (response.code == 200) {
					if(response.data.errcode == 0){
						this.submitform.preview.pic_id_list.push(response.data.mediaid)
						this.$message({
							type: 'success',
							message: "上传成功"
						})
						// 提交保存成功后清空已上传的文件
						// this.$refs.upload.clearFiles();
					}
				} else {
					this.$message({
						type: 'error',
						message: res.msg + "上传失败"
					})
				}
			},
			handleSuccess(response, file, fileList) {
				console.log('Video uploaded successfully:', response);
			},
			handleError(err, file, fileList) {
				console.log('Error uploading video:', err,);
				console.log(file,'file');
			},
			beforeUpload(file) {
				const isVideo = file.type === 'video/mp4' || file.type === 'video/ogg';
				const isLt500MB = file.size / 1024 / 1024 < 500;

				if (!isVideo) {
					this.$message.error('请上传视频格式文件!');
				}
				if (!isLt500MB) {
					this.$message.error('上传视频大小不能超过 500MB!');
				}
				return isVideo && isLt500MB;
			}
		}
	}
</script>

<style scoped>
	.content {
		width: 100%;
		height: 100vh;
		background-color: #F8F9FB;
		overflow: auto;
	}

	.content .title {
		font-size: 16px;
		font-weight: 700;
		padding: 20px;
	}

	.content .contbox {
		width: 80%;
		margin: 0 auto;
	}

	.content .iptitem {
		display: flex;
		margin-top: 40px;
	}

	.content .iptitem .ward {
		flex-shrink: 0;
		margin-right: 20px;
	}

	.row {
		display: flex;
		align-items: center;
	}

	.btnbox {
		width: 100%;
		display: flex;
		justify-content: center;
		margin-top: 100px;

	}
	.video-uploader .el-upload__tip {
	  margin-left: 10px;
	}
</style>