<template>
	<div class="">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="18"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>微信会员卡</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>微信会员卡</span>
						<span><el-button type="text" style="float: right;" @click="funAddCard">新增</el-button></span>
					</div>
					<div class="main">
						<div class="main-left"> 
							<div class="main-box" :style="{background:item.wxcolor}" v-for="(item,index) in cardlist" :key="index" @click="fundetail(item)">
								<div class="cont" >
									<div class="head">
										<div class="logo" >
											<img :src="item.logo_url">
										</div>
										<div class="title">
											<div class="name" >
												{{item.brand_name || '卡券商户助手'}}
											</div>
											<div class="op">
												{{item.title || '微信会员卡体验'}}
											</div>
										</div>
									</div>
									<div class="footer">
				
									</div>
								</div>
								<div class="bgimg" v-if="item.background_pic_url.length > 0">
									<img :src="item.background_pic_url" />
								</div>
							</div>
						</div>
					</div>
				</el-card>
				</div>
				</div>
	
	</div>
</template>

<script>
	import cardApi from '../../../../../../api/cus/card.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixingzh/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				title: "",
				mpid: "",
				cardlist: [],
				editForm: {},
				editFormVisible: false
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				let param = {
					mpid: this.mpid
				}
				cardApi.list(param, res => {
					console.log(res, 'res.list');
					if (res.code == 200) {
						this.cardlist = res.data
					}
				})
			},
			fundetail(item){
				this.$router.push({
					path:"/customer/home/mp/weixingzh/card/detail",
					query:{
						id:item.id,
						mpid:this.mpid
					}
				})
			},
			funAddCard(){
				this.$router.push({
					path:"/customer/home/mp/weixingzh/card/addcard",
					query:{
						mpid:this.mpid
					}
				})
			}
		}
	}
</script>

<style scoped>
	.main {
		display: flex;
		height: 600px;
		overflow-y: auto;
	}

	.main .main-left {
		height: 100%;
		display: flex;
		flex-direction: row;
		overflow-y: auto;
		position: relative;
	}

	.main .main-left .main-box {
		margin: 10px;
		width: 280px;
		overflow: hidden;
		height: 200px;
		border-radius: 10px;
		cursor: pointer;
		border: 1px dashed #ccc;
		position: relative;
	}

	.main .main-left .main-box .cont {
		width: 100%;
		color: #fff;
		position: absolute;
		left: 0;
		top: 0;
		z-index: 999;
	}

	.main .main-left .main-box .head {
		padding-left: 10px;
		padding-top: 10px;
		display: flex;
		align-items: center;
	}

	.main .main-left .main-box .head .title .name {
		font-size: 14pt;
		font-weight: 800;
		width: 150px;
		cursor: pointer;
	}

	.main .main-left .main-box .head .title .op {
		margin-top: 5px;
		cursor: pointer;
	}

	.main .main-left .main-box .head .logo {
		width: 40px;
		height: 40px;
		cursor: pointer;
		margin-right: 10px;
	}

	.main .main-left .main-box .head .logo img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.main .main-left .main-box .head .ewm img {
		width: 100%;
		height: 100%;
		border-radius: 5px;
	}

	.main .main-left .main-box .bgimg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	.main .main-left .main-box .bgimg img {
		width: 100%;
		height: 100%;
		border-radius: 10px;
	}

	.main .main-right {
		flex-grow: 1;
		padding: 0 20px;
		height: 100%;
	}

	.main .main-right .footer {
		margin-top: 20px;
		text-align: right;
	}
	.pagecontent {
		display: flex;
	
		height: calc(100vh - 81px);
	}
	
	.w200 {
		width: 200px;
	}
	
	.value {
		color: #777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow: 1;
	}
	.btnbox{
		width:100%;
		display:flex;
		justify-content: center;
	} 
	.btnbox .btn{
	width: 200px;
	margin: 20px auto;
	}
	.page {
		background: #F5F7FA;
	}
	
	.border {
		border-bottom: 1px solid #E2E2E2;
	}
	
	.rightbox {
		padding-top: 20px;
		width: 1320px;
	}
</style>