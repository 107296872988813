/**
* 系统管理 用户管理
*/
<template>
	<div>
		<!-- 面包屑导航 -->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>用户管理</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 搜索筛选 -->
		<el-form :inline="true" class="user-search">
			<el-form-item>
				<el-button size="small" type="primary" icon="el-icon-search" @click="search">搜索</el-button>
				<el-button size="small" type="primary" icon="el-icon-plus" @click="handleEdit()">添加</el-button>
			</el-form-item>
		</el-form>
		<!--列表-->
		<el-table size="small" :data="userData" highlight-current-row v-loading="loading" border
			element-loading-text="拼命加载中" style="width: 100%;">
			<el-table-column align="center" prop="nickName" label="姓名" width="120">
			</el-table-column>
			<el-table-column align="center" prop="loginCode" label="账号" width="120">
			</el-table-column>
			<el-table-column align="center" prop="wxwork_userid" label="企微账号" width="120">
			</el-table-column>

			<el-table-column align="center" prop="isuse" label="状态" min-width="100">
				<template slot-scope="scope">
					<div class="userstate1" v-if="scope.row.isuse==1">
						启用
					</div>
					<div class="userstate0" v-else>
						停用
					</div>
				</template>
			</el-table-column>
			<el-table-column label="操作" min-width="200">
				<template slot-scope="scope">
					<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">编辑</el-button>
					<el-button size="mini" type="danger" @click="deleteUser(scope.$index, scope.row)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>
		<!-- 分页组件 -->
		<!-- 编辑界面 -->
		<el-dialog :title="title" :visible.sync="editFormVisible" width="30%" @click='closeDialog("edit")'>
			<el-form label-width="80px" ref="editForm" :model="editForm" :rules="rules">
				<el-form-item label="用户名" prop="uname">
					<el-input size="medium" v-model="editForm.nickname" auto-complete="off"
						placeholder="请输入用户名"></el-input>
				</el-form-item>
				<el-form-item label="企微服务商用户账号" prop="ucode">
					<el-input size="medium" v-model="editForm.wxwork_userid" auto-complete="off"
						placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="账号" prop="ucode">
					<el-input size="medium" v-model="editForm.logincode" auto-complete="off"
						placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="密码" prop="ucode">
					<el-input size="medium" v-model="editForm.loginpwd" type="password" auto-complete="off"
						placeholder="请输入账号"></el-input>
				</el-form-item>
				<el-form-item label="状态" prop="state">
					<el-switch v-model="editForm.isuse==1?nshow:fshow" active-color="#13ce66" inactive-color="#ff4949"
						@change="editType($event)">
					</el-switch>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="medium" @click='closeDialog("edit")'>取消</el-button>
				<el-button size="medium" type="primary" :loading="loading" class="title"
					@click="submitForm('editForm')">保存
				</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	// 导入请求方法


	import userApi from '../../../api/sys/user.js'
	export default {
		data() {
			return {
				nshow: true, //switch开启
				fshow: false, //switch关闭
				loading: false, //是显示加载
				title: '添加用户',
				editFormVisible: false, //控制编辑页面显示与隐藏
				dataAccessshow: false, //控制数据权限显示与隐藏
				unitAccessshow: false, //控制所属单位隐藏与显示
				userInfo: {},
				// 编辑与添加
				editForm: {
					isuse: 0,
					logincode: "",
					loginpwd: "",
					menulist: {},
					nickname: "",
					uid: "",
					wxwork_userid: ""
				},
				tooldepData: [],
				tooljobData: [],
				// 选择数据
				selectdata: [],
				// rules表单验证
				rules: {
					userName: [{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}],
				},

				//用户数据
				userData: [],
				// 数据权限
				UserDept: [],
				defaultProps: {
					children: 'children',
					label: 'name'
				},
				// 选中
				checkmenu: [],
				//参数role
				saveroleId: '',
				// 分页参数
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 10
				}
			}
		},

		created() {
			this.getdata()
		},
		/**
		 * 里面的方法只有被调用才会执行
		 */
		methods: {
			// 获取数据方法
			getdata(page) {
				this.loading = true
				// 请求数据开始
				userApi.list(res => {
					console.log(res, 'user.res');
					this.loading = false
					if (res.code == 200) {
						this.userData = res.data
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			editType(bool) {
				if (bool) {
					this.editForm.isuse = 1
				} else {
					this.editForm.isuse = 0
				}
			},


			//搜索事件
			search() {
				this.getdata()
			},
			//显示编辑界面
			handleEdit: function(index, row) {
				console.log(row, 'rowrow');
				this.editFormVisible = true
				this.editForm = {
					isuse: 0,
					logincode: "",
					loginpwd: "",
					menulist: {},
					nickname: "",
					uid: "",
					wxwork_userid: ""
				}
				if (row != undefined && row != 'undefined') {
					this.title = '修改用户'
					// 请求数据开始
					let param = {
						uid: row.uid
					}
					userApi.detail(param, res => {
						console.log(res, 'user.info.res');
						if (res.code == 200) {
							this.editForm.isuse = res.data.isuse,
								this.editForm.logincode = res.data.loginCode
							this.editForm.loginpwd = res.data.loginPwd
							this.editForm.menulist = res.data.menulist
							this.editForm.nickname = res.data.nickName
							this.editForm.uid = res.data.uid
							this.editForm.wxwork_userid = res.data.wxwork_userid
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})

				} else {
					this.title = '添加用户'
				}
			},
			// 编辑、添加提交方法
			submitForm(editData) {
				this.$refs[editData].validate(valid => {
					if (valid) {
						// 请求方法
						let params = {
							isuse: this.editForm.isuse,
							logincode: this.editForm.logincode,
							loginpwd: this.editForm.loginpwd,
							menulist: this.editForm.menulist,
							nickname: this.editForm.nickname,
							uid: this.editForm.uid,
							wxwork_userid: this.editForm.wxwork_userid
						}

						console.log(params, 'params');
						userApi.edit(params, res => {
							if (res.code == 200) {
								this.loading = false
								this.editFormVisible = false
								this.$message({
									type: 'success',
									message: res.msg
								})
								this.getdata()
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						})
					} else {
						return false
					}
				})
			},
			// 关闭编辑、增加弹出框
			closeDialog(dialog) {
				if (dialog == 'edit') {
					this.editFormVisible = false
				} else if (dialog == 'perm') {
					this.dataAccessshow = false
				} else if (dialog == 'unit') {
					this.unitAccessshow = false
				}
			},
			// 删除用户
			deleteUser(index, row) {
				console.log(row, 'rows');
				this.$confirm('确定要删除吗?', '信息', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					})
					.then(() => {
						// 删除
						userApi.del(row.uid, res => {
							if (res.code == 200) {
								this.loading = false
								this.$message({
									type: 'success',
									message: '数据已删除!'
								})
							} else {
								this.$message({
									type: 'error',
									message: res.msg
								})
							}
						})
					})
					.catch(() => {
						this.$message({
							type: 'info',
							message: '已取消删除！'
						})
					})
			},
		}
	}
</script>

<style scoped>
	.user-search {
		margin-top: 20px;
	}

	.userRole {
		width: 100%;
	}

	.userstate0 {
		color: #ff0000;
	}

	.userstate1 {
		color: #00aa00;
	}
</style>