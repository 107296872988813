<template>
  <div>
    <div id="notice" :style="{background:list.options.background,}">
      <div :style="{color:list.options.textcolor}">
        <i class="el-icon-s-comment"
          >{{list.options.notice||"这是一段公告这是一段公告这是一段公告这是一段公告这是一段公告" }}</i
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "小程序模块话Notice",
props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
   created() {
    this.list = this.data;
  },
  data() {
    return {
      list: {},

    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="less" scoped>
#notice {
  background-color: #fff;
  div {
    margin: 0px 20px;
    padding: 10px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>