<template>
  <!-- 轮播图 -->
  <div class="swiper">
    <!-- 空白状态 -->
    <el-empty
      :image-size="100"
      height="150px"
      description="点击编辑轮播图"
      v-if="data.data.length == 0"
    ></el-empty>
    <!-- 轮播图 -->
    <el-carousel
     class="contain"
      v-else
    >
      <el-carousel-item v-for="(item, index) in data.data" :key="index">
        <el-image :fit="contain"  :style="{ paddingLeft:  list.options.margins + 'px',paddingRight:  list.options.margins + 'px', borderRadius:chamfer }" :src="item.url"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "Product",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: "",
    };
  },

  created() {
    this.list = this.data;
  },
  computed:{
   chamfer(){
     if(this.list.options.chamfer==2){
       return "20px"
     }else{
       return "0px"
     }
   }
 },
  methods: {},
};
</script>

<style lang="less" scoped>
.img {

}
.contain{
  width: 100%;
}
</style>