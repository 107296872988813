/**
* 系统管理 用户管理
*/
<template>
	<div>
		<!-- 面包屑导航 -->
		<el-breadcrumb separator-class="el-icon-arrow-right">
			<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
			<el-breadcrumb-item>日志查询</el-breadcrumb-item>
		</el-breadcrumb>
		<!-- 搜索筛选 -->
		<el-form :inline="true" class="user-search">
			<div class="" style="display: flex;justify-content: left;">
				<el-input type="text" placeholder="请输入配置编号" style="width:200px;margin-right: 20px;" v-model="formInline.mpid" size="small"
					class="mr"></el-input>
				<el-button size="small" type="primary" @click.stop="search">搜索</el-button>
			</div>
		</el-form>
		<!--列表-->
		<el-table size="small" :data="list" highlight-current-row v-loading="loading" border
			element-loading-text="拼命加载中" style="width: 100%;">
			<el-table-column align="center" prop="logid" label="日志编号" width="190">
			</el-table-column>
			<el-table-column align="center" prop="mpid" label="配置编号" width="190">
			</el-table-column>
			<el-table-column align="center" prop="fun" label="模块" width="160">
			</el-table-column>
			<el-table-column align="center" prop="err" label="是否错误日志" width="80">
				<template slot-scope="scope">
					<div :class="'err' + scope.row.err">
						<span v-if="scope.row.err == 0">错误日志</span>
						<span v-else>正常日志</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="msg" label="日志内容" width="720">
			</el-table-column>
			<el-table-column align="center" prop="createDate" label="创建日期" width="180">
			</el-table-column>
			
		</el-table>
		<!-- 分页组件 -->
		<!-- 编辑界面 -->
		<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
	</div>
</template>

<script>
	// 导入请求方法
	import Pagination from '@/components/Pagination.vue'
	import logsApi from '../../../../api/sys/logs.js'
	export default {
		components:{
			Pagination
		},
		data() {
			return {
				list:[],
				pageparm: {
					currentPage: 1,
					pageSize: 50,
					total: 20
				},
				formInline: {
					pageno: 1,
					pagesize: 50,
					mpid:''
				},
			}
		},

		created() {
			this.getdata(this.formInline)
		},
		/**
		 * 里面的方法只有被调用才会执行
		 */
		methods: {
			// 获取数据方法
			getdata(page) {
				this.loading = true
				// 请求数据开始
				let param ={
					mpid:this.formInline.mpid,
					pageno:page.pageno,
				}
				logsApi.list(param,res => {
					console.log(res, 'getdata.res');
					this.loading = false
					if (res.code == 200) {
						this.list = res.data.list
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.getdata(this.formInline)
			},
			//搜索事件
			search() {
				this.getdata(this.formInline)
			},
		
		
		}
	}
</script>

<style scoped>
	.user-search {
		margin-top: 20px;
	}

	.userRole {
		width: 100%;
	}

	
	.err1{
		color: #00aa00;
	}
	.err0{
		color: #ff0000;
	}
</style>