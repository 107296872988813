<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent" style="display:flex;">
			<leftnav menuid="10"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-breadcrumb separator-class="el-icon-arrow-right" style="margin-bottom: 10px;">
					<el-breadcrumb-item :to="{ path: '/customer/manage/app' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>总览</el-breadcrumb-item>
				</el-breadcrumb>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>小程序开发者配置</span>
					</div>
					<div class="main">
						<div class="item border">
								<div class="w200">开发者ID(AppID)：</div>
								<div class="value">{{appinfo.appID}}</div>
						</div>
						<div class="item">
							开发者密码(AppSecret)：{{appinfo.appSecret}}
						</div>
					</div>
				</el-card>
				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>服务器配置</span>
					</div>
					<div class="main" v-if="appinfo.isOpen == 1">
						<div class="item">
							<div class="w200">{{error || "授权状态："  }}</div>
							<div  class="value">{{auth_state == 1 ? '已授权':'未授权' }}</div>
							<a :href="authurl" target="_self"><div class="btn">{{auth_state == 1 ? '重新授权':'立即授权' }}</div></a>
						</div>
						<!-- <div class="item" v-if="appinfo.isOpen == 1">
							{{auth_state == 1 ? '已授权':'未授权' }}
							<a :href="authurl" target="_self"><el-button size="small" type="primary">授权</el-button></a>
						</div> -->
						
						
					</div>
					<div class="main" v-else>
						<div class="item">
							服务器地址(URL)：{{appinfo.postURL}}
						</div>
						<div class="item">
							令牌(Token)：{{appinfo.token}}
						</div>
						
						<div class="item">
							消息加解密密钥(EncodingAESKey)：{{appinfo.aesKey}}
						</div>
						<div class="item">
							消息加解密方式：明文模式
						</div>
						
					</div>
				</el-card>
			</div>
		</div>
	</div>
	
	
</template>

<script>
	import accountApi from '../../../../../../api/cus/account'
	import leftnav from '../../../../../../components/customer/home/mp/weixinapp/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components:{
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				appinfo: {},
				authurl:'',
				error:"",
				auth_state:0
			}
		},
		mounted() {
			let mpid = this.$route.query.mpid
			this.mpid = mpid
			if (mpid != undefined) {
			} else {
				this.$router.push("/");
			}
			accountApi.info({
				mpid: this.mpid
			}, res => {
				console.log(res, 'res.info');
				this.appinfo = res.data
				 this.getauth()
			})
		},
		methods: {
			getauth(){
				accountApi.wexinauthurl(this.mpid, res => {
					console.log(res, 'res.auth');
					if(res.code == 200){
						this.authurl = res.data.auth_url
						this.auth_state = res.data.auth_state
					}else{
						this.error = "授权获取失败"
					}
				})
			}
		}
	}
</script>

<style scoped>
	.page{
		background:#F5F7FA;
	}
	.box-card {
		margin-top: 20px;
	}

	.main .item {
		margin-bottom: 10px;
		font-weight: 700;
		font-size: 16px;
		padding:18px 0;
		display: flex;
		align-items: center;
	}
	.main .item  .btn{
		padding:5px 10px;
		color:#3388FF;
		border:1px solid #3388FF;
		font-weight: 500;
	}
	.border{
		border-bottom: 1px solid #E2E2E2;
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
	}
	.pagecontent{
		
		
		height: calc(100vh - 81px);
	}
	.w200{
		width:200px;
	}
	.value{
		color:#777777;
		font-weight: 500;
		font-size: 16px;
		flex-grow:1;
	}
</style>