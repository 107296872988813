import httpapi from '../../utils/httpapi';
const menuApi = {
	//自定义菜单项-列表
	list(param, funsucc) {
		console.log(param, 'list.param');
		httpapi.get('/customer/menu/list?'+`mpid=${param.mpid}`, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//自定义菜单项-添加/修改
	edit(param, funsucc) {
		httpapi.postjson('/customer/menu/edit', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//自定义菜单项-删除
	del(param, funsucc) {
		httpapi.post('/customer/menu/del', param).then(res => {
			console.log(res, 'edit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//发布菜单到微信公众号
	publish(param, funsucc) {
		httpapi.post('/customer/wxgzh/menu/publish', param).then(res => {
			console.log(res, 'publish.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//自定义菜单项-菜单文本消息
	txtmsg(param, funsucc) {
		httpapi.post('/customer/menu/msg/txt', param).then(res => {
			console.log(res, 'txtmsg.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//自定义菜单项-菜单图文消息
	txtimg(param, funsucc) {
		httpapi.post('/customer/menu/msg/img', param).then(res => {
			console.log(res, 'txtimg.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//自定义菜单项-获取菜单文本/图文内容
	info(menuid, funsucc) {
		httpapi.post('/customer/menu/detail', menuid).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	
	//查询图文消息列表-分页
	newslist(param, funsucc) {
		console.log(param, 'newslist.param');
		httpapi.get('/customer/mp/news/group/page?'+`mpid=${param.mpid}&pageno=${param.pageno}&pagesize=${param.pagesize}`, {}).then(res => {
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})

	},
	//图文消息-点添加调用
	newsadd(param, funsucc) {
		httpapi.post('/customer/mp/news/group/add', param).then(res => {
			console.log(res, 'newsadd.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//图文消息-查询图文消息详情
	newsdetail(param, funsucc) {
		httpapi.post('/customer/mp/news/group/detail', param).then(res => {
			console.log(res, 'newsdetail.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//图文消息-编辑图文内新闻
	newsedit(param, funsucc) {
		httpapi.postjson('/customer/mp/news/group/edit', param).then(res => {
			console.log(res, 'newsedit.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//图文消息-删除图文内新闻
	newsedel(param, funsucc) {
		httpapi.post('/customer/mp/news/group/del', param).then(res => {
			console.log(res, 'newsdel.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//图文消息-删除图文内单条新闻
	newsinfodel(param, funsucc) {
		httpapi.post('/customer/mp/news/info/del', param).then(res => {
			console.log(res, 'newsinfodel.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//图文消息-内部单条消息详情
	newsInfo(param, funsucc) {
		httpapi.post('/customer/mp/news/info/detail', param).then(res => {
			console.log(res, 'newsInfo.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default menuApi