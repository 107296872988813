<template>
	<div>
		<!--选择图片-->
		<el-dialog title="选择附件" :visible.sync="affixVisible" width="800px" :before-close="handleClose"
			:append-to-body="true">
			<div class="main">
				<el-radio-group v-model="welcomejson.msgtype" v-if="welcomejson.msgtype == ''">
					<el-radio label="image">图片</el-radio>
					<el-radio label="link">链接</el-radio>
					<!-- <el-radio label="miniprogram">小程序</el-radio> -->
				</el-radio-group>
				<div v-else class="mr"><span>类型</span>{{welcomejson.msgtype == 'image' ? '图片' :''}}{{welcomejson.msgtype == 'link' ? '链接' :''}}{{welcomejson.msgtype == 'miniprogram' ? '小程序' :''}}</div>
				<div class="" v-if="welcomejson.msgtype == 'image'">
					<el-upload class="upload-demo" ref="upload" drag :action="uploadqwurl" :on-success="successfile"
						:headers="headers" :data="importData" :limit="1" multiple style="margin-top: 20px;">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
					</el-upload>
					<div>
						<img :src="welcomejson.logo" style="width: 100px;" />
					</div>	
				</div>
				<div class="" v-if="welcomejson.msgtype == 'link'">
					<div class="mr">
						<span>标题</span><el-input type="text" v-model="welcomejson.title" size="small"
							placeholder="请输入标题" style="width: 400px;"></el-input>
					</div>
					<div class="mr">
						<span>地址</span><el-input type="text" v-model="welcomejson.url" size="small"
							placeholder="请输入http://或者https://的链接地址" style="width: 400px;"></el-input>
					</div>
					<div class="mr">
						<span>描述</span><el-input type="text" v-model="welcomejson.desc" size="small"
							placeholder="请输入链接描述" style="width: 400px;"></el-input>
					</div>
					<div class="mr">
						<span>图片</span>
						<el-upload class="upload-demo" ref="upload" drag :action="uploadqwurl" :on-success="successfile"
							:headers="headers" :data="importData" :limit="1" multiple style="margin-top: 20px;">
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
						</el-upload>
						<p>仅能上传一张图片，图片尺寸为500*400px，限制图片大小不能超过200k，仅支持PNG、JPG格式</p>
					</div>
					<div>
						<img :src="welcomejson.logo" style="width: 100px;" />
					</div>
				</div>
				<div class="" v-if="welcomejson.msgtype == 'miniprogram'">
					<div class="mr">
						<span>小程序标题</span><el-input type="text" v-model="welcomejson.title" size="small"
							placeholder="请输入小程序标题" style="width: 400px;"></el-input>
					</div>
					<div class="mr">
						<span>小程序APPID</span><el-input type="text" v-model="welcomejson.appid" size="small"
							placeholder="请输入小程序APPID" style="width: 400px;"></el-input>
						<div style="margin-left: 100px;margin-top: 5px;color: #909399;">小程序appid，必须是关联到企业的小程序应用</div>
					</div>
					<div class="mr">
						<span>小程序路径</span><el-input type="text" v-model="welcomejson.apppage" size="small"
							placeholder="请输入小程序路径" style="width: 400px;"></el-input>
					</div>
					<div>
						<span>小程序封面</span>
						<div  v-if="welcomejson.logo != undefined && welcomejson.logo.length > 0">
							<img :src="welcomejson.logo" style="width: 100px;" />
						</div>
						<el-upload class="upload-demo" ref="upload" drag :action="uploadappurl"
							:on-success="successfile" :headers="headers" :data="importData" :limit="1" multiple
							style="margin-top: 20px;" v-else>
							<i class="el-icon-upload"></i>
							<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
							<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>
							<p>仅能上传一张图片，封面图建议尺寸为520*416，限制图片大小不能超过200k，仅支持PNG、JPG格式</p>
						</el-upload>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='handleAdd'>保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import imgApi from '../../api/common/img'
	import {
		getloginuser
	} from '../../utils/util.js'
	export default {
		data() {
			return {
				headers: {
					Authorization: ''
				},
				uploadurl: '',
				uploadqwurl: '',
				importData: {
					name: '图片',
					mpid: 0
				},
				showselbtn:true,
				affixVisible: false,
				welcomejson: {
					"msgtype": "",
					"appid": "",
					"apppage": "",
					"desc": "",
					"logo": "",
					"media_id": "",
					"title": "",
					"url": "",
					"media_url": '',
					"res_id":'',
					"media_expire":''
				},
				handtype:''

			}
		},
		methods: {
			funShow(mpid,item,handtype) {
				console.log(item,'funShow.item');
				this.affixVisible = true
				let u = getloginuser()
				if (u != null) {
					this.headers.Authorization = 'Bearer ' + u.token
				}
				// this.headers.Authorization = 'Bearer ' + token
				this.uploadqwurl = '/api/customer/file/weixinwork/uploadimg'
				this.uploadappurl = '/api/customer/file/weixinwork/uploadimgtmp'
				this.uploadurl = '/api/customer/file/upload/image'
				this.importData.mpid = mpid
				
				this.handtype = handtype
				if(handtype == 'edit'){
					this.showselbtn = false
					this.welcomejson.msgtype = item.msgtype
					this.welcomejson.appid = item.appid
					this.welcomejson.apppage = item.apppage
					this.welcomejson.desc = item.desc
					this.welcomejson.logo = item.logo
					this.welcomejson.media_id = item.media_id
					this.welcomejson.title = item.title
					this.welcomejson.url = item.url
					this.welcomejson.media_url = item.media_url
					this.welcomejson.res_id = item.res_id
					this.welcomejson.media_expire = item.media_expire
					
				}else{
					this.showselbtn = true
					this.welcomejson.msgtype = ''
					this.welcomejson.appid =''
					this.welcomejson.apppage = ''
					this.welcomejson.desc = ''
					this.welcomejson.logo = ''
					this.welcomejson.media_id = ''
					this.welcomejson.title = ''
					this.welcomejson.url = ''
					this.welcomejson.media_url = ''
					this.welcomejson.res_id = ''
					this.welcomejson.media_expire = ''
				}
			},
			handleAdd() {
				// if (this.welcomejson.msgtype == 'miniprogram') {
				// 	param = {
				// 		"title": this.miniprogram.title,
				// 		"pic_media_id": this.miniprogram.pic_media_id,
				// 		"appid": this.miniprogram.appid,
				// 		"page": this.miniprogram.page,


				// 	}
				// } else {
				// 	param = {
				// 		title: this.welcomejson.title,
				// 		msgtype: this.welcomejson.msgtype,
				// 		linkurl: this.welcomejson.linkurl,
				// 		appid: this.welcomejson.appid,
				// 		apppage: this.welcomejson.apppage,
				// 	}
				// }
				let param = {
					msgtype:this.welcomejson.msgtype, 
					appid:this.welcomejson.appid, 
					apppage:this.welcomejson.apppage ,
					desc:this.welcomejson.desc ,
					logo:this.welcomejson.logo,
					title:this.welcomejson.title ,
					url:this.welcomejson.url ,
					media_id:this.welcomejson.media_id,
					media_url:this.welcomejson.media_url,
					res_id:this.welcomejson.res_id,
					media_expire:this.welcomejson.media_expire
				}
				
				console.log(param, 'param222');
				this.affixVisible = false
				this.$emit('selaffixresult', param,this.handtype)
			},
			successfile(res, file, fileList) {
				console.log(this.welcomejson.msgtype, 'this.welcomejson.msgtype');
				console.log("successfile==>", res, file, fileList);
				if (res.code == 200) {
					this.$message({
						type: 'success',
						message: "上传成功"
					})
					// 提交保存成功后清空已上传的文件
					this.$refs.upload.clearFiles();
					this.welcomejson.media_url = res.data.media_url
					this.welcomejson.medeia_id = res.data.medeia_id
					this.welcomejson.logo = res.data.link_url
					this.welcomejson.res_id = res.data.res_id
					if(this.welcomejson.msgtype == 'miniprogram'){
						this.welcomejson.media_expire = res.data.expireTime
					}else{
						this.welcomejson.media_expire = '0'
					}
				} else {
					this.$message({
						type: 'error',
						message: res.msg + "上传失败"
					})
				}
				// if (this.welcomejson.msgtype == 'image') {
				// 	if (res.code == 200) {
				// 		this.$message({
				// 			type: 'success',
				// 			message: "上传成功"
				// 		})

				// 		this.$refs.upload.clearFiles();
				// 		this.welcomejson.imgurl = res.data.media_url
				// 		this.welcomejson.medeia_id = res.data.medeia_id
				// 	} else {
				// 		this.$message({
				// 			type: 'error',
				// 			message: res.msg + "上传失败"
				// 		})
				// 	}
				// } else if (this.welcomejson.msgtype == 'link') {
				// 	if (res.code == 200) {
				// 		this.$message({
				// 			type: 'success',
				// 			message: "上传成功"
				// 		})

				// 		this.$refs.upload.clearFiles();
				// 		this.welcomejson.imgurl = res.data.media_url
				// 		this.welcomejson.medeia_id = res.data.medeia_id
				// 	} else {
				// 		this.$message({
				// 			type: 'error',
				// 			message: res.msg + "上传失败"
				// 		})
				// 	}
				// } else if (this.welcomejson.msgtype == 'miniprogram') {
				// 	if (res.code == 200) {
				// 		this.$message({
				// 			type: 'success',
				// 			message: "上传成功"
				// 		})

				// 		this.$refs.upload.clearFiles();
				// 		this.welcomejson.imgurl = res.data.media_url
				// 		this.welcomejson.medeia_id = res.data.medeia_id
				// 	} else {
				// 		this.$message({
				// 			type: 'error',
				// 			message: res.msg + "上传失败"
				// 		})
				// 	}
				// }


			},
			handleClose() {
				this.affixVisible = false
			}
		}
	}
</script>

<style scoped>
	.mr {
		margin: 10px 0;
	}

	.mr span {
		margin: 0 10px;
	}
</style>