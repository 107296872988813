<template>
	<div class="container">
		<div class="header">模版市场</div>
		<div class="waterfall">
			<div class="waterfall-wrap">
				<div class="waterfall-item" v-for="(item,index) in listbypaylist" :key="index">
					<img :src="item.logo"/>
				</div>
			</div>
		</div>
		<hansTabber :list="list" :currentIndex="1" style="position:fixed;bottom:0;width:100%;left:0;right:0;" @tabChange="tabChange">
		</hansTabber>
	</div>
</template>

<script>
	import marketApi from '@/api/cus/market.js';
	import {getloginuser} from '../../../utils/util.js'
	import hansTabber from '@/components/hans-tabbar/hans-tabbar.vue'
	export default{
		components: {
			hansTabber
		},
		data() {
		  return {
			  listbypaylist:[],
				list: [{
						"text": "首页",
						"iconPath": require('@/assets/app/home.png'),
						"selectedIconPath": require('@/assets/app/home_active.png')
					},
					{
						"text": "模版市场",
						"iconPath": require('@/assets/app/work.png'),
						"selectedIconPath": require('@/assets/app/work_active.png')
					},
					{
						"text": "我的",
						"iconPath": require('@/assets/app/my.png'),
						"selectedIconPath": require('@/assets/app/my_active.png')
					}
				],
			};
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let u = getloginuser()
				// let u = sessionStorage.getItem('customeruser')
				if (u != null) {
					// this.userInfo = JSON.parse(u);
					this.getlistbypaylist()
				} else {
					this.$router.push("/app/qiyewx/login");
				}
			},
			getlistbypaylist(){
				marketApi.listbypay({}, res => {
					console.log(res, 'listbypay.res');
					if (res.code == 200) {
						this.listbypaylist = res.data
					}
				})
			},
			  tabChange(index) {
				  console.log(index,'indexcot');
			  }
		},
	}
</script>

<style lang="scss">
	.container{
		.header{
			position: fixed;
			top: 0;
			width: 100%;
			height: 40px;
			line-height: 40px;
			text-align: center;
			background-color: #ffffff;
			border-bottom: 1px solid #f0f0f0;
		}
		.waterfall{
			margin: 0 auto;
			margin-top: 50px;
			width:320px;
			.waterfall-wrap{
				padding-bottom: 100px;
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				.waterfall-item{
					margin: 10px 0;
				}
				img{
					width: 140px;
				}
			}
		}
	}
</style>