<template>
	<div>
		<div class="foot_nav_circle" >
			<div class="imgcont" :style="{
         'margin-left':marginpage,
          'margin-right':marginpage,
					'margin-top':margintop,
          'margin-bottom':marginbottom,
					'width' : width,
					'height' : height}">

					<div v-if="list.options && list.options.bgimg && list.options.bgimg.length > 0">
						<img :src="(list.options.bgimg && list.options.bgimg.length > 0) ? list.options.bgimg : ''" :style="{
							'width' : width,
					'height' : height
						}" />
					</div>



				<div 	v-for="(item, index) in list.data" :key="index" class="item" 
				 :id="'a'+index"
				 :style="{
						
						'left':(item.left / 2) + 'px',
						'top':(item.top / 2) + 'px',
						'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px'
					}">
						<el-image style="display:block"
						
						:style="{
							'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
						'border-radius': borderradius,
						 
						}" :src="item.url" v-show="data.data &&data.data.length > 0"></el-image>
						<div class="title" :style="{
							'margin-top':item.margintop + 'px',
							'color':list.options.titlecolor,
							fontSize: size,
          fontWeight: bold,
						}">
							{{item.title}}
						</div>
					</div>

			
				<div class="empty" v-show="data.data.length == 0">
					<el-empty description="此区域放置图片"></el-empty>
				</div>
			</div>

			<!-- <el-image style="width: 100%" :src="url"></el-image> -->
		</div>
	</div>
</template>

<script>


	export default {
		name: "foot_nav_cube",
		components: {
			
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				list: "",
			};
		},

		mounted() {
			this.list = this.data;
			console.log(this.list, 'list');

			 // 添加点击事件
			 this.$nextTick(() => {
            for (let i = 0; i < this.list.data.length; i++) {
                this.btnclick(i);
            }
        });

		},
		computed: {
			

			height(){
				if (this.list.options) {
					if (this.list.options.height && this.list.options.height > 0)
						return (this.list.options.height / 2) + 'px';
				}
			},
			width(){
				if (this.list.options) {
					if (this.list.options.width && this.list.options.width > 0)
						return (this.list.options.width / 2) + 'px';
				}
			},
			margintop() {
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + 'px';
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
						return this.list.options.marginbottom + 'px';
				}
			},
			marginpage() {
				if (this.list.options) {
					if (this.list.options.marginpage && this.list.options.marginpage > 0)
						return this.list.options.marginpage + 'px';
				}
			},
			borderradius() {
				if (this.list.options) {
					if (this.list.options.borderradius && this.list.options.borderradius > 0)
						return this.list.options.borderradius + 'px';
				}
			},
			//  标题字体大小
			size() {
      if (this.list.options.size == 3) {
        return "12px";
      } else if (this.list.options.size == 2) {
        return "14px";
      } else {
        return "16px";
      }
    },
			// 标题加粗
			bold() {
      if (this.list.options.bold == 2) {
        return "900";
      } else {
        return "300";
      }
    },
		},

		methods: {
			// 点击元素事件
			btnclick(index) {
            console.log(`a${index}`);
            const son = document.getElementById(`a${index}`);
            const father = document.getElementById('imgcont');

            //触摸拖动
            this.dragTouch(father, son);
            //鼠标点击拖动
            this.dragMouse(father, son);
        },
				dragTouch(father, son) {
            let disX;
            let disY;
            const that = this;
            son.addEventListener('click', (e) => {
                console.log('这是触摸', e, son);
                
            });
        },
        dragMouse(father, son) {
            let disX;
            let disY;
            const that = this;
            son.onmousedown = function (e) {
                console.log('这是鼠标', e, son);
                that.count += 1;
                son.style.zIndex = that.count;
                const oEvent = e || event;
                disX = oEvent.clientX - son.offsetLeft;
                disY = oEvent.clientY - son.offsetTop;
                that.isDrag = true;
                // 鼠标移动事件
                document.onmousemove = function (e) {
                    console.log('正在鼠标移动', e);
                    if (that.isDrag) {
                        const oEvent = e || event;
                        let l = oEvent.clientX - disX;
                        let t = oEvent.clientY - disY;
                        /** 限定拖拽范围，限定拖拽元素在指定的范围内 */
                        if (l < 0) {
                            l = 0;
                        }
                        if (t < 0) {
                            t = 0;
                        }
                        if (l > father.clientWidth - son.clientWidth) {
                            l = father.clientWidth - son.clientWidth;
                        }
                        if (t > father.clientHeight - son.clientHeight) {
                            t = father.clientHeight - son.clientHeight;
                        }
                        son.style.left = `${l}px`;
                        son.style.top = `${t}px`;
                    }
                };
                document.onmouseup = function () {
                    that.isDrag = false;
                    document.onmousemove = null;
                    document.onmouseup = null;
                };
                return false; // 阻止默认行为，空的div在低版本ff下，第二次拖动手型会变异常
            };
        },

			test(){
				alert(1);
			}
		},
	};
</script>

<style lang="less" scoped>
	.foot_nav_circle {
		width: 400px;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		.imgcont {
			overflow: hidden;
		position: relative;
		.item{
			position: absolute;
		}
	}

	}



	.empty {
		width: 400px;
		// text-align: center;
		// display: flex;
		// flex-wrap: wrap;
	}
	.title{
		height: 40px;
		line-height: 40px;
	}
</style>