<template>
	<div>
		<el-dialog :title="title" :visible.sync="showdesk" width="80%" :before-close="deskClose" :append-to-body="true">
			<div>
				<div class="area">
					<div class="areaitem" :class="areaIndex == index ? 'sel' :'nomal'" v-for="(item,index) in areaList"
						:key="index" @click="funSelArea(item,index)">
						{{item.mc}}
					</div>
				</div>
				<div class="desk">
					<div class="deskitem" v-for="(item,index) in deskList" :key="index" @click="funSelDesk(item,index)">
						{{item.mc}}
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer" style="display: flex;justify-content: right;">
				<el-button size="small" @click='deskClose'>关闭</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import deskApi from '../../api/cus/desk.js'
	export default {
		data() {
			return {
				title: "选择桌台",
				showdesk: false,
				areaid: "",
				app_tmpid: "",
				areaIndex: 0,
				areaList: [],
				deskall: []
			}
		},
		computed:{
			deskList(){
				if(this.areaid == ''){
					return this.deskall
				}else{
					let templist =	this.deskall.filter(f =>{
							return this.areaid == f.gid
						})
						if(templist != undefined){
							return templist
						}else{
							return []
						}
					}
				}
			
		},
		methods: {
			funShow(tmpid) {
				this.app_tmpid = tmpid
				this.showdesk = true;
				this.getarea()
				this.getdesk()
			},
			deskClose() {
				this.showdesk = false;
			},
			funSelArea(item, index) {
				this.areaIndex = index
				this.areaid = item.id
				
			},
			getarea() {
				let param = {
					app_tmpid: this.app_tmpid
				}
				console.log(param, 'areaparam');
				deskApi.arealist(param, res => {
					console.log(res, 'arealist.res');
					if (res.code == 200) {
						res.rows.unshift({
							id: '',
							mc: '全部',
							selzt: 0
						})
						this.areaList = res.rows
					}
				})
			},
			getdesk() {
				let param = {
					app_tmpid: this.app_tmpid,
					areaid: ''
				}
				console.log(param, 'deskparam');
				deskApi.desklist(param, res => {
					console.log(res, 'deskres');
					if (res.code == 200) {
						this.deskall = res.rows
					}
				})
			},
			funSelDesk(item) {
				this.showdesk = false
				this.$emit('seldeskresult', {
					id:item.id,
					gid:item.gid,
					mc:item.mc,
				})
			}
		}

	}
</script>

<style scoped>
	.area {
		display: flex;
		align-items: center;
	}

	.area .areaitem {
		margin: 5px;
		padding: 5px 10px;
		border: 1px solid #ccc;
		border-radius: 15px;
		cursor: pointer;
	}

	.sel {
		color: #fff;
		border: 1px solid #5c6bc8 !important;
		background-color: #5c6bc8;
	}

	.desk {
		height: calc(100vh - 500px);
		overflow-y: auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		align-content: baseline;
	}

	.desk .deskitem {
		margin: 10px;
		cursor: pointer;
		width: 200px;
		height: 100px;
		text-align: center;
		line-height: 100px;
		border: 1px solid #ccc;
		border-radius: 5px;
	}
</style>