<template>

	<!--企业微信个人二维码-->
	<div class="manbox" :style="bgimg" >
		<div class="ewmbox">
			<img class="ewmimg" :src="deskconfig.qrcode" />
		</div>
		
		
		<div class="bottombg">
			<img :src="deskconfig.btnurl" class="btnbg"/>
		</div>
		<div class="btn-bar">
			<wx-open-launch-weapp style="display: block;width: 100%;height: 110px;position: absolute;top: 0;left: 0;" :env-version="envversion"
				:path="deskconfig.apppath"
				:appid="deskconfig.appid" id="open-launch" @launch="handleLaunchFn" @error="handleErrorFn">
				<script type="text/wxtag-template">
					<style>.btn {width:100%;height: 110px;position: 'absolute', top:0, left: 0}</style> 
					<div class="btn"></div>
				  </script>
			</wx-open-launch-weapp>
		</div>
		
	</div>
</template>
<script>
	import signApi from '../../../../api/cus/sign.js'
	export default {
		data() {
			return {
				mpid: '',
				wxminiappid: 'wxf1779ed3e57024ee',
				signurl: '', 
				envversion:'release', //正式版release、开发版develop、体验版trial	
				err1: {},
				err2: {},
				querydata: {
					deskid: ''
				},
				deskconfig: {
					appid: "",
					apppath: "",
					apptype: "",
					config_id: "",
					deskid: "",
					deskmc: "",
					mchno: "",
					mchshopid: "",
					mchshopname: "",
					mpid: "",
					qrcode: "",
					btnurl:'',
					bgurl:''
				},
				bgimg:''
			}
		},
		// created() {
		// 	// const oScript = document.createElement('script');
		// 	// oScript.type = 'text/javascript';
		// 	// oScript.src = 'https://res2.wx.qq.com/open/js/jweixin-1.6.0.js';
		// 	// oScript.onload = this.init
		// 	// document.body.appendChild(oScript);

		// },
		mounted() {
		
			this.init()
			// document.querySelector('wx-open-launch-weapp').setAttribute('path', '/pages/index/index');

		},
		methods: {
			handleErrorFn(e) {
				this.err1 = e.detail
				console.log('fail', e.detail);
			},
			handleLaunchFn(e) {
				this.err2 = JSON.stringify(e)
				console.log('success', e)
			},


			init() {
				let mpid = this.$route.query.mpid
				
				if(this.$route.query.ver != undefined){
					this.envversion =this.$route.query.ver
				}
				else
				{
					this.envversion = 'release'
				}

				if (mpid != undefined) {
					this.mpid = mpid
				}
				let url = ''
				if (window.entryUrl) {
					url = window.entryUrl
				} else if (sessionStorage.weappUrl) {
					url = sessionStorage.weappUrl
				} else {
					url = window.location.href.split('#')[0]
				}
				this.signurl = url;
				console.log(this.signurl, 'signurl')
				let mchno = this.$route.query.mchno != undefined ? this.$route.query.mchno : ''
				let fdid = this.$route.query.fdid != undefined ? this.$route.query.fdid : ''
				let desk = this.$route.query.desk != undefined ? this.$route.query.desk : ''
				let viewpath = this.$route.query.viewpath != undefined ? this.$route.query.viewpath : ''
				let funid = this.$route.query.funid != undefined ? this.$route.query.funid : ''
				let clubid = this.$route.query.clubid != undefined ? this.$route.query.clubid : ''
				let clubmc = this.$route.query.clubmc != undefined ? this.$route.query.clubmc : ''
				let sellerid = this.$route.query.sellerid != undefined ? this.$route.query.sellerid : ''
				let sellermc = this.$route.query.sellermc != undefined ? this.$route.query.sellermc : ''
				let pagetype = this.$route.query.pagetype != undefined ? this.$route.query.pagetype : ''
				let pageid = this.$route.query.pageid != undefined ? this.$route.query.pageid : ''
				let tempid = this.$route.query.tempid != undefined ? this.$route.query.tempid : ''
				let tempcls = this.$route.query.tempcls != undefined ? this.$route.query.tempcls : ''
				let appver = this.$route.query.appver != undefined ? this.$route.query.appver : ''
				this.querydata.deskid = desk
				
				this.getConfigWxgzh();
				this.getdeskqr()
			},

			getdeskqr() {
				//获取微信公众号jsapi权限，用于打开小程序
				signApi.qywx_getdeskqrconfig({
					deskid: this.querydata.deskid
				}, res => {
					console.log(res, 'qywx_getdeskqrconfig')
					if (res.code == 200) {
						let apppath = res.data.apppath
						apppath = apppath.replace('$fun$',"diandan");
						apppath = apppath.replace('$mch$',res.data.mchno);
						apppath = apppath.replace('$fdid$',res.data.mchshopid);
						apppath = apppath.replace('$deskid$',res.data.deskid);
						this.deskconfig.appid = res.data.appid
						this.deskconfig.apppath = apppath
						this.deskconfig.apptype = res.data.apptype
						this.deskconfig.config_id = res.data.config_id
						this.deskconfig.deskid = res.data.deskid
						this.deskconfig.deskmc = res.data.deskmc
						this.deskconfig.mchno = res.data.mchno
						this.deskconfig.mchshopid = res.data.mchshopid
						this.deskconfig.mchshopname = res.data.mchshopname
						this.deskconfig.mpid = res.data.mpid
						this.deskconfig.qrcode = res.data.qrcode
						this.deskconfig.bgurl = res.data.bgurl
						this.deskconfig.btnurl = res.data.btnurl
						this.bgimg = 'background-image:url(' + res.data.bgurl +');' 
						
						document.title = res.data.deskmc + res.data.mchshopname
						console.log(this.deskconfig,'this.deskconfig');
					}
				})
			},

			getConfigWxgzh() {
				//获取微信公众号jsapi权限，用于打开小程序
				signApi.wxgzh_getsignature({
					signurl: this.signurl
				}, res => {
					console.log(res, 'wxgzh_getsignature')
					if (res.code == 200) {
						wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
							appId: res.data.appid, // 必填，公众号的唯一标识
							timestamp: parseInt(res.data.timestamp), // 必填，生成签名的时间戳
							nonceStr: res.data.noncestr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: ['chooseImage', 'previewImage'], // 必填，需要使用的JS接口列表
							openTagList: [
								'wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
						});

						wx.ready(function() {
							// config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
						});
						wx.error(function(res) {
							// config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
						});
					}
				})

			},
			//获取企业微信绑定的微信点单小程序id
			getqiyewxbindwxappid() {

			},
			//获取企业微信对应的企业微信二维码
			getqiyewxqrcode() {

			},

			// 应用身份认证
			agentInit() {
				let _this = this
				let param = {
					url: 'https://gzh.dxerp.net/',
					type: 2, // 1:企业 2:应用
					mpid: this.mpid
				}
				console.log(param, ';param');
				signApi.getsignature(param, res => {
					console.log(res, 'sign');
					if (res.code == 200) {
						wx.agentConfig({
							corpid: res.data.corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
							agentid: res.data.agentid, // 必填，企业微信的应用id （e.g. 1000247）
							timestamp: res.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名，见附录-JS-SDK使用权限签名算法
							jsApiList: ['getContext', 'getCurExternalContact',
								'hideMenuItems', 'checkJsApi'
							], //必填，传入需要使用的接口名称
							success: (res) => {
								// 回调
								console.log('应用身份', res)
								// this.getContext()
								_this.checkJsApi()
							},
							fail: function(res) {
								if (res.errMsg.indexOf('function not exist') > -1) {
									alert('版本过低请升级')
								}
							}
						});
					}
				})
			},
			checkJsApi() {
				let _this = this
				wx.checkJsApi({
					jsApiList: ["getContext", "getCurExternalContact"], // 需要检测的JS接口列表
					success: function(res) {
						// 以键值对的形式返回，可用的api值true，不可用为false
						// 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
						console.log(res, 'res.checkJsApi');
						let obj = res.checkResult;
						wx.invoke('getContext', {}, function(res) {
							console.log(res, 'getContext');
							if (res.err_msg == "getContext:ok") {
								// single_kf_tools  客服工具栏进入
								// entry  = res.entry ; //返回进入H5页面的入口类型，目前有normal、contact_profile、single_chat_tools、group_chat_tools、chat_attachment								
								console.log(2222);
								// if (res.entry === 'single_kf_tools') {
								_this.getUserId()
								// }
							} else {
								//错误处理
								// _this.$toast('请从微信客服端进入！')
								alert('请从微信客服端进入!')
							}
						});
					},
				});
			},
			// 调用获取H5环境入口的接口
			// getContext() {
			// 	wx.invoke('getCurExternalContact', {}, (res) => {
			// 		console.log('getCurExternalContact', res)

			// 		if (res.errMsg == "getContext:ok") {
			// 			// 正常处理业务逻辑
			// 			// 获取当前对话框userid
			// 			// if (res.entry == 'normal') {
			// 				// 侧边栏进入
			// 				// uni.showModal({
			// 				// 	title: '请在下列场景打开应用!',
			// 				// 	content: '外部联系人聊天窗口或详情',
			// 				// 	confirmColor: '#fff',
			// 				// 	showCancel: false,
			// 				// 	success() {

			// 				// 	}
			// 				// })
			// 			// } else {
			// 				this.getUserid()
			// 			// }


			// 			// entry  = res.entry ; 
			// 			// shareTicket = res.shareTicket; //可用于调用getShareInfo接口

			// 		} else {
			// 			//错误处理
			// 		}
			// 	});
			// },
			// 当前外部联系人userId
			getUserId() {
				console.log(333);
				const _this = this
				wx.invoke('getCurExternalContact', {}, (res) => {
					console.log('getCurExternalContact11111', res)
					if (res.err_msg == "getCurExternalContact:ok") {
						var userId = res.userId; //返回当前外部联系人userId
						const params = {
							company_id: localStorage.getItem("company_id") || Cookies.get('company_id'),
							weixin_id: _this.userId
						}
						_this.getData(params)
						// uni.$emit('getExternalId', res)
					} else {
						//错误处理
						alert('getCurExternalContact!')
						console.log('getCurExternalContact:err')
					}
				});
			},
			getData(params) {
				console.log(params, 'params');
			},

		}
	}
</script>

<style scoped>
	.manbox {
		height: 100vh;
		overflow: hidden;
		/* background: url('../../../../assets/image/icon_manbg.jpg'); */
		background-size: 100%;
		position: relative;
		
		
	}


	.manbox  .ewmbox {
		width: 200px;
		height: 200px;
		position: absolute;
		bottom:130px ;
		left: calc((100vw - 200px) / 2);
	}
	
	.manbox  .ewmbox .ewmimg {
		width: 100%;
		height: 100%;
	}

	.manbox  .btn {
		width: 100%;
		height: 100%;
	}

	/* .btn-bar {
		position: absolute;
		bottom:0 ;
		left: 0;
		width: 100%;
		height: 280px;
		z-index: 99;
		
	} */
	.bottombg{
		width: 100%;
		height: 110px;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 10;
		text-align: center;
		
	}
	.bottombg .btnbg{
		max-width: 100%;
		height: auto;
		margin: 0 auto;
		
		
	}
	.btn-bar {
			position: absolute;
			left:0;
			bottom: 0;
			
			text-align: center;
			width:100%;
			height: 110px;
			
			
			display: flex;
			justify-content: center;
			text-align: center;
			z-index: 9999;
		}
		.btn-bar .btn{
			width: 100%;
			height: 100%;
		}
</style>