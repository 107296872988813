<template>
	<div class="content" v-if="payshow">
		<div class="maskbg" @click="funClose"></div>
		<div class="paybox">
			<div class="payleft">
				<img src="../../assets/image/paybg.png"/>
			</div>
			<div class="payright">
				<div class="pay_head">在线支付</div>
				<div class="pay_cont">
					<div class="pay_wrap">
						<div class="pay_item">
							<img src="../../assets/image/wxpay.png"/>
						</div>
						<div class="pay_item">
							<img src="../../assets/image/zfbpay.png"/>
						</div>
					</div>
					<div class="pay_ewm">
						<div class="title">
							支付宝支付：<span class="price">50</span>元
						</div>
						<div class="ewm">
							<img src="../../assets/image/ewm.png"/>
						</div>
						<div class="scan">
							打开支付宝扫一扫，扫描二维码完成支付
						</div>
					</div>
				</div>
				<div class="tips">
					如有问题请24小时内发起投诉
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		data(){
			return{
				payshow:false
			}
		},
		methods:{
			funShow(){
				this.payshow = true
			},
			funClose(){
				this.payshow = false
			}
		}
	}
</script>

<style scoped>
	.maskbg{
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, 0.5);
	}
	.paybox{
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 700px;
		height: 470px;
		background: #fff;
		border-radius: 10px;
		display: flex;
	}
	.paybox .payleft{
		width: 250px;
	}
	.paybox .payleft img{
		width: 100%;
		height: 100%;
	}
	.paybox .payright{
		width: 450px;
		height: 70px;
		line-height: 70px;
		border-bottom: 1px solid #D3D3D3;
	}
	.paybox .payright .pay_head{
		text-align: center;
		font-size: 18px;
		font-weight: 800;
	}
	.paybox .payright .pay_cont .pay_wrap{
		margin: 0 auto;
		width: 350px;
		display: flex;
		align-items: center;
		justify-content: space-around;
	}
	.paybox .payright .pay_cont .pay_wrap .pay_item{
		cursor: pointer;
		margin-top: 20px;
		border: 1px solid #3388FF;
		border-radius: 10px;
		display: flex;
		align-items: center;
		padding: 10px;
	}
	.paybox .payright .pay_cont .pay_wrap .pay_item:nth-child(1){
		border: 1px solid #00cf00;
	}
	.paybox  .pay_ewm{
		margin-top: 10px;
		height: 260px;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.paybox  .pay_ewm .price{
		color: #ffaa00;
		font-size: 18px;
		font-weight: 800;
		padding:0 5px;
	}
	.paybox  .pay_ewm .title{
		font-size: 18px;
		font-weight: 800;
	}
	.paybox  .pay_ewm .ewm{
		width: 133px;
		height: 133px;
	}
	.paybox  .pay_ewm .ewm img{
		width: 100%;
		height: 100%;
	}
	.paybox  .pay_ewm .scan{
		color: #979797;
		font-size: 12px;
	}
	.paybox .tips{
		cursor: pointer;
		height: 60px;
		line-height: 60px;
		border-top: 1px solid #D3D3D3;
		text-align: center;
		color: #3388FF;
	}
</style>