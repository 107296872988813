<template>
	<!-- 设置背景样式 -->
	<div id="wenben" :style="{ borderBottom: border,'padding-top':margintop,
          'padding-bottom':marginbottom,'padding-left':  list.options.paddingpage + 'px',
        'padding-right':  list.options.paddingpage + 'px',background: list.options.bgcolor
          }">
		<div class="box" :style="{background: list.options.background,'padding-top':paddingtop,
          'padding-bottom':paddingbottom,'border-radius':list.options.borderradius + 'px','height':list.options.height + 'px'}">
			<div class="cont" v-if="list.options.titleshow">
				<div class="bottom" :style="{
				  fontSize: size,
				  textAlign: align,
				  fontWeight: bold,
				  color: list.options.titlecolor,
				  'background-color':list.options.titlebgcolor
				}">
						{{ list.options.name  }}
					</div>
			</div>
			<div class="bgimg" v-if="list.options.bgimg.length > 0">
				<img :src="list.options.bgimg " :style="{'border-radius':list.options.borderradius + 'px'}"/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "news_list",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
			};
		},

		created() {
			this.list = this.data;
		},
		computed: {
			//  标题字体大小
			size() {
				if (this.list.options.size == 3) {
					return "12px";
				} else if (this.list.options.size == 2) {
					return "14px";
				} else {
					return "16px";
				}
			},
			// 标题显示位置
			align() {
				if (this.list.options.position == 1) {
					return "left";
				} else if (this.list.options.position == 2) {
					return "center";
				}
			},
			// 描述文字大小
			describesize() {
				if (this.list.options.describe == 3) {
					return "12px";
				} else if (this.list.options.describe == 2) {
					return "14px";
				} else {
					return "12px";
				}
			},
			// 标题加粗
			bold() {
				if (this.list.options.bold == 2) {
					return "900";
				} else {
					return "300";
				}
			},
			// 底部分割线
			border() {
				if (this.list.options.border) {
					return "1px solid #cccccc";
				} else {
					return "";
				}
			},
			margintop() {
				
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + 'px';
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (this.list.options.marginbottom && this.list.options.marginbottom > 0)
						return this.list.options.marginbottom + 'px';
				}
			},
			paddingtop() {
				if (this.list.options) {
					if (this.list.options.paddingtop && this.list.options.paddingtop > 0)
						return this.list.options.paddingtop + 'px';
				}
			},
			paddingbottom() {
				if (this.list.options) {
					if (this.list.options.paddingbottom && this.list.options.paddingbottom > 0)
						return this.list.options.paddingbottom + 'px';
				}
			},
			paddingpage() {
				if (this.list.options) {
					if (this.list.options.paddingpage && this.list.options.paddingpage > 0)
						return this.list.options.paddingpage + 'px';
				}
			},
		},
		methods: {},
	};
</script>

<style lang="less" scoped>
	#wenben {
		width: 100%;
		padding:0 16px;
		box-sizing: border-box;
	}
	
	.box {
		min-height: 80px;
		padding: 16px;
		border-radius: 4px;
		position: relative;
		overflow: hidden;
	}
	.box .cont{
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 999;
		width: 100%;
		height: 35px;
		line-height: 35px;
		text-align: center;
		opacity: 0.8;
		// background-color:rgba(0, 0, 0, 0.6);
	}
	.box .cont .bottom{
		
	}
	.box .bgimg{
		position: absolute;
		left: 0;
		top: 0;
		width: 375px;
	}
	.box .bgimg img{
		width: 100%;
		height: 100%;
	}
	.box .btns {
		position: absolute;
		right: 20px;
		top: 30px;
		z-index: 9;
		text-align: center;
		overflow: hidden;
	}

	.box .btns span {
		display: block;
		color: #fff;
		width: 56px;
		min-width: 56px;
		height: 20px;
		font-size: 14px;
		line-height: 20px;
		padding: 2px 10px;
		border-radius: 12px;
		font-weight: 600;
		white-space: nowrap;
		background-image: linear-gradient(
		90deg,#feae79,#ff3d3d);
	}

	.text {
		overflow: hidden;
		line-height: 30px;
		font-size: 16px;

		p {
			margin: 0 20px;
		}
	}

	.describe {
		line-height: 30px;
		font-size: 12px;
		word-wrap: break-word;
	}
</style>