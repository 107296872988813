<template>
	<div class="main-wrap">
		<div class="main-title">{{title}}</div>
		<div class="main-date">{{author}} {{createdate}}</div>
		<div class="newscontent" v-html="content">
		</div>
	</div>
</template>

<script>
	import menuApi from '../../../api/cus/menu.js'
	export default {
		data() {
			return {
				id: "",
				content: "",
				title: "",
				author:"",
				createdate:""
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let id = this.$route.query.id
				if (id != undefined || id != undefined) {
					this.id = id
				} else {
					this.content = "新闻不存在"
				}

				this.getDetail()
			},
			getDetail() {
				let param = {
					id: this.id
				}
				menuApi.newsInfo(param, res => {
					if (res.code == 200) {
						this.title = res.data.title
						this.content = res.data.content
						this.author = res.data.author
						this.createdate = res.data.createDate
						document.title = this.title
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			},
		}
	}
</script>

<style scoped>
	.main-wrap {
		margin: 0 20px;
	}
	.main-date{
		color:#ccc;
		font-size: 12px;
		margin-bottom:30px;
	}
	.newscontent{
		font-size: 14px;
	}
	.newscontent>>>img {
		width: 100%;
	}

	.main-title {
		margin:15px 0;
		text-align: left;
		font-size: 26px;
		font-weight: 800;
	}
</style>