<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="22"></leftnav>
			<div class="rightbox" >
				<el-card class="box-card" >
							<div slot="header" class="clearfix">
								<div class="">
									<span>名称：</span><el-input type="text" size="small" v-model="formInline.name" style="width:200px;margin: 0 20px;"></el-input>
								</div>
								<div class="" style="display: flex;justify-content: left;">
									<el-button type="primary" size="small" @click="search">搜索</el-button>
								</div>
								<div class="" style="display: flex;justify-content: left;margin-left: 10px;">
									<el-button  size="small" @click="funAdd">新增</el-button>
								</div>
							</div>
							<!--列表-->
							<el-table size="small" :data="scanData" highlight-current-row v-loading="loading" :border="false"
								element-loading-text="拼命加载中" style="width: 100%;">
				<!-- 
								<el-table-column align="center" sortable prop="chat_id" label="id" width="180">
								</el-table-column> -->
								
								<!-- <el-table-column align="center" sortable prop="qrtype" label="群二维码" width="100">
									<template slot-scope="scope">
										<img :src="scope.row.qrcode" style="width: 80px;"/>
									</template>
								</el-table-column> -->
								<el-table-column align="left"  prop="title" label="标题" width="280">
								</el-table-column>
								
								
							<!-- 	<el-table-column align="center" sortable prop="scene" label="使用场景" width="100">
								</el-table-column> -->
								
								<el-table-column align="left"  prop="memo" label="备注" width="280">
								</el-table-column>
								<el-table-column label="操作" min-width="280" fixed="right">
									<template slot-scope="scope">
										<el-button size="mini" @click="handleqr(scope.$index, scope.row)">获取二维码</el-button>
										<el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
										<el-button size="mini" type="danger"
											@click="deleteqr(scope.$index, scope.row)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
							<!-- 分页组件 -->
							<!-- <Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather" ></Pagination> -->
						</el-card>
				</div>
				<el-dialog title="企微群二维码 " top="5%" :width="showmenu == true ? '30%' : '90%'" :visible.sync="showewm" @close="showewm = false">
					<div style="width: 100%;display: flex;justify-content: center;">
						<el-image style="margin: 20px auto;" :src="qr_code"></el-image>
					</div>
					
				</el-dialog>
				</div>
		
	</div>
</template>

<script>
	import Pagination from '@/components/Pagination.vue'
	import qrcodeApi from '../../../../../../api/cus/qrcode.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			Pagination,
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				formInline:{
					name:"",
					pageno:1,
					pagesize:10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
				loading: false,
				scanData:[
					
				],
				showewm:false,
				qr_code:'',
				showmenu:true
			}
		},
		mounted() {
			let screenWidth = window.innerWidth
			console.log(screenWidth,'this.screenWidth');
			if(screenWidth <= 750){
				this.showmenu = false
			}else{
				this.showmenu = true
			}
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist(this.formInline)
			},
			getlist(page){
				let param = {
					mpid:this.mpid,
					name:page.name,
					pageno:page.pageno,
					pagesize:page.pagesize
				}
				qrcodeApi.qunlist(param,res=>{
					console.log(res,'reslist');
					if(res.code == 200){
						this.scanData = res.data
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.getlist(this.formInline)
			},
			search(){
				this.formInline.page = 1
				this.getlist(this.formInline)
			},
			funPreview(row){
				this.$router.push({
					path:"/published/groupqr/groupqr",
					query:{
						mpid:this.mpid,
						mxid:row.id
					}
				})
			},
			handleEdit(index,row){
				this.$router.push({
					path:"/customer/home/mp/weixinqiye/qrcode/info",
					query:{
						mpid:this.mpid,
						mxid:row.id
					}
				})
			},
			handleqr(index,row){
				let param = {
					mxid:row.id,
				}
				qrcodeApi.getqr(param,res=>{
					console.log(res,'reshandleqr');
					if(res.code == 200){
						
						this.showewm = true
						this.qr_code = res.data
					}else{
						this.$message({
							type:'error',
							message:res.msg
						})
					}
				})
			},
			deleteqr(index,row){
				let param = {
					mxid:row.id,
				}
				qrcodeApi.delete(param,res=>{
					console.log(res,'resdelete');
					if(res.code == 200){
						this.$message({
							type:'success',
							message:res.msg
						})
						this.getlist(this.formInline)
					}else{
						this.$message({
							type:'error',
							message:res.msg
						})
					}
				})
			},
			funAdd(){
				this.$router.push({
					path:"/customer/home/mp/weixinqiye/qrcode/info",
					query:{
						mpid:this.mpid
					}
				})
			}
		}
	}
</script>

<style scoped>
	.clearfix{
		display: flex;
		align-items: center;
	}
	.page{
	
	background:#F5F7FA;
		
	}
	.rightbox{
		padding-top:20px;
		width: 1320px;
		overflow: auto;
		margin: 0 auto;
	}
	.pagecontent{
		display: flex;
		height: calc(100vh - 81px);
	}
	.box-card {
		width: 1038px;
	}
	@media screen and (max-width: 414px) {
		.box-card {
			margin-bottom: 10px;
			width:340px;
		}
		.rightbox{
			padding-top:20px;
			margin: 0 auto;
			width: 100%;
		}
		.box-card{
			margin: 10px auto;
		}
		.pagecontent{
			display: flex;
			width: 100%;
			flex-direction: column;
			overflow: auto;
		}
	}
</style>