import httpapi from '../../utils/httpapi';
const welcomeApi = {
	//微信小程序名称设置
	add(param, funsucc) {
		httpapi.postjson('/customer/qiyeweixin/msg/welgroup/msgedit', param).then(res => {
			console.log(res, 'send.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	list(mpid, funsucc) {
		httpapi.get('/customer/qiyeweixin/msg/welgroup/list?mpid=' + mpid).then(res => {
			console.log(res, 'send.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	
	detail(id, funsucc) {
		httpapi.get('/customer/qiyeweixin/msg/welgroup/msgdetail?id=' + id).then(res => {
			console.log(res, 'send.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	del(param, funsucc) {
		httpapi.post('/customer/qiyeweixin/msg/welgroup/msgdel',param).then(res => {
			console.log(res, 'send.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	submit(param,funsucc){
		httpapi.post('/customer/qiyeweixin/msg/welgroup/submit',param).then(res => {
			console.log(res, 'send.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
			
		})
	}
	
}
export default welcomeApi