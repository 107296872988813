import httpapi from '../../utils/httpapi';
const licenseApi = {
	//授权码订单列表
	list(mpid,funsucc) {
		httpapi.get('customer/qiyeweixin/license/order/list?mpid=' + mpid, {}).then(res => {
			console.log(res, 'list.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({

		})
	},
	//授权码订单购买创建订单
	buynew(param,funsucc) {
		httpapi.post('customer/qiyeweixin/license/order/buynew', param).then(res => {
			console.log(res, 'buynew.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//获取企业授权详情
	getdetail(param,funsucc) {
		httpapi.get('customer/qiyeweixin/license/order/detail?mpid=' + param.mpid + '&billno=' + param.billno, {}).then(res => {
			console.log(res, 'getdetail.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//员工激活/授权
	active(param,funsucc) {
		httpapi.get('customer/qiyeweixin/license/license/active?mpid=' + param.mpid + '&manid=' + param.manid + '&order_mxid=' + param.order_mxid, {}).then(res => {
			console.log(res, 'active.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//授权码查询（全部）
	getall(param,funsucc) {
		httpapi.get('customer/qiyeweixin/license/license/list/all?mpid=' + param.mpid).then(res => {
			console.log(res, 'getall.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
	//员工授权账号继承
	gettransfer(param,funsucc) {
		httpapi.get('customer/qiyeweixin/license/license/transfer?mpid=' + param.mpid + '&hand_manid=' + param.hand_manid + '&take_manid=' + param.take_manid).then(res => {
			console.log(res, 'gettransfer.res');
			if (funsucc) {
				funsucc(res)
			}
		}).catch({
	
		})
	},
}
export default licenseApi