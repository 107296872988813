<template>
	<div class="content">
		<el-button size="small" @click="funreg">创建模版</el-button>
		<div class="list">
			<div class="item" v-for="(item,index) in list" :key="index" @click="funitem(item)">
				<div class="logo"> <img :src="item.logo" /> </div>
				<div class="title">{{ item.tmpname }}</div>
				<div class="title">{{ item.createDate }}</div>
				<div class="edit">
					<el-dropdown @command="funMenu($event,item)">
						<span class="el-dropdown-link">
							<i class="el-icon-s-operation"></i>
						</span>
						<el-dropdown-menu slot="dropdown">
							<el-dropdown-item :command="'name'">重命名</el-dropdown-item>
							<el-dropdown-item :command="'logo'">更改封面</el-dropdown-item>
							<el-dropdown-item :command="'down'">下架</el-dropdown-item>
							<el-dropdown-item :command="'add'">上架申请</el-dropdown-item>
							<el-dropdown-item :command="'copy'">复制副本</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="sjx" :class="item.onlinestate == 2 ? 'sel' : 'nol'">
					{{item.onlinename}}
				</div>
			</div>
		</div>
		<div style="text-align: center;">
			<!-- 分页组件 -->
			<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
		</div>
		<!-- 服务商界面 -->
		<el-dialog :title="title" :visible.sync="addShow" width="40%" @click='addShow = false'>
			<el-form label-width="150px" ref="editForm" :model="editForm">
				<el-form-item label="样式" prop="tmpname">
					<div class="cont">
						<div class="boxs" v-for="(item,index) in styleList" :key="index">
							<div :style="{background:item.style_color}"
								:class="item.style_id==style_id?'curr' : 'cusm'" class="item_cont"
								style="border-radius: 100%;">
								<div class="items" :style="{background:item.subject1_bg_color}"
									@click="funselStyle(item)">
									<i class="el-icon-check" v-if="item.style_id==style_id" style="color: #fff;"></i>
								</div>

							</div>
							<!-- <div class="stylemc" :style="{color:item.style_id==style_id?item.subject1_bg_color : ''}">
								{{item.style_name}}
							</div> -->

						</div>
					</div>
					<!-- <div style="text-align: right;" @click="openflag">{{flag ?'收起':'展开'}}</div> -->

				</el-form-item>
				<el-form-item label="模板名称" prop="tmpname">
					<el-input size="small" v-model="editForm.tmpname" auto-complete="off"
						placeholder="请输入模板名称"></el-input>
				</el-form-item>
				<el-form-item label="说明" prop="memo">
					<el-input size="small" v-model="editForm.memo" auto-complete="off" placeholder="请输入说明"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='addShow = false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title" @click="funtempcreate()">保存
				</el-button>
			</div>
		</el-dialog>
		<el-dialog :title="uptitle" :visible.sync="editShow" width="40%" @click='editShow = false'>
			<el-form label-width="150px" ref="from" :model="from">
				<el-form-item label="模板名称" prop="tmpname" v-if="uptitle == '重命名'">
					<el-input size="small" v-model="from.name" auto-complete="off" placeholder="请输入模板名称"></el-input>
				</el-form-item>
				<el-form-item label="模版logo" prop="logo" v-else>
					<div class="logo">
						<el-image :src="from.logo"></el-image>
					</div>
					<el-button size="small" type="primary" @click="funupload">上传</el-button>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer" v-if="uptitle == '重命名'">
				<el-button size="small" @click='editShow = false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title" @click="funtempmcSave()">保存
				</el-button>
			</div>
			<div slot="footer" class="dialog-footer" v-else>
				<el-button size="small" @click='editShow = false'>取消</el-button>
				<el-button size="small" type="primary" @click="funupsave">保存</el-button>
			</div>

		</el-dialog>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>
<script>
	import QRCode from "qrcode";
	import styleApi from '../../../api/isv/style.js'
	import designTemplataApi from "../../../api/isv/designtmplate.js";
	import dragBox from "@/components/applet/toolbox/dragbox.vue";
	import selImgs from "@/components/designer/selImgs";
	import Pagination from '@/components/Pagination.vue'
	import {getloginuser} from '../../../utils/util.js'
	export default {
		components: {
			selImgs,
			dragBox,
			Pagination
		},
		data() {
			return {
				list: [],
				styleList: [],
				options1: {
					left: 0,
					top: 0,
					width: 100,
					height: 100
				},
				options2: {
					left: 50,
					top: 50,
					width: 100,
					height: 100
				},
				editForm: {
					memo: "",
					tmpname: ""
				},
				from: {
					name: '',
					logo: ''
				},
				title: "注册模板",
				uptitle: "",
				style_id: "",
				loading: false,
				addShow: false,
				ispermissions: true,
				flag: false,
				editShow: false,
				templateid: "",
				formInline: {
					name: "",
					pageno: 1,
					pagesize: 10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
			}
		},
		computed: {
			// slist() {
			// 	if (this.flag == false) {
			// 		var list = [];
			// 		if (this.styleList.length > 10) {
			// 			for (var i = 0; i < 10; i++) {
			// 				list.push(this.styleList[i])
			// 			}
			// 		} else {
			// 			list = this.styleList
			// 		}
			// 		return list;
			// 	} else {
			// 		return this.styleList;
			// 	}
			// }
		},
		mounted() {
			let u = getloginuser()
			// let u = sessionStorage.getItem('designeruser')
			console.log(u, 'designeruser.u');
			if (u != null) {
				this.userInfo = u;
				console.log(this.userInfo, 'this.userInfo');
			} else {
				this.$router.push("/");
			}
			this.initdata(this.formInline);
		},
		methods: {
			initdata: function(page) {

				console.log('initdata')
				designTemplataApi.getUserTemplate({
					pageno: page.pageno,
					pagesize: page.pagesize
				}, res => {
					console.log(res, 'designTemplataApi.res');
					if (res.code == 200) {
						this.list = res.data.list
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					}
				})
			},
			getStyle() {
				styleApi.list({}, res => {
					console.log(res, 'styleApi.res');
					if (res.code == 200) {
						this.styleList = res.data
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.initdata(this.formInline)
			},
			openflag() {
				this.flag = !this.flag
			},
			funupload() {
				this.$refs.selImgs.funShow();
			},
			selImgsresult(imgitem) {
				this.from.logo = imgitem.url;
			},
			funMenu(type, item) {
				console.log(item, 'item');
				switch (type) {
					case 'name':
						this.editShow = true
						this.templateid = item.tmpid
						this.uptitle = '重命名'
						break;
					case 'logo':
						this.editShow = true
						this.templateid = item.tmpid
						this.uptitle = '修改封面'
						break;
					case 'down':
						this.funDown(item.tmpid)
						break;
					case 'add':
						this.funPut(item.tmpid)
						break;
					case 'copy':
						this.funCopy(item.tmpid)
						break;
					default:
						break;
				}
			},
			funtempmcSave() {
				let param = {
					tmpid: this.templateid,
					name: this.from.name,
				};
				designTemplataApi.editname(param, (res) => {
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg,
						});
						this.editShow = false
						this.initdata(this.formInline);
					} else {
						this.$message({
							type: "error",
							message: res.msg,
						});
					}
				});
			},
			funupsave() {
				let param = {
					tmpid: this.templateid,
					logo: this.from.logo,
				};
				designTemplataApi.editlogo(param, (res) => {
					console.log(res, 'editlogo.res');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: res.msg,
						});
						this.editShow = false
						this.initdata(this.formInline);
					} else {
						this.$message({
							type: "error",
							message: res.msg,
						});
					}
				});
			},
			funDown(tempid) {
				this.$confirm('是否下架此模版, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						id: tempid
					}
					console.log(param, 'param');
					designTemplataApi.tempdown(param, res => {
						console.log(res, 'tempdown.res');
						if (res.code == 200) {
							this.initdata(this.formInline);
							this.$message({
								type: "success",
								message: "下架成功"
							})
						}
					})
				}).catch(() => {

				});
			},
			funPut(tempid) {
				this.$confirm('是否上架此模版, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						id: tempid
					}
					console.log(param, 'param');
					designTemplataApi.tempup(param, res => {
						console.log(res, 'tempcopy.res');
						if (res.code == 200) {
							this.initdata(this.formInline);
							this.$message({
								type: "success",
								message: "上架成功"
							})
						}
					})
				}).catch(() => {

				});
			},
			funCopy(tempid) {
				this.$confirm('是否拷贝此模版, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						tempid: tempid
					}
					console.log(param, 'param');
					designTemplataApi.tempcopy(param, res => {
						console.log(res, 'tempcopy.res');
						if (res.code == 200) {
							this.initdata(this.formInline);
							this.$message({
								type: "success",
								message: "拷贝成功"
							})
						}else{
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					})
				}).catch(() => {
						console.log(111)
				});
			},
			funselStyle(item) {
				this.style_id = item.style_id
			},
			funreg: function() {
				this.addShow = true
				this.getStyle()
			},
			funtempcreate() {
				let param = {
					memo: this.editForm.memo,
					style_id: this.style_id,
					tmpname: this.editForm.tmpname
				}
				console.log(param, 'param');
				designTemplataApi.tempcreate(param, res => {
					console.log(res, 'tempcreate.res');
					if (res.code == 200) {
						this.addShow = false
						this.initdata(this.formInline);
					}
				})
			},
			funitem: function(item) {
				let id = item.tmpid
				console.log(item, 'item');
				if (item.onlinestate == 2) {
					this.$message({
						type: "error",
						message: "此模版已上架，请下架后进行设计"
					})
					return
				}
				this.$router.push({
					path: `/designer/xcxtemplate/design?tid=${id}`
				})
			},
			funtempbase() {
				this.$router.push({
					path: '/designer/xcxtemplate/templatebase'
				})
			}
		}
	}
</script>
<style lang="less" scoped>
	.content {
		width: 90%;
		min-width: 1200px;
		margin: 0 auto;
	}

	.list {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		position: relative;
	}

	.list .edit {
		position: absolute;
		right: 0;
		top: 0;
		padding-right: 10px;
	}

	.list .sjx {
		position: absolute;
		left: 10px;
		top: 2px;
		font-size: 12px;
	}

	.list .sel {
		color: green;
	}

	.list .nol {
		color: red;
	}

	.item {
		text-align: center;
		box-shadow: 0 2px 6px #ccc;
		padding: 20px;
		margin: 20px 20px 0 0;
		position: relative;

		&:hover {
			box-shadow: 0 2px 6px #2865e0;
			border-radius: 20px;
		}

		.logo {
			width: 200px;
			height: 350px;
			overflow: hidden;

			img {
				width: 100%;
			}
		}
	}

	.boxs {
		display: flex;
		align-items: center;
		flex-direction: column;
		flex-wrap: wrap;
		position: relative;
	}

	.boxs .stylemc {
		white-space: nowrap;
	}

	.boxs .items {
		cursor: pointer;
		width: 30px;
		height: 30px;
		font-size: 10px;
		line-height: 30px;
		text-align: center;
		border-radius: 100%;
	}

	.curr {
		border: 3px solid #55aaff;
	}

	.cusm {
		border: 3px solid #fff;
	}

	.cont {
		overflow-y: auto;
		display: flex;
		flex-wrap: wrap;
	}
</style>