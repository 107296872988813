import httpapi from '../../utils/httpapi';
const logsApi = {
  list(param,funsucc) {
    httpapi.post('/system/mp/log/list',param).then(res => {
      if (funsucc) {
        funsucc(res)
      }
    }).catch({

    })

  },
  


}
export default logsApi
