<template>
  <!-- Banner & Image 通用组件 -->
  <div class="image-content">
    <p class="desc">添加图片 (最多{{ len }}张，可拖动排序）</p>
    <vuedraggable
      v-model="list.data"
      tag="ul"
      draggable="li"
      v-if="list.data && list.data.length > 0"
      class="image-list"
      :class="{ disable: data.tabType == 2 }"
    >
      <li v-for="(item, index) in list.data" :key="index">
        <div class="l-info">
			<div class="">
				标题：<el-input type="text" size="small" v-model="item.title" style="width: 150px;"></el-input>
			</div>
      <div class="list">
		  <div class="title">
		    <span>宽高 </span>
		  </div>
		  <div class="btn2 full">
        <div class="flex-row">
          <el-input v-model="item.width" style="width: 100px;"></el-input>
       <div style="width: 30px;"> x</div>
		    <el-input v-model="item.height" style="width: 100px;"></el-input>
        </div>
       
		  </div>
      </div>
      <div class="list">
		  <div class="title">
		    <span>位置 </span>
		  </div>
		  <div class="btn2 full">
        <div class="flex-row">
          <el-input v-model="item.left" style="width: 100px;"></el-input>
       <div style="width: 30px;"> x</div>
		    <el-input v-model="item.top" style="width: 100px;"></el-input>
        </div>
       
		  </div>
      </div>

      <div class="list">
        <div class="title">
          <span>文字上边距 </span>
        </div>
        <div class="btn2 full">
          <el-slider v-model="item.margintop" show-input :max="200"> 
          </el-slider>
        </div>
      </div>

          <p>
            <span>名称：</span>
            <span class="text">{{ item && item.name }}</span>
          </p>
          <p>
            <span>链接：</span>
            <el-tooltip
              effect="dark"
              :content="item.link"
              placement="top"
              v-if="item.link"
            >
              <span class="text" @click="urlPopup(index, item.link)">{{
                item.link
              }}</span>
            </el-tooltip>
            <span v-else @click="urlPopup(index)" class="link"
              >请输入跳转链接</span
            >
          </p>
        </div>
        <div class="r-image">
          <span @click="removeImage(index)" class="el-icon-close"></span>
          <div class="image-box">
            <img :src="item && item.url" />
            <span @click="addImage(index)" class="el-icon-edit-outline"></span>
          </div>
        </div>
      </li>
    </vuedraggable>
    <template v-if="list.data && list.data.length < len">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="addImage(null)"
        class="add-image"
      >
        添加图片
      </el-button>
      <p class="size">（建议尺寸：{{ size }}）</p>
    </template>
    <el-upload
      ref="upload"
      :http-request="upload"
      :show-file-list="false"
      multiple
      action
      style="display: none"
    >
    </el-upload>
    <el-dialog title="请填写图片跳转链接" :visible.sync="show" @close="close">
      <el-form label-width="100px">
        <el-form-item label="跳转链接">
          <el-input v-model="url"></el-input>
        </el-form-item>
        <el-form-item label="">
          <el-button type="primary" @click="confirm">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--属性设置-->
    <div class="details">
      <div class="list">
		  <div class="title">
		    <span>宽高 </span>
		  </div>
		  <div class="btn2 full">
        <div class="flex-row">
          <el-input v-model="list.options.width" style="width: 100px;"></el-input>
       <div style="width: 30px;"> x</div>
		    <el-input v-model="list.options.height" style="width: 100px;"></el-input>
        </div>
       
		  </div>
		</div>
    <div class="list">
		  <div class="title">
		    <span>背景图片 </span>
		  </div>
		  <div class="btn2 full">
		    <el-button
        type="primary"
        @click="addImage('bg')"
      >
        选择图片
      </el-button>
		  </div>
		</div>

     <!-- 背景颜色 -->
     <div class="list">
        <div class="title"><span>背景颜色</span>{{list.options.bgcolor||'默认' }}<span></span></div>
        <div class="btn">
          <el-button type="text" class="reset" @click="reset('bgcolor')">重置</el-button
          ><el-color-picker v-model="list.options.bgcolor" size="medium"></el-color-picker>
        </div>
      </div>
      <!-- 标题文字大小 -->
      <div class="list">
        <div class="title">
          <span>标题大小 </span>
          <span
            >{{
              list.options.size == 1
                ? "大(16号)"
                : list.options.size == 2
                ? "中(14号)"
                : "小(12号)"
            }}
          </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.size" @change="size">
            <el-tooltip
              class="item"
              effect="dark"
              content="大(16号)"
              placement="bottom"
            >
              <el-radio-button :label="1"><div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div></el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="中(14号)"
              placement="bottom"
            >
              <el-radio-button :label="2"><div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div></el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="小(12号)"
              placement="bottom"
            >
              <el-radio-button :label="3"><div style="font-size: 12px;width: 11px;height:16px;line-height: 16px;"> A</div></el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>
      
       <!-- 是否文字加粗 -->
       <div class="list">
        <div class="title">
          <span>标题粗细 </span>
          <span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
        </div>
        <div class="btn">
          <el-radio-group v-model="list.options.bold">
            <el-tooltip
              class="item"
              effect="dark"
              content="常规体"
              placement="bottom"
            >
              <el-radio-button :label="1"
                ><i class="el-icon-s-fold"></i
              ></el-radio-button>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="加粗体"
              placement="bottom"
            >
              <el-radio-button :label="2">
                <i class="el-icon-s-operation"></i>
              </el-radio-button>
            </el-tooltip>
          </el-radio-group>
        </div>
      </div>

      <!-- 标题文字颜色 -->
      <div class="list">
        <div class="title"><span>标题颜色</span>{{list.options.titlecolor||'默认' }}<span></span></div>
        <div class="btn">
          <el-button type="text" class="reset" @click="reset('titlecolor')">重置</el-button
          ><el-color-picker v-model="list.options.titlecolor" size="medium"></el-color-picker>
        </div>
      </div>

      <!-- 圆角 -->
      <div class="list">
        <div class="title">
          <span>圆角 </span>
        </div>
        <div class="btn2 full">
          <el-slider v-model="list.options.borderradius" show-input :max="800"> 
          </el-slider>
        </div>
      </div>

      <div class="list">
        <div class="title">
          <span>上边距 </span>
        </div>
        <div class="btn2 full">
          <el-slider v-model="list.options.margintop" show-input :max="200"> 
          </el-slider>
        </div>
      </div>
      <div class="list">
        <div class="title">
          <span>下边距 </span>
        </div>
        <div class="btn2 full">
          <el-slider v-model="list.options.marginbottom" show-input :max="200"> 
          </el-slider>
        </div>
      </div>

      <div class="list">
        <div class="title">
          <span>页边距 </span>
        </div>
        <div class="btn2 full">
          <el-slider v-model="list.options.marginpage" show-input :max="200"> 
          </el-slider>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vuedraggable from "vuedraggable";
export default {
	name: "foot_nav_cube_edit",
  components: {
    vuedraggable,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: {},
      url: "",
      index: null,
      show: false,
      imageIndex: null,
    };
  },
  computed: {
    size() {
      return this.list.type == "images" ? "750*750" : "750*400";
    },
    len() {
      return this.list.type == "images" ? 8 : 10;
    },
  },
  created() {
    this.list = this.data;
  },
  methods: {
    close() {
      this.show = false;
      this.url = "";
    },
    reset(key){
      this.$delete(this.list.options,key)
    },
    confirm() {
      this.list["data"][this.index]["link"] = this.url;
      this.close();
    },
    urlPopup(index) {
      this.show = true;
      this.index = index;
      this.url = link;
    },
    removeImage(index) {
      this.list.data.splice(index, 1);
    },
    addImage(index) {
      this.imageIndex = index;
      this.$refs["upload"].$children[0].$refs.input.click();
    },
    upload(params) {
      if(this.imageIndex == 'bg'){
        this.uploadbg(params);
      }
      else{
        this.uploadItem(params);
      }
    },
    uploadbg(params){
      const file = params.file,
        fileType = file.type;

      const verifyList = [
        {
          text: "只能上传图片格式png、jpg、gif!",
          result: fileType.indexOf("image") != -1,
        },
        {
          text: "只能上传大小小于5M",
          result: file.size / 1024 / 1024 < 5,
        },
      ];

      for (let item of verifyList) {
        if (!item.result) {
          this.$message.error(item.text);
          return;
        }
      }

      const form = new FormData();
      form.append("file", file);
      form.append("clientType", "multipart/form-data");
     
      this.list.options.bgimg = URL.createObjectURL(file);
      this.list.options.bgimgform =form
      //this.$set(this.list.options.backgroundform, form);
    },

    uploadItem(params){
      const len = this.list.data.length;

      if (len >= this.len) {
        this.$message.error(`最多添加${this.len}张图片!`);
        return;
      }
      const file = params.file,
        fileType = file.type;

      const verifyList = [
        {
          text: "只能上传图片格式png、jpg、gif!",
          result: fileType.indexOf("image") != -1,
        },
        {
          text: "只能上传大小小于5M",
          result: file.size / 1024 / 1024 < 5,
        },
      ];

      for (let item of verifyList) {
        if (!item.result) {
          this.$message.error(item.text);
          return;
        }
      }

      const form = new FormData();
      form.append("file", file);
      form.append("clientType", "multipart/form-data");

      const index = this.imageIndex;
      

      if (index !== null) {
        let data = this.list.data[index];
        data.url = URL.createObjectURL(file);
        data.form = form;
        console.log(data,'data.url')
        this.$set(this.list.data, index, data);
      } else {
        const data = {
        name: file.name,
		title:'菜单',
        url: URL.createObjectURL(file),
        form:form,
        width:200,
        height:200,
        left:0,
        top:0,
        margintop:20
      };

        this.list.data.push(data);
      }
    }

  },
};
</script>

<style lang="less" scoped>
.image-content {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  overflow: hidden;
  .desc {
    text-align: center;
    font-size: 12px;
    color: #666;
    margin: 18px 0;
    padding-bottom: 10px;
    border-bottom: 1px dashed #ddd;
  }
  .size {
    text-align: center;
    font-size: 12px;
    color: #999;
    margin-bottom: 0;
  }
  .add-image {
    width: calc(100% - 20px);
    height: 34px;
    line-height: 34px;
    padding: 0;
    font-size: 12px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .image-list {
    margin: 0;
    padding: 0 10px;
    overflow: auto;
    &::-webkit-scrollbar-thumb {
      background: #dbdbdb;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background: #f6f6f6;
      border-radius: 10px;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    li {
      list-style: none;
      background: #f7f8f9;
      border-radius: 4px;
      padding: 6px 14px 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      &.disable div {
        pointer-events: none;
        user-select: none;
      }
      .l-info {
        font-size: 12px;
        padding-top: 8px;
        width: calc(100% - 70px);
        p {
          margin: 12px 0 0;
          white-space: nowrap;
          overflow: hidden;
          display: flex;
          .link {
            color: #1b8bff;
            cursor: pointer;
          }
          .text {
            white-space: nowrap;
            text-align: -webkit-auto;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
      .r-image {
        text-align: right;
        .el-icon-close {
          color: #999;
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 6px;
          cursor: pointer;
          &:hover {
            color: red;
          }
        }
        .image-box {
          width: 70px;
          height: 70px;
          border-radius: 5px;
          overflow: hidden;
          position: relative;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.5);
            opacity: 0;
            transition: all 0.3s;
          }
          span {
            position: absolute;
            top: 50%;
            left: 50%;
            color: #fff;
            transform: translate(-50%, -50%);
            font-size: 20px;
            cursor: pointer;
            opacity: 0;
            transition: all 0.3s;
          }
          img {
            max-width: 100%;
          }
          &:hover {
            &::before,
            span {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  .list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px;
  .title{
    margin-right: 10px;
    flex-shrink: 0;
  }
  .full{
    flex-grow: 1;
  }
  .btn {
  display: flex;
  align-items: center;
  
  .reset {
    margin-right: 10px;
  }
}
  .flex-row{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

}
</style>