/**
* 左边菜单
*/
<template>
	
	<div class="leftcontent">
		<div class="appinfo">
			<div class="">【微信公众号】{{appinfo.mpName}}<i class="el-icon-edit" @click="updatemc"></i></div>
			<div class="">{{appinfo.appID}}</div>
			<div class="aitem" style="display: flex;align-items: center;">
				<div :class="'zt' + appinfo.isOpen"></div>
				<div>
					{{appinfo.isOpen == 1 ? "已授权" : "未授权"}}
				</div>
			</div>
		</div>
		
		<div class="" style="padding: 0 20px;">
			<div v-for="(menu,cindex) in allmenu" class="clsitem" :class="menuid == menu.menuid ? 'active':'normal'"  :key="cindex">
				
				<!-- <img class="iconfont"  :src="menu.icon"/> -->
				   <router-link :to="menu.url + '?mpid=' + mpid"><span>{{menu.menuname}}</span></router-link>
				
			</div>
		</div>
		<!-- 服务商界面 -->
		<el-dialog :title="title" :visible.sync="mcShow" width="50%" @click='mcShow = false'>
			<el-form label-width="150px" ref="mcForm" :model="mcForm" >
				<el-form-item label="公众号名称" prop="mpname">
					<el-input size="small" v-model="mcForm.mpname" auto-complete="off"
						placeholder="请输入公众号名称"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='mcShow = false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title"
					@click="funmcAdd()">保存
				</el-button>
			</div>
		</el-dialog>
	</div>
	
</template>
<script>
	import accountApi from '../../../../../api/cus/account'
	// import svrappApi from '../../../api/cus/svrapp.js'
	export default {
		name: 'leftnav',
		data() {
			return {
				title:"修改应用名称",
				loading:false,
				mcShow:false,
				allmenu: [],
				appinfo: {},
				mpid: '',
				mcForm:{}
			}
		},
		props:{
			menuid:{
				type:String,
				value:'11' 
			}
		},
		created() {
			let res = {
				success: true,
				data: [{
						menuid: '11',
						icon: 'icon-news-manage',
						menuname: '总览',
						hasThird: 'N',
						url: '/customer/home/mp/weixingzh/alls/index',
						menus: null
					},
					{
						menuid: '12',
						icon: 'icon-news-manage',
						menuname: '开发设置',
						hasThird: 'N',
						url: '/customer/home/mp/weixingzh/set/index',
						menus: null
					},
					{
						menuid: '13',
						icon: 'icon-news-manage',
						menuname: '自动回复',
						hasThird: 'N',
						url: '/customer/home/mp/weixingzh/msg/wppmsg',
						menus: null
					},
					{
						menuid: '14',
						icon: 'icon-news-manage',
						menuname: '关键词回复',
						hasThird: 'N',
						url: '/customer/home/keyword/index',
						menus: null
					},
					{
						menuid: '15',
						icon: 'icon-news-manage',
						menuname: '图文列表',
						hasThird: 'N',
						url: '/customer/home/mp/weixingzh/imgmenu/imgmenulist',
						menus: null
					},
					{
						menuid: '16',
						icon: 'icon-news-manage',
						menuname: '自定义菜单',
						hasThird: 'N',
						url: '/customer/home/mp/weixingzh/custom/index',
						menus: null
					},
					{
						menuid: '17',
						icon: 'icon-news-manage',
						menuname: '模板消息',
						hasThird: 'N',
						url: '/customer/home/mp/weixingzh/template/index',
						menus: null
					},
					{
						menuid: '18',
						icon: 'icon-news-manage',
						menuname: '微信会员卡',
						hasThird: 'N',
						url: '/customer/home/mp/weixingzh/card/index',
						menus: null
					},
					// {
					//   menuid: 13,
					//   icon: 'icon-news-manage',
					//   menuname: '成员管理',
					//   hasThird: 'N',
					//   url: 'customer/home/auth/index',
					//   menus: null
					// },
					// {
					//   menuid: 14,
					//   icon: 'icon-news-manage',
					//   menuname: '开发设置',
					//   hasThird: 'N',
					//   url: 'customer/home/auth/index',
					//   menus: null
					// }

				],
				msg: 'success'
			}
			this.allmenu = res.data

		},
		mounted() {
			
			
			let mpid= this.$route.query.mpid
			console.log(mpid,'letnavmpid');
			this.mpid = mpid
			if (mpid != undefined) {
			} else {
				this.$router.push("/");
			}
			accountApi.info({
				mpid: this.mpid
			}, res => {
				this.appinfo = res.data
			})
		},
		methods: {
			updatemc(){
				this.mcShow = true
			},
			funmcAdd(){
				accountApi.updategzh({
					mpid: this.mpid,
					mpname:this.mcForm.mpname
				}, res => {
					this.mcShow = false
					if(res.code == 200){
						this.$message({
							type: 'success',
							message: '修改成功!'
						})
						this.appinfo.mpName = this.mcForm.mpname
					}else{
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
		}
	}
</script>
<style>
	.leftcontent{
		background-color: #ffffff;
		width:250px;
		
	}
	.leftcontent .clsitem{
		padding-left: 15px;
		height: 40px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content:flex-start;
		
	}
	
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		width: 240px;
		min-height: 400px;
		
	}
	
	
	.el-menu-vertical-demo:not(.el-menu--collapse) {
		border: none;
		text-align: left;
	}
	
	.el-menu-item-group__title {
		padding: 0px;
	}
	
	.el-menu-bg {
		
	}
	
	.el-menu {
		border: none;
	}
	
	.logobox {
		height: 40px;
		line-height: 40px;
		color: #9d9d9d;
		font-size: 20px;
		text-align: center;
		padding: 20px 0px;
	}
	
	.logoimg {
		height: 40px;
	}
	
	.appinfo {
		padding: 14px 38px ;
		line-height: 1.5;
		border-bottom: 1px solid #eee;
		color: #333;
		font-size: 12px;
		margin-bottom: 10px;
	}
	
	.appinfo .name {
		font-size: 14px;
		font-weight: 600;
	}
	
	.appinfo .aitem {
		margin-top: 6px;
		font-family: Source Han Sans SC, Source Han Sans SC;
		font-weight: 400;
		font-size: 12px;
		color: #636363;
	}
	
	.zt0 {
		width: 10px;
		height: 10px;
		border-radius: 10px;
		background-color: #ff0000;
		margin-right: 5px;
	}
	
	.zt1 {
		width: 10px;
		height: 10px;
		border-radius: 10px;
		background-color: #00aa00;
		margin-right: 5px;
	}
	.iconfont{
		width: 16px;
		height:16px;
	}
	.el-icon-edit {
		cursor: pointer;
	}
	.normal a ,.normal a:hover{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 14px;
		color: #464646;
	}
	.active{
		color:#00aaff;
		background:#E6E9F2;
		width: 90%;
		border-radius: 10px;
	}
	.active a ,.active a:hover{
		font-family: Source Han Sans CN, Source Han Sans CN;
		font-weight: 500;
		font-size: 14px;
		color: #00aaff;
	}
	
	@media screen and (max-width: 414px) {
		.el-menu-vertical-demo {
			width: 0;
		}
	}
</style>