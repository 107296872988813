<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="23"></leftnav>
			<div class="rightbox" style="margin-left:130px;">

				<el-card class="box-card">
					<div slot="header" class="clearfix">
						<span>二维码配置{{ruleForm.qrid.length > 0 ? '编辑' : '新增'}}</span>
					</div>
					<div style="margin-left: 50px;margin-bottom: 20px;color: #ff0000;">小程序appid，必须是关联到企业的小程序应用</div>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
						<el-form-item label="二维码名称" prop="title">
							<el-input v-model="ruleForm.title" placeholder="请输入二维码名称" size="medium"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="小程序类型" prop="title">
							<el-select v-model="ruleForm.apptype" size="medium" placeholder="请选择">
								<el-option v-for="item in appoption" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="小程序标题" prop="apptitle">
							<el-input v-model="ruleForm.apptitle" placeholder="请输入小程序标题" size="medium"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="" prop="">
							<div>你好，欢迎你光临[店名]，请扫码：[桌台]</div>
						</el-form-item>

						<el-form-item label="跳转页面" prop="title">
							<el-select v-model="ruleForm.apppage" size="medium" placeholder="请选择">
								<el-option v-for="item in topagetype" :key="item.value" :label="item.label"
									:value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否送券" prop="isticket">
							<el-switch v-model="ruleForm.isticket" active-color="#13ce66" inactive-color="#bababa">
							</el-switch>
						</el-form-item>


						<el-form-item label="选择礼券" prop="isticket" v-if="ruleForm.isticket == 1">
							<el-button @click="funselticket">选择礼券</el-button>
						</el-form-item>
						<el-form-item label="" prop="isticket"
							v-if="ruleForm.ticketjson.id.length > 0 && ruleForm.isticket == 1">
							<div class="ticket">
								<img class="timg" :src="ruleForm.ticketjson.logo" />
								<div class="right">
									<div class="title">{{ruleForm.ticketjson.title}}</div>
									<div>{{ruleForm.ticketjson.title2}}</div>
									<div class="memo">{{ruleForm.ticketjson.memo}}</div>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="" prop="isticket" v-if=" ruleForm.isticket == 1">

							<img v-if="ruleForm.adlogo != undefined && ruleForm.adlogo.length > 0"
								:src="ruleForm.adlogo" style="width: 100px;margin-right: 20px;" />
							<el-button type="primary" @click="funupadlogo('adlogo')">上传礼券图片</el-button>


						</el-form-item>
						<el-form-item label="小程序封面图" prop="title">
							<div v-if="ruleForm.appmedia_url != undefined && ruleForm.appmedia_url.length > 0"
								style="display: flex;">
								<div>
									<img :src="ruleForm.appmedia_url" style="width: 100px;" />
								</div>
								<el-button type="primary" style="height: 30px;margin-left: 20px;"
									@click="funupappbgimg">重新上传</el-button>
							</div>

							<el-upload class="upload-demo" ref="upload" drag :action="uploadappurl"
								:on-success="successfile" :headers="headers" :data="importData" :limit="1" multiple
								style="margin-top: 20px;" v-else>
								<i class="el-icon-upload"></i>
								<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
								<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>

							</el-upload>
						</el-form-item>
						<el-form-item label="企微背景图" prop="title">
							<div>
								<img :src="ruleForm.bgurl"
									v-if="ruleForm.bgurl != undefined && ruleForm.bgurl.length > 0"
									class="qwbgimg"></img>
								<el-button type="primary" @click="funupqwbgimg('qwbgimg')"
									style="margin-left: 20px;">上传背景图</el-button>
							</div>

						</el-form-item>

						<el-form-item label="企微按钮图" prop="title">
							<img :src="ruleForm.btnurl" v-if="ruleForm.bgurl != undefined && ruleForm.bgurl.length > 0"
								class="qwbgimg"></img>
							<el-button type="primary" @click="funupqwbtnimg('qwbtnimg')"
								style="margin-left: 20px;">上传按钮图</el-button>
						</el-form-item>

						<el-form-item label="小程序路径" prop="title" v-if="ruleForm.apptype == 'diy'">
							<el-input v-model="ruleForm.apppath" placeholder="请输入二维码名称" size="medium"
								style="width: 300px;"></el-input>
						</el-form-item>
						<el-form-item label="小程序APPID" prop="title" v-if="ruleForm.apptype == 'diy'">
							<el-input v-model="ruleForm.appid" placeholder="请输入APPID" size="medium"
								style="width: 300px;"></el-input>
						</el-form-item>


						<el-form-item label="是否需要验证" prop="isverify">
							<el-switch v-model="ruleForm.isverify" active-color="#13ce66" inactive-color="#ff4949">
							</el-switch>
						</el-form-item>
						<el-form-item label="使用成员" prop="manjson" v-if="!ruleForm.istemp">
							<el-select style="margin-bottom: 20px;margin-right: 20px;" v-model="ruleForm.manjson"
								multiple filterable allow-create default-first-option size="medium" placeholder="请选择成员">
								<el-option v-for="item in manData" :key="item.id" :label="item.manname"
									:value="item.id">
									<span style="float: left">{{ item.manname }}</span>
									<span style="  font-size: 13px ;margin-right: 5px;margin-left: 15px;" :class="'auth' + item.islinkauth">{{ item.islinkauth  == 1 ? '微信授权' : '--'}}</span>
									<span style="  font-size: 13px" :class="'auth' + item.isbaseauth">{{ item.isbaseauth  == 1 ? '基础授权' : '--'}}</span>
								</el-option>
							</el-select>
							<el-button type="primary" size="medium" @click="funAddPerson">添加</el-button>
							<el-table :data="personData" stripe style="width: 100%" border>
								<el-table-column prop="name" label="名称" width="180">
								</el-table-column>
								<!-- <el-table-column prop="manno" label="名称" width="180">
								</el-table-column> -->
								<!-- <el-table-column prop="num" label="客户上限" width="180">
									<template slot-scope="scope">
										<el-input-number v-model="scope.row.num" :min="1" :max="10"
											label="请输入客户上限"></el-input-number>
									</template>
								</el-table-column> -->
								<el-table-column label="操作">
									<template slot-scope="scope">
										<el-button type="text" size="medium"
											@click="fundelperson(scope.$index)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item>
						<el-form-item label="使用成员" prop="grouptip" v-else="ruleForm.istemp">
							<el-select style="margin-bottom: 20px;margin-right: 20px;" v-model="ruleForm.manjson"
								size="medium" placeholder="请选择成员" @change="funselPerson">
								<el-option v-for="item in manData" :key="item.id" :label="item.manname"
									:value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="入群引导语" prop="grouptip">
							<el-input type="textarea" rows="5" v-model="ruleForm.grouptip" placeholder="请输入入群引导语"
								maxlength="260" show-word-limit></el-input>
							<div class="addaffix">
								<div class="list">
									<div class="list-item" v-for="(item,index) in ruleForm.welcomejson" :key="index">
										<div style="display: flex;" @click.stop="funeditfix(item)">
											<div v-if="item.msgtype == 'image'">【图片】</div>
											<div v-if="item.msgtype == 'link'">【链接】</div>
											<div v-if="item.msgtype == 'miniprogram'">【小程序】</div>
											<span>{{item.title}}</span>
										</div>

										<!-- <img :src="item" style="width: 50px;"> -->
										<div class="del" @click.stop="fundelaffix(index)">
											删除
										</div>
									</div>
								</div>
								<span @click="funAddaffix">添加附件</span>
							</div>
						</el-form-item>
						<!-- <el-form-item label="选择群聊" prop="xcxurl">
							<p>1、可添加多个群聊，当前面的群人数达到上限后，自动发送后面的群二维码</p>
							<p>2、群人数上限最多为200人</p>
							<p>3、上传群二维码时请【仔细核对】，群二维码和群聊【对应错误】将导致【拉群任务失败】</p>
							<el-button type="primary" @click="funaddewm('ruleForm')">添加</el-button>
							<el-table :data="groupsData" border style="width: 100%">
								<el-table-column prop="pic" label="群活码图片" width="180">
									<template slot-scope="scope">
										<img :src="scope.row.pic" style="width: 80px;height: 80px;" />
									</template>
								</el-table-column>
								<el-table-column prop="chatname" label="群名称">
								</el-table-column>
								<el-table-column fixed="right" label="操作" width="100">
									<template slot-scope="scope">
										<el-button type="text" size="small" @click="fundelgroup(scope.$index)">删除</el-button>
									</template>
								</el-table-column>
							</el-table>
						</el-form-item> -->
						<el-form-item>
							<div>
								<div class="btnbox">
									<el-button style="width: 180px;" type="primary" size="medium"
										@click="submitForm('ruleForm')">保存配置</el-button>
									<el-button style="width: 160px" size="medium" @click="funback">返回</el-button>
								</div>

							</div>

						</el-form-item>
					</el-form>
				</el-card>
				<el-dialog :visible.sync="groupVisible" width="30%" @click='groupVisible=false'>
					<el-form label-width="150px">
						<el-select v-model="ruleForm.groupchatjson" filterable size="medium" placeholder="请选择群聊">
							<el-option v-for="item in groupData" :key="item.id" :label="item.chat_name"
								:value="item.id">
							</el-option>
						</el-select>
						<!-- <el-form-item label="人数" prop="number">
							<el-input size="small" v-model="ewmForm.number" auto-complete="off" placeholder="请输入人数"></el-input>
						</el-form-item> -->
					</el-form>
					<div slot="footer" class="dialog-footer">
						<el-button size="medium" type="primary" class="title" @click="funAddgroup()">确定
						</el-button>
					</div>
				</el-dialog>

				<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
				<selaffix ref="selaffix" @selaffixresult="selaffixresult"></selaffix>
				<ticket ref="ticket" @ticketresult="ticketresult"></ticket>
			</div>
		</div>

	</div>
</template>

<script>
	import selImgs from "@/components/customer/selImgs";
	import selaffix from "@/components/customer/selaffix";
	import personApi from '../../../../../../api/cus/person.js';
	import groupApi from '../../../../../../api/cus/group.js'
	import qrcodeApi from '../../../../../../api/cus/qrcode.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	import deskqrcodeApi from "../../../../../../api/cus/deskqrcode.js";
	import ticket from '../../../../../../components/customer/selticket.vue'
	import {
		getloginuser
	} from "../../../../../../utils/util.js";
	export default {
		components: {
			selImgs,
			selaffix,
			leftnav,
			navcon,
			ticket
		},
		data() {
			return {
				mpid: "",
				mxid: "",
				manData: [],
				personData: [],
				groupData: [],
				groupsData: [],
				ruleForm: {
					title: '',
					appid: '',
					memo: '',
					scene: 2,
					isverify: 0,
					shopid: '',
					grouptip: '你好，我是XXXX客服，欢迎扫码进群，享受更多福利',
					welcomejson: [],
					manjson: [],
					groupchatjson: [],
					qrid: '',
					apptype: '',
					apppage: '',
					apppath: '',
					btnurl: '',
					bgurl: '',
					appmedia_url: '',
					appmedia_resid: '',
					isticket: 0,
					ticketjson: {
						title: '',
						title2: '',
						logo: '',
						id: '',
						num: 1,
						memo: ''
					},
					apptitle: '',
					adlogo: ''
				},
				headers: {
					Authorization: ''
				},
				uploadappurl: '',
				importData: {
					name: '图片',
					mpid: 0
				},
				isedit: false,
				groupVisible: false,
				rules: {},
				appoption: [{
					value: 'dxjbclub',
					label: '大新酒吧点单'
				}, {
					value: 'yyjbclub',
					label: '夜莺酒吧点单'
				}, {
					value: 'diy',
					label: '自定义'
				}],
				topagetype: [{
						value: 'index',
						label: '首页'
					},
					{
						value: 'diandan',
						label: '点单'
					}
				],
				ticketlist: []
			};
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				let shopid = this.$route.query.shopid
				this.ruleForm.mchno = this.$route.query.mchno
				this.ruleForm.mchshopid = this.$route.query.mchshopid
				this.ruleForm.mchshopname = this.$route.query.mchshopname
				this.ruleForm.mchshopno = this.$route.query.mchshopno
				this.uploadappurl = '/api/customer/file/weixinwork/uploadimgtmp'
				this.importData.mpid = this.$route.query.mpid
				let u = getloginuser()
				if (u != null) {
					this.headers.Authorization = 'Bearer ' + u.token
				}
				console.log(shopid, 'shopid');
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				if (shopid != undefined) {
					this.ruleForm.shopid = shopid

					this.getdetail()

				}
				this.getlist()
				this.getgrouplist()
			},
			getlist(page) {
				let param = {
					mpid: this.mpid,
					pageno: 1,
					pagesize: 100,
				}
				this.loading = true
				personApi.list(param, res => {
					console.log(res, 'getmanlist.res');
					if (res.code == 200) {
						this.loading = false
						res.data.list.forEach(item => {
							this.manData.push({
								id: item.id,
								manno:item.manno,
								manname: item.manname,
								islinkauth: item.islinkauth,
								isbaseauth: item.isbaseauth
							})
						})

					}
				})
			},
			funselticket() {
				let param = {
					mpid: this.mpid,
					shopid: this.ruleForm.shopid
				}
				this.$refs['ticket'].funshow(param)
			},
			ticketresult(res) {
				console.log(res, 'selticket.res');
				if (res != undefined) {
					this.ruleForm.ticketjson = {
						title: res.item.title,
						title2: res.item.title2,
						logo: res.item.logo,
						id: res.item.billno,
						num: 1,
						memo: res.item.memo
					}
				}
			},
			getgrouplist() {
				let param = {
					mpid: this.mpid,
					name: '',
					pageno: 1,
					pagesize: 100
				}
				groupApi.list(param, res => {
					console.log(res, 'reslist');
					if (res.code == 200) {
						this.groupData = res.data.list
					}
				})
			},
			getdetail() {
				deskqrcodeApi.detailbyshopid(this.ruleForm.shopid, res => {
					console.log(res, 'resdetail');
					if (res.code == 200) {
						this.isedit = true
						this.ruleForm.title = res.data.title
						this.ruleForm.isverify = res.data.isverify == 0 ? false : true
						this.ruleForm.isticket = res.data.isticket == 0 ? false : true
						this.personData = JSON.parse(res.data.manjson)
						this.ruleForm.grouptip = res.data.grouptip
						this.ruleForm.welcomejson = JSON.parse(res.data.welcomejson)
						console.log(this.ruleForm.welcomejson, 'this.ruleForm.welcomejson');
						this.ruleForm.qrid = res.data.qrid
						this.ruleForm.apptype = res.data.apptype
						this.ruleForm.apppage = res.data.apppage
						this.ruleForm.appid = res.data.appid
						this.ruleForm.apppath = res.data.apppath
						this.ruleForm.btnurl = res.data.btnurl
						this.ruleForm.bgurl = res.data.bgurl
						this.ruleForm.appmedia_url = res.data.appmedia_url
						this.ruleForm.appmedia_resid = res.data.appmedia_resid
						this.ruleForm.apptitle = res.data.apptitle
						this.ruleForm.adlogo = res.data.adlogo
						if (res.data.isticket == 1) {
							this.ruleForm.ticketjson = JSON.parse(res.data.ticketjson)

						}


					} else if (res.code == 201) {

					} else {
						this.$message({
							message: res.msg,
							type: 'success'
						})
					}
				})
			},
			submitForm(formName) {
				let user = getloginuser()
				let manjson = [];
				console.log(this.manData, this.ruleForm.manjson, 'sub');
				manjson = this.personData
				
					let param = {
						qrid: this.ruleForm.qrid,
						mpid: this.mpid,
						memo: '',
						title: this.ruleForm.title,
						appid: this.ruleForm.appid,
						apppath: this.ruleForm.apppath,
						apptype: this.ruleForm.apptype,
						scene: 2,
						isverify: this.ruleForm.istemp == false ? 1 : 2,
						manid: '',
						manmc: '',
						manno: '',
						groupchatjson: this.groupsData,
						qrtype: 1,
						manjson: manjson,
						welcomejson: this.ruleForm.welcomejson,
						grouptip: this.ruleForm.grouptip,
						isticket: 1,
						mchno: this.ruleForm.mchno,
						mchshopid: this.ruleForm.mchshopid,
						mchshopname: this.ruleForm.mchshopname,
						mchshopno: this.ruleForm.mchshopno,
						mchtype: '',
						shopid: this.ruleForm.shopid,
						ticketjson: this.ruleForm.ticketjson,
						unitno: user != undefined ? user.unitno : '',
						btnurl: this.ruleForm.btnurl,
						bgurl: this.ruleForm.bgurl,
						appmedia_url: this.ruleForm.appmedia_url,
						appmedia_resid: this.ruleForm.appmedia_resid,
						apppage: this.ruleForm.apppage,
						apptitle: this.ruleForm.apptitle,
						adlogo: this.ruleForm.adlogo
					}
					console.log(param, 'param');
					deskqrcodeApi.add(param, res => {
						console.log(res, 'submit.res');
						if (res.code == 200) {
							this.$message({
								type: 'success',
								message: res.msg
							})
							this.$confirm('保存成功，是否返回主页面?', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.$router.push({
									path: '/customer/home/mp/weixinqiye/deskqrcode/index?mpid=' +
										this.mpid,
								})
							}).catch(() => {});
						} else {
							this.$message({
								type: 'error',
								message: res.msg
							})
						}
					})
								
				
			},
			funback() {
				this.$router.push({
					path: '/customer/home/mp/weixinqiye/deskqrcode/index?mpid=' + this.mpid,
				})
			},
			changeswitch() {
				this.ruleForm.manjson = ''
			},


			funaddewm() {
				this.groupVisible = true
			},
			funupappbgimg() {
				this.ruleForm.appmedia_url = ''
				this.ruleForm.appmedia_resid = ''
			},
			funupadlogo(t) {
				this.$refs["selImgs"].funShow(t)
			},
			funupqwbgimg(t) {
				this.$refs['selImgs'].funselqw(t)
			},
			funupqwbtnimg(t) {
				this.$refs['selImgs'].funselqw(t)
			},
			selImgsresult(imgitem) {
				console.log(imgitem, 'imgitem');
				if (imgitem.t == 'qwbgimg') {
					this.ruleForm.bgurl = imgitem.url

				} else if (imgitem.t == 'qwbtnimg') {
					this.ruleForm.btnurl = imgitem.url
				} else {
					this.ruleForm.adlogo = imgitem.url
				}
			},
			funAddPerson() {
				console.log(this.ruleForm.manjson,'this.ruleForm.manjson');
				console.log(this.manData,'this.manData');
				let newArr = []
				this.ruleForm.manjson.forEach(f => {
					this.manData.forEach(f2 => {
						if (f == f2.id) {
							newArr.push(f2)
						}
					})
				})
				newArr.forEach(f => {
					console.log(f, 'f');
					this.personData.push({
						name: f.manname,
						manno: f.manno,
					})
				})
			},
			funselPerson(v) {
				console.log(v, this.ruleForm.manjson);
				// let item = this.ruleForm.manjson.find(f=>{
				// 	return f.id == v;
				// })

				// if(item != undefined){
				// 	this.personData = [{
				// 	name: item.manname,
				// 	manno: item.manno,
				// }]
				// }

			},
			fundelperson(index) {
				this.personData.splice(index, 1)
			},

			funAddgroup() {
				this.groupVisible = false
				console.log(this.groupData, 'this.groupsData');
				let f = this.groupData.find(f => f.id == this.ruleForm.groupchatjson)
				console.log(find, ';');
				if (f != undefined) {
					this.groupsData.push({
						id: f.id,
						chatid: f.chat_id,
						chatname: f.chat_name
					})
				}
			},
			fundelgroup(index) {
				this.groupsData.splice(index, 1)
			},
			funAddaffix() {
				this.$refs['selaffix'].funShow(this.mpid, {}, 'add')
			},
			fundelaffix(index) {
				this.ruleForm.welcomejson.splice(index, 1)
			},
			funeditfix(item) {
				this.$refs['selaffix'].funShow(this.mpid, item, 'edit')
			},
			successfile(res, file, fileList) {
				console.log("successfile==>", res, file, fileList);
				if (res.code == 200) {
					this.$message({
						type: 'success',
						message: "上传成功"
					})
					// 提交保存成功后清空已上传的文件
					this.$refs.upload.clearFiles();
					this.ruleForm.appmedia_url = res.data.link_url
					this.ruleForm.appmedia_resid = res.data.res_id
				} else {
					this.$message({
						type: 'error',
						message: res.msg + "上传失败"
					})
				}


			},
			selaffixresult(param, handtype) {
				console.log(param, 'param');
				if (handtype == 'add') {
					this.ruleForm.welcomejson.push(param)
				} else {
					let temp = this.ruleForm.welcomejson.find(f => {
						return f.msgtype == param.msgtype
					})
					if (temp != undefined) {
						temp.msgtype = param.msgtype
						temp.appid = param.appid
						temp.apppage = param.apppage
						temp.desc = param.desc
						temp.logo = param.logo
						temp.media_id = param.media_id
						temp.title = param.title
						temp.url = param.url
						temp.imgurl = param.imgurl
					} else {
						this.ruleForm.welcomejson.push(param)
					}

				}


			}
		}
	}
</script>

<style scoped>
	.page {
		background: #F5F7FA;

	}

	.title-tips {
		padding-left: 28px;
		font-weight: 650;
		font-style: normal;
		color: #f59a23;
		font-size: 14px;
	}

	.addaffix {
		cursor: pointer;
		padding-left: 10px;
		border: 1px solid #DCDFE6;
		border-top: 0px solid #DCDFE6;
	}


	.list {
		display: flex;
		flex-direction: column;
	}

	.list-item {
		position: relative;
	}

	.del {
		position: absolute;
		right: 10px;
		top: 0px;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);
	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
		overflow: auto;
	}

	.btnbox {
		width: 100%;
		display: flex;
		justify-content: flex-end;
	}

	.qwbgimg {
		max-width: 100px;
		height: auto;
	}

	.ticket {
		width: 400px;
		border-radius: 10px;
		border: 1px solid #e0e0e0;
		display: flex;
		flex-direction: row;
		align-items: center;
		overflow: hidden;
	}

	.ticket .timg {
		width: 80px;
		height: 80px;
		margin-right: 10px;
	}

	.ticket .right {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
	}

	.ticket .right .title {
		font-size: 16px;
		font-weight: 600;
	}

	.ticket .right .memo {
		font-size: 9px;

		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 100%;
	}
	.auth1{
		color: #00aa00;
	}
</style>