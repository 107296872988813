var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"foot_nav_circle"},[_c('div',{staticClass:"imgcont",style:({
         'margin-left':_vm.marginpage,
          'margin-right':_vm.marginpage,
					'margin-top':_vm.margintop,
          'margin-bottom':_vm.marginbottom,
					'width' : _vm.width,
					'height' : _vm.height})},[(_vm.list.options && _vm.list.options.bgimg && _vm.list.options.bgimg.length > 0)?_c('div',[_c('img',{style:({
							'width' : _vm.width,
					'height' : _vm.height
						}),attrs:{"src":(_vm.list.options.bgimg && _vm.list.options.bgimg.length > 0) ? _vm.list.options.bgimg : ''}})]):_vm._e(),_vm._l((_vm.list.data),function(item,index){return _c('div',{key:index,staticClass:"item",style:({
						
						'left':(item.left / 2) + 'px',
						'top':(item.top / 2) + 'px',
						'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px'
					}),attrs:{"id":'a'+index}},[_c('el-image',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data &&_vm.data.data.length > 0),expression:"data.data &&data.data.length > 0"}],staticStyle:{"display":"block"},style:({
							'width':(item.width / 2) + 'px',
						'height':(item.height / 2) + 'px',
						'border-radius': _vm.borderradius,
						 
						}),attrs:{"src":item.url}}),_c('div',{staticClass:"title",style:({
							'margin-top':item.margintop + 'px',
							'color':_vm.list.options.titlecolor,
							fontSize: _vm.size,
          fontWeight: _vm.bold,
						})},[_vm._v(" "+_vm._s(item.title)+" ")])],1)}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.data.length == 0),expression:"data.data.length == 0"}],staticClass:"empty"},[_c('el-empty',{attrs:{"description":"此区域放置图片"}})],1)],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }