<template>
	<!-- Banner & Image 通用组件 -->
	<div class="image-content">
		<p class="desc">添加图片 (最多{{ len }}张，可拖动排序）</p>
		<el-tabs v-model="activeName" type="card">
			<el-tab-pane label="样式" name="first">
				<!--属性设置-->
				<div class="details">
					<div class="imglist">
						<div class="imgbox" @click="funstyle(1)"
							:class="list.options.stylebox == 1 ? 'active' : 'normal'">
							<img src="../../../../assets/image/market/icon_coustyle1.png" />
						</div>
						<div class="imgbox" @click="funstyle(2)"
							:class="list.options.stylebox == 2 ? 'active' : 'normal'">
							<img src="../../../../assets/image/market/icon_coustyle2.png" />
						</div>
					</div>
					<!-- 样式切换 -->
					<!-- <div class="list">
						<div class="title">
							<span>样式选择 </span>
							<span> {{ list.options.stylebox == 1 ? "样式一" : "样式二" }} </span>
						</div>
						<div class="btn">
							<el-radio-group v-model="list.options.stylebox">
								<el-tooltip class="item" effect="dark" content="样式一" placement="bottom">
									<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
								</el-tooltip>
								<el-tooltip class="item" effect="dark" content="样式二" placement="bottom">
									<el-radio-button :label="2">
										<i class="el-icon-s-operation"></i>
									</el-radio-button>
								</el-tooltip>
							</el-radio-group>
						</div>
					</div> -->
					<!-- 字体颜色 -->
					<div class="list">
						<div class="title"><span>字体颜色</span></div>
						<div class="center">{{list.options.color||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset" @click="reset('color')">重置</el-button><el-color-picker
								v-model="list.options.color" size="medium"></el-color-picker>
						</div>
					</div>

					<!-- 背景颜色 -->
					<div class="list">
						<div class="title"><span>背景颜色</span></div>
						<div class="center">{{list.options.bgcolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('bgcolor')">重置</el-button><el-color-picker v-model="list.options.bgcolor"
								size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 按钮字体颜色 -->
					<div class="list" v-if="list.options.stylebox == 2">
						<div class="title"><span>按钮字体颜色</span></div>
						<div class="center">{{list.options.btncolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('btncolor')">重置</el-button><el-color-picker
								v-model="list.options.btncolor" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 按钮背景颜色 -->
					<div class="list" v-if="list.options.stylebox == 2">
						<div class="title"><span>按钮背景颜色</span></div>
						<div class="center">{{list.options.btnbgcolor||'默认' }}</div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('btnbgcolor')">重置</el-button><el-color-picker
								v-model="list.options.btnbgcolor" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 风格颜色 -->
					<div class="list" v-if="list.options.stylebox == 1">
						<div class="title"><span>风格颜色</span>{{list.options.linecolor||'默认' }}<span></span></div>
						<div class="btn">
							<el-button type="text" class="reset"
								@click="reset('linecolor')">重置</el-button><el-color-picker
								v-model="list.options.linecolor" size="medium"></el-color-picker>
						</div>
					</div>
					<!-- 圆角 -->
					<div class="list wd" v-if="list.options.stylebox == 2">
						<div class="title">
							<span>按钮圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.btnradius" show-input :max="800">
							</el-slider>
						</div>
					</div>
					<!-- 圆角 -->
					<div class="list wd">
						<div class="title">
							<span>圆角 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.borderradius" show-input :max="800">
							</el-slider>
						</div>
					</div>
					<div class="list wd">
						<div class="title">
							<span>上边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.margintop" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list wd">
						<div class="title">
							<span>下边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginbottom" show-input :max="200">
							</el-slider>
						</div>
					</div>
					<div class="list wd">
						<div class="title">
							<span>项目间距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginitem" show-input :max="200">
							</el-slider>
						</div>
					</div>

					<div class="list wd">
						<div class="title">
							<span>页边距 </span>
						</div>
						<div class="btn2 full">
							<el-slider v-model="list.options.marginpage" show-input :max="200">
							</el-slider>
						</div>
					</div>
				</div>
			</el-tab-pane>
		</el-tabs>

		<sellink ref="sellink" @sellinkresult="sellinkresult"></sellink>
		<selImgs ref="selImgs" @selImgsresult="selImgsresult"></selImgs>
	</div>
</template>

<script>
	import sellink from "@/components/customer/sellink"
	import selImgs from "@/components/customer/selImgs"
	import vuedraggable from "vuedraggable";
	export default {
		name: "image_row_edit",
		components: {
			sellink,
			selImgs,
			vuedraggable,
		},
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				list: {},
				url: "",
				index: null,
				show: false,
				imageIndex: -1,
				item: {

				},
				activeName: "first"
			};
		},
		computed: {
			size() {
				return this.list.type == "images" ? "750*750" : "750*400";
			},
			len() {
				return this.list.type == "images" ? 8 : 10;
			},
		},
		created() {
			this.list = this.data;
		},
		methods: {
			close() {
				this.show = false;
				this.url = "";
			},
			selitem(item, index) {
				this.item = item;
				this.imageIndex = index;
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			confirm() {
				this.list["data"][this.index]["link"] = this.url;
				this.close();
			},
			urlPopup(index) {
				this.$refs['sellink'].funShow()
			},
			removeImage(index) {
				this.list.data.splice(index, 1);
			},
			addImage(index) {
				this.imageIndex = index;
				this.$refs['selImgs'].funShow()
			},
			selImgsresult(imgitem) {
				this.upload(imgitem)
			},
			sellinkresult(param) {
				console.log(param, 'param,');
				console.log(this.imageIndex, 'this.imageIndex');
				const index = this.imageIndex;
				if (index !== null) {
					let data = this.list.data[index];
					data.link = param;
					console.log(data, 'data.url')
					this.$set(this.list.data, index, data);
				}
			},
			funstyle(t) {
				this.list.options.stylebox = t
			},
			upload(imgitem) {
				const index = this.imageIndex;
				const data = {
					name: imgitem.name,
					url: imgitem.url,
				};

				if (index !== null) {
					this.$set(this.list.data, index, data);
				} else {
					this.list.data.push(data);
				}
			},
		},
	};
</script>

<style lang="less" scoped>
	.image-content {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		height: 100%;
		overflow: hidden;

		.desc {
			text-align: center;
			font-size: 12px;
			color: #666;
			margin: 18px 0;
			padding-bottom: 10px;
			border-bottom: 1px dashed #ddd;
		}

		.size {
			text-align: center;
			font-size: 12px;
			color: #999;
			margin-bottom: 0;
		}

		.add-image {
			width: calc(100% - 20px);
			height: 34px;
			line-height: 34px;
			padding: 0;
			font-size: 12px;
			margin-left: 10px;
			margin-top: 10px;
		}

		.image-list {
			margin: 0;
			padding: 0 10px;
			overflow: auto;
			display: flex;
			flex-wrap: wrap;

			.item {
				width: 80px;
				height: 80px;
				overflow: hidden;
				margin: 2px;
				position: relative;

				.el-icon-close {
					position: absolute;
					right: 0;
					top: 0;
					cursor: pointer;
				}

				.el-icon-edit-outline {
					position: absolute;
					right: 0;
					bottom: 0;
					cursor: pointer;
				}

				img {
					width: 100%;
				}
			}


			.normal {
				border: 1px solid #ccc;
			}

			.sel {
				border: 1px solid #1b8bff;
			}

			&::-webkit-scrollbar-thumb {
				background: #dbdbdb;
				border-radius: 10px;
			}

			&::-webkit-scrollbar-track {
				background: #f6f6f6;
				border-radius: 10px;
			}

			&::-webkit-scrollbar {
				width: 6px;
			}

			li {
				list-style: none;
				background: #f7f8f9;
				border-radius: 4px;
				padding: 6px 14px 10px;
				margin-bottom: 10px;
				display: flex;
				justify-content: space-between;

				&.disable div {
					pointer-events: none;
					user-select: none;
				}

				.l-info {
					font-size: 12px;
					padding-top: 8px;
					width: calc(100% - 70px);

					p {
						margin: 12px 0 0;
						white-space: nowrap;
						overflow: hidden;
						display: flex;

						.link {
							color: #1b8bff;
							cursor: pointer;
						}

						.text {
							white-space: nowrap;
							text-align: -webkit-auto;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}

				.r-image {
					text-align: right;

					.el-icon-close {
						color: #999;
						font-size: 12px;
						font-weight: 600;
						margin-bottom: 6px;
						cursor: pointer;

						&:hover {
							color: red;
						}
					}

					.image-box {
						width: 70px;
						height: 70px;
						border-radius: 5px;
						overflow: hidden;
						position: relative;
						background: #fff;
						display: flex;
						justify-content: center;
						align-items: center;

						&::before {
							content: "";
							position: absolute;
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.5);
							opacity: 0;
							transition: all 0.3s;
						}

						span {
							position: absolute;
							top: 50%;
							left: 50%;
							color: #fff;
							transform: translate(-50%, -50%);
							font-size: 20px;
							cursor: pointer;
							opacity: 0;
							transition: all 0.3s;
						}

						img {
							max-width: 100%;
						}

						&:hover {

							&::before,
							span {
								opacity: 1;
							}
						}
					}
				}
			}
		}

		.list {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 20px;

			.title {
				margin-right: 10px;
				flex-shrink: 0;

			}

			.full {
				flex-grow: 1;
			}
		}

		p {
			margin: 10px;
		}
	}

	.wd {
		.title {
			width: 80px;
		}
	}

	.btn {
		display: flex;
		align-items: center;
	}

	.imglist {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.imglist img {
		width: 100%;
		height: 100%;
	}

	.imglist .imgbox {
		overflow: hidden;
		cursor: pointer;
		height: 60px;
		width: 120px;
		border-radius: 5px;
		margin: 5px;
		text-align: center;

	}

	.active {
		border: 2px solid #00aaff
	}

	.normal {
		border: 2px solid #ccc
	}

	.center {
		color: #969799;
	}

	.reset {
		color: #ff6060;
	}
</style>