<template>
	<div id="text">
		<!-- <el-tabs v-model="activeName" type="card"> -->
		<!-- <el-tab-pane label="数据" name="first"> -->
		<p>标题</p>
		<el-input size="medium" v-model="list.options.name" maxlength="10" placeholder="请输入标题"></el-input>
		<!-- 标题文字大小 -->
		<div class="list" style="margin-top: 20px;">
			<!-- <div class="title">
			    <span>标题大小 </span>
			    <span
			      >{{
			        list.options.size == 1
			          ? "大(16号)"
			          : list.options.size == 2
			          ? "中(14号)"
			          : "小(12号)"
			      }}
			    </span>
			  </div> -->
			<div class="btn">
				<el-select size="medium" v-model="list.options.size" filterable allow-create placeholder="请选择标题大小">
					<el-option v-for="item in fontlist" :key="item" :label="item" :value="item">
					</el-option>
				</el-select>
				<el-tooltip effect="dark" content="加粗" placement="top">
					<div class="bbox" style="margin-left: 10px;">
						<img class="blodimg" src="../../../../../assets/image/icon_b0.png" v-if="list.options.bold == 1" @click="funchu(2)"></img>
						<img class="blodimg" src="../../../../../assets/image/icon_b1.png" v-else-if="list.options.bold == 2" @click="funchu(1)" /></img>
					</div>
				</el-tooltip>
				<el-tooltip  effect="dark"   content="居中" placement="top">
					<div class="bbox" style="margin-left: 10px;">
						<img class="blodimg" src="../../../../../assets/c0.png" v-if="list.options.position && list.options.position == 1"  @click="funcenter(2)"></img>
						<img class="blodimg" src="../../../../../assets/c1.png" v-else  @click="funcenter(1)"/></img>
					</div>
				</el-tooltip>
				<!-- <el-tooltip  effect="dark"   content="标题粗细" placement="top"></el-tooltip> -->
				<el-tooltip effect="dark" content="标题字体颜色" placement="top">
					<el-color-picker style="margin: 0 10px;" v-model="list.options.titlecolor"
						size="medium"></el-color-picker>
				</el-tooltip>
				<el-tooltip effect="dark" content="背景色" placement="top">
					<el-color-picker v-model="list.options.background" size="medium"></el-color-picker>
				</el-tooltip>

			</div>
		</div>
		<!-- 是否文字加粗 -->
		<!-- <div class="list" >
			  <div class="title">
			    <span>标题粗细 </span>
			    <span> {{ list.options.bold == 1 ? "常规体" : "加粗体" }} </span>
			  </div>
			  <div class="btn">
			    <el-radio-group v-model="list.options.bold" >
			      <el-tooltip
			        class="item"
			        effect="dark"
			        content="常规体"
			        placement="bottom"
			      >
			        <el-radio-button :label="1"
			          ><i class="el-icon-s-fold"></i
			        ></el-radio-button>
			      </el-tooltip>
			      <el-tooltip
			        class="item"
			        effect="dark"
			        content="加粗体"
			        placement="bottom"
			      >
			        <el-radio-button :label="2">
			          <i class="el-icon-s-operation"></i>
			        </el-radio-button>
			      </el-tooltip>
			    </el-radio-group>
			  </div>
			</div> -->
		<!-- 标题文字颜色 -->
		<!-- <div class="list">
			  <div class="title"><span>标题颜色</span>{{list.options.titlecolor||'默认' }}<span></span></div>
			  <div class="btn">
			    <el-button type="text" class="reset" @click="reset('titlecolor')">重置</el-button
			    ><el-color-picker v-model="list.options.titlecolor" size="medium"></el-color-picker>
			  </div>
			</div> -->
		<!-- 背景颜色 -->
		<!-- <div class="list">
			  <div class="title"><span>背景颜色</span>{{ list.options.background||'默认' }}<span></span></div>
			  <div class="btn">
			    <el-button type="text" class="reset" @click="reset('background')">重置</el-button
			    ><el-color-picker v-model="list.options.background" size="medium"></el-color-picker>
			  </div>
			</div> -->
		<!-- 底部分割线 -->
		
		
		<p>描述内容</p>
		
			<el-input v-model="list.options.label"  :rows="5" maxlength="500" type="textarea" placeholder="描述内容，最多500字"></el-input>
			<div style="display: flex;align-items: center;margin-top: 10px;">
			<el-select size="medium" v-model="list.options.describe" filterable allow-create placeholder="请选择描述字大小">
				<el-option v-for="item in msfontlist" :key="item.id" :label="item.mc" :value="item.id">
				</el-option>
			</el-select>
			
			<!-- <el-tooltip  effect="dark"   content="标题粗细" placement="top"></el-tooltip> -->
			<el-tooltip  effect="dark"   content="描述字体色" placement="top">
				<el-color-picker style="margin: 0 10px;" v-model="list.options.describecolor" size="medium"></el-color-picker>
			</el-tooltip>
			<!-- <div class="btn">
				<el-radio-group v-model="list.options.describe">
					<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
						<el-radio-button :label="1">
							<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
						</el-radio-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
						<el-radio-button :label="2">
							<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
						</el-radio-button>
					</el-tooltip>
					<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
						<el-radio-button :label="3">
							<div style="font-size: 12px;width: 11px;height:16px ;"> A</div>
						</el-radio-button>
					</el-tooltip>
				</el-radio-group>
			</div> -->
		</div>
		<div class="line"></div>
		<div class="list">
			<div class="title">
				<span>底部分割线</span>{{ list.options.border ? "显示" : "不显示" }}<span></span>
			</div>
			<div class="btn">
				<el-switch v-model="list.options.border" active-color="#13ce66" inactive-color="#ff4949">
				</el-switch>
			</div>
		</div>
		<div style="height: 1px"></div>
		<p class="division"></p>

		<!-- </el-tab-pane> -->
		<!-- <el-tab-pane label="样式" name="style"> -->
		<div class="details">

			<!-- 显示位置 -->
			<!-- <div class="list">
				<div class="title">
					<span>显示位置 </span>
					<span> {{ list.options.position == 1 ? "居左显示" : "居中显示" }} </span>
				</div>
				<div class="btn">
					<el-radio-group v-model="list.options.position" @change="position">
						<el-tooltip class="item" effect="dark" content="居左显示" placement="bottom">
							<el-radio-button :label="1"><i class="el-icon-s-fold"></i></el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="居中显示" placement="bottom">
							<el-radio-button :label="2">
								<i class="el-icon-s-operation"></i>
							</el-radio-button>
						</el-tooltip>
					</el-radio-group>
				</div>
			</div> -->
			<!-- 描述文字大小 -->
			<!-- <div class="list">
				<div class="title">
					<span>描述大小 </span>
					<span>{{
			          list.options.describe == 1 ? "大(16号)" : list.options.describe == 2 ? "中(14号)" : "小(12号)"
			        }}
					</span>
				</div>
				<div class="btn">
					<el-radio-group v-model="list.options.describe">
						<el-tooltip class="item" effect="dark" content="大(16号)" placement="bottom">
							<el-radio-button :label="1">
								<div style="font-size: 16px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="中(14号)" placement="bottom">
							<el-radio-button :label="2">
								<div style="font-size: 14px;width: 11px;height:16px;line-height: 16px;"> A</div>
							</el-radio-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="小(12号)" placement="bottom">
							<el-radio-button :label="3">
								<div style="font-size: 12px;width: 11px;height:16px ;"> A</div>
							</el-radio-button>
						</el-tooltip>
					</el-radio-group>
				</div>
			</div> -->


			<!-- 描述文字颜色 -->
			


		</div>
		<!-- </el-tab-pane> -->
		<!-- </el-tabs> -->

	</div>
</template>

<script>
	export default {
		name: "text_title_edit",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},

		data() {
			return {
				list: {},
				fontlist: [
					10, 11, 12, 14, 16, 18, 20, 24, 32, 36, 40, 48, 64, 96, 128
				],
				msfontlist:[{
					mc:'12号(小)',
					id:3
				},
				{
					mc:'14号(中)',
					id:2
				},
				{
					mc:'16号(大)',
					id:1
				},
				],
				activeName: "first"
			};
		},

		created() {
			this.list = this.data;
			console.log(this.list,'text_title.list');
		},

		methods: {
			//  显示的位置
			position(event) {
				// this.$set(this.list.options.position, position, event);
				this.list.options.position = event;
			},
			reset(key) {
				this.$delete(this.list.options, key)
			},
			funcenter(t){
				this.list.options.position = t
			},
			funchu(bold){
				console.log(bold,'bold');
				this.list.options.bold = bold
			},
			// 字体大小
			size() {},
		},
	};
</script>

<style lang="less" scoped>
	#text {
		padding: 0 20px;

		p {
			padding: 10px 0;
		}
	}

	.list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 10px;
	}

	.title {
		font-size: 12px;

		span {
			color: #404450;
			font-size: 12px;
		}

		:first-child {
			padding-right: 10px;
		}
	}

	.btn {
		display: flex;
		align-items: center;

		:first-child {}

		.reset {
			margin-right: 10px;
		}
	}

	p {
		font-size: 10px;
		color: #404450;
	}

	.bbox {
		cursor: pointer;
		margin-right: 5px;
		width: 32px;
		height: 30px;
		border-radius: 4px;
		background-color: #DEDFE1;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.blodimg {
		width: 20px;
		height: 20px;
	}
	.line{
		border: 1px solid #f0f0f0;
		margin: 10px 0;
	}
</style>