<template>
	<div>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>系统模板</span>
				<div class="" style="display: flex;justify-content: left;">
					<el-button size="small" type="primary" @click="funAddtemp">新增</el-button>
					<el-button size="small" type="primary" @click="funlinktemp">导入用户模板</el-button>
				</div>
			</div>

			<!--列表-->
			<el-table size="small" :data="tempsysData" highlight-current-row v-loading="loading" :border="false"
				element-loading-text="拼命加载中" style="width: 100%;">
				<el-table-column align="center" width="180" label="模板图片">
					<template slot-scope="scope">
						<img :src="scope.row.logo" style="width: 80px;height: auto;" />
					</template>
				</el-table-column>
				<el-table-column align="center" prop="tmpname" label="模板名称" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.isdefault==1">(默认){{scope.row.tmpname}}</span>
						<span v-else>{{scope.row.tmpname}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" sortable prop="pricesale" label="价格" width="180">
				</el-table-column>
				<el-table-column align="center" sortable prop="isfree" label="是否付费" width="180">
					<template slot-scope="scope">
						<span v-if="scope.row.isfree==1">付费</span>
						<span v-else>免费</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="onlinename" label="是否上架" width="180">
				</el-table-column>
				<el-table-column align="center" prop="memo" label="说明" width="180">
				</el-table-column>
				<!-- <el-table-column label="操作" min-width="200">
					<template slot-scope="scope">
						<el-button size="mini" @click="temponline(scope.$index, scope.row)">{{scope.row.onlinestate == 2 ? '下架' :'上架'}}</el-button>
						<el-button size="mini" @click="tempEdit(scope.$index, scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click="tempDel(scope.$index, scope.row)">删除</el-button>
						<el-button size="mini" @click="tempSet(scope.$index, scope.row)">设为默认</el-button>
					</template>
				</el-table-column> -->
			</el-table>
			<!-- 分页组件 -->
			<Pagination v-bind:child-msg="pageparm" @callFather="imgcallFather"></Pagination>
		</el-card>
		<!-- 编辑界面 -->
		<el-dialog :title="title" :visible.sync="editFormVisible" width="80%" @click='editFormVisible=false'>
			<el-form label-width="150px" ref="editForm" :model="editForm">
				<el-form-item label="模板Logo" prop="logo">
					<el-input size="small" v-model="editForm.logo" auto-complete="off" placeholder="请输入logo"></el-input>
				</el-form-item>
				<el-form-item label="模板名称" prop="tmpname">
					<el-input size="small" v-model="editForm.tmpname" auto-complete="off"
						placeholder="请输入模板名称"></el-input>
				</el-form-item>
				<el-form-item label="样式id" prop="style_id">
					<el-input size="small" v-model="editForm.style_id" auto-complete="off"
						placeholder="请输入样式id"></el-input>
				</el-form-item>
				<el-form-item label="是否免费" prop="isfree">
					<el-select v-model="editForm.isfree" placeholder="请选择" size="small">
						<el-option v-for="item in freelist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="选择行业分类">
					<el-select v-model="editForm.hangyeclsid" placeholder="请选择" size="small"
						@focus="changecls('hangye')">
						<el-option v-for="item in hangyeclslist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="选择风格分类">
					<el-select v-model="editForm.styleclsid" placeholder="请选择" size="small" @focus="changecls('style')">
						<el-option v-for="item in styleclslist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="选择销售分类">
					<el-select v-model="editForm.saleclsid" placeholder="请选择" size="small" @focus="changecls('style')">
						<el-option v-for="item in saleclslist" :key="item.id" :label="item.mc" :value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态">
					
					<el-checkbox :label="item.id" :value="item.check" @change="checkChange(item)" v-for="(item,index) in checklist"
						:key="index">{{item.label}}</el-checkbox>
				</el-form-item>
				<el-form-item label="价格">
					<el-button size="small" type="primary"  class="title"
						@click="funsetprice('editForm')">设置价格
					</el-button>
				</el-form-item>
				<el-form-item label="备注" prop="memo">
					<!-- <el-input size="small" v-model="editForm.memo" auto-complete="off" placeholder="请输入消息备注"></el-input> -->
					<Editor class="CKEditor" ref="Editor" v-model="editForm.memo" @change="callbackChangeEditor"
						:fileUrl="richTextFileUrl">
					</Editor>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='editFormVisible=false'>取消</el-button>
				<el-button size="small" type="primary" :loading="loading" class="title"
					@click="submitForm('editForm')">保存
				</el-button>
			</div>
		</el-dialog>
		<!-- 导入界面 -->
		<el-dialog :title="linktitle" :visible.sync="linkVisible" width="80%" @click='linkVisible=false'>
			<div class="search">
				模板id:<el-input size="small" v-model="fromserch.tempid" placeholder="请输入模板id"
					style="width: 300px;margin: 0 10px;"></el-input><el-button size="small" type="primary"
					@click="funSearch">搜索</el-button>
			</div>
			<div class="main">
				<div class="box" v-for="(item,index) in tempuserData" :key="index" @click="funtempOk(item)">
					<div class="img">
						<img :src="item.logo" />
					</div>
					<div class="">
						{{item.tmpname}}
					</div>
					<div class="bg">
						<span>选择此模板</span>
					</div>
				</div>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='linkVisible=false'>关闭</el-button>
			</div>
		</el-dialog>
		<!-- 设置价格 -->
		<el-dialog :title="pricetitle" :visible.sync="priceVisible" width="80%" @click='priceVisible=false'>
			<div class="main">
				<el-form label-width="150px" >
					<el-form-item :label="item.buyname" v-for="(item,index) in priceform" :key="index">
						<span>购买价格</span><el-input size="small" v-model="item.pricebuy" auto-complete="off" placeholder="请输入购买价格"></el-input>
						<span>市场价格</span><el-input size="small" v-model="item.pricesale" auto-complete="off" placeholder="请输入市场价格"></el-input>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button size="small" @click='pricesubmit'>确定</el-button>
			</div>
		</el-dialog>
	</div>

</template>

<script>
	import Editor from '@/components/system/Editor'
	import Pagination from '@/components/Pagination.vue'
	import tempuserApi from '../../../../api/sys/tempuser.js'
	import tempsysApi from '../../../../api/sys/tempsys.js'
	export default {
		components: {
			Editor,
			Pagination
		},
		data() {
			return {
				title: "",
				linktitle: "",
				pricetitle:"",
				tmpid:"",
				loading: false,
				priceVisible:false,
				priceform:[
					{
						buytime:7,
						buyname:'7天',
						pricesale:0,
						pricebuy:0
					},
					{
						buytime:15,
						buyname:'15天',
						pricesale:0,
						pricebuy:0
					},
					{
						buytime:30,
						buyname:'30天',
						pricesale:0,
						pricebuy:0
					},
					
					
				],
				fromserch: {
					tempid: ""
				},
				formInline: {
					name: "",
					pageno: 1,
					pagesize: 10
				},
				pageparm: {
					currentPage: 1,
					pageSize: 10,
					total: 20
				},
				editForm: {
					context: "",
					isfree: "",
					logo: "",
					memo: "",
					pricesale: "",
					style_id: "",
					tmpname: "",
					type: [],
					hangyeclsid: '',
					styleclsid: '',
					saleclsid:''
				},
				checklist: [{
						id: '0',
						name: 'type',
						label: "推荐",
						check: false
					},
					{
						id: '1',
						name: 'type',
						label: "热销",
						check: false
					},
					{
						id: '2',
						name: 'type',
						label: "首页",
						check: false
					},
					{
						id: '3',
						name: 'type',
						label: "精品",
						check: false
					},
					{
						id: '4',
						name: 'type',
						label: "保留2",
						check: false
					}, {
						id: '5',
						name: 'type',
						label: "保留3",
						check: false
					}, {
						id: '6',
						name: 'type',
						label: "保留6",
						check: false
					},
				],
				linkeditForm: {
					logo: "",
					tmpName: "",
					tmpid: "",
				},
				freelist: [{
						id: 0,
						mc: "免费"
					},
					{
						id: 1,
						mc: "付费"
					},
				],
				linkVisible: false,
				editFormVisible: false,
				tempsysData: [],
				tempuserData: [],
				richTextFileUrl: '', // http://localhost:8088/api/v1/insideWorkOrder/upload
				hangyeclslist: [],
				styleclslist: [],
				saleclslist: []
			}
		},
		mounted() {
			this.init(this.formInline)
			this.getclslist('style');
			this.getclslist('hangye');
			this.getclslist('sale');
		},
		methods: {
			init(page) {
				tempsysApi.listdesign({
					pageno: page.pageno,
					pagesize: page.pagesize
				}, res => {
					console.log(res, 'list.res');
					if (res.code == 200) {
						this.tempsysData = res.data.list
						this.pageparm.currentPage = res.data.pageno
						this.pageparm.pageSize = res.data.pagesize
						this.pageparm.total = res.data.total
					}
				})
			},
			// 分页插件事件
			imgcallFather(parm) {
				this.formInline.pageno = parm.currentPage
				this.formInline.pagesize = parm.pageSize
				this.init(this.formInline)
			},
			funAddtemp() {
				this.title = "新增"
				this.editForm.context = ""
				this.editForm.isfree = ""
				this.editForm.logo = ""
				this.editForm.memo = ""
				this.editForm.pricesale = ""
				this.editForm.style_id = ""
				this.editForm.tmpname = ""
				this.editFormVisible = true
				this.editForm.hangyeclsid = ''
				this.editForm.styleclsid = ''
				this.editForm.saleclsid = ''
				this.editForm.type = ''
				this.checklist.forEach(ff=>{
					ff.check = false;
				})
			},
			changecls(type) {},
			getclslist(type) {
				let param = {
					typecls: type
				}
				tempsysApi.clslist(param, res => {
					console.log(res, 'getclslist.res');
					if (res.code == 200) {
						switch (type) {
							case 'hangye':
								this.hangyeclslist = res.data
								break;
							case 'style':
								this.styleclslist = res.data
								break;
							case 'sale':
								this.saleclslist = res.data
								break;
						}
					}
				})
			},
			funlinktemp() {
				this.linktitle = "导入模板"
				this.linkVisible = true

				this.linklist()
			},
			linklist() {
				tempuserApi.list({
					user_tmpid: this.fromserch.tempid
				}, res => {
					console.log(res, 'tempuserApi.res');
					if (res.code == 200) {
						this.tempuserData = res.data
					}
				})
			},
			funtempOk(item) {
				console.log(item, 'item');
				let param = {
					logo: item.logo,
					tmpName: item.tmpname,
					tmpid: item.tmpid
				}
				tempsysApi.fromuser(param, res => {
					console.log(res, 'fromuser.res');
					this.linkVisible = false
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "新增成功"
						})
						this.init(this.formInline)
					} else {
						this.$message({
							type: "error",
							message: "新增失败"
						})
					}
				})
			},
			temponline(index, row){
				if(row.onlinestate == 2){
					let param = {
						id: row.tmpid,
					}
					tempsysApi.tempdown(param, res => {
						console.log(res, 'tempdown.res');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg
							})
							this.init(this.formInline)
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					})
				}else{
					let param = {
						id: row.tmpid,
					}
					tempsysApi.tempup(param, res => {
						console.log(res, 'tempup.res');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: res.msg
							})
							this.init(this.formInline)
						} else {
							this.$message({
								type: "error",
								message: res.msg
							})
						}
					})
				}
				
			},
			tempEdit(index, row) {
				this.title = "编辑"
				this.tmpid = row.tmpid
				this.editFormVisible = true
				let param = {
					id: row.tmpid,
					context: this.editForm.context,
					isfree: this.editForm.isfree,
					logo: this.editForm.logo,
					memo: this.editForm.memo,
					pricesale: this.editForm.pricesale,
					style_id: this.editForm.style_id,
					tmpname: this.editForm.tmpname
				}
				tempsysApi.detail(param, res => {
					console.log(res, 'detail.res');
					if (res.code == 200) {
						let arr = []
						let row = res.data.appTmplate
						this.editForm.context = row.context
						this.editForm.isfree = row.isfree
						this.editForm.logo = row.logo
						this.editForm.memo = row.memo
						this.editForm.tmpid = row.tmpid
						this.editForm.pricesale = row.pricesale
						this.editForm.style_id = row.style_id
						this.editForm.tmpname = row.tmpname
						this.editForm.hangyeclsid = row.cls3id
						this.editForm.styleclsid = row.cls2id
						this.editForm.saleclsid = row.cls1id
						if(row.stateflag != null){
							arr = row.stateflag.substring(0,row.stateflag.length - 1).split(',')
							let checkindex = 0
							arr.forEach(ff=>{
								this.checklist[checkindex].check = ff == '1';
								checkindex++
							})
						
						}else{
							this.checklist.forEach(ff=>{
								ff.check = false;
							})
						}
						console.log(this.editForm,'editForm.type');
					} else {
						this.$message({
							type: "error",
							message: "失败"
						})
					}
				})
			},
			tempSet(index, row) {
				let param = {
					id: row.tmpid,
				}
				tempsysApi.default(param, res => {
					console.log(res, 'default.res');
					if (res.code == 200) {
						this.$message({
							type: "success",
							message: "设置成功"
						})
						this.init(this.formInline)
					} else {
						this.$message({
							type: "error",
							message: "失败"
						})
					}
				})
			},
			tempDel(index, row) {
				this.$confirm('是否删除此模版, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let param = {
						sys_tmpid: row.tmpid,
					}
					tempsysApi.delete(param, res => {
						console.log(res, 'default.res');
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "删除成功"
							})
							this.init(this.formInline)
						} else {
							this.$message({
								type: "error",
								message: "删除失败"
							})
						}
					})
				}).catch(() => {

				});
			},
			funsetprice(){
				this.pricetitle = '设置价格'
				this.priceVisible = true
			},
			pricesubmit(){
				console.log(this.priceform,'priceform');
				tempsysApi.setprice({
					tmpid:this.tmpid,
					list:this.priceform
				}, res => {
					console.log(res, 'list.res');
					if (res.code == 200) {
						this.$message({
							type:"success",
							message:res.msg
						})
						this.priceVisible = false
					}
				})
			},
			submitForm() {
				if(this.editForm.saleclsid == null){
					this.$message({
						type:"error",
						message:"请填写销售分类"
					})
					return
				}
				if(this.editForm.hangyeclsid == null){
					this.$message({
						type:"error",
						message:"请填写行业分类"
					})
					return
				}
				if(this.editForm.styleclsid == null){
					this.$message({
						type:"error",
						message:"请填写风格分类"
					})
					return
				}
				let stateflag = '',
					statename = '';
				this.checklist.forEach(i => {
					if (i.check) {
						stateflag += '1,';
						statename += i.label + ','
					} else {
						stateflag += '0,'
					}
				})
				let cls1name = '',
					cls2name = '',
					cls3name = '';
				let f1 = this.saleclslist.find(f => f.id == this.editForm.saleclsid)
				if (f1 != undefined) {
					cls1name = f1.mc
				}
				let f2 = this.styleclslist.find(f => f.id == this.editForm.styleclsid)
				if (f2 != undefined) {
					cls2name = f2.mc
				}
				let f3 = this.hangyeclslist.find(f => f.id == this.editForm.hangyeclsid)
				if (f3 != undefined) {
					cls3name = f3.mc
				}
				let param = {
					context: this.editForm.context,
					isfree: this.editForm.isfree,
					logo: this.editForm.logo,
					memo: this.editForm.memo,
					pricesale: this.editForm.pricesale,
					style_id: this.editForm.style_id,
					tmpname: this.editForm.tmpname,
					cls1id: this.editForm.saleclsid, //销售
					cls2id: this.editForm.styleclsid, //风格
					cls3id: this.editForm.hangyeclsid, //行业
					cls1name: cls1name,
					cls2name: cls2name,
					cls3name: cls3name,
					stateflag: stateflag, //推荐,热销,首页
					statename: statename
				}
				if (this.title == "新增") {
					tempsysApi.regnew(param, res => {
						console.log(res, 'regnew.res');
						this.editFormVisible = false
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "新增成功"
							})
							this.init(this.formInline)
						} else {
							this.$message({
								type: "error",
								message: "新增失败"
							})
						}
					})
				} else {
					param.id = this.editForm.tmpid
					console.log(param, 'edit.param');
					tempsysApi.edit(param, res => {
						console.log(res, 'edit.res');
						this.editFormVisible = false
						if (res.code == 200) {
							this.$message({
								type: "success",
								message: "编辑成功"
							})
							this.init(this.formInline)
						} else {
							this.$message({
								type: "error",
								message: "编辑失败"
							})
						}
					})
				}

			},
			funSearch() {
				this.linklist()
			},
			callbackChangeEditor(value) {
				console.log(value, 'callbackChangeEditor');
				this.editForm.memo = value
			},
			checkChange(item) {
				item.check = !item.check
				// console.log(e,'eee');
				// this.checklist.forEach(i => {
				// 	let f = e.find(t => {
				// 		return t == i.id
				// 	})
				// 	if (f != undefined) {
				// 		i.check = true
				// 	} else {
				// 		i.check = false
				// 	}
				// })
			}
		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.main {
		height: 500px;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		overflow-y: auto;
	}

	.main .box {
		border: 2px solid #000;
		border-radius: 10px;
		margin: 10px;
		padding: 10px;
		height: 375px;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: space-between;
		position: relative;
	}

	.main .box .bg {
		display: none;
	}

	.main .box:hover .bg {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 100%;
		height: 100%;
		color: #fff;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(0, 0, 0, 0.6);
	}

	.main .box .img {
		width: 150px;
		height: auto;
	}

	.main .box .img img {
		width: 100%;
		height: 100%;
	}

	.search {
		padding-bottom: 10px;
	}

	/deep/ .el-dialog__body {
		padding: 10px 20px !important;
	}
</style>