<template>
	<div>
		<div class="img">
			<div class="imgcont" :style="{
          'margin-left': marginpage,
          'margin-right': marginpage,
          'margin-top': margintop,
          'margin-bottom': marginbottom,
        }" v-if="list.options!= undefined && list.options.stylebox == 1">
				<div class="box">
					<div class="box-item" v-for="(item, index) in list.data" :style="{
		    width: colWidth,
		    height: height,
			'border-radius':list.options.borderradius + 'px',
			'background':list.options.bgcolor,
		    'margin-left': index > 0 ? marginitem : '0px',
		  }" :key="index" v-show="data.data && data.data.length > 0">
						<el-image style="display: block; flex-shrink: 0" :style="{
			      width: colWidth,
			      height: height,
			      'margin-left': index > 0 ? marginitem : '0px',
			    }" :src="item.url" v-if="item.url.length > 0"></el-image>
						<div class="op" :style="{'color':list.options.color}">
							<div class="title">
								<span>￥</span>1000
							</div>
							<div class="memo">
								无门槛使用
							</div>
						</div>
						<div class="line" :style="{'border': linecolor}"></div>
						<div class="l-radius" :style="{background:circlecolor}"></div>
						<div class="r-radius" :style="{background:circlecolor}"></div>
					</div>

				</div>
			</div>
			<div class="box2" :style="{
		    'margin-top': margintop,
		    'margin-bottom': marginbottom,
			'margin-left': marginpage,
			'margin-right': marginpage,
		  }" v-else>
				<div class="box2-item" v-for="(item, index) in list.data" :key="index"
					:style="{
					'margin-bottom': marginitem,width: colWidth2,'background':list.options.bgcolor,'color':list.options.color,'border-radius':list.options.borderradius + 'px'}">
					<div class="box2-left">
						<span>￥</span><span class="price">10</span>
					</div>
					<div class="box2-right">
						<div class="title">
							满199使用
						</div>
						<div class="btn"
							:style="{'background':list.options.btnbgcolor,'color':list.options.btncolor,'border-radius':list.options.btnradius + 'px'}">
							立即领用
						</div>
					</div>
				</div>
			</div>
			<div class="empty" v-show="data.data.length == 0" :style="{
          height: height,
          overflow: 'hidden', 
        }">
				<el-empty description="此区域放置图片" :style="{
            height: height,
          }"></el-empty>
			</div>
			<!-- <el-image style="width: 100%" :src="url"></el-image> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "image_row",
		props: {
			data: {
				type: Object,
				default: () => {},
			},
		},
		data() {
			return {
				url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
				list: ""
			};
		},

		mounted() {
			this.list = this.data;
		},
		computed: {
			overflow() {
				return "auto";
			},
			colWidth() {
				if (this.list.options) {
					if (this.list.options) {
						let w = (750 / 2 - this.list.options.marginpage * 2 - this.list.options.marginitem * 2)
						return (w / 3) + "px";
					} else {
						return "100%";
					}
				}
			},
			colWidth2() {
				if (this.list.options) {
					if (this.list.options) {
						let w = (750 / 2 - this.list.options.marginpage * 2 - this.list.options.marginitem)
						return (w / 2) + "px";
					} else {
						return "100%";
					}
				}
			},
			circlecolor() {
				if (this.list.options) {
					if (this.list.options.linecolor && this.list.options.linecolor.length > 0) {
						return this.list.options.linecolor;
					}
				}
				return "#f0f0f0";
			},
			linecolor() {
				if (this.list.options) {
					if (this.list.options.linecolor && this.list.options.linecolor.length > 0) {
						return '1px dashed ' + this.list.options.linecolor;
					}
				}
				return "1px dashed #f0f0f0";
			},
			height() {
				if (this.list.options) {
					if (this.list.options.itemheight && this.list.options.itemheight > 0) {
						return this.list.options.itemheight / 2 + "px";
					}
				}
			},

			margintop() {
				if (this.list.options) {
					if (this.list.options.margintop && this.list.options.margintop > 0)
						return this.list.options.margintop + "px";
				}
			},
			marginbottom() {
				if (this.list.options) {
					if (
						this.list.options.marginbottom &&
						this.list.options.marginbottom > 0
					)
						return this.list.options.marginbottom + "px";
				}
			},
			marginitem() {
				if (this.list.options) {
					if (this.list.options.marginitem && this.list.options.marginitem > 0)
						return this.list.options.marginitem + "px";
				}
			},

			marginpage() {
				if (this.list.options) {
					if (this.list.options.marginpage && this.list.options.marginpage > 0)
						return this.list.options.marginpage + "px";
				}
			},
			borderradius() {
				if (this.list.options) {
					if (
						this.list.options.borderradius &&
						this.list.options.borderradius > 0
					)
						return this.list.options.borderradius + "px";
				}
			},
		},

		methods: {},
	};
</script>

<style lang="less" scoped>
	.img {
		width: 100%;
		text-align: center;
		display: flex;
		flex-wrap: wrap;
	}

	.imgcont {
		display: flex;
		flex-direction: row;
	}

	.box {
		display: flex;
		justify-content: space-between;
		position: relative;
		overflow: hidden;
	}

	.box .box-item {
		position: relative;
		background: linear-gradient(to right, #F3E6D4, #DEAB6D);
		;
		overflow: hidden;
	}

	.box .box-item .line {
		position: absolute;
		top: 45px;
		width: 100%;

	}

	// .box .box-item::before{
	// 	content: '';
	// 	position: absolute;
	// 	left: -10px;
	// 	top: 35px;
	// 	width: 20px;
	// 	height: 20px;
	// 	border-radius: 50%;
	// 	background-color: #fff;
	// 	z-index: 999;
	// }
	// .box .box-item::after{
	// 	content: '';
	// 	position: absolute;
	// 	right: -10px;
	// 	top: 35px;
	// 	width: 20px;
	// 	height: 20px;
	// 	border-radius: 50%;
	// 	background-color: #fff;
	// 	z-index: 999;
	// }
	.box .box-item .l-radius {
		content: '';
		position: absolute;
		left: -10px;
		top: 35px;
		width: 20px;
		height: 20px;
		border-radius: 50%;

		z-index: 999;
	}

	.box .box-item .r-radius {
		content: '';
		position: absolute;
		right: -10px;
		top: 35px;
		width: 20px;
		height: 20px;
		border-radius: 50%;

		z-index: 999;
	}

	.box .box-item .op {
		position: absolute;
		left: 0px;
		top: 10px;
		width: 100%;
		color: #322C2C;
		z-index: 999;
	}

	.box .box-item .op .title {
		font-size: 16px;
		font-weight: 800;
	}

	.box .box-item .op .title span {
		font-size: 10px;

	}

	.box .box-item .op .memo {
		margin-top: 30px;
		white-space: nowrap;
		font-size: 12px;
	}

	.empty {
		width: 100%;
		// text-align: center;
		// display: flex;
		// flex-wrap: wrap;
	}

	.box2 {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	.box2 .box2-item {
		overflow: hidden;
		height: 80px;
		background-color: #404092;
		display: flex;
		align-items: center;
		color: #fff;
	}

	.box2 .box2-left {
		width: 80px;
	}

	.box2 .box2-left .price {
		font-size: 30px;
	}

	.box2 .box2-right {
		flex-grow: 1;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
	}
	.box2 .box2-right .title{
		white-space: nowrap;
	}
	.box2 .box2-right .btn {
		font-size: 10px;
		width: 80px;
		margin: 5px 0;
		padding: 2px 0;
		background-color: #E4004F;
	}
</style>