<template>
  <!-- 轮播图 -->
  <div class="swiper"  :style="{ 
     'height':height,
    'margin-left':  list.options.marginpage + 'px',
        'margin-right':  list.options.marginpage + 'px', 
        'margin-top':  list.options.margintop + 'px',
        'margin-bottom':  list.options.marginbottom + 'px', 

        'border-radius':chamfer }">
    <!-- 空白状态 -->
    <el-empty
      :image-size="100"
      :style="{
        'height':height
      }"
     
      description="点击编辑轮播图"
      v-if="data.data.length == 0"
    ></el-empty>
    <!-- 轮播图 -->
    <el-carousel :height="height"
     class="contain"
      v-else
    >
      <el-carousel-item v-for="(item, index) in data.data" :key="index">
        <el-image :fit="fit"  
        style="width: 100%;"
       
        :src="item.url"></el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: "image_swiper",
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: "",
      fit: 'contain',
    };
  },

  created() {
    this.list = this.data;
  },
  computed:{
   chamfer(){
     if(this.list.options.chamfer==2){
       return "20px"
     }else{
       return "0px"
     }
   },
   height(){
     if(this.list.options && this.list.options.height > 0){
     
     }
     else{
      this.list.options.height = 360;
     }
     return (this.list.options.height/2) + 'px'
   }
 },
  methods: {},
};
</script>

<style lang="less" scoped>
.swiper{
  width: 100%;
  overflow: hidden;
}
.img {

}
.contain{
  width: 100%;
}
</style>