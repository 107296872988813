<template>
	<div class="page">
		<navcon></navcon>
		<div class="pagecontent">
			<leftnav menuid="26"></leftnav>
			<div class="rightbox" style="margin-left:130px;">
				<el-card class="box-card" style="width: 1038px;">
					<!--列表-->
					<el-table size="small" :data="list" highlight-current-row v-loading="loading" :border="false"
						element-loading-text="拼命加载中" style="width: 100%;">
						<el-table-column align="center" prop="config_id" label="配置编号" >
						</el-table-column>
						<el-table-column fixed="right" label="操作" width="100">
							<template slot-scope="scope">
								<el-button type="text" size="small" @click="fundel(scope.row)">删除</el-button>
							</template>
						</el-table-column>
					</el-table>
					<!-- 分页组件 -->
					<el-button v-if="next_cursor != undefined && next_cursor.length > 0" @click="funtopage">下一页</el-button>
				</el-card>
			</div>
		</div>

	</div>
</template>

<script>
	
	import deskqrcodeApi from '../../../../../../api/cus/deskqrcode.js'
	import leftnav from '../../../../../../components/customer/home/mp/weixinqiye/leftnav.vue'
	import navcon from '../../../../../../components/customer/navcon.vue'
	export default {
		components: {
			leftnav,
			navcon
		},
		data() {
			return {
				mpid: "",
				title: "",
				loading: false,
				list: [],
				next_cursor:''
			}
		},
		mounted() {
			this.init()
		},
		methods: {
			init() {
				let mpid = this.$route.query.mpid
				if (mpid != undefined) {
					this.mpid = mpid
				} else {
					this.$router.push("/");
				}
				this.getlist()
			},
			getlist(page) {
				let param = {
					mpid: this.mpid,
					cursor: this.next_cursor,
					pagesize:10
				}
				console.log(param, 'getlist.param');
				deskqrcodeApi.getlist_contact_way(param, res => {
					console.log(res, 'getlist_contact_way.res');
					if (res.code == 200) {
						this.list = res.data.contact_way
						this.next_cursor = res.data.next_cursor
					}
				})
			},
			funtopage(){
				this.getlist()
			},
			// 分页插件事件
			
			search() {
				this.next_cursor = ''
				this.getlist()
			},
			fundel(item) {
				let param = {
					mpid:this.mpid,
					config_id: item.config_id
				}
				console.log(param, 'fundel.param');
				deskqrcodeApi.del_contact_way(param, res => {
					console.log(res, 'fundel.res');
					if (res.code == 200) {
						this.$message({
							message: res.msg,
							type: 'success'
						})
						this.search()
					} else {
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			}

		}
	}
</script>

<style scoped>
	.clearfix {
		display: flex;
		align-items: center;
	}

	.page {

		background: #F5F7FA;

	}

	.rightbox {
		padding-top: 20px;
		width: 1320px;
		overflow: auto;
	}

	.pagecontent {
		display: flex;

		height: calc(100vh - 81px);

	}
</style>